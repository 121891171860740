import React, { Component } from 'react'
import './GuestRoute.scss'
import FAQComponent from './FAQComponent'
import { Modal } from 'sfy-react'
import { MediumButton, CustomIconComponent } from '../../components/UIComponents'
import { browserHistory } from 'react-router'

export default class FAQs extends Component {
  constructor() {
    super()
    this.state = {
      showSupportModal: false,
    }
  }

  componentWillMount() {
    window.scrollTo(0, 0)
  }

  supportModalToggle = () => {
    this.setState({
      showSupportModal: !this.state.showSupportModal,
    })
  }

  handleBrowSererRidirection = () => {
    this.supportModalToggle()
    window.open('https://www.asurion.com/samsung/', '_blank')
  }

  render() {
    return (
      <div className='faq-contianer'>
        <div className='empty-route-container1'>
          <div className='row home-components1'>
            <div className='col-sm-12 col-lg-12'>
              <p
                className='fontLarge boldFontStyle primaryColor flex cursor-pointer'
                onClick={() => {
                  browserHistory.push('/')
                }}
              >
                <img src={`/${appName}/images/mycare/rightArrow.svg`} className='mr8 color-white fontLarge' /> BACK TO HOMEPAGE
              </p>
              <div className='font40 boldFontStyle'>Frequently Asked Questions</div>
              <div className='font20 bookFontStyle mt8'>
                Quick answers to questions you can have. Can’t find what you are looking for? Reach out to our friendly &nbsp;
                <span className='text-underline cursor-pointer' onClick={this.supportModalToggle}>
                  support team.
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className='empty-route-container1 background-white' id='faq'>
          <div className='row flex home-components1'>
            <FAQComponent />
          </div>
        </div>
        <div className='empty-route-container1 black-bg-color' id='support'>
          <div className='row home-components1 display-flex mt28 mb28'>
            <div className='col-sm-12 col-lg-8'>
              <div className='font20 color-white mb8'>Didn't find what you are looking for?</div>
              <div className='font40 boldFontStyle color-white'>Connect with us for support.</div>
            </div>
            <div className='col-sm-12 col-lg-3 mobile-support-section'>
              <div
                className='bookFontStyle color-white fontLarge'
                onClick={() => {
                  window.location.href = 'mailto:' + 'samsungmxcare@servify.tech'
                }}
              >
                <img src={`/${appName}/images/mycare/support/mail.svg`} className='mr8 color-white fontLarge' />
                <span className='color-white'>samsungmxcare@servify.tech</span>
              </div>
              <div className='bookFontStyle color-white mt20'>
                <img src={`/${appName}/images/mycare/support/phone.svg`} className='mr8 color-white fontLarge' />
                (833)690-0918
              </div>
            </div>
          </div>
        </div>

        <Modal showModal={this.state.showSupportModal} className={'sfy-modal mycare-viewer-modal '}>
          <div className={'close-icon ' + (window.isDirectionRTL ? ' text-left ' : ' text-right')}>
            <CustomIconComponent
              className={'close-icon ' + (window.isDirectionRTL ? ' text-left ' : ' text-right')}
              onClick={this.supportModalToggle}
              alt='Cancel'
            />
          </div>

          <div className='text-center'>
            <img src={`/${appName}/images/mycare/call_support.svg`} />
            <div className='boldFontStyle mt8 mb8 fontExtraLarge'>Samsung Care+ Support</div>
            <div className='mt28 mb28 flex body-container'>
              <img src={`/${appName}/images/mycare/call_support.svg`} />
              <div className='text-container'>
                <div className='mb4 boldFontStyle fontMedium'>Enrolled in Samsung Care+ with Theft and Loss plan before 01/31/2023</div>
                <div className='fontSmall'>
                  File a claim or get support for Samsung Care+ with Theft and Loss device(s) enrolled before 01/31/2023
                </div>
              </div>
              <MediumButton
                buttonText='Get Plan Support'
                className='button-ghost button-small save-button'
                onClick={this.handleBrowSererRidirection}
              />
            </div>
            <p className='mt28 boldFontStyle fontMedium text-center cursor-pointer primaryColor' onClick={this.supportModalToggle}>
              Back to home
            </p>
          </div>
        </Modal>
      </div>
    )
  }
}
