import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './accountLink.scss'
import { LoginPhNo, LoginOTP, LoginHeading, UserAuthLinkComponent, LoginContactDetails } from './AccountLinkSubComponent'
import { Captcha, getCaptcha } from 'sfy-react'
import { BrandSpecificConfig } from '../../../utils/constants'
import { regexCheck } from '../../../utils/regex'
import { isValidMobileNumber } from '../../../utils/validators'
import { browserHistory } from 'react-router'
import _ from 'lodash'
import { getTranslationText } from '../../../utils/languageTranslation'

class AccountLinkComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loginPageId: 'user_auth',
      otpValue: '',
      showReCaptcha: false,
      resendOTP: false,
      ResendOTPRequest: false,
      allowResend: false,
      Phno: '',
      UsrName: '',
      showResendOTPbtn: false,
      frequentCalls: 60,
      showOTPTimer: false,
      showCallOptnBtn: false,
      otpViaCallTimer: 60,
      showCallTimer: false,
      sendOTPCounter: 0,
      selectedCountryObj: _.get(props, 'user.selectedCountryData', {}),
      BtnLoaderStatus: false,
      setFocusOTP: false,
      otpImage: '/commonAssets/Login/otpimg.png',
      termsAndCondCheck: false,
      invalidFormat: false,
      disableResendOtpCall: false,
      phnoLengthAr: _.get(props, 'user.selectedCountryData.AllowedMobileLengths')
        ? props.user.selectedCountryData.AllowedMobileLengths.split(',').map((int) => parseInt(int))
        : [10],
    }
  }

  componentWillMount() {
    this.props.setglobalLoaderStatus(false)
    // _.get(this, 'props.user.userDetails.IsConsumerLinked', true) && browserHistory.replace('/')
  }

  componentWillUnmount() {
    // clear timer related intervals
    clearInterval(window.frequentCallsFunc)
    clearInterval(window.otpViaCallFunc)
    clearInterval(window.sendOTPCounterFunc)
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      let phnoLengthAr = _.get(nextProps, 'user.selectedCountryData.AllowedMobileLengths')
        ? nextProps.user.selectedCountryData.AllowedMobileLengths.split(',').map((int) => parseInt(int))
        : [10]
      this.setState({
        phnoLengthAr,
      })
    }
  }

  handleCountryChange = (value) => {
    let configDetails = this.props.user
    if (
      value.CountryID !== configDetails.selectedCountryData.CountryID ||
      value.CountryCode !== configDetails.appConfigDetails.CountryCode
    ) {
      // call appConfig only when selected country has changed
      this.setState({
        selectedCountryObj: value,
      })
      this.props.setSelectedCountryData(value)
      this.props.setglobalLoaderStatus(true)
      this.props.loadAppConfig().then(() => {
        this.props.getAppLegalConfigs().then(() => {
          this.props.setglobalLoaderStatus(false)
        })
      })
    }
  }

  onFetchingOtp =
    ({ viaResendOptn, requestObj }) =>
    (data) => {
      // @todo remove below default value when the API goes live for the same
      const showReCaptchaDefault = false
      const allowResendDefault = true
      const {
        data: { sendCaptcha: showReCaptcha = showReCaptchaDefault, allowResend = allowResendDefault },
      } = data
      const disableResendOtp = false
      this.setState({ allowResend })
      if (viaResendOptn) {
        // resend option set state execution
        if (requestObj.voiceOtp) {
          // for resend via call
          this.setState(
            {
              showCallTimer: true,
            },
            () => {
              window.otpViaCallFunc = setInterval(() => {
                if (this.state.otpViaCallTimer === 0) {
                  clearInterval(window.otpViaCallFunc)
                  this.setState({
                    otpViaCallTimer: 60,
                    showCallOptnBtn: true,
                    disableResendOtp,
                  })
                } else {
                  this.setState({
                    showCallOptnBtn: false,
                    otpViaCallTimer: this.state.otpViaCallTimer - 1,
                  })
                }
              }, 1000)
            }
          )
        } else {
          // for resend via msg
          this.setState(
            {
              showOTPTimer: true,
            },
            () => {
              window.frequentCallsFunc = setInterval(() => {
                if (this.state.frequentCalls === 1) {
                  clearInterval(window.frequentCallsFunc)
                  this.setState({
                    frequentCalls: 60,
                    showResendOTPbtn: true,
                    disableResendOtp,
                  })
                } else {
                  this.setState({
                    showResendOTPbtn: false,
                    frequentCalls: this.state.frequentCalls - 1,
                  })
                }
              }, 1000)
            }
          )
        }
      } else {
        if (data.redirect) {
          browserHistory.replace('/login')
          return
        }
        this.setState(
          {
            showReCaptcha,
            BtnLoaderStatus: false,
            otpFetched: true,
            sendOTPCounter: 30,
            loginPageId: 'otp_page',
            setFocusOTP: true,
          },
          () => {
            window.sendOTPCounterFunc = setInterval(() => {
              // timer functions to display counters for enabling re-send options
              if (this.state.sendOTPCounter === 1) {
                clearInterval(window.sendOTPCounterFunc)
                this.setState({
                  sendOTPCounter: 0,
                  showResendOTPbtn: true,
                  showCallOptnBtn: true,
                  disableResendOtp: false,
                })
              } else {
                this.setState({
                  sendOTPCounter: this.state.sendOTPCounter - 1,
                })
              }
            }, 1000)
            if (this.state.setFocusOTP && !viaResendOptn) {
              document.getElementById('otp-input').focus()
            }
          }
        )
      }
    }

  onFetchingOtpFailure = () => this.setState({ BtnLoaderStatus: false, disableResendOtp: false })

  fetchOTPfunc = (request, viaResendOptn = true) => {
    const requestObj = { ...request }
    requestObj.ResendOTPRequest = !!this.state.ResendOTPRequest
    const onFetchingOtp = this.onFetchingOtp({ viaResendOptn, requestObj })
    this.props.generateOTPAsync(requestObj).then(onFetchingOtp).catch(this.onFetchingOtpFailure)
  }

  validatePhno = () => {
    if (this.state.termsAndCondCheck) {
      if (regexCheck('validation', 'numeric', this.state.Phno, this.state.phnoLengthAr)) {
        this.setState(
          {
            BtnLoaderStatus: true,
          },
          async () => {
            const request = {
              mobileNo: this.state.Phno,
            }
            if (_.get(this, 'props.user.appConfigDetails.sendCaptcha')) {
              const captcha = await getCaptcha()
              this.fetchOTPfunc(
                {
                  ...request,
                  captcha,
                },
                false
              )
            } else {
              this.fetchOTPfunc(request, false)
            }
          }
        )
      } else {
        this.props.setToaster(getTranslationText('error', 'invalidMobile'), 'error')
        this.setState({
          Phno: '',
        })
      }
    }
  }

  checkFormat = (value) => {
    if (this.state.phnoLengthAr.includes(value.length) && !isNaN(value)) {
      this.setState(
        {
          invalidFormat: isValidMobileNumber(value, this.state.selectedCountryObj.CountryCode),
        },
        () => {
          this.state.invalidFormat && this.props.setToaster(getTranslationText('error', 'invalidMobile'), 'error')
        }
      )
    }
    return true
  }

  handlePhNoInputChange = (e) => {
    if (e.which === 13) {
      !this.state.invalidFormat && e.target.value && this.state.phnoLengthAr.includes(e.target.value.length) && this.validatePhno()
    } else {
      if (this.checkFormat(e.target.value) && regexCheck('onChange', 'numeric', e.target.value, Math.max(...this.state.phnoLengthAr))) {
        this.setState({
          Phno: e.target.value,
        })
      }
    }
  }

  // OTP funcs
  checkOTPlength = (e) => {
    if (e.which === 13) {
      e.target.value && e.target.value.length === 6 && !this.isButtonDisabled() && this.checkOTP()
    } else {
      if (regexCheck('onChange', 'numeric', e.target.value, 6)) {
        this.setState({
          otpValue: e.target.value,
        })
      }
    }
  }

  checkOTP = () => {
    const { BtnLoaderStatus, otpValue } = this.state
    if (BtnLoaderStatus || !otpValue) {
      return
    }
    this.setState(
      {
        BtnLoaderStatus: true,
      },
      async () => {
        const { showReCaptcha, Phno: mobileNo, otpValue: OTP } = this.state
        const request = {
          mobileNo,
          OTP,
          isConsumerLinked: false,
        }
        if (showReCaptcha) {
          const captcha = await getCaptcha()
          request.captcha = captcha
          this.verifyOTP(request)
        } else {
          this.verifyOTP(request)
        }
      }
    )
  }

  redirectToLogin = () => {
    browserHistory.replace('/')
  }

  handleOtpVerification = ({ sendCaptcha: showReCaptcha, message, isLoginSuccess, ...data }) => {
    const BtnLoaderStatus = false
    if (data.redirect) {
      this.props.setToaster(message, 'error')
      browserHistory.replace('/login')
      return
    }
    if (isLoginSuccess) {
      if (!data.profileComplete) {
        this.setState({ loginPageId: 'new_usr', BtnLoaderStatus })
      } else {
        browserHistory.replace('/')
      }
      return
    }

    const loginPageId = 'otp_page'
    const otpValue = ''
    const resetState = {
      loginPageId,
      showReCaptcha,
      otpValue,
      BtnLoaderStatus,
      allowResend: data.allowResend,
    }
    this.setState(resetState)
    this.props.setToaster(message, 'error')
  }

  verifyOTP = (reqBody) =>
    this.props.verifyOTPAsync(reqBody, { allowPartialSuccess: true }).then(this.handleOtpVerification).catch(this.redirectToLogin)

  ResendOtpHandler = (type) => {
    let resendType = type
    this.setState(
      {
        otpValue: '',
        ResendOTPRequest: true,
        disableResendOtp: true,
      },
      async () => {
        let reqPayload = { mobileNo: this.state.Phno }
        if (resendType === 'call') {
          reqPayload.voiceOtp = true
        }
        const hander = (req) => this.fetchOTPfunc(req)
        if (_.get(this, 'props.user.appConfigDetails.sendCaptcha')) {
          const captcha = await getCaptcha()
          reqPayload.captcha = captcha
          hander(reqPayload)
        } else {
          if (this.state.otpViaCallTimer === 60 || this.state.frequentCalls === 60) {
            hander(reqPayload)
          }
        }
      }
    )
  }

  updateOTPImage = (key) => {
    this.setState({
      otpImage: key === 'active' ? `/${appName}/images/Login/otpimg_active.png` : '/commonAssets/Login/otpimg.png',
    })
  }

  handleTermsAndCondClick = () => {
    this.setState({
      termsAndCondCheck: !this.state.termsAndCondCheck,
    })
  }

  isOtpValid = () => this.state.otpValue.length === 6

  isButtonDisabled = () => {
    if (!this.isOtpValid()) {
      return true
    }
    return false
  }

  handleUserInputs = (e) => {
    if (e.which === 13) {
      this.validateContactDetails()
    } else {
      if (regexCheck('onChange', 'userName', e.target.value, 40)) {
        this.setState({
          UsrName: e.target.value,
        })
      }
    }
  }

  validateContactDetails = () => {
    let callApiStatus = regexCheck('validation', 'userName', this.state.UsrName)
    if (callApiStatus) {
      this.props.setglobalLoaderStatus(true)
      this.props
        .updateUserDetailsAsync({
          data: {
            ...this.props.user.consumerDetails.data,
            updateObj: {
              Name: this.state.UsrName,
            },
            CurrentDateTime: new Date().toISOString(),
            CouponCode: '',
          },
        })
        .then(() => {
          this.props.setglobalLoaderStatus(false)
          browserHistory.replace('/')
        })
        .catch(() => {
          this.props.setglobalLoaderStatus(false)
        })
    }
  }

  handleUserAuth = () =>
    this.setState({
      loginPageId: 'ph_no',
    })

  render() {
    const isButtonDisabled = this.isButtonDisabled()
    let renderDiv
    let clientName = BrandSpecificConfig().ClientName
    let title = getTranslationText('common', 'welcomeTo') + ` ${clientName} ` + getTranslationText('common', 'care')
    let displayCaptcha = _.get(this, 'props.user.appConfigDetails.sendCaptcha')
    if (this.state.loginPageId === 'new_usr') {
      renderDiv = (
        <LoginContactDetails
          handleUserInputs={this.handleUserInputs}
          validateContactDetails={this.validateContactDetails}
          UsrName={this.state.UsrName}
          BtnLoaderStatus={this.state.BtnLoaderStatus}
          {...this.props}
        />
      )
      displayCaptcha = false
    } else if (this.state.loginPageId === 'otp_page') {
      renderDiv = (
        <LoginOTP
          checkOTPlength={this.checkOTPlength}
          checkOTP={this.checkOTP}
          sendOTPCounter={this.state.sendOTPCounter}
          otpValue={this.state.otpValue}
          {...this.props}
          frequentCalls={this.state.frequentCalls}
          showOTPTimer={this.state.showOTPTimer}
          showResendOTPbtn={this.state.showResendOTPbtn}
          showCallOptnBtn={this.state.showCallOptnBtn}
          otpViaCallTimer={this.state.otpViaCallTimer}
          showCallTimer={this.state.showCallTimer}
          otpImage={this.state.otpImage}
          updateOTPImage={this.updateOTPImage}
          disableResendOtp={this.state.disableResendOtp}
          BtnLoaderStatus={this.state.BtnLoaderStatus}
          Phno={this.state.Phno}
          id={'otp-input'}
          user={this.props.user}
          allowResend={this.state.allowResend}
          btnDisabled={isButtonDisabled}
          ResendOtpHandler={this.ResendOtpHandler}
        />
      )
    } else if (this.state.loginPageId === 'ph_no') {
      renderDiv = (
        <LoginPhNo
          handlePhNoInputChange={this.handlePhNoInputChange}
          validatePhno={this.validatePhno}
          Phno={this.state.Phno}
          phnoLengthAr={this.state.phnoLengthAr}
          selectedCountryObj={this.state.selectedCountryObj}
          handleCountryChange={this.handleCountryChange}
          termsAndCondCheck={this.state.termsAndCondCheck}
          handleTermsAndCondClick={this.handleTermsAndCondClick}
          {...this.props}
          BtnLoaderStatus={this.state.BtnLoaderStatus}
          handleRedirection={this.handleRedirection}
          invalidFormat={this.state.invalidFormat}
        />
      )
    } else {
      title = getTranslationText('common', 'authorize') + ` ${clientName} ` + getTranslationText('common', 'careToUse')
      renderDiv = <UserAuthLinkComponent user={this.props.user} handleUserAuth={this.handleUserAuth} />
    }
    let legalTxt = _.get(this, 'props.user.appConfigDetails.footerLinks.footerText')
    return (
      <div className='account-link-component mt48 mb48'>
        <div className='mb28 account-link-heading'>
          <LoginHeading title={title} />
        </div>
        {renderDiv}
        {displayCaptcha ? <Captcha recaptcha={recaptcha} /> : ''}
        <div className='mt20 ml-10 text-center'>
          {legalTxt && <span className='Note_Text bookFontStyle fontSmall' dangerouslySetInnerHTML={{ __html: legalTxt }} />}
        </div>
      </div>
    )
  }
}

export default AccountLinkComponent

AccountLinkComponent.propTypes = {
  generateOTPAsync: PropTypes.func.isRequired,
  verifyOTPAsync: PropTypes.func.isRequired,
  updateUserDetailsAsync: PropTypes.func.isRequired,
  setToaster: PropTypes.func.isRequired,
  getUserDetailsAsync: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  loadAppConfig: PropTypes.func.isRequired,
  getAppLegalConfigs: PropTypes.func.isRequired,
  storeLastKnowPathName: PropTypes.func.isRequired,
  setSelectedCountryData: PropTypes.func.isRequired,
  getAppConfig: PropTypes.func,
  setglobalLoaderStatus: PropTypes.func.isRequired,
  setTncLink: PropTypes.func.isRequired,
  setPrivacyLink: PropTypes.func.isRequired,
}
