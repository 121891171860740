import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { browserHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Modal } from 'sfy-react'
import {
  clearFields,
  removeDeviceDetails,
  setAllFields,
  setBrands,
  setDeviceDetails,
  setErrors,
  setFields,
  setIsSubscribed,
  setProducts,
  setSubscriptionDetails,
} from '../../../store/subscription'
import { setIsSSOFlow } from '../../../store/user'
import { getTranslationText } from '../../../utils/languageTranslation'
import AddDevice from './AddDevice'
import '../Subscription.scss'
import { setglobalLoaderStatus, setToaster } from '../../../store/toastsAndLoaders'
import { generateRandomString } from '../../../utils/constants'
import { postRequestAsync } from '../../../api/genericAPIs'
import pushToAnalytics from '../../../utils/Analytics'
import { ReactComponent as PlusCircle } from '../../../../public/commonAssets/plus-circle.svg'
import { ReactComponent as DownArrowCircle } from '../../../../public/commonAssets/down-arrow-circle.svg'

const SubscribePanel = (props) => {
  const dispatch = useDispatch()

  const fields = useSelector((state) => state.subscription.fields)
  const subscriptionDetails = useSelector((state) => state.subscription)
  const appConfigDetails = useSelector((state) => state.user.appConfigDetails)
  const consumerId = useSelector((state) => state.user.userDetails.consumerID)
  const userDetails = useSelector((state) => state.user)
  const planDetails = useSelector((state) => state.subscription.planDetails)
  const accessToken = useSelector((state) => state.user.consumerDetails?.data?.accessToken?.ID)
  const deviceDetails = useSelector((state) => state.subscription.deviceDetails)
  const errors = useSelector((state) => state.subscription.errors)

  const [showDevices, setShowDevices] = useState(false)
  const [showForm, setShowForm] = useState(false)
  const [isEditClicked, setIsEditClicked] = useState(false)
  const [currentDevice, setCurrentDevice] = useState(0)
  const [groupedDetails, setGroupedDetails] = useState()
  const [showMaximumDevicesModal, setShowMaximumDevicesModal] = useState(false)
  const [noConfirmationPopup, setNoConfirmationPopup] = useState(true)

  useEffect(() => {
    const handleBeforeUnloadEvent = (event) => {
      if (!noConfirmationPopup) {
        event.preventDefault()
        event.returnValue = 'Changes that you made may not be saved.'
        return 'Changes that you made may not be saved.'
      }
    }
    const handleUnloadEvent = () => {
      !noConfirmationPopup && dispatch(setSubscriptionDetails({ isSubscribed: false }))
    }
    window.addEventListener('unload', handleUnloadEvent)
    window.addEventListener('beforeunload', handleBeforeUnloadEvent)
    return () => {
      window.removeEventListener('unload', handleUnloadEvent)
      window.removeEventListener('beforeunload', handleBeforeUnloadEvent)
    }
  }, [dispatch, noConfirmationPopup])

  useEffect(() => {
    props.router.setRouteLeaveHook(props.route, (data) => {
      if (data.pathname.indexOf('/subscription') === -1 && !noConfirmationPopup) {
        const isConfirmed = window.confirm('Reload site? Changes that you made may not be saved.')
        if (isConfirmed) {
          dispatch(setSubscriptionDetails({ isSubscribed: false }))
          return true
        }
        return false
      }
      return true
    })
  }, [props.route, dispatch, props.router, noConfirmationPopup])

  useEffect(() => {
    if (!fields || !deviceDetails) {
      dispatch(clearFields())
    }
  }, [fields, deviceDetails, dispatch])

  useEffect(() => {
    let result = []
    if (deviceDetails?.length) {
      result = deviceDetails.reduce((r, a, index) => {
        r[a.category.value] = r[a.category.value] || []
        r[a.category.value].push({ ...a, id: index })
        return r
      }, Object.create(null))
    }
    setGroupedDetails(result)
  }, [deviceDetails])

  const handleOnChange = useCallback(
    (e, id) => {
      dispatch(setFields({ id, field: { [e.target.name]: e.target.value } }))
    },
    [dispatch]
  )

  const handleOnSubcategoryClick = useCallback(
    async (selectedItem, id, isEditClicked) => {
      try {
        dispatch(setglobalLoaderStatus(true))
        dispatch(setErrors({}))
        let response = await postRequestAsync(
          'ConsumerProduct/getPlanBrandsForSubcategories',
          {
            PlanArray: [planDetails.PlanDetails.PlanID],
            ProductSubCategoryID: selectedItem.ProductSubCategoryID,
            app: appConfigDetails.app,
            CountryID: window.CountryID,
          },
          {
            Authorization: accessToken,
          }
        )
        if (response?.length) {
          response = response.map((item) => ({ ...item, value: item.BrandName }))
          dispatch(setBrands(response))
        } else {
          dispatch(setBrands([]))
          dispatch(setErrors({ brand: { type: 'error', value: getTranslationText('error', 'noResultsFound') } }))
        }
        if (!isEditClicked) {
          handleOnChange({ target: { name: 'brand', value: '' } }, id)
          handleOnChange({ target: { name: 'product', value: '' } }, id)
        }
        dispatch(setProducts([]))
        dispatch(setglobalLoaderStatus(false))
      } catch (e) {
        console.log(e)
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        dispatch(setglobalLoaderStatus(false))
      }
    },
    [appConfigDetails, accessToken, dispatch, handleOnChange, planDetails]
  )

  const handleOnBrandClick = useCallback(
    async (selectedItem, id, isEditClicked) => {
      try {
        dispatch(setglobalLoaderStatus(true))
        dispatch(setErrors({}))
        let response = await postRequestAsync(
          'ConsumerProduct/getFilteredPlanProducts',
          {
            PlanArray: [planDetails.PlanDetails.PlanID],
            AdditionalFilter: 'Product',
            BrandID: selectedItem.BrandID,
            ProductSubCategoryID: fields[id].category.ProductSubCategoryID,
          },
          {
            Authorization: accessToken,
          }
        )
        if (response?.length) {
          response = response.map((item) => ({ ...item, value: item.ProductName }))
          dispatch(setProducts(response))
        } else {
          dispatch(setProducts([]))
          dispatch(setErrors({ product: { type: 'error', value: getTranslationText('error', 'noResultsFound') } }))
        }
        if (!isEditClicked) {
          handleOnChange({ target: { name: 'product', value: '' } }, id)
        }
        dispatch(setglobalLoaderStatus(false))
      } catch (e) {
        console.log(e)
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        dispatch(setglobalLoaderStatus(false))
      }
    },
    [fields, dispatch, accessToken, handleOnChange, planDetails]
  )

  const handleOnSaveClick = useCallback(() => {
    setShowForm(false)
    setIsEditClicked(false)
    if (!isEditClicked && Object.keys(fields).length === 5) {
      setShowMaximumDevicesModal(true)
    }
    dispatch(setDeviceDetails(currentDevice))
    const clevertapUserProfile = consumerId ? userDetails : {}
    const { EmailID } = clevertapUserProfile.userDetails || {}
    pushToAnalytics(
      'Plan Sales - Add Device',
      {
        'Logged In': consumerId ? 'Yes' : 'No',
        'Plan Name': planDetails.PlanDetails.PlanName,
        Subcategory: fields[currentDevice]?.category?.value,
        Brand: fields[currentDevice]?.brand?.value,
        Product: fields[currentDevice]?.product?.value,
        'Email ID': EmailID,
        ConsumerID: consumerId || '',
      },
      clevertapUserProfile
    )
  }, [currentDevice, consumerId, userDetails, fields, planDetails, isEditClicked, dispatch])

  const handleOnRemoveClick = useCallback(
    (id) => {
      setShowForm(deviceDetails?.length === 1)
      dispatch(removeDeviceDetails(id))
      setIsEditClicked(false)
    },
    [deviceDetails, dispatch]
  )

  const handleOnAddAnotherDeviceClick = () => {
    setNoConfirmationPopup(false)
    setCurrentDevice(deviceDetails.length)
    setShowForm(true)
    dispatch(setBrands([]))
    dispatch(setProducts([]))
  }

  const handleOnEditClick = async (device) => {
    dispatch(setAllFields())
    setCurrentDevice(device.id)
    setIsEditClicked(true)
    await handleOnSubcategoryClick(device.category, device.id, true)
    await handleOnBrandClick(device.brand, device.id, true)
  }

  const handleOnSubscribeClick = async () => {
    try {
      setNoConfirmationPopup(true)
      setShowMaximumDevicesModal(false)
      dispatch(setglobalLoaderStatus(true))
      dispatch(setIsSubscribed(true))
      await dispatch(setIsSSOFlow(true))
      const isLoginEnabled = appConfigDetails.isSSOEnabled
      let callbackURL = appConfigDetails.callbackURL
      if (isLoginEnabled && callbackURL && !consumerId) {
        localStorage.setItem('subscription', JSON.stringify(subscriptionDetails))
        callbackURL = `${callbackURL}state=${generateRandomString(10)}`
        pushToAnalytics(
          'Plan Sales - Login Initiated',
          {
            'Plan Name': planDetails.PlanDetails.PlanName,
          },
          userDetails
        )
        window.location.replace(callbackURL)
        dispatch(setglobalLoaderStatus(false))
        // browserHistory.replace('/subscription/review')
      } else {
        dispatch(setglobalLoaderStatus(false))
        browserHistory.replace('/subscription/review')
      }
    } catch (e) {
      console.log(e)
      dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
      dispatch(setglobalLoaderStatus(false))
    }
  }

  const handleShowDevices = () => {
    setNoConfirmationPopup(!noConfirmationPopup)
    setShowDevices(!showDevices)
    !isEditClicked && deviceDetails?.length === 0 && setShowForm(true)
  }

  return (
    <>
      <div className='subscription-wrapper'>
        <h4 className={` subscription-input-container`}>{getTranslationText('subscription', 'deviceProtection')}</h4>
        <div className={`pb20 subscription-input-container flex`}>
          <div className='clickable-link' onClick={handleShowDevices}>
            {showDevices ? <DownArrowCircle /> : <PlusCircle />}
          </div>
          <div style={window.isDirectionRTL ? { marginRight: '5px' } : { marginLeft: '5px' }}>
            <span className='clickable-link' onClick={handleShowDevices} title={getTranslationText('subscription', 'registerDevices')}>
              {getTranslationText('subscription', 'registerDevices')}
            </span>
            <span>{` ${getTranslationText('subscription', 'claimProcess')}`}</span>
          </div>
        </div>
        <div className={`${showDevices ? `show` : ``} device-form-container`}>
          {groupedDetails &&
            Object.keys(groupedDetails)?.length > 0 &&
            Object.keys(groupedDetails).map((key) => {
              const devices = groupedDetails[key]
              return (
                <Fragment key={key}>
                  <div className='group-container'>
                    {devices?.length > 0 && (
                      <img
                        className='group-details-image'
                        src={`${appConfigDetails.productImageUrl}${devices[0].category?.ProductSubCategoryID}.png`}
                        alt='category'
                      />
                    )}
                    <p className='pl8 m0'>
                      {key} <span className='group-details-length'>x {groupedDetails[key].length}</span>
                    </p>
                  </div>
                  {devices.map((device, index) => (
                    <Fragment key={device.id}>
                      {!(isEditClicked && currentDevice === device.id) ? (
                        <div className='subscription-device-container'>
                          <h6 key={device.brand?.value + device.product?.value}>
                            {device.brand?.value}&nbsp;|&nbsp;{device.product?.value}
                            {device.serialNumber && `${' '}| ${device.serialNumber}`}
                          </h6>
                          <Button
                            handleOnClick={() => {
                              handleOnEditClick(device)
                            }}
                            className='button-link subscription-button--view'
                          >
                            {getTranslationText('common', 'edit')}
                          </Button>
                          {index !== devices.length - 1 && <hr className='subscription-device--divider' />}
                        </div>
                      ) : (
                        isEditClicked &&
                        currentDevice === device.id && (
                          <AddDevice
                            id={currentDevice}
                            handleOnChange={handleOnChange}
                            handleOnSubcategoryClick={handleOnSubcategoryClick}
                            handleOnBrandClick={handleOnBrandClick}
                            handleOnSaveClick={handleOnSaveClick}
                            handleOnRemoveClick={handleOnRemoveClick}
                          />
                        )
                      )}
                    </Fragment>
                  ))}
                </Fragment>
              )
            })}
          {deviceDetails?.length > 0 && deviceDetails.length < 5 && !showForm && !isEditClicked && (
            <Button handleOnClick={handleOnAddAnotherDeviceClick} classes='button-ghost button-small subscription-button--add-another'>
              {getTranslationText('subscription', 'addAnotherDevice')}
            </Button>
          )}
          {showForm && !isEditClicked && (
            <AddDevice
              id={currentDevice}
              handleOnChange={handleOnChange}
              handleOnSubcategoryClick={handleOnSubcategoryClick}
              handleOnBrandClick={handleOnBrandClick}
              handleOnSaveClick={handleOnSaveClick}
              handleOnRemoveClick={handleOnRemoveClick}
            />
          )}
        </div>
        {(showDevices || deviceDetails?.length > 0) && (
          <div className={`subscription-save-container subscription-device-background`}>
            <h6 className='mb0'>{getTranslationText('device', 'totalDevices')}</h6>
            <h6 className='mb0'>{deviceDetails?.length}</h6>
          </div>
        )}
        <div className='subscription-button-container'>
          <Button isDisabled={errors?.eligibility?.message} handleOnClick={handleOnSubscribeClick} classes='button-primary'>
            {getTranslationText('subscription', 'subscribeNow')}
          </Button>
          <p className={`mt8 mb8 ${errors?.eligibility?.type || ''}`}>
            {errors?.eligibility?.message || getTranslationText('subscription', 'registerDevice')}
          </p>
        </div>
      </div>
      <Modal
        className='plan-modal'
        showModal={showMaximumDevicesModal}
        closeModal={() => {
          setShowMaximumDevicesModal(false)
        }}
        showCloseBtn={true}
      >
        <Modal.Header className='text-center mb20'>
          <img className='mt20' src={'/commonAssets/successImg.svg'} alt='success-alert' />
          <h4 className='mt20'>
            {getTranslationText('common', 'hurray')}! {getTranslationText('subscription', 'youHaveAddedDevices')} {deviceDetails?.length}{' '}
            {getTranslationText('login', 'devices')}
          </h4>
        </Modal.Header>
        <Modal.Body>
          <p className='mb20 text-center'>{getTranslationText('subscription', 'continueAddingMore')}</p>
        </Modal.Body>
        <Modal.Footer className='justify-center'>
          <Button classes='button-primary' handleOnClick={handleOnSubscribeClick}>
            {getTranslationText('subscription', 'subscribeNow')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default SubscribePanel
