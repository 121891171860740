import React, { Component } from 'react'
import './GuestRoute.scss'
import { Carousel, Button } from 'sfy-react'
import { browserHistory } from 'react-router'
import { CarePlus } from './PlanBenefitsSubComponents/CarePlus'
import { LostTheft, LostTheftTnc } from './PlanBenefitsSubComponents/LostTheft'
import { LostTheftNY, LostTheftNYTnc } from './PlanBenefitsSubComponents/LostTheftNY'
import { Essentials } from './PlanBenefitsSubComponents/Essentials'
import { CarePlusTnc } from './PlanBenefitsSubComponents/CarePlus'
import { EssentialsTnc } from './PlanBenefitsSubComponents/Essentials'

export default class PlanBenefitsComponent extends Component {
  constructor() {
    super()
    this.state = {
      homeBannersList: [
        { imgSrc: `/${appName}/images/mycare/benefits.png`, keySet: 'sc+1' },
        { imgSrc: `/${appName}/images/mycare/benefits.png`, keySet: 'sc+2' },
        { imgSrc: `/${appName}/images/mycare/benefits2.png`, keySet: 'sc+3' },
        { imgSrc: `/${appName}/images/mycare/benefits.png`, keySet: 'sc+4' },
      ],
      mobileHomeBannersList: [
        { imgSrc: `/${appName}/images/mycare/mobile_careplus.png` },
        { imgSrc: `/${appName}/images/mycare/mobile_careplus.png` },
        { imgSrc: `/${appName}/images/mycare/mobile_essential.png` },
        { imgSrc: `/${appName}/images/mycare/mobile_careplus.png` },
      ],
      bannerButton: [
        { btnText: 'Samsung Care+ with Theft and Loss', keySet: 'sc+1' },
        { btnText: 'Samsung Care+', keySet: 'sc+2' },
        { btnText: 'Samsung Care+ Essentials', keySet: 'sc+3' },
        { btnText: 'Theft and Loss Insurance (only available in NY)', keySet: 'sc+4' },
      ],
      activeTabButton: 'sc+1',
    }
  }

  componentWillMount() {
    window.scrollTo(0, 0)
  }

  faqButtonToggle = (activeTab = undefined) => {
    if (activeTab) {
      this.setState({
        activeTabButton: activeTab,
      })
    } else {
      this.setState({
        activeTabButton: 'sc+1',
      })
    }
  }

  render() {
    const { activeTabButton } = this.state
    const isSCplus = ['sc+1', 'sc+2', 'sc+4'].includes(activeTabButton)
    return (
      <div className='my-care-contianer'>
        <div className='padding-none desktop'>
          <Carousel
            items={this.state.homeBannersList.filter((item) => item)}
            individualBanner={() => (
              <div className=''>
                <img
                  className='full-width'
                  src={isSCplus ? `/${appName}/images/mycare/benefits.png` : `/${appName}/images/mycare/benefits2.png`}
                />
                <div className='plan-benefit-banner'>
                  <h1 className='color-white BannerHeader'>Plan Benefits</h1>
                  <Button className='plan-benefit-btn' text='Back to home' onClick={() => browserHistory.replace('/mycare')} />
                </div>
                <div className='col-lg-12 banner-tab-div'>
                  {this.state.bannerButton.map((item1, i) => (
                    <div
                      key={i}
                      className={'tab-item fontLarge' + (activeTabButton == item1.keySet ? ' tab-active' : '')}
                      onClick={() => this.faqButtonToggle(item1.keySet)}
                    >
                      {item1.btnText}
                    </div>
                  ))}
                </div>
              </div>
            )}
            showArrows={false}
            showDots={false}
            autoPlay={false}
          />
        </div>

        <div className='padding-none mobile'>
          <Carousel
            items={this.state.mobileHomeBannersList.filter((item) => item)}
            individualBanner={() => (
              <div className=''>
                <img
                  className='full-width'
                  src={isSCplus ? `/${appName}/images/mycare/mobile_careplus.png` : `/${appName}/images/mycare/mobile_essential.png`}
                />
                <div className='plan-benefit-banner'>
                  <h1 className='color-white fontExtraLarge'>Plan Benefits</h1>
                  <Button className='plan-benefit-btn' text='Back to home' onClick={() => browserHistory.replace('/mycare')} />
                </div>
                <div className='div-wrapper'>
                  <div className='col-lg-12 banner-tab-div'>
                    {this.state.bannerButton.map((item1, i) => (
                      <div
                        key={i}
                        className={'tab-item fontSmall' + (activeTabButton == item1.keySet ? ' tab-active' : '')}
                        onClick={() => this.faqButtonToggle(item1.keySet)}
                      >
                        {item1.btnText}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
            showArrows={false}
            showDots={false}
            autoPlay={false}
          />
        </div>
        {activeTabButton == 'sc+1' && (
          <>
            {' '}
            <LostTheft /> <LostTheftTnc />{' '}
          </>
        )}
        {activeTabButton == 'sc+2' && (
          <>
            {' '}
            <CarePlus /> <CarePlusTnc />{' '}
          </>
        )}
        {activeTabButton == 'sc+3' && (
          <>
            <Essentials /> <EssentialsTnc />{' '}
          </>
        )}
        {activeTabButton == 'sc+4' && (
          <>
            {' '}
            <LostTheftNY /> <LostTheftNYTnc />{' '}
          </>
        )}
      </div>
    )
  }
}
