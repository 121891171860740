import { connect } from 'react-redux'

import { setToaster, setglobalLoaderStatus } from '../../../store/toastsAndLoaders'
import { storeLastKnowPathName } from '../../../store/location'
import {
  getPurchasedPlansAsync,
  getEnrolledDevicesAsync,
  setSelectedPurchasedPlanDetails,
  clearMyPlanDetails,
  setDeviceAdditionModal,
  fetchCardDetailsAsync,
  setSoldPlanDetails,
} from '../../../store/myPlansReducer'
import {
  setPlansEligibleDevices,
  fetchConsumerProductsForEligiblePlansAsync,
  fetchAllConsumerProductsForEligiblePlansAsync,
  getBillingHistory,
} from '../../../store/devices'
import { getPayableAmountForNonLoginFlow } from '../../../store/payment'
import MyPlans from '../components/MyPlans'

const mapDispatchToProps = {
  storeLastKnowPathName,
  setToaster,
  setglobalLoaderStatus,
  getPurchasedPlansAsync,
  getEnrolledDevicesAsync,
  setSelectedPurchasedPlanDetails,
  clearMyPlanDetails,
  setPlansEligibleDevices,
  fetchConsumerProductsForEligiblePlansAsync,
  fetchAllConsumerProductsForEligiblePlansAsync,
  setDeviceAdditionModal,
  fetchCardDetailsAsync,
  getPayableAmountForNonLoginFlow,
  setSoldPlanDetails,
  getBillingHistory,
}

const mapStateToProps = (state) => ({
  user: state.user,
  myPlans: state.myPlans,
  location: state.location,
  toastsAndLoaders: state.toastsAndLoaders,
  translationData: state.languageDetail.translationText,
  devices: state.devices,
})

export default connect(mapStateToProps, mapDispatchToProps)(MyPlans)
