import React, { useCallback, useEffect, useState } from 'react'
import { browserHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Dropdown } from 'sfy-react'
import { getRequestAsync } from '../../../api/genericAPIs'
import lookup from '../../../utils/ipinfo'
import { setAppConfig, setCountries, setSelectedCountryData } from '../../../store/user'
import { setglobalLoaderStatus, setToaster } from '../../../store/toastsAndLoaders'
import PlanDetails from '../Subcomponents/PlanDetails'
import SubscribePanel from '../Subcomponents/SubscribePanel'
import './Details.scss'
import { getTranslationText } from '../../../utils/languageTranslation'
import { MediumButton } from '../../../components/UIComponents/Buttons/Buttons'
import { setErrors, setIsSubscribed } from '../../../store/subscription'

const Details = (props) => {
  const dispatch = useDispatch()

  const countries = useSelector((state) => state.user.countries)
  const isAnGuestUser = useSelector((state) => state.user.isAnGuestUser)
  const isSSOFlow = useSelector((state) => state.user.isSSOFlow)
  const selectedCountry = useSelector((state) => state.user.selectedCountryData)
  const appConfigDetails = useSelector((state) => state.user.appConfigDetails)
  const [showCountryModal, setShowCountryModal] = useState(false)
  const [country, setCountry] = useState({})
  const [allDetailsAvailable, setAllDetailsAvailable] = useState(false)

  useEffect(() => {
    const handleOnBeforeUnload = () => {
      dispatch(setErrors({}))
      !isSSOFlow && isAnGuestUser && dispatch(setSelectedCountryData({}))
    }
    window.addEventListener('unload', handleOnBeforeUnload)
    return () => {
      window.removeEventListener('unload', handleOnBeforeUnload)
    }
  }, [dispatch, isSSOFlow, isAnGuestUser])

  const getAppConfigDetails = useCallback(
    async (country) => {
      try {
        dispatch(setglobalLoaderStatus(true))
        if (!country.CountryCode) {
          const regionCode = await lookup()
          window.CountryCode = regionCode
          window.CountryID = undefined
        }
        const response = await getRequestAsync('Consumer/appConfig')
        dispatch(setCountries(response.Countries))
        dispatch(setAppConfig(response))
        if (!country.CountryCode) {
          let countryObj = response.Countries.find((val) => val.CountryCode === window.CountryCode)
          if (!countryObj && Array.isArray(response.Countries) && response.Countries.length === 1) {
            setShowCountryModal(true)
            countryObj = response.Countries[0]
            setCountry(countryObj)
          } else {
            dispatch(
              setSelectedCountryData({
                ...countryObj,
                label: '+' + countryObj.PhoneCode,
                value: countryObj.PhoneCode,
              })
            )
          }
        } else {
          setAllDetailsAvailable(true)
          dispatch(
            setSelectedCountryData({
              ...country,
              label: '+' + country.PhoneCode,
              value: country.PhoneCode,
            })
          )
        }
      } catch (e) {
        console.log(e)
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        dispatch(setglobalLoaderStatus(false))
      }
    },
    [dispatch]
  )

  useEffect(() => {
    const getInitialDetails = async () => {
      dispatch(setIsSubscribed(false))
      if (props.params?.id) {
        if (!selectedCountry || !Object.keys(selectedCountry).length) {
          await getAppConfigDetails(selectedCountry)
          dispatch(setglobalLoaderStatus(false))
        } else {
          setAllDetailsAvailable(true)
        }
      } else {
        browserHistory.replace('/')
      }
    }
    getInitialDetails()
  }, [props.params, getAppConfigDetails, selectedCountry, dispatch])

  const CountryValueComponent = useCallback(
    (props) => (
      <div className='country-value-container'>
        <img src={props.value.FlagImageURL} alt='' />
        <div className='country-label'>
          <span>{props.value.CountryName}</span>
        </div>
      </div>
    ),
    []
  )

  const CountryOptionComponent = useCallback(
    (props) => (
      <div className='country-value-container'>
        <img src={props.option.FlagImageURL} alt='' />
        <div className='country-label'>{props.option.CountryName}</div>
      </div>
    ),
    []
  )

  const handleCountrySelection = (country) => {
    if (country.CountryCode !== selectedCountry.CountryCode) {
      // call appConfig only when selected country has changed
      setCountry(country)
    }
  }

  const handleCountryClick = async () => {
    window.CountryCode = country.CountryCode
    window.CountryID = country.CountryID
    const fallback = 'en'
    let defaultLanguage = _.get(selectedCountry, 'countryConfig.LanguageCode.DEFAULT', fallback).toLowerCase()
    localStorage.setItem('languageCode', defaultLanguage)
    setShowCountryModal(false)
    await getAppConfigDetails(country)
    dispatch(setglobalLoaderStatus(false))
  }
  return (
    <div className='plan-subscription-container'>
      {allDetailsAvailable && (
        <PlanDetails params={props.params} location={props.location}>
          <SubscribePanel router={props.router} route={props.route} />
        </PlanDetails>
      )}
      <Modal showModal={showCountryModal} closeModal={() => {}} className='sfy-modal'>
        <Modal.Header>
          <h2 className='mb20 text-center'>{getTranslationText('store', 'countryModalHeader')}</h2>
        </Modal.Header>
        <div className={'close-icon ' + (window.isDirectionRTL ? ' text-left ' : ' text-right')}></div>
        <div className='col-sm-12 mb8'>
          {appConfigDetails && countries.length === 1
            ? getTranslationText('store', 'countryModalText')
            : getTranslationText('store', 'countriesModalText')}
        </div>
        <div className='col-sm-12'>
          <Dropdown
            id='subscription-dropdown'
            handleOnChange={(item) => handleCountrySelection(item)}
            value={country && Object.keys(country).length > 0 ? country : ''}
            placeholder={getTranslationText('common', 'searchByText')}
            options={
              appConfigDetails && countries.length
                ? countries.map((countryObj) => ({
                    ...countryObj,
                    label: countryObj.CountryName,
                    value: countryObj.CountryName,
                  }))
                : []
            }
            ValueComponent={country && Object.keys(country).length > 0 && CountryValueComponent}
            OptionsComponent={CountryOptionComponent}
            filterBy='CountryName'
            searchText={getTranslationText('common', 'search')}
            // isDisabled={appConfigDetails && countries.length === 1}
          />
        </div>
        <div className='col-sm-12'>
          <MediumButton className='button-small mt20' buttonText={getTranslationText('common', 'okay')} onClick={handleCountryClick} />
        </div>
      </Modal>
    </div>
  )
}

export default Details
