import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './SliderComponent.scss'

class SliderComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sliderState: document.readyState === 'complete',
      enableResizeOption: props.enableResizeOption,
      sliderSettings: {
        slidesToShow: Math.ceil(document.body.clientWidth / 450),
        slidesToScroll: Math.ceil(document.body.clientWidth / 450),
      },
    }
  }

  componentDidMount() {
    if (window.addEventListener && document.readyState !== 'complete') {
      window.addEventListener('load', this.setSliderStateFunc, false)
    }
    this.displayActiveSlide()
  }

  componentWillUnmount() {
    window.removeEventListener('load', this.setSliderStateFunc, false)
  }

  displayActiveSlide = () => {
    this.sliderRef &&
      this.props.activeSlideIndex &&
      this.state.sliderSettings.slidesToShow <= this.props.activeSlideIndex &&
      this.sliderRef.slickGoTo(this.props.activeSlideIndex)
  }

  setSliderStateFunc = () => {
    this.setState({
      sliderState: true,
    })
  }

  render() {
    let { customSettings } = this.props
    // console.log('customSettings', customSettings)
    let settings = {
      dots: false,
      infinite: false,
      speed: 500,
      autoplaySpeed: 4000,
      rtl: window.isDirectionRTL,
      ...customSettings,
    }
    if (this.state.enableResizeOption) {
      settings = {
        ...settings,
        ...this.state.sliderSettings,
      }
    }
    return (
      <Slider {...settings} ref={(ref) => (this.sliderRef = ref)}>
        {this.state.sliderState ? this.props.children : ''}
      </Slider>
    )
  }
}

SliderComponent.propTypes = {
  customSettings: PropTypes.object,
  children: PropTypes.node.isRequired,
  enableResizeOption: PropTypes.bool,
  activeSlideIndex: PropTypes.number,
  // user: PropTypes.object.isRequired
}

SliderComponent.defaultProps = {
  customSettings: {},
}

export default SliderComponent
