import { api } from '../config/variables'
import { setToaster } from './toastsAndLoaders'

export const SET_BENEFITS = 'SET_BENEFITS'

const ACTION_HANDLERS = {
  [SET_BENEFITS]: (state, action) => ({
    state,
    benefits: action.payload,
  }),
}

const initialState = {}

export const setBenefits = (payload) => {
  return {
    type: SET_BENEFITS,
    payload,
  }
}

export const fetchSoldPlanBenefits = (requestObj, authToken) => {
  let body = JSON.stringify({
    ...requestObj,
  })
  return (dispatch) => {
    return axios
      .post(api.coreUri + '/ConsumerProduct/sendSoldPlanBenefits', body, {
        headers: {
          Authorization: authToken,
          customHeader: true,
        },
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          const homePageData = response.data.data?.filter((benefit) => benefit.Status === 'ACTIVE')
          dispatch(setBenefits(homePageData))
          return response.data.data
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(err.message, 'error'))
          throw err
        }
      })
      .catch((err) => {
        dispatch(setToaster(err.message, 'error'))
      })
  }
}

export const markBenefitAsUsed = (requestObj, authToken) => {
  let body = JSON.stringify({
    ...requestObj,
  })
  return (dispatch) => {
    return axios
      .post(api.coreUri + '/ConsumerProduct/markBenefitAsUsed', body, {
        headers: {
          Authorization: authToken,
          customHeader: true,
        },
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          return response.data.data
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(err.message, 'error'))
          throw err
        }
      })
      .catch((err) => {
        dispatch(setToaster(err.message, 'error'))
      })
  }
}

export default function vouchersReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
