import { connect } from 'react-redux'
import { getNotificationsAsync, deleteNotificationsAsync, editNotificationAsync } from '../../../store/user'
import { setToaster, setglobalLoaderStatus } from '../../../store/toastsAndLoaders'

import Messages from '../components/Messages'
const mapDispatchToProps = {
  getNotificationsAsync,
  deleteNotificationsAsync,
  editNotificationAsync,
  setToaster,
  setglobalLoaderStatus,
}
const mapStateToProps = (state) => ({
  user: state.user,
  translationData: state.languageDetail.translationText,
})

export default connect(mapStateToProps, mapDispatchToProps)(Messages)
