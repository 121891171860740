import _ from 'lodash'
const translationData = require('../config/data-language.json')

const getTranslations = () => JSON.parse(localStorage.getItem('translationsData'))

const getTranslatedText = (translations, moduleName, label) => _.get(translations, ['data', moduleName, label])

export const getTranslationText = (moduleName, label, defaultText = '', placeHolder = '', placeHolderValue = '') => {
  let textFromApi = getTranslatedText(getTranslations(), moduleName, label)
  if (textFromApi) {
    return placeHolder && placeHolderValue ? textFromApi.replaceAll(placeHolder, placeHolderValue) : textFromApi
  }
  // @ToDo keep below console warning to make sure that all text comes from API
  // console.warn(`${moduleName}:${label} missing in API`)
  let textFromJson = getTranslatedText(translationData, moduleName, label)
  if (textFromJson) {
    return placeHolder && placeHolderValue ? textFromJson.replaceAll(placeHolder, placeHolderValue) : textFromJson
  }
  // @ToDo keep below console warning to make sure that all default text comes from the JSON
  // till API starts serving it
  // console.error(`${moduleName}:${label} missing in JSON`)
  return placeHolder && placeHolderValue ? defaultText.replaceAll(placeHolder, placeHolderValue) : defaultText
}
