import { api } from '../config/variables'

export const getRequestAsync = async (apiEndPoint, headers) => {
  try {
    const response = await axios.get(`${api.coreUri}/${apiEndPoint}`, {
      headers: {
        ...headers,
        customHeader: true,
      },
    })
    if (response.status === 200 && response.data.success) {
      return response.data && response.data.data
    }
    throw response.data
  } catch (e) {
    if (e.response && e.response.data) {
      const error = {
        data: {},
        msg: e.response.data.message || 'Something went wrong !',
      }
      throw error
    }
    if (!e.message) {
      e.data = {}
      e.msg = 'Something went wrong !'
      throw e
    } else {
      e.msg = e.message
    }
    throw e
  }
}

export const postRequestAsync = async (apiEndPoint, body, headers, uri) => {
  try {
    const response = await axios.post(`${uri || api.coreUri}/${apiEndPoint}`, body, {
      headers: {
        ...headers,
        customHeader: true,
      },
    })
    if (response.status === 200 && response.data.success) {
      return response.data && response.data.data
    }
    throw response.data
  } catch (e) {
    if (e.response && e.response.data) {
      const error = {
        data: {},
        msg: e.response.data.message || 'Something went wrong !',
      }
      throw error
    }
    if (!e.message) {
      e.data = {}
      e.msg = 'Something went wrong !'
      throw e
    } else {
      e.msg = e.message
    }
    throw e
  }
}
