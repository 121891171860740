import React, { Suspense } from 'react'
import { browserHistory, Router } from 'react-router'
import { Provider } from 'react-redux'
import PropTypes from 'prop-types'
// import { BrowserRouter, Switch, Route } from 'react-router-dom'
// import PaymentAcknowledgementPage from '../pages/PaymentAcknowledgementPage'

// const LegacyRoutes = ({ routes }) => <Router history={browserHistory} children={routes} />

// LegacyRoutes.propTypes = {
//   routes: PropTypes.object.isRequired
// }

const App = (props) => (
  <Provider store={props.store}>
    <div style={{ height: '100%' }}>
      <Suspense fallback={<div>Loading...</div>}>
        <Router history={browserHistory} children={props.routes} />
        {/* @ToDo Use the below pattern after testing */}
        {/* <BrowserRouter>
          <Switch>
            <Route exact path='/payment/acknowledgement' component={PaymentAcknowledgementPage} />
            <Route component={() => <LegacyRoutes routes={props.routes} />} />
          </Switch>
        </BrowserRouter> */}
      </Suspense>
    </div>
  </Provider>
)
App.propTypes = {
  store: PropTypes.object.isRequired,
  routes: PropTypes.object.isRequired,
}

export default App
