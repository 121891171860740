import React from 'react'
import PropTypes from 'prop-types'
import './AddressBox.scss'
import _ from 'lodash'
import { Loader } from '../UIComponents'
import { getTranslationText } from '../../utils/languageTranslation.js'
import { setAutocomplete } from '../../utils/location'

export default class AddressBox extends React.Component {
  constructor(props) {
    super(props)
    this.landmarkReference = React.createRef()
    this.autocompleteReference = React.createRef()
  }

  componentDidMount() {
    this.autocompleteReference.current = setAutocomplete(this.landmarkReference.current, this.handleOnLandmarkSelection, [
      _.get(this, 'props.user.selectedCountryData.CountryCode', 'IN').toLowerCase(),
    ])
  }

  componentWillUnmount() {
    window.google.maps.event.clearListeners(this.autocompleteReference.current, 'place_changed')
    document.getElementsByClassName('pac-container')[0] && document.getElementsByClassName('pac-container')[0].remove()
  }

  addFavouriteLocations = (e) => {
    let regex = e && e.target.value && new RegExp(e.target.value, 'gi')
    let favouriteLocations = document.getElementById('favourites')
    favouriteLocations && favouriteLocations.remove()
    let suggestions = document.getElementsByClassName('pac-container')[0]
    favouriteLocations = document.createElement('div')
    favouriteLocations.id = 'favourites'
    suggestions && suggestions.prepend(favouriteLocations)
    const item = document.createElement('div')
    item.className = 'pac-item user-location'
    item.innerHTML = getTranslationText('common', 'userLocation')
    favouriteLocations.appendChild(item)
    this.props.customLocations
      .filter((location) => !regex || regex.test(location.Landmark + '[' + location.AddressType + ']'))
      .forEach((location) => {
        const item = document.createElement('div')
        item.className = 'pac-item'
        item.addEventListener('mousedown', () => {
          let callback = this.props.handleUserAddressClick
          callback(location.ConsumerFavouriteLocationID)
          document.getElementsByClassName('pac-container')[0].style.zIndex = '-1'
        })
        item.innerHTML = `<span class="pac-icon pac-icon-marker"></span>
        <span>${location.Landmark} [${location.AddressType}]</span>`
        favouriteLocations.appendChild(item)
      })
  }

  handleOnLandmarkSelection = (value) => {
    this.props.handleTypeaheadSelect(value)
  }

  handleOnLandmarkChange = (e) => {
    if (document.getElementsByClassName('pac-container') && document.getElementsByClassName('pac-container').length > 0) {
      document.getElementsByClassName('pac-container')[0].style.zIndex = '1000'
    }
    this.props.onChangeTypeahead(e.target.value)
    this.props.customLocations && this.addFavouriteLocations(e)
  }

  render() {
    return (
      <div className='location-container'>
        <div className='location-label bookFontStyle mb8'>
          {this.props.locationLabel || getTranslationText('addressBox', 'selectLocation', 'Select Location')}
        </div>
        {this.props.additionalInfo && <div className='location-additional-info mb8 bookFontStyle'>{this.props.additionalInfo}</div>}
        <div id='input-container' className='input-container'>
          <div className='location-pin'>
            <img src={'/commonAssets/Request/location.png'} alt='location-pin' />
          </div>
          <input
            id='autocomplete-id'
            name='landmark'
            className='input'
            value={_.get(this, 'props.placesAutocomplete.landmark', '')}
            type='text'
            label='Landmark'
            placeholder={getTranslationText('addressBox', 'searchAreaPincode', 'Search Area or Pincode')}
            onChange={this.handleOnLandmarkChange}
            ref={this.landmarkReference}
          />
          <Loader loaderState={this.props.isLoaderSet} miniLoader className='loader' />
          {this.props.showDetect && (
            <button className='auto-detect-btn' id='auto-detect' type='button' onClick={this.props.handleDetectClick}>
              <img src={'/commonAssets/Request/detect.png'} alt='auto-detect' />
            </button>
          )}
        </div>
      </div>
    )
  }

  static propTypes = {
    placesAutocomplete: PropTypes.object.isRequired,
    customLocations: PropTypes.array,
    handleTypeaheadSelect: PropTypes.func.isRequired,
    handleDetectClick: PropTypes.func,
    onChangeTypeahead: PropTypes.func.isRequired,
    containerComponentName: PropTypes.string,
    isLoaderSet: PropTypes.bool.isRequired,
  }
}
