import uuid from 'node-uuid'
import { api } from '../config/variables'
import { setToaster } from './toastsAndLoaders'
import { BrandSpecificConfig } from '../utils/constants.js'
import { decrypt } from '../utils/AESEncrypt.js'
import { setPlanPaymentDetails } from './storeReducer' // eslint-disable-line
import moment from 'moment'
import _ from 'lodash'
import { getTranslationText } from '../utils/languageTranslation.js'
export const SET_PAYABLE_VALUE = 'SET_PAYABLE_VALUE'
export const SET_POST_PAYMENT = 'SET_POST_PAYMENT'
export const SET_STRIPE_SOURCE = 'SET_STRIPE_SOURCE'
export const MAKE_STRIPE_CHARGE = 'MAKE_STRIPE_CHARGE'
export const SET_3D_SECURE_POLL = 'SET_3D_SECURE_POLL'
export const CLEAR_3D_SECURE_POLL = 'CLEAR_3D_SECURE_POLL'
export const PAYMENT_POLL_STATUS = 'PAYMENT_POLL_STATUS'
export const PAYMENT_CLEAR_USER_DETAILS = 'PAYMENT_CLEAR_USER_DETAILS'
export const PAYMENT_STATUS_REQUEST = 'PAYMENT_STATUS_REQUEST'
export const SET_PAYMENT_ID = 'SET_PAYMENT_ID'
export const SET_TELR_CREATE_DETAILS = 'SET_TELR_CREATE_DETAILS'
export const SET_TELR_CHECK_DETAILS = 'SET_TELR_CHECK_DETAILS'
export const UNSET_CHARGE_PAYMENT_ID = 'UNSET_CHARGE_PAYMENT_ID'

export const clearPaymentDetails = () => {
  return {
    type: PAYMENT_CLEAR_USER_DETAILS,
  }
}

export const setPayableValue = (payload) => {
  return {
    type: SET_PAYABLE_VALUE,
    payload,
  }
}

export const postPaymentAction = (payload) => {
  return {
    type: SET_POST_PAYMENT,
    payload,
  }
}

export const setStripeSource = (payload) => {
  return {
    type: SET_STRIPE_SOURCE,
    payload,
  }
}

export const makeStripeCharge = (payload) => {
  return {
    type: MAKE_STRIPE_CHARGE,
    payload,
  }
}

export const set3dSecurePoll = (payload) => {
  return {
    type: SET_3D_SECURE_POLL,
    payload,
  }
}

export const clear3dSecurePoll = (payload) => {
  return {
    type: CLEAR_3D_SECURE_POLL,
    payload,
  }
}

export const setPaymentPollStatus = (payload) => {
  return {
    type: PAYMENT_POLL_STATUS,
    payload,
  }
}

export const setPaymentRequestStatus = (payload) => {
  return {
    type: PAYMENT_STATUS_REQUEST,
    payload,
  }
}

export const setPaymentID = (payload) => {
  return {
    type: SET_PAYMENT_ID,
    payload,
  }
}

export const setTelrCreateDetails = (payload) => {
  return {
    type: SET_TELR_CREATE_DETAILS,
    payload,
  }
}

export const setTelrCheckDetails = (payload) => {
  return {
    type: SET_TELR_CHECK_DETAILS,
    payload,
  }
}

export const unsetChargePaymentId = () => {
  return {
    type: UNSET_CHARGE_PAYMENT_ID,
  }
}

export const getPayableAmountForNonLoginFlow = (requestObj, storePayload) => {
  return (dispatch, getState) => {
    let body = JSON.stringify({
      ...requestObj.data,
      // CurrencyID: _.get(props, 'store.selectedPlanDetails.CurrencyID')
      CallbackUrl: requestObj.CallbackUrl || window.location.href.replace('store/checkout?step=1', 'payment?status=1'),
      Redirect_url: requestObj.Redirect_url || window.location.href.replace('store/checkout?step=1', 'payment?status=1'),
      WebAppPay: true,
      ExternalOriginSource: _.get(getState(), 'store.externalOriginSource', undefined) || undefined,
    })
    return axios
      .post(api.coreUri + '/ConsumerProduct/getPayableAmount', body, {
        headers: {
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(err.message, 'error'))
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          let pathName = getState().location.pathname
          let paymentResp = BrandSpecificConfig().isPaymentEncrypted ? decrypt(response.data.data) : response.data.data
          if (paymentResp.gateway === 'iyzipay' && !paymentResp.iyzipay && response.data.iyzipay) {
            console.log('altering response for iyzipay...')
            paymentResp.iyzipay = response.data.iyzipay
          }
          if (paymentResp.gateway === 'iyzipay' && paymentResp.iyzipay.status != 'success') {
            let err = new Error(paymentResp.iyzipay.errorMessage)
            dispatch(setToaster(err.message, 'error'))
            throw err
          }
          if (storePayload)
            paymentResp = {
              ...paymentResp,
              retryPayload: {
                ...requestObj,
                CallbackUrl: window.location.href.replace('store/checkout?step=1', 'payment?status=1'),
                Redirect_url: window.location.href.replace('store/checkout?step=1', 'payment?status=1'),
              },
            }
          dispatch(setPayableValue(paymentResp))
          if (pathName.includes('store')) {
            dispatch(setPlanPaymentDetails(paymentResp))
          }
          return response
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(err.message, 'error'))
          throw err
        }
      })
  }
}

export const getPayableAmountAsync = (requestObj) => {
  return (dispatch, getState) => {
    let body = JSON.stringify({
      ...requestObj.data,
      // CurrencyID: _.get(props, 'store.selectedPlanDetails.CurrencyID')
      CallbackUrl: window.location.href.replace('store/checkout?step=1', 'payment?status=1'),
      Redirect_url: window.location.href.replace('store/checkout?step=1', 'payment?status=1'),
      WebAppPay: true,
      ExternalOriginSource: _.get(getState(), 'store.externalOriginSource', undefined) || undefined,
    })
    return axios
      .post(api.coreUri + '/OnlinePayment/getPayableAmount', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID', undefined),
          customHeader: true,
        },
      })
      .catch((err) => {
        console.log('err - ', err)
        dispatch(setToaster(err.message, 'error'))
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          let pathName = getState().location.pathname
          let paymentResp = BrandSpecificConfig().isPaymentEncrypted ? decrypt(response.data.data) : response.data.data
          if (paymentResp.gateway === 'iyzipay' && !paymentResp.iyzipay && response.data.iyzipay) {
            console.log('altering response for iyzipay...')
            paymentResp.iyzipay = response.data.iyzipay
          }
          if (paymentResp.gateway === 'iyzipay' && paymentResp.iyzipay.status != 'success') {
            let err = new Error(paymentResp.iyzipay.errorMessage)
            dispatch(setToaster(err.message, 'error'))
            throw err
          }
          dispatch(setPayableValue(paymentResp))
          if (pathName.includes('store')) {
            dispatch(setPlanPaymentDetails(paymentResp))
          }
          return response
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(err.message, 'error'))
          throw err
        }
      })
  }
}

export const getIyzipayPaymentStatus = (requestObj) => {
  return (dispatch, getState) => {
    let body = JSON.stringify({
      ...requestObj,
    })
    return axios
      .post(api.coreUri + '/Payment/getIyziPaymentStatus', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          return response.data
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const getAmazonPaymentStatus = (requestObj) => {
  return (dispatch, getState) => {
    let body = JSON.stringify({
      ...requestObj,
    })
    return axios
      .post(api.coreUri + '/Payment/getAmazonPaymentStatus', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          return response.data
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const getBuckarooPaymentStatus = (requestObj) => {
  return (dispatch, getState) => {
    let body = JSON.stringify({
      ...requestObj,
    })
    return axios
      .post(api.coreUri + '/Payment/getBuckarooPaymentStatus', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          return response.data
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const getNoonpayPaymentStatus = (requestObj) => {
  return (dispatch, getState) => {
    return (
      axios
        // this is an external GET call for noonpay checkout status. so no /api/ in the uri
        .get(
          api.coreUri.replace('/api', '') + '/payment/noonpay/checkout',
          { params: requestObj },
          {
            headers: {
              Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
              customHeader: true,
            },
          }
        )
        .catch((err) => {
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        })
        .then((response) => {
          if (response.status === 200 && response.data.success) {
            return response.data
          } else {
            let err = new Error(response.data.msg)
            dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
            throw err
          }
        })
    )
  }
}

export const initTelr = (reqMethod) => {
  return (dispatch, getState) => {
    let payDetails = getState().payment.payableDetails
    let currency = getState().payment.payableDetails.CurrencyCode
    // let currency = _.get(getState(), 'planDetail.selectedPlanDetails.CurrencyCode', 'AED') ||
    //   _.get(getState(), 'user.selectedCountryData.CurrencyCode', 'AED')

    let appUrl = new URL(window.location.href)

    let returnUrl = appUrl
    let obj = {}
    if (reqMethod === 'create') {
      obj = {
        method: reqMethod,
        store: parseInt(payDetails.store),
        authkey: payDetails.auth_key,
        id: payDetails.id,
        order: {
          cartid: getState().user.consumerDetails.data.consumerID + '-' + uuid.v1(),
          test: process.env.NODE_ENV === 'production' ? 0 : 1,
          amount: payDetails.amount,
          // currency: 'AED',
          currency,
          description: getState().location.pathname,
        },
        return: {
          authorised: returnUrl + '?telrPayStatus=authorised',
          declined: returnUrl + '?telrPayStatus=declined',
          cancelled: returnUrl + '?telrPayStatus=cancelled',
        },
      }
    } else if (reqMethod === 'check') {
      obj = {
        method: reqMethod,
        store: parseInt(payDetails.store),
        authkey: payDetails.auth_key,
        id: payDetails.id,
        order: {
          ref: _.get(getState(), 'payment.telrPayCreateDetails.order.ref', undefined),
        },
      }
    }
    // alert('You will be redirected to payment page')
    return obj
  }
}

export const CheckOrCreateTelrPaymentAsync = (requestObj) => {
  let body = JSON.stringify({
    ...requestObj,
  })
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/TelrPayment/CreateOrCheckPayment', body, {
        headers: {
          Authorization: getState().user.consumerDetails && getState().user.consumerDetails.data.accessToken.ID,
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(err.message, 'error'))
        throw err
      })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.method === 'create') {
            dispatch(setTelrCreateDetails(response.data))
            window.location.replace(response.data.order.url)
          } else if (response.data.method === 'check') {
            dispatch(setTelrCheckDetails(response.data))
          }
          return response.data
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(err.message, 'error'))
          throw err
        }
      })
  }
}

export const addPaymentInfoAsync = () => {
  return (dispatch, getState) => {
    let body = {
      // common payload
      Source: BrandSpecificConfig().header,
      ConsumerID: getState().user.userDetails.consumerID,
      PaymentProcessID: getState().payment.payableDetails.id,
      id: getState().payment.payableDetails.id,
      AmountPaid: getState().payment.payableDetails.amount,
    }
    const lastpathName = getState().location.lastpathName.path
    if (lastpathName.includes('/request')) {
      // advance pay
      let requestReducer = getState().request
      const selectedPlan = _.get(requestReducer, 'selectedDamageType')
      const hasClaimForm = selectedPlan && selectedPlan.RequiresClaimForm
      const dateOfDamage = _.get(getState(), 'request.claimFormDetails.deviceDamageDate') || undefined
      let damageObj
      if (selectedPlan && hasClaimForm && _.get(selectedPlan, 'ClaimForm.RequiresTypeOfDamage')) {
        damageObj = requestReducer.claimFormDetails.typesOfDamage.find((obj) => obj.isSelected) || {}
      }
      const userLocation = requestReducer.userLocation
      const dateAndTime = requestReducer.dateAndTime
      let selectedIssuesList = undefined
      if (_.get(selectedPlan, 'RequiresIssueSelection', true)) {
        selectedIssuesList = requestReducer.selectedIssues ? requestReducer.selectedIssues.filter((issues) => issues.isSelected) : undefined
      }
      const claimRequestIDMapping = {
        1: 11,
        2: 12,
      }
      const { ConsumerProductID, ProductUniqueID, ProductSubCategoryID, ProductID, BrandID } = requestReducer.selectedDeviceInfo
      const serviceTypeID = requestReducer.selectedMode.ServiceTypeID
      const isAnDropoffFlow = _.includes([17, 23, 57, 58], serviceTypeID)

      let PartnerServiceLocationID = isAnDropoffFlow
        ? _.get(requestReducer, 'requestModesData.dropOffServicePartner.PartnerServiceLocationID')
        : _.get(requestReducer, 'partnerServiceLocation.PartnerServiceLocationID') ||
          _.get(requestReducer, 'requestModesData.PartnerServiceLocationID')
      let PartnerID = isAnDropoffFlow
        ? _.get(requestReducer, 'requestModesData.dropOffServicePartner.PartnerID')
        : _.get(requestReducer, 'partnerServiceLocation.PartnerID') || _.get(requestReducer, 'requestModesData.PartnerID')
      let DeliveryMode = isAnDropoffFlow
        ? _.get(requestReducer, 'requestModesData.dropOffServicePartner.DeliveryMode')
        : _.get(requestReducer, 'requestModesData.logisticPartner.isChooseLogisticsPartner')
        ? _.get(requestReducer, 'selectedVendor.VendorID')
        : _.get(requestReducer, 'requestModesData.DeliveryMode')

      body = {
        ...body,
        TransactionType: 'claim',
        APIToBeCalled: 'ConsumerServicerequest/createRequest',
        ConsumerProductID,
        ServiceTypeID: selectedPlan ? 10 : undefined,
        IncidentDate: hasClaimForm ? moment(dateOfDamage).format('YYYY-MM-DD') + 'T00:00:00.000Z' : undefined,
        RequiresClaimForm: hasClaimForm,
        PlanID: selectedPlan && selectedPlan.PlanID,
        SoldPlanID: selectedPlan && selectedPlan.SoldPlanID,
        SoldPlanCoverageID: selectedPlan && selectedPlan.SoldPlanCoverageID,
        PlanCoverageID: selectedPlan && selectedPlan.PlanCoverageID,
        consumerServiceRequestDetails: {
          lossDateTime: (() => {
            let dte = _.get(requestReducer, 'claimFormDetails.deviceDamageDate') || undefined
            let tme = _.get(requestReducer, 'claimFormDetails.deviceDamageTime') || undefined
            let lossDateTime = ''
            if (!dte && !tme) {
              lossDateTime = moment().format('YYYY-MM-DD')
            } else if (dte && !tme) {
              lossDateTime = moment(dte).format('YYYY-MM-DD')
            } else if (tme && !dte) {
              lossDateTime = moment(tme, 'hh:mm a').format()
            } else {
              dte = moment(dte).format('YYYY-MM-DD')
              lossDateTime = moment(`${dte} ${tme}`, 'YYYY-MM-DD hh:mm A').format()
            }
            return lossDateTime
          })(),
          damageId: _.get(damageObj, 'id') || 0,
          descriptionOfLoss: _.get(requestReducer, 'claimFormDetails.deviceDamageDescp') || undefined,
          placeOfDamage: _.get(requestReducer, 'claimFormDetails.deviceDamagePlace') || undefined,
          locationOfDamage: _.get(requestReducer, 'claimFormDetails.deviceDamagePlace') || undefined,
          deviceSwitchOn: _.get(requestReducer, 'claimFormDetails.deviceOnState') || undefined,
          placeOfDevice: _.get(requestReducer, 'claimFormDetails.deviceDamageLocation') || undefined,
        },
        ConsumerServiceRequest: {
          Address: userLocation.Address,
          AddressType: userLocation.AddressType,
          Landmark: userLocation?.Landmark || userLocation?.landmark,
          Lat: parseFloat(userLocation.latitude || userLocation.Lat),
          Lng: parseFloat(userLocation.longitude || userLocation.Lng),
          deliveryAddress: _.includes([1, 11, 3, 31, 17, 23, 57, 58, 43], serviceTypeID)
            ? {
                CountryID: getState().user.selectedCountryData.CountryID,
                ...requestReducer.deliveryLocation,
                Landmark: _.get(requestReducer, 'deliveryLocation.Landmark') || _.get(requestReducer, 'deliveryLocation.landmark'),
                Lat: (_.get(requestReducer, 'deliveryLocation.latitude') || _.get(requestReducer, 'deliveryLocation.Lat')).toString(),
                Lng: (_.get(requestReducer, 'deliveryLocation.longitude') || _.get(requestReducer, 'deliveryLocation.Lng')).toString(),
                Zipcode: _.get(requestReducer, 'deliveryLocation.pincode'),
                City: _.get(requestReducer, 'deliveryLocation.city'),
                State: _.get(requestReducer, 'deliveryLocation.state'),
                Address: _.get(requestReducer, 'deliveryLocation.Address'),
              }
            : undefined,
          Zipcode: userLocation.pincode,
          State: userLocation.state,
          StateCode: userLocation.stateCode,
          City: userLocation.city,
          PaymentProcessID: body.PaymentProcessID,
          SoldPlanID: selectedPlan && selectedPlan.SoldPlanID,
          ConsumerID: body.ConsumerID,
          IsNonPartner: _.get(requestReducer, 'partnerServiceLocation.IsNonPartner'),
          IsUrgent: false,
          ScheduledDateTime: dateAndTime && dateAndTime.date.date,
          ScheduledFromTime: dateAndTime && dateAndTime.time.StartTimeVal,
          ScheduledToTime: dateAndTime && dateAndTime.time.EndTimeVal,
          PartnerID,
          PartnerServiceLocationID,
          ConsumerServiceRequestID: 0,
          Documents: [],
          Issues: selectedIssuesList,
          ServiceTypeID: selectedPlan
            ? claimRequestIDMapping[serviceTypeID]
              ? claimRequestIDMapping[serviceTypeID]
              : serviceTypeID
            : serviceTypeID,
          SoldPlanCoverageID: selectedPlan && selectedPlan.SoldPlanCoverageID,
          PlanCoverageID: selectedPlan && selectedPlan.PlanCoverageID,
          ProductUniqueID,
          ProductID,
          ConsumerProductID,
          ProductSubCategoryID,
          BrandID,
          Reasons: [],
          DeliveryMode: [1, 17, 23, 52].includes(serviceTypeID) ? DeliveryMode : undefined,
        },
      }
    } else {
      // store payment
      let planPrice = {}
      let planInfo = getState().store.selectedPlanDetails
      let fetchedPlanPrice = getState().store.planPriceOnProductBasis
      planPrice = {
        PlanID: getState().store.selectedPlanDetails.PlanID,
        PlanPriceID: planInfo.PlanConfig.IsCustomPlan
          ? undefined
          : getState().store.selectedPlanDetails.planPrice.filter((price) => price.isSelected)[0].PlanPriceID,
        Answers: planInfo.PlanConfig.IsCustomPlan ? getState().store.postPaymentActionObj : undefined,
        PlanCode: planInfo.PlanConfig.IsCustomPlan ? planInfo.PlanCode : undefined,
      }
      if (planInfo.priceRange && fetchedPlanPrice && fetchedPlanPrice.PlanID) {
        // plan price fetched on product basis
        planPrice = {
          PlanID: fetchedPlanPrice.PlanID,
          PlanPriceID: fetchedPlanPrice.PlanPriceID,
        }
      }
      let currencyCode = _.get(getState(), 'payment.payableDetails.CurrencyCode', '')
      const isTaxAddress = !!_.get(getState(), 'store.taxAddress', undefined)
      const isCustomPlan = _.get(getState().store, 'selectedPlanDetails.PlanConfig.IsCustomPlan', undefined)
      body = {
        ...body,
        ConsumerProductID: getState().store.planConsumerProduct && getState().store.planConsumerProduct.ConsumerProductID,
        ExternalPlanCode: _.get(getState().store, 'purchaseEligibilityInfo.ExternalPlanCode', undefined),
        PlanDiscountConfigurationID:
          _.get(getState().store, 'selectedPaymentOption.maxDiscountOffer.DiscountCategory', '') === 'PromoCode'
            ? _.get(getState().store, 'selectedPaymentOption.appliedDiscountOffer.PlanDiscountConfigurationID', undefined)
            : _.get(getState().store, 'selectedPaymentOption.maxDiscountOffer.PlanDiscountConfigurationID', undefined),
        Status: getState().store.planConsumerProduct && getState().store.planConsumerProduct.ConsumerProductID ? 1 : 0,
        CurrencyID: _.get(getState().user, 'selectedCountryData.currencies[0].CurrencyID', undefined),
        IMEI: getState().store.planConsumerProduct.ProductUniqueID || undefined,
        StateCode: _.get(getState().store, 'selectedState.StateCode', undefined),
        PlanArray: [
          {
            ...planPrice,
            PlanPriceBillingConfigID: _.get(getState().store, 'selectedPaymentOption.PlanPriceBillingConfigID', undefined),
          },
        ],
        CountryID: getState().user.selectedCountryData?.CountryID || undefined,
        amount: getState().payment.payableDetails.amount || undefined,
        description: getState().payment.payableDetails.purpose || undefined,
        CurrencyCode: currencyCode ? currencyCode.toLowerCase() : undefined,
        currency: currencyCode ? currencyCode.toLowerCase() : undefined,
        ConsumerFavouriteLocationID: _.get(getState(), 'store.consumerAddress.ConsumerFavouriteLocationID', undefined),
        Zipcode: isTaxAddress
          ? _.get(getState(), 'store.taxAddress.PinCode', undefined)
          : _.get(getState(), 'store.consumerAddress.PinCode', undefined),
        ConsumerAddress: isTaxAddress
          ? {
              AddressLine1: _.get(getState(), 'store.taxAddress.UserLandmark', undefined),
              AddressLine2: _.get(getState(), 'store.taxAddress.Address', undefined),
              City: _.get(getState(), 'store.taxAddress.City', undefined),
              State: _.get(getState(), 'store.taxAddress.State', undefined),
              Zipcode: _.get(getState(), 'store.taxAddress.PinCode', undefined),
            }
          : _.get(getState(), 'store.consumerAddress', {}),
        PlanID: _.get(getState(), 'store.selectedPlanDetails.PlanID') || undefined,
        isSubscriptionPayment: _.get(getState(), 'store.selectedPaymentOption.PaymentModeCode', '') === 'EMI',
        PaymentMode: _.get(getState(), 'store.selectedPaymentOption.PaymentModeCode', undefined),
        hasConcession: _.get(this, 'props.store.selectedPaymentOption.maxDiscountOffer.hasConcession', undefined),
        Gateway: _.get(getState(), 'store.selectedPaymentOption.Gateway.Name', undefined),
        BrandID: _.get(getState(), 'store.purchaseEligibilityInfo.PlanDiscounts.ProductObj.BrandID', undefined),
        ProductID: _.get(getState(), 'store.purchaseEligibilityInfo.PlanDiscounts.ProductObj.ProductID', undefined),
        ProductSubCategoryID: _.get(getState(), 'store.purchaseEligibilityInfo.PlanDiscounts.ProductObj.ProductSubCategoryID', undefined),
        EmailID: _.get(getState(), 'user.userDetails.emailID', undefined),
        isDiscountTaxIncluded: _.get(getState().store, 'selectedPaymentOption.appliedDiscountOffer.isDiscountTaxIncluded', undefined),
        OrderID: _.get(getState().payment, 'payableDetails.OrderID', undefined),
        ProductPurchaseCost: isCustomPlan && _.get(getState().store, 'customPlanProductPrice', undefined),
        DeviceDateOfPurchase:
          isCustomPlan &&
          (_.get(getState().store, 'planConsumerProduct.DateOfPurchase', undefined) || _.get(getState().store, 'customPlanDOP', undefined)),
      }
    }
    body = {
      ...body,
      ExternalOriginSource: _.get(getState(), 'store.externalOriginSource', undefined) || undefined,
    }
    return axios
      .post(api.coreUri + '/OnlinePayment/addPaymentInfo', JSON.stringify(body), {
        headers: {
          Authorization: getState().user.consumerDetails.data.accessToken.ID,
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(err.message, 'error'))
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          return response.data.success
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(err.message, 'error'))
          throw err
        }
      })
  }
}

export const postPaymentActionNonLoginFlow = (reqObj) => {
  return (dispatch, getState) => {
    var body = JSON.stringify({
      ...reqObj,
      ExternalOriginSource: _.get(getState(), 'store.externalOriginSource', undefined) || undefined,
    })

    return axios
      .post(api.coreUri + '/ConsumerProduct/postPaymentAction', body, {
        headers: {
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(err.message))
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(postPaymentAction(true))
          return response.data.data
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(err.message, 'error'))
          throw err
        }
      })
  }
}

export const postPaymentActionAsync = (reqObj) => {
  return (dispatch, getState) => {
    let lastpathName = getState().location.lastpathName.path
    let planPrice = {}
    if (!['/track', '/request/confirm'].includes(lastpathName)) {
      let planInfo = getState().store.selectedPlanDetails
      let fetchedPlanPrice = getState().store.planPriceOnProductBasis
      planPrice = {
        PlanID: getState().store.selectedPlanDetails.PlanID,
        PlanPriceID: planInfo.PlanConfig.IsCustomPlan
          ? undefined
          : getState().store.selectedPlanDetails.planPrice.filter((price) => price.isSelected)[0].PlanPriceID,
        Answers: planInfo.PlanConfig.IsCustomPlan ? getState().store.postPaymentActionObj : undefined,
        PlanCode: planInfo.PlanConfig.IsCustomPlan ? planInfo.PlanCode : undefined,
      }
      if (planInfo.priceRange && fetchedPlanPrice && fetchedPlanPrice.PlanID) {
        // plan price fetched on product basis
        planPrice = {
          PlanID: fetchedPlanPrice.PlanID,
          PlanPriceID: fetchedPlanPrice.PlanPriceID,
        }
      }
    }
    let obj = !['/track', '/request/confirm'].includes(lastpathName)
      ? {
          StateCode: _.get(getState().store, 'selectedState.StateCode', undefined),
          ExternalPlanCode: _.get(getState(), 'store.purchaseEligibilityInfo.ExternalPlanCode'),
          PlanArray: [planPrice],
          PlanPriceID: planPrice.PlanPriceID || undefined,
          CurrencyID:
            _.get(getState(), 'store.planPriceOnProductBasis[0].CurrencyID') || _.get(getState(), 'store.selectedPlanDetails.CurrencyID'),
          AmountPaid: getState().payment.payableDetails.amount,
        }
      : {
          ConsumerServiceRequestID: getState().payment.payableDetails.ConsumerServiceRequestID,
          amount: getState().payment.payableDetails.amount,
        }
    let isCustomPlan = _.get(getState().store, 'selectedPlanDetails.PlanConfig.IsCustomPlan', undefined)
    var body = JSON.stringify({
      ...reqObj,
      OrderID: _.get(getState().payment, 'payableDetails.OrderID', undefined),
      ExternalPlanCode: _.get(getState().store, 'purchaseEligibilityInfo.ExternalPlanCode', undefined),
      PlanDiscountConfigurationID:
        _.get(getState().store, 'selectedPaymentOption.maxDiscountOffer.DiscountCategory', '') === 'PromoCode'
          ? _.get(getState().store, 'selectedPaymentOption.appliedDiscountOffer.PlanDiscountConfigurationID', undefined)
          : _.get(getState().store, 'selectedPaymentOption.maxDiscountOffer.PlanDiscountConfigurationID', undefined),
      isDiscountTaxIncluded: _.get(getState().store, 'selectedPaymentOption.appliedDiscountOffer.isDiscountTaxIncluded', undefined),
      BrandID: _.get(getState().store, 'purchaseEligibilityInfo.PlanDiscounts.ProductObj.BrandID', undefined),
      ProductID: _.get(getState().store, 'purchaseEligibilityInfo.PlanDiscounts.ProductObj.ProductID', undefined),
      ProductSubCategoryID: _.get(getState().store, 'purchaseEligibilityInfo.PlanDiscounts.ProductObj.ProductSubCategoryID', undefined),
      Source: BrandSpecificConfig().header,
      ConsumerID: getState().user.userDetails.consumerID,
      ConsumerProductID: !['/track', '/request/confirm'].includes(lastpathName)
        ? getState().store.planConsumerProduct && getState().store.planConsumerProduct.ConsumerProductID
        : undefined,
      PaymentProcessID: getState().payment.payableDetails.id,
      id: getState().payment.payableDetails.id,
      Status:
        lastpathName !== '/track'
          ? getState().store.planConsumerProduct && getState().store.planConsumerProduct.ConsumerProductID
            ? 1
            : 0
          : undefined,
      CurrencyID: _.get(getState().user, 'selectedCountryData.currencies[0].CurrencyID', undefined),
      ProductPurchaseCost: isCustomPlan && getState().store.customPlanProductPrice,
      DeviceDateOfPurchase:
        isCustomPlan && (_.get(getState().store, 'planConsumerProduct.DateOfPurchase', undefined) || getState().store.customPlanDOP),
      // gateway: getState().payment.payableDetails.Gateway,
      ...obj,
      PlanPriceBillingConfigID: _.get(getState(), 'store.selectedPaymentOption.PlanPriceBillingConfigID', undefined),
      Gateway: _.get(getState(), 'store.selectedPaymentOption.Gateway.Name', undefined),
      EmailID: _.get(getState().user, 'userDetails.emailID', undefined),
      ConsumerAddress: _.get(getState(), 'store.consumerAddress', undefined),
      ConsumerFavouriteLocationID: _.get(getState(), 'store.consumerAddress.ConsumerFavouriteLocationID', undefined),
      PaymentMode: _.get(getState(), 'store.selectedPaymentOption.PaymentModeCode', undefined),
      ExternalOriginSource: _.get(getState(), 'store.externalOriginSource', undefined) || undefined,
    })

    return axios
      .post(api.coreUri + '/OnlinePayment/postPaymentAction', body, {
        headers: {
          Authorization: getState().user.consumerDetails.data.accessToken.ID,
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(err.message))
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(postPaymentAction(true))
          return response.data.data
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(err.message, 'error'))
          throw err
        }
      })
  }
}

export const addStripeSourceAsync = (SourceID) => {
  return (dispatch, getState) => {
    var body = JSON.stringify({
      SourceID,
      PaymentProcessID: getState().payment.payableDetails.id,
    })

    return axios
      .post(api.coreUri + '/StripePayment/addSource', body, {
        headers: {
          Authorization: getState().user.consumerDetails.data.accessToken.ID,
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(err.message))
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          return dispatch(setStripeSource(response.data.data))
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(err.message, 'error'))
          throw err
        }
      })
  }
}

export const updatePaymentDetailsAsync = (reqObj) => {
  return (dispatch, getState) => {
    var body = JSON.stringify({
      ...reqObj,
    })

    return axios
      .post(api.coreUri + '/ConsumerProduct/updatePaymentDetails', body, {
        headers: {
          Authorization: getState().user.consumerDetails.data.accessToken.ID,
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(err.message))
        throw err
      })
      .then((response) => {
        if (response.status === 200) {
          return response
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(err.message, 'error'))
          throw err
        }
      })
  }
}

export const makeStripeChargeAsync = (intent) => {
  return (dispatch, getState) => {
    const isAdvanceExchangeMode =
      [65].includes(_.get(getState(), 'request.selectedMode.ServiceTypeID')) ||
      [65].includes(_.get(getState(), 'track.trackDetails.ServiceTypeID'))
    const consumerProductID =
      isAdvanceExchangeMode &&
      (_.get(getState(), 'request.selectedDeviceInfo.ConsumerProductID') || _.get(getState(), 'track.requestDetails.ConsumerProductID'))

    let lastpathName = getState().location.lastpathName.path
    let currencyCode = _.get(getState(), 'payment.payableDetails.CurrencyCode', undefined)
    if (!currencyCode) currencyCode = _.get(getState(), 'user.selectedCountryData.CurrencyCode', undefined)
    const isTaxAddress = !!_.get(getState(), 'store.taxAddress', undefined)

    let reqPayload = {
      isAdvanceExchange: isAdvanceExchangeMode || undefined,
      ConsumerProductID: consumerProductID || undefined,
      CountryID: getState().user.selectedCountryData?.CountryID || undefined,
      amount: getState().payment.payableDetails.amount,
      description: getState().payment.payableDetails.purpose,
      PaymentProcessID: getState().payment.payableDetails.id,
      ConsumerID: getState().user.userDetails.consumerID,
      id: getState().payment.payableDetails.id,
      Source: BrandSpecificConfig().header,
      CurrencyID: getState().user.selectedCountryData.currencies[0].CurrencyID,
      CurrencyCode: currencyCode ? currencyCode.toLowerCase() : undefined,
      currency: currencyCode ? currencyCode.toLowerCase() : undefined,
      ConsumerServiceRequestID: ['/track', '/request/confirm'].includes(lastpathName)
        ? getState().payment.payableDetails.ConsumerServiceRequestID
        : undefined,
      intentID: intent.id,
      ConsumerFavouriteLocationID: _.get(getState(), 'store.consumerAddress.ConsumerFavouriteLocationID', undefined),
      Zipcode: isTaxAddress
        ? _.get(getState(), 'store.taxAddress.PinCode', undefined)
        : _.get(getState(), 'store.consumerAddress.PinCode', undefined),
      ConsumerAddress: isTaxAddress
        ? {
            AddressLine1: _.get(getState(), 'store.taxAddress.UserLandmark', undefined),
            AddressLine2: _.get(getState(), 'store.taxAddress.Address', undefined),
            City: _.get(getState(), 'store.taxAddress.City', undefined),
            State: _.get(getState(), 'store.taxAddress.State', undefined),
            Zipcode: _.get(getState(), 'store.taxAddress.PinCode', undefined),
          }
        : _.get(getState(), 'store.consumerAddress', {}),
    } // common payload
    if (!['/track', '/request/confirm', '/devices', '/home', '/myplans'].includes(lastpathName)) {
      // plan specific
      if (!['/subscription/review'].includes(lastpathName)) {
        let planInfo = getState().store.selectedPlanDetails
        let hasWarrantyAPI = !!(planInfo.EntityDetails && planInfo.EntityDetails.length && planInfo.EntityDetails[0].WarrantyCheckDetails)
        let fetchedPlanPrice = getState().store.planPriceOnProductBasis
        let planPrice = {
          PlanID: getState().store.selectedPlanDetails.PlanID,
          PlanPriceID: getState().store.selectedPlanDetails.planPrice.filter((price) => price.isSelected)[0].PlanPriceID,
        }
        if (planInfo.priceRange && hasWarrantyAPI && fetchedPlanPrice && fetchedPlanPrice.PlanID) {
          // plan price fetched on product basis
          planPrice = {
            PlanID: fetchedPlanPrice.PlanID,
            PlanPriceID: fetchedPlanPrice.PlanPriceID,
          }
        }
        reqPayload = {
          ...reqPayload,
          PlanID: _.get(getState(), 'store.selectedPlanDetails.PlanID') || undefined,
          Zipcode:
            getState().store.Zipcode || _.get(getState(), 'store.taxAddress.PinCode') || _.get(getState(), 'store.consumerAddress.PinCode'),
          ConsumerProductID: getState().store.planConsumerProduct && getState().store.planConsumerProduct.ConsumerProductID,
          Status: getState().store.planConsumerProduct && getState().store.planConsumerProduct.ConsumerProductID ? 1 : 0,
          PlanArray: [planPrice],
          StateCode: _.get(getState().store, 'selectedState.StateCode', undefined),
          isSubscriptionPayment: _.get(getState(), 'store.selectedPaymentOption.PaymentModeCode', '') === 'EMI',
          ExternalPlanCode: _.get(getState(), 'store.purchaseEligibilityInfo.ExternalPlanCode'),
          PaymentMode: _.get(getState(), 'store.selectedPaymentOption.PaymentModeCode'),
          PlanPriceBillingConfigID: _.get(getState(), 'store.selectedPaymentOption.PlanPriceBillingConfigID', undefined),
          PlanDiscountConfigurationID:
            _.get(getState().store, 'selectedPaymentOption.maxDiscountOffer.DiscountCategory', '') === 'PromoCode'
              ? _.get(getState().store, 'selectedPaymentOption.appliedDiscountOffer.PlanDiscountConfigurationID', undefined)
              : _.get(getState().store, 'selectedPaymentOption.maxDiscountOffer.PlanDiscountConfigurationID', undefined),
          hasConcession: _.get(this, 'props.store.selectedPaymentOption.maxDiscountOffer.hasConcession', undefined),
          Gateway: _.get(getState(), 'store.selectedPaymentOption.Gateway.Name', undefined),
          BrandID: _.get(getState(), 'store.purchaseEligibilityInfo.PlanDiscounts.ProductObj.BrandID', undefined),
          ProductID: _.get(getState(), 'store.purchaseEligibilityInfo.PlanDiscounts.ProductObj.ProductID', undefined),
          ProductSubCategoryID: _.get(getState(), 'store.purchaseEligibilityInfo.PlanDiscounts.ProductObj.ProductSubCategoryID', undefined),
          EmailID: _.get(getState(), 'user.userDetails.emailID', undefined),
        }
      } else {
        delete reqPayload.Source
        const planDetails = getState().subscription.planDetails
        const planPrice = {
          PlanID: planDetails.PlanDetails.PlanID,
          PlanPriceID: planDetails.PlanDetails.planPrice[0].PlanPriceID,
        }
        let consumerProductDetails = getState().subscription.consumerProductDetails || []
        if (consumerProductDetails?.length) {
          consumerProductDetails = consumerProductDetails.map((cp) => cp.consumerProductID)
        }
        reqPayload = {
          ...reqPayload,
          PlanID: planDetails.PlanDetails.PlanID,
          Zipcode: _.get(getState(), 'store.taxAddress.PinCode'),
          ConsumerProductIDs: consumerProductDetails,
          Status: 1,
          PlanArray: [planPrice],
          isSubscriptionPayment: true,
        }
      }
    }
    let ServiceTypeID = _.get(getState(), 'request.selectedMode.ServiceTypeID')
    if ([65].includes(ServiceTypeID) && ['/track', '/request/confirm'].includes(lastpathName) && !reqPayload.Zipcode) {
      reqPayload = {
        ...reqPayload,
        Zipcode: _.get(getState(), 'request.userLocation.pincode', ''),
      }
    }
    if (isAdvanceExchangeMode && ['/track'].includes(lastpathName)) {
      reqPayload = {
        ...reqPayload,
        callReplacementFulfillment:
          _.get(getState(), 'track.trackDetails.coverageType') == 'EW' ||
          _.get(getState(), 'request.selectedDamageType.RequiresIssueSelection')
            ? true
            : undefined,
      }
    }
    let isAnAutoApprovalFlow = _.get(getState(), 'request.selectedDamageType.SkipClaimApproval')
    const csrID = _.get(getState(), 'track.trackDetails.ConsumerProduct.ConsumerServiceRequestID')
    if (ServiceTypeID && (isAnAutoApprovalFlow || csrID)) {
      switch (ServiceTypeID) {
        case 1:
          ServiceTypeID = 11
          break
        case 2:
          ServiceTypeID = 12
          break
        default:
          break
      }
    }
    var body = JSON.stringify({
      ...reqPayload,
      ServiceTypeID: ServiceTypeID || undefined,
      ExternalOriginSource: _.get(getState(), 'store.externalOriginSource', undefined) || undefined,
      isSubscriptionPayment: _.get(getState(), 'request.consentPaymentDetails.tokenizeFlow', undefined),
      tokenizeFlow: _.get(getState(), 'request.consentPaymentDetails.tokenizeFlow', undefined),
    })

    return axios
      .post(api.coreUri + '/StripePayment/charge', body, {
        headers: {
          Authorization: getState().user.consumerDetails.data.accessToken.ID,
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(err.message))
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          return dispatch(makeStripeCharge(response.data.data))
        } else {
          // dispatch(setToaster(response.data.msg))
          throw response?.data
        }
      })
      .catch((err) => {
        // dispatch(setToaster(err.message))
        throw err
      })
  }
}

export const pollPaymentStatusAsync = (SourceID) => {
  return (dispatch, getState) => {
    let lastpathName = getState().location.lastpathName.path
    let planPrice = {}
    if (lastpathName !== '/track') {
      let planInfo = getState().store.selectedPlanDetails
      let hasWarrantyAPI = !!(planInfo.EntityDetails && planInfo.EntityDetails.length && planInfo.EntityDetails[0].WarrantyCheckDetails)
      let fetchedPlanPrice = getState().store.planPriceOnProductBasis
      planPrice = {
        PlanID: getState().store.selectedPlanDetails.PlanID,
        PlanPriceID: getState().store.selectedPlanDetails.planPrice.filter((price) => price.isSelected)[0].PlanPriceID,
      }
      if (planInfo.priceRange && hasWarrantyAPI && fetchedPlanPrice && fetchedPlanPrice.PlanID) {
        // plan price fetched on product basis
        planPrice = {
          PlanID: fetchedPlanPrice.PlanID,
          PlanPriceID: fetchedPlanPrice.PlanPriceID,
        }
      }
    }
    var body = JSON.stringify({
      SourceID,
      PaymentProcessID: getState().payment.payableDetails.id,
      paymentID: SourceID,
      ConsumerID: getState().user.userDetails.consumerID,
      ConsumerProductID:
        lastpathName !== '/track'
          ? getState().store.planConsumerProduct && getState().store.planConsumerProduct.ConsumerProductID
          : undefined,
      id: getState().payment.payableDetails.id,
      Source: BrandSpecificConfig().ClientName,
      Zipcode: lastpathName !== '/track' ? getState().store.Zipcode : undefined,
      PlanArray: lastpathName !== '/track' ? [planPrice] : undefined,
      AmountPaid: lastpathName === '/track' ? getState().payment.payableDetails.amount : undefined,
      ConsumerServiceRequestID: lastpathName === '/track' ? getState().payment.payableDetails.ConsumerServiceRequestID : undefined,
      Status:
        lastpathName !== '/track'
          ? getState().store.planConsumerProduct && getState().store.planConsumerProduct.ConsumerProductID
            ? 1
            : 0
          : undefined,
      CurrencyID: getState().user.selectedCountryData.currencies[0].CurrencyID,
    })

    return axios
      .post(api.coreUri + '/StripePayment/pollPaymentStatus', body, {
        headers: {
          Authorization: getState().user.consumerDetails.data.accessToken.ID,
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(err.message))
        throw err
      })
      .then((response) => {
        if (response.data.success && !response.data.data) {
          dispatch(setToaster(response.data.msg))
        }
        return dispatch(setPaymentPollStatus(response.data))
      })
      .catch((err) => {
        dispatch(setToaster(err.message))
        throw err
      })
  }
}

export const advancePayAsync = (requestObj) => {
  return (dispatch, getState) => {
    let body

    let isTrackRoute = window.location.href.split('/track')
    var p = window.location.href.split('/request/')
    let redirect_url
    if (isTrackRoute && isTrackRoute.length > 1) {
      redirect_url = isTrackRoute[0] + '/payment?status=1'
    } else {
      redirect_url = p.length > 1 ? p[0] + '/payment?status=1' : window.location.href
    }

    body = JSON.stringify({
      ...requestObj,
      Redirect_url: redirect_url,
      CallbackUrl: redirect_url,
      WebAppPay: true,
    })

    return axios
      .post(api.coreUri + '/OnlinePayment/getAdvancePay', body, {
        headers: {
          Authorization: getState().user.consumerDetails.data.accessToken.ID,
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          // let paymentResp = (BrandSpecificConfig().isPaymentEncrypted
          //   ? decrypt(response.data.data)
          //   : response.data.data
          // )
          dispatch(setPayableValue(response.data.data))
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const getAdvancePaymentDetails = (requestObj) => {
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/ConsumerServicerequest/getAdvanceDetails', requestObj, {
        headers: {
          Authorization: getState().user.consumerDetails.data.accessToken.ID,
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          return response.data.data
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}
// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [PAYMENT_CLEAR_USER_DETAILS]: (state) => ({ ...initialState, chargePaymentId: state.chargePaymentId }),
  [SET_PAYABLE_VALUE]: (state, action) => ({
    ...state,
    payableDetails: action.payload,
    three_d_secure_src: '',
    stripeSource: state.pollFor3DSecure ? state.stripeSource : undefined,
    stripeCharge: state.pollFor3DSecure ? state.stripeCharge : undefined,
  }),
  [SET_POST_PAYMENT]: (state, action) => ({
    ...state,
    postPaymentDetails: action.payload,
  }),
  [SET_STRIPE_SOURCE]: (state, action) => ({
    ...state,
    stripeSource: action.payload,
  }),
  [MAKE_STRIPE_CHARGE]: (state, action) => ({
    ...state,
    stripeCharge: true,
    chargePaymentId: action.payload.PaymentID,
    status: action.payload.Status,
    holdAmount: action.payload.HoldAmount,
    isCardHoldSkipped: action.payload?.isCardHoldSkipped,
  }),
  [SET_3D_SECURE_POLL]: (state, action) => ({
    ...state,
    pollFor3DSecure: true,
    three_d_secure_src: action.payload,
  }),
  [CLEAR_3D_SECURE_POLL]: (state) => ({
    ...state,
    pollFor3DSecure: false,
    pollPaymentStatus: {},
  }),
  [PAYMENT_POLL_STATUS]: (state, action) => ({
    ...state,
    paymentPollStatus: action.payload,
  }),
  [PAYMENT_STATUS_REQUEST]: (state, action) => ({
    ...state,
    paymentRequestStatus: action.payload,
  }),
  [SET_PAYMENT_ID]: (state, action) => ({
    ...state,
    paymentID: action.payload,
  }),
  [SET_TELR_CREATE_DETAILS]: (state, action) => ({
    ...state,
    telrPayCreateDetails: action.payload,
  }),
  [SET_TELR_CHECK_DETAILS]: (state, action) => ({
    ...state,
    telrPayCheckDetails: action.payload,
  }),
  [UNSET_CHARGE_PAYMENT_ID]: () => ({ ...initialState }),
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  payableDetails: {},
  stripeSource: undefined,
  stripeCharge: undefined,
  pollFor3DSecure: false,
  three_d_secure_src: '',
  paymentPollStatus: {},
}

export default function paymentReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
