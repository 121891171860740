import React from 'react'
import PropTypes from 'prop-types'

const PrivacyPolicy = ({ path }) => (
  <div className={path}>
    <h2 style={{ textAlign: 'center', margin: '10px 0' }}>Service Lee Technologies Private Limited</h2>
    <p style={{ textAlign: 'center', margin: '10px 0' }}>
      Last updated: May 21<sup>st</sup>, 2018
    </p>
    <p>
      We believe You should always know what data We collect from You and how We use it, and that You should have meaningful control over
      both. We want to empower You to make the best decisions about the information that You share with Us
    </p>
    <p>
      The practices described in this policy are subject to applicable laws in the places in which We operate. This means that We only
      engage in the practices described in this policy in a particular country or region if permitted under the laws of Your country or
      region. Please contact Us if You have questions on Our practices in Your country or region.
    </p>
    <div>
      <table style={{ margin: '20px', width: '100%' }}>
        <tbody>
          <tr>
            <td>
              <a href='#general'>1. General</a>
            </td>
          </tr>
          <tr>
            <td>
              <a href='#information'>2. Information We Collect</a>
            </td>
          </tr>
          <tr>
            <td>
              <a href='#useOfInfo'>3. How We Use Your Information</a>
            </td>
          </tr>
          <tr>
            <td>
              <a href='#track'>4. Tracking &amp; Cookies</a>
            </td>
          </tr>
          <tr>
            <td>
              <a href='#shareAndDisclose'>5. Information We Share And Disclose</a>
            </td>
          </tr>
          <tr>
            <td>
              <a href='#retention'>6. Retention and Deletion Of Data</a>
            </td>
          </tr>
          <tr>
            <td>
              <a href='#dataProtection'>7. Data Protection Statement for European Union Users</a>
            </td>
          </tr>
          <tr>
            <td>
              <a href='#contactUs'>8. Contact Us</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <ol className='ol1'>
      <li className='li1' id='general'>
        <p className='terms-head'>General</p>
        <p>
          Service Lee Technologies Private Limited ("Servify" its subsidiaries and branch operations hereinafter referred as the "Company,"
          "Parent Company" "We," "Us," and "Our, ") collects information about You ("You", or "Your") when You use Our Application/s,
          Website/s, content, product/s, and service/s (collectively the "Services" and each an "Application") and through other
          interactions and communications You have with Us. We have Our copyright over this Privacy Policy. Use by third parties, even by
          way of extract, for commercial purposes is not allowed and any infringements may be subject to legal action.
        </p>
        <ol className='ol1'>
          <li className='li3'>
            <span className='s2'>
              This Privacy Policy explains how Servify collects and uses Your personal and non-personal information shared by You while
              registering Your account using Our Service/s or Our Website/s &amp; Application/s or such other websites and applications as
              may be developed by Servify from time to time ("Webiste/s &amp; Application/s"). This policy applies to all users of Our apps,
              websites, features or other services anywhere in the world.
            </span>
          </li>
          <li className='li3'>
            <span className='s2'>
              By using Our Website/s &amp; Application/s, You implicitly agree that We can collect, disclose and use Your information in
              accordance with the terms of this privacy policy ("Privacy Policy"). By visiting Our Website/s &amp; Application/s or using
              Our Services You agree to be bound by the terms and conditions of this Privacy Policy.
            </span>
          </li>
          <li className='li3'>
            <span className='s2'>
              We value the trust You place in Our Services. That's why We insist upon the highest standards for secure transactions and
              customer information privacy. Please read the following statement to learn about Our information gathering and dissemination
              practices.
            </span>
          </li>
          <li className='li3'>
            <span className='s2'>
              Please read this policy carefully to understand Servify's policies and practices regarding Your information and how Servify
              will treat it
            </span>
          </li>
          <li className='li3'>
            <span className='s2'>
              If You reside in United States of America,& nbsp;Servify (US) Inc., located at 1546 NW 56th Street, Seattle, WA 98107 will be
              the controller of Your personal data provided to, or collected by or for, or processed in connection with Our Services;
            </span>
          </li>
          <li className='li3'>
            <span className='s2'>
              If You reside in India or any other part of the world,&nbsp;Service Lee Technologies Pvt. Ltc, located at 1022, Building 10,
              Second Floor, Solitaire Corporate Park, Andheri East, Mumbai – 400 093, India will be the controller of Your personal data
              provided to, or collected by or for, or processed in connection with Our Services.
            </span>
          </li>
        </ol>
      </li>
      <li className='li1' id='information'>
        <p className='terms-head'>Information We Collect</p>
        <p>
          We receive and store any information You enter on Our website or give Us in any other way. You can choose not to provide certain
          information but then You might not be able to take advantage of many of Our features. We use the information that You provide for
          such purposes as responding to Your requests, helping You manage Your requests, improving Our platform, prevent or detect fraud or
          abuses of Our website, enable third parties to carry out technical, logistical or other functions on Our behalf and to communicate
          with You.
        </p>
        <p>
          {' '}
          <span className='s2'>
            We may collect certain Personal Information from You. 'Personal Information' is data that can be used to identify a person.
            ("Personal Information") including:
          </span>
        </p>
        <ol className='ol1'>
          <li className='li3'>
            <span className='s2'>
              <strong>USER PROFILE:</strong>
              <br />
              Your full name, address, email address, telephone number(s), date of birth and bank or payment account details and any proof
              of Your identity and/or address that We may request on case to case basis;{' '}
            </span>
          </li>
          <li className='li3'>
            <span className='s2'>
              <strong>TRANSACTION INFORMATION:</strong>
              <br />
              We collect transaction details related to Your use of Our services, including the type of services You requested or provided,
              Your order details, delivery information, date and time the service was provided, amount charged, and payment method. Payment
              Method includes:
              <ol className='ol1'>
                <li className='li3'>
                  Details of any bank account (including but not limited to, account holder, account name and account number) during any
                  -transaction/s initiated by You on Our Website/s &amp; Application/s while using Our Services;
                </li>
                <li className='li3'>
                  Details of any credit, debit or other card used by You for any transaction/s on Our Website/s &amp; Application/s or while
                  using Our Services;
                </li>
              </ol>
            </span>
          </li>
          <li className='li3'>
            <span className='s2'>
              <strong>LOCATION INFORMATION:</strong>
              <br />
              Depending on the Servify services that You use, request or search and Your app settings or device permissions, We may collect
              Your precise or approximate location information as determined through data and means such as GPS, IP address and WiFi. -
              Servify may collect location information when Servify App or it's partner applications are running in the foreground. In
              certain regions, Servify may also collect this information when the Servify App or it's partner applications are running in
              the background of Your device if this collection is enabled through Your app settings or device permissions.
            </span>
          </li>
          <li className='li3'>
            <span className='s2'>
              <strong>USAGE INFORMATION:</strong>
              <ol className='ol1'>
                <li className='li3'>
                  We collect information about how You interact with Our services. This includes information such as access dates and times,
                  app features or pages viewed, app crashes and other system activity, type of browser, and third-party sites or service You
                  were using before interacting with Our services.
                </li>
                <li className='li3'>
                  We also collect and use information regarding the duration of Your stay on Our Website/s &amp; Application/s &amp; this is
                  also stored in the session along with the date and time of Your access;
                </li>
                <li className='li3'>
                  When You use Servify's Website/s &amp; Application/s, Servify's servers (which may be hosted by a third party service
                  provider) may collect information indirectly and automatically about Your activities on the Website/s &amp; Application/s;
                  for instance by way of cookies, web beacons or web analytics. This anonymous information is maintained distinctly and is
                  not linked to the Personal Information You submit to Us and shall not be used to identify Your Account.
                </li>
                <li className='li3'>
                  In some cases, We collect this information through cookies, pixel tags, and similar technologies that create and maintain
                  unique identifiers. Web beacons are graphic image files embedded in a web page that provides information from the user's
                  browser. This allows Us to monitor and ascertain the number of users using the Website/s &amp; Application/s and selected
                  sponsors, advertiser website and for what purposes.
                </li>
                <li className='li3'>
                  Web analytics are services provided by third parties in connection with a website. We may use such services to find
                  usability problems in order to make Our Website/s &amp; Application/s easier to use. These services do not collect
                  information that has not been voluntarily provided by You. Accordingly, these services do not track Your browsing habits.
                  The information shared through these services will not identify You or Your Account.
                </li>
              </ol>
              <p style={{ margin: '10px 0' }}>
                To learn more about Our Cookie Policy,
                <a href='#track'>click here</a>
              </p>
            </span>
          </li>
          <li className='li3'>
            <span className='s2'>
              <strong>DEVICE INFORMATION:</strong>
              <br />
              We may collect and use information about the devices You use to access Our services, including the hardware models, device IP
              address, the type of browser (Internet Explorer, Firefox, Opera, Google Chrome, Safari etc.), operating systems and versions,
              software, file names and versions, preferred languages, unique device identifiers, advertising identifiers, serial numbers,
              device motion information, and mobile network information.
            </span>
          </li>
          <li className='li3'>
            <span className='s2'>
              <strong>COMMUNICATIONS DATA :</strong>
              <ol className='ol1'>
                <li className='li3'>
                  Servify receives some information regarding the calls or texts, including the date and time of the call/text, and the
                  content of the communications. Servify may also use this information for customer support services (including to resolve
                  disputes between users), for safety and security purposes, to improve Our products and services and for analytics.
                </li>
                <li className='li3'>
                  We also receive information on Your participation in any promotion sponsored by Us and Our Partner Brands;
                </li>
                <li className='li3'>Surveys that You complete through the Application or based on Our request;</li>
              </ol>
            </span>
          </li>
        </ol>
      </li>
      <li className='li1' id='useOfInfo'>
        <p className='terms-head'>How We Use Your Information</p>
        <p>We use the information that We collect for purposes including:</p>
        <ol className='ol1'>
          <li className='li3'>
            <span className='s2'>Providing services and features</span>
            <p>
              We use the information We collect to provide, personalize, maintain and improve Our products and services. This includes using
              the information to:
            </p>
            <ol className='ol1'>
              <li className='li3'>
                <span className='s2'>To create and update Your account</span>
              </li>
              <li className='li3'>
                <span className='s2'>To verify Your identity</span>
              </li>
              <li className='li3'>
                <span className='s2'>To track the progress of Your requests</span>
              </li>
              <li className='li3'>
                <span className='s2'>Administer Our Services and diagnose technical problems.</span>
              </li>
              <li className='li3'>
                <span className='s2'>
                  Perform internal operations necessary to provide Our services,
                  <br />
                  including to troubleshoot software bugs and operational problems, to conduct data analysis, testing, and research, and to
                  monitor and analyze usage and activity trends.
                </span>
              </li>
              <li className='li3'>
                <span className='s2'>
                  To respond to Your inquiries or fulfill Your requests for information
                  <br />
                  about the various services;
                </span>
              </li>
              <li className='li3'>
                <span className='s2'>
                  1. If You purchase any content or avail of any service from Us through one of these Website/s &amp; Application/s, to
                  complete and fulfill Your purchase, for example, to have Your payments processed, communicate with You regarding Your
                  purchase and provide You with related customer service;
                </span>
              </li>
            </ol>
          </li>
          <li className='li3'>
            <span className='s2'>Customer Support</span>
            <p>
              We use the information collected (including recordings of customer support calls after notifying You and with Your consent) to
              assist You when You contact Our customer support services, including to:
            </p>
            <ol className='ol1'>
              <li className='li3'>
                <span className='s2'>Direct Your questions to the appropriate customer support person</span>
              </li>
              <li className='li3'>
                <span className='s2'>Investigate and address Your concerns</span>
              </li>
              <li className='li3'>
                <span className='s2'>Monitor and improve Our customer support responses</span>
              </li>
            </ol>
          </li>
          <li className='li3'>
            <span className='s2'>Research and development</span>
            <p>
              We may use the information We collect for testing, research, analysis and product development. This allows Us to improve and
              enhance the safety and security of Our services, develop new features and products, and facilitate insurance and finance
              solutions in connection with Our services.
            </p>
          </li>
          <li className='li3'>
            <span className='s2'>Communication From Servify</span>
            <ol className='ol1'>
              <li className='li3'>
                <span className='s2'>
                  We use the information You provide to communicate about Our Products/Services and to send You information, materials, and
                  offers from Us;
                </span>
              </li>
              <li className='li3'>
                <span className='s2'>
                  To send You important information regarding the Website/s &amp; Application/s, changes to Our terms, conditions, and
                  policies and/or other administrative information;
                </span>
              </li>
              <li className='li3'>
                <span className='s2'>To send You surveys and marketing communications that We believe may be of interest to You;</span>
              </li>
              <li className='li3'>
                <span className='s2'>
                  To personalize Your experience on the Website/s &amp; Application/s by presenting advertising, products and offers We
                  believe would be tailored to You;
                </span>
              </li>
            </ol>
          </li>
          <li className='li3'>
            <span className='s2'>Other Uses</span>
            <p>The information collected from You is used:</p>
            <ol className='ol1'>
              <li className='li3'>
                <span className='s2'>
                  To provide it for scrutiny to any statutory, regulatory or any other governmental authority or any other agency as advised
                  by government or any judicial body;
                </span>
              </li>
              <li className='li3'>
                <span className='s2'>To resolve disputes;</span>
              </li>
              <li className='li3'>
                <span className='s2'>
                  To detect and protect Us against error, fraud and other criminal activity; enforce Our terms and conditions;
                </span>
              </li>
              <li className='li3'>
                <span className='s2'>
                  We identify and use Your IP address to help diagnose problems with Our servers if any, and to administer Our Website/s &
                  amp; Application/s. Your IP address is also used to help identify You and to gather broad demographic information;
                </span>
              </li>
              <li className='li3'>
                <span className='s2'>
                  To comply with financial services regulation including retention of financial information and transaction;
                </span>
              </li>
              <li className='li3'>
                <span className='s2'>
                  To conduct financial and identity checks; fraud prevention checks; anti money laundering and credit checks;
                </span>
              </li>
              <li className='li3'>
                <span className='s2'>To enhance security of Our Site; and</span>
              </li>
            </ol>
          </li>
        </ol>
      </li>
      <li className='li1' id='track'>
        <p className='terms-head'>Tracking &amp; Cookies</p>
        <p>
          A "cookie" is a small piece of information stored by a web server on a web browser so it can be later read back from that browser.
          Cookies are useful for enabling the browser to remember information specific to a given user. We may place both permanent and
          temporary cookies in Your computer's hard drive while using Our Services. These cookies do not contain any of Your personally
          identifiable information. You may choose to disable the cookies using the settings in Your operating system of the device.
          However, if You do so, You may not be able to use the full functionality of Our Website/ s &amp; Application/s
        </p>
        <p>Cookies may be used for the following purposes:</p>
        <ol className='ol1'>
          <li className='li3'>
            <span className='s2'>Customize Your experience on Our Website/s &amp; Application/s;</span>
          </li>
          <li className='li3'>
            <span className='s2'>
              For obtaining information regarding use of the Website/s &amp; Application/s and assess which pages do You visit the most in
              order to provide You with goods/services that may be of interest to You.
            </span>
          </li>
          <li className='li3'>
            <span className='s2'>
              To obtain information regarding Your IP address, location and other demographic information such as age range and gender in
              order to ascertain user's internet behaviour.
            </span>
          </li>
          <li className='li3'>
            <span className='s2'>
              Assist in complying with regulatory obligations such as anti-fraud and anti-money laundering obligations.
            </span>
          </li>
          <li className='li3'>
            <span className='s2'>
              For allowing Us to provide You with personalized services by remembering the choices made by You with respect to language You
              prefer, geographical location where You are etc.
            </span>
          </li>
          <li className='li3'>
            <span className='s2'>
              When You visit third party websites from Our Website/s &amp; Application/s, cookies embedded in such third party content may
              be downloaded onto Your device. We do not control such third party cookies and are governed by the terms and conditions of
              such third parties. Please read the terms and conditions governing such third party websites for more information on the same.
            </span>
          </li>
        </ol>
      </li>
      <li className='li1' id='shareAndDisclose'>
        <p className='terms-head'>Information We Share And Disclose</p>
        <p>
          Information about Our customers is an important part of Our business and We are not in the business of selling it to others. We
          may however, share Your information with Our affiliates, subsidiaries and business partners, for legal reasons or in connection
          with claims or disputes.
        </p>
        <p>
          We may disclose personal information that We collect or you provide, as described in this privacy policy, in the following ways:
        </p>
        <p>
          <strong>General Information Disclosures</strong>
        </p>
        <ol className='ol1'>
          <li className='li3'>
            <span className='s2'>
              To Our subsidiaries and affiliates, which are entities under common ownership or control of Our ultimate parent
              company&nbsp;Service Lee Technologies Private Limited.
            </span>
          </li>
          <li className='li3'>
            <span className='s2'>
              To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution or other sale
              or transfer of some or all of Servify's assets, whether as a going concern or as part of bankruptcy, liquidation or similar
              proceeding, in which personal information held by Servify about the users of Our Services are among the assets transferred.
            </span>
          </li>
          <li className='li3'>
            <span className='s2'>To fulfill the purpose for which you provide it.</span>
          </li>
          <li className='li3'>
            <span className='s2'>For any other purpose disclosed by us when you provide the information.</span>
          </li>
          <li className='li3'>
            <span className='s2'>
              <strong>Service Providers.</strong>&nbsp;We engage service providers to perform functions and provide services to us in the
              United States, Canada, and other countries. We may share Your information with outside vendors that We use for a variety of
              purposes, such as to send you emails and messages on behalf of other Servify members, push notifications to Your mobile device
              on Our behalf, provide voice recognition services to process Your spoken queries and questions, help us analyze use of Our
              Services, and process and collect payments. Some of Our products, services and databases are hosted by third party hosting
              services providers. We also may use vendors for other projects, such as conducting surveys or organizing sweepstakes for us.
              We may share information about you with these vendors only to enable them to perform their services.
            </span>
          </li>
          <li className='li3'>
            <span className='s2'>
              <strong>Payment Services Providers.</strong>
              We share Your payment information with payment services providers to process payments; prevent, detect, and investigate fraud
              or other prohibited activities; facilitate dispute resolution such as chargebacks or refunds; and for other purposes
              associated with the acceptance of credit and debit cards.
            </span>
          </li>
          <li className='li3'>
            <span className='s2'>
              <strong>Legal Purposes.</strong>&nbsp;We may share Your information when We believe in good faith that such sharing is
              reasonably necessary in order to investigate, prevent, or take action regarding possible illegal activities or to comply with
              legal process. We may also share Your information to investigate and address threats or potential threats to the physical
              safety of any person, to investigate and address violations of this Privacy Policy or the Terms of Service, or to investigate
              and address violations of the rights of third parties and/or to protect the rights, property and safety of Servify, Our
              employees, users, or the public. This may involve the sharing of Your information with law enforcement, government agencies,
              courts, and/or other organizations on account of legal request such as subpoena, court order or government demand to comply
              with the law.
            </span>
          </li>
          <li className='li3'>
            <span className='s2'>
              To enforce or apply Our Terms of Use [
              <a href='https://servify.tech/termsandconditions/'>https://servify.tech/termsandconditions/</a>] and other agreements,
              including for billing and collection purposes.
            </span>
          </li>
          <li className='li3'>
            <span className='s2'>
              If We believe disclosure is necessary or appropriate to protect the rights, property, or safety of Servify, Our customers or
              others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and
              credit risk reduction.
            </span>
          </li>
          <li className='li3'>
            <span className='s2'>
              <strong>Consent.</strong>&nbsp;We may share Your information in any other circumstances where We have Your consent.
            </span>
          </li>
        </ol>
      </li>
      <li className='li1' id='retention'>
        <p className='terms-head'>Retention and Deletion Of Data</p>
        <ol className='ol1'>
          <li className='li3'>
            <span className='s2'>
              After termination of Your Account with Us, We, shall destroy all the data collected from You, except the records and the data
              pertaining to the transactions undertaken using Our Services or the Website/s &amp; Application/s. Please note that under the
              applicable laws, We are required to maintain record of these transaction with Us including personal data such as Your name,
              contact details, Your number, transaction history etc. Other than the information specified above, We will delete all Personal
              Information that We hold about You when Your Account is terminated. We will not retain Your online banking login PIN, TAN
              and/or password, credit card information
            </span>
          </li>
          <li className='li3'>
            <span className='s2'>
              Please note that We will not require You to disclose Your personal or security detail vide email or other communication. If
              You receive any email/ phone call/ SMS or other communication requesting You to share Your personal/security/financial
              information, please do not respond to such requests and delete such requests immediately. Please forward such requests to
              <a href='mailto:data.protect@servify.tech'>data.protect@servify.tech</a> for notifying Us of the same prior to deletion of
              such requests.
            </span>
          </li>
          <li className='li3'>
            <span className='s2'>
              "Phishing" is an attempt to steal personal details and financial account details from a website user. Such "Phishers" use fake
              emails to lead users to counterfeit websites where the user is tricked into entering their personal details such as credit
              card numbers, passwords, PIN etc. Please abstain from providing Your details on such websites and immediately report such
              requests to Us at <a href='mailto:data.protect@servify.tech'>data.protect@servify.tech</a>. We shall not be held responsible
              for any loss, damage caused by responding to such emails and sharing information with third parties specified under{' '}
              <strong>Sections 4.2 and 4.3.</strong>
            </span>
          </li>
        </ol>
      </li>
      <li className='li1' id='dataProtection'>
        <p className='terms-head'>Data Protection Statement for European Union Users</p>
        <p>
          The European Union General Data Protection Regulation (GDPR) is a comprehensive data protection law that governs how entities
          operating in the EU, targeting products and services to individuals in the EU or monitoring the behavior of EU citizens, handle
          personal information. The GDPR is intended to unify and strengthen the protection of personal data of people residing within the
          member states of the European Union. More information is available at the EU GDPR website. The GDPR regulations will come into
          effect on May 25, 2018.
        </p>
        <p>EU General Data Protection Regulation (GDPR) has provided the below rights to the EU residents:</p>
        <ol className='ol1'>
          <li className='li3'>
            <span className='s2'>
              Right to information – including contact details to the DPO, the purposes for processing Personal Information and the rights
              of the individual.
            </span>
          </li>
          <li className='li3'>
            <span className='s2'>Right to erasure ("Right to be forgotten")</span>
          </li>
          <li className='li3'>
            <span className='s2'>Right to rectification</span>
          </li>
          <li className='li3'>
            <span className='s2'>Right to restriction of processing</span>
          </li>
          <li className='li3'>
            <span className='s2'>Right to data portability of the Personal Information supplied to Servify by the EU resident</span>
          </li>
          <li className='li3'>
            <span className='s2'>
              Right to object (opt-out) to processing based on interests or performance, profiling, direct marketing
            </span>
          </li>
          <li className='li3'>
            <span className='s2'>
              Rights related to automated decision making and profiling: The GDPR prevents automated decision-making without human
              intervention in certain circumstances, in particular for decisions that "produce a legal effect or a similarly significant
              effect on the individual."
            </span>
          </li>
        </ol>
      </li>
      <li className='li1' id='contactUs'>
        <p className='terms-head'>Contact Us</p>
        <p className='p3'>
          <span className='s2'>
            If You have any queries relating to the processing/ usage of information provided by You or Servify's Privacy Policy, You
            mayemail the Data Protection Officer (DPO) at <a href='mailto:data.protect@servify.tech'> data.protect@servify.tech</a>
          </span>
        </p>
      </li>
    </ol>
  </div>
)

PrivacyPolicy.propTypes = {
  path: PropTypes.string.isRequired,
}

export default PrivacyPolicy
