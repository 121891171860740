import React from 'react'
import './../GuestRoute.scss'

export const LostTheftNY = () => {
  const planBenfits = [
    {
      title: `Available to New York customers only`,
      text: `Theft and Loss only coverage is available only to NY customers`,
      img: `/${appName}/images/mycare/lostTheft/2.svg`,
      url: '',
    },
    {
      title: 'Same-Day Replacement and Set Up',
      text: `Keep calm. We'll replace your lost or stolen phone as soon as the same day and help you set it up.`,
      img: `/${appName}/images/mycare/lostTheft/3.svg`,
      url: '',
    },
    {
      title: '24/7 Expert Support',
      text: `
      Get immediate answers from a Samsung Care+ expert 24/7 via phone or online for device setup, content transfers, and connecting with other devices.`,
      img: `/${appName}/images/mycare/lostTheft/5.svg`,
      url: '',
    },
  ]
  const section1 = [
    { header: 'Tier 1', cost: ['$5.50', '$5.50', '$5.50'] },
    { header: 'Tier 2', cost: ['$5.50', '$5.50', '$5.50'] },
    { header: 'Tier 3', cost: ['$5.50', '$5.50', '$5.50'] },
    { header: 'Tier 4', cost: ['$5.50', '$5.50', '$5.50'] },
  ]

  const section1mobile = {
    tableHeader: ['Tier 1', 'Tier 2', 'Tier 3', 'Tier 4'],
    tableData: [
      {
        header: {
          img: [
            `/${appName}/images/mycare/products/pc.png`,
            `/${appName}/images/mycare/products/tablet.png`,
            `/${appName}/images/mycare/products/watch.png`,
          ],
          title: `PC's, Tablet, Watches`,
        },
        data: ['$5.50', '$5.50', '$5.50', '$5.50'],
      },
    ],
  }

  const section2mobile = {
    tableData: [
      {
        header: { title: 'Tier 1' },
        data: ['$99'],
      },
      {
        header: { title: 'Tier 2' },
        data: ['$149'],
      },
      {
        header: { title: 'Tier 3' },
        data: ['$149'],
      },
      {
        header: { title: 'Tier 4' },
        data: ['$199'],
      },
    ],
  }

  const section3 = [{ header: 'Deductible', cost: ['$99', '$149', '$149', '$199'] }]
  return (
    <div className='empty-route-container1 background-white'>
      <div className='row home-components1'>
        <div className='col-sm-12 col-lg-12 mb20 ml8'>
          <h1>Theft and Loss Insurance (only available in NY)</h1>
          <div className='fontExtraLarge'>
            Theft and Loss Insurance (only available in NY) replaces your device if lost or stolen with a low deductible fee.
          </div>
        </div>
        {planBenfits.map((item, index) => (
          <div key={index} className='col-sm-12 col-lg-4'>
            <div className='plan-benefit-section1'>
              <div className='plan-benefit-1'>
                <h4 className='boldFontStyle'>{item.title}</h4>
                <img src={item.img} />
              </div>
              <p className='fontLarge'>{item.text}</p>
            </div>
          </div>
        ))}
      </div>
      <div className='row home-components1 mt48 text-center'>
        <div className='col-sm-12 col-lg-12 mt48'>
          <h1>Monthly Recurring</h1>
          <h4 className='mtb14'>36-month term</h4>
        </div>

        <div className='flex-table-section desktop'>
          <div className='gaps'>
            <div className='table-header'>Device</div>
            <div className='table-container'>
              <div className='table-details-center flex boldFontStyle align-left'>
                <img src={`/${appName}/images/mycare/products/mobile.png`} />
                Phones
              </div>
              <br />
              <div className='table-details-center boldFontStyle align-left'>
                <img src={`/${appName}/images/mycare/products/tablet.png`} />
                Tablets
              </div>
              <br />
              <div className='table-details-center boldFontStyle align-left'>
                <img src={`/${appName}/images/mycare/products/watch.png`} />
                Watches
              </div>
            </div>
          </div>
          {section1.map((value, index) => (
            <div key={index} className='gaps'>
              <div className='table-header'>{value.header}</div>
              <div className='table-container'>
                {value.cost.map((v, i) => (
                  <>
                    <div className='table-details-center boldFontStyle' key={i}>
                      {v}
                    </div>
                    {value.cost.length == i + 1 ? '' : <br />}
                  </>
                ))}
              </div>
            </div>
          ))}
        </div>

        <div className='mobile'>
          <div className='table-header-section'>
            {section1mobile.tableHeader?.map((v, i) => (
              <div key={i} className='table-header-title'>
                {v}
              </div>
            ))}
          </div>
          {section1mobile.tableData?.map((v, i) => (
            <div key={i} className='flex-table-section-mobile'>
              <div className='table-details-center boldFontStyle'>
                {v.header.img.map((v, i) => (
                  <img key={i} src={v} />
                ))}
                {v.header.title}
              </div>
              <div className='table-data'>
                {v.data.map((v, i) => (
                  <div key={i} className='table-details-center font20 boldFontStyle'>
                    {v}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className='row home-components1 mt48 text-center'>
        <div className='col-sm-12 col-lg-12 mt48 mb28'>
          <h1>Samsung Care+ with Theft and Loss Deductible</h1>
        </div>
        <div className='flex-table-section desktop'>
          <div className='gaps'>
            <div className='table-header width20rem'>Device Tier</div>
            <div className='table-container width20rem'>
              <div className='table-details-center boldFontStyle fontLarge'>Tier 1</div>
              <br />
              <div className='table-details-center boldFontStyle fontLarge'>Tier 2</div>
              <br />
              <div className='table-details-center boldFontStyle fontLarge'>Tier 3</div>
              <br />
              <div className='table-details-center boldFontStyle fontLarge'>Tier 4</div>
            </div>
          </div>
          {section3.map((value, index) => (
            <div key={index} className='gaps'>
              <div className='table-header'>{value.header}</div>
              <div className='table-container'>
                {value.cost.map((v, i) => (
                  <>
                    <div className='table-details-center boldFontStyle' key={i}>
                      {v}
                    </div>
                    {value.cost.length == i + 1 ? '' : <br />}
                  </>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className='mobile'>
          {section2mobile.tableData?.map((v, i) => (
            <div key={i} className='flex-table-section-mobile'>
              <div className='table-details-center boldFontStyle'>{v.header.title}</div>
              <div className='table-data'>
                {v.data.map((v, i) => (
                  <div key={i} className='table-details-center font20 boldFontStyle'>
                    {v}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export const LostTheftNYTnc = () => {
  const tnc = [
    {
      title: 'What is covered:',
      conditions: [`Lost, stolen, and unrecoverable (excluding ADH).`],
      subtext: '',
    },

    {
      title: 'Eligible devices:',
      conditions: [`Phones, tablets, and watches.`],
      subtext: '',
    },

    {
      title: 'Service options:',
      conditions: [`Same-day replacement for lost or stolen devices based on location and subject to stock availability.`],
      subtext: '',
    },

    {
      title: 'Cancellation policy:',
      conditions: [`Cancel at any time for a prorated refund.`],
      subtext: '',
    },

    {
      title: 'Service fees',
      conditions: [
        `A non-refundable deductible or service fee will be charged for each approved claim. All applicable taxes and surcharges are extra. Offers may be modified or discounted at
      any time. Fees are based on device tiers.`,
      ],
      subtext: '',
    },

    {
      title: '',
      conditions: [],
      subtext: 'Eligible devices and available markets are subject to change at any time.',
    },
  ]

  const section2 = [
    {
      title: 'Enroll',
      text: `<p>Visit a Samsung store or go to <a href='https://samsungcareplus.com/' target='_blank'>samsungcareplus.com</a></p>`,
      img: `/${appName}/images/mycare/section3/6.svg`,
      url: '',
    },
    {
      title: 'Submit a claim',
      text: `<p>If your device is damaged or not working, visit samsung. <a href='https://samsung.servify.tech/us' target='_blank'>samsung.servify.tech/us</a> or call (833) 690-0918. 24 hours a day, 7 days a week. <br/> Claims must be filed within 30 days of the incident.</p>`,
      img: `/${appName}/images/mycare/section2/3.svg`,
      url: '',
    },
    {
      title: 'Contact 24/7 Expert Support',
      text: `<p>From set-up to troubleshooting, visit <a href='https://samsung.servify.tech/us' target='_blank'>samsung.servify.tech/us</a> or call (833) 690-0918.</p>`,
      img: `/${appName}/images/mycare/section3/3.svg`,
      url: '',
    },
  ]
  return (
    <>
      <div className='empty-route-container1'>
        <div className='row home-components1'>
          <div className='col-sm-12 col-lg-12 mb20'>
            <h1>How does it work?</h1>
          </div>
          {section2.map((item, index) => (
            <div key={index} className='col-sm-12 col-lg-4'>
              <div className='box-holder1 section3'>
                <img src={item.img} />
                <h4 className='boldFontStyle'>{item.title}</h4>
                <span className='fontLarge' dangerouslySetInnerHTML={{ __html: item.text }} />
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className='empty-route-container1 background-white'>
        <div className='row flex home-components1'>
          <div className='col-sm-12 col-lg-12'>
            <h1 className='mb28'>Plan Coverage Summary</h1>
            {tnc.map((item, index) => (
              <div key={index} className='terms-listing'>
                <div className='boldFontStyle fontLarge mb15'>{item.title}</div>
                <ul>
                  {item.conditions.map((value, index) => (
                    <li key={index} className='bookFontStyle fontLarge'>
                      {value}
                    </li>
                  ))}
                </ul>
                <div className='bookFontStyle fontLarge'>{item.subtext}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}
