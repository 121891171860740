import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { SelectDeviceComponent, SelectIssuesComponent } from './ServiceEstimatorSubComp'
import './ServiceEstimator.scss'
import { browserHistory } from 'react-router'
import _ from 'lodash'
import { getTranslationText } from '../../../utils/languageTranslation'

class ServiceEstimator extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pageState: 0,
      isAnAppleDevice: false,
      selectedDeviceData: {},
      addingDeviceslistbox: {
        categoryvalue: undefined,
        brandvalue: undefined,
        categoryenabled: false,
        placeholder: 'Select a device',
        brandenabled: false,
        productvalue: undefined,
        productenabled: false,
      },
      listofProducts: {
        categoryDataList: [],
        brandDataList: [],
        productDataList: [],
      },
      deviceRadiobtnvalue: null,
      selectFrombtnStatus: true,
      issuesList: [],
      estimatePrice: 0,
      estimatedPriceFlg: false,
      priceLvl: [],
      showEstimate: false,
      fetchingProductLoader: false,
    }
  }

  componentWillMount() {
    window.scrollTo(0, 0)
    if (_.get(this, 'props.devices.serviceEstimatorSelectedDevice.cpId')) {
      let device = _.find(this.props.devices.deviceDetails.supportedDevices, (obj) => {
        if (parseInt(obj.ConsumerProductID) === parseInt(this.props.devices.serviceEstimatorSelectedDevice.cpId)) {
          return obj
        }
      })
      if (device) {
        let { ProductSubCategory, ProductSubCategoryID } = device.ProductSubCategory
        let { BrandID, BrandName } = device.Brand
        let { ProductName, ProductID } = device.Product
        this.props.setglobalLoaderStatus(true)
        this.setState({
          isAnAppleDevice: BrandID === 4,
          defListValue: true,
          addingDeviceslistbox: {
            ...this.state.addingDeviceslistbox,
            categoryvalue: [{ text: ProductSubCategory, CategoryId: ProductSubCategoryID, value: ProductSubCategory }],
            brandvalue: [{ text: BrandName, BrandId: BrandID, value: BrandName }],
            productvalue: [{ text: ProductName, ProductId: ProductID, value: ProductName }],
          },
          SelectedDeviceCPId: device.ConsumerProductID,
          selectedDeviceData: device,
          selectFrombtnStatus: false,
          showEstimate: true,
          deviceRadiobtnvalue: device.IsUnderWarranty,
        })
        this.fetchIssuePriceList(BrandID, ProductSubCategoryID)
      } else {
        browserHistory.push('/service-estimator')
      }
    } else {
      browserHistory.push('/service-estimator')
    }
  }

  componentWillUnmount = () => {
    let qry = _.get(this, 'props.devices.serviceEstimatorSelectedDevice')
    this.props.setSeviceEstimatorDevice()
    if (qry && qry.cpId) {
      if (qry.from === 'mydevice') {
        browserHistory.push('/devices?cpid=' + window.btoa(qry.cpId).toString())
      } else if (qry.from === 'purchasehistory') {
        browserHistory.replace('/purchasehistory')
      }
    }
  }

  fetchIssuePriceList = (BrandID, ProductSubCategoryID) => {
    this.props.setglobalLoaderStatus(true)
    this.props
      .getPriceCardAsyn({
        BrandID,
        ProductSubCategoryID,
      })
      .then(() => {
        this.setState({
          priceCard: this.props.devices.priceCardDetails,
        })
        this.props
          .getIssuesListAsync({
            ProductSubCategoryID,
            AuthID: this.props.user.consumerDetails.data.accessToken.ID,
          })
          .then((response) => {
            if (response.length) {
              this.setState(
                {
                  estimatedPriceFlg: false,
                  estimatePrice: 0,
                  priceLvl: [],
                  showEstimate: true,
                  skipDeviceSelectionLoader: false,
                  issuesList: response.map((issue) => {
                    issue.text = issue.Issue
                    issue.isSelected = false
                    return { ...issue }
                  }),
                },
                () => {
                  this.props.setglobalLoaderStatus(false)
                  browserHistory.push('/service-estimator?id=estimate')
                }
              )
            }
          })
          .catch(() => {
            this.setState({
              skipDeviceSelectionLoader: false,
            })
            this.props.setglobalLoaderStatus(false)
          })
      })
      .catch(() => {
        this.setState({
          skipDeviceSelectionLoader: false,
        })
        this.props.setglobalLoaderStatus(false)
      })
  }

  handleDeviceSelection = (productObj) => {
    this.clearNewDeviceState()
    if (productObj.length) {
      var pId = productObj[0].ConsumerProductID
      this.setState({
        isAnAppleDevice: productObj[0].BrandID === 4,
      })
      if (pId === -1) {
        let categorydata = this.props.user.appConfigDetails.Products.map((data) => {
          return { text: data.ProductSubCategory, CategoryId: data.ProductSubCategoryID, value: data.ProductSubCategory }
        })
        this.setState(
          {
            defListValue: false,
            addingDeviceslistbox: {
              ...this.state.addingDeviceslistbox,
              brandvalue: {},
              productvalue: {},
              categoryenabled: true,
            },
            listofProducts: {
              ...this.state.listofProducts,
              categoryDataList: _.compact(categorydata),
            },
            deviceRadiobtnvalue: null,
            selectedDeviceData: {},
          },
          () => {
            if (_.get(this, 'state.listofProducts.categoryDataList', []).length === 1) {
              this.catergoryListHandler(this.state.listofProducts.categoryDataList)
            }
          }
        )
      } else {
        this.props.devices.deviceDetails.supportedDevices.find((productData) => {
          if (productData.ConsumerProductID === pId) {
            var { ProductSubCategory, ProductSubCategoryID } = productData.ProductSubCategory
            var { BrandID, BrandName } = productData.Brand
            var { ProductName, ProductID } = productData.Product
            this.setState({
              defListValue: true,
              addingDeviceslistbox: {
                ...this.state.addingDeviceslistbox,
                categoryvalue: [{ text: ProductSubCategory, CategoryId: ProductSubCategoryID, value: ProductSubCategory }],
                brandvalue: [{ text: BrandName, BrandId: BrandID, value: BrandName }],
                productvalue: [{ text: ProductName, ProductId: ProductID, value: ProductName }],
              },
              selectedDeviceData: productData,
              SelectedDeviceCPId: pId,
              selectFrombtnStatus: false,
              deviceRadiobtnvalue: productData.IsUnderWarranty,
            })
            this.props.setSeviceEstimatorDevice({ cpId: productData.ConsumerProductID, fromHome: true })
          }
        })
      }
    } else {
      this.setState({
        selectedDeviceData: {},
        selectFrombtnStatus: true,
        addingDeviceslistbox: {
          ...this.state.addingDeviceslistbox,
          categoryvalue: [],
          categoryenabled: false,
        },
        listofProducts: {
          ...this.state.listofProducts,
          categoryDataList: [],
        },
      })
      this.clearNewDeviceState()
    }
  }

  catergoryListHandler = (val) => {
    if (val.length && !this.state.defListValue) {
      var dummyobj = { ...this.state.addingDeviceslistbox }
      dummyobj.categoryvalue = val
      dummyobj.placeholder = 'Select a brand'
      // set list of brand for selected category
      let branddata
      this.props.user.appConfigDetails.Products.map((data) => {
        if (data.ProductSubCategoryID === val[0].CategoryId) {
          branddata = data.Brand.map((brand) => {
            return { text: brand.BrandName, BrandId: brand.BrandID, value: brand.BrandName }
          })
        }
      })
      dummyobj.brandenabled = true
      this.setState({
        listofProducts: {
          categoryDataList: [...this.state.listofProducts.categoryDataList],
          brandDataList: branddata,
          productDataList: [],
        },
        addingDeviceslistbox: {
          ...dummyobj,
          brandvalue: branddata && branddata.length === 1 ? branddata : [],
        },
      })
    } else {
      this.setState({
        addingDeviceslistbox: {
          ...this.state.addingDeviceslistbox,
          brandvalue: [],
          brandenabled: false,
          placeholder: 'Select a device',
          categoryvalue: undefined,
        },
        listofProducts: {
          ...this.state.listofProducts,
          brandDataList: [],
        },
      })
      this.clearNewDeviceState()
    }
  }

  brandListHandler = (val) => {
    if (val.length && !this.state.defListValue) {
      this.setState({
        isAnAppleDevice: val[0].BrandId === 4,
        fetchingProductLoader: this.state.listofProducts.productDataList && this.state.listofProducts.productDataList.length === 0,
      })
      var dummyobj = { ...this.state.addingDeviceslistbox }
      dummyobj.brandvalue = val
      this.props
        .getProductsList({
          categoryID: this.state.addingDeviceslistbox.categoryvalue[0].CategoryId,
          brandID: val[0].BrandId,
        })
        .then((response) => {
          let productData = response.map((data) => {
            return { text: data.ProductName, ProductId: data.ProductID, value: data.ProductName }
          })
          dummyobj.productenabled = true
          dummyobj.placeholder = 'Select a product'
          this.setState({
            fetchingProductLoader: false,
            listofProducts: {
              categoryDataList: [...this.state.listofProducts.categoryDataList],
              brandDataList: [...this.state.listofProducts.brandDataList],
              productDataList: _.compact(productData),
            },
            addingDeviceslistbox: dummyobj,
          })
        })
        .catch(() => {
          this.setState({
            fetchingProductLoader: false,
          })
        })
    } else {
      this.setState({
        addingDeviceslistbox: {
          ...this.state.addingDeviceslistbox,
          brandvalue: undefined,
          placeholder: 'Select a brand',
          productvalue: [],
          productenabled: false,
        },
        listofProducts: {
          categoryDataList: [...this.state.listofProducts.categoryDataList],
          brandDataList: [...this.state.listofProducts.brandDataList],
          productDataList: [],
        },
      })
      if (!this.state.defListValue) {
        this.setState({
          isAnAppleDevice: false,
        })
      }
      this.clearNewDeviceState()
    }
  }

  productListHandler = (val) => {
    // var flg
    if (val.length && !this.state.defListValue) {
      var dummyobj = { ...this.state.addingDeviceslistbox }
      dummyobj.productvalue = val
      dummyobj.placeholder = 'Select a product'
      this.setState({
        addingDeviceslistbox: dummyobj,
        selectFrombtnStatus: false,
        deviceRadiobtnvalue: false,
      })
      // flg = true
    } else {
      this.setState({
        addingDeviceslistbox: {
          ...this.state.addingDeviceslistbox,
          productvalue: undefined,
        },
      })
      this.clearNewDeviceState()

      // flg = false
    }
  }

  clearNewDeviceState = () => {
    this.setState({
      deviceRadiobtnvalue: null,
      selectFrombtnStatus: true,
      issuesList: this.state.issuesList.map((issue) => {
        issue.isSelected = false
        return {
          ...issue,
        }
      }),
      estimatePrice: 0,
      estimatedPriceFlg: false,
    })
  }

  setSelectedDeviceID = () => {
    let BrandID = this.state.addingDeviceslistbox.brandvalue[0].BrandId
    let ProductSubCategoryID = this.state.addingDeviceslistbox.categoryvalue[0].CategoryId
    this.fetchIssuePriceList(BrandID, ProductSubCategoryID)
  }

  // handleBackButton = () => {
  //   if (this.props.devices.serviceEstimatorSelectedDevice) {
  //     browserHistory.push('/devices?cpid=' +
  //       window.btoa(this.props.devices.serviceEstimatorSelectedDevice).toString())
  //   } else {
  //     browserHistory.push('/')
  //   }
  // }

  handlePillSelect = (e) => {
    let pillId = e.currentTarget.id.replace('issuesPill-', '')
    let ctr = 0
    this.state.issuesList.map((pill, index) => {
      if (index === parseInt(pillId) && !pill.isSelected) {
        ctr++
      }
      if (pill.isSelected && index !== parseInt(pillId)) {
        ctr++
      }
    })
    if (ctr < 5) {
      this.setState({
        estimatedPriceFlg: !(ctr === 0),
      })
      this.setState({
        issuesList: this.state.issuesList.map((pill, index) => {
          if (index === parseInt(pillId)) {
            let priceLvl = this.state.priceLvl
            if (pill.isSelected === false) {
              priceLvl.push(pill.IssueLevel)
              this.setState({
                priceLvl,
                estimatePrice: this.showPriceEstimate(priceLvl),
              })
              return {
                ...pill,
                isSelected: true,
              }
            } else {
              let hasRemoved = false
              priceLvl = _.remove(priceLvl, (obj) => {
                if (!hasRemoved) {
                  if (obj === pill.IssueLevel) {
                    hasRemoved = true
                  } else {
                    return obj
                  }
                } else {
                  return obj
                }
              })
              this.setState({
                priceLvl,
                estimatePrice: this.showPriceEstimate(priceLvl),
              })
              return {
                ...pill,
                isSelected: false,
              }
            }
          } else {
            return {
              ...pill,
            }
          }
        }),
      })
    } else {
      this.props.setToaster(getTranslationText('request', 'selectMaxIssues'), 'error')
    }
  }

  getPriceValue = (levelId) => {
    let price = 0
    let selectedPilllevel = parseInt(levelId)
    if (selectedPilllevel === 1) {
      price = parseInt(this.state.priceCard.Level1)
    } else if (selectedPilllevel === 2) {
      price = parseInt(this.state.priceCard.Level2)
    } else {
      price = parseInt(this.state.priceCard.Level3)
    }
    return price
  }

  showPriceEstimate = (levels) => {
    let range
    let max = 0
    let min = 0
    max = this.getPriceValue(_.max(levels))
    min = this.getPriceValue(_.min(levels))
    if (max && min && max !== min) {
      range = min + '-' + max
    } else {
      // max min same or one holds 0
      if (max && min === 0) {
        range = max
      } else if (min && max === 0) {
        range = min
      } else {
        range = max
      }
    }
    return range
  }

  handleNavClick = () => {
    browserHistory.push('/service-estimator')
  }

  render() {
    let paramQry = this.props.location.query
    let renderDiv = null
    if (paramQry && paramQry.id) {
      if (paramQry.id === 'estimate') {
        renderDiv = (
          <SelectIssuesComponent
            issuesList={this.state.issuesList}
            deviceInfo={this.state.selectedDeviceData}
            estimatePrice={this.state.estimatePrice}
            estimatedPriceFlg={this.state.estimatedPriceFlg}
            handlePillSelect={this.handlePillSelect}
            handleNavClick={this.handleNavClick}
            {...this.props}
          />
        )
      } else {
        browserHistory.replace('/pagenotfound')
      }
    } else {
      renderDiv = (
        <SelectDeviceComponent
          {...this.props}
          devicelist={this.props.devices.deviceDetails.supportedDevices}
          fetchingProductLoader={this.state.fetchingProductLoader}
          handleDeviceSelection={this.handleDeviceSelection}
          setSelectedDeviceID={this.setSelectedDeviceID}
          deviceInfo={this.state.selectedDeviceData}
          btnStatus={this.state.selectFrombtnStatus}
          listofProducts={this.state.listofProducts}
          listboxdata={this.state.addingDeviceslistbox}
          catergoryListHandler={this.catergoryListHandler}
          brandListHandler={this.brandListHandler}
          productListHandler={this.productListHandler}
          listofSupportedModes={this.state.listofSupportedModes}
          deviceRadiobtnvalue={this.state.deviceRadiobtnvalue}
          isAnAppleDevice={this.state.isAnAppleDevice}
        />
      )
    }
    return renderDiv
  }
}

export default ServiceEstimator

ServiceEstimator.propTypes = {
  devices: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  getProductsList: PropTypes.func.isRequired,
  addDeviceAsync: PropTypes.func.isRequired,
  getIssuesListAsync: PropTypes.func.isRequired,
  getPriceCardAsyn: PropTypes.func.isRequired,
  setToaster: PropTypes.func.isRequired,
  setglobalLoaderStatus: PropTypes.func.isRequired,
  setSeviceEstimatorDevice: PropTypes.func.isRequired,
}
