import { connect } from 'react-redux'
import FAQs from '../FAQs'
import { loadAppConfig } from '../../../store/user'
import { setglobalLoaderStatus } from '../../../store/toastsAndLoaders'

const mapDispatchToProps = {
  loadAppConfig,
  setglobalLoaderStatus,
}

const mapStateToProps = (state) => ({
  user: state.user,
})

export default connect(mapStateToProps, mapDispatchToProps)(FAQs)
