import React, { useState } from 'react'
import PropTypes from 'prop-types'
import './AccordionComponent.scss'
import { Accordion } from 'sfy-react'
import { ReactComponent as RightArrow } from '../../../../public/commonAssets/right-arrow.svg'
import { ReactComponent as DownArrow } from '../../../../public/commonAssets/down-arrow.svg'

const AccordionComponent = (props) => {
  const [showAccordion, setShowAccordion] = useState([])

  return (
    <div className={'row accordion-row'}>
      {props.data.length > 0 && (
        <div className={'col-sm-12 accordion-sect '}>
          {props.data.map((item, index) => {
            return (
              <Accordion
                showAccordion={showAccordion[index]}
                key={index}
                className={{
                  container: 'accordion-wrapper' + (!props.hasNode ? ' defaultStyles' : ''),
                  button: 'accordion-title fontSmall' + (window.isDirectionRTL ? ' acc-rtl-dir' : ''),
                }}
                title={
                  props.hasNode ? (
                    <div className='title-text'>{item.title}</div>
                  ) : (
                    <div className={'title-text ' + props.titleClassName} dangerouslySetInnerHTML={{ __html: item.title }} />
                  )
                }
                onClick={() => {
                  setShowAccordion((showAccordion) => {
                    let updatedShowAccordion = [...showAccordion]
                    updatedShowAccordion[index] = !showAccordion[index]
                    return updatedShowAccordion
                  })
                }}
                initialValue={0}
                openIcon={<DownArrow />}
                closeIcon={<RightArrow />}
              >
                <div className={'accordion-body'}>
                  {props.hasNode ? (
                    item.body
                  ) : (
                    <div className={'bookFontStyle fontSmall ' + props.subtextClassName} dangerouslySetInnerHTML={{ __html: item.body }} />
                  )}
                </div>
              </Accordion>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default AccordionComponent

AccordionComponent.propTypes = {
  data: PropTypes.any,
  titleClassName: PropTypes.string,
  subtextClassName: PropTypes.string,
  hasNode: PropTypes.bool,
}
