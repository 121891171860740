import { push } from 'react-router-redux'
import { guestUserLoggedIn, clearConsumerDetails, sendotpreport } from '../user'
import { storeLastKnowPathName } from '../location'
import { checkIfHasAccessToken } from '../../utils/functions'
import { HeadersTabsConst, NavHeadersConst } from '../../utils/constants'
import _ from 'lodash'
import { browserHistory } from 'react-router'

export default (store) => (next) => (action) => {
  const { payload } = action
  const urlParams = new URLSearchParams(window.location.search)
  let code = urlParams.get('code')
  let redirectionPath = urlParams.get('redirectionPath')
  let cprefid = urlParams.get('cprefid')
  let refid = urlParams.get('refid')
  let accessTokenFrmCookie = code || checkIfHasAccessToken()
  if (payload) {
    let consumer = _.get(store.getState(), 'user.consumerDetails', {})
    let selectedCountry = store.getState().user.selectedCountryData
    let userDetails = _.get(store.getState(), 'user.userDetails', {})
    let routesAvail = _.get(store.getState(), 'user.appConfigDetails.navBarHeaders', NavHeadersConst())
    routesAvail = [...routesAvail, 'Request', 'Track', 'Service estimator', 'Login', 'My Plans', 'Subscription', 'Vouchers']
    if (
      !(
        _.get(store.getState(), 'store.selectedBrand.BrandName', '').toUpperCase() !== 'APPLE' &&
        _.get(store.getState(), 'user.appConfigDetails.nonOtpPlanSale', false)
      )
    )
      routesAvail.push('Payment')
    let protectedRoutesRegex = _.compact(
      routesAvail.map((route) => {
        let routecontent = HeadersTabsConst(route)
        if (routecontent.isProtectedRoute) {
          return routecontent.routeRegex
        }
      })
    )
    let isLinkaccountAllowed = routesAvail.includes('link account')
    let isLinkaccount = !!/link-account/.test(payload.pathname)
    let consumerLinked = _.get(userDetails, 'isConsumerLinked', true)
    let isLandingPage = _.get(store.getState(), 'user.appConfigDetails.app', '') == 'SamsungUS-Web'

    if (/login/.test(payload.pathname)) {
      if (_.get(consumer, 'data.accessToken.ID') && selectedCountry) {
        if (consumer.data.isNew) {
          next(action)
        } else {
          if (redirectionPath) {
            if (redirectionPath == 'trackRequest') {
              browserHistory.push(`/track?refid=${refid}`)
            } else if (redirectionPath == 'myDevices' && cprefid) {
              browserHistory.push(`/devices?cpid=${cprefid}`)
            } else if (redirectionPath == 'myDevices') {
              browserHistory.push(`/devices`)
            } else if (redirectionPath == 'planCoverage') {
              browserHistory.push(`/devices?action=repair&cpid=${cprefid}`)
            } else {
              browserHistory.push('/')
            }
          } else {
            store.dispatch(push('/'))
          }
        }
      } else {
        if (payload.pathname === '/login') {
          next(action)
        } else {
          store.dispatch(clearConsumerDetails({}))
          store.dispatch(push('/login'))
        }
      }
    } else if (payload.pathname && _.filter(protectedRoutesRegex, (routeReg) => routeReg.test(payload.pathname))[0]) {
      if (
        (!accessTokenFrmCookie && !_.get(consumer, 'data.accessToken.ID')) ||
        (/link-account/.test(payload.pathname) && _.get(consumer, 'data.redirect'))
      ) {
        if (payload.pathname === '/') {
          if (isLandingPage) {
            store.dispatch(push('/mycare'))
            return
          }
        } else {
          store.dispatch(push('/login'))
          return
        }
      }
      if (accessTokenFrmCookie) {
        // user is not already logged in & has accessToken in cookie
        if (/^\/$/.test(payload.pathname) && !_.get(consumer, 'data.accessToken.ID')) {
          next(action)
          return
        }
        if (!/^\/$/.test(payload.pathname) && !userDetails.consumerID) {
          store.dispatch(storeLastKnowPathName({ path: payload.pathname + payload.search, obtainProfile: true }))
          store.dispatch(push('/'))
          return
        }
      }
      if (!selectedCountry) {
        store.dispatch(push('/login'))
      } else {
        const profileStatus = _.get(consumer, 'data.profileComplete') || !!(userDetails.name && userDetails.emailID)
        if (_.get(consumer, 'data.accessToken.ID') && (profileStatus || !consumerLinked || isLinkaccount)) {
          if (!profileStatus || !consumerLinked) {
            if (isLinkaccountAllowed && !isLinkaccount) {
              store.dispatch(push('/link-account'))
            }
          }
          !/payment/.test(payload.pathname) && store.dispatch(storeLastKnowPathName({}))
          next(action)
        } else {
          store.dispatch(sendotpreport({}))
          store.dispatch(push('/login'))
        }
      }
    } else if (
      /store/.test(payload.pathname) ||
      /about-us/.test(payload.pathname) ||
      /terms-conditions/.test(payload.pathname) ||
      /privacy-policy/.test(payload.pathname)
    ) {
      let flg = true
      if (_.get(consumer, 'data.accessToken.ID')) {
        const profileStatus = _.get(consumer, 'data.profileComplete') || !!(userDetails.name && userDetails.emailID)
        // store.dispatch(storeLastKnowPathName({}))
        if (!profileStatus || !consumerLinked) {
          if (isLinkaccountAllowed && !isLinkaccount && !/^\/$/.test(payload.pathname)) {
            store.dispatch(push('/link-account'))
          }
        }
        flg = !profileStatus
      } else if (accessTokenFrmCookie && /store/.test(payload.pathname)) {
        store.dispatch(storeLastKnowPathName({ path: payload.pathname + payload.search, obtainProfile: true }))
        store.dispatch(push('/'))
      }
      store.dispatch(guestUserLoggedIn({ status: flg }))
      next(action)
    } else {
      next(action)
    }
  } else {
    next(action)
  }
}
