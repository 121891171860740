import { connect } from 'react-redux'
import GuestRoute from '../GuestRoute'
import { loadAppConfig, setPrefilledUserDetails } from '../../../store/user'
import { setglobalLoaderStatus } from '../../../store/toastsAndLoaders'

const mapDispatchToProps = {
  loadAppConfig,
  setglobalLoaderStatus,
  setPrefilledUserDetails,
}

const mapStateToProps = (state) => ({
  user: state.user,
  location: state.location,
})

export default connect(mapStateToProps, mapDispatchToProps)(GuestRoute)
