import React from 'react'
import PropTypes from 'prop-types'
import SignatureCanvas from 'react-signature-canvas'
// import { base64ToImage } from '../../../utils/functions'
import './index.scss'

class SignatureComponent extends React.Component {
  constructor(props) {
    super(props)
    this.sigCanvas = ''
  }

  componentDidMount = () => {
    if (this.sigCanvas) {
      this.props.getSignCanvasRef({
        ...this.sigCanvas,
        isSignCompEmpty: this.isSignCompEmpty,
        getSignImageUrl: this.getSignImageUrl,
        setSignImageUrl: this.setSignImageUrl,
        clearSignature: this.clearSignature,
      })
    }
    if (this.props.signImgUrl) {
      this.setSignImageUrl()
    }
  }

  isSignCompEmpty = () => {
    return this.sigCanvas && this.sigCanvas.isEmpty()
  }

  getSignImageUrl = () => {
    if (this.sigCanvas && !this.isSignCompEmpty()) {
      let imgStr = this.sigCanvas.toDataURL()
      // return base64ToImage(imgStr)
      return imgStr && this.dataURLToBlob(imgStr)
    }
    return false
  }

  setSignImageUrl = () => {
    this.isSignCompEmpty() && this.sigCanvas.fromDataURL(this.props.signImgUrl)
  }

  clearSignature = () => {
    !this.isSignCompEmpty() && this.sigCanvas.clear()
    this.props.signCallBack()
  }

  dataURLToBlob(dataURL) {
    let parts = dataURL.split(';base64,')
    let contentType = parts[0].split(':')[1]
    let raw = window.atob(parts[1])
    let rawLength = raw.length
    let uInt8Array = new Uint8Array(rawLength)

    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i)
    }

    return new Blob([uInt8Array], { type: contentType })
  }

  // resizeCanvas = () => {
  //   let canvas = this.getCanvas()
  //   let ratio =  Math.max(window.devicePixelRatio || 1, 1);
  //   canvas.width = canvas.offsetWidth * ratio;
  //   canvas.height = canvas.offsetHeight * ratio;
  //   canvas.getContext("2d").scale(ratio, ratio);
  //   signaturePad.clear(); // otherwise isEmpty() might return incorrect value
  // }

  render() {
    let styles = {
      width: '310',
      height: '160',
      className: 'sigCanvas ' + (this.props.className || ''),
      penColor: 'black',
      ...this.props.styles,
    }
    return (
      <div className='signature-canvas-wrapper'>
        <span className='clear-sign' onClick={this.clearSignature}>
          x
        </span>
        <SignatureCanvas
          ref={(ref) => {
            this.sigCanvas = ref
          }}
          onEnd={this.props.signCallBack}
          penColor={styles.penColor}
          canvasProps={styles}
        />
      </div>
    )
  }
}
SignatureComponent.propTypes = {
  styles: PropTypes.object,
  className: PropTypes.string,
  signImgUrl: PropTypes.string,
  signCallBack: PropTypes.func.isRequired,
  getSignCanvasRef: PropTypes.func.isRequired,
}

export default SignatureComponent
