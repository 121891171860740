import { connect } from 'react-redux'
import {
  UserDevicePointsAsync,
  getProductsList,
  authenticateProductsAsync,
  addDeviceAsync,
  addProductDocAsync,
  getProductDocsAsync,
  updateDevicesAsync,
} from '../../../store/devices'
import {
  getDeviceOverviewAsync,
  updateUserDetailsAsync,
  setSelectedCountryData,
  getCountriesAsync,
  fetchBannersAsync,
  getUserDetailsAsync,
  loadAppConfig,
  getAppLegalConfigs,
  getSignedUrlAsync,
  setStoreDocs,
  getNotificationsAsync,
  setTncLink,
  setPrivacyLink,
  setDataConsentLink,
  generateRedirectionUrl,
  sendotpreport,
} from '../../../store/user'
import { setToaster, setglobalLoaderStatus } from '../../../store/toastsAndLoaders'
import { storeLastKnowPathName } from '../../../store/location'
import {
  getPayableAmountAsync,
  getPayableAmountForNonLoginFlow,
  initTelr,
  CheckOrCreateTelrPaymentAsync,
  postPaymentActionAsync,
  postPaymentActionNonLoginFlow,
} from '../../../store/payment'
import {
  getRequiredPaymentParamsAsync,
  getEligiblePlans,
  getPlansFromImei,
  setSelectedBrand,
  setPlanPaymentDetails,
  fetchEntityPlanAsync,
  logConsentsAsync,
  getSelectedPlanDetailsAsync,
  setCheckoutParams,
  setBuyPlanStatusAval,
  aysncFetchBrandID,
  addorFetchAsync,
  addorFetchDeviceAsync,
  fetchStoreBannersAsync,
  seteligibleDeviceInfo,
  validateZipcodeAsync,
  saveZipcodeOrState,
  setPurchaseEligibilityInfo,
  getPlanEligibilityStatus,
  getPlanEligibilityStatusForNonLoginFlow,
  validateUserDetails,
  checkPurchaseEligibility,
  getPlanPriceByIdentifiers,
  checkIfPaymentRequiredAsync,
  purchasePlanNoPaymentAsync,
  setSelectedPlan,
  setPlanConsumerProduct,
  setPlanActivated,
  fetchPlanQuestionsAsync,
  setPriorityQueueQuestion,
  setDependencyQueueQuestion,
  setCurrentQueueQuestion,
  setCustomPlanPrice,
  addCustomPlanProductPrice,
  addCustomPlanDOP,
  setPostPaymentActionObject,
  fetchIPIDAsync,
  fetchPlanProductsAsync,
  setFetchedPlanPriceByProduct,
  setPaymentOnActivation,
  setPurchasePlanNoPayment,
  fetchDiscountDetailsAsync,
  setSelectedPaymentOption,
  setConsumerAddress,
  setTaxAddress,
  validateAddressAsync,
  setDiscountDetails,
  setUndiscountedPlanPrice,
  setUserData,
  setImeiForFilter,
  setExternalOriginSource,
  decodeToken,
  setImeiFromToken,
} from '../../../store/storeReducer'
import { generateOTPAsync, verifyOTPAsync } from '../../Login/modules/login'
/*  This is a container component. Notice it does not contain any JSX,
    nor does it import React. This component is **only** responsible for
    wiring in the actions and state necessary to render a presentational
    component - in this case, the counter:   */

import Store from '../components/Store'

const mapDispatchToProps = {
  getDeviceOverviewAsync,
  UserDevicePointsAsync,
  getProductsList,
  fetchEntityPlanAsync,
  logConsentsAsync,
  // getStateListAsync,
  getRequiredPaymentParamsAsync,
  getSignedUrlAsync,
  addProductDocAsync,
  getProductDocsAsync,
  getEligiblePlans,
  getPlansFromImei,
  initTelr,
  CheckOrCreateTelrPaymentAsync,
  postPaymentActionAsync,
  postPaymentActionNonLoginFlow,
  getNotificationsAsync,
  clearExistingPlansRelatedInfo: () => setSelectedPlan(),
  getPlanEligibilityStatus,
  getPlanEligibilityStatusForNonLoginFlow,
  validateUserDetails,
  checkPurchaseEligibility,
  getPlanPriceByIdentifiers,
  checkIfPaymentRequiredAsync,
  purchasePlanNoPaymentAsync,
  setSelectedPlan,
  clearexistingPaymentDetails: () => setPlanPaymentDetails({}),
  getPayableAmountAsync,
  getPayableAmountForNonLoginFlow,
  storeLastKnowPathName,
  setPlanActivated,
  getSelectedPlanDetailsAsync,
  setSelectedBrand,
  setCheckoutParams,
  validateZipcodeAsync,
  saveZipcodeOrState,
  setSelectedCountryData,
  setBuyPlanStatusAval,
  getCountriesAsync,
  fetchBannersAsync,
  setglobalLoaderStatus,
  seteligibleDeviceInfo,
  setToaster,
  aysncFetchBrandID,
  generateOTPAsync,
  updateUserDetailsAsync,
  verifyOTPAsync,
  getUserDetailsAsync,
  fetchStoreBannersAsync,
  addorFetchAsync,
  addorFetchDeviceAsync,
  loadAppConfig,
  getAppLegalConfigs,
  authenticateProductsAsync,
  setPurchaseEligibilityInfo,
  setPlanConsumerProduct,
  setStoreDocs,
  addDeviceAsync,
  sendotpreport,
  fetchPlanQuestionsAsync,
  setPriorityQueueQuestion,
  setDependencyQueueQuestion,
  setCurrentQueueQuestion,
  setCustomPlanPrice,
  setPostPaymentActionObject,
  addCustomPlanProductPrice,
  addCustomPlanDOP,
  setTncLink,
  setPrivacyLink,
  setDataConsentLink,
  generateRedirectionUrl,
  fetchIPIDAsync,
  fetchPlanProductsAsync,
  updateDevicesAsync,
  setFetchedPlanPriceByProduct,
  setPaymentOnActivation,
  setPurchasePlanNoPayment,
  fetchDiscountDetailsAsync,
  setSelectedPaymentOption,
  setConsumerAddress,
  setTaxAddress,
  validateAddressAsync,
  setDiscountDetails,
  setUndiscountedPlanPrice,
  setUserData,
  setImeiForFilter,
  setExternalOriginSource,
  decodeToken,
  setImeiFromToken,
}

const mapStateToProps = (state) => ({
  user: state.user,
  store: state.store,
  location: state.location,
  toastsAndLoaders: state.toastsAndLoaders,
  translationData: state.languageDetail.translationText,
  // devices: state.devices
})

export default connect(mapStateToProps, mapDispatchToProps)(Store)
