import { getTranslationText } from './languageTranslation.js'
import uuid from 'node-uuid'
import { docServiceModuleName } from './constants.js'
import { getLocaleTime } from './locale.js'

export const mapsDirectionServiceFunc = async (origin, destination) => {
  let directionsService = new google.maps.DirectionsService()
  try {
    let response = new Promise((resolve, reject) => {
      return directionsService.route(
        {
          origin: new google.maps.LatLng(origin),
          destination: new google.maps.LatLng(destination),
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            resolve(result)
          } else {
            reject(status)
          }
        }
      )
    })
    return response
  } catch (err) {
    throw err
  }
}

export const pollingFunc = async (func, responseFunc, pollingTimer, pollingStatus = 1) => {
  /*
    pollingStatus: { 1: start, 0: stop}
  */
  let pollFuncRef = () => {
    let timer = Number(new Date()) + pollingTimer
    window.pollFuncRef = setInterval(() => {
      func
        .then((response) => {
          response = { success: true, data: response, msg: 'interval server error' }
          if (!response.success) {
            if (Number(new Date() < timer)) {
              response = {
                ...response,
                msg: response.msg || 'Something went wrong',
              }
              //   setTimeout(pollingIntervalCall, pollingTimer, resolve, reject)
            } else {
              response = {
                ...response,
                msg: response.msg || 'Time out error',
              }
            }
          }
          responseFunc(response)
        })
        .catch((err) => {
          // reject(new Error(err))
          responseFunc({
            msg: err,
          })
        })
      // let pollingIntervalCall = (resolve, reject) => {
      //
      // }
      // console.log('return response')
      // return new Promise(pollingIntervalCall)
    }, pollingTimer)
  }
  pollingStatus && func && pollFuncRef()
  !pollingStatus &&
    (() => {
      clearInterval(window.pollFuncRef)
    })()
}

export const readCookie = (cookieName) => {
  let decodedCookie = decodeURIComponent(document.cookie)
  let ca = decodedCookie.split(';')
  let cookieVal
  ca &&
    ca.find((val) => {
      if (val.trim().includes(cookieName)) {
        cookieVal = val.split('=')[1]
      }
    })
  return cookieVal
}

export const checkIfHasAccessToken = () => {
  // read access token & get profile
  return readCookie('ONEPLUS_SERVIFY_ID')
}

export const getElementPosition = (ele) => {
  return ele && ele.getBoundingClientRect()
}

export const oberserveElement = (target) => {
  let observer = new MutationObserver((mutations) => {
    mutations.forEach(() => {})
  })
  let config = { attributes: true, childList: true, characterData: true }
  observer.observe(target, config)
  // observer.disconnect()
}

export const base64ToImage = (str) => {
  let image = new Image()
  image.src = str
  return image
}

export const captchaResizeFunc = (id) => {
  const ele = document.getElementById(id)
  if (ele) {
    let clientWidth = ele.clientWidth
    if (clientWidth < 302) {
      let scale = clientWidth / 302
      console.log('scale ', scale)
      ele.style.transform = 'scale(' + scale + ')'
      ele.style.transformOrigin = '0 0'
    }
  }
}

export const hasConsent = (legalConfigs, consentID) => {
  let consent = legalConfigs.find((cfg) => cfg.legalConfig && cfg.legalConfig.LegalConfigCode === consentID)
  // console.log(consent)
  return !!consent
}

export const getLegalConsents = (legalConfigs, consentIDs) => {
  // console.log(legalConfigs)
  // console.log(consentIDs)
  return (
    (legalConfigs.length &&
      legalConfigs.filter(
        (cfg) => cfg.legalConfig && cfg.legalConfig.LegalConfigCode && consentIDs.indexOf(cfg.legalConfig.LegalConfigCode) !== -1
      )) ||
    []
  )
}

export const replacePlaceholders = (consentText, countryName) => {
  if (countryName) {
    console.log('lang translation - ', getTranslationText('common', 'localized' + countryName.replaceAll(' ', '')))
    consentText = consentText.replaceAll(
      '@country@',
      getTranslationText('common', 'localized' + countryName.replaceAll(' ', '')) || countryName
    )
  }
  return consentText
    .replaceAll('@tnc@', getTranslationText('common', 'termsAndCondition'))
    .replaceAll('@privacy@', getTranslationText('common', 'privacyPolicy'))
    .replaceAll('@dataconsent@', getTranslationText('common', 'dataConsent'))
    .replaceAll('@dataconsentstore@', getTranslationText('common', 'dataConsentStore'))
    .replaceAll('@insurancecard@', getTranslationText('store', 'insuranceCard'))
    .replaceAll('@relateddocuments@', getTranslationText('store', 'relateddocuments'))
}

export const getConsentTextChunks = (consentText) => {
  // console.log('getConsentTextChunks - ', consentText)
  if (consentText.length && consentText.indexOf('@') !== -1) return consentText.split(/([@][a-zA-Z]+[@])/g)
  return ''
}

export const sortAddressConfig = (addressConfig) => {
  addressConfig = addressConfig.sort((a, b) => {
    return a.displayOrder - b.displayOrder
  })

  let sortedAddressConfig = addressConfig
  let isUserLandmarkPresent = _.findIndex(sortedAddressConfig, (v) => {
    return v['keyName'] === 'UserLandmark'
  })
  let isLandMarkPresent = _.findIndex(sortedAddressConfig, (v) => {
    return v['keyName'] === 'Landmark'
  })
  if (isUserLandmarkPresent > -1) {
    const swap = (arr, index1, index2) =>
      arr.map((val, idx) => {
        if (idx === index1) return arr[index2]
        if (idx === index2) return arr[index1]
        return val
      })
    const arr = addressConfig
    if (isUserLandmarkPresent == 0) {
      sortedAddressConfig = swap(arr, isUserLandmarkPresent, isLandMarkPresent)
    }
  }
  return sortedAddressConfig
}

/**
 * Returns the formatted address
 * @param {Object} addrObj Object containing address fields, should contain atleast Landmark and Address field
 * @param {Object} addrConfig Object containing address configurations
 * @returns format address of string type
 */
export const getFormattedAddress = (addrObj, addrConfig) => {
  addrConfig = sortAddressConfig(addrConfig)
  let addrValues = []
  addrConfig.forEach((configItem) => {
    const value = addrObj[configItem.keyName]
    if (value && value?.length > 0) {
      addrValues.push(value)
    }
  })

  //If user landmark and landmark exists, then delete landmark
  if (!!addrObj['Landmark'] && !!addrObj['UserLandmark']) {
    addrValues.splice(0, 1)
  }

  return addrValues.reduce((resultAddr, value, index) => {
    return resultAddr + value + (index < addrValues.length - 1 ? ', ' : '')
  }, '')
}

export const getPlaceholders = (consentText) => {
  // console.log('getPlaceholders - ', consentText)
  let chunks = getConsentTextChunks(consentText)
  // console.log('getPlaceholders - chunks - ', chunks)
  if (chunks.length) {
    let placeholders = []
    chunks.map((chunk) => {
      if (chunk[0] === '@' && chunk[chunk.length - 1] === '@') {
        placeholders.push(chunk.replace(/\@/g, ''))
      }
    })
    return placeholders
  }
  return []
}

export const useQuery = (location) => {
  try {
    return new URLSearchParams(location)
  } catch (err) {
    console.log('Unable to parse query params')
  }
}

export const deviceCountFormatter = (val) => {
  if (val && val.toString().length < 2) {
    return '0' + val.toString()
  }
  return (val && val.toString()) || '00'
}

export const sortedAddressConfig = (addressConfig) => {
  addressConfig.sort((a, b) => {
    return a.displayOrder - b.displayOrder
  })

  let sortedAddressConfig_ = addressConfig
  addressConfig.map(async (v, i) => {
    if (v['keyName'].includes('UserLandmark', 'Landmark')) {
      const swap = (arr, index1, index2) =>
        arr.map((val, idx) => {
          if (idx === index1) return arr[index2]
          if (idx === index2) return arr[index1]
          return val
        })
      const arr = addressConfig
      sortedAddressConfig_ = swap(arr, i, i + 1)
    }
  })
  return sortedAddressConfig_
}

export const getDocServicePayload = (file, documentType) => {
  let uniqueId = uuid.v1()
  let clientRefId = uuid.v4()
  let payload = new FormData()
  payload.append('docType', documentType)
  payload.append('file', file)
  payload.append('clientRefId', clientRefId)
  payload.append('entity-id', uniqueId)
  payload.append('entity-type', documentType)
  payload.append('module', docServiceModuleName)
  return payload
}

/**
 * Receives a time string eg: '18:00:00' and returns it's locale e.g '6:00 AM'
 * @param {String} timeString time string eg: '18:00:00'
 * @param {String} locale locale string eg: 'en-IN'
 * @returns formatted locale time
 */
export const getFormattedTime = (timeString = '', locale = 'en', options = {}) => {
  let d = new Date()
  let timeArr = timeString.split(':').map((timeComponent) => parseInt(timeComponent))
  d.setHours(timeArr[0])
  d.setMinutes(timeArr[1])
  d.setSeconds(timeArr[2])
  return getLocaleTime(d, locale, options)
}

export const sortAlphabetically = (array, key) => {
  if (appName === 'servifycare') {
    array.sort((a, b) => {
      const textA = a[key].toLowerCase()
      const textB = b[key].toLowerCase()
      return textA < textB ? -1 : textA > textB ? 1 : 0
    })
  }
  return array
}

export const getRequestTypeForServiceType = (serviceTypeId) => {
  const replacementTypes = [65, 43, 46, 66]
  const serviceTypes = [7]
  const installTypes = [6, 5]

  if (replacementTypes.includes(serviceTypeId)) return 'replacement'
  if (serviceTypes.includes(serviceTypeId)) return 'service'
  if (installTypes.includes(serviceTypeId)) return 'install'
  return 'repair'
}

export const upperize = (data = {}) => {
  function loopInObj(obj = {}) {
    return Object.keys(obj).reduce((acc, str) => {
      if (obj[str] && !Array.isArray(obj[str]) && typeof obj[str] === 'object') {
        acc[str.charAt(0).toLowerCase() + str.slice(1)] = loopInObj(obj[str])
      } else {
        acc[str.charAt(0).toUpperCase() + str.slice(1)] = obj[str]
      }
      return acc
    }, {})
  }

  return loopInObj(data)
}
// Object.keys(obj).reduce((acc, str) => {
//   if (obj[str] && Object.keys(obj[str]).length) {
//     console.log(acc, str, obj)
//     acc[str.charAt(0).toLowerCase() + str.slice(1)] = obj[str]
//   } else {
//     acc[str.charAt(0).toUpperCase() + str.slice(1)] = obj[str]
//   }
//   return acc
// }, {})

export const lowerize = (obj) =>
  Object.keys(obj).reduce((acc, str) => {
    acc[str.charAt(0).toLowerCase() + str.slice(1)] = obj[str]
    return acc
  }, {})
