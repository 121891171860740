import { applyMiddleware, compose, createStore as createReduxStore } from 'redux'
import thunk from 'redux-thunk'
import { browserHistory } from 'react-router'
import makeRootReducer from './reducers'
import { routerMiddleware, replace } from 'react-router-redux'
import { updateLocation } from './location'
import accessTokenCheck from './middleware/accessTokenCheck.js'
import axiosInterceptors from '../utils/axios-interceptors.js'
import { loadState, saveState } from './middleware/localStorageLoad'
import { setToaster } from './toastsAndLoaders'
import { clearConsumerDetails } from './user'

axiosInterceptors.init()
const createStore = (initialState = {}) => {
  initialState = loadState()
  // ======================================================
  // Middleware Configuration
  // ======================================================
  const middleware = [thunk, routerMiddleware(browserHistory), accessTokenCheck]

  // ======================================================
  // Store Enhancers
  // ======================================================
  const enhancers = []
  let composeEnhancers = compose

  if (process.env.NODE_ENV === 'development') {
    if (typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function') {
      composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true })
    }
  }

  // ======================================================
  // Store Instantiation and HMR Setup
  // ======================================================
  const store = createReduxStore(makeRootReducer(), initialState, composeEnhancers(applyMiddleware(...middleware), ...enhancers))

  store.subscribe(() => {
    if (window.isAuthorized) {
      saveState(store.getState())
    } else {
      if (window.location.pathname !== '/login') {
        window.isAuthorized = true
        store.dispatch(clearConsumerDetails())
        store.dispatch(setToaster('Unauthorized user, login again', 'error'))
        setTimeout(() => {
          store.dispatch(replace('/login'))
        }, 500)
      }
    }
  })

  store.asyncReducers = {}

  // To unsubscribe, invoke `store.unsubscribeHistory()` anytime
  store.unsubscribeHistory = browserHistory.listen(updateLocation(store))

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      const reducers = require('./reducers').default
      store.replaceReducer(reducers(store.asyncReducers))
    })
  }

  return store
}

export default createStore
