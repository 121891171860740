import { connect } from 'react-redux'
import { addAddressAsync } from '../../../store/user'
import { getProductsList, addDeviceAsync, getIssuesListAsync, getPriceCardAsyn, setSeviceEstimatorDevice } from '../../../store/devices'
import { setToaster, setglobalLoaderStatus } from '../../../store/toastsAndLoaders'
/*  This is a container component. Notice it does not contain any JSX,
    nor does it import React. This component is **only** responsible for
    wiring in the actions and state necessary to render a presentational
    component - in this case, the counter:   */

import ServiceEstimator from '../components/ServiceEstimator'

const mapDispatchToProps = {
  setToaster,
  getProductsList,
  addDeviceAsync,
  addAddressAsync,
  getIssuesListAsync,
  getPriceCardAsyn,
  setSeviceEstimatorDevice,
  setglobalLoaderStatus,
}

const mapStateToProps = (state) => ({
  user: state.user,
  devices: state.devices,
  translationData: state.languageDetail.translationText,
})

export default connect(mapStateToProps, mapDispatchToProps)(ServiceEstimator)
