import { connect } from 'react-redux'
import Login from '../components/Login'
import { generateOTPAsync, verifyOTPAsync, verifyTokenAsync } from '../modules/login'
import {
  updateUserDetailsAsync,
  getUserDetailsAsync,
  generateRedirectionUrl,
  clearConsumerDetails,
  loadAppConfig,
  getAppLegalConfigs,
  setSelectedCountryData,
  getCountriesAsync,
  setTncLink,
  setPrivacyLink,
  setDataConsentLink,
  setIsSSOFlow,
} from '../../../store/user'

import { logConsentsAsync } from '../../../store/storeReducer'
import { storeLastKnowPathName } from '../../../store/location'
import getTranslationTextAsync from '../../../api/getTranslations.js'
import getLoginPageDetails from '../../../api/getLoginPageDetails'
import { setToaster, setglobalLoaderStatus } from '../../../store/toastsAndLoaders'

const mapDispatchToProps = {
  generateOTPAsync,
  verifyOTPAsync,
  verifyTokenAsync,
  updateUserDetailsAsync,
  setToaster,
  getUserDetailsAsync,
  loadAppConfig,
  getAppLegalConfigs,
  logConsentsAsync,
  storeLastKnowPathName,
  getCountriesAsync,
  setglobalLoaderStatus,
  getTranslationTextAsync,
  getLoginPageDetails,
  setSelectedCountryData: (obj) => setSelectedCountryData(obj),
  generateRedirectionUrl,
  setTncLink,
  setPrivacyLink,
  setDataConsentLink,
  clearConsumerDetails: (obj) => clearConsumerDetails(obj),
  setIsSSOFlow,
}

const mapStateToProps = (state) => ({
  user: state.user,
  location: state.location,
  translationData: state.languageDetail.translationText,
  languageCode: state.languageDetail.languageCode,
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
