import React, { lazy } from 'react'
import PropTypes from 'prop-types'
import './login.scss'
import { LoginSubHeading, MediumButton, InputComponent, RadioBox } from '../../../components/UIComponents'
import { Input, Dropdown, Checkbox, MultiInput, Captcha, getCaptcha } from 'sfy-react'
import _ from 'lodash'
import { BrandSpecificConfig } from '../../../utils/constants.js'
import { getTranslationText } from '../../../utils/languageTranslation.js'
import getRetryMsg from '../../../utils/getRetryMsg.js'
import isAppRegion from '../../../utils/isAppRegion.js'
import { regexCheck } from '../../../utils/regex'
import { Link } from 'react-router'
import { isValidMobileNumber } from '../../../utils/validators'
import { getConsentTextChunks } from '../../../utils/functions'
import { ReactComponent as Email } from '../../../../public/commonAssets/email-web.svg'
import { ReactComponent as DownArrow } from '../../../../public/commonAssets/down-arrow.svg'
import { ReactComponent as Edit } from '../../../../public/commonAssets/Edit.svg'

const ChangeLanguageOption = lazy(() => import('../../../components/UIComponents/ChangeLanguage'))

const isNokiaEu = isAppRegion('nokia', 'eu')

export const LoginPhNo = (props) => {
  const { loginPageDetails } = props
  // nokia specific changes
  let title = loginPageDetails.title || _.get(props, 'user.appConfigDetails.LoginOption.LoginHeading')
  const LoginSubTxt = loginPageDetails.subtitle || _.get(props, 'user.appConfigDetails.LoginOption.LoginSubTxt')
  const LoginSubHeadingText = _.get(props, 'user.appConfigDetails.LoginOption.LoginSubHeading')
  const LoginStorePlanTxt = String(
    loginPageDetails.newCustomerHelp || _.get(props, 'user.appConfigDetails.LoginOption.LoginStorePlan', '')
  ).replace('@storeLink', window.location.origin + '/store')
  // eslint-disable-next-line max-len
  let { termsConsent } = loginPageDetails
  if (!_.isString(termsConsent)) {
    termsConsent = _.get(props, 'user.appConfigDetails.configLegalParams.consent.Text', '')
  }
  // end of specific changes
  let brandConfig = BrandSpecificConfig().ClientName
  const isNokia = brandConfig === 'Nokia'
  const isOnePlus = brandConfig === 'OnePlus'

  // const isServifyCare = appName === 'ServifyCare'
  // @ToDo remove below if block fallback for for Login Page Title after testing all CW Apps

  if (!title) {
    title = isOnePlus ? 'Welcome&nbsp;to OnePlus&nbsp;Care' : 'Say&nbsp;hello&nbsp;to a&nbsp;better&nbsp;ownership experience'
  }
  // eslint-disable-next-line max-len
  let loginAssitance = `${getTranslationText('login', 'completeAssistance')} ${brandConfig} ${getTranslationText('login', 'devices')}`
  if (brandConfig == 'Realme') {
    brandConfig = BrandSpecificConfig().ClientName.toLowerCase()
    // realmeeu + not_german
    let countryCode = _.get(props, 'user.selectedCountryData.CountryCode')
    if (!['IN', 'DE'].includes(countryCode)) {
      loginAssitance = `${getTranslationText('login', 'completeAssistance')} ${getTranslationText('login', 'devices')} ${brandConfig}`
    } else {
      loginAssitance = `${getTranslationText('login', 'completeAssistance')} ${brandConfig} ${getTranslationText('login', 'devices')}`
    }
  }
  // app config specific boolean flags
  const isLoginEnabled = !_.get(props, 'user.appConfigDetails.isSSOEnabled', false)
  const callbackURL = _.get(props, 'user.appConfigDetails.callbackURL', false)

  const isEmailLogin = _.get(props, 'user.appConfigDetails.EnableEmailLogin', false) || props.accountType === 'email'

  // If EnableEmailLogin is not available in apConfigDetails show phone or email input
  const isPhoneOrEmailLogin = !('EnableEmailLogin' in _.get(props, 'user.appConfigDetails', {}))

  const existingUserLogin = _.get(props, 'user.appConfigDetails.LoginOption.existingUserLogin', false)
  const canShowLanguageOptions = _.get(props, 'user.appConfigDetails.showChangeLanguageOption', false)
  const showClientPromotionText = _.get(props, 'user.appConfigDetails.ShowClientPromotionText', false)
  const fAQLink = _.get(props, 'user.appConfigDetails.FAQLink', false)
  const {
    loginLinks: { termsACondLink, privacyLink, dataLink },
  } = props
  // const continueToAgree = getTranslationText('login', 'continueToAgree')
  //   .replace('@termsACondLink', termsACondLink)
  //   .replace('@privacyLink', privacyLink)
  const legalConsent = props.legalConsent
  let linksData = {
    tnc: { label: 'termsAndCondition', link: termsACondLink },
    privacy: { label: 'privacyPolicy', link: privacyLink },
    dataconsent: { label: 'dataConsent', link: dataLink },
  }
  const isSubmitDisabled = () => {
    return isEmailLogin
      ? !regexCheck('validation', 'emailId', props.EmailID) || !props.termsAndCondCheck
      : !props.phnoLengthAr.includes(props.Phno.length) || !props.termsAndCondCheck || props.invalidFormat
  }

  const handleMobileLogin = () => {
    document.getElementById('inputSubmit').click()
    props.validatePhno()
  }
  let isFestival = new Date().getFullYear() === 2022 && 'Consumer-Web'.includes(_.get(props, 'user.appConfigDetails.app', ''))
  return (
    <div className={'row login-ph ' + (props.BtnLoaderStatus ? 'disabledBlock' : '')}>
      <div className='col-sm-12 login-profile'>
        <div className='login-header-wrapper mb20'>
          <div className='header-container'>
            <img src={isFestival ? `/${appName}/images/festival/christmas/logo.png` : `/${appName}/images/logo.png`} />
          </div>
          {canShowLanguageOptions && (
            <div id='login-page-change-language-option'>
              <ChangeLanguageOption
                countryCode={window.CountryCode}
                afterLanguageChange={props.afterLanguageChange}
                loadTranslations={isNokia}
                selectedLanguage={localStorage.getItem('languageCode') || 'en'}
                changeLanguageText={getTranslationText('common', 'changeLanguage')}
              />
            </div>
          )}
        </div>
        {showClientPromotionText ? <div className='promotionalText'>{getTranslationText('login', 'serviceResume')}</div> : ''}
        <LoginHeading title={title} wordBreak={isNokia && isNokiaEu} />
        <div className='bookFontStyle fontMedium mt8'>
          {LoginSubHeadingText ? (
            <ul>
              <li>
                <p dangerouslySetInnerHTML={{ __html: LoginSubHeadingText }} />{' '}
              </li>
            </ul>
          ) : LoginSubTxt ? (
            <span dangerouslySetInnerHTML={{ __html: LoginSubTxt }} />
          ) : (
            BrandSpecificConfig().header !== 'AppleEU-Web' && (
              <ul>
                <li>
                  <p>{loginAssitance}</p>
                </li>
                <li>
                  <p>{getTranslationText('login', 'getService')}</p>
                </li>
                {fAQLink ? (
                  <li>
                    <Link className='' target='_blank' to={fAQLink}>
                      {getTranslationText('common', 'faq')}
                    </Link>
                  </li>
                ) : (
                  ''
                )}
              </ul>
            )
          )}
        </div>
        <div className='bookFontStyle fontMedium mt8'>
          {LoginStorePlanTxt ? (
            <div>
              <span className='boldFontStyle fontLarge'>{getTranslationText('login', 'newCustomer')}</span>
              <p dangerouslySetInnerHTML={{ __html: LoginStorePlanTxt }} />
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      <div className='col-sm-12'>
        {existingUserLogin ? (
          <div>
            <span className='boldFontStyle fontLarge'>{getTranslationText('login', 'existingCustomer')}</span>
            <div className='bookFontStyle fontMedium mb4'>{getTranslationText('login', 'loginMobilenNumber')}</div>
          </div>
        ) : isLoginEnabled ? (
          isPhoneOrEmailLogin ? (
            <PhoneOrEmailLogin {...props} />
          ) : isEmailLogin ? (
            <EmailLogin {...props} />
          ) : (
            <>
              <LoginSubHeading title={getTranslationText('login', 'enterMobileNumber')} />
              <CountryDropdownComponent {...props} />
            </>
          )
        ) : (
          ''
        )}
        {legalConsent.length && legalConsent[0].legalConfig.Text ? (
          <React.Fragment>
            <div className='purchase-selectables mt20'>
              <Checkbox id='terms-and-conditions' value={props.termsAndCondCheck} handleOnChange={props.handleTermsAndCondClick}>
                {getConsentTextChunks(legalConsent[0].legalConfig.Text).length > 0 ? (
                  <div className='bookFontStyle fontMedium login-footer-txt'>
                    {getConsentTextChunks(legalConsent[0].legalConfig.Text).map((chunk, index) => {
                      if (chunk[0] === '@' && chunk[chunk.length - 1] === '@') {
                        let link = chunk.replace(/\@/g, '')
                        return (
                          <Link
                            className='termsfontColor'
                            target='_blank'
                            to={linksData[link].link}
                            onClick={() => {
                              props.handleRedirection(link, linksData[link].link)
                            }}
                            key={index}
                          >
                            {getTranslationText('common', linksData[link].label)}
                          </Link>
                        )
                      }
                      return <span key={index} dangerouslySetInnerHTML={{ __html: chunk.replace(/\n/g, '<br />') }} />
                    })}
                  </div>
                ) : (
                  <div className='bookFontStyle fontMedium login-footer-txt'>
                    <span htmlFor='terms-and-conditions'>
                      <span dangerouslySetInnerHTML={{ __html: legalConsent[0].legalConfig.Text }} />
                    </span>
                    <br />
                  </div>
                )}
              </Checkbox>
            </div>
          </React.Fragment>
        ) : (
          ''
        )}
        <br />
      </div>
      {!isLoginEnabled ? (
        <div
          className={'col-sm-12 ml8 mt8 oneplus-login-wrapper fontMedium' + (props.termsAndCondCheck ? '' : ' disabledDiv')}
          onClick={props.customLoginRedirection}
        >
          <img src={`/${appName}/images/sso-logo.png`} />
          <span>{callbackURL ? 'Login via HP' : 'Login Via OnePlus'}</span>
        </div>
      ) : (
        <div className='col-sm-12 mt20'>
          <MediumButton
            buttonText={getTranslationText('common', 'next')}
            className={'loginContactDetails_btn '}
            btnLoader={props.BtnLoaderStatus}
            onClick={isEmailLogin ? props.handleEmailLogin : handleMobileLogin}
            disabled={isSubmitDisabled()}
          />
        </div>
      )}
    </div>
  )
}

LoginPhNo.propTypes = {
  validatePhno: PropTypes.func.isRequired,
  handlePhNoInputChange: PropTypes.func.isRequired,
  Phno: PropTypes.string.isRequired,
  BtnLoaderStatus: PropTypes.bool.isRequired,
  termsAndCondCheck: PropTypes.bool.isRequired,
  handleTermsAndCondClick: PropTypes.func.isRequired,
  selectedCountryObj: PropTypes.object.isRequired,
  handleCountryChange: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  phnoLengthAr: PropTypes.array,
  customLoginRedirection: PropTypes.func,
  invalidFormat: PropTypes.bool.isRequired,
  disabledCountrySelection: PropTypes.any,
  loginLinks: PropTypes.object,
  afterLanguageChange: PropTypes.func.isRequired,
  loginPageDetails: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    newCustomerHelp: PropTypes.string.isRequired,
    mobileNumberLabel: PropTypes.string.isRequired,
    termsLabel: PropTypes.string.isRequired,
    termsConsent: PropTypes.string,
  }).isRequired,
  handleRedirection: PropTypes.func,
}

export const PhoneOrEmailLogin = (props) => {
  const isPhoneNumber = props.accountType === 'phone'
  const countryOptions =
    props.user.appConfigDetails && _.get(props, 'user.countries', []).length
      ? props.user.countries.map((countryObj) => ({
          ...countryObj,
          label: '+' + countryObj.PhoneCode,
          value: countryObj.PhoneCode,
        }))
      : []
  return (
    <div className='login-ph-content'>
      <Dropdown
        id='dropdown-1'
        value={props.selectedCountryObj}
        handleOnChange={props.handleCountryChange}
        isDisabled={!props.user.appConfigDetails || props.disabledCountrySelection ? true : _.get(props, 'user.countries', []).length === 1}
        options={countryOptions}
        ValueComponent={CountryValueEmailComponent}
        OptionsComponent={CountryOptionEmailComponent}
        classObject={{
          container: 'mt8 mb28 background-white rounded-borders',
          valueContainer: 'servifycare-value-container',
          optionsSearch: 'pt8 pr8 pb8 pl8',
        }}
        optionLength={_.get(props, 'user.countries.length', 0)}
        isSearchable
        searchText={getTranslationText('login', 'selectCountry')}
        filterBy='CountryName'
        labelFloat
      />
      <div className='input-wrapper'>
        <Input
          inputType='text'
          placeholder={getTranslationText('login', 'inputEmailOrPhone')}
          value={props.accountInput}
          className={{
            formControl: ' mt8 mb20 text-center',
            inputContainer: 'input-text bookFontStyle fontSmall login-input',
            label: 'fontSmall',
          }}
          label={getTranslationText('login', 'inputEmailOrPhone')}
          labelAlign='float'
          id='floating-label'
          handleOnChange={props.handleEmailOrPhoneLogin}
        />
        {isPhoneNumber ? (
          <div
            ref={props.phonecodeDropdown}
            className={window.isDirectionRTL ? 'phonecode-dropdown_rtl' : 'phonecode-dropdown'}
            tabIndex={isPhoneNumber ? 0 : -1}
            onClick={props.handlePhoneCodeOptions}
          >
            <span>{`+${props.selectedCountryObj.PhoneCode} `}</span>
            <DownArrow />
          </div>
        ) : null}
        <Input inputType='submit' className={{ formControl: 'hidden' }} id='inputSubmit' />

        <div
          ref={props.dropdownOptionsRef}
          className={`dropdown-options ${props.showPhoneCodeOptions ? 'show-dropdown' : 'hide-dropdown'}`}
        >
          {countryOptions.length > 0 ? (
            <>
              {countryOptions.map((option, index) => {
                return (
                  <div
                    key={index}
                    id={`option-${index}`}
                    tabIndex={props.showPhoneCodeOptions ? 0 : -1}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        props.handleCountryChange(option)
                      }
                    }}
                    onClick={() => {
                      props.handleCountryChange(option)
                    }}
                    className={`dropdown-option`}
                  >
                    <div className='country-value-container'>
                      <img src={option.FlagImageURL} alt='' />
                      <div className='country-label'>
                        <span>{option.CountryName}</span>
                        <span>{`(${option.label})`}</span>
                      </div>
                    </div>
                  </div>
                )
              })}
            </>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export const CountryDropdownComponent = (props) => (
  <div className='login-ph-content' dir='ltr'>
    <Dropdown
      id='dropdown-1'
      value={props.selectedCountryObj}
      handleOnChange={props.handleCountryChange}
      isDisabled={!props.user.appConfigDetails || props.disabledCountrySelection ? true : !_.get(props, 'user.countries', []).length}
      options={
        props.user.appConfigDetails && _.get(props, 'user.countries', []).length
          ? props.user.countries.map((countryObj) => ({
              ...countryObj,
              label: '+' + countryObj.PhoneCode,
              value: countryObj.PhoneCode,
            }))
          : []
      }
      ValueComponent={CountryValueComponent}
      OptionsComponent={CountryOptionComponent}
      classObject={{
        container: 'mt8 background-white',
        valueContainer: 'country-dropdown-container',
        optionsSearch: 'pt8 pr8 pb8 pl8',
      }}
      optionLength={_.get(props, 'user.countries.length', 0)}
      isSearchable={_.get(props, 'user.countries', []).length > 1}
      searchText={getTranslationText('common', 'searchByText')}
      filterBy='CountryName'
    >
      <Input
        className={{
          inputContainer: 'phone-number-input',
        }}
        inputType='text'
        placeholder={getTranslationText('common', 'mobileNumber')}
        autoComplete='on'
        value={props.Phno}
        name='contact_no'
        handleOnChange={props.handlePhNoInputChange}
        otherProps={{
          onKeyPress: props.handlePhNoInputChange,
        }}
      />
      <Input inputType='submit' className={{ formControl: 'hidden' }} id='inputSubmit' />
    </Dropdown>
  </div>
)
CountryDropdownComponent.propTypes = {
  selectedCountryObj: PropTypes.object.isRequired,
  handleCountryChange: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  handlePhNoInputChange: PropTypes.func.isRequired,
  Phno: PropTypes.string.isRequired,
  disabledCountrySelection: PropTypes.any,
}

const CountryValueComponent = (props) => (
  <div className='country-value-container'>
    <img src={props.value.FlagImageURL} alt='' />
    <div className='country-label'>
      <span>{`+${props.value.PhoneCode}`}</span>
    </div>
  </div>
)
CountryValueComponent.propTypes = {
  value: PropTypes.object.isRequired,
}

const CountryOptionComponent = (props) => (
  <div className='country-value-container'>
    <img src={props.option.FlagImageURL} alt='' />
    <div>{`+${props.option.PhoneCode}`}</div>
    <div className='country-label'>{props.option.CountryName}</div>
  </div>
)

CountryOptionComponent.propTypes = {
  option: PropTypes.object.isRequired,
}

const CountryValueEmailComponent = (props) => (
  <div className='country-value-container'>
    <img src={props.value.FlagImageURL} alt='' />
    <div className='country-label'>
      <span>{props.value.CountryName}</span>
    </div>
  </div>
)
CountryValueEmailComponent.propTypes = {
  value: PropTypes.object.isRequired,
}

const CountryOptionEmailComponent = (props) => (
  <div className='country-value-container'>
    <img src={props.option.FlagImageURL} alt='' />
    <div className='country-label'>{props.option.CountryName}</div>
  </div>
)
CountryOptionComponent.propTypes = {
  option: PropTypes.object.isRequired,
}

export const EmailLogin = (props) => {
  return (
    <div className='login-ph-content'>
      <div className='fontSmall'>{getTranslationText('login', 'selectCountry')}</div>
      <Dropdown
        id='dropdown-1'
        value={props.selectedCountryObj}
        handleOnChange={props.handleCountryChange}
        isDisabled={!props.user.appConfigDetails || props.disabledCountrySelection ? true : _.get(props, 'user.countries', []).length === 1}
        options={
          props.user.appConfigDetails && _.get(props, 'user.countries', []).length
            ? props.user.countries.map((countryObj) => ({
                ...countryObj,
                label: '+' + countryObj.PhoneCode,
                value: countryObj.PhoneCode,
              }))
            : []
        }
        ValueComponent={CountryValueEmailComponent}
        OptionsComponent={CountryOptionEmailComponent}
        classObject={{
          container: 'mt8 background-white rounded-borders servifycare-dropdown-container',
          valueContainer: 'servifycare-value-container',
          optionsSearch: 'pt8 pr8 pb8 pl8',
        }}
        optionLength={_.get(props, 'user.countries.length', 0)}
        isSearchable
        searchText={getTranslationText('common', 'searchByText')}
        filterBy='CountryName'
      />
      <div className='mt20 fontSmall'>{getTranslationText('login', 'email')}</div>
      <EmailLoginInput
        value={props.EmailID}
        placeholder={getTranslationText('login', 'enterEmailID')}
        handleOnChange={props.handleEmailInputChange}
      />
    </div>
  )
}

export const EmailLoginInput = ({ value, handleOnChange, placeholder }) => {
  return (
    <div className='email-login__input-container mt8 rounded-borders'>
      <Email width='50' height='50' />
      <div className='input-img-partition' />
      <input
        className='email-login__input'
        type='email'
        placeholder={placeholder || getTranslationText('login', 'email')}
        value={value}
        onChange={handleOnChange}
        onKeyPress={handleOnChange}
      />
    </div>
  )
}

export const LoginOTP = ({ disableResendOtp, ...props }) => {
  let showCallCtr = _.get(props, 'user.appConfigDetails.showCallOTP', true)
  let phoneCode = _.get(props, 'selectedContact.phoneCode') || _.get(props, 'user.selectedCountryData.PhoneCode')
  // Selected contact is the contact selected in case of multiple linked accounts
  const isEmailLogin =
    !_.get(props, 'selectedContact.mobileNo') &&
    (_.get(props, 'user.appConfigDetails.EnableEmailLogin', false) ||
      props.accountType === 'email' ||
      _.get(props, 'selectedContact.emailID'))
  const reverificationContact = _.get(props, 'selectedContact.mobileNo')
    ? `+${phoneCode} - ${_.get(props, 'selectedContact.mobileNo')}`
    : _.get(props, 'selectedContact.emailID')

  const otpSentFullText = `${isEmailLogin ? getTranslationText('login', 'entercode') : getTranslationText('login', 'enterotp')}`
  const accountInput = reverificationContact || (isEmailLogin ? props.EmailID : `${phoneCode} - ${props.Phno}`)
  let isFestival = new Date().getFullYear() === 2022 && 'Consumer-Web'.includes(_.get(props, 'user.appConfigDetails.app', ''))

  return (
    <div className={'row login-OTP ' + (props.BtnLoaderStatus ? 'disabledBlock' : '')}>
      <div className='col-sm-12 login-profile'>
        <div className='header-container mb20'>
          <img src={isFestival ? `/${appName}/images/festival/christmas/logo.png` : `/${appName}/images/logo.png`} />
        </div>
        <div className='login-main-heading '>{getTranslationText('login', 'OTPHeading')}</div>
        <div className='account-input'>
          <span dir='ltr'>{accountInput}</span>
          {!reverificationContact ? (
            <button onClick={() => props.handleLoginRedirection('login')}>
              <Edit />
              {getTranslationText('login', 'change')}
            </button>
          ) : null}
        </div>
        <div className='bookFontStyle fontMedium mt8 pl8'>
          <span>{otpSentFullText}</span>
        </div>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className='col-sm-12 mt28'>
            <div dir='ltr' className='otp-input-wrap'>
              <MultiInput
                length={6}
                handleOnChange={props.handleOnMultiOTPChange}
                classNames={{ input: 'mediumFontStyle fontLarge' }}
                autoComplete='off'
                stateValue={props.otpValue}
              />
            </div>
            {props.allowResend ? (
              <React.Fragment>
                {props.sendOTPCounter <= 0 ? (
                  <div className='mt20 bookFontStyle fontSmall'>
                    {isEmailLogin ? getTranslationText('login', 'didNotReceiveCode') : getTranslationText('login', 'didNotRecieveAnOtp')}
                  </div>
                ) : (
                  <div className='mt20 mediumFontStyle fontSmall'>
                    ({isEmailLogin ? getTranslationText('login', 'resendCode') : getTranslationText('login', 'resendOTP')} 00:
                    {String(props.sendOTPCounter).length === 1 ? '0' : ''}
                    {props.sendOTPCounter})
                  </div>
                )}
                <div className='otp-actions'>
                  {props.showResendOTPbtn ? (
                    <button
                      className='bookFontStyle fontSmall special-link-txt otp-links'
                      disabled={disableResendOtp}
                      onClick={() => props.ResendOtpHandler('sms')}
                      title={disableResendOtp ? getTranslationText('login', 'timerText') : ''}
                    >
                      {isEmailLogin ? getTranslationText('login', 'emailMeTheOtp') : getTranslationText('login', 'smsMeTheOtp')}
                    </button>
                  ) : props.showOTPTimer ? (
                    <span className='mediumFontStyle fontSmall'>{getRetryMsg(props.frequentCalls)}</span>
                  ) : (
                    ''
                  )}
                  {(!isEmailLogin && props.showCallOptnBtn && showCallCtr) || props.showCallTimer ? ' | ' : ''}
                  {!isEmailLogin && props.showCallOptnBtn && showCallCtr ? (
                    <button
                      className='bookFontStyle fontSmall special-link-txt otp-links'
                      disabled={disableResendOtp}
                      onClick={() => props.ResendOtpHandler('call')}
                      title={disableResendOtp ? getTranslationText('login', 'timerText') : ''}
                    >
                      {getTranslationText('login', 'callMeWithTheOtp')}
                    </button>
                  ) : props.showCallTimer && showCallCtr ? (
                    <span className='mediumFontStyle fontSmall opt-call-link'>{getRetryMsg(props.otpViaCallTimer)}</span>
                  ) : (
                    ''
                  )}
                </div>
              </React.Fragment>
            ) : (
              ''
            )}
          </div>
          <div className='col-sm-12 mt8'>
            <MediumButton
              action='submit'
              buttonText={getTranslationText('login', 'verify')}
              onClick={props.checkOTP}
              btnLoader={props.BtnLoaderStatus}
              disabled={props.otpValue.length < 6}
            />
            {/* <label className='hideipfieldend mtb20' /> */}
          </div>
        </form>
      </div>
    </div>
  )
}

LoginOTP.defaultProps = {
  btnDisabled: false,
}

LoginOTP.propTypes = {
  // user: PropTypes.object,
  updateOTPImage: PropTypes.func,
  otpImage: PropTypes.string,
  checkOTP: PropTypes.func.isRequired,
  checkOTPlength: PropTypes.func,
  otpValue: PropTypes.string.isRequired,
  allowResend: PropTypes.bool,
  Phno: PropTypes.string.isRequired,
  showResendOTPbtn: PropTypes.bool.isRequired,
  showOTPTimer: PropTypes.bool.isRequired,
  frequentCalls: PropTypes.number.isRequired,
  showCallOptnBtn: PropTypes.bool.isRequired,
  showCallTimer: PropTypes.bool.isRequired,
  otpViaCallTimer: PropTypes.number.isRequired,
  sendOTPCounter: PropTypes.number.isRequired,
  BtnLoaderStatus: PropTypes.bool,
  btnDisabled: PropTypes.bool,
  id: PropTypes.any,
  disableResendOtp: PropTypes.bool,
  ResendOtpHandler: PropTypes.func,
  loginPageDetails: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    newCustomerHelp: PropTypes.string.isRequired,
    mobileNumberLabel: PropTypes.string.isRequired,
    termsLabel: PropTypes.string.isRequired,
    termsConsent: PropTypes.string,
  }).isRequired,
}

export const LoginContactDetails = (props) => {
  const getButtonStatus = () => {
    if (props.isEmailLogin) {
      return (
        regexCheck('validation', 'userName', props.UsrName) &&
        ((props.UsrMobileNo.length === 0 && !props.isMobileNoMandatory) ||
          (regexCheck('validation', 'numeric', props.UsrMobileNo, props.phnoLengthAr) && !props.invalidFormat))
      )
    }
    return regexCheck('validation', 'userName', props.UsrName) && regexCheck('validation', 'emailId', props.UsrEmailid)
  }

  return (
    <div className={'row'}>
      <div className='col-sm-12 login-profile'>
        <LoginHeading title={getTranslationText('login', 'createProfileTitle')} />
        <br />
        <div>{getTranslationText('login', 'createProfileSubtext')}</div>
      </div>
      <div className='col-sm-12'>
        <div className='row'>
          <div className='col-sm-12 mb28 mt28'>
            <Input
              inputType='text'
              value={props.UsrName}
              className={{
                inputContainer: ' newUserInput ',
              }}
              label={getTranslationText('login', 'yourname')}
              labelAlign='float'
              id='Usrname'
              onKeyPress={props.handleUserInputs}
              handleOnChange={props.handleUserInputs}
            />
          </div>
          {props.isEmailLogin ? (
            <div className='col-sm-12 mb20'>
              <div className='bookFontStyle fontSmall mb8'>
                {getTranslationText('common', 'mobileNumber') + (props.isMobileNoMandatory ? '' : '(optional)')}
              </div>
              <div className='newUser-mobile'>
                <Dropdown
                  id='dropdown-1'
                  value={props.selectedCountryObj}
                  handleOnChange={() => {}}
                  options={[props.selectedCountryObj]}
                  isDisabled={true}
                  ValueComponent={CountryValueComponent}
                  OptionsComponent={CountryOptionComponent}
                  classes={{
                    container: 'mt8 background-white',
                    valueContainer: 'country-dropdown-container',
                    optionsSearch: 'pt8 pr8 pb8 pl8',
                  }}
                  optionLength={0}
                  filterBy='CountryName'
                >
                  <InputComponent
                    direction={window.isDirectionRTL}
                    className='mobile-number-input fontMedium'
                    type='text'
                    placeholder={getTranslationText('login', 'enterMobileNumber')}
                    autoComplete='on'
                    onKeyPress={props.handleUserInputs}
                    id='MobileNo'
                    value={props.UsrMobileNo}
                    name='contact_no'
                    onChange={props.handleUserInputs}
                  />
                  <InputComponent type='submit' style={{ display: 'none' }} id='inputSubmit' />
                </Dropdown>
              </div>
            </div>
          ) : (
            <div className='col-sm-12 mb20'>
              <Input
                inputType='email'
                value={props.UsrEmailid}
                className={{
                  inputContainer: 'newUserInput ',
                }}
                label={getTranslationText('login', 'email')}
                id='email-input'
                labelAlign='float'
                onKeyPress={props.handleUserInputs}
                handleOnChange={props.handleUserInputs}
              />
              <br />
            </div>
          )}
          <div className='col-sm-12'>
            <MediumButton
              buttonText={getTranslationText('common', 'submit')}
              onClick={props.validateContactDetails}
              disabled={!getButtonStatus()}
              className={'newUserInput'}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

LoginContactDetails.propTypes = {
  handleUserInputs: PropTypes.func.isRequired,
  UsrName: PropTypes.string.isRequired,
  UsrEmailid: PropTypes.string.isRequired,
  validateContactDetails: PropTypes.func.isRequired,
}

export const LoginHeading = ({ isBold, wordBreak, title }) => (
  <div className='login-main-heading'>
    {wordBreak ? (
      title.split(' ').map((word, index) => <div key={'key' + index} />)
    ) : (
      <span className={isBold ? 'boldFontStyle' : ''} dangerouslySetInnerHTML={{ __html: title }}></span>
    )}
  </div>
)

LoginHeading.propTypes = {
  title: PropTypes.string.isRequired,
  wordBreak: PropTypes.bool,
  isBold: PropTypes.bool,
}

LoginHeading.defaultProps = {
  wordBreak: false,
  isBold: false,
}

// Login component for side bar on Sidebar
export const SignInDetails = (props) => {
  // checks to be added
  let btnStatus =
    regexCheck('validation', 'numeric', props.Phno, props.phnoLengthAr) &&
    regexCheck('validation', 'userName', props.UsrName) &&
    props.termsAndCondCheck &&
    regexCheck('validation', 'emailId', props.UsrEmailid)
  return (
    <div className='row login-CDetails signin-box'>
      <form onSubmit={props.validateContactDetails} className='col-sm-12 pad0' target='remember_store'>
        <div className='row'>
          <div className='col-sm-12 mb24'>
            <label className='mediumFontStyle'>{getTranslationText('common', 'name')}</label>
            <InputComponent
              direction={window.isDirectionRTL}
              className='bookfontStyle newSignIn'
              type='text'
              id='Usrname'
              placeholder={getTranslationText('login', 'yourname')}
              onChange={props.handleUserInputs}
              value={props.UsrName}
              autoComplete='off'
            />
            <br />
          </div>
          <div className='col-sm-12 mb24'>
            <label className='mediumFontStyle inline-ele-Casing'>
              <span>{getTranslationText('common', 'mobileNumber')}</span>
            </label>
            <div className='store-sign-in'>
              <span className='mediumFontStyle'>+ {window.PhoneCode}</span>
              <InputComponent
                direction={window.isDirectionRTL}
                className='mediumFontStyle mobile-number'
                type='text'
                placeholder={getTranslationText('login', 'enterMobileNum')}
                autoComplete='on'
                value={props.Phno}
                name='mobile'
                onChange={props.handlePhNoInputChange}
              />
              <InputComponent type='submit' style={{ display: 'none' }} id='inputSubmit' />
            </div>
          </div>
          <div className='col-sm-12 mb28'>
            <label className='mediumFontStyle'>{getTranslationText('common', 'emailId')}</label>
            <InputComponent
              direction={window.isDirectionRTL}
              className='mediumFontStyle email-input newSignIn'
              type='email'
              placeholder={getTranslationText('login', 'enterEmailID')}
              onChange={props.handleUserInputs}
              value={props.UsrEmailid}
              autoComplete='off'
            />
            <br />
          </div>
          <div className='purchase-selectables mb20'>
            <Checkbox value={props.termsAndCondCheck} handleOnChange={props.handleTermsAndCondClick}>
              <div className='bookFontStyle fontSmall'>
                <span onClick={props.handleTermsAndCondClick}>{getTranslationText('login', 'toAgree')}</span>&nbsp;
                {getTranslationText('common', 'our')} <br />
                <Link className='termsfontColor' target='_blank' to={props.storeLinks.tnCLink}>
                  {getTranslationText('common', 'termsAndCondition')}
                </Link>
                &nbsp;
                {getTranslationText('common', 'and')}{' '}
                <Link className='termsfontColor' target='_blank' to={props.storeLinks.privacyLink}>
                  {getTranslationText('common', 'privacyPolicy')}
                </Link>
                <br />
                <span
                  dangerouslySetInnerHTML={{
                    __html: _.get(props, 'user.appConfigDetails.configLegalParams.consent.Text', ''),
                  }}
                />
              </div>
            </Checkbox>
          </div>
          <div className='col-sm-12 mb8'>
            <MediumButton
              buttonText={getTranslationText('common', 'confirm')}
              disabled={!btnStatus}
              className={'btnSignIn'}
              btnLoader={props.BtnLoaderStatus}
              onClick={props.validatePhno}
            />
          </div>
        </div>
      </form>
    </div>
  )
}
SignInDetails.propTypes = {
  handleUserInputs: PropTypes.func.isRequired,
  UsrName: PropTypes.string.isRequired,
  UsrEmailid: PropTypes.string.isRequired,
  validateContactDetails: PropTypes.func,
  validatePhno: PropTypes.func.isRequired,
  handlePhNoInputChange: PropTypes.func.isRequired,
  Phno: PropTypes.string.isRequired,
  BtnLoaderStatus: PropTypes.bool,
  termsAndCondCheck: PropTypes.bool.isRequired,
  handleTermsAndCondClick: PropTypes.func.isRequired,
  phnoLengthAr: PropTypes.array.isRequired,
  storeLinks: PropTypes.object,
}

export class MobileNoComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mobileNo: props.mobileNo || '',
      phnoLengthAr: props.phnoLengthAr || [10],
      isInValidNo: false,
    }
  }

  componentDidMount() {
    this.props.getMobileComponentRef &&
      this.props.getMobileComponentRef({
        getPhNoStatus: this.getPhNoStatus,
        getMobileNo: () => this.state.mobileNo,
      })
  }

  getPhNoStatus = () => !this.state.isInValidNo && regexCheck('validation', 'numeric', this.state.mobileNo, this.state.phnoLengthAr)

  onChange = (event) => {
    let value = event.target.value || ''
    regexCheck('onChange', 'numeric', value, Math.max(...this.state.phnoLengthAr)) &&
      this.setState(
        {
          mobileNo: value,
          isInValidNo: this.state.phnoLengthAr.includes(value.length)
            ? isValidMobileNumber(value, this.props.selectedCountryData.CountryCode)
            : false,
        },
        () => {
          !this.state.isInValidNo && this.props.mobileNoOnChangeCallback()
        }
      )
  }

  render() {
    return (
      <div className='row'>
        <div className='col-sm-12'>
          <div className='bookFontStyle inline-ele-Casing mb8'>
            <span>{this.props.inputLabel || getTranslationText('common', 'mobileNumber')}</span>
          </div>
          <div className='mobile-no-container'>
            <span className='bookFontStyle'>+ {window.PhoneCode}</span>
            <InputComponent
              className='bookFontStyle mobile-number'
              type='text'
              placeholder={getTranslationText('login', 'enterMobileNum')}
              autoComplete='on'
              value={this.state.mobileNo}
              name='store-mobile'
              onChange={this.onChange}
            />
          </div>
          <div className={`${this.state.isInValidNo ? '' : 'hide-visibility'} mt4 lightFontStyle error-status fontSmall`}>
            {getTranslationText('error', 'invalidMobile')}
          </div>
        </div>
      </div>
    )
  }
}
MobileNoComponent.propTypes = {
  selectedCountryData: PropTypes.any,
  mobileNoOnChangeCallback: PropTypes.func.isRequired,
  phnoLengthAr: PropTypes.array,
  getMobileComponentRef: PropTypes.func.isRequired,
  inputLabel: PropTypes.string,
  mobileNo: PropTypes.string,
}

export class UserDetailsComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      userName: props.Name,
      userEmailid: props.EmailID,
    }
  }

  componentDidMount() {
    this.props.getUserDetailsRef({
      isProfileCompleted: () => {
        return this.getFieldStatus('userName', this.state.userName) && this.getFieldStatus('emailId', this.state.userEmailid)
      },
      getUserName: () => this.state.userName,
      getUserEmailId: () => this.state.userEmailid,
    })
  }

  getFieldStatus = (fieldName, value) => regexCheck('validation', fieldName, value)

  fieldValidation = (fieldType, value) => regexCheck('onChange', fieldType, value, 40) && value

  handleUserInputs = (e) => {
    if (e.target.type === 'text') {
      let userName = this.fieldValidation('userName', e.target.value)
      if (userName || userName === '') {
        this.setState(
          {
            userName,
          },
          this.props.userIpOnChangeCallBack
        )
      }
    } else {
      let userEmailid = this.fieldValidation('emailId', e.target.value)
      if (userEmailid || userEmailid === '') {
        this.setState(
          {
            userEmailid,
          },
          this.props.userIpOnChangeCallBack
        )
      }
    }
  }

  render() {
    return (
      <div className='row user-details-container'>
        <div className='col-sm-12 mb20'>
          <div className='bookFontStyle mb8'>{getTranslationText('common', 'name')}</div>
          <InputComponent
            className='bookFontStyle'
            type='text'
            id='Usrname'
            placeholder={getTranslationText('login', 'yourname')}
            name='store-name'
            onChange={this.handleUserInputs}
            value={this.state.userName}
            autoComplete='on'
          />
          <br />
        </div>
        <div className='col-sm-12'>
          <div className='bookFontStyle mb8'>{getTranslationText('common', 'emailId')}</div>
          <InputComponent
            className='bookFontStyle'
            type='email'
            placeholder={getTranslationText('login', 'enterEmailID')}
            name='store-email'
            onChange={this.handleUserInputs}
            value={this.state.userEmailid}
            autoComplete='on'
          />
        </div>
      </div>
    )
  }
  static propTypes = {
    getUserDetailsRef: PropTypes.func.isRequired,
    userIpOnChangeCallBack: PropTypes.func.isRequired,
    Name: PropTypes.any,
    EmailID: PropTypes.any,
  }
}

export const AccountSelect = (props) => {
  const isEmailLogin = props.accountType === 'email'
  const accountTypeDetails = {
    email: {
      linkedText: 'linkedEmails',
      confirmText: 'confirmLinkedMobile',
      replace: '@emailID',
      linkedContactKeyName: 'mobileNo',
      contactNotFound: 'mobileNotFound',
      inputKeyName: 'EmailID',
    },
    phone: {
      linkedText: 'linkedMobile',
      confirmText: 'confirmLinkedEmail',
      replace: '@mobileNo',
      linkedContactKeyName: 'emailID',
      contactNotFound: 'emailNotFound',
      inputKeyName: 'Phno',
    },
  }[props.accountType]

  const LINKED_TEXT = getTranslationText('login', accountTypeDetails['linkedText']).replace(
    accountTypeDetails['replace'],
    props[accountTypeDetails['inputKeyName']]
  )

  const isButtonDisabled = _.isEmpty(props.selectedContact) && !_.get(props, 'selectedContact.consumerID')

  return (
    <div className={'account-select'}>
      <div className='col-sm-12 login-profile'>
        <LoginHeading title={getTranslationText('login', 'accountValidateTitle')} />
        <br />
        <div className='light-bg'>
          <p>{LINKED_TEXT}</p>
          <p
            dangerouslySetInnerHTML={{
              __html: getTranslationText('login', accountTypeDetails['confirmText']),
            }}
          />
        </div>
        {Array.isArray(props.existingContact) && props.existingContact.length ? (
          <div className='linked-contacts'>
            {props.existingContact.map((eachContact) => (
              <div className='contact-item' key={eachContact.consumerID}>
                <RadioBox
                  isSelected={eachContact.consumerID === _.get(props, 'selectedContact.consumerID')}
                  key={eachContact.consumerID}
                  id={'radio-' + eachContact.consumerID}
                  handleOnClick={() => props.handleContactSelection(eachContact)}
                >
                  {isEmailLogin ? <span className='phone-code'>{`+${eachContact.phoneCode}`}</span> : null}
                  <span className='contact-name'>{eachContact[accountTypeDetails.linkedContactKeyName]}</span>
                </RadioBox>
              </div>
            ))}
          </div>
        ) : null}
        <MediumButton
          buttonText={getTranslationText('common', 'confirm')}
          disabled={isButtonDisabled}
          className={'btnSignIn'}
          btnLoader={props.BtnLoaderStatus}
          onClick={props.handleConfirmContact}
        />
      </div>
      <button
        className='no-contact-link'
        title={getTranslationText('login', accountTypeDetails['contactNotFound'])}
        onClick={props.handleLoginWithToken}
      >
        {getTranslationText('login', accountTypeDetails['contactNotFound'])}
      </button>
    </div>
  )
}

export class OTPComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      otpValue: '',
      // otpBtnStatus: false,
      otpImage: '/commonAssets/Login/otpimg.png',
      sendOTPCounter: 30,
      showResendOTPbtn: false,
      showResendCallBtn: false,
      resendOTPTimerViaCall: 60,
      resendOTPTimerViaSms: 60,
      disableResendOtp: false,
      btnStatus: false,
      allowResend: true,
      showResendCaptch: _.get(props, 'user.appConfigDetails.sendCaptcha', false),
      showOTPCaptcha: _.get(props, 'requestOtpResponse.showCaptcha', false),
    }
  }

  componentDidMount = () => {
    this.otpRef = document.getElementById('custom-otp-ref')
    // this.continueBtnRef = document.getElementById('otp-action-btn')
    this.props.getOtpCompRef({
      getOtpValue: () => this.state.otpValue,
      getOtpStatus: this.getOtpStatus,
      clearOTPValue: () => this.setState({ otpValue: '' }, this.btnStatusHandler),
    })
    window.resendOTPCounter =
      _.get(this, 'props.requestOtpResponse.allowResend', true) &&
      setInterval(() => {
        if (this.state.sendOTPCounter === 1) {
          clearInterval(window.resendOTPCounter)
          this.setState({
            sendOTPCounter: 0,
            showResendOTPbtn: true,
            showResendCallBtn: true,
            disableResendOtp: false,
          })
        } else {
          this.setState({
            sendOTPCounter: this.state.sendOTPCounter - 1,
          })
        }
      }, 1000)
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps !== this.props) {
      this.setState({
        allowResend: _.get(this, 'props.requestOtpResponse.allowResend', true),
        showOTPCaptcha: _.get(this, 'props.requestOtpResponse.showCaptcha', false),
      })
    }
  }

  componentWillUnmount = () => {
    clearInterval(window.resendOTPCounter)
    clearInterval(window.otpResendOptionCtr)
    window.resendOTPCounter = undefined
    window.otpResendOptionCtr = undefined
  }

  getOtpStatus = () => this.state.otpValue.length === 6

  otpOnChangeHandler = (value) => {
    if (regexCheck('onChange', 'numeric', value, 6)) {
      this.setState(
        {
          otpValue: value,
        },
        () => {
          this.btnStatusHandler()
        }
      )
    }
  }

  updateOTPImage = (key) =>
    !this.props.hideOTPImage &&
    this.setState({
      otpImage: key === 'active' ? `/${appName}/images/Login/otpimg_active.png` : '/commonAssets/Login/otpimg.png',
    })

  timerComp = (timer) =>
    this.props.hideOTPImage ? (
      <li className='mediumFontStyle fontSmall mb4'>{getRetryMsg(timer)}</li>
    ) : (
      <div className='mediumFontStyle fontSmall mb4 displayFlex'>{getRetryMsg(timer)}</div>
    )

  resendComp = (txt, handler) => {
    let disabledResendOption = this.state.disableResendOtp
    let attr = {
      className: 'bookFontStyle fontSmall special-link-txt mb4' + (disabledResendOption ? ' disable-resend' : ''),
      onClick: handler,
      disabled: disabledResendOption,
      title: disabledResendOption ? getTranslationText('login', 'timerText') : '',
    }
    if (this.props.hideOTPImage) {
      return <div {...attr}>{txt}</div>
    } else {
      return <button {...attr}>{txt}</button>
    }
  }

  resendOTPHandler = (type) => {
    this.setState(
      {
        resendOTPType: type,
        disableResendOtp: true,
        otpValue: '',
        btnStatus: false,
      },
      async () => {
        if (this.state.showResendCaptch) {
          const captcha = await getCaptcha()
          this.initResendOTPCall(captcha)
        } else {
          this.initResendOTPCall()
        }
      }
    )
  }

  initResendOTPCall = (captcha) => {
    let reqPayload = {
      ...(this.state.showResendCaptch && captcha ? { captcha } : {}),
    }
    let type = this.state.resendOTPType
    if (type === 'resendOTPTimerViaCall') {
      reqPayload = {
        voiceOtp: true,
        ...reqPayload,
      }
    }
    this.props.resendOTPMainFunc &&
      this.props
        .resendOTPMainFunc(reqPayload)
        .then(() => {
          window.otpResendOptionCtr = setInterval(() => {
            if (this.state[type] === 1) {
              clearInterval(window.otpResendOptionCtr)
              this.setState({
                [type]: 60,
                disableResendOtp: false,
                showResendOTPbtn: true,
                showResendCallBtn: true,
              })
            } else {
              this.setState({
                [type]: this.state[type] - 1,
              })
            }
          }, 1000)
        })
        .catch(() => {
          this.setState({
            disableResendOtp: false,
          })
        })
  }

  btnStatusHandler = () => {
    let btnStatus = this.getOtpStatus()
    this.setState({
      btnStatus,
    })
  }

  verifyHandler = () => {
    this.setState(
      {
        BtnLoaderStatus: true,
      },
      async () => {
        let request = {
          OTP: this.state.otpValue,
        }
        let otpHandler = (req) =>
          this.props.verifyOTPHandler(req).finally(() => {
            // this.otpRef.focus()
            this.setState({
              BtnLoaderStatus: false,
            })
          })
        if (this.state.showOTPCaptcha) {
          request.captcha = await getCaptcha()
          otpHandler(request)
        } else {
          otpHandler(request)
        }
      }
    )
  }

  render() {
    const { appConfigDetails } = this.props.user || {}
    // let otpInputStyle = !this.props.hideOTPImage && { backgroundImage: 'url(' + this.state.otpImage + ')' }
    let {
      sendOTPCounter,
      showResendOTPbtn,
      showResendCallBtn,
      resendOTPTimerViaCall,
      resendOTPTimerViaSms,
      otpValue,
      showOTPCaptcha,
      allowResend,
      showResendCaptch,
    } = this.state
    return (
      <React.Fragment>
        <div className={'col-sm-12 otp-input-container ' + (this.props.hideOTPImage ? 'store-otp-container' : '')}>
          <div dangerouslySetInnerHTML={{ __html: this.props.OTPHeading }} />
          <div className='mb20 mt20' dir='ltr'>
            <MultiInput
              length={6}
              handleOnChange={this.otpOnChangeHandler}
              classNames={{ input: 'mediumFontStyle fontLarge' }}
              autoComplete='off'
              stateValue={otpValue}
            />
            {!this.props.hideOTPImage && <label className='hideipfieldend mtb20' />}
          </div>
          {allowResend && (
            <React.Fragment>
              {sendOTPCounter === 0 ? (
                <div className={'bookFontStyle fontSmall ' + (this.props.hideOTPImage ? 'mb8' : '')}>
                  {getTranslationText('login', 'didNotRecieveAnOtp')}
                </div>
              ) : (
                <div className='mediumFontStyle fontSmall'>
                  {getTranslationText('login', 'resendOTP')}&nbsp; 00:{String(sendOTPCounter).length === 1 ? '0' : ''}
                  {sendOTPCounter}
                </div>
              )}
              <div className={'resend-otp-action ' + (this.props.hideOTPImage ? '' : ' add-inline-flex ')}>
                {showResendOTPbtn && (
                  <React.Fragment>
                    {resendOTPTimerViaSms === 60
                      ? this.resendComp(getTranslationText('login', 'smsMeTheOtp'), () => {
                          this.resendOTPHandler('resendOTPTimerViaSms')
                        })
                      : this.timerComp(resendOTPTimerViaSms)}
                  </React.Fragment>
                )}
                {!this.props.hideOTPImage && showResendCallBtn ? <span>&nbsp;|&nbsp;</span> : ''}
                {showResendCallBtn && appConfigDetails.showCallOTP && (
                  <React.Fragment>
                    {resendOTPTimerViaCall === 60
                      ? this.resendComp(getTranslationText('login', 'callMeWithTheOtp'), () => {
                          this.resendOTPHandler('resendOTPTimerViaCall')
                        })
                      : this.timerComp(resendOTPTimerViaCall)}
                  </React.Fragment>
                )}
              </div>
            </React.Fragment>
          )}
          {(showResendCaptch || showOTPCaptcha) && <Captcha recaptcha={recaptcha} />}
        </div>
        <div className='col-sm-12 mt20'>
          <MediumButton
            id='otp-action-btn'
            buttonText={this.props.buttonText}
            onClick={this.verifyHandler}
            btnLoader={this.state.BtnLoaderStatus}
            disabled={!this.state.btnStatus}
          />
        </div>
      </React.Fragment>
    )
  }
  static propTypes = {
    user: PropTypes.object.isRequired,
    buttonText: PropTypes.string.isRequired,
    OTPHeading: PropTypes.string.isRequired,
    hideOTPImage: PropTypes.bool,
    getOtpCompRef: PropTypes.func.isRequired,
    resendOTPMainFunc: PropTypes.func.isRequired,
    verifyOTPHandler: PropTypes.func.isRequired,
  }
}
