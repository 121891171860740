import React from 'react'
import PropTypes from 'prop-types'
import {
  BoxHolder,
  Heading,
  SubHeading,
  MediumButton,
  PillGroup,
  CustomIconComponent,
  BoxImagesComponent,
} from '../../../components/UIComponents'
import { Input, Dropdown } from 'sfy-react'
import AddressBox from '../../../components/AddressBox'
import { regexCheck } from '../../../utils/regex'
import _ from 'lodash'
import { getTranslationText } from '../../../utils/languageTranslation.js'
import { fallbackAddressConfig } from '../../../utils/constants'
import { ReactComponent as LogoutIcon } from '../../../../public/commonAssets/logout.svg'
import { ReactComponent as AddIcon } from '../../../../public/commonAssets/add-plus.svg'
import { Link } from 'react-router'

export const MainDetailsComponent = (props) => {
  const getButtonStatus = () => {
    let btnStatus = regexCheck('validation', 'userName', props.name)
    console.log('name', btnStatus)
    if (props.alternateMobileNo && props.alternateMobileNo.length) {
      btnStatus =
        regexCheck('validation', 'numeric', props.alternateMobileNo, props.phnoLengthAr) &&
        btnStatus &&
        !props.invalidFormat &&
        !(props.alternateMobileNo === props.mobileNo)
    }

    if (props.mobileNo && props.mobileNo.length) {
      btnStatus =
        regexCheck('validation', 'numeric', props.mobileNo, props.phnoLengthAr) &&
        btnStatus &&
        !props.invalidFormat &&
        !(props.alternateMobileNo === props.mobileNo)
    } else {
      btnStatus = false
    }
    if (props.emailID && props.emailID.length) {
      btnStatus = regexCheck('validation', 'emailId', props.emailID) && btnStatus
    } else {
      btnStatus = false
    }
    return btnStatus
  }
  let style = {
    paddingLeft: '30px',
  }
  if (props.isEditing) {
    // let defWidth = 30
    let numbers = _.get(props, 'phoneCode', '+00').length
    // numbers = 3
    let width = 30 + numbers * 5
    style = {
      paddingLeft: width + 'px',
    }
  }
  return (
    <BoxHolder>
      <div className='row remove-row-flex'>
        <div className='col-sm-12 mb20'>
          <Heading wordBreak title={getTranslationText('profile', 'myDetails')} />
        </div>
        <div className='col-sm-12 mb20'>
          {props.isEmailLogin ? (
            <React.Fragment>
              <SubHeading className='boldFontStyle overrideCasing' title={getTranslationText('common', 'emailId')} />
              <span>{props.emailID}</span>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <SubHeading className='boldFontStyle overrideCasing' title={getTranslationText('common', 'mobileNumber')} />
              <span dir='ltr'>{'+' + props.phoneCode + ' ' + (props.mobileNo || '')}</span>
            </React.Fragment>
          )}
        </div>
        <div className='col-sm-12'>
          <div className='row min-height-fix user-info-components'>
            <div className='col-lg-3 col-sm-3 mb20 align-bottom input-min-width'>
              <SubHeading className='boldFontStyle' title={getTranslationText('common', 'name')} />
              {props.isEditing ? (
                <Input
                  inputType='text'
                  value={props.name}
                  className={{
                    formControl: 'input-text bookFontStyle mt4',
                  }}
                  handleOnChange={props.handleChange}
                  id='Name'
                  name='name'
                />
              ) : (
                <div className='maindetails-label'>{props.name}</div>
              )}
            </div>
            {props.isEmailLogin ? (
              <div className='col-lg-3 col-sm-3 mb20 align-bottom input-min-width'>
                <SubHeading className='boldFontStyle' title={getTranslationText('common', 'mobileNumber')} />
                {props.isEditing ? (
                  <div dir='ltr' className='input-text-mobile mt4'>
                    <span>{'+' + props.phoneCode + ' '}</span>
                    <Input
                      inputType='text'
                      value={props.mobileNo}
                      className={{
                        formControl: 'input-text bookFontStyle',
                      }}
                      handleOnChange={props.handleChange}
                      id='MobileNo'
                      name='mobileNo'
                      otherProps={{
                        style,
                      }}
                    />
                  </div>
                ) : (
                  <div className='maindetails-label'>{props.mobileNo ? '+' + props.phoneCode + ' ' + props.mobileNo : '-'}</div>
                )}
              </div>
            ) : (
              <div className='col-lg-5 col-sm-5 mb20 align-bottom input-min-width fit-div-content'>
                <SubHeading className='boldFontStyle' title={getTranslationText('common', 'emailId')} />
                {props.isEditing ? (
                  <Input
                    inputType='text'
                    value={props.emailID}
                    className={{
                      formControl: 'input-text bookFontStyle mt4',
                    }}
                    handleOnChange={props.handleChange}
                    id='EmailID'
                    name='emailID'
                  />
                ) : (
                  <div className='maindetails-label'>{props.emailID && props.emailID.length > 0 ? props.emailID : '-'}</div>
                )}
              </div>
            )}
            <div className='col-lg-3 col-sm-3 mb20 align-bottom input-min-width'>
              <SubHeading className='boldFontStyle overrideCasing' title={getTranslationText('request', 'alternateMobileNumber')} />
              {props.isEditing ? (
                <div className='input-text-mobile mt4'>
                  <span>{'+' + props.phoneCode + ' '}</span>
                  <Input
                    inputType='text'
                    value={props.alternateMobileNo}
                    className={{
                      formControl: 'input-text bookFontStyle',
                    }}
                    handleOnChange={props.handleChange}
                    id='AlternateMobileNo'
                    name='alternateMobileNo'
                    otherProps={{
                      style,
                    }}
                  />
                </div>
              ) : (
                <div dir='ltr' className='maindetails-label'>
                  {props.alternateMobileNo ? '+' + props.phoneCode + ' ' + props.alternateMobileNo : '-'}
                </div>
              )}
            </div>
            {props.isEditing ? (
              <div className='col-lg-12 col-sm-12'>
                <div className='edit-button-holder btn-bottom'>
                  <MediumButton
                    className='button-small button-ghost'
                    id='cancel'
                    onClick={props.toggleEdit}
                    buttonText={getTranslationText('common', 'cancel')}
                  />
                  <MediumButton
                    className='button-small'
                    id='save'
                    onClick={props.toggleEdit}
                    buttonText={getTranslationText('common', 'save')}
                    disabled={!getButtonStatus()}
                  />
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        {!props.isEditing ? (
          <div className={'mb8 mt8 align-bottom user-btn-actions ' + (window.isDirectionRTL ? ' text-right ' : ' text-left')}>
            <CustomIconComponent
              onClick={(e) => {
                !props.loaderState && props.toggleEdit(e)
              }}
              iconTag='edit'
              className={'edit-image' + (props.loaderState ? ' loader-cursor ' : '')}
            />
            <span
              className='editableIcon'
              onClick={(e) => {
                !props.loaderState && props.toggleEdit(e)
              }}
            >
              {getTranslationText('common', 'edit')}
            </span>
          </div>
        ) : (
          ''
        )}
      </div>
    </BoxHolder>
  )
}
MainDetailsComponent.propTypes = {
  MobileNo: PropTypes.string,
  PhoneCode: PropTypes.string,
  Name: PropTypes.string,
  AlternateMobileNo: PropTypes.string,
  EmailID: PropTypes.string,
  isEditing: PropTypes.bool,
  toggleEdit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  loaderState: PropTypes.bool,
  phnoLengthAr: PropTypes.array.isRequired,
  invalidFormat: PropTypes.bool,
}
MainDetailsComponent.defaultProps = {
  MobileNo: '',
  PhoneCode: '',
  Name: '',
  AlternateMobileNo: '',
  EmailID: '',
}

export const AddressesComponent = (props) => {
  let header = ''
  if (props.isEdit) {
    header = <Heading wordBreak title={getTranslationText('profile', 'editAddress')} />
  } else if (props.isCreate) {
    header = <Heading wordBreak title={getTranslationText('profile', 'addAddress')} />
  } else {
    header = <Heading wordBreak title={getTranslationText('profile', 'savedAddress')} />
  }
  return (
    <BoxHolder>
      <div className='row mb20'>
        <div className='col-6'>{header}</div>
        {!props.isEdit && !props.isCreate ? (
          <div className={'col-6 ' + (window.isDirectionRTL ? ' text-left ' : ' text-right')}>
            <div
              className={'special-link-txt boldFontStyle fontSmall' + (props.loaderState ? ' loader-cursor ' : '')}
              onClick={() => {
                !props.loaderState && props.handleAddressClick()
              }}
            >
              + {getTranslationText('profile', 'addNewAddress')}
            </div>
          </div>
        ) : (
          ''
        )}
        {!props.isEdit && !props.isCreate ? (
          <div className='col-sm-12 address-view mt20'>
            <div className='address-list row'>
              {props.locations.map((location) => {
                let addressConfig = _.get(props, 'user.countryAddressConfig.addressConfig', fallbackAddressConfig)
                addressConfig = props.sortedAddressConfig(addressConfig)
                let array_1 = []
                let isLandmark = !!location['Landmark']
                let isUserLandmark = !!location['UserLandmark']
                addressConfig.map((definedConifg) => {
                  Object.keys(location).map((addressValue) => {
                    if (definedConifg?.['keyName'] == addressValue) {
                      if (!!location[addressValue] && !array_1.includes(addressValue)) {
                        array_1.push(location[addressValue])
                      }
                    }
                  })
                })
                if (isLandmark && isUserLandmark) {
                  array_1.splice(0, 1)
                }
                return (
                  <div key={location.ConsumerFavouriteLocationID} className='col-sm-12'>
                    <div className='address-line'>
                      <div className='row remove-row-flex'>
                        <div className='col-sm-11 col-11 align-middle'>
                          <SubHeading className='boldFontStyle overrideCasing' title={location.AddressType} />
                          <span className='bookFontStyle'>
                            {array_1 &&
                              array_1.length &&
                              array_1.map((v, i) => {
                                return v + (array_1.length !== i + 1 ? ', ' : '.')
                              })}
                          </span>
                        </div>
                        {!location.Uneditable ? (
                          <div className='col-sm-1 col-1 align-middle'>
                            <CustomIconComponent
                              name={location.ConsumerFavouriteLocationID}
                              className={'edit-image' + (props.loaderState ? ' loader-cursor ' : '')}
                              onClick={(e) => {
                                !props.loaderState && props.handleAddressClick(e, location.ConsumerFavouriteLocationID)
                              }}
                              iconTag='edit'
                            />
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        ) : (
          ''
        )}

        {props.isEdit || props.isCreate ? <CreateAddressComponent {...props} /> : ''}
      </div>
    </BoxHolder>
  )
}

AddressesComponent.propTypes = {
  locations: PropTypes.array,
  handleAddressClick: PropTypes.func.isRequired,
  isEdit: PropTypes.any,
  isCreate: PropTypes.bool,
  loaderState: PropTypes.bool,
}

AddressesComponent.defaultProps = {
  locations: [],
  isEdit: '',
}

export const CreateAddressComponent = (props) => {
  let { newAddressDetails } = props
  let selectedAddress = props.isEdit
    ? props.locations.filter((address) => address.ConsumerFavouriteLocationID === parseInt(props.isEdit))[0]
    : newAddressDetails
  let AddressType = props.isEdit ? selectedAddress.AddressType : newAddressDetails.AddressType
  let pinCodeValue = selectedAddress.PinCode || ''
  const cityValue = selectedAddress.City || ''
  const stateValue = selectedAddress.State || ''
  let userLandmarkValue = selectedAddress.UserLandmark || ''
  const addressValue = selectedAddress.Address || ''

  let isAddressValueRequire = true
  let isCityValueRequire = true
  let isStateValueRequire = true
  let isLandmarkValueRequire = true
  let isUserLandMarkValueRequire = true
  let isPincodeValueRequire = true
  let pincodeLengthCheck = props.pincodeLengthAr

  let addressConfig = _.get(props, 'user.countryAddressConfig.addressConfig', fallbackAddressConfig)
  let sortedAddressConfig = props.sortedAddressConfig(addressConfig)
  let districtAddressListConfig_ = _.get(props, 'user.districtAddressListConfig', [])
  return (
    <div className='address-create'>
      <div className='col-sm-12 mb20'>
        <AddressBox
          handleTypeaheadSelect={props.handleTypeaheadSelect}
          placesAutocomplete={props.placesAutocomplete}
          onChangeTypeahead={props.onChangeTypeahead}
          user={props.user}
        />
      </div>
      {sortedAddressConfig &&
        sortedAddressConfig.length &&
        sortedAddressConfig.map((element) => {
          switch (element?.keyName) {
            case 'Zipcode':
            case 'PinCode': {
              if (element.display) {
                pincodeLengthCheck = element['length'].some((i) => [pinCodeValue.length].includes(i))
                // UK pincodes have empty space in between. so check lengh removing that
                if (!pincodeLengthCheck)
                  pincodeLengthCheck = element['length'].some((i) => [pinCodeValue.replaceAll(' ', '').length].includes(i))
                isPincodeValueRequire = element['mandatory'] ? !!pincodeLengthCheck : pinCodeValue.length ? !!pincodeLengthCheck : true
                return (
                  element.display && (
                    <Input
                      inputType='text'
                      value={pinCodeValue}
                      className={{
                        formControl: 'col-sm-12 mb20',
                        input: 'input-text book',
                        label: 'address-label bookFontStyle overrideCasing',
                      }}
                      label={`${element.label} ${element.mandatory ? '' : getTranslationText('common', 'optional')}`}
                      handleOnChange={props.onChangeAddressInput}
                      name='PinCode'
                    />
                  )
                )
              }
            }
            case 'Address':
              isAddressValueRequire = element['mandatory'] ? !!addressValue : true
              return (
                element.display && (
                  <div className='col-sm-12 mb20'>
                    <span className={`fontSmall bookFontStyle char-length-checker ` + (window.isDirectionRTL ? ' text-left-align10' : '')}>
                      {`(${(!!_.get(selectedAddress, 'Address', '') && _.get(selectedAddress, 'Address', '').length) || 0} / ${
                        element.inputLength || 50
                      })`}
                    </span>
                    <Input
                      inputType='text'
                      value={selectedAddress.Address || ''}
                      className={{
                        input: 'input-text bookFontStyle',
                        label: 'address-label bookFontStyle overrideCasing',
                      }}
                      label={`${element.label} ${element.mandatory ? '' : getTranslationText('common', 'optional')}`}
                      handleOnChange={props.onChangeAddressInput}
                      maxLength={element.inputLength || '50'}
                      name='Address'
                    />
                  </div>
                )
              )
            case 'State':
              isStateValueRequire = element['mandatory'] ? !!stateValue : true
              return (
                element.display && (
                  <div className='col-sm-12 mb20'>
                    <div className='address-label bookFontStyle'>
                      <span>{element.label}</span>
                      {element.mandatory ? '' : ' ' + getTranslationText('common', 'optional')}
                    </div>

                    <Dropdown
                      id='state-address-dropdown'
                      value={!_.isEmpty(props.selectedState) ? props.selectedState.value : ''}
                      options={props.user.stateAddressListConfig.map((state) => ({
                        ...state,
                        label: state.State,
                        value: state.State,
                      }))}
                      placeholder={getTranslationText('store', 'selectStateLabel')}
                      handleOnChange={props.onChangeStateSelection}
                      filterBy='value'
                      searchText='Search'
                    />
                  </div>
                )
              )
            case 'City':
              isCityValueRequire = element['mandatory'] ? !!cityValue : true
              return (
                element.display && (
                  <Input
                    inputType='text'
                    value={selectedAddress.City || ''}
                    name='City'
                    handleOnChange={props.onChangeAddressInput}
                    className={{
                      formControl: 'col-sm-12 mb20',
                      inputContainer: 'input-text bookFontStyle',
                      label: 'address-label bookFontStyle',
                    }}
                    label={`${element.label} ${element.mandatory ? '' : getTranslationText('common', 'optional')}`}
                  />
                )
              )
            case 'UserLandmark':
              userLandmarkValue = userLandmarkValue?.replace(/\s/g, '')
              isUserLandMarkValueRequire = element['mandatory'] ? userLandmarkValue?.length >= _.get(element, 'minLength', 1) : true
              return (
                element.display && (
                  <div className='col-sm-12 mb20'>
                    <span className={`fontSmall bookFontStyle char-length-checker ` + (window.isDirectionRTL ? ' text-left-align10' : '')}>
                      {`(${(!!_.get(selectedAddress, 'UserLandmark', '') && _.get(selectedAddress, 'UserLandmark', '').length) || 0} / ${
                        element.inputLength || 50
                      })`}
                    </span>
                    <Input
                      inputType='text'
                      value={selectedAddress.UserLandmark || ''}
                      name='UserLandmark'
                      handleOnChange={props.onChangeAddressInput}
                      className={{
                        inputContainer: 'input-text bookFontStyle',
                        label: 'address-label bookFontStyle',
                      }}
                      maxLength={element.inputLength || '50'}
                      label={`${element.label} ${element.mandatory ? '' : getTranslationText('common', 'optional')}`}
                    />
                  </div>
                )
              )
            case 'District':
              isStateValueRequire = element['mandatory'] ? !!stateValue : true
              return (
                element.display && (
                  <div className='col-sm-12 mb20'>
                    <div className='address-label bookFontStyle'>
                      <span>{element.label}</span>
                      {element.mandatory ? '' : ' ' + getTranslationText('common', 'optional')}
                    </div>
                    <Dropdown
                      value={!_.isEmpty(props.selectedDistrict) ? props.selectedDistrict.value : ''}
                      options={districtAddressListConfig_.map((state) => ({
                        ...state,
                        label: state.DistrictID,
                        value: state.DistrictName,
                      }))}
                      placeholder={getTranslationText('store', 'selectDistrictLabel')}
                      handleOnChange={props.handleDistrictSelection}
                      filterBy='value'
                      searchText='Search'
                      isDisabled={props.user.districtAddressListConfig && !props.user.districtAddressListConfig.length}
                    />
                  </div>
                )
              )
          }
        })}

      <div className='col-sm-12 mb20'>
        <div className='address-label bookFontStyle mb8 overrideCasing'>
          <span>{getTranslationText('profile', 'addressType')}</span>
        </div>
        <div className='pills-holder'>
          <PillGroup prefix='addressType' showImg pills={props.addressTypesList} handlePillSelect={props.toggleAddressPillSelection} />
        </div>
      </div>

      <div className='row address-actions-btns'>
        <MediumButton
          className='button-small button-ghost mr8'
          onClick={props.cancel}
          buttonText={getTranslationText('common', 'cancel')}
        />
        <MediumButton
          className='button-small save-button mr8'
          onClick={props.saveAddress}
          buttonText={getTranslationText('common', 'save')}
          disabled={
            !props.allowSave ||
            !AddressType ||
            !isAddressValueRequire ||
            !isCityValueRequire ||
            !isStateValueRequire ||
            !isLandmarkValueRequire ||
            !isUserLandMarkValueRequire ||
            !isPincodeValueRequire
          }
        />
        {props.isEdit ? (
          <MediumButton
            className='button-small button-ghost mr8 delete-btn'
            buttonText={getTranslationText('profile', 'delete')}
            onClick={props.toggleConfirmationPopUp}
          />
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

CreateAddressComponent.propTypes = {
  handleTypeaheadSelect: PropTypes.func.isRequired,
  onChangeTypeahead: PropTypes.func.isRequired,
  onChangeAddressInput: PropTypes.func.isRequired,
  onChangeStateSelection: PropTypes.func.isRequired,
  selectedState: PropTypes.any,
  cancel: PropTypes.func.isRequired,
  toggleConfirmationPopUp: PropTypes.func.isRequired,
  saveAddress: PropTypes.func.isRequired,
  placesAutocomplete: PropTypes.object,
  isEdit: PropTypes.any,
  locations: PropTypes.array,
  newAddressDetails: PropTypes.object,
  allowSave: PropTypes.bool,
  addressTypesList: PropTypes.array,
  toggleAddressPillSelection: PropTypes.func,
  pincodeLengthAr: PropTypes.array.isRequired,
}

CreateAddressComponent.defaultProps = {
  newAddressDetails: {
    Address: '',
    AddressType: '',
    PinCode: '',
    City: '',
    State: '',
  },
  allowSave: false,
}

export const UploadDocumentComponent = (props) => {
  return (
    <BoxHolder>
      <div className='row'>
        <div className='col-sm-12 mb20'>
          <Heading wordBreak title={getTranslationText('profile', 'myDocuments')} />
        </div>

        {props.documents.map((documentObj, index) => {
          return (
            <div key={index} className='col-sm-12 mb20'>
              <SubHeading className='document-subheading overrideCasing' title={documentObj.DocumentType} />
              {documentObj.HelpText ? <span>{documentObj.HelpText}</span> : ''}
              <div className='row mt8 document-list'>
                {documentObj.Documents.map((doc) => {
                  if (!doc.UserDocumentID) {
                    return (
                      <BoxImagesComponent
                        key={doc.DocumentID}
                        loaderState={props.loaderState}
                        isUploaded={false}
                        className='document-upload'
                        imgFilePath={<AddIcon />}
                        imgViewerHandler={null}
                        imageID={''}
                        inputName={doc.DocumentID}
                        fileUploadHander={window.useDocService ? props.uploadConsumerDocToDS : props.changeDoc}
                      />
                    )
                  } else {
                    return (
                      <BoxImagesComponent
                        key={doc.DocumentID}
                        loaderState={props.loaderState}
                        isUploaded
                        className='document-upload'
                        imgFilePath={doc.FilePath}
                        imgViewerHandler={props.openDocsGallery}
                        imageID={doc.UserDocumentID}
                      />
                    )
                  }
                })}
              </div>
            </div>
          )
        })}
      </div>
    </BoxHolder>
  )
}

UploadDocumentComponent.propTypes = {
  documents: PropTypes.array,
  openDocsGallery: PropTypes.func.isRequired,
  changeDoc: PropTypes.func,
  loaderState: PropTypes.any,
}

UploadDocumentComponent.defaultProps = {
  documents: [],
}

export const ProfileSettingsComponent = (props) => {
  return (
    <BoxHolder>
      <div className='row'>
        <div className='col-sm-12 mb20'>
          <Heading wordBreak title={getTranslationText('profile', 'profileSettings')} />
        </div>
        <div className='col-sm-12 mb20'>
          <div className='setting-line' onClick={props.handleLogout}>
            <div className='setting-text align-middle'>
              <SubHeading title={getTranslationText('profile', 'logout')} className='logout-heading' />
            </div>
            <div className='setting-checkbox'>
              {/* <img alt='' src={LogoutIcon} /> */}
              <LogoutIcon />
            </div>
          </div>
        </div>
        {props.appConfigDetails.deleteAccountURL && (
          <Link
            className='col-sm-12 mt20 delete-account error boldFontStyle fontLarge cursor-pointer'
            to={props.appConfigDetails.deleteAccountURL}
            target='_blank'
            rel='noreferrer'
          >
            {getTranslationText('profile', 'deleteAccount')}
          </Link>
        )}
      </div>
    </BoxHolder>
  )
}

ProfileSettingsComponent.propTypes = {
  PhoneCode: PropTypes.string,
  MobileNo: PropTypes.string,
  EmailID: PropTypes.string,
  handleLogout: PropTypes.func.isRequired,
}

ProfileSettingsComponent.defaultProps = {
  PhoneCode: '',
  MobileNo: '',
  EmailID: '',
}
