export const fetchCity = (arabicCity) => {
  const countries = [
    { name: 'Dubai', code: 'دبي' },
    { name: 'Abu Dhabi', code: 'أبو ظبي' },
    { name: 'Sharjah', code: 'الشارقة' },
    { name: 'Al Ain', code: 'العين' },
    { name: 'Ajman', code: 'عجمان' },
    { name: 'Ras Al Khaimah', code: 'رأس الخيمة' },
    { name: 'Fujairah', code: 'الفجيرة' },
    { name: 'Umm Al Quwain', code: 'أم القيوين' },
    { name: 'Kalba', code: 'كلباء' },
    { name: 'Madinat Zayed', code: 'مدينة زايد' },
    { name: 'Khor Fakkan', code: 'خورفكان' },
    { name: 'Dibba Al-Fujairah', code: 'دبا الفجيرة' },
    { name: 'Ruwais', code: 'مدينة الرويس الصناعية' },
    { name: 'Ghayathi', code: 'غياثي' },
    { name: 'Dhaid', code: 'الذيد' },
    { name: 'Jebel Ali', code: 'جبل علي' },
    { name: 'Liwa Oasis', code: 'بطين ليوا' },
    { name: 'Hatta', code: 'حتا' },
    { name: 'Rams', code: 'الرمس' },
    { name: 'Al Jazirah Al Hamra', code: 'الجزيرة الحمراء' },
    { name: 'Dibba Al-Hisn', code: 'دبا الحصن' },
    { name: 'Riyadh', code: 'الرياض' },
  ]
  return countries.find((entry) => entry.code == arabicCity)?.name || arabicCity
}
