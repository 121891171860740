import React, { useState, memo } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { MediumButton } from '..'
import { Input, Modal } from 'sfy-react'
import { SubHeading, PillGroup, Heading, CustomIconComponent } from './ReusableComponents'
import { getTranslationText } from '../../../utils/languageTranslation.js'
import getRatingData from '../../../api/getRatingData'
import pushToAnalytics from '../../../utils/Analytics'
// import safePromise from '../../../utils/safePromise'

const StarsComponent = (props) => (
  <span id={props.id} className={props.className} onClick={props.onClick} onMouseOver={props.onMouseOver} onMouseOut={props.onMouseOut}>
    ★
  </span>
)

StarsComponent.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  onMouseOut: PropTypes.func.isRequired,
  onMouseOver: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
}
const RatingModal = (props) => {
  const starsCount = Array.from(Array(5).keys())
  const defaultStarsState = []
  starsCount.forEach((index) => {
    const defaultStarState = {
      id: `star-${index + 1}`,
      class: '',
    }
    defaultStarsState.push(defaultStarState)
  })
  const defaultState = {
    stars: defaultStarsState,
    feedback: {
      title: '',
      subTitle: '',
      options: [],
    },
    starRating: -1,
    showFeedbackForm: false,
  }
  const [state, setState] = useState(defaultState)
  const onMouseOutStar = () => {
    const revisedStarsState = getHighlightedStarsState(state.starRating)
    const revisedState = {
      ...state,
      stars: revisedStarsState,
    }
    setState(revisedState)
  }

  const getHighlightedStarsState = (highlightedStarIndex) => {
    const revisedStarsState = []
    defaultStarsState.forEach((star, index) => {
      const shouldBeHighlighted = index < highlightedStarIndex
      const defaultStarState = {
        ...star,
        class: shouldBeHighlighted ? 'checked' : '',
      }
      revisedStarsState.push(defaultStarState)
    })
    return revisedStarsState
  }

  const onClickStar = (clickedStarIndex) => async () => {
    const revisedStarsState = getHighlightedStarsState(clickedStarIndex)
    const revisedState = {
      ...state,
      starts: revisedStarsState,
      starRating: clickedStarIndex,
      showFeedbackForm: true,
    }
    if (state.showFeedbackForm) {
      setState(revisedState)
      props.starRating(clickedStarIndex)
      !state.showFeedbackForm && props.setGlobalLoaderStatus(true)
      return
    }
    props.setGlobalLoaderStatus(true)
    props.starRating(clickedStarIndex)
    props.setGlobalLoaderStatus(false)
    setState(revisedState)
    pushToAnalytics('Rating_selected', {
      Rating: clickedStarIndex,
    })
    pushToAnalytics('Rating_like_about_us_options', {})
  }
  const onMouseOverStar = (hoveredStarIndex) => () => {
    const revisedStarsState = getHighlightedStarsState(hoveredStarIndex)
    const revisedState = {
      ...state,
      stars: revisedStarsState,
    }
    setState(revisedState)
  }

  const canSubmitFeedback = () => {
    // feedback details not mandatory for rating > 3
    if (state.starRating > 3) {
      return true
    }
    // isAnyFeedbackSelected
    if (props.ratingoptns.options.find((pill) => pill.isSelected)) {
      return true
    }
    // isFeedbackEmpty
    if (!_.isEmpty(props.usrFeedbck)) {
      return true
    }
    return false
  }

  return (
    <Modal showModal={props.show} closeModal={props.toggleModal} className='sfy-modal rating-modal'>
      <div className={'close-icon ' + (window.isDirectionRTL ? ' text-left ' : ' text-right')} onClick={props.toggleModal}>
        <CustomIconComponent className={window.isDirectionRTL ? ' text-left ' : ' text-right'} alt='Cancel' />
      </div>

      <div className='row'>
        <div className='col-12 text-center'>
          <Heading title={getTranslationText('RatingModal', 'rateYourExp')} />
        </div>
      </div>
      <div className='row mt20 text-center'>
        <div className='col-sm-12'>
          <SubHeading title={getTranslationText('common', 'experienceText')} className='bookFontStyle fontSmall' />
        </div>
        <div className='col-sm-12 mb8'>
          <div className='start-rating mt8'>
            {state.stars.map((star, index) => (
              <StarsComponent
                key={star.id}
                id={star.id}
                className={star.class}
                onClick={onClickStar(index + 1)}
                onMouseOver={onMouseOverStar(index + 1)}
                onMouseOut={onMouseOutStar}
              />
            ))}
          </div>
          {state.showFeedbackForm ? (
            <div className='rating-content'>
              <SubHeading title={state.feedback.title || props.ratingoptns.textHeader} className={'mt8 overrideCasing text-center'} />
              <SubHeading
                title={state.feedback.subTitle || props.ratingoptns.optionsHeader}
                className={'mt4 mb20 overrideCasing text-center'}
              />
              <PillGroup
                handlePillSelect={props.handlePillSelect}
                prefix='ratingpill'
                pills={_.isEmpty(state.feedback.options) ? props.ratingoptns.options : state.feedback.options}
              />
              <Input
                inputType='text'
                placeholder={getTranslationText('common', 'otherReason')}
                value={props.usrFeedbck}
                className={{
                  formControl: 'col-sm-12 mt8 mb20 text-center',
                  inputContainer: 'input-text bookFontStyle fontSmall rating-input',
                }}
                handleOnChange={props.handleRatingInput}
              />
              <div className='col-sm-12 mt20 text-center'>
                <MediumButton
                  id='updateReq'
                  buttonText={getTranslationText('common', 'submit')}
                  onClick={props.handlefeedback}
                  disabled={!canSubmitFeedback()}
                />
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </Modal>
  )
}

RatingModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setGlobalLoaderStatus: PropTypes.func.isRequired,
  getRatingData: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  handlePillSelect: PropTypes.func.isRequired,
  ratingoptns: PropTypes.object.isRequired,
  usrFeedbck: PropTypes.string,
  handleRatingInput: PropTypes.func.isRequired,
  handlefeedback: PropTypes.func.isRequired,
  starRating: PropTypes.func.isRequired,
}

const mapStateToProps = () => ({})

const mapDispatchToProps = {
  getRatingData,
}

export default connect(mapStateToProps, mapDispatchToProps)(memo(RatingModal))
