let CountryCode = ''
const pushToAnalytics = (evtName, data) => {
  //console.log('pushToAnalytics - ', evtName, data, onUserProfile)

  const data_masking_keys = [
    'Name',
    'Customer Name',
    'Mobile Number',
    'Email',
    'Email ID',
    'Email Address',
    'Plan Price',
    'Base Price',
    'Total Price',
    'Tax Value',
  ]
  const data_masking_keys_imei = ['IMEI', 'Serial No', 'IMEI or Serial', 'Device IMEI / Serial Nos.', 'Device IMEI']

  try {
    let _data = {}
    if (!data) data = {}
    if (!Object.keys(data).length) _data = data
    else {
      Object.keys(data).forEach((key) => {
        if (data_masking_keys.length && data_masking_keys.indexOf(key) == -1) {
          if (data_masking_keys_imei.length && data_masking_keys_imei.indexOf(key) >= 0) {
            _data[key.replaceAll(' ', '_')] = data.ConsumerProductID || ''
          } else _data[key.replaceAll(' ', '_')] = data[key]
        }
      })
    }
    _data.event = evtName
    let bCountryCodeFound = false
    Object.keys(_data).length > 0 &&
      Object.keys(_data).map((key) => {
        if (key === 'Country_Code') {
          CountryCode = _data.Country_Code
          bCountryCodeFound = true
        }
      })
    if (!bCountryCodeFound && CountryCode.length > 0) _data.Country_Code = CountryCode
    // console.log('pushToGTM - ', evtName)
    // console.log('_data - ', _data)
    window.dataLayer && dataLayer && Object.keys(_data).length > 0 && dataLayer.push(_data)
    // window.dataLayer && console.log('pushToGTM - datalayer - ', window.dataLayer)
    // pushToGTM(evtName, _data, onUserProfile)
  } catch (e) {
    console.log('pushToGTM - error - ', e)
  }
}

export const pushProfileToAnalytics = () => {
  //profilePushGTM(consumerID)
  // console.log('profilePushGTM - ', email)
}

export default pushToAnalytics
