import React, { Component } from 'react'
import './GuestRoute.scss'
import { Carousel, Modal } from 'sfy-react'
import { MediumButton, CustomIconComponent } from '../../components/UIComponents'
import { browserHistory } from 'react-router'
export default class MyCareComponent extends Component {
  constructor() {
    super()
    this.state = {
      homeBannersList: [{ imgSrc: `/${appName}/images/mycare/samsung_mycare_desktop.png` }],
      mobileHomeBannersList: [{ imgSrc: `/${appName}/images/mycare/samsung_mycare_mobile.png` }],
      section2: [
        {
          title: 'Step 1. Tell us your issue',
          text: 'Describe the issue(s) that your current device is experiencing',
          img: `/${appName}/images/mycare/section2/1.svg`,
          url: '',
        },
        {
          title: 'Step 2. Payment',
          text: `A non-refundable service fee or deductible may apply (See Care+ <a className='boldFontStyle' href='https://us.servify.tech/termsandconditions/samsung/samsung-b2c' > T&C's</a>)`,
          img: `/${appName}/images/mycare/section3/card.svg`,
          url: '',
        },
        {
          title: 'Step 3. Repair or Replacement',
          text: 'We’ll provide you with the options to address your device issue',
          img: `/${appName}/images/mycare/section3/repair.svg`,
          url: '',
        },
      ],
      section3: [
        {
          title: 'Unlimited Repairs',
          text: 'Breathe easy with unlimited repairs including batteries and same day $29 cracked screen repairs at 700+ Samsung authorized locations.',
          img: `/${appName}/images/mycare/lostTheft/1.svg`,
          url: '',
        },
        {
          title: 'Theft and Loss Coverage',
          text: 'Stay cool if your device is lost or stolen knowing that you have the ultimate protection.',
          img: `/${appName}/images/mycare/lostTheft/2.svg`,
          url: '',
        },
        {
          title: 'Same Day Replacement and Set Up',
          text: 'We’ll replace your Lost or Stolen device as soon as the same day and help set it up so you can breathe easier.',
          img: `/${appName}/images/mycare/lostTheft/3.svg`,
          url: '',
        },
        {
          title: 'Knox Guard Security',
          text: 'Stay protected with Samsung Knox Guard including remote lock and wipe so your content remains private.',
          img: `/${appName}/images/mycare/lostTheft/4.svg`,
          url: '',
        },
        {
          title: '24/7 Expert Support',
          text: 'Get immediate answers from a Samsung Care+ expert 24/7 via phone or online for device setup, content transfers, and connecting with other devices.',
          img: `/${appName}/images/mycare/section1/4.svg`,
          url: '',
        },
        {
          title: 'Samsung Genuine Parts and Certified Technicians',
          text: 'Rest assured your device will be repaired by a Samsung Certified Technician using Samsung Genuine Parts.',
          img: `/${appName}/images/mycare/lostTheft/6.svg`,
          url: '',
        },
      ],
      showVideoPlayer: false,
    }
  }

  componentWillMount() {
    window.scrollTo(0, 0)
  }

  handleBrowserRidirection = () => {
    window.open('https://rg2.samsungcareplus.com/us#/', '_blank')
  }

  toggleModal = () => {
    this.setState({
      showVideoPlayer: !this.state.showVideoPlayer,
    })
  }

  render() {
    let loginUrl = '/login'
    const phone = _.get(this, 'props.location.query.phone', false)
    const email = _.get(this, 'props.location.query.email', false)
    const primary_identifier = _.get(this, 'props.location.query.primary_identifier', 'EMAIL_ID')

    if (phone && email && primary_identifier) {
      loginUrl = `/login?email=${email}?phone=${phone}?primary_identifier=${primary_identifier}`
    } else if (email) {
      loginUrl = `/login?email=${email}?primary_identifier=${primary_identifier}`
    } else if (phone) {
      loginUrl = `/login?phone=${phone}?primary_identifier=${primary_identifier}`
    }
    return (
      <>
        <div className='my-care-contianer'>
          <div className='padding-none desktop position-relation my-care-home'>
            <Carousel
              items={this.state.homeBannersList.filter((item) => item)}
              individualBanner={(item) => (
                <div className='row slider-slide-img'>
                  <img className='full-width' src={item.imgSrc} />
                  <div className='banner'>
                    <h1 className='.BannerHeader boldFontStyle'>Samsung Care+</h1>
                    <div className='bookFontStyle mt8 font32'>Protection for your Galaxy Life</div>

                    <MediumButton
                      className='mt48 mb20 boldFontStyle'
                      buttonText='File a Claim'
                      onClick={() => {
                        browserHistory.push(loginUrl)
                      }}
                    />
                    <MediumButton
                      className='mt48 ml20 boldFontStyle'
                      buttonText='Purchase Care+'
                      onClick={() => {
                        window.open('https://rg2.samsungcareplus.com/us#/ ')
                      }}
                    />

                    <br />
                    <a className='mt30 boldFontStyle' href='https://us.servify.tech/termsandconditions/samsung/samsung-b2c'>
                      {' '}
                      View Terms & Conditions
                    </a>
                  </div>
                </div>
              )}
              showDots
            />
          </div>

          <div className='padding-none mobile'>
            <Carousel
              items={this.state.mobileHomeBannersList.filter((item) => item)}
              individualBanner={(item) => (
                <div className='row slider-slide-img'>
                  <img className='full-width' src={item.imgSrc} />
                  <div className='banner'>
                    <h1 className='font48 boldFontStyle'>Samsung Care+</h1>
                    <div className='bookFontStyle mt8 fontExtraLarge'>
                      Protection for your <br /> Galaxy Life
                    </div>

                    <MediumButton
                      className='mt30 boldFontStyle'
                      buttonText='File a Claim'
                      onClick={() => {
                        browserHistory.push(loginUrl)
                      }}
                    />
                    <br />
                    <MediumButton
                      className='mt10 boldFontStyle'
                      buttonText='Purchase Care+'
                      onClick={() => {
                        window.open('https://rg2.samsungcareplus.com/us#/ ')
                      }}
                    />
                    <br />
                    <a className='boldFontStyle mobile-banner-tnc' href='https://us.servify.tech/termsandconditions/samsung/samsung-b2c'>
                      {' '}
                      View Terms & Conditions
                    </a>
                  </div>
                </div>
              )}
              showDots
            />
          </div>

          <div className='empty-route-container1 bg-primary-color'>
            <div className='row home-components1 mb28'>
              <div className='col-sm-12 col-lg-12 mb48'>
                <div className='mt20 bookFontStyle color-white font24'>
                  If your device needs a helping hand in the future, keep this info in mind:
                </div>
                <div className='boldFontStyle color-white font40 mt20'>Device repairs & replacements are fast, easy, and hassle-free</div>
              </div>
              {this.state.section2.map((item, index) => (
                <div key={index} className='col-sm-12 col-lg-4'>
                  <div className='box-holder1 section3'>
                    <img className='color-white' src={item.img} />
                    <div className='boldFontStyle mt8 mb20 color-white font20'>{item.title}</div>
                    <p
                      className='fontLarge color-white-opacity'
                      dangerouslySetInnerHTML={{
                        __html: item.text,
                      }}
                    />
                  </div>
                </div>
              ))}
              <div className='col-sm-12 col-lg-12 mt28'>
                <MediumButton
                  className='mt8 boldFontStyle white-bg-color primary-color'
                  buttonText='File a Claim'
                  onClick={() => {
                    browserHistory.push(loginUrl)
                  }}
                />
              </div>
            </div>
          </div>

          <div className='padding-zero empty-route-container1 white-bg-color pr mb30 desktop'>
            <img src={`/${appName}/images/mycare/banner_plan_protect_bg.jpg`} />
            <img onClick={this.toggleModal} className='video-thumbnail' src={`/${appName}/images/mycare/video-thumbnail.png`} />
            <div className='text-alignment'>
              <div className='font48 boldFontStyle text-center mt40'>Protection for your Galaxy life</div>
              <div className='bookFontStyle mt10 mb48 font24 text-center'>Learn more about the benefits of Samsung Care+</div>
            </div>

            <div className='mobile-care-plus-btn button-alignment'>
              <MediumButton className='boldFontStyle' buttonText='Purchase Care+' onClick={this.handleBrowserRidirection} />
              <MediumButton
                className='ml20 button-ghost boldFontStyle'
                buttonText='Explore Benefits'
                onClick={() => {
                  browserHistory.push('/mycare/benefits')
                }}
              />
            </div>
          </div>

          <div className='padding-zero empty-route-container1 mobile'>
            <div className='container home-components1 mb28'>
              <div className='col-sm-12 mb48'>
                <div className='boldFontStyle font48 mt40'>Protection for your Galaxy life</div>
                <div className='bookFontStyle mt10 mb48 font24'>Learn more about the benefits of Samsung Care+</div>
                <img
                  onClick={this.toggleModal}
                  className='mobile-video-thumbnail mb20'
                  src={`/${appName}/images/mycare/video-thumbnail.png`}
                />
                <MediumButton className='boldFontStyle mt20' buttonText='Purchase Care+' onClick={this.handleBrowserRidirection} />
                <MediumButton
                  className='mt20 button-ghost boldFontStyle'
                  buttonText='Explore Benefits'
                  onClick={() => {
                    browserHistory.push('/mycare/benefits')
                  }}
                />
              </div>
            </div>
          </div>

          <div className='empty-route-container1'>
            <div className='row home-components1 mb28'>
              <div className='col-sm-12 col-lg-12 mb48'>
                <p className='fontLarge boldFontStyle primaryColor'>ULTIMATE PROTECTION</p>
                <div className='font40 boldFontStyle mt8'>Samsung Care+ with Theft and Loss Benefits</div>
                <div className='font20 bookFontStyle mt20'>
                  The Ultimate Protection for your Galaxy life covers Unlimited Drops, Spills, and Mechanical Breakdowns plus Theft and Loss
                  coverage
                </div>
              </div>
              {this.state.section3.map((item, index) => (
                <div key={index} className='col-sm-12 col-lg-4'>
                  <div className='box-holder1 section3'>
                    <img src={item.img} />
                    <div className='font20 boldFontStyle mt8 mb20'>{item.title}</div>
                    <p className='fontLarge' style={{ color: '#808192' }}>
                      {item.text}
                    </p>
                  </div>
                </div>
              ))}
              <div className='col-sm-12 col-lg-12 mt15rem'>
                <MediumButton
                  className='mt8 button-ghost boldFontStyle'
                  buttonText='View Additional Plans'
                  onClick={() => {
                    browserHistory.push('/mycare/benefits')
                  }}
                />
              </div>
            </div>
          </div>

          <div className='empty-route-container1 black-bg-color' id='support'>
            <div className='row home-components1 display-flex mt28 mb28'>
              <div className='col-sm-12 col-lg-8'>
                <div className='font20 color-white mb8'>Didn't find what you are looking for?</div>
                <div className='font40 boldFontStyle color-white'>Connect with us for support.</div>
              </div>
              <div className='col-sm-12 col-lg-3 mobile-support-section'>
                <div
                  className='bookFontStyle color-white fontLarge'
                  onClick={() => {
                    window.location.href = 'mailto:' + 'samsungmxcare@servify.tech'
                  }}
                >
                  <img src={`/${appName}/images/mycare/support/mail.svg`} className='mr8 color-white fontLarge' />
                  <span className='color-white'>samsungmxcare@servify.tech</span>
                </div>
                <div className='bookFontStyle color-white mt20'>
                  <img src={`/${appName}/images/mycare/support/phone.svg`} className='mr8 color-white fontLarge' />
                  (833)690-0918
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal showModal={this.state.showVideoPlayer} closeModal={() => this.toggleModal} className='sfy-modal youtube-video-modal'>
          <div className={'close-icon ' + (window.isDirectionRTL ? ' text-left ' : ' text-right')}>
            <CustomIconComponent
              className={'close-icon ' + (window.isDirectionRTL ? ' text-left ' : ' text-right')}
              onClick={this.toggleModal}
              alt='Cancel'
            />
          </div>

          <div className='text-center desktop'>
            <iframe
              className=''
              width='700'
              height='500'
              frameBorder='0'
              scrolling='no'
              autoPlay='1'
              src='https://www.youtube.com/embed/mqSXfYe69BI?&theme=dark&autohide=1&frameborder=0&rel=0&fs=0&showinfo=0&modestbranding=1&autoplay=1&mute=0'
            ></iframe>
          </div>

          <div className='text-center mobile'>
            <iframe
              className=''
              width='300'
              height='250'
              frameBorder='0'
              scrolling='no'
              autoPlay='1'
              src='https://www.youtube.com/embed/mqSXfYe69BI?&theme=dark&autohide=1&frameborder=0&rel=0&fs=0&showinfo=0&modestbranding=1&autoplay=1&mute=0'
            ></iframe>
          </div>
        </Modal>
      </>
    )
  }
}
