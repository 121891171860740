/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import { linkify } from '../../../utils/regex'
import { BoldHeading, LightHeading, SubHeading, Loader, BoxHolder, MediumButton, RadioBox } from '../../../components/UIComponents'
import { Slider, DotProgressBar } from 'sfy-react'
import Select from 'react-select'
import 'react-select/dist/react-select.css'
import { getTranslationText } from '../../../utils/languageTranslation.js'
// import { ReactComponent as RightArrow } from '../../../../public/commonAssets/right-arrow.svg'
import { ReactComponent as EscalateIcon } from '../../../../public/commonAssets/escalate.svg'
// const trackpoints = `/${appName}/images/festival/diwali/trackpoints.png`
import { browserHistory } from 'react-router'
import { getLocaleTime } from '../../../utils/locale'
import { getFormattedTime } from '../../../utils/functions'
import pushToAnalytics from '../../../utils/Analytics'

export class TrackComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedProduct: _.get(props, 'trackDetails.ConsumerProduct', {}),
      AllowVideoUpload: false,
    }
  }
  selectedDeviceFunc = (info) => {
    if (info.refId !== this.state.selectedProduct.ConsumerProductID) {
      this.setState(
        {
          selectedProduct: this.props.trackDetails.ExternalConsumerProducts.find((val) => val.ConsumerProductID === info.refId),
        },
        () => {
          window.location.href =
            window.location.origin + '/track?id=' + window.btoa(this.state.selectedProduct.ConsumerServiceRequestID.toString())
        }
      )
    }
  }

  render() {
    let brandEmail = _.get(this, 'props.trackDetails.SupportInfo.Email[0]', 'support@servify.in')
    let productsList = _.get(this, 'props.trackDetails.ExternalConsumerProducts', [])
    return (
      <TabHolder>
        <div className='removeMargin' style={{ width: '100%' }}>
          <div className='mb20 mt20'>
            {productsList.length > 1 ? (
              <Select
                clearable={false}
                searchable={false}
                onChange={this.selectedDeviceFunc}
                value={{
                  ...this.state.selectedProduct,
                  value: _.get(this, 'state.selectedProduct.ConsumerProductID'),
                  label: (
                    <React.Fragment>
                      <div className='mediumFontStyle'>{_.get(this, 'state.selectedProduct.product.ProductName', '')}</div>
                      <div className='fontSmall'>
                        {getTranslationText('track', 'referenceId')}: {this.props.ReferenceID}
                      </div>
                    </React.Fragment>
                  ),
                }}
                options={productsList.map((info) => ({
                  label: (
                    <React.Fragment>
                      <div className='mediumFontStyle'>{_.get(info, 'product.ProductName', '')}</div>
                      <div className='fontSmall'>
                        {getTranslationText('track', 'referenceId')}: {info.ReferenceID}
                      </div>
                    </React.Fragment>
                  ),
                  value: info.ConsumerProductID,
                  refId: info.ConsumerProductID,
                }))}
              />
            ) : (
              <React.Fragment>
                <BoldHeading>
                  {_.get(this, 'state.selectedProduct.TagName', '') || _.get(this, 'state.selectedProduct.product.ProductName', '')}
                </BoldHeading>
                <LightHeading>
                  {getTranslationText('track', 'referenceId')}: {this.props.ReferenceID}
                </LightHeading>
              </React.Fragment>
            )}
          </div>
          <Loader loaderState={this.props.loaderState}>
            <div>
              <div className='tab-header'>
                <Tab
                  id={0}
                  handleClick={this.props.handleTabClick}
                  loaderState={this.props.loaderState}
                  selectedTab={this.props.selectedTab}
                >
                  {getTranslationText('track', 'trackRequest')}
                </Tab>
                <Tab
                  id={1}
                  handleClick={(e) => {
                    this.props.handleTabClick(e)
                    pushToAnalytics('Request_details_tab', {
                      ReferenceID: (this.props.trackDetails && this.props.trackDetails.ReferenceID) || '',
                    })
                  }}
                  loaderState={this.props.loaderState}
                  selectedTab={this.props.selectedTab}
                >
                  {getTranslationText('track', 'requestDetails')}
                </Tab>
                <div className='escalate-label' onClick={() => (window.fcWidget ? window.fcWidget.open() : false)}>
                  <a
                    href={
                      window.fcWidget
                        ? false
                        : `mailto:` +
                          brandEmail +
                          `?subject=Request Reference ID ${this.props.trackDetails && this.props.trackDetails.ReferenceID}
                          &body=I need a help with my service request.`
                    }
                    className='escalateLabel'
                    id='pingpongchat'
                  >
                    <EscalateIcon />
                    <span id='pingpongchat' className='escalateText'>
                      {getTranslationText('track', 'requestEscalate')}
                    </span>
                  </a>
                </div>
              </div>
              {this.props.loaderState ? (
                ''
              ) : (
                <div className='col-sm-12 tab-content'>
                  {parseInt(this.props.selectedTab) === 0 ? <TrackSliderComponent {...this.props} /> : ''}
                  {parseInt(this.props.selectedTab) === 1 ? <TrackDetailsComponent {...this.props} /> : ''}
                </div>
              )}
            </div>
          </Loader>
        </div>
      </TabHolder>
    )
  }
}

TrackComponent.propTypes = {
  ReferenceID: PropTypes.string.isRequired,
  ConsumerProduct: PropTypes.object,
  handleTabClick: PropTypes.func.isRequired,
  selectedTab: PropTypes.number,
  loaderState: PropTypes.bool,
  listDownServiceCenters: PropTypes.bool,
  requestCancelledOrConverted: PropTypes.bool,
  user: PropTypes.object.isRequired,
  trackDetails: PropTypes.object.isRequired,
  request: PropTypes.object.isRequired,
  handleDropOffCenterChange: PropTypes.func.isRequired,
}

const TrackSliderComponent = (props) => {
  const locale = _.get(props, 'user.selectedCountryData.countryConfig.Locale.DEFAULT', 'en')
  let shouldShowInCaseOfDropoff = _.includes([17, 23], props.requestDetails.ServiceTypeID)
  let revLogs = [...props.logs]
  let lastGroupId =
    revLogs.length && revLogs.reverse().find((log) => log.DisplayInfo.GroupId && !log.DisplayInfo.Hidden).DisplayInfo.GroupId
  let newGroups = props.groupDetails.filter(
    (group) =>
      (group.groupId > lastGroupId && !group.optionalGroup) ||
      props.logs.findIndex((log) => log.DisplayInfo.GroupId === group.groupId && !log.DisplayInfo.Hidden) !== -1
  )

  let activeSlideIndex = _.findIndex(newGroups, (group) => group.groupId === lastGroupId)
  let disableNormalRepair = !_.get(props, 'user.appConfigDetails.showNormalRepairOptions', true)
  let hideRaiseRequestAgain = _.get(props, 'user.appConfigDetails.hideRaiseRequestAgain', false)
  let pickMode =
    (props.request.requestModesData &&
      props.request.requestModesData.modes &&
      props.request.requestModesData.modes.find((mode) => mode.IsAvailable && [1, 11].includes(mode.ServiceTypeID))) ||
    undefined

  let dropOffMode =
    (props.request.requestModesData &&
      props.request.requestModesData.modes &&
      props.request.requestModesData.modes.find((mode) => mode.IsAvailable && [17, 23].includes(mode.ServiceTypeID))) ||
    undefined
  // let isFestivalTheme = false
  let isFestivalTheme =
    'Consumer-Web'.includes(_.get(props, 'user.appConfigDetails.app', '')) &&
    moment('2022-01-07').isAfter(moment().format('YYYY-MM-DD'), 'day')
  return (
    <div className='row'>
      {newGroups.length > 0 && (
        <div className='col-sm-12 mb20'>
          <DotProgressBar
            currentIndex={newGroups.findIndex((group) => group.groupId === lastGroupId)}
            progressBarItems={newGroups.map((group) => group.groupName)}
            isFestivalTheme={isFestivalTheme}
          />
        </div>
      )}
      {newGroups.length ? (
        <div className='col-sm-12 track-slider-container' style={isFestivalTheme ? { marginBottom: '19.5rem' } : {}}>
          <Slider
            spacing={10}
            config={[
              { breakpoint: 0, items: 1 }, // for screen width above 0 show 1 item per slide
              { breakpoint: 600, items: 2 }, // for screen width above 600 show 2 items per slide
              { breakpoint: 992, items: 3 }, // for screen width above 992 show 3 items per slide
              { breakpoint: 1200, items: 4 }, // for screen width above 1200 show 4 items per slide
            ]}
            activeSlideIndex={activeSlideIndex}
            isRTL={window.isDirectionRTL}
          >
            {newGroups.map((group) => {
              let Activity = _.findLast(props.logs, (activity) => {
                return activity.DisplayInfo.GroupId === group.groupId && !activity.DisplayInfo.Hidden
              })
              let isActiveLog = group.groupId === lastGroupId
              let sliderClassName = group.groupId === lastGroupId ? 'active' : Activity && group.groupId < lastGroupId ? 'complete' : ''

              let showLogisticsInstruction = sliderClassName && isActiveLog && Activity.DisplayInfo.ShowLogisticsInstruction
              let showDocument = sliderClassName && isActiveLog && Activity.DisplayInfo.ShowDocument
              let showBottomSheet = sliderClassName && isActiveLog && Activity.DisplayInfo.ShowBottomSheet
              const Remarks = _.get(Activity, 'DisplayInfo.DisplayRemark', '') || _.get(Activity, 'Remarks', '')

              const isAdvanceExchangeCardHoldSuccess = _.includes(['FAILED'], _.get(props, 'track.trackDetails.CARD_HOLD'))
              return (
                <div key={group.groupId} className={sliderClassName}>
                  <SubHeading className='' title={sliderClassName ? Activity.DisplayInfo.DisplayText : group.groupName} />
                  <div className='date-div mt8'>
                    {sliderClassName ? (
                      <span>
                        {`${getLocaleTime(new Date(Activity.UpdatedDate), locale)},
                         ${moment(Activity.UpdatedDate).format('DD MMMM YYYY')}`}
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                  {Remarks && sliderClassName ? (
                    <div className='box-content-remarks mt8' dangerouslySetInnerHTML={{ __html: linkify(Remarks) }} />
                  ) : (
                    ''
                  )}
                  {sliderClassName === 'active' ? (
                    isFestivalTheme ? (
                      // <img src={trackpoints} className='box-content-dot' />
                      <div className='box-content-dot' />
                    ) : (
                      <div className='box-content-dot' />
                    )
                  ) : (
                    ''
                  )}
                  <div className='slide-btn mt20 align-bottom'>
                    {sliderClassName && isActiveLog && Activity.DisplayInfo.ShowRequireReplacementImei ? (
                      <MediumButton
                        className='button-small button-ghost mr8'
                        id='add-device-imei'
                        buttonText={`Add New Device Details`}
                        onClick={props.ModalCall}
                      />
                    ) : (
                      ''
                    )}
                    {(
                      sliderClassName && isActiveLog && hideRaiseRequestAgain
                        ? ''
                        : sliderClassName && isActiveLog && Activity.DisplayInfo.RaiseRequestAgain
                    ) ? (
                      <MediumButton
                        className='button-small button-ghost mr8'
                        id='rating-req'
                        buttonText={getTranslationText('track', 'raiseANormalRepair')}
                        onClick={props.navtoRequestModes}
                      />
                    ) : (
                      ''
                    )}
                    {sliderClassName && isActiveLog && Activity.DisplayInfo.RaiseRequest ? (
                      Activity.DisplayInfo.IsNormalRequest ? (
                        disableNormalRepair ? (
                          ''
                        ) : (
                          <MediumButton
                            className='button-small button-ghost mr8'
                            id='rating-req'
                            buttonText={getTranslationText('track', 'raiseANormalRepair')}
                            onClick={props.navtoRequestModes}
                          />
                        )
                      ) : (
                        <MediumButton
                          className='button-small button-ghost mr8'
                          id='rating-req'
                          buttonText={getTranslationText('track', 'scheduleARepair')}
                          onClick={props.navtoRequestModes}
                        />
                      )
                    ) : (
                      ''
                    )}
                    {sliderClassName && isActiveLog && Activity.DisplayInfo.HasAdjudication ? (
                      <MediumButton
                        className='button-small button-ghost mr8'
                        id='rating-req'
                        buttonText={Activity.DisplayInfo.ButtonText}
                        onClick={props.navtoRequestModes}
                      />
                    ) : (
                      ''
                    )}
                    {sliderClassName !== 'complete' && isActiveLog && Activity.DisplayInfo.ShowPay && (
                      <MediumButton
                        className='button-small button-ghost mr8'
                        id='show-payment'
                        buttonText={getTranslationText('track', 'viewInvoiceBreakdown')}
                        onClick={props.ModalCall}
                        btnLoader={props.BtnStatusLoader}
                      />
                    )}
                    {sliderClassName !== 'complete' &&
                      isActiveLog &&
                      Activity.DisplayInfo.ShowRating &&
                      props.trackDetails.IsRatedByConsumer < 1 && (
                        <MediumButton
                          className='button-small button-ghost'
                          id='show-rating-btn'
                          buttonText={getTranslationText('track', 'rateyourExperience')}
                          onClick={(e) => {
                            props.ModalCall(e)
                            pushToAnalytics('Rate_your_experience_button_clicked', {
                              ReferenceID: (props.trackDetails && props.trackDetails.ReferenceID) || '',
                            })
                          }}
                        />
                      )}
                    {sliderClassName && isActiveLog && Activity.DisplayInfo.ShowEstimation ? (
                      <MediumButton
                        className='button-small button-ghost mr8'
                        id='show-estimated-invoice'
                        buttonText={getTranslationText('track', 'showEstimation')}
                        onClick={props.ModalCall}
                      />
                    ) : (
                      ''
                    )}
                    {sliderClassName && isActiveLog && Activity.DisplayInfo.ShowBER ? (
                      <MediumButton
                        className='button-small button-ghost mr8'
                        id='initiate-BERCase'
                        buttonText={getTranslationText('track', 'checkOptions')}
                        onClick={props.ModalCall}
                      />
                    ) : (
                      ''
                    )}
                    {sliderClassName && isActiveLog && Activity.DisplayInfo.ShowDocument && !props.trackDetails.VideoDocumentRequired ? (
                      <MediumButton
                        className='button-small button-ghost mr8'
                        id='BER-upload-docs'
                        buttonText={getTranslationText('track', 'uploadDocument')}
                        onClick={props.ModalCall}
                      />
                    ) : (
                      ''
                    )}
                    {sliderClassName && isActiveLog && Activity.DisplayInfo.ShowTreatments ? (
                      <MediumButton
                        className='button-small button-ghost mr8'
                        id='show-treatments'
                        buttonText={getTranslationText('track', 'showNextSteps')}
                        onClick={props.ModalCall}
                      />
                    ) : (
                      ''
                    )}
                    {sliderClassName && isActiveLog && Activity.DisplayInfo.ShowSwapOptions ? (
                      <MediumButton
                        className='button-small button-ghost mr8'
                        id='show-swap-options'
                        buttonText={getTranslationText('track', 'showSwapOptions')}
                        onClick={props.ModalCall}
                      />
                    ) : (
                      ''
                    )}
                    {sliderClassName && isActiveLog && Activity.DisplayInfo.ShowAccessoriesOption ? (
                      <MediumButton
                        className='button-small button-ghost mr8'
                        id='accessories-Option'
                        buttonText={getTranslationText('track', 'checkOptions')}
                        onClick={props.ModalCall}
                      />
                    ) : (
                      ''
                    )}
                    {showLogisticsInstruction || (showBottomSheet && (showDocument || dropOffMode || pickMode)) ? (
                      <MediumButton
                        className='button-small button-ghost mr8'
                        id='track-Instruction'
                        buttonText={
                          shouldShowInCaseOfDropoff
                            ? getTranslationText('track', 'viewDropOffDetails')
                            : getTranslationText('track', 'viewDocuments')
                        }
                        onClick={(e) => props.ModalCall(e, Activity.DisplayInfo)}
                      />
                    ) : (
                      ''
                    )}
                    {sliderClassName &&
                    sliderClassName !== 'complete' &&
                    pickMode &&
                    Activity.DisplayInfo.ShowSchedulePickup &&
                    !shouldShowInCaseOfDropoff &&
                    isActiveLog ? (
                      <MediumButton
                        className='button-small button-ghost mr8'
                        id='schedule-to-pickup'
                        buttonText={getTranslationText('track', 'schedulePickup')}
                        onClick={props.ModalCall}
                      />
                    ) : (
                      ''
                    )}
                    {/* (sliderClassName && isActiveLog)
                      ? <OutlineButton id='add-billing-details'
                        buttonText={getTranslationText('track', 'addAirwayBillingNumber')} onClick={props.ModalCall} /> : ''
                      */}
                    {sliderClassName && Activity.DisplayInfo.ShowEngineerDetail ? (
                      <MediumButton
                        className='button-small button-ghost mr8'
                        id='show-engineer-details'
                        buttonText={getTranslationText('track', 'showEngineerDetails')}
                        onClick={props.ModalCall}
                      />
                    ) : (
                      ''
                    )}
                    {sliderClassName && isActiveLog && Activity.DisplayInfo.UpdateCourierDetails ? (
                      <MediumButton
                        className='button-small button-ghost mr8'
                        id='courier-details'
                        buttonText={getTranslationText('track', 'updateCourierDetails')}
                        onClick={props.ModalCall}
                      />
                    ) : (
                      ''
                    )}
                    {sliderClassName &&
                    isActiveLog &&
                    props.trackDetails.Status === 'Document pending' &&
                    Activity.DisplayInfo.ShowDocument &&
                    props.trackDetails.CheckForValidDocument === 'video' &&
                    props.trackDetails.VideoDocumentRequired &&
                    _.includes([46, 63], props.trackDetails.ServiceTypeID) ? (
                      <MediumButton
                        className='button-small button-ghost'
                        id='upload-proof'
                        buttonText={
                          Activity.DisplayInfo.ButtonText ? Activity.DisplayInfo.ButtonText : getTranslationText('track', 'proofOfDamage')
                        }
                        onClick={props.ModalCall}
                      />
                    ) : (
                      ''
                    )}
                    {sliderClassName && isActiveLog && Activity.DisplayInfo.ChoosReplacementOption ? (
                      <MediumButton
                        className='button-small button-ghost'
                        id='show-estimated-invoice'
                        buttonText={getTranslationText('track', 'chooseReplacement')}
                        onClick={() => {
                          browserHistory.push({
                            pathname: '/track/choose-replacement',
                            query: props.location.query,
                          })
                        }}
                      />
                    ) : (
                      ''
                    )}
                    {sliderClassName && isActiveLog && isAdvanceExchangeCardHoldSuccess ? (
                      <MediumButton
                        className='button-small button-ghost mr10'
                        id='retry-payment'
                        buttonText={getTranslationText('common', 'retry-payment')}
                        onClick={props.initiatePayment}
                      />
                    ) : (
                      ''
                    )}
                    {_.get(props, 'user.appConfigDetails.showReassignServiceCentre', false) &&
                    sliderClassName &&
                    isActiveLog &&
                    Activity.DisplayInfo.ShowReassignServiceLocation ? (
                      <MediumButton
                        className='button-small button-ghost mr10'
                        id='change-repair-location'
                        buttonText={getTranslationText('track', 'change-repair-location')}
                        onClick={() => props.navtoRequestModes('showReassignServiceLocation')}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              )
            })}
          </Slider>
        </div>
      ) : (
        ''
      )}
      {isFestivalTheme && <div className='festival-footer-img' />}
    </div>
  )
}

TrackSliderComponent.propTypes = {
  groupDetails: PropTypes.array.isRequired,
  logs: PropTypes.array.isRequired,
  trackDetails: PropTypes.object.isRequired,
  ModalCall: PropTypes.func.isRequired,
  navtoRequestModes: PropTypes.func.isRequired,
  BtnStatusLoader: PropTypes.bool.isRequired,
  sliderSettings: PropTypes.object.isRequired,
  request: PropTypes.object.isRequired,
}

TrackSliderComponent.defaultProps = {
  groupDetails: [],
  logs: [],
}

const TrackDetailsComponent = (props) => {
  let csrDeliveryDetails = _.get(props, 'requestDetails.consumerServiceRequestDeliveryDetail[0]')
  let damageDetails = _.get(props, 'requestDetails.damage[0]')
  let scheduleDate = _.get(props, 'logisticsDetails.RescheduleDate') || _.get(props, 'requestDetails.ScheduledDateTime')
  let scheduledFromTime = _.get(props, 'logisticsDetails.ScheduledFromTime') || _.get(props, 'requestDetails.ScheduledFromTime')
  let scheduledToTime = _.get(props, 'logisticsDetails.ScheduledToTime') || _.get(props, 'requestDetails.ScheduledToTime')
  const locale = _.get(props, 'user.selectedCountryData.countryConfig.Locale.DEFAULT', 'en')
  let latestLog = _.get(props, 'track.trackDetails.Logs').find((log) => log.DisplayInfo.GroupId && !log.DisplayInfo.Hidden)
  const showWalkinPromptDetails = _.get(props, 'user.appConfigDetails.showWalkinPromptDetails', false)
  return (
    <div className='row'>
      <div className='col-sm-12'>
        <div className='row trackflow-details-container'>
          <div className='col-sm-2 col-6 mb20'>
            <label className='track-label'>{getTranslationText('track', 'referenceId')}</label>
            <br />
            <label className='track-value'>{props.requestDetails.ReferenceID}</label>
          </div>
          <div className='col-sm-2 col-6 mb20'>
            <label className='track-label'>{getTranslationText('common', 'device')}</label>
            <br />
            <label className='track-value'>{props.requestDetails.product.ProductName}</label>
          </div>
          <div className='col-sm-2 col-6 mb20'>
            <label className='track-label overrideCasing'>{getTranslationText('track', 'serviceType')}</label>
            <br />
            <label className='track-value'>{props.requestDetails.servicetype.Type}</label>
          </div>

          {_.includes([17, 23, 65], props.requestDetails.ServiceTypeID) ? (
            ''
          ) : scheduleDate ? (
            <div className='col-sm-3 col-6 mb20'>
              <label className='track-label overrideCasing'>{getTranslationText('track', 'scheduledDate')}</label>
              <br />
              <label dir='ltr' className='track-value'>
                {`${moment(scheduleDate).format('DD MMM, YYYY')}
                  ${_.get(props, 'requestDetails.ScheduledFromTime') ? ' | ' + getFormattedTime(scheduledFromTime, locale) : ''}
                  ${_.get(props, 'requestDetails.ScheduledToTime') ? ' - ' + getFormattedTime(scheduledToTime, locale) : ''}
                `}
              </label>
            </div>
          ) : (
            <div className='col-sm-3 col-6 mb20'>
              <label className='track-label overrideCasing'>{getTranslationText('track', 'createdDate')}</label>
              <br />
              <label className='track-value'>
                {moment(props.requestDetails.CreatedDate).format('DD MMM, YYYY') +
                  ' | ' +
                  getLocaleTime(new Date(props.requestDetails.CreatedDate), locale)}
              </label>
            </div>
          )}
          {_.includes([12], props.requestDetails.ServiceTypeID) && !_.isEmpty(props.requestDetails.ClientServiceLocationDetails) ? (
            <div className='col-sm-3 col-6 mb20'>
              <label className='track-label'>{getTranslationText('track', 'serviceLocation')}</label>
              <br />
              <label className='track-value'>
                <label className='track-value'>{props.requestDetails.ClientServiceLocationDetails.ClientServiceLocationName}</label>
                <label className='track-value'>{props.requestDetails.ClientServiceLocationDetails.ClientServiceLocationAddress}</label>
                {showWalkinPromptDetails && (
                  <label className='track-value'>
                    {getTranslationText('common', 'contact')} :{' '}
                    {props.requestDetails.ClientServiceLocationDetails?.ClientServiceLocationContact}
                  </label>
                )}
              </label>
            </div>
          ) : (
            ''
          )}
          {latestLog.DisplayInfo.ShowDropOffCenterDetail ? (
            <div className='col-sm-3 col-6 mb20'>
              <label className='track-label'>{getTranslationText('track', 'mail-in-address')}</label>
              <br />
              <label className='track-value'>
                <div className='mt4 fontSmall boldFontStyle special-link-txt near-by-centers' onClick={props.handleDropOffCenterChange}>
                  <span className='text-underline'>{getTranslationText('track', 'click-to-view')}</span>
                </div>
              </label>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      <div className='col-sm-12'>
        <div className='row trackflow-details-container'>
          <div className='col-sm-2 col-6 mb20'>
            <label className='track-label overrideCasing'>{getTranslationText('track', 'requestStatus')}</label>
            <br />
            <label className='track-value'>{props.requestDetails.Status}</label>
          </div>
          {csrDeliveryDetails ? (
            <div className={_.includes([17, 23], props.requestDetails.ServiceTypeID) ? 'col-sm-2 col-6 mb20' : 'col-sm-3 col-6 mb20'}>
              <label className='track-label'>
                {_.includes([17, 23], props.requestDetails.ServiceTypeID)
                  ? getTranslationText('common', 'returnAddress')
                  : getTranslationText('common', 'address')}
              </label>
              <br />
              <label className='track-value'>
                {_.get(props, 'track.trackDisplayUserAddress.value', []).length &&
                  _.get(props, 'track.trackDisplayUserAddress.value').map((v, i) => {
                    return v + (_.get(props, 'track.trackDisplayUserAddress.value').length !== i + 1 ? ', ' : '.')
                  })}
              </label>
            </div>
          ) : (
            ''
          )}
          {!_.includes([17, 23], props.requestDetails.ServiceTypeID) && csrDeliveryDetails ? (
            <div className='col-sm-3 col-6 mb20'>
              <label className='track-label overrideCasing'>{getTranslationText('common', 'deliveryAddress')}</label>
              <br />
              <label className='track-value'>
                {_.get(props, 'track.trackDisplayDeliveryAddress.value', []).length &&
                  _.get(props, 'track.trackDisplayDeliveryAddress.value').map((v, i) => {
                    return v + (_.get(props, 'track.trackDisplayDeliveryAddress.value').length !== i + 1 ? ', ' : '.')
                  })}
              </label>
            </div>
          ) : (
            ''
          )}
          {damageDetails && damageDetails.lossDateTime ? (
            <div className='col-sm-2 col-6 mb20'>
              <label className='track-label overrideCasing'>{getTranslationText('track', 'dateOfDamage')}</label>
              <br />
              <label className='track-value'>{moment(damageDetails.lossDateTime).format('DD MMM, YYYY')}</label>
            </div>
          ) : (
            ''
          )}
          {damageDetails && damageDetails.damageType ? (
            <div className='col-sm-2 col-6 mb20'>
              <label className='track-label overrideCasing'>{getTranslationText('common', 'typeOfDamage')}</label>
              <br />
              <label className='track-value'>{damageDetails.damageType}</label>
            </div>
          ) : (
            ''
          )}
          {damageDetails && damageDetails.descriptionOfLoss ? (
            <div className='col-sm-3 col-6 mb20'>
              <label className='track-label overrideCasing'>{getTranslationText('track', 'issueDescription')}</label>
              <br />
              <label className='track-value'>{damageDetails.descriptionOfLoss}</label>
            </div>
          ) : (
            ''
          )}
          {props.requestDetails.issues && props.requestDetails.issues[0] ? (
            <div className='col-sm-5 col-6 mb20'>
              <label className='track-label'>{getTranslationText('common', 'issues')}</label>
              <br />
              <label className='track-value'>
                {props.requestDetails.issues.map((issue, index) => (index === 0 ? '' : ' | ') + issue.IssueText)}
              </label>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      <div className='col-sm-12 Requestbtn-holder mt20'>
        {!_.includes([17, 23], props.requestDetails.ServiceTypeID) && props.trackDetails.isReschedulable && (
          <div className='mb8'>
            <MediumButton
              className='button-small button-ghost'
              id='RescheduleReq'
              buttonText={getTranslationText('track', 'rescheduleRequest')}
              onClick={props.ModalCall}
            />
          </div>
        )}
        {props.trackDetails.isCancelable && (
          <div className='mb8'>
            <MediumButton
              className='button-small button-ghost'
              id='CancelReq'
              buttonText={getTranslationText('track', 'cancelRequest')}
              onClick={(e) => {
                props.ModalCall(e)
                pushToAnalytics('cancel_request_button_clicked', {
                  ReferenceID: (props.trackDetails && props.trackDetails.ReferenceID) || '',
                })
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}

TrackDetailsComponent.propTypes = {
  // request: PropTypes.object.isRequired,
  requestDetails: PropTypes.object.isRequired,
  trackDetails: PropTypes.object.isRequired,
  ModalCall: PropTypes.func.isRequired,
}

TrackDetailsComponent.defaultProps = {
  requestDetails: {
    product: {},
    servicetype: {},
  },
}

const TabHolder = (props) => {
  return <div className='tab-holder'>{props.children}</div>
}

TabHolder.propTypes = {
  children: PropTypes.node,
}

const Tab = (props) => {
  return (
    <div
      id={props.id}
      className={
        'track-tab overrideCasing ' +
        (props.id === parseInt(props.selectedTab) ? 'active' : '') +
        (props.loaderState ? ' loader-cursor ' : '')
      }
      onClick={(e) => {
        !props.loaderState && props.handleClick(e)
      }}
    >
      {props.children}
    </div>
  )
}

Tab.propTypes = {
  id: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  handleClick: PropTypes.func.isRequired,
  selectedTab: PropTypes.number.isRequired,
  loaderState: PropTypes.bool,
}

export const ReplacementOptions = (props) => {
  const [replacementOptions, setReplacementOptions] = useState([])
  const [isReimbursement, setIsReimbursement] = useState(false)

  useEffect(() => {
    setReplacementOptions(_.get(props.track, 'trackDetails.replacementOption', []))
  }, [props.track])

  const handleOptionSelection = (selectedOption) => {
    let updatedOptions = [...replacementOptions]
    if (selectedOption === 'reimbursement') {
      setIsReimbursement(true)
      updatedOptions = updatedOptions.map((option) => ({ ...option, isSelected: false }))
    } else {
      setIsReimbursement(false)
      updatedOptions = updatedOptions.map((option) => {
        if (option.Option === selectedOption) {
          return { ...option, isSelected: true }
        }
        return { ...option, isSelected: false }
      })
    }
    setReplacementOptions(updatedOptions)
  }

  const handleConfirmation = () => {
    if (isReimbursement) {
      let deviceInfo = props.devices.deviceDetails.supportedDevices.find(
        (data) => data.ConsumerProductID === props.track.trackDetails.ConsumerProduct.ConsumerProductID
      )
      props.setSelectedDevice(deviceInfo)
      props.getPayoutModesAsync({ ServiceTypeID: 63 })
      browserHistory.push({
        pathname: '/request/paymentmodes',
        query: {
          claim: true,
          csrid: window.btoa(_.get(props, 'track.requestDetails.ConsumerServiceRequestID')),
          type: 'repair',
          from: 'replacement-options',
        },
      })
    } else {
      const { Option } = replacementOptions.find((option) => option.isSelected)
      props.setglobalLoaderStatus(true)
      props
        .storeReplacementOptions({
          ConsumerServiceRequestID: _.get(props, 'track.requestDetails.ConsumerServiceRequestID'),
          SelectedReplacementOption: Option,
        })
        .then(() => {
          window.location.href =
            window.location.origin + '/track?id=' + window.btoa(_.get(props, 'track.requestDetails.ConsumerServiceRequestID').toString())
        })
        .catch((e) => {
          console.log(e)
          props.setToaster(getTranslationText('error', 'somethingWentWrong'))
        })
        .finally(() => {
          props.setglobalLoaderStatus(false)
        })
    }
  }

  return (
    <BoxHolder className='replacement-options-container'>
      <div className='replacement-options-header fontLarge bookFontStyle'>{getTranslationText('track', 'chooseReplacement')}</div>
      <div className='replacement-options-list mt40 mb28'>
        {replacementOptions &&
          replacementOptions.map((option) => (
            <RadioBox
              className={{ container: 'replacement-options-radio', content: 'replacement-options-radio-content' }}
              isSelected={option.isSelected}
              handleOnClick={() => {
                handleOptionSelection(option.Option)
              }}
              key={option.Option}
            >
              <div className='fontSmall mediumFontStyle'>
                <div className='mb4'>{option.OptionValues.BrandName}</div>
                <div className='mb4'>{option.OptionValues.ProductName}</div>
              </div>
              <div className='fontSmall lightFontStyle'>
                {option.OptionValues.Graphic && <div>{'Graphic: ' + option.OptionValues.Graphic}</div>}
                {option.OptionValues.RAM && <div>{'RAM: ' + option.OptionValues.RAM}</div>}
                {option.OptionValues.Storage && <div>{'Storage: ' + option.OptionValues.Storage}</div>}
                {option.OptionValues.Colour && <div>{'Color: ' + option.OptionValues.Colour}</div>}
              </div>
            </RadioBox>
          ))}
        {![65].includes(_.get(props, 'track.trackDetails.ServiceTypeID', 0)) && (
          <RadioBox
            className={{ container: 'replacement-options-radio', content: 'replacement-options-radio-content' }}
            isSelected={isReimbursement}
            handleOnClick={() => {
              handleOptionSelection('reimbursement')
            }}
          >
            <div className='fontSmall mediumFontStyle'>
              <div className='mb4'>{getTranslationText('track', 'getReimbursed')}</div>
            </div>
            <div className='fontSmall lightFontStyle txt_noCase'>{getTranslationText('track', 'reimbursementDetails')}</div>
          </RadioBox>
        )}
      </div>
      <hr />
      <div className='replacement-options-buttons mt28'>
        <MediumButton
          className='button-small button-ghost mr20'
          buttonText={getTranslationText('common', 'backLabel')}
          onClick={browserHistory.goBack}
        />
        <MediumButton
          className='button-small mr20'
          buttonText={getTranslationText('common', 'confirm')}
          onClick={handleConfirmation}
          disabled={!(replacementOptions.some((option) => option.isSelected) || isReimbursement)}
        />
      </div>
    </BoxHolder>
  )
}
