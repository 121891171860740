import React from 'react'
import PropTypes from 'prop-types'
import { mapDefaultStyles } from '../../../utils/constants'
import { GoogleMap, withGoogleMap, withScriptjs, DirectionsRenderer, Marker, Polyline } from 'react-google-maps'

export class MapComponent extends React.Component {
  onMapMounted = (ref) => {
    if (ref) {
      this.mapRef = ref
      let bounds = new google.maps.LatLngBounds()
      let markers = this.props.markers || []
      markers.length &&
        markers.map((mk) => {
          let pos = new google.maps.LatLng(mk.lat, mk.lng)
          bounds.extend(pos)
        })
      this.mapRef && this.mapRef.fitBounds(bounds)
    }
  }
  render() {
    return (
      <MapWrapper
        {...this.props}
        loadingElement={<div style={{ height: `100%` }} />}
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=places,geometry&language=${
          this.props.language ? this.props.language.toLowerCase() : 'en'
        }`}
        onMapMounted={this.onMapMounted}
        containerElement={<div style={{ height: this.props.mapHeight || '150px' }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
    )
  }
}

MapComponent.propTypes = {
  mapHeight: PropTypes.string,
  markers: PropTypes.array,
}

export const MapWrapper = withScriptjs(
  withGoogleMap((props) => {
    let mapDefValues = {
      center: {
        lat: 19.1121049,
        lng: 72.861073,
      },
      zoom: 15,
      options: {
        keyboardShortcuts: false,
        disableDefaultUI: true,
        // gestureHandling: false,
        zoomControl: false,
        // scrollwheel: false,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        styles: mapDefaultStyles.styles,
        ...props.mapStyles, // override defStyles
      },
      ...props.mapProps, // override default values
    }
    let markerOptions = {
      animation: google.maps.Animation.DROP,
    }

    const baseURL = window.location.origin

    return (
      <GoogleMap
        defaultZoom={mapDefValues.zoom}
        defaultCenter={mapDefValues.center}
        options={mapDefValues.options}
        ref={props.onMapMounted}
      >
        {props.directions && <DirectionsRenderer directions={props.directions} />}
        {props.markers &&
          !props.directions &&
          props.markers.length &&
          props.markers.map((pos, index) => (
            <Marker
              key={index}
              position={pos}
              icon={
                pos?.isUserAddress
                  ? `${baseURL}/commonAssets/UserAddressMarker.png`
                  : pos?.isSelectedPSL
                  ? `${baseURL}/commonAssets/pin.gif`
                  : `${baseURL}/commonAssets/defaultMarker.png`
              }
              title={`${index + 1}. ${pos.title}`}
              label={''}
              animation='google.maps.Animation.DROP'
              optimized='false'
              {...markerOptions}
            />
          ))}
        {props.path && !props.directions && <Polyline options={{ path: props.path }} />}
      </GoogleMap>
    )
  })
)

MapWrapper.propTypes = {
  mapStyles: PropTypes.object,
  mapProps: PropTypes.object,
  directions: PropTypes.object,
  markers: PropTypes.array,
  path: PropTypes.array,
}
