/* eslint-disable */
// ------------------------------------
// Constants
// ------------------------------------
import { api } from '../config/variables'
import { setToaster } from './toastsAndLoaders'
// import { productAuthErrorMessages } from '../utils/constants'
import { updateDevicesAsync } from './devices'
import _ from 'lodash'
import { getTranslationText } from '../utils/languageTranslation.js'
import { filterPlanDetails } from '../utils/planDetails'
import { BrandSpecificConfig } from '../utils/constants'
import pushToAnalytics from '../utils/Analytics.js'

export const SET_STATE_LIST = 'SET_STATE_LIST'
export const SELECTED_PLAN_DETAILS = 'SELECTED_PLAN_DETAILS'
export const SET_PLANS_LIST = 'SET_PLANS_LIST'
export const SET_PLANS_LIST_FOR_IMEI = 'SET_PLANS_LIST_FOR_IMEI'
export const SET_PRODUCT_NAME = 'SET_PRODUCT_NAME'
export const SET_EXTERNAL_ORIGIN_SOURCE = 'SET_EXTERNAL_ORIGIN_SOURCE'
export const SET_IMEI_FROM_TOKEN = 'SET_IMEI_FROM_TOKEN'
export const SET_IMEI_FOR_FILTER = 'SET_IMEI_FOR_FILTER'
export const SET_FETCHING_PLANS = 'SET_FETCHING_PLANS'
export const SET_USER_DATA = 'SET_USER_DATA'
export const SET_PLAN_PAYMENT_DETAILS = 'SET_PLAN_PAYMENT_DETAILS'
export const SET_BRANDID_FOR_PLANS = 'SET_BRANDID_FOR_PLANS'
export const STORE_CHECKOUT_PARAMS = 'STORE_CHECKOUT_PARAMS'
export const SET_REQUIRED_PAYMENT_PARAMS = 'SET_REQUIRED_PAYMENT_PARAMS'
export const VALIDATED_ZIPCODE = 'VALIDATED_ZIPCODE'
export const SAVE_ZIPCODE_STATE = 'SAVE_ZIPCODE_STATE'
export const BUY_PLAN_STATUS = 'BUY_PLAN_STATUS'
export const SET_RECOMMENDED_PLANS = 'SET_RECOMMENDED_PLANS'
export const SET_BRAND_LIST = 'SET_BRAND_LIST'
export const SET_ELIGIBLE_DEVICE_INFO = 'SET_ELIGIBLE_DEVICE_INFO'
export const SET_BRAND_BANNERS = 'SET_BRAND_BANNERS'
export const SET_PLAN_CONSUMER_PRODUCT = 'SET_PLAN_CONSUMER_PRODUCT'
export const SET_PURCHASE_ELIGIBILITY_INFO = 'SET_PURCHASE_ELIGIBILITY_INFO'
export const SET_FETCHED_PLANPRICE_BY_PRODUCT = 'SET_FETCHED_PLANPRICE_BY_PRODUCT'
export const SET_PRIORITYQUEUE_QUES = 'SET_PRIORITYQUEUE_QUES'
export const SET_DEPENDENCYQUEUE_QUES = 'SET_DEPENDENCYQUEUE_QUES'
export const SET_CURRENTQUEUE_QUES = 'SET_CURRENTQUEUE_QUES'
export const SET_CUSTOM_PLAN_PRICE = 'SET_CUSTOM_PLAN_PRICE'
export const SET_POST_PAYMENT_ACTION_OBJECT = 'SET_POST_PAYMENT_ACTION_OBJECT'
export const SET_CUSTOM_PLAN_PRODUCT_PRICE = 'SET_CUSTOM_PLAN_PRODUCT_PRICE'
export const SET_CUSTOM_PLAN_DOP = 'SET_CUSTOM_PLAN_DOP'
export const SET_PLAN_ACTIVATED = 'SET_PLAN_ACTIVATED'
export const SET_IPID_URL = 'SET_IPID_URL'
export const SET_OTHER_RELATED_DOCS_URL = 'SET_OTHER_RELATED_DOCS_URL'
export const SET_PLAN_PRODUCTS = 'SET_PLAN_PRODUCTS'
export const SET_PAYMENT_ON_ACTIVATION = 'SET_PAYMENT_ON_ACTIVATION'
export const SET_PURCHASE_PLAN_NO_PAYMENT = 'SET_PURCHASE_PLAN_NO_PAYMENT'
export const SET_DISCOUNT_DETAILS = 'SET_DISCOUNT_DETAILS'
export const SET_AVAILABLE_PAYMENT_OPTIONS = 'SET_AVAILABLE_PAYMENT_OPTIONS'
export const SET_SELECTED_PAYMENT_OPTION = 'SET_SELECTED_PAYMENT_OPTION'
export const SET_CONSUMER_ADDRESS = 'SET_CONSUMER_ADDRESS'
export const SET_TAX_ADDRESS = 'SET_TAX_ADDRESS'
export const SET_UNDISCOUNTED_PLAN_PRICE = 'SET_UNDISCOUNTED_PLAN_PRICE'

export const setConsumerAddress = (payload) => {
  return {
    type: SET_CONSUMER_ADDRESS,
    payload,
  }
}

export const setBuyPlanStatusAval = (payload) => {
  return {
    type: BUY_PLAN_STATUS,
    payload,
  }
}

export const setPlansList = (payload) => {
  return {
    type: SET_PLANS_LIST,
    payload,
  }
}

export const setPlansListForImei = (payload) => {
  return {
    type: SET_PLANS_LIST_FOR_IMEI,
    payload,
  }
}

export const setProductName = (payload) => {
  return {
    type: SET_PRODUCT_NAME,
    payload,
  }
}

export const setExternalOriginSource = (payload) => {
  return {
    type: SET_EXTERNAL_ORIGIN_SOURCE,
    payload,
  }
}

export const setImeiFromToken = (payload) => {
  return {
    type: SET_IMEI_FROM_TOKEN,
    payload,
  }
}

export const setImeiForFilter = (payload) => {
  return {
    type: SET_IMEI_FOR_FILTER,
    payload,
  }
}

export const setFetchingPlans = (payload) => {
  return {
    type: SET_FETCHING_PLANS,
    payload,
  }
}

export const setUserData = (payload) => {
  return {
    type: SET_USER_DATA,
    payload,
  }
}

export const setStateList = (payload) => {
  return {
    type: SET_STATE_LIST,
    payload,
  }
}

export const setBrandList = (payload) => {
  return {
    type: SET_BRAND_LIST,
    payload,
  }
}

export const setSelectedPlan = (payload) => {
  return {
    type: SELECTED_PLAN_DETAILS,
    payload,
  }
}

export const setPlanPaymentDetails = (payload) => {
  return {
    type: SET_PLAN_PAYMENT_DETAILS,
    payload,
  }
}

export const setSelectedBrand = (payload) => {
  return {
    type: SET_BRANDID_FOR_PLANS,
    payload,
  }
}

export const setCheckoutParams = (payload) => {
  return {
    type: STORE_CHECKOUT_PARAMS,
    payload,
  }
}

export const setPlanActivated = (payload) => {
  return {
    type: SET_PLAN_ACTIVATED,
    payload,
  }
}

export const setRecommendedPlans = (payload) => {
  return {
    type: SET_RECOMMENDED_PLANS,
    payload,
  }
}

export const setRequiredPaymentParams = (payload) => {
  return {
    type: SET_REQUIRED_PAYMENT_PARAMS,
    payload,
  }
}
export const seteligibleDeviceInfo = (payload) => {
  return {
    type: SET_ELIGIBLE_DEVICE_INFO,
    payload,
  }
}

export const setZipcodeValidated = (payload) => {
  return {
    type: VALIDATED_ZIPCODE,
    payload,
  }
}
export const setBrandBannersList = (payload) => {
  return {
    type: SET_BRAND_BANNERS,
    payload,
  }
}

export const saveZipcodeOrState = (value, type) => {
  return {
    type: SAVE_ZIPCODE_STATE,
    payload: {
      type,
      value,
    },
  }
}
export const setPlanConsumerProduct = (payload) => {
  return {
    type: SET_PLAN_CONSUMER_PRODUCT,
    payload,
  }
}

export const setPurchaseEligibilityInfo = (payload) => {
  return {
    type: SET_PURCHASE_ELIGIBILITY_INFO,
    payload,
  }
}

export const setFetchedPlanPriceByProduct = (payload) => {
  return {
    type: SET_FETCHED_PLANPRICE_BY_PRODUCT,
    payload,
  }
}

export const setPriorityQueueQuestion = (payload) => {
  return {
    type: SET_PRIORITYQUEUE_QUES,
    payload,
  }
}

export const setDependencyQueueQuestion = (payload) => {
  return {
    type: SET_DEPENDENCYQUEUE_QUES,
    payload,
  }
}

export const setCurrentQueueQuestion = (payload) => {
  return {
    type: SET_CURRENTQUEUE_QUES,
    payload,
  }
}

export const setCustomPlanPrice = (payload) => {
  return {
    type: SET_CUSTOM_PLAN_PRICE,
    payload,
  }
}

export const setPostPaymentActionObject = (payload) => {
  return {
    type: SET_POST_PAYMENT_ACTION_OBJECT,
    payload,
  }
}

export const addCustomPlanProductPrice = (payload) => {
  return {
    type: SET_CUSTOM_PLAN_PRODUCT_PRICE,
    payload,
  }
}

export const addCustomPlanDOP = (payload) => {
  return {
    type: SET_CUSTOM_PLAN_DOP,
    payload,
  }
}
export const setIpIdUrl = (payload) => {
  return {
    type: SET_IPID_URL,
    payload,
  }
}
export const setOtherRelatedDocsUrl = (payload) => {
  return {
    type: SET_OTHER_RELATED_DOCS_URL,
    payload,
  }
}
export const setPlanProducts = (payload) => {
  //console.log("setPlanProducts",payload)
  return {
    type: SET_PLAN_PRODUCTS,
    payload,
  }
}
export const setPaymentOnActivation = (payload) => {
  return {
    type: SET_PAYMENT_ON_ACTIVATION,
    payload,
  }
}
export const setPurchasePlanNoPayment = (payload) => {
  return {
    type: SET_PURCHASE_PLAN_NO_PAYMENT,
    payload,
  }
}

export const setAvailablePaymentOptions = (payload) => {
  return {
    type: SET_AVAILABLE_PAYMENT_OPTIONS,
    payload,
  }
}

export const setSelectedPaymentOption = (payload) => {
  return {
    type: SET_SELECTED_PAYMENT_OPTION,
    payload,
  }
}

export const setDiscountDetails = (payload) => {
  return {
    type: SET_DISCOUNT_DETAILS,
    payload,
  }
}

export const setTaxAddress = (payload) => {
  return {
    type: SET_TAX_ADDRESS,
    payload,
  }
}

export const setUndiscountedPlanPrice = (payload) => {
  return {
    type: SET_UNDISCOUNTED_PLAN_PRICE,
    payload,
  }
}

export const addorFetchDeviceAsync = (requestObj) => {
  let body = {
    ...requestObj.data,
    UseACC: true, // hardcoded
  }
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/ConsumerProduct/addOrFetchDevice', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(err.message, 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (!response.status === 200 || !response.data.success) {
          // console.log('Api Error: ', response.data.msg)
          let errMsg = response.data.msg
          if (response.data.msg === 'Serial Number Not Unique') {
            errMsg = 'Serial Number ' + (requestObj.data.ProductSubCategoryID === 12 ? 'or IMEI ' : '') + 'is not unique '
          }
          let err = new Error(errMsg)
          dispatch(setToaster(errMsg, 'error'))
          throw err
        } else {
          let productObj = response.data.data
          let isNew = _.get(response, 'data.IsNew', true)
          if (productObj && productObj.ConsumerProductID && !isNew) {
            dispatch(
              updateDevicesAsync({
                ConsumerProductID: productObj.ConsumerProductID,
                AuthID: requestObj.AuthID,
                updateObj: {
                  ...requestObj.data,
                },
              })
            )
          }
          dispatch(setPlanConsumerProduct(response.data.data))
          return response.data.data
        }
      })
  }
}

export const addorFetchAsync = (requestObj) => {
  let body = {
    ...requestObj.data,
    UseACC: true, // hardcoded
  }
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/ConsumerProduct/addOrFetch', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(err.message, 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (!response.status === 200 || !response.data.success) {
          // console.log('Api Error: ', response.data.msg)
          let errMsg = response.data.msg
          if (response.data.msg === 'Serial Number Not Unique') {
            errMsg = 'Serial Number ' + (requestObj.data.ProductSubCategoryID === 12 ? 'or IMEI ' : '') + 'is not unique '
          }
          let err = new Error(errMsg)
          dispatch(setToaster(errMsg, 'error'))
          throw err
        } else {
          let productObj = response.data.data
          let isNew = _.get(response, 'data.IsNew', true)
          if (productObj && productObj.ConsumerProductID && !isNew) {
            dispatch(
              updateDevicesAsync({
                ConsumerProductID: productObj.ConsumerProductID,
                AuthID: requestObj.AuthID,
                updateObj: {
                  ...requestObj.data,
                },
              })
            )
          }
          dispatch(setPlanConsumerProduct(response.data.data))
          return response.data.data
        }
      })
  }
}

export const getSelectedPlanDetailsAsync = (requestObj) => {
  let body = JSON.stringify({
    ...requestObj.data,
  })
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/ConsumerProduct/fetchPlanDetails', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          let planPriceSorted = response.data.data.PlanDetails.planPrice
          planPriceSorted.sort((a, b) => {
            let comparison = 0
            if (a.PlanPrice > b.PlanPrice) {
              comparison = 1
            } else if (a.PlanPrice < b.PlanPrice) {
              comparison = -1
            }
            return comparison
          })

          let appleData = filterPlanDetails(response.data.data.PlanDetails.PlanID)

          let planDetails = {
            ...response.data.data.PlanDetails,
            planPrice: planPriceSorted,
            PlanConfig: {
              ...response.data.data.PlanConfigDetails,
              ...appleData,
            },
            EntityDetails: response.data.data.EntityDetails,
            PlanDiscounts: response.data.data.PlanDiscounts,
          }
          dispatch(setSelectedPlan(planDetails))
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
      .finally(() => {
        dispatch(setPurchasePlanNoPayment(undefined))
        dispatch(setPaymentOnActivation(undefined))
      })
  }
}

export const checkPurchaseEligibility = (requestObj) => {
  return (dispatch, getState) => {
    let body = JSON.stringify({
      ...requestObj,
    })
    return axios
      .post(api.coreUri + '/ConsumerProduct/checkPurchaseEligibility', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(
            setPurchaseEligibilityInfo({
              ...response.data.data,
              imeiOrSerial: requestObj.imeiOrSerial,
            })
          )
          dispatch(setFetchedPlanPriceByProduct(undefined)) // check this
          return response.data
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const getPlanPriceByIdentifiers = (requestObj) => {
  return (dispatch, getState) => {
    let body = JSON.stringify({
      ...requestObj,
    })
    return axios
      .post(api.coreUri + '/ConsumerProduct/getPlanPriceByIdentifiers', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(setFetchedPlanPriceByProduct(response.data.data.PlanPrice || response.data.data[0]))
          return response.data
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const validateUserDetails = (requestObj) => {
  return (dispatch, getState) => {
    let body = JSON.stringify({
      ...requestObj,
    })
    return axios
      .post(api.consumerUri + '/consumer/pre-validate', body, {
        headers: {
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        // const response = { status: 200, data: { "success": true, "msg": "Congratulations! This device is eligible for the selected plan", "data": { "InputParams": { "DateOfPurchase": true, "ProductPurchaseCost": true }, "IsPurchasedAllowed": true, "status_message": "IS_ELIGIBLE", "PlanPrice": { "PlanPriceID": 881, "PlanID": 247, "PlanPrice": 1889, "CurrencyCode": "INR", "CurrencySymbol": "&#8377;" }, "app": "Consumer-Web" } } }
        if (response.status === 200 && response.data.success) {
          return response.data
        }
      })
      .finally(() => {})
  }
}

export const getPlanEligibilityStatusForNonLoginFlow = (requestObj, callType = 'primaryCheck') => {
  return (dispatch, getState) => {
    let body = JSON.stringify({
      ...requestObj,
      app: BrandSpecificConfig().header,
    })
    return axios
      .post(api.coreUri + '/ConsumerProduct/purchasePlanHandshake', body, {
        headers: {
          // Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        // const response = { status: 200, data: { "success": true, "msg": "Congratulations! This device is eligible for the selected plan", "data": { "InputParams": { "DateOfPurchase": true, "ProductPurchaseCost": true }, "IsPurchasedAllowed": true, "status_message": "IS_ELIGIBLE", "PlanPrice": { "PlanPriceID": 881, "PlanID": 247, "PlanPrice": 1889, "CurrencyCode": "INR", "CurrencySymbol": "&#8377;" }, "app": "Consumer-Web" } } }
        if (response.status === 200 && response.data.success) {
          if (callType !== 'secondaryCheck') {
            dispatch(
              setPurchaseEligibilityInfo({
                ...response.data.data,
                imeiOrSerial: requestObj.imeiOrSerial,
              })
            )
            dispatch(setFetchedPlanPriceByProduct(undefined))
            dispatch(setAvailablePaymentOptions([]))
          }
          return response.data
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
      .finally(() => {
        dispatch(setPurchasePlanNoPayment(undefined))
        dispatch(setPaymentOnActivation(undefined))
      })
  }
}

export const getPlanEligibilityStatus = (requestObj, callType = 'primaryCheck') => {
  return (dispatch, getState) => {
    let body = JSON.stringify({
      ...requestObj,
      app: BrandSpecificConfig().header,
    })
    return axios
      .post(api.coreUri + '/ConsumerProduct/planPurchaseHandshake', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        // const response = { status: 200, data: { "success": true, "msg": "Congratulations! This device is eligible for the selected plan", "data": { "InputParams": { "DateOfPurchase": true, "ProductPurchaseCost": true }, "IsPurchasedAllowed": true, "status_message": "IS_ELIGIBLE", "PlanPrice": { "PlanPriceID": 881, "PlanID": 247, "PlanPrice": 1889, "CurrencyCode": "INR", "CurrencySymbol": "&#8377;" }, "app": "Consumer-Web" } } }
        if (response.status === 200 && response.data.success) {
          if (callType !== 'secondaryCheck') {
            dispatch(
              setPurchaseEligibilityInfo({
                ...response.data.data,
                imeiOrSerial: requestObj.imeiOrSerial,
              })
            )
            dispatch(setFetchedPlanPriceByProduct(undefined))
            //dispatch(setAvailablePaymentOptions([]))
            dispatch(saveZipcodeOrState(null, 'selectedState'))
            dispatch(setConsumerAddress({}))
          }
          return response.data
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
      .finally(() => {
        dispatch(setPurchasePlanNoPayment(undefined))
        dispatch(setPaymentOnActivation(undefined))
      })
  }
}

export const checkIfPaymentRequiredAsync = (requestObj) => {
  return (dispatch, getState) => {
    let body = JSON.stringify({
      ...requestObj,
      Source: BrandSpecificConfig().header,
      CheckForPaymentOnActivation: true,
      ExternalOriginSource: _.get(getState(), 'store.externalOriginSource', undefined) || undefined,
    })
    return axios
      .post(api.coreUri + '/ConsumerProduct/getPlanPayment', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          const PaymentOnActivation = response.data.data.PaymentOnActivation
          dispatch(setAvailablePaymentOptions(!PaymentOnActivation ? response.data.data.paymentOptions : response.data.data))
          dispatch(setPaymentOnActivation(PaymentOnActivation))
          return response.data.data
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const purchasePlanNoPaymentAsync = (requestObj, path) => {
  return (dispatch, getState) => {
    let body = JSON.stringify({
      ...requestObj,
      ExternalOriginSource: _.get(getState(), 'store.externalOriginSource', undefined) || undefined,
    })
    return axios
      .post(api.coreUri + path, body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(setPurchasePlanNoPayment(response.data.data))
          return response.data.data
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const logConsentsAsync = (requestObj) => {
  return (dispatch) => {
    let body = JSON.stringify({
      ...requestObj,
    })
    return axios
      .post(api.coreUri + '/Consumer/logConsumerConsents', body, {
        headers: { customHeader: true },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          console.log('logConsentsAsync successful')
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const fetchEntityPlanAsync = (requestObj) => {
  return (dispatch) => {
    let body = JSON.stringify({
      ...requestObj,
    })
    return axios
      .post(api.coreUri + '/ConsumerProduct/fetchEntitiesFromPlan', body, {
        headers: { customHeader: true },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success && response.data.data.length) {
          let respData = []
          response.data.data.map((List, index) => {
            if (index === 0) {
              respData = List.EntityArray || []
            } else {
              List.EntityArray.map((brand) => {
                if (!respData.find((data) => data.BrandID === brand.BrandID)) {
                  respData.push(brand)
                }
              })
            }
          })
          dispatch(setBrandList(respData))
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const getEligiblePlans = (requestObj, loggedIn = false) => {
  return (dispatch, getState) => {
    let body = JSON.stringify({
      ...requestObj,
    })
    let localState = getState()
    return axios
      .post(api.coreUri + (!loggedIn ? '/ConsumerProduct/getEligiblePlans' : '/ConsumerProduct/fetchEligiblePlans'), body, {
        // return axios.post(api.coreUri + '/ConsumerProduct/getEligiblePlans', body, {
        headers: {
          Authorization: _.get(localState, 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          let plansData = []
          response.data.data.map((content) => {
            content.PlanObject.map((obj) => {
              let isPurchasedAllowed = _.get(obj, 'PlanConfig.IsPurchasedAllowed', true)
              if (isPurchasedAllowed) {
                plansData.push(obj)
              }
            })
          })
          plansData = plansData.map((data) => {
            if ([593, 370].includes(data.PlanID)) {
              return { ...data, PlanType: 'Secure' }
            } else {
              return data
            }
          })
          plansData = _.orderBy(plansData, 'PlanConfig.ValidTillMonths', 'desc')
          let List = [
            {
              Heading: getTranslationText('store', 'protectionPlans'),
              PlanList: _.compact(
                plansData.filter((plan) => plan.PlanType.toLowerCase() === 'protect' && !_.get(plan, 'PlanConfig.IsCustomPlan', false))
              ),
            },
            {
              Heading: getTranslationText('store', 'extWarrantyPlans'),
              PlanList: _.compact(
                plansData.filter((plan) => plan.PlanType.toLowerCase() === 'secure' && !_.get(plan, 'PlanConfig.IsCustomPlan', false))
              ),
            },
            {
              Heading: getTranslationText('store', 'buyback'),
              PlanList: _.compact(
                plansData.filter((plan) => plan.PlanType.toLowerCase() === 'buyback' && !_.get(plan, 'PlanConfig.IsCustomPlan', false))
              ),
            },
            {
              Heading: getTranslationText('device', 'customPlan'),
              IsCustomPlan: true,
              PlanList: _.compact(plansData.filter((plan) => _.get(plan, 'PlanConfig.IsCustomPlan'))),
            },
          ]
          dispatch(setPlansList(_.compact(List.filter((data) => data.PlanList && data.PlanList.length))))
          if (_.get(getState(), 'store.imeiFromToken')) {
            getPlansFromImei(
              dispatch,
              {
                imeiOrSerial: _.get(getState(), 'store.imeiFromToken'),
                BrandID: _.get(getState(), 'store.selectedBrand.BrandID'),
                app: BrandSpecificConfig().header,
                CountryID: _.get(getState(), 'user.selectedCountryData.CountryID'),
                ConsumerID: _.get(getState(), 'user.userDetails.consumerID', undefined),
                EmailID: _.get(getState(), 'user.userDetails.emailID', undefined),
                MobileNo: _.get(getState(), 'user.userDetails.mobileNo', undefined),
              },
              _.get(getState(), 'user'),
              _.get(getState(), 'store')
            )
          }
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const decodeToken = (token) => {
  return (dispatch, getState) => {
    let body = JSON.stringify({
      Token: token,
    })
    return axios
      .post(api.consumerUri + '/consumer-product/internal/decodeImei', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          if (response.data.data.device_imei && response.data.data.device_imei.length > 0) {
            dispatch(setImeiFromToken(response.data.data.device_imei))
          } else {
            dispatch(setImeiFromToken(''))
            dispatch(setExternalOriginSource(''))
          }
          // dispatch(setImeiFromToken("362563748463563"))
        } else {
          dispatch(setImeiFromToken(''))
          dispatch(setExternalOriginSource(''))
        }
      })
  }
}

export const getPlansFromImei = (dispatch, requestObj, user, store) => {
  let body = JSON.stringify({
    ...requestObj,
  })
  dispatch(setFetchingPlans(true))
  return axios
    .post(api.coreUri + '/ConsumerProduct/getPlansFromImei', body, {
      headers: {
        Authorization: _.get(user, 'consumerDetails.data.accessToken.ID'),
        customHeader: true,
      },
    })
    .catch((err) => {
      dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
      throw err
    })
    .then((response) => {
      if (response.status === 200 && response.data.success) {
        if (response.data.data.PlanObj.length) {
          let plansData = []
          response.data.data.PlanObj.map((content) => {
            content.PlanObject.map((obj) => {
              let isPurchasedAllowed = _.get(obj, 'IsPurchasedAllowed', true)
              if (isPurchasedAllowed) {
                plansData.push(obj)
              }
            })
          })
          plansData = plansData.map((data) => {
            if ([593, 370].includes(data.PlanID)) {
              return { ...data, PlanType: 'Secure' }
            } else {
              return data
            }
          })
          plansData = _.orderBy(plansData, 'PlanConfig.ValidTillMonths', 'desc')
          let List = [
            {
              Heading: getTranslationText('store', 'protectionPlans'),
              PlanList: _.compact(
                plansData.filter((plan) => plan.PlanType.toLowerCase() === 'protect' && !_.get(plan, 'PlanConfig.IsCustomPlan', false))
              ),
            },
            {
              Heading: getTranslationText('store', 'extWarrantyPlans'),
              PlanList: _.compact(
                plansData.filter((plan) => plan.PlanType.toLowerCase() === 'secure' && !_.get(plan, 'PlanConfig.IsCustomPlan', false))
              ),
            },
            {
              Heading: getTranslationText('store', 'buyback'),
              PlanList: _.compact(
                plansData.filter((plan) => plan.PlanType.toLowerCase() === 'buyback' && !_.get(plan, 'PlanConfig.IsCustomPlan', false))
              ),
            },
            {
              Heading: getTranslationText('device', 'customPlan'),
              IsCustomPlan: true,
              PlanList: _.compact(plansData.filter((plan) => _.get(plan, 'PlanConfig.IsCustomPlan'))),
            },
          ]
          let modifiedList = _.compact(List.filter((data) => data.PlanList && data.PlanList.length))

          if (modifiedList.length) {
            const messagesForEmptyCategory = {
              [getTranslationText('store', 'protectionPlans')]: 'No plans available',
              [getTranslationText('store', 'extWarrantyPlans')]: getTranslationText('store', 'noExtendedPlanForIMEI'),
              [getTranslationText('store', 'buyback')]: 'No plans available',
              [getTranslationText('device', 'customPlan')]: 'No plans available',
            }
            const allCategoriesForImei = modifiedList.map((category) => category.Heading)

            store.brandPlansList.forEach((category) => {
              if (!allCategoriesForImei.includes(category.Heading)) {
                modifiedList.push({
                  Heading: category.Heading,
                  PlanList: [],
                  Message: messagesForEmptyCategory[category.Heading],
                })
              } else {
                return false
              }
            })
            dispatch(setProductName(response.data.data.ProductName || ''))
            dispatch(setPlansListForImei({ data: modifiedList }))
            pushToAnalytics('Store Page - Eligibility check', {
              Eligible: true,
              eureka: !!_.get(store, 'imeiFromToken', false),
              ProductName: response.data.data.ProductName || '',
            })
          } else {
            dispatch(setPlansListForImei({ error: 'no plans' }))
            pushToAnalytics('Store Page - Eligibility check', { Eligible: false, eureka: !!_.get(store, 'imeiFromToken', false) })
          }
        } else {
          dispatch(setPlansListForImei({ error: 'no plans' }))
          pushToAnalytics('Store Page - Eligibility check', { Eligible: false, eureka: !!_.get(store, 'imeiFromToken', false) })
        }
      } else {
        dispatch(setPlansListForImei({ error: response.data.msg }))
        pushToAnalytics('Store Page - Eligibility check', { Eligible: false, eureka: !!_.get(store, 'imeiFromToken', false) })
      }
    })
    .finally(() => {
      dispatch(setFetchingPlans(false))
    })
}

export const fetchEligiblePlans = (requestObj) => {
  return (dispatch, getState) => {
    let body = JSON.stringify({
      ...requestObj,
    })
    let localState = getState()
    return axios
      .post(api.coreUri + '/ConsumerProduct/fetchEligiblePlans', body, {
        // return axios.post(api.coreUri + '/ConsumerProduct/fetchEligiblePlans', body, {
        headers: {
          Authorization: _.get(localState, 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          let plansData = []
          response.data.data.map((content) => {
            content.PlanObject.map((obj) => {
              let isPurchasedAllowed = _.get(obj, 'PlanConfig.IsPurchasedAllowed', true)
              if (isPurchasedAllowed) {
                plansData.push(obj)
              }
            })
          })
          plansData = plansData.map((data) => {
            if ([593, 370].includes(data.PlanID)) {
              return { ...data, PlanType: 'Secure' }
            } else {
              return data
            }
          })
          plansData = _.orderBy(plansData, 'PlanConfig.ValidTillMonths', 'desc')
          let List = [
            {
              Heading: getTranslationText('store', 'protectionPlans'),
              PlanList: _.compact(
                plansData.filter((plan) => plan.PlanType.toLowerCase() === 'protect' && !_.get(plan, 'PlanConfig.IsCustomPlan', false))
              ),
            },
            {
              Heading: getTranslationText('store', 'extWarrantyPlans'),
              PlanList: _.compact(
                plansData.filter((plan) => plan.PlanType.toLowerCase() === 'secure' && !_.get(plan, 'PlanConfig.IsCustomPlan', false))
              ),
            },
            {
              Heading: getTranslationText('device', 'customPlan'),
              IsCustomPlan: true,
              PlanList: _.compact(plansData.filter((plan) => _.get(plan, 'PlanConfig.IsCustomPlan'))),
            },
          ]
          dispatch(setPlansList(_.compact(List.filter((data) => data.PlanList && data.PlanList.length))))
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const getRequiredPaymentParamsAsync = () => {
  return (dispatch, getState) => {
    let body = JSON.stringify({
      CountryID: getState().user && getState().user.selectedCountryData.CountryID,
    })
    return axios
      .post(api.coreUri + '/Billing/getRequiredPaymentParamsList', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          // return response.data.data
          return dispatch(setRequiredPaymentParams(response.data.data))
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const validateZipcodeAsync = (Zipcode) => {
  return (dispatch, getState) => {
    let body = JSON.stringify({
      CountryID: getState().user && getState().user.selectedCountryData.CountryID,
      Zipcode,
    })
    return axios
      .post(api.coreUri + '/Billing/validateZipcode', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(err.message, 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          // return response.data.data
          return dispatch(setZipcodeValidated(true))
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(err.message, 'error'))
          dispatch(setZipcodeValidated(false))
          // throw err
        }
      })
  }
}

export const validateAddressAsync = (address) => {
  return (dispatch, getState) => {
    let body = JSON.stringify({
      CountryID: window.CountryID,
      CountryCode: _.get(getState(), 'user.selectedCountryData.CountryCode'),
      Address: {
        AddressLine1: address.AddressLine1 || address.userlandmark || address.UserLandmark,
        AddressLine2: address.AddressLine2 || address.address || address.Address,
        State: address.state || address.State,
        City: address.city || address.City,
        Zipcode: address.ZipCode || address.Zipcode,
      },
    })
    return axios
      .post(api.coreUri + '/Billing/validateAddress', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          return response.data.data
        } else {
          let err = new Error(response.data.msg)
          throw err
        }
      })
  }
}

export const aysncFetchBrandID = (requestObj) => {
  let body = JSON.stringify({
    BrandName: requestObj.BrandName,
  })
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/Brand/fetchBrandDetails', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(err.message, 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(setSelectedBrand(response.data.data))
          // dispatch(setBrandList(response.data.data))
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(err.message, 'error'))
          throw err
        }
      })
  }
}

export const fetchStoreBannersAsync = (requestObj) => {
  let body = JSON.stringify({
    BrandID: requestObj.BrandID,
  })
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/Banner/getBannerDetails', body, {
        headers: {
          // Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(err.message, 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          let bannerResp = response.data.data
          dispatch(setBrandBannersList(bannerResp))
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(err.message, 'error'))
          throw err
        }
      })
  }
}

export const fetchPlanQuestionsAsync = (requestObj) => {
  return (dispatch, getState) => {
    let body = JSON.stringify({
      ...requestObj,
      CountryID: _.get(getState(), 'user.selectedCountryData.CountryID'),
    })
    return axios
      .post(api.coreUri + '/ConsumerProduct/fetchPlanQuestions', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(err.message, 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          return response.data.data
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(err.message, 'error'))
          throw err
        }
      })
  }
}

// Device models for plan
export const fetchPlanProductsAsync = (requestObj) => {
  //console.log('fetchPlanProductsAsync 1')
  let body = JSON.stringify({
    ...requestObj,
  })
  //console.log('fetchPlanProductsAsync 2',body)
  return (dispatch) => {
    return axios
      .post(api.coreUri + '/ConsumerProduct/getPlanProducts', body, {
        headers: {
          // Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .then((response) => {
        //console.log('fetchPlanProductsAsync 3',response)
        if (response.status === 200 && response.data.success) {
          // if(!response.data.data.length) {
          //   response.data = [{"ProductID":26999,"ProductName":"Y12i","ProductCategoryID":1,"ProductSubCategoryID":12,"BrandID":86,"IsActive":true,"PlanType":"Protect","PlanID":382},{"ProductID":26998,"ProductName":"X60 Pro 5G","ProductCategoryID":1,"ProductSubCategoryID":12,"BrandID":86,"IsActive":true,"PlanType":"Protect","PlanID":382}]
          //   console.log('fetchPlanProductsAsync 3 - test response',response)
          // }
          //console.log("dispatch setPlanProducts")
          dispatch(setPlanProducts(response.data.data))
          return response.data
        } else {
          let err = new Error(response.data.msg)
          //console.log('fetchPlanProductsAsync 4',err)
          dispatch(setToaster(err.message, 'error'))
          throw err
        }
      })
      .catch((err) => {
        console.log('fetchPlanProductsAsync 5', err)
        dispatch(setToaster(err.message, 'error'))
      })
  }
}

export const fetchIPIDAsync = (requestObj) => {
  let body = JSON.stringify({
    ...requestObj,
  })
  return (dispatch) => {
    return axios
      .post(api.coreUri + '/ConsumerProduct/getPlanDocuments', body, {
        headers: {
          // Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          if (response.data.data.length === 1) {
            dispatch(setIpIdUrl(response.data.data[0]))
          } else if (response.data.data.length > 1) {
            let ipid_ind = -1,
              otherDocs_ind = -1
            response.data.data.map((doc, ind) => {
              if (doc.PlanDocumentType === 'INSURANCE_INTERMEDIARY') otherDocs_ind = ind
              else if (doc.PlanDocumentType === 'IPID') ipid_ind = ind
            })
            if (ipid_ind >= 0) dispatch(setIpIdUrl(response.data.data[ipid_ind]))
            if (otherDocs_ind >= 0) dispatch(setOtherRelatedDocsUrl(response.data.data[otherDocs_ind]))
          }
          return response.data.data[0]
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(err.message, 'error'))
          throw err
        }
      })
      .catch((err) => {
        dispatch(setToaster(err.message, 'error'))
      })
  }
}

export const fetchDiscountDetailsAsync = (requestObj) => {
  let body = JSON.stringify({
    ...requestObj,
  })
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/ConsumerProduct/getPlanPurchaseDetails', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(setDiscountDetails(response.data.data))
          return response.data.data
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(err.message, 'error'))
          throw err
        }
      })
      .catch((err) => {
        dispatch(setToaster(err.message, 'error'))
      })
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SET_BRAND_LIST]: (state, action) => ({
    ...state,
    availableBrandsList: action.payload,
  }),
  [SET_STATE_LIST]: (state, action) => ({
    ...state,
    stateList: action.payload,
  }),
  [SELECTED_PLAN_DETAILS]: (state, action) => ({
    ...state,
    selectedPlanDetails: action.payload,
  }),
  [SET_PLANS_LIST]: (state, action) => ({
    ...state,
    brandPlansList: action.payload,
  }),
  [SET_PLANS_LIST_FOR_IMEI]: (state, action) => ({
    ...state,
    brandPlansListForImei: action.payload,
  }),
  [SET_PRODUCT_NAME]: (state, action) => ({
    ...state,
    productName: action.payload,
  }),
  [SET_EXTERNAL_ORIGIN_SOURCE]: (state, action) => ({
    ...state,
    externalOriginSource: action.payload,
  }),
  [SET_IMEI_FROM_TOKEN]: (state, action) => ({
    ...state,
    imeiFromToken: action.payload,
  }),
  [SET_IMEI_FOR_FILTER]: (state, action) => ({
    ...state,
    imeiForFilter: action.payload,
  }),
  [SET_FETCHING_PLANS]: (state, action) => ({
    ...state,
    fetchingPlans: action.payload,
  }),
  [SET_USER_DATA]: (state, action) => ({
    ...state,
    userData: action.payload,
  }),
  [SET_PLAN_PAYMENT_DETAILS]: (state, action) => ({
    ...state,
    planPaymentDetails: action.payload,
  }),
  [SET_BRANDID_FOR_PLANS]: (state, action) => ({
    ...state,
    selectedBrand: action.payload,
  }),
  [STORE_CHECKOUT_PARAMS]: (state, action) => ({
    ...state,
    storeCheckout: action.payload,
  }),
  [SET_REQUIRED_PAYMENT_PARAMS]: (state, action) => ({
    ...state,
    requiredPaymentParams: action.payload,
  }),
  [VALIDATED_ZIPCODE]: (state, action) => ({
    ...state,
    validatedZipcode: action.payload,
  }),
  [SAVE_ZIPCODE_STATE]: (state, action) => ({
    ...state,
    [action.payload.type]: action.payload.value,
  }),
  [BUY_PLAN_STATUS]: (state, action) => ({
    ...state,
    buyplanStatus: action.payload,
  }),
  [SET_RECOMMENDED_PLANS]: (state, action) => ({
    ...state,
    recommendedPlanList: action.payload,
  }),
  [SET_BRAND_BANNERS]: (state, action) => ({
    ...state,
    brandBannersList: action.payload,
  }),
  [SET_ELIGIBLE_DEVICE_INFO]: (state, action) => ({
    ...state,
    eligibleDeviceInfo: action.payload,
  }),
  [SET_PLAN_CONSUMER_PRODUCT]: (state, action) => ({
    ...state,
    planConsumerProduct: action.payload,
  }),
  [SET_PURCHASE_ELIGIBILITY_INFO]: (state, action) => ({
    ...state,
    purchaseEligibilityInfo: action.payload,
    // purchaseEligibilityInfo: (action.payload
    //   ? { ...action.payload, ProductObj: { ...action.payload.ProductObj, DateOfPurchase: undefined } }
    //   : action.payload)
  }),
  [SET_FETCHED_PLANPRICE_BY_PRODUCT]: (state, action) => ({
    ...state,
    planPriceOnProductBasis: action.payload,
  }),
  [SET_PRIORITYQUEUE_QUES]: (state, action) => ({
    ...state,
    priorityQueue: action.payload,
  }),
  [SET_DEPENDENCYQUEUE_QUES]: (state, action) => ({
    ...state,
    dependencyQueue: action.payload,
  }),
  [SET_CURRENTQUEUE_QUES]: (state, action) => ({
    ...state,
    currentQueue: action.payload,
  }),
  [SET_CUSTOM_PLAN_PRICE]: (state, action) => ({
    ...state,
    customPlanPrice: action.payload,
  }),
  [SET_POST_PAYMENT_ACTION_OBJECT]: (state, action) => ({
    ...state,
    postPaymentActionObj: action.payload,
  }),
  [SET_CUSTOM_PLAN_PRODUCT_PRICE]: (state, action) => ({
    ...state,
    customPlanProductPrice: action.payload,
  }),
  [SET_CUSTOM_PLAN_DOP]: (state, action) => ({
    ...state,
    customPlanDOP: action.payload,
  }),
  [SET_PLAN_ACTIVATED]: (state, action) => ({
    ...state,
    planActivated: action.payload,
  }),
  [SET_IPID_URL]: (state, action) => ({
    ...state,
    ipidUrl: action.payload,
  }),
  [SET_OTHER_RELATED_DOCS_URL]: (state, action) => ({
    ...state,
    otherRelatedDocsUrl: action.payload,
  }),
  [SET_PLAN_PRODUCTS]: (state, action) => ({
    ...state,
    planProducts: action.payload,
  }),
  [SET_PAYMENT_ON_ACTIVATION]: (state, action) => ({
    ...state,
    checkIfPaymentRequired: action.payload,
  }),
  [SET_PURCHASE_PLAN_NO_PAYMENT]: (state, action) => ({
    ...state,
    purchasePlanNoPayment: action.payload,
  }),
  [SET_DISCOUNT_DETAILS]: (state, action) => ({
    ...state,
    discountDetails: action.payload,
  }),
  [SET_AVAILABLE_PAYMENT_OPTIONS]: (state, action) => ({
    ...state,
    availablePaymentOptions: action.payload,
  }),
  [SET_SELECTED_PAYMENT_OPTION]: (state, action) => ({
    ...state,
    selectedPaymentOption: action.payload,
  }),
  [SET_CONSUMER_ADDRESS]: (state, action) => ({
    ...state,
    consumerAddress: action.payload,
  }),
  [SET_TAX_ADDRESS]: (state, action) => ({
    ...state,
    taxAddress: action.payload,
  }),
  [SET_UNDISCOUNTED_PLAN_PRICE]: (state, action) => ({
    ...state,
    undiscountedPlanPrice: action.payload,
  }),
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  storeData: {},
  requiredPaymentParams: {},
  taxAddress: null,
}

export default function storeReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
