// Declare all CONSTANTS here

import { getTranslationText } from './languageTranslation.js'
const maintenanceServiceImg = '/commonAssets/vector-smart-object@3x.png'
const installDemoImg = '/commonAssets/layer-629-copy@3x.png'
const bookRepairImg = `/${appName}/images/repairImg.png`
const serviceEstimateImg = `/${appName}/images/serviceEstimator.png`

// @todo wrap below strings in getTranslation function

export const productAuthErrorMessages = (value, uniqueId) => {
  let message = getTranslationText('error', 'somethingWentWrong')
  if (value) {
    switch (value) {
      case 'DEVICE_ID_MISSING':
        message = getTranslationText('error', 'deviceIDMissing')
        break

      case 'INVALID_DEVICEID_SERIALNO':
        message = getTranslationText('error', 'couldNotIdentify') + uniqueId
        break

      case 'RATE_LIMIT_EXCEEDED':
      case 'ATTEMPTS_EXHAUSTED':
        message = getTranslationText('error', 'exhausted') + uniqueId
        break

      case 'INVALID_REQUEST_URI':
        message = getTranslationText('error', 'invalidRequest')
        break

      case 'INVALID_SERIAL_NO':
      case 'INVALID_SERIAL_NO_FORMAT':
      case 'INVALID_IMEI_NO':
      case 'INVALID_IMEI_ERROR':
        message = getTranslationText('error', 'looksLike') + uniqueId + getTranslationText('error', 'youHaveProvided')
        break

      case 'INVALID_SSX_CREDS':
        message = getTranslationText('error', 'serverFailed')
        break

      case 'GSX_SERVER_ERROR':
        message = getTranslationText('error', 'validationFailed') + uniqueId
        break

      case 'DIFFERENT_DEVICE':
        message = getTranslationText('error', 'looksLike') + uniqueId + getTranslationText('error', 'doesNotBelongToDevice')
        break

      case 'PRODUCT_MISMATCH_ERROR':
        message = getTranslationText('error', 'looksLike') + uniqueId + getTranslationText('error', 'doesNotBelongToModel')
        break
      case 'IMEI_SAME':
        message = getTranslationText('error', 'sameIMEI')
        break
      default:
        message = `${getTranslationText('error', 'errorValidating')} ${uniqueId}`
    }
  }
  return message
}

export const BrandSpecificConfig = () => {
  let message = { header: 'Consumer-Web', ClientName: 'Servify' }
  let value = appName
  if (value) {
    switch (value) {
      case 'moto':
        message = {
          header: 'Moto-Web',
          ClientName: 'Motorola',
          showPServifyTxt: true,
        }
        break
      case 'realme':
        message = {
          header: 'Realme-Web',
          ClientName: 'Realme',
          showPServifyTxt: true,
        }
        break
      case 'nokia':
        message = {
          header: 'Nokia-Web',
          ClientName: 'Nokia',
          showPServifyTxt: true,
        }
        break
      case 'nokiaeu':
        message = {
          header: 'Nokia-Web',
          ClientName: 'Nokia',
          showPServifyTxt: true,
        }
        break
      case 'bhartiassist':
        message = {
          header: 'BhartiAssist-Web',
          ClientName: 'Bharti Assist Care',
          showPServifyTxt: true,
        }
        break
      case 'clearlycovered':
        message = {
          header: 'ClearlyCovered-Web',
          ClientName: 'Clearlycovered',
          showPServifyTxt: true,
        }
        break
      case 'tenor':
        message = {
          header: 'Tenor-Web',
          ClientName: '10.or care',
          showPServifyTxt: true,
        }
        break
      case 'glyde':
        message = {
          header: 'Glyde-Web',
          ClientName: 'Glyde',
          showPServifyTxt: true,
        }
        break
      case 'tapsafe':
        message = {
          header: 'Tapsafe-Web',
          ClientName: 'Tapsafe',
          showPServifyTxt: true,
        }
        break
      case 'airtel':
        message = {
          header: 'Airtel-Web',
          ClientName: 'Airtel',
          showPServifyTxt: true,
        }
        break
      case 'samsung':
        message = {
          header: 'Samsung-Web',
          ClientName: 'Samsung',
          showPServifyTxt: true,
        }
        break
      case 'samsunguae':
        message = {
          header: 'SamsungUAE-Web',
          ClientName: 'Samsung',
          showPServifyTxt: true,
        }
        break
      case 'samsungksa':
        message = {
          header: 'SamsungKSA-Web',
          ClientName: 'Samsung',
          showPServifyTxt: true,
        }
        break
      case 'samsungeu':
        message = {
          header: 'SamsungEU-Web',
          ClientName: 'Samsung',
          showPServifyTxt: true,
        }
        break
      case 'appleeu':
        message = {
          header: 'AppleEU-Web',
          ClientName: 'Apple',
          showPServifyTxt: true,
        }
        break
      case 'maple':
        message = {
          header: 'Maple-Web',
          ClientName: 'Maple',
          showPServifyTxt: true,
        }
        break
      case 'tresor':
        message = {
          header: 'Tresor-Web',
          ClientName: 'Tresor',
          showPServifyTxt: true,
        }
        break
      case 'p3s':
        message = {
          header: 'P3S-Web',
          ClientName: 'P3S',
          showPServifyTxt: true,
        }
        break
      case 'aptronix':
        message = {
          header: 'Aptronix-Web',
          ClientName: 'Aptronix',
          showPServifyTxt: true,
        }
        break
      case 'futureworld':
        message = {
          header: 'Futureworld-Web',
          ClientName: 'Futureworld',
          showPServifyTxt: true,
        }
        break
      case 'imagine':
        message = {
          header: 'Imagine-Web',
          ClientName: 'Imagine',
          showPServifyTxt: true,
        }
        break
      case 'nyasa':
        message = {
          header: 'Nyasa-Web',
          ClientName: 'Nyasa',
          showPServifyTxt: true,
        }
        break
      case 'idelta':
        message = {
          header: 'iDelta-Web',
          ClientName: 'iDelta',
          showPServifyTxt: true,
        }
        break
      case 'reliance':
        message = {
          header: 'Reliance-Digital-Web',
          ClientName: 'Reliance Digital',
          showPServifyTxt: true,
        }
        break
      case 'supreme':
        message = {
          header: 'Supreme-Mobiles-Web',
          ClientName: 'Supreme Mobiles',
          showPServifyTxt: true,
        }
        break
      case 'idestiny':
        message = {
          header: 'iDestiny-Web',
          ClientName: 'iDestiny',
          showPServifyTxt: true,
        }
        break
      case 'krbl':
        message = {
          header: 'KRBL-Web',
          ClientName: 'KRBL',
          showPServifyTxt: true,
        }
        break
      case 'happi':
        message = {
          header: 'Happi-Mobiles-Web',
          ClientName: 'Happi Mobiles',
          showPServifyTxt: true,
        }
        break
      case 'sangeetha':
        message = {
          header: 'Sangeetha-Mobiles-Web',
          ClientName: 'Sangeetha Mobiles',
          showPServifyTxt: true,
        }
        break
      case 'arrowhead':
        message = {
          header: 'ArrowHead-Web',
          ClientName: 'ArrowHead',
          showPServifyTxt: true,
        }
        break
      case 'iplanet':
        message = {
          header: 'iPlanet-Web',
          ClientName: 'iPlanet',
          showPServifyTxt: true,
        }
        break
      case 'istudio':
        message = {
          header: 'iStudio-Web',
          ClientName: 'iStudio',
          showPServifyTxt: true,
        }
        break
      case 'ipalace':
        message = {
          header: 'iPalace-Web',
          ClientName: 'iPalace',
          showPServifyTxt: true,
        }
        break
      case 'ifuture':
        message = {
          header: 'iFuture-Web',
          ClientName: 'iFuture',
          showPServifyTxt: true,
        }
        break
      case 'vijaysales':
        message = {
          header: 'Vijay-Sales-Web',
          ClientName: 'Vijay-Sales',
          showPServifyTxt: true,
        }
        break
      case 'unistore':
        message = {
          header: 'Unistore-Web',
          ClientName: 'Unistore',
          showPServifyTxt: true,
        }
        break
      case 'poojara':
        message = {
          header: 'Poojara-Web',
          ClientName: 'Poojara',
          showPServifyTxt: true,
        }
        break
      case 'poshvine':
        message = {
          header: 'Poshvine-Web',
          ClientName: 'Poshvine',
          showPServifyTxt: true,
        }
        break
      case 'bigcmobiles':
        message = {
          header: 'BigC-Mobiles-Web',
          ClientName: 'BigCMobiles',
          showPServifyTxt: true,
        }
        break
      case 'lotmobiles':
        message = {
          header: 'Lot-Mobiles-Web',
          ClientName: 'LotMobiles',
          showPServifyTxt: true,
        }
        break
      case 'nokiauae':
        message = {
          header: 'NokiaUAE-Web',
          ClientName: 'NokiaUAE',
          showPServifyTxt: true,
        }
        break
      case 'oneplususa':
        message = {
          header: 'OneplusNA-Web',
          ClientName: 'OnePlus',
          showPServifyTxt: true,
        }
        break
      case 'serviceleeweb':
        message = {
          header: 'ServiceLee-Web',
          ClientName: 'ServiceLee Sigorta ve Reasürans Brokerliği',
          showPServifyTxt: true,
        }
        break
      case 'etisalat':
        message = {
          header: 'Etisalat-Web',
          ClientName: 'Etisalat',
          showPServifyTxt: true,
        }
        break
      case 'istyle':
        message = {
          header: 'iStyle-Web',
          ClientName: 'PYD',
          showPServifyTxt: false,
        }
        break
      case 'servifycare':
        message = {
          header: 'ServifyCare-Web',
          ClientName: 'ServifyCare',
          isPaymentEncrypted: true,
          showPServifyTxt: false,
        }
        break
      case 'screenprotect':
        message = {
          header: 'Screencrackprotect-Web',
          ClientName: 'Screencrackprotect',
          isPaymentEncrypted: true,
          showPServifyTxt: true,
        }
        break
      case 'treadfit':
        message = {
          header: 'Treadfit-Web',
          ClientName: 'Treadfit',
          isPaymentEncrypted: true,
          showPServifyTxt: true,
        }
        break
      case 'godigit':
        message = {
          header: 'goDigit-Web',
          ClientName: 'GoDigit',
          showPServifyTxt: true,
        }
        break
      case 'vivo':
        message = {
          header: 'Vivo-Web',
          ClientName: 'Vivo',
          isPaymentEncrypted: true,
        }
        break
      case 'hp':
        message = {
          header: 'HpCare-Web',
          ClientName: 'HP',
        }
        break
      case 'nokiaus':
        message = {
          header: 'NokiaNA-Web',
          ClientName: 'Nokia',
          showPServifyTxt: true,
        }
        break
      case 'samsungenterprise':
        message = {
          header: 'SamsungEnterprise-Web',
          ClientName: 'Samsung',
          showPServifyTxt: true,
        }
        break
      case 'samsungus':
        message = {
          header: 'SamsungUS-Web',
          ClientName: 'Samsung',
          showPServifyTxt: true,
        }
        break
      case 'nothing':
        message = {
          header: 'Nothing-Web',
          ClientName: 'Nothing',
          showPServifyTxt: true,
        }
        break
      case 'realmeeu':
        message = {
          header: 'RealmeEU-Web',
          ClientName: 'Realme',
          showPServifyTxt: true,
        }
        break
      case 'mds':
        message = {
          header: 'MDSSamsung-Web',
          ClientName: 'MDS',
          showPServifyTxt: true,
        }
        break
      case 'kaspersky':
        message = {
          header: 'Kaspersky-Web',
          ClientName: 'Kaspersky',
          showPServifyTxt: true,
        }
        break
      case 'bose':
        message = {
          header: 'Bose-Web',
          ClientName: 'Bose',
          showPServifyTxt: true,
        }
        break
      case 'harmanuae':
        message = {
          header: 'HarmanUAE-Web',
          ClientName: 'Harman',
          showPServifyTxt: true,
        }
        break
      case 'goldcare':
        message = {
          header: 'GoldCare-Web',
          ClientName: 'GoldCare',
          showPServifyTxt: true,
        }
        break
      case 'thumb':
        message = {
          header: 'ThumbUS-Web',
          ClientName: 'Thumb',
          showPServifyTxt: true,
        }
        break
      case 'odysse':
        message = {
          header: 'Odysse-Web',
          ClientName: 'Odysse',
          showPServifyTxt: true,
        }
        break
      case 'oppo':
        message = {
          header: 'Oppo-Web',
          ClientName: 'OPPO',
          showPServifyTxt: true,
        }
        break
      case 'narwal':
        message = {
          header: 'NarwalUS-Web',
          ClientName: 'Narwal',
          showPServifyTxt: true,
        }
        break
      case 'lgus':
        message = {
          header: 'LGLA-Web',
          ClientName: 'LG',
          showPServifyTxt: true,
        }
        break
      default:
        message = {
          header: 'Consumer-Web',
          ClientName: 'Servify',
          isPaymentEncrypted: true,
          showPServifyTxt: false,
        }
    }
  }
  return message
}

export const NavHeadersConst = () => {
  let defHeaders = ['Home', 'My devices', 'Store', 'Notifications', 'Profile']
  switch (process.env.APP_NAME) {
    case 'oneplus':
    case 'oneplususa':
      defHeaders.push('purchased history')
      break
    case 'servifycare':
      defHeaders.push('my plans')
      break
  }
  return defHeaders
}

export const HeadersTabsConst = (tabName) => {
  let tab = [
    {
      routeName: 'home',
      labelText: 'home',
      routeRegex: /^\/$/,
      path: '/',
      isEnabledOnHeader: true,
      isProtectedRoute: true,
    },
    {
      routeName: 'my devices',
      labelText: 'myDevices',
      routeRegex: /devices/,
      path: '/devices',
      isEnabledOnHeader: true,
      isProtectedRoute: true,
    },
    {
      routeName: 'store',
      labelText: 'store',
      routeRegex: /store/,
      path: '/store',
      isEnabledOnHeader: true,
      isProtectedRoute: false,
    },
    {
      routeName: 'subscription',
      labelText: 'subscription',
      routeRegex: /subscription/,
      path: '/subscription',
      isProtectedRoute: true,
    },
    {
      routeName: 'my plans',
      labelText: 'myPlans',
      routeRegex: /myplans/,
      path: '/myplans',
      isEnabledOnHeader: true,
      isProtectedRoute: true,
    },
    {
      routeName: 'notifications',
      labelText: 'notifications',
      routeRegex: /notifications/,
      path: '/notifications',
      isEnabledOnHeader: true,
      isProtectedRoute: true,
    },
    {
      routeName: 'profile',
      labelText: 'profile',
      routeRegex: /profile/,
      path: '/profile',
      isEnabledOnHeader: true,
      isProtectedRoute: true,
    },
    {
      routeName: 'request',
      labelText: 'request',
      routeRegex: /request/,
      path: '/request',
      isEnabledOnHeader: false,
      isProtectedRoute: true,
    },
    {
      routeName: 'track',
      labelText: 'track',
      routeRegex: /track/,
      path: '/track',
      isEnabledOnHeader: false,
      isProtectedRoute: true,
    },
    {
      routeName: 'service estimator',
      labelText: 'serviceEstimator',
      routeRegex: /service-estimator/,
      path: '/service-estimator',
      isEnabledOnHeader: false,
      isProtectedRoute: true,
    },
    {
      routeName: 'login',
      labelText: 'login',
      routeRegex: /login/,
      path: '/login',
      isEnabledOnHeader: false,
      isProtectedRoute: false,
    },
    {
      routeName: 'payment',
      labelText: 'payment',
      routeRegex: /payment/,
      path: '/payment',
      isEnabledOnHeader: false,
      isProtectedRoute: true,
    },
    {
      routeName: 'purchased history',
      labelText: 'purchasedHistory',
      routeRegex: /purchasehistory/,
      path: '/purchasehistory',
      isEnabledOnHeader: false,
      isProtectedRoute: true,
    },
    {
      routeName: 'customroute',
      labelText: 'customroute',
      routeRegex: /custom-route/,
      path: '/custom-route',
      isEnabledOnHeader: false,
      isProtectedRoute: false,
    },
    {
      routeName: 'link account',
      labelText: 'linkAccount',
      routeRegex: /link-account/,
      path: '/link-account',
      isEnabledOnHeader: false,
      isProtectedRoute: true,
    },
    {
      routeName: 'about us',
      labelText: 'aboutUs',
      routeRegex: /about-us/,
      path: '/about-us',
      isProtectedRoute: true,
      isEnabledOnHeader: true,
    },
    {
      routeName: 'privacy policy',
      labelText: 'privacyPolicy',
      routeRegex: /privacy-policy/,
      path: '/privacy-policy',
      isProtectedRoute: false,
      isEnabledOnHeader: false,
    },
    {
      routeName: 'terms and conditions',
      labelText: 'termsAnd conditions',
      routeRegex: /terms-conditions/,
      path: '/terms-conditions',
      isProtectedRoute: false,
      isEnabledOnHeader: false,
    },
    {
      routeName: 'online consent',
      labelText: 'OnlineConsent',
      routeRegex: /online-consent/,
      path: '/online-consent',
      isProtectedRoute: false,
      isEnabledOnHeader: false,
    },
    {
      routeName: 'mycare',
      labelText: 'mycare',
      routeRegex: /mycare/,
      path: '/mycare',
      isEnabledOnHeader: true,
      isProtectedRoute: false,
    },
    {
      routeName: 'faq',
      labelText: 'faq',
      routeRegex: /faq/,
      path: '/faq',
      isEnabledOnHeader: true,
      isProtectedRoute: false,
    },
    {
      routeName: 'vouchers',
      labelText: 'vouchers',
      routeRegex: /vouchers/,
      path: '/vouchers',
      isEnabledOnHeader: true,
      isProtectedRoute: true,
    },
  ]
  let tabInfo = tab.find((route) => route.routeName === tabName.toLowerCase())
  return tabInfo || tab[0]
}

export const defaultFooterLinks = {
  consumerLinks: [
    {
      label: 'Home',
      link: '/',
      display: true,
    },
    {
      label: 'My Devices',
      link: '/devices',
      display: true,
    },
    {
      label: 'Store',
      link: '/store',
      display: true,
    },
    {
      label: 'Servify Guide',
      link: '//guide.servify.in/',
      newTab: true,
      display: true,
    },
    {
      label: 'Blog',
      link: '//blog.servify.tech/',
      newTab: true,
      display: true,
    },
  ],
  bussinessLinks: {
    aboutUs: '//servify.in/#about',
    privacyPolicy: '//servify.in/privacy',
    Terms: '//servify.in/termsandconditions/servify/',
    contactUs: '//servify.in/contact/',
  },
  supportLinks: {
    email: 'support@servify.tech',
    contact: '18333133331',
  },
  stayConnectedLinks: {
    facebook: '//www.facebook.com/servifyIn/',
    twitter: '//twitter.com/servifyin',
    linkedIn: '//www.linkedin.com/company-beta/10303025/',
    google: '//plus.google.com/u/0/+ServifyIn',
    instagram: '//www.instagram.com/servify.in/',
  },
}

export const supportedModesBasedonType = (type) => {
  let ids = []
  switch (type) {
    case 'install':
      ids = [5, 6]
      break
    case 'service':
      ids = [7]
      break
    case 'repair':
    case 'refund':
    case 'replacement':
    default:
      ids = [1, 11, 2, 12, 3, 31, 32, 17, 23, 53, 54]
    // pick up (C), carry-in (C), on-site , EW (C), Dropoff (C), Refund/ Replacement DropOff
  }
  return ids
}

export const requestTypesList = () => [
  {
    type: 'repair',
    description: 'Raise a repair request.',
    text: getTranslationText('common', 'bookRepairLabel'),
    key: 'showRepairOptions',
    image: bookRepairImg,
    path: '/request/select-device?type=repair',
  },
  {
    type: 'claim',
    description: 'Raise a request.',
    text: getTranslationText('home', 'raiseClaim'),
    key: 'ShowRaiseClaim',
    image: bookRepairImg,
    path: '/request/select-device?type=repair',
  },
  {
    type: 'refund',
    text: getTranslationText('common', 'bookRefund'),
    description: 'Raise a refund request.',
    key: 'showRefundOption',
    image: bookRepairImg,
    path: '/request/select-order?type=refund',
  },
  {
    type: 'replacement',
    description: 'Raise a replacement request.',
    text: 'Replacement',
    key: 'showReplacementOption',
    image: bookRepairImg,
    path: '/request/select-order?type=replacement',
  },
  {
    type: 'service',
    text: getTranslationText('common', 'getMaintainanceLabel'),
    description: 'Raise a service request.',
    key: 'showServicing',
    image: maintenanceServiceImg,
    path: '/request/select-device?type=service',
  },
  {
    type: 'install',
    text: getTranslationText('common', 'getDemoLabel'),
    description: 'Raise a install request.',
    key: 'showInstallAndDemo',
    image: installDemoImg,
    path: '/request/select-device?type=install',
  },
  {
    type: 'estimate',
    text: getTranslationText('common', 'getEstimateLabel'),
    description: 'Raise a estimate request.',
    key: 'showServiceEstimator',
    image: serviceEstimateImg,
    path: '/service-estimator',
    skipRepairCheck: true,
  },
]

export const mapDefaultStyles = {
  styles: [
    {
      featureType: 'poi.attraction',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'poi.government',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'poi.park',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'poi.place_of_worship',
      stylers: [{ visibility: 'off' }, { weight: 1 }],
    },
    {
      featureType: 'poi.school',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'poi.sports_complex',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.stroke',
      stylers: [{ color: '#f2f2f2' }],
    },
    {
      featureType: 'road.local',
      elementType: 'geometry.stroke',
      stylers: [{ color: '#f0f0f0' }],
    },
    {
      featureType: 'transit.station',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'transit.station.airport',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'transit.station.airport',
      elementType: 'geometry',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'transit.station.airport',
      elementType: 'labels',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'water',
      stylers: [{ weight: 1.5 }],
    },
  ],
}

export const menuLangTranslationMapping = {
  Home: 'home',
  'My devices': 'myDevice',
  'My Devices': 'myDevice',
  Store: 'store',
  Notifications: 'notifications',
  Profile: 'profile',
  'Servify Guide': 'servifyGuide',
  Blog: 'blog',
  'My Product': 'myProduct',
}

export const fallbackAddressConfig = [
  {
    keyName: 'PinCode',
    length: [6],
    regexFunc: 'NUMERIC',
    label: getTranslationText('profile', 'zipCodeLabel'),
    mandatory: true,
    displayOrder: 1,
  },
  {
    keyName: 'Address',
    label: getTranslationText('profile', 'completeAddressLabel'),
    mandatory: true,
    display: true,
    displayOrder: 2,
  },
  {
    keyName: 'State',
    label: getTranslationText('profile', 'stateLabel'),
    mandatory: true,
    display: true,
    displayOrder: 3,
  },
  {
    keyName: 'City',
    label: getTranslationText('profile', 'cityLabel'),
    mandatory: true,
    display: true,
    displayOrder: 4,
  },
  {
    keyName: 'Landmark',
    label: getTranslationText('profile', 'landmarkLabel'),
    mandatory: false,
    display: true,
    displayOrder: 5,
  },
]

export const legalConfigCodes = {
  us: {
    // for country code US
    tnc: 'TNC_AGREEMENT',
    buy_tnc: 'BUY_NOW_TNC_AGREEMENT',
    data: 'TNC_AGREE_DATA_SHARE',
    subscription: 'TNC_SUBSCRIPTION_PURCHASE',
    claim: 'WARRANTY_VOID_CLAIM',
  },
  tr: {
    // for country code TR
    tnc: 'TNC_AGREEMENT_CONSUMER_APP_OTP',
    data: 'TNC_AGREE_DATA_SHARE',
  },
  other: {
    // list of consents generic to other countries
    tnc: 'TNC_AGREEMENT',
    buy_tnc: 'BUY_NOW_TNC_AGREEMENT',
    data: 'TNC_AGREE_DATA_SHARE',
    tnc_insurance: 'TNC_AGREE_INSURANCE',
    buy_tnc_gst_credit_na: 'BUY_NOW_TNC_GST_CREDIT_NA',
    payment_decl: 'PAYMENT_DECLARATION_WEB',
    payment_decl2: 'PAYMENT_DECL_WEB', // this is without [other related documents]
  },
}

export const legalConfigAttributes = {
  email: 'EmailID',
  imei_serial: 'imeiOrSerial',
  privacy: 'PrivacyPolicyLink',
  tnc: 'TermsAndConditionsLink',
  ipid: 'IPIDLink',
  insurancecard: 'IPIDLink',
  relateddocuments: 'OtherRelatedDocumentsLink',
  dataconsent: 'ConsentURL',
  dataconsentstore: 'ConsentURL',
  otp: 'OTP',
  country: 'country',
}

export const generateRandomString = (length) => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const docServiceModuleName = 'Consumer-Web'
