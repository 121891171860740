import { connect } from 'react-redux'
import {
  getDeviceOverviewAsync,
  setSliderState,
  getUserDetailsAsync,
  fetchBannersAsync,
  fetchDiscountsAsync,
  loadAppConfig,
  getAppLegalConfigs,
  sendotpreport,
  generateRedirectionUrl,
  getAccessTokenFromCookie,
  loginUsingSSOAsync,
  setIsSSOFlow,
  checkIfContractPurchasedFromThirdPartyAsync,
} from '../../../store/user'
import { logConsentsAsync } from '../../../store/storeReducer'
import { getProductsList, UserDevicePointsAsync, getDeviceRepairHistory, addDeviceAsync } from '../../../store/devices'
import { setToaster, setglobalLoaderStatus } from '../../../store/toastsAndLoaders'
import { setSelectedDevice, setSelectedOrder, clearRequestState } from '../../../store/request'
import { clearTrackState } from '../../../store/track'
import getFaq from '../../../api/getFaq'
import { fetchSoldPlanBenefits } from '../../../store/vouchers'
import { getPurchasedPlansAsync } from '../../../store/myPlansReducer'
import { getPayableAmountForNonLoginFlow } from '../../../store/payment'
import { storeLastKnowPathName } from '../../../store/location'
import { setSoldPlanDetails } from '../../../store/myPlansReducer'

/*  This is a container component. Notice it does not contain any JSX,
    nor does it import React. This component is **only** responsible for
    wiring in the actions and state necessary to render a presentational
    component - in this case, the counter:   */

import Home from '../components/Home'

const mapDispatchToProps = {
  // loadAppConfig,
  getDeviceOverviewAsync,
  getProductsList,
  UserDevicePointsAsync,
  setToaster,
  getDeviceRepairHistory,
  getAccessTokenFromCookie,
  loginUsingSSOAsync,
  setSelectedDevice,
  setSliderState,
  getUserDetailsAsync,
  fetchBannersAsync,
  fetchSoldPlanBenefits,
  fetchDiscountsAsync,
  addDeviceAsync,
  loadAppConfig,
  getAppLegalConfigs,
  setglobalLoaderStatus,
  sendotpreport,
  setSelectedOrder,
  generateRedirectionUrl,
  getFaq,
  clearRequestState,
  logConsentsAsync,
  setIsSSOFlow,
  clearTrackState,
  getPurchasedPlansAsync,
  getPayableAmountForNonLoginFlow,
  storeLastKnowPathName,
  setSoldPlanDetails,
  checkIfContractPurchasedFromThirdPartyAsync,
}

const mapStateToProps = (state) => ({
  user: state.user,
  store: state.store,
  devices: state.devices,
  request: state.request,
  location: state.location,
  vouchers: state.vouchers,
  translationData: state.languageDetail.translationText,
})

export default connect(mapStateToProps, mapDispatchToProps)(Home)
