import React from 'react'
import PropTypes from 'prop-types'
import { Radio } from 'sfy-react'
import './RadioBox.scss'

/**
 * Represent a Radio with Content in a box ;-)
 *
 * @component
 *
 * @property {Boolean} isSelected This props decides if the RadioBox is in active state which has slighlty different style
 * @property {Function} handleOnClick handler function called when RadioBox is clicked.
 * @property {String} image optional image to show on the left
 * @property {*} children Child elements/ Content of the RadioBox
 * @property {Object} className object containing classNames for various elements of the RadioBox
 * @property {String} id id
 */

const RadioBox = ({ isSelected, handleOnClick, image, children, className, id, showRadio = true, showFlair, flairText, name }) => {
  return (
    <div
      className={`radiobox ${isSelected ? 'radiobox--active' : ''} ${className?.container || ''} ${showFlair ? 'radiobox--flair' : ''}`}
      onClick={handleOnClick}
      id={id}
      data-flair-text={flairText}
    >
      {image ? (
        <div className='ml8 mr8  align-center'>
          <img className='left-image' src={image} />
        </div>
      ) : (
        ''
      )}
      <div className={`align-center pr28 ${className?.content || ''}`}>
        <span style={{ textAlign: 'left' }} className='pr20'>
          {children}
        </span>
      </div>
      {showRadio && (
        <div className='ml20 mr8 align-center'>
          <Radio
            classes={`radiobox__radio ${className?.radio || ''}`}
            value={isSelected || 'not-selected'}
            name={name || 'switiching-on'}
            selectedValue={isSelected}
          />
        </div>
      )}
    </div>
  )
}

RadioBox.propTypes = {
  isSelected: PropTypes.bool,
  handleOnClick: PropTypes.func,
  image: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.object,
}

export default RadioBox
