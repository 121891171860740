import React, { useCallback, useEffect, useState } from 'react'
import { AllVouchers, VouchersDetails } from './VoucherSubComponents'
import { browserHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { BrandSpecificConfig } from '../../../utils/constants'
import { setglobalLoaderStatus } from '../../../store/toastsAndLoaders'

const Vouchers = ({ fetchSoldPlanBenefits, markBenefitAsUsed }) => {
  const [voucherCategories, setVoucherCategories] = useState({
    Available: [],
    Unlocked: [],
    Expired: [],
  })
  const [selectedVoucher, setSelectedVoucher] = useState({})

  const dispatch = useDispatch()

  const user = useSelector((state) => state.user)

  const fetchData = useCallback(async () => {
    try {
      dispatch(setglobalLoaderStatus(true))
      const requestObj = {
        app: BrandSpecificConfig().header,
        appFilters: true,
        ConsumerID: user.consumerDetails.data.consumerID,
      }
      const response = await fetchSoldPlanBenefits(requestObj, user.consumerDetails.data.accessToken.ID)

      const categories = {
        Available: [],
        Unlocked: [],
        Expired: [],
      }
      let autoSelectedVoucher = {}

      if (response) {
        response.forEach((item) => {
          if (item.Status === 'ACTIVE') {
            categories.Available.push(item)
          } else if (item.Status === 'EXPIRED') {
            categories.Expired.push(item)
          } else if (item.Status === 'USED') {
            categories.Unlocked.push(item)
          }
        })
        setVoucherCategories(categories)
        if (window.location.pathname.includes('/vouchers/') && Object.keys(categories).length) {
          const soldPlanBenefitID = parseInt(window.location.pathname.match(/\d+/g))
          Object.values(categories).forEach((vouchers) => {
            vouchers.forEach((voucher) => {
              if (voucher.SoldPlanBenefitID === soldPlanBenefitID) {
                autoSelectedVoucher = voucher
                setSelectedVoucher(voucher)
              }
            })
          })
        }
      }
      !Object.entries(autoSelectedVoucher).length && browserHistory.push('/vouchers')
      dispatch(setglobalLoaderStatus(false))
    } catch (error) {
      dispatch(setglobalLoaderStatus(false))
      console.error('Error fetching data:', error)
    }
  }, [user, dispatch, fetchSoldPlanBenefits])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  if (window.location.pathname === '/vouchers')
    return <AllVouchers voucherCategories={voucherCategories} setSelectedVoucher={setSelectedVoucher} />
  else if (window.location.pathname.includes('/vouchers/'))
    return <VouchersDetails markBenefitAsUsed={markBenefitAsUsed} fetchData={fetchData} selectedVoucher={selectedVoucher} />
}

export default Vouchers
