import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'
// import moment from 'moment'
import './react-datepicker.css'
import './input.scss'
import { getTranslationText } from '../../../utils/languageTranslation'

const stripHTML = (text) => {
  var regex = /(<([^>]+)>)/gi
  return text.replace(regex, '')
}

const withSecurityValidations = (onChangeCb) => (event) => {
  event.persist()
  const {
    target: { type, value },
  } = event
  if (type === 'text') {
    const eventCopy = { ...event }
    eventCopy.target.value = stripHTML(value)
    onChangeCb(eventCopy)
  } else {
    onChangeCb(event)
  }
}

class Input extends Component {
  constructor() {
    super()
    this.state = {
      dateTypeAvail: true,
    }
  }
  componentDidMount() {
    if (this.props.type === 'date') {
      this.setState({
        dateTypeAvail: this.checkTypeCompatibility(this.props.type),
      })
    }
  }

  checkTypeCompatibility = (type) => {
    let ele = document.createElement('input')
    ele.setAttribute('type', type)
    return ele.type === type
  }

  checkValidDate = (date) => {
    return date && !!date.getDate() ? date : undefined
  }

  render() {
    const { type, onChange } = this.props
    if (this.props.type === 'date') {
      return (
        <DatePicker
          className={this.props.className + ' date-picker'}
          id={this.props.id}
          dateFormat={this.props.dateFormat ? this.props.dateFormat.toString().toLowerCase().replace(/m/g, 'M') : null}
          autoComplete='off'
          placeholderText={this.props.placeholder || this.props.placeHolderDate}
          readOnly={this.props.readOnly}
          withPortal={this.props.withPortal}
          customInput={<CustomDatePickerInput placeholder={this.props.placeholder} />}
          minDate={this.checkValidDate(this.props.min) || new Date()}
          maxDate={this.checkValidDate(this.props.max) || undefined}
          selected={this.props.value ? this.checkValidDate(new Date(this.props.value)) : undefined}
          openToDate={this.props.value ? this.checkValidDate(new Date(this.props.value)) : new Date()}
          onChange={onChange}
          disabled={this.props.disabled}
        />
      )
    } else {
      return (
        <input
          dir={this.props.direction ? 'rtl' : 'ltr'}
          type={type}
          className={this.props.className}
          id={this.props.id}
          name={this.props.name}
          title={this.props.title}
          accept={this.props.accept}
          readOnly={this.props.readOnly}
          autoComplete={this.props.autoComplete}
          style={this.props.style}
          pattern={this.props.pattern}
          onClick={this.props.onClick}
          onChange={this.props.withSecurityValidations(onChange)}
          onFocus={this.props.onFocus}
          onBlur={this.props.onBlur}
          onKeyDown={this.props.onKeyDown}
          onKeyPress={this.props.onKeyPress}
          min={this.props.min}
          max={this.props.max}
          maxLength={this.props.maxLength}
          placeholder={this.props.placeholder}
          value={this.props.value}
          checked={this.props.checked}
          disabled={this.props.disabled}
          required={this.props.required}
        />
      )
    }
  }
}

Input.propTypes = {
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.any,
  title: PropTypes.string,
  accept: PropTypes.string,
  autoComplete: PropTypes.string,
  style: PropTypes.object,
  pattern: PropTypes.string,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyPress: PropTypes.func,
  withSecurityValidations: PropTypes.func,
  min: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  max: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  dateFormat: PropTypes.string,
  readOnly: PropTypes.bool,
  withPortal: PropTypes.bool,
  placeHolderDate: PropTypes.string,
}

Input.defaultProps = {
  dateFormat: 'dd MMM yyyy',
  placeHolderDate: getTranslationText('request', 'placeHolderDate'),
  withSecurityValidations,
  onChange: () => {},
}

class CustomDatePickerInput extends React.Component {
  render() {
    return (
      <input
        className='input-text boldFontStyle'
        readOnly
        placeholder={this.props.placeholder || getTranslationText('request', 'placeHolderDate')}
        value={this.props.value}
        onClick={this.props.onClick}
      />
    )
  }

  static propTypes = {
    onClick: PropTypes.func,
    value: PropTypes.string,
  }
}

export default Input
