import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'
import moment from 'moment'
import uuid from 'node-uuid'
import _ from 'lodash'
import { connect } from 'react-redux'
import {
  StoreLandingPage,
  ViewProductStoreComponent,
  CheckoutStoreMainComponent,
  CustomizedPlanComponent,
  BuyStoreMainComponent,
  ReviewPurchasePlanComponent,
} from './StoreSubComponents'
import './Store.scss'
import pushToAnalytics from '../../../utils/Analytics'
import { validateInputChangeForDevice, defaultValidationFilter, canUpload } from '../../../utils/validators'
import { regexCheck } from '../../../utils/regex'
import lookup from '../../../utils/ipinfo'
import { Modal, Dropdown } from 'sfy-react'
import { getTranslationText } from '../../../utils/languageTranslation'
import { MediumButton } from '../../../components/UIComponents/Buttons/Buttons'
import getTranslations from '../../../api/getTranslations'
import { useQuery } from '../../../utils/functions'
const brandConfig = require('../../../utils/constants').BrandSpecificConfig()
// import { checkIfHasAccessToken } from '../../../utils/functions'
// const MiniLogo = '../../../' + _.get(images, 'common.MINILOGO')
// import _ from 'lodash'
import { getLocaleCurrency } from '../../../utils/locale'
import { postRequestAsync } from '../../../api/genericAPIs'
import { BrandSpecificConfig, fallbackAddressConfig } from '../../../utils/constants'
import {
  setDistrictListConfig,
  getUserAddressesAsync,
  getStateListAsync,
  getGeoLocationAsync,
  updateAddressAsync,
  addAddressAsync,
} from '../../../store/user'

const defHomeIcon = '/commonAssets/DefAddressTypeBtnIcons/Home.png'
const defOfficeIcon = '/commonAssets/DefAddressTypeBtnIcons/Office.png'
const defOtherIcon = '/commonAssets/DefAddressTypeBtnIcons/Other.png'
const activeHomeIcon = `/${appName}/images/AddressTypeBtnIcons/Home.png`
const activeOfficeIcon = `/${appName}/images/AddressTypeBtnIcons/Office.png`
const activeOtherIcon = `/${appName}/images/AddressTypeBtnIcons/Other.png`

class Store extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedCountryObj: _.get(props, 'user.selectedCountryData', {}),
      showLanguageModal: false,
      productListBoxData: {
        categoryList: [],
        brandList: [],
        productList: [],
      },
      newDeviceListBoxDataValues: {
        categoryValue: undefined,
        brandEnabled: false,
        brandValue: undefined,
        productEnabled: false,
        productValue: undefined,
      },
      deviceOverviewState: {
        noOfDevices: '00',
        noOfDevicesProtected: '00',
      },
      // termsandCondnCkbox: false,
      checkeligibltyTC: false,
      selectedItems: Object.keys(_.get(props, 'store.selectedPlanDetails', {})).length
        ? (() => {
            return this.createSelectedItem(props.store.selectedPlanDetails)
          })()
        : {},
      selectedState: {},
      userDeviceStateLoader: true,
      Zipcode: '',
      showEligibilityStatus: false,
      eligibilityLoader: false,
      eligiblityCheckValue: '',
      storeCheckoutWidgetLoader: false,
      secondaryDOPValue: '',
      productPurchaseCost: '',
      selectedDeviceModel: '',
      showDeviceDocsUploadModal: false,
      // custom plan keys
      customPlanImeiDisabled: true,
      customPlanDOPStatus: true,
      customPlanPriceDisabled: true,
      showCustomPlanEligibilityStatus: false,
      isCustomPlanEligible: false,
      CustomPlanIMEIValue: '',
      CustomPlanDevicePrice: '',
      customPlanIMEIError: '',
      customPlanDOPValue: '',
      showProductPriceInput: '',
      showDOP: false,
      isDopAvailable: false,
      isProductPurchasePriceAvailable: false,
      storeBannersList: [],
      showCustomQuestions: false,
      planViewSelectedTab: 0,
      showDisclaimerModal: false,
      showDateOfPruchase: false,
      showProductPurchaseCost: false,
      disableIMEI: false,
      disableDeviceModel: false,
      isPaymentModeSelected: false,
      showPaymentMode: false,
      showAddress: false,
      addressTypesList: [
        {
          text: getTranslationText('AddressType', 'addressTypeHome'),
          isSelected: false,
          deactiveImg: defHomeIcon,
          activeImg: activeHomeIcon,
        },
        {
          text: getTranslationText('AddressType', 'addressTypeOffice'),
          isSelected: false,
          deactiveImg: defOfficeIcon,
          activeImg: activeOfficeIcon,
        },
        {
          text: getTranslationText('AddressType', 'addressTypeOther'),
          isSelected: false,
          deactiveImg: defOtherIcon,
          activeImg: activeOtherIcon,
        },
      ],
      isCreate: false,
      locations: [],
      selectedDistrict: {},
      pincodeLengthAr: [6],
      addressComponentLoader: false,
      locationObtained: false,
      newAddressDetails: {},
      landmark: '',
      placesAutocompleteOptions: {},
      showDOB: _.get(props, 'store.selectedPlanDetails.PlanConfig.SuperGroupEligibilityCheckFields', []).includes('BirthDate'),
      disableDOB: true,
      dateOfBirth: _.get(this, 'props.user.userDetails.birthDate', ''),
      dateOfBirthVerified: false,
      showCountryModal: Object.keys(_.get(props, 'user.selectedCountryData', {})).length === 0,
      isStateInputDisabled: false,
      isCityInputDisabled: false,
      isPincodeInputDisabled: false,
      showPaymentDeclPopup: false,
      paymentDeclConsentShown: false,
      paymentDeclConsentText: '',
      showInvalidDOBModal: false,
      dobInvalidMessage: '',
      taxErrors: {},
      taxAddressId: null,
      isAddAddress: false,
      isEditAddress: false,
      requiredDetails: {
        zipCode: '',
        landmark: '',
        userlandmark: '',
        city: '',
        state: '',
        lat: '',
        lng: '',
        ConsumerFavouriteLocationID: '',
        address: '',
      },
      isEdit: false,
      showDiscountDetails: false,
      isNewPlanSalesFlow: _.get(this, 'props.user.appConfigDetails.nonOtpPlanSale', false),
      showIMEILoader: false,
      reviewScreenGtmEventSent: false,
      initialLoggedInState: !!_.get(this, 'props.user.userDetails.consumerID', false),
    }
  }

  setTaxErrors = (errors) => {
    this.setState({ taxErrors: errors })
  }

  setIsEditAddress = (isEdit) => {
    this.setState({ isEditAddress: isEdit })
  }

  setIsAddAddress = (isAdd) => {
    this.setState({ isAddAddress: isAdd })
  }

  setTaxAddressId = (id) => {
    this.setState({ taxAddressId: parseInt(id) })
  }

  setRequiredDetails = (details) => {
    this.setState({ requiredDetails: { ...this.state.requiredDetails, ...details } })
  }

  openConsentsPopup = (consentText) => {
    this.setState({
      showPaymentDeclPopup: true,
      paymentDeclConsentShown: true,
      paymentDeclConsentText: consentText,
    })
  }

  closeConsentsPopup = () => {
    this.setState({
      showPaymentDeclPopup: false,
      paymentDeclConsentShown: false,
    })
  }

  setDOBInvalidModal = (shouldShow, message) => {
    this.setState({
      showInvalidDOBModal: shouldShow,
      dobInvalidMessage: message,
    })
  }

  isPlanPurchasePage = () => /\/buy/.test(this.props.location.pathname) || this.props.params.p === 'buy'

  componentDidUpdate = async (prevProps) => {
    const hasPathChanged = prevProps.params.p !== this.props.params.p
    const shouldFetchPlan = this.isPlanPurchasePage() && hasPathChanged
    if (shouldFetchPlan) {
      await this.fetchPlanInfoForPlanID(this.props.location.query.id, this.props.location.query.brandId)
    } else if (hasPathChanged) {
      if (!this.props.user.locations && this.props.user.userDetails.consumerID && this.props.user.consumerDetails.data.accessToken) {
        this.loadBrandRelatedPlans()
      }
    }
    if (this.props !== prevProps) {
      this.setState({
        selectedItems:
          this.props.store.selectedPlanDetails && Object.keys(this.props.store.selectedPlanDetails).length > 0
            ? this.props.store.selectedPlanDetails
            : {},
      })
    }
    if (!this.props.user.locations && this.props.user.userDetails.consumerID && this.props.user.consumerDetails.data.accessToken) {
      this.props
        .getUserAddressesAsync({
          ConsumerID: this.props.user.userDetails.consumerID,
          AuthID: this.props.user.consumerDetails.data.accessToken.ID,
        })
        .then(() => {
          this.props.getStateListAsync().then(() => {
            this.setState({
              locations: this.props.user.locations,
              isCreate: !this.props.user.locations.length,
            })
          })
        })
    }
  }

  // Check if plan is present in query params and set relative tab active
  setSelectedTab = (selectedPlan) => {
    const { search } = browserHistory.getCurrentLocation()
    const tabPlanMap = {
      Protect: 0,
      Secure: 1,
    }
    const plan = selectedPlan || useQuery(search).get('plan')
    const selectedTab = tabPlanMap[plan]

    if (parseInt(selectedTab) >= 0) {
      this.listPlanViewDetails(selectedTab)
    }
  }

  createSelectedItem = (planObj, pickedPlanPrice) => {
    const locale = _.get(this.props, 'user.selectedCountryData.countryConfig.Locale.DEFAULT', 'en')
    const currencyCode = _.get(this.props, 'user.selectedCountryData.CurrencyCode')
    planObj.planPrice = planObj.planPrice.map((price, index) => {
      return {
        ...price,
        text: getLocaleCurrency(locale, currencyCode, price.PlanPrice),
        // + (price.Value ? (' for ' + price.Value) : '')
        isSelected: pickedPlanPrice ? price.PlanPrice === pickedPlanPrice : index === 0,
      }
    })
    return planObj
  }

  setCountryLocal = async (regionCode, bDontSetLang = false) => {
    let selectedObj = this.props.user.countries.find((country) => country.CountryCode === regionCode)
    if (!selectedObj) {
      this.toggleModal('showCountryModal', true)
      if (_.isEmpty(this.state.selectedCountryObj)) {
        if (this.props.user.countries.length === 1) {
          this.setState({ selectedCountryObj: _.get(this.props, 'user.countries[0]', {}) })
        }
      } else {
        this.props.setSelectedCountryData(this.state.selectedCountryObj)
      }
      this.props.setglobalLoaderStatus(false)
    } else {
      this.props.setSelectedCountryData({
        ...selectedObj,
        label: '+ ' + selectedObj.PhoneCode,
        value: selectedObj.PhoneCode,
      })
      if (_.get(this.props, 'user.countries', []).length === 1 || !_.isEmpty(selectedObj)) this.toggleModal('showCountryModal', false)
      this.setState({ selectedCountryObj: selectedObj })
      if (!bDontSetLang) {
        const fallback = 'en'
        let defaultLanguage = _.get(selectedObj, 'countryConfig.LanguageCode.DEFAULT', fallback).toLowerCase()
        localStorage.setItem('languageCode', defaultLanguage)
        this.props.setglobalLoaderStatus(true)
        try {
          await this.props.getTranslations({ languageCode: defaultLanguage })
        } catch (e) {
          console.log(e)
        }
        this.props.setglobalLoaderStatus(false)
      }
      this.callAPIs()
    }
  }

  toggleModal = (key, value) => {
    this.setState({
      [key]: value,
    })
  }

  handleDeviceModelChange = (valueObj) => {
    this.setState({
      selectedDeviceModel: valueObj.ProductName,
    })
  }

  handleCountryChange = (value) => {
    let configDetails = this.props.user
    if (value.CountryID !== configDetails.selectedCountryData.CountryID) {
      // call appConfig only when selected country has changed
      this.setState({
        selectedCountryObj: value,
      })
      this.props.setSelectedCountryData(value)
    }
  }

  handleCountryClick = async () => {
    this.props.setSelectedCountryData(this.state.selectedCountryObj)
    const fallback = 'en'
    let defaultLanguage = _.get(this.state.selectedCountryObj, 'countryConfig.LanguageCode.DEFAULT', fallback).toLowerCase()
    localStorage.setItem('languageCode', defaultLanguage)
    this.props.setglobalLoaderStatus(true)
    try {
      await this.props.getTranslations({ languageCode: defaultLanguage })
    } catch (e) {
      console.log(e)
    }
    this.props.setglobalLoaderStatus(true)
    this.props.loadAppConfig().then(() => {
      this.props.getAppLegalConfigs().then(() => {
        this.toggleModal('showCountryModal', false)
        this.callAPIs()
      })
    })
  }

  callAPIs = async () => {
    try {
      this.props.setglobalLoaderStatus(true)
      let scdataParam = this.props.location.query.scdata
      // console.log('scdataParam - ', scdataParam)
      if (scdataParam) {
        scdataParam = decodeURIComponent(scdataParam)
        // console.log('tokenParam(after decode) - ', scdataParam)
        // bydefault in query params, + signs are replaced with blank space. so replace them back.
        scdataParam = scdataParam.replaceAll(' ', '+')
        // console.log('scdataParam(after replace) - ', scdataParam)
        this.props.decodeToken(scdataParam)
      }
      await this.props.getCountriesAsync()
      if (this.state.isNewPlanSalesFlow && !_.get(this, 'props.user.consumerDetails.data.consumerID', false)) {
        this.props.getStateListAsync().then(() => {
          this.setState({
            locations: this.props.user.locations,
            isCreate: !(this.props.user.locations && this.props.user.locations.length),
          })
        })
      }
      const response = await postRequestAsync('ConsumerProduct/fetchEligiblePlanSubTypes', {
        app: BrandSpecificConfig().header,
        CountryID: window.CountryID,
      })
      if (response && response.plan_types?.length) {
        const isMultiDevicePlan = response.plan_types.find((plan) => plan.plan_type === 'MULTI_DEVICE_PLAN')
        if (!isMultiDevicePlan) {
          if (!this.props.location.query.reloadPlansforCountry && !_.get(this, 'props.store.imeiForFilter', false))
            this.props.setglobalLoaderStatus(false)
          if (/\/view/.test(this.props.location.pathname) && this.props.location.query.id) {
            await this.handleStoreItemSelection(this.props.location.query.id, !this.props.location.query.reloadPlansforCountry)
            if (this.props.location.query.reloadPlansforCountry) this.props.setglobalLoaderStatus(false)
          } else if (/\/buy/.test(this.props.location.pathname) && this.props.location.query.id) {
            this.setState(
              {
                isNewPlanSalesFlow:
                  _.get(this, 'props.store.selectedBrand.BrandName', '').toUpperCase() !== 'APPLE' &&
                  _.get(this, 'props.user.appConfigDetails.nonOtpPlanSale', false),
              },
              () => {
                if (this.state.isNewPlanSalesFlow && !_.get(this, 'props.user.consumerDetails.data.consumerID', false)) {
                  this.props.getStateListAsync().then(() => {
                    this.setState({
                      locations: this.props.user.locations,
                      isCreate: !(this.props.user.locations && this.props.user.locations.length),
                    })
                  })
                }
              }
            )
            await this.fetchPlanInfoForPlanID(this.props.location.query.id, !this.props.location.query.reloadPlansforCountry)
            if (this.props.location.query.reloadPlansforCountry) this.props.setglobalLoaderStatus(false)
          } else {
            this.props.setglobalLoaderStatus(true)
            await this.props.fetchEntityPlanAsync({
              EntityType: 'Brand',
              skipPlanGrouping: false,
              GroupingType: 'PlanType',
              PlanFilter: { PlanType: ['Secure', 'Protect'] },
            })
            this.props.setglobalLoaderStatus(false)
            if (_.get(this, 'props.store.availableBrandsList', []).length === 1) {
              const brandName = this.props.store.availableBrandsList[0].BrandName
              this.handleStoreItemSelection(brandName)
            }
          }
          if (this.props.location.pathname === '/store') {
            this.props.setSelectedPlan({})
          } else {
            this.setState({
              selectedItems:
                this.props.store.selectedPlanDetails && Object.keys(this.props.store.selectedPlanDetails).length > 0
                  ? this.props.store.selectedPlanDetails
                  : {},
            })
          }
          if (this.props.user && !this.props.user.isAnGuestUser) {
            this.setState({
              eligiblityCheckValue: '',
              productPurchaseCost: '',
              showEligibilityStatus: false,
              eligibilityStatus: false,
              isAnGuestUser: false,
            })
            let ConsumerID = this.props.user.consumerDetails.data.consumerID
            this.props.setglobalLoaderStatus(true)
            const response = await this.props.getDeviceOverviewAsync({ ConsumerID })
            this.setState({
              userDeviceStateLoader: false,
              deviceOverviewState: {
                noOfDevices: this.deviceOverviewCounts(response.NoOfDevices),
                noOfDevicesProtected: this.deviceOverviewCounts(response.NoOfDevicesProtected),
              },
            })
            this.loadCategoryList()
            this.props.setglobalLoaderStatus(false)
          }
        } else {
          browserHistory.replace('/subscription')
        }
      }
    } catch (e) {
      console.log(e)
      this.props.setglobalLoaderStatus(false)
      this.props.setToaster(getTranslationText('error', 'somethingWentWrong'), 'error')
      browserHistory.replace('/')
    }
  }

  loadLandingPageBanner = () => {
    // this.props.setglobalLoaderStatus(true)
    this.props.fetchBannersAsync({}).then(() => {
      let storeBannersList = []
      this.props.user.bannerList.forEach((banner) => {
        if (banner.location.includes('store')) {
          const { imageLink, bannerLink, orderPreference } = banner
          let imagePath = imageLink || ''
          let onClick
          if (bannerLink) {
            onClick = () => {
              browserHistory.push(bannerLink)
            }
          }
          storeBannersList[orderPreference] = {
            ...banner,
            onClick,
            imagePath,
          }
        }
      })
      this.setState({
        storeBannersList,
      })
      this.props.setglobalLoaderStatus(false)
    })
  }

  componentDidMount = async () => {
    console.log('componentDidMount Store')
    try {
      window.scrollTo(0, 0)
      this.props.setglobalLoaderStatus(true)
      const cidParam = this.props.location.query.cid
      // console.log('cidParam - ', cidParam)
      if (cidParam) this.props.setExternalOriginSource(cidParam)
      if (_.isEmpty(this.props.user.userDetails)) {
        const regionCode = await lookup()
        window.CountryCode = this.props.location.query.reloadPlansforCountry || regionCode
        // window.CountryID = undefined
        // Domain redirection if Not country is Not India in "nokia.servify.tech"
        if (brandConfig.ClientName.includes('Nokia') && window.location.hostname.includes('nokia.servify.tech')) {
          if (!window.CountryCode.includes('IN')) {
            window.location.assign('https://nokia.aftersalesgroup.com/store')
          }
        }
      }
      await this.props.loadAppConfig()
      await this.props.getAppLegalConfigs()
      let isSSOEnabled = _.get(this, 'props.user.appConfigDetails.isSSOEnabled', false)
      const callbackURL = _.get(this, 'props.user.appConfigDetails.callbackURL', false)
      if (isSSOEnabled && !callbackURL && _.isEmpty(this.props.user.userDetails)) {
        await this.props.generateRedirectionUrl()
      }
      if (!this.props.location.pathname.includes('checkout')) {
        if (this.props.user.isAnGuestUser)
          this.setCountryLocal(
            this.props.location.query.reloadPlansforCountry || window.CountryCode,
            this.props.location.query.reloadPlansforCountry
          )
        else this.callAPIs()
      }
      if (this.props.user.userDetails.consumerID && this.props.user.consumerDetails.data.accessToken) {
        this.props
          .getUserAddressesAsync({
            ConsumerID: this.props.user.userDetails.consumerID,
            AuthID: this.props.user.consumerDetails.data.accessToken.ID,
          })
          .then(() => {
            this.props.getStateListAsync().then(() => {
              this.setState({
                locations: this.props.user.locations,
                isCreate: !this.props.user.locations.length,
              })
              this.props.setglobalLoaderStatus(false)
            })
          })
      } else this.props.setglobalLoaderStatus(false)
    } catch (e) {
      console.log(e)
      this.props.setglobalLoaderStatus(false)
      this.props.setToaster(getTranslationText('error', 'somethingWentWrong'), 'error')
    }
  }

  redirectToLandingPage = () => {
    this.props.setglobalLoaderStatus(false)
    browserHistory.push({
      pathname:
        _.get(this, 'props.store.availableBrandsList', [{}]).length === 1 ? (this.props.user.isAnGuestUser ? '/login' : '/') : '/store',
      query: {
        [this.props.location.query.region ? 'region' : '']: this.props.location.query.region,
      },
    })
  }

  // to convert value from (0....9) to (00, 01, ..., 09)
  deviceOverviewCounts = (val) => {
    if (val && val.toString().length < 2) {
      return '0' + val.toString()
    } else {
      return (val && val.toString()) || '00'
    }
  }

  loadCategoryList = () => {
    let categorydata = this.props.user.appConfigDetails.Products.map((data) => {
      return { text: data.ProductSubCategory, CategoryId: data.ProductSubCategoryID }
    })
    this.setState(
      {
        productListBoxData: {
          ...this.state.productListBoxData,
          categoryList: categorydata,
        },
      },
      () => {
        if (categorydata.length === 1) {
          return this.handleCategoryListSelection(this.state.productListBoxData.categoryList)
        }
      }
    )
  }

  handleCategoryListSelection = (val) => {
    if (val.length > 0) {
      var dummyobj = { ...this.state.newDeviceListBoxDataValues }
      dummyobj.categoryValue = val
      // set list of brand for selected category
      let branddata
      this.props.user.appConfigDetails.Products.map((data) => {
        if (data.ProductSubCategoryID === val[0].CategoryId) {
          branddata = data.Brand.map((brand) => {
            return { text: brand.BrandName, BrandId: brand.BrandID }
          })
        }
      })
      dummyobj.brandEnabled = true
      this.setState(
        {
          productListBoxData: {
            categoryList: [...this.state.productListBoxData.categoryList],
            brandList: branddata,
            productList: [],
          },
          newDeviceListBoxDataValues: {
            // if not more than 1 set brand name as pre-selected
            ...dummyobj,
            brandValue: [],
          },
        },
        () => {
          if (branddata && branddata.length === 1) {
            this.handleBrandListSelection(branddata)
          }
        }
      )
    } else {
      this.setState({
        newDeviceListBoxDataValues: {
          ...this.state.newDeviceListBoxDataValues,
          categoryValue: [],
          brandValue: [],
          brandEnabled: false,
          productEnabled: false,
        },
        productListBoxData: {
          categoryList: [...this.state.productListBoxData.categoryList],
          brandList: [],
          productList: [],
        },
      })
    }
  }

  handleBrandListSelection = (val) => {
    if (val.length > 0) {
      var brandListObj = { ...this.state.newDeviceListBoxDataValues }
      brandListObj.brandValue = val
      this.props
        .getProductsList({
          categoryID: this.state.newDeviceListBoxDataValues.categoryValue[0].CategoryId,
          brandID: val[0].BrandId,
        })
        .then((brandList) => {
          let productData = brandList.map((data) => {
            return { text: data.ProductName, ProductId: data.ProductID }
          })
          brandListObj.productEnabled = true
          this.setState({
            productListBoxData: {
              categoryList: [...this.state.productListBoxData.categoryList],
              brandList: [...this.state.productListBoxData.brandList],
              productList: productData,
            },
            newDeviceListBoxDataValues: brandListObj,
          })
        })
    } else {
      this.setState({
        newDeviceListBoxDataValues: {
          ...this.state.newDeviceListBoxDataValues,
          brandValue: undefined,
          productValue: [],
          productEnabled: false,
        },
        productListBoxData: {
          categoryList: [...this.state.productListBoxData.categoryList],
          brandList: [...this.state.productListBoxData.brandList],
          productList: [],
        },
      })
    }
  }

  handleProductListSelection = (val) => {
    if (val.length > 0) {
      var productListObj = { ...this.state.newDeviceListBoxDataValues }
      productListObj.productValue = val
      this.setState({
        newDeviceListBoxDataValues: productListObj,
      })
    } else {
      this.setState({
        newDeviceListBoxDataValues: {
          ...this.state.newDeviceListBoxDataValues,
          productValue: undefined,
        },
      })
    }
  }

  addNewDeviceHome = () => {
    this.props.setglobalLoaderStatus(true)
    let ProductSubCategoryID = this.state.newDeviceListBoxDataValues.categoryValue[0].CategoryId
    let ConsumerID = this.props.user.consumerDetails.data.consumerID
    let { ProductID, BrandID } = ''
    if (_.get(this, 'state.newDeviceListBoxDataValues.brandValue', []).length) {
      BrandID = this.state.newDeviceListBoxDataValues.brandValue[0].BrandId
    }
    if (_.get(this, 'state.newDeviceListBoxDataValues.productValue', []).length) {
      ProductID = this.state.newDeviceListBoxDataValues.productValue[0].ProductId
    }
    if (ProductSubCategoryID && ConsumerID && BrandID && ProductID) {
      let data = {
        ConsumerID,
        ProductSubCategoryID,
        BrandID,
        ProductID,
        show: false,
        IsUnderWarranty: null,
        IsActive: 1,
        validationError: false,
        msg: '',
        showP: false,
        TagName: '',
        ModelNo: '',
        WarrantyTill: null,
        DownloadedDeviceUniqueKey: '',
        ProductUniqueID: null,
        AlternateUniqueKey: null,
      }
      let addedDeviceCPid
      this.props
        .addDeviceAsync(data)
        .then((response) => {
          this.props.setglobalLoaderStatus(false)
          addedDeviceCPid = response.ConsumerProductID ? window.btoa(response.ConsumerProductID) : ''
          browserHistory.push('/devices?cpid=' + addedDeviceCPid)
        })
        .catch(() => {
          this.props.setglobalLoaderStatus(false)
        })
    }
  }

  handleStoreItemSelection = async (e, bDontShowLoader) => {
    let brand = e
    if (e.currentTarget) {
      // via handler
      brand = e.currentTarget.id.replace('Brand-', '')
    }
    if (!_.get(this, 'props.store.availableBrandsList', false)) {
      if (!bDontShowLoader) this.props.setglobalLoaderStatus(true)
      try {
        await this.props.fetchEntityPlanAsync({
          EntityType: 'Brand',
          skipPlanGrouping: false,
          GroupingType: 'PlanType',
          PlanFilter: { PlanType: ['Secure', 'Protect'] },
        })
        if (!bDontShowLoader) this.props.setglobalLoaderStatus(false)
      } catch (err) {
        if (!bDontShowLoader) this.props.setglobalLoaderStatus(false)
        console.log(err)
        this.props.setToaster(getTranslationText('error', 'somethingWentWrong'), 'error')
      }
    }
    let filteredBrand = this.props.store.availableBrandsList.find((data) => data.BrandName === brand)
    if (filteredBrand && filteredBrand.BrandName) {
      this.props.setSelectedBrand(filteredBrand)
      if (!bDontShowLoader) this.props.setglobalLoaderStatus(true)
      this.setState(
        {
          isNewPlanSalesFlow: brand.toUpperCase() !== 'APPLE' && _.get(this, 'props.user.appConfigDetails.nonOtpPlanSale', false),
        },
        () => {
          if (this.state.isNewPlanSalesFlow && !_.get(this, 'props.user.consumerDetails.data.consumerID', false)) {
            this.props.getStateListAsync().then(() => {
              this.setState({
                locations: this.props.user.locations,
                isCreate: !(this.props.user.locations && this.props.user.locations.length),
              })
            })
          }
        }
      )
      this.props
        .aysncFetchBrandID({
          BrandName: brand,
        })
        .then(() => {
          if (this.props.store.selectedBrand.BrandName) {
            this.clearExitingPlanDetails()
            this.loadPlanLandingDetails()
          } else {
            if (!bDontShowLoader) this.props.setglobalLoaderStatus(false)
            browserHistory.push({
              pathname: this.props.user.isAnGuestUser ? 'pagenotfound' : '/',
            })
          }
        })
        .catch(() => {
          this.redirectToLandingPage()
        })
        .finally(() => {
          const { utm_source: utmSource, utm_medium: utmMedium, utm_campaign: utmCampaign } = this.props.location.query
          // ---------------------- Clever Tap | user Plan Viewed detail -----------------------
          const LoggedIn = _.get(this, 'props.user.consumerDetails.data.consumerID', false)
          const { emailID, mobileNo } = _.get(this, 'props.user.userDetails', false)
          const clevertapUserProfile = LoggedIn ? _.get(this, 'props.user', {}) : {}

          pushToAnalytics(
            'Plan Sales - Store Page',
            {
              Logged_In: LoggedIn ? 'Yes' : 'No',
              Brand: _.get(this, 'props.store.selectedBrand.BrandName', undefined),
              'Email ID': emailID || undefined,
              'Mobile Number': mobileNo || undefined,
              ConsumerID: _.get(this, 'props.user.consumerDetails.data.consumerID', undefined),
              'Country Code': window.CountryCode,
              Source: 'Web',
              'Referral Source': utmSource || undefined,
              'Referral Medium': utmMedium || undefined,
              'Referral Campaign': utmCampaign || undefined,
              eureka: !!_.get(this, 'props.store.imeiFromToken', false),
            },
            clevertapUserProfile
          )
          // ---------------------- Clever Tap | user Plan Viewed detail ------------------------
        })
    } else {
      browserHistory.push({
        pathname: '/store',
        query: {
          ...this.props.location.query,
          [this.props.location.query.region ? 'region' : '']: this.props.location.query.region,
        },
      })
    }
  }

  loadPlanLandingDetails = () => {
    if (Object.keys(_.get(this, 'props.store.selectedBrand', {})).length) {
      this.props
        .fetchStoreBannersAsync({
          BrandID: this.props.store.selectedBrand && this.props.store.selectedBrand.BrandID,
        })
        .then(() => {
          // this.props.setglobalLoaderStatus(false)
          this.loadBrandRelatedPlans()
        })
        .catch(() => {
          this.redirectToLandingPage()
        })
    }
  }

  loadBrandRelatedPlans = () => {
    let brandName = this.props.store.selectedBrand.BrandName
    this.props
      .getEligiblePlans(
        {
          BrandName: brandName,
          ConsumerID: _.get(this, 'props.user.userDetails.consumerID', undefined),
          EmailID: _.get(this, 'props.user.userDetails.emailID', undefined),
          MobileNo: _.get(this, 'props.user.userDetails.mobileNo', undefined),
        },
        _.get(this, 'props.user.userDetails.consumerID', false) !== false
      )
      .then(() => {
        this.props.setglobalLoaderStatus(false)
        if (this.props.store.brandPlansList.length) {
          this.props.location.pathname !== '/store/view' &&
            browserHistory.push({
              pathname: '/store/view',
              query: {
                ...this.props.location.query,
                id: brandName,
                [this.props.location.query.region ? 'region' : '']: this.props.location.query.region,
              },
            })
        } else {
          this.redirectToLandingPage()
        }
      })
      .catch(() => {
        this.redirectToLandingPage()
      })
  }

  isAddressRequiredForPlanPurchase = () => {
    let req_fields = _.get(this, 'props.store.selectedPlanDetails.PlanConfig.RequiredFieldsForPurchase', [])
    return !!req_fields.find((e) => e.FieldName === 'ConsumerAddress' && e.Optional === false && !_.get(this, 'props.store.taxAddress'))
  }

  validateRequiredPaymentParams = async () => {
    let discountsAll = _.get(this, 'props.store.purchaseEligibilityInfo.PlanDiscounts.PlanDiscountOffers', [])
    if (!this.isAddressRequiredForPlanPurchase() && this.state.selectedState.StateCode)
      discountsAll = _.get(this, 'props.store.availablePaymentOptions[0].PlanDiscounts.PlanDiscountOffers', [])
    if (discountsAll.length > 0) {
      await this.checkIfPaymentRequiredHandling()
    }
    if (this.props.store.requiredPaymentParams.length === 1 && this.props.store.requiredPaymentParams[0].RequiredParameter === 'Zipcode') {
      this.props.validateZipcodeAsync(this.state.Zipcode).then(() => {
        this.props.saveZipcodeOrState(this.state.Zipcode, 'Zipcode')
        if (_.get(this, 'props.store.checkIfPaymentRequired')) {
          this.handleCheckoutNavigation()
        } else if (!this.state.isPaymentModeSelected) {
          this.setState({ showPaymentMode: true, showAddress: this.isAddressRequiredForPlanPurchase() })
        }
      })
      this.props
        .validateZipcodeAsync(this.state.Zipcode)
        .then(() => {
          if (!this.state.isPaymentModeSelected && _.get(this, 'props.store.validatedZipcode', false)) {
            this.props.saveZipcodeOrState(this.state.Zipcode, 'Zipcode')
            this.checkForDiscountAndRedirect()
          }
        })
        .catch((e) => {
          console.log(e)
        })
        .finally(() => {
          this.setState({ isValidateLoading: false })
        })
    } else {
      if (
        _.get(this, 'props.store.checkIfPaymentRequired') ||
        (_.get(this, 'props.store.availablePaymentOptions', []).length === 1 && discountsAll.length <= 0)
      ) {
        this.handleCheckoutNavigation()
      } else if (!this.state.isPaymentModeSelected) {
        this.setState({ showPaymentMode: true, showAddress: this.isAddressRequiredForPlanPurchase() })
      }
    }
  }

  handleCheckoutNavigation = () => {
    const PaymentOnActivation = _.get(this, 'props.store.checkIfPaymentRequired', false)
    if (
      _.get(this, 'props.store.requiredPaymentParams.length') === 1 &&
      _.get(this, 'props.store.requiredPaymentParams[0].RequiredParameter ') === 'Zipcode'
    ) {
      this.setState({
        enableBuyNow: false,
        isValidateLoading: true,
        eligibilityLoader: true,
      })
      // this.props
      //   .validateZipcodeAsync(this.state.Zipcode)
      //   .then(() => {
      if (PaymentOnActivation) {
        // ************** API calling purchase PlanNoPaymentAsync ***********
        this.purchasePlanWithoutPayment()
        return
      } else if (this.props.store.validatedZipcode) {
        // this.postHandleDetails()
        // this.props.saveZipcodeOrState(this.state.Zipcode, 'Zipcode')
        this.initiatePlanPayment()
        return
      }
      this.setState({
        enableBuyNow: true,
        isValidateLoading: false,
        eligibilityLoader: false,
      })
      // })
      // .catch(() => {
      //   this.setState({
      //     eligibilityLoader: false,
      //   })
      // })
    } else {
      if (PaymentOnActivation) {
        // ************** API calling purchase PlanNoPaymentAsync ***********
        this.purchasePlanWithoutPayment()
      } else {
        if (this.state.selectedState.StateCode) this.props.saveZipcodeOrState(this.state.selectedState, 'selectedState')
        this.initiatePlanPayment()
      }
    }
  }

  checkForDiscountAndRedirect = () => {
    this.props.setglobalLoaderStatus(true)
    this.props
      .fetchDiscountDetailsAsync({
        PlanArray: [this.props.store.purchaseEligibilityInfo.PlanPrice],
        EmailID: this.props.user.userDetails.emailID,
        ConsumerProductIDs: [this.props.store.planConsumerProduct.ConsumerProductID],
        Zipcode: _.get(this, 'props.store.Zipcode'),
      })
      .then(() => {
        if (parseInt(_.get(this, 'props.store.discountDetails.discount', 0)) > 0) {
          this.setState({ showDiscountDetails: true })
          console.log('Discount is there')
        } else {
          console.log('Discount is not there')
          this.redirectToPaymentOptionSelection()
        }
      })
      .finally(() => {
        this.props.setglobalLoaderStatus(false)
      })
  }

  redirectToPaymentOptionSelection = () => {
    let availablePaymentOptions = _.get(this, 'props.store.availablePaymentOptions', [])
    if (availablePaymentOptions.length > 1) {
      this.props.setSelectedPaymentOption(null)
      this.setState({ showPaymentMode: true, showDiscountDetails: false })
    } else {
      let selectedPaymentOption = availablePaymentOptions[0]
      this.props.setSelectedPaymentOption({
        ...selectedPaymentOption,
        appliedDiscountOffer:
          selectedPaymentOption &&
          selectedPaymentOption.maxDiscountOffer &&
          selectedPaymentOption.maxDiscountOffer.DiscountCategory != 'PromoCode'
            ? selectedPaymentOption.maxDiscountOffer
            : {},
      })
      console.log('show: ', this.props.showPaymentMode)
      this.handleCheckoutNavigation()
    }
  }

  // generic handle to toggle pillbtn selection
  togglePillBtnSelection = (data, id) => {
    return data.map((item, index) => {
      if (index === id) {
        return {
          ...item,
          isSelected: true,
        }
      } else {
        return {
          ...item,
          isSelected: false,
        }
      }
    })
  }

  handlePlanOptionsSelection = (e) => {
    if (e) {
      this.resetPlanEligibilityChecks()
      let id = parseInt(e.currentTarget.id.replace('sub-plans-', ''))
      let dummyobj = this.state.selectedItems
      dummyobj.planPrice = this.togglePillBtnSelection(dummyobj.planPrice, id)
      // dummyobj.planPrice = dummyobj.planPrice.find(data => (data.isSelected)).planPrice
      this.setState(
        {
          selectedItems: dummyobj,
        },
        () => {
          this.props.setSelectedPlan(this.state.selectedItems)
        }
      )
    }
  }

  handleStateSelection = (stateObject) => {
    this.setState({ selectedState: stateObject })
  }

  resetPlanEligibilityChecks = () => {
    this.setState({
      eligiblityCheckValue: '',
      showEligibilityStatus: false,
      eligibilityStatus: false,
      secondaryDOPValue: '',
      customPlanImeiDisabled: true,
      customPlanDOPStatus: true,
      customPlanPriceDisabled: true,
      showCustomPlanEligibilityStatus: false,
      isCustomPlanEligible: false,
      CustomPlanIMEIValue: '',
      CustomPlanDevicePrice: '',
      customPlanIMEIError: '',
      customPlanDOPValue: '',
      showProductPriceInput: '',
      showDOP: false,
      showCustomQuestions: false,
      productPurchaseCost: '',
      eligibilityMessage: '',
    })
  }

  getValidationTypeLength = (brand, type) => {
    let allowedValues = _.get(brand, 'WarrantyCheckDetails.AllowedValues') || []
    let ValidationLength = defaultValidationFilter.find((obj) => obj.Type.toUpperCase() === type.toUpperCase()).MinLength
    if (allowedValues.length) {
      ValidationLength = allowedValues.find((data) => data.Type.toUpperCase() === type.toUpperCase())
      ValidationLength = ValidationLength && ValidationLength.Length
    }
    return ValidationLength
  }

  handleKeyPressForEligibilityCheck = (e) => {
    if (e.which === 13 || e.keyCode === 13) {
      let fieldTypeLength
      let brandDetails = this.getBrandDetails(this.props.store.selectedPlanDetails)
      let fieldType = e.target.id
      switch (fieldType) {
        case 'imeiorserial':
          {
            let type = /[A-z]/.test(e.target.value) ? 'SerialNumber' : 'IMEI'
            fieldTypeLength = this.getValidationTypeLength(brandDetails, type)
          }
          break
        case 'serialno':
          fieldTypeLength = this.getValidationTypeLength(brandDetails, 'SerialNumber')
          break
        default:
          fieldTypeLength = this.getValidationTypeLength(brandDetails, 'IMEI')
      }
      e.target.value && e.target.value.length === fieldTypeLength && e.allowAutoCheck && this.checkPlanEligibility()
    }
  }

  eligibilityValueOnChange = (e, allowAutoCheck = true) => {
    if (_.get(this, 'props.user.storeDocs', []).length) {
      this.props.setStoreDocs({ unset: true })
    }
    if (e) {
      let value = e.target.value.trim().toUpperCase()
      let eventID = e.target.id
      let brand = this.getBrandDetails(this.props.store.selectedPlanDetails)
      let customEvent = {
        target: {
          id:
            eventID === 'serialno'
              ? 'DownloadedDeviceUniqueKey'
              : eventID === 'imeionly'
              ? 'ProductUniqueID'
              : regexCheck('validation', 'hasAlphachars', value)
              ? 'DownloadedDeviceUniqueKey'
              : 'ProductUniqueID',
          value: value,
        },
      }
      let verdict = validateInputChangeForDevice(customEvent, { BrandID: brand && brand.BrandID }, brand.WarrantyCheckDetails)
      if (verdict.isValid) {
        this.setState({
          hasPriceAvailability: false,
          showEligibilityStatus: false,
          eligibilityStatus: false,
          secondaryDOPValue: '',
          eligibilityMessage: '',
        })
        !_.isEmpty(_.get(this, 'props.store.purchaseEligibilityInfo')) && this.props.setPurchaseEligibilityInfo(undefined)
        !_.isEmpty(_.get(this, 'props.store.eligibleDeviceInfo')) && this.props.seteligibleDeviceInfo(undefined)
        this.setState(
          {
            eligiblityCheckValue: value,
          },
          () => {
            this.handleKeyPressForEligibilityCheck({
              target: {
                value: this.state.eligiblityCheckValue,
                id: eventID,
              },
              allowAutoCheck,
              which: 13,
            })
          }
        )
      } else {
        verdict.msg && this.props.setToaster(verdict.msg, 'error')
      }
    }
  }

  checkPlanPurchaseEligibility = () => {
    this.setState(
      {
        eligibilityLoader: true,
        eligibilityStatus: false,
        showEligibilityStatus: false,
      },
      () => {
        let selectedPlan = this.props.store.selectedPlanDetails
        let brand = this.getBrandDetails(selectedPlan)
        const MobVal = _.get(this, 'props.user.consumerDetails.MobileNo')
        const { BrandName, ValidTillMonths } = selectedPlan.PlanConfig || {}
        const { PlanPrice } = selectedPlan.planPrice[0] || {}
        const LoggedIn = _.get(this, 'props.user.consumerDetails.data.consumerID', false)
        const { emailID } = _.get(this, 'props.user.userDetails', false)
        const clevertTapEligibility = {
          'Logged In': LoggedIn ? 'Yes' : 'No',
          'Email ID': emailID || undefined,
          'Mobile Number': MobVal,
          'Plan Name': selectedPlan.PlanName,
          Brand: BrandName,
          'Policy Name': selectedPlan.PlanName,
          'Plan Validity': ValidTillMonths,
          'Plan Price': PlanPrice,
          Source: 'Web',
          'IMEI or Serial': this.state.eligiblityCheckValue,
          ConsumerProductID: '',
          Model: '',
          'Date of Purchase': this.state.secondaryDOPValue ? moment(this.state.secondaryDOPValue).format('YYYY-MM-DD') : '',
        }
        let eligibilityResponse = {}
        this.props
          .checkPurchaseEligibility({
            warrantyCheckRequired: false,
            getStatusMessage: true,
            StorageCapacity: null,
            DateOfPurchase: this.state.secondaryDOPValue ? moment(this.state.secondaryDOPValue).format('YYYY-MM-DD') : '',
            PlanID: this.state.selectedItems.PlanID,
            BrandID: brand.BrandID,
            imeiOrSerial: this.state.eligiblityCheckValue,
            ProductPurchaseCost: this.state.productPurchaseCost,
          })
          .then((response) => {
            this.setState({
              showEligibilityStatus: !response.data.IsPurchasedAllowed,
              eligibilityStatus: response.data.IsPurchasedAllowed,
              eligibilityLoader: false,
              eligibilityMessage: response.data.Message,
            })
            if (response.data.IsPurchasedAllowed && !this.state.showDateOfPruchase && !this.state.showProductPurchaseCost) {
              this.setState({
                showDateOfPruchase: true,
                showProductPurchaseCost: true,
                disableIMEI: true,
                disableDeviceModel: true,
              })
              this.props.btnStatusHandler(this.props)
              return
            }
            eligibilityResponse = {
              status: response.data.IsPurchasedAllowed,
              msg: response.data.Message,
            }
          })
          .catch(() => {
            this.setState({ eligibilityLoader: false })
            eligibilityResponse = {
              status: false,
              msg: 'Something went wrong',
            }
          })
          .finally(() => {
            // ------------ Clever Tap | Eligibility Check For DOP ------------
            const clevertapUserProfile = LoggedIn ? _.get(this, 'props.user', {}) : {}

            pushToAnalytics(
              'Plan Sales - Eligibility Check',
              {
                ...clevertTapEligibility,
                Eligible: eligibilityResponse.status,
                eureka: !!_.get(this, 'props.store.imeiFromToken', false),
                Error: eligibilityResponse.status ? undefined : eligibilityResponse.msg,
                ProductName: _.get(this, 'props.store.purchaseEligibilityInfo.ProductName', ''),
              },
              clevertapUserProfile
            )
            // ------------ Clever Tap | Eligibility Check For DOP ------------
            if (eligibilityResponse.status) {
              let selectedPlanProducts = _.get(this.props, 'store.planProducts', []).filter((prod) => {
                if (prod.ProductName === this.state.selectedDeviceModel) return prod
              })
              const identifiersArray = [
                { Type: 'app', Value: brandConfig.ClientName },
                { Type: 'CountryID', Value: window.CountryID },
              ]
              if (this.state.productPurchaseCost) {
                identifiersArray.push({ Type: 'ProductPurchaseCost', Value: this.state.productPurchaseCost })
              }
              if (selectedPlanProducts.length) {
                identifiersArray.push(
                  { Type: 'ProductID', Value: selectedPlanProducts[0].ProductID },
                  { Type: 'BrandID', Value: this.getBrandDetails(this.props.store.selectedPlanDetails).BrandID },
                  {
                    Type: 'ProductSubCategoryID',
                    Value: selectedPlanProducts[0].ProductSubCategoryID,
                  }
                )
              }
              const requestObject = {
                PlanArray: [this.props.store.selectedPlanDetails.PlanID],
                IdentifierArray: identifiersArray,
              }
              this.props
                .getPlanPriceByIdentifiers(requestObject)
                .then((response) => {
                  if (response.data && response.data.length && response.data[0].PlanPrice) {
                    this.setState({
                      selectedItems: this.createSelectedItem(this.props.store.selectedPlanDetails, response.data[0].PlanPrice),
                    })
                    this.buySelectedPlan({})
                  } else {
                    this.setState({
                      showEligibilityStatus: true,
                      eligibilityStatus: false,
                      eligibilityMessage: getTranslationText('store', 'noPlansAvailable'),
                    })
                  }
                })
                .catch(() => {
                  this.setState({ eligibilityLoader: false })
                })
            }
          })
      }
    )
  }

  handleDOBOnChange = (date) => {
    this.setState({
      dateOfBirth: date,
      eligibilityMessage: '',
      hasPriceAvailability: false,
      showEligibilityStatus: false,
      eligibilityStatus: false,
    })
  }

  pickPlanPrice = (sourceObj) => {
    // plan price priority 1. TotalPrice, 2. ServiceBasePrice + InsuranceBasePrice, 3. existing logic i.e.,  PlanPrice
    if (!sourceObj) return
    if (sourceObj.TotalPrice > 0) return sourceObj.TotalPrice
    if (
      (sourceObj.ServiceBasePrice && sourceObj.ServiceBasePrice > 0) ||
      (sourceObj.InsuranceBasePrice && sourceObj.InsuranceBasePrice > 0)
    )
      return (sourceObj.ServiceBasePrice || 0) + (sourceObj.InsuranceBasePrice || 0)
    return sourceObj.PlanPrice
  }

  pickEMIPlanPrice = (sourceObj) => {
    // plan price priority 1. TotalEMIAmount, 2. EMIServiceBasePrice + EMIInsuranceBasePrice, 3. existing logic i.e.,  EMIAmount
    if (!sourceObj) return
    if (sourceObj.TotalEMIAmount > 0) return sourceObj.TotalEMIAmount
    if (
      (sourceObj.EMIServiceBasePrice && sourceObj.EMIServiceBasePrice > 0) ||
      (sourceObj.EMIInsuranceBasePrice && sourceObj.EMIInsuranceBasePrice > 0)
    )
      return (sourceObj.EMIServiceBasePrice || 0) + (sourceObj.EMIInsuranceBasePrice || 0)
    return sourceObj.EMIAmount
  }

  // Util function to get discounts by payment type, EMI or UPFRONT
  getDiscountsByType = (sourceObj, paymentType) => {
    if (!(sourceObj && sourceObj.PlanDiscountOffers && sourceObj.PlanDiscountOffers.length > 0)) {
      return []
    }
    return sourceObj.PlanDiscountOffers.filter((obj) => {
      return obj.PaymentType === paymentType
    })
  }

  checkPlanEligibility = (MobileNo = undefined, dob = this.state.dateOfBirth, callback = undefined, failureCb = undefined) => {
    if (!dob?.length) {
      dob = this.state.dateOfBirth
    }
    if (
      !this.state.eligiblityCheckValue &&
      this.state.isNewPlanSalesFlow &&
      (_.get(this, 'props.store.purchaseEligibilityInfo.imeiOrSerial', '') || _.get(this, 'props.store.imeiForFilter', ''))
    )
      this.setState({
        eligiblityCheckValue: _.get(this, 'props.store.purchaseEligibilityInfo.imeiOrSerial', _.get(this, 'props.store.imeiForFilter', '')),
      })
    if (this.state.eligiblityCheckValue) {
      if (!this.state.isNewPlanSalesFlow) this.props.setglobalLoaderStatus(true)
      this.setState(
        {
          // eligibilityLoader: true,
          eligibilityStatus: false,
          showEligibilityStatus: false,
          eligibilityMessage: '',
          showIMEILoader: this.state.isNewPlanSalesFlow,
        },
        () => {
          let selectedPlan = this.props.store.selectedPlanDetails
          let brand = this.getBrandDetails(selectedPlan)
          let callType = 'primaryCheck' // primary check with imei / dop
          let eligibilityFieldType = this.getEligibilityFieldType()

          const MobVal = MobileNo || _.get(this, 'props.user.consumerDetails.MobileNo')

          const { BrandName, ValidTillMonths } = selectedPlan.PlanConfig || {}
          const { PlanPrice } = selectedPlan.planPrice[0] || {}
          const LoggedIn = _.get(this, 'props.user.consumerDetails.data.consumerID', false)
          const { emailID } = _.get(this, 'props.user.userDetails', false)
          const clevertTapEligibility = {
            'Logged In': LoggedIn ? 'Yes' : 'No',
            'Email ID': emailID || undefined,
            'Mobile Number': MobVal,
            ConsumerID: _.get(this, 'props.user.consumerDetails.data.consumerID', ''),
            'Plan Name': selectedPlan.PlanName,
            Brand: BrandName,
            'Policy Name': selectedPlan.PlanName,
            'Plan Validity': ValidTillMonths,
            'Plan Price': PlanPrice,
            Source: 'Web',
            'IMEI or Serial': eligibilityFieldType !== 'DOP' ? this.state.eligiblityCheckValue : undefined,
            Model: '',
            'Date of Purchase': eligibilityFieldType === 'DOP' ? moment(this.state.eligiblityCheckValue).format('YYYY-MM-DD') : undefined,
          }

          let eligibilityResponse = {}

          if (this.state.eligiblityCheckValue && eligibilityFieldType === 'DOP') {
            this.props
              .getPlanEligibilityStatus(
                {
                  getStatusMessage: true,
                  DateOfPurchase: moment(this.state.eligiblityCheckValue).format('YYYY-MM-DD'),
                  PlanID: this.state.selectedItems.PlanID,
                  BrandID: brand.BrandID,
                  imeiOrSerial: this.state.eligiblityCheckValue,
                },
                callType
              )
              .then((response) => {
                this.setState({
                  showEligibilityStatus: true,
                  eligibilityStatus: response.data.IsPurchasedAllowed,
                  showIMEILoader: false,
                  // eligibilityLoader: false,
                })
                this.props.setglobalLoaderStatus(false)
                eligibilityResponse = {
                  status: response.data.IsPurchasedAllowed,
                  msg: response.data.message,
                }
              })
              .catch(() => {
                this.setState({ eligibilityLoader: false })
                eligibilityResponse = {
                  status: false,
                  msg: 'Something went wrong',
                }
              })
              .finally(() => {
                // ------------ Clever Tap | Eligibility Check For DOP ------------
                const clevertapUserProfile = LoggedIn ? _.get(this, 'props.user', {}) : {}

                pushToAnalytics(
                  'Plan Sales - Eligibility Check',
                  {
                    ...clevertTapEligibility,
                    Eligible: eligibilityResponse.status,
                    eureka: !!_.get(this, 'props.store.imeiFromToken', false),
                    Error: eligibilityResponse.status ? undefined : eligibilityResponse.msg,
                    ProductName: _.get(this, 'props.store.purchaseEligibilityInfo.ProductName', ''),
                  },
                  clevertapUserProfile
                )
                // ------------ Clever Tap | Eligibility Check For DOP ------------
              })
          } else {
            // imei/serial related validation
            let params = {
              getStatusMessage: true,
              BrandID: brand.BrandID,
              PlanID: this.state.selectedItems.PlanID,
              imeiOrSerial: this.state.eligiblityCheckValue,
              MobileNo: MobVal,
              ConsumerID: _.get(this, 'props.user.consumerDetails.data.consumerID'),
              DateOfPurchase: this.state.secondaryDOPValue ? moment(this.state.secondaryDOPValue).format('YYYY-MM-DD') : undefined,
              EmailID: _.get(this, 'props.user.userDetails.emailID', undefined),
            }
            if (this.state.showDOB && dob) {
              params.BirthDate = moment(dob).format('YYYY-MM-DD')
            }
            let apiCall = this.props.getPlanEligibilityStatus
            if (this.state.isNewPlanSalesFlow && !_.get(this, 'props.user.consumerDetails.data.consumerID', false)) {
              apiCall = this.props.getPlanEligibilityStatusForNonLoginFlow
              const address = _.get(this, 'props.store.consumerAddress', undefined)
              const selectedState = _.get(this, 'props.store.selectedState', undefined)
              params.firstName = _.get(this, 'props.store.userData.name', undefined)
              params.mobileNo = _.get(this, 'props.store.userData.mobile', undefined)
              params.emailID = _.get(this, 'props.store.userData.email', undefined)
              params.ConsumerAddress = Object.keys(address).length > 0 ? address : undefined
              params.StateCode = selectedState.StateCode || undefined
              const dob = _.get(this, 'props.store.userData.BirthDate', undefined)
              if (dob) params.BirthDate = moment(dob).format('YYYY-MM-DD')
            }
            let alreadyVerified = !!_.get(this, 'props.store.imeiForFilter', false)
            if (!alreadyVerified) alreadyVerified = _.get(this, 'props.store.purchaseEligibilityInfo.IsPurchasedAllowed', false)
            apiCall(params, callType)
              .then((response) => {
                let msg = !response.data.IsPurchasedAllowed && response.data.Message
                if (!response.data.IsPurchasedAllowed && _.get(this, 'props.location.pathname', '').includes('signin')) {
                  this.setDOBInvalidModal(true, response.data.Message)
                  this.setState({
                    disableDOB: true,
                    dateOfBirth: _.get(this, 'props.user.userDetails.birthDate', ''),
                  })
                  browserHistory.push({
                    pathname: '/store/buy',
                    query: {
                      id: _.get(this, 'props.store.selectedPlanDetails.PlanCode'),
                    },
                  })
                  return
                }

                if (!response.data.IsPurchasedAllowed && response.data.status_message === 'INVALID_IMEI_ERROR') {
                  let fieldType =
                    eligibilityFieldType === 'SERIALNO'
                      ? 'SerialNo'
                      : /[A-z]/.test(this.state.eligiblityCheckValue)
                      ? 'IMEI/SerialNo'
                      : 'IMEI'
                  msg = `${getTranslationText('common', 'invalid')} ${fieldType}.
            ${getTranslationText('common', 'enterCorrect')} ${fieldType}`
                }

                if (!response.data.IsPurchasedAllowed && response.data.EligibilityStatusCode === 'INELIGIBLE_DOB') {
                  this.setDOBInvalidModal(true, response.data.Message)
                }

                // -------------CT ------------
                eligibilityResponse = {
                  status: response.data.IsPurchasedAllowed,
                  msg,
                }
                // -----------------------------
                if (!response.data.InputParams) {
                  this.setState({
                    showEligibilityStatus: true,
                    eligibilityStatus: response.data.IsPurchasedAllowed,
                    eligibilityMessage: response.data.Message,
                  })
                }
                if (response.data.IsPurchasedAllowed) {
                  this.props.seteligibleDeviceInfo(response.data.InputParams)
                  if (response.data.InputParams && response.data.InputParams.DateOfPurchase) {
                    clevertTapEligibility['Date of Purchase'] = response.data.InputParams.DateOfPurchase
                  }
                  if (selectedPlan.priceRange) {
                    this.props.setFetchedPlanPriceByProduct(response.data.PlanPrice)
                    // let fetchedPlanPrice = response.data.PlanPrice
                    let fetchedPlanPrice = this.pickPlanPrice(response.data.PlanPrice)
                    if (fetchedPlanPrice > 0) {
                      clevertTapEligibility['Plan Price'] = fetchedPlanPrice
                      if (!_.get(this, 'props.store.undiscountedPlanPrice')) {
                        this.props.setUndiscountedPlanPrice(fetchedPlanPrice)
                      }
                    }
                    this.setState(
                      {
                        // eligibilityLoader: false,
                        eligibilityStatus: true,
                        hasPriceAvailability: fetchedPlanPrice > 0,
                        disableDOB: !(
                          _.get(this, 'props.user.userDetails.birthDate', '') === '' ||
                          _.get(this, 'props.user.userDetails.birthDate', '') == null
                        ),
                        dateOfBirthVerified: this.state.showDOB && !!dob,
                        showIMEILoader: false,
                      },
                      () => {
                        this.props.setglobalLoaderStatus(false)
                        if (callback) callback(!this.state.dateOfBirthVerified, response)
                      }
                    )
                  } else {
                    this.setState(
                      {
                        //  eligibilityLoader: false,
                        eligibilityStatus: true,
                        hasPriceAvailability: true,
                        disableDOB: !(
                          _.get(this, 'props.user.userDetails.birthDate', '') === '' ||
                          _.get(this, 'props.user.userDetails.birthDate', '') == null
                        ),
                        dateOfBirthVerified: this.state.showDOB && !!dob,
                        showIMEILoader: false,
                      },
                      () => {
                        this.props.setglobalLoaderStatus(false)
                        if (callback) callback(!this.state.dateOfBirthVerified, response)
                      }
                    )
                  }
                } else {
                  this.setState({ eligibilityLoader: false, eligibilityStatus: false, showIMEILoader: false })
                  this.props.setglobalLoaderStatus(false)
                  if (failureCb) failureCb()
                }
              })
              .catch((err) => {
                console.log(err)
                this.props.setToaster(err.msg, 'error')
                this.setState({ eligibilityLoader: false, eligibilityStatus: false, showIMEILoader: false })
                this.props.setglobalLoaderStatus(false)
                eligibilityResponse = {
                  status: false,
                  msg: 'Something went wrong',
                }
              })
              .finally(() => {
                // ------------ Clever Tap | Eligibility Check for IMEI ------------
                if (!alreadyVerified) {
                  const clevertapUserProfile = _.get(this, 'props.user', {})
                  pushToAnalytics(
                    'Plan Sales - Eligibility Check',
                    {
                      ...clevertTapEligibility,
                      Eligible: eligibilityResponse.status,
                      eureka: !!_.get(this, 'props.store.imeiFromToken', false),
                      Error: eligibilityResponse.status ? undefined : eligibilityResponse.msg,
                      ProductName: _.get(this, 'props.store.purchaseEligibilityInfo.ProductName', ''),
                    },
                    clevertapUserProfile
                  )
                }
                // ------------ Clever Tap | Eligibility Check for IMEI ------------
              })
          }
        }
      )
    } else {
      this.setState({
        showEligibilityStatus: true,
        eligibilityStatus: 'Enter ',
        eligibilityLoader: false,
        showIMEILoader: false,
      })
      this.props.setglobalLoaderStatus(false)
    }
  }
  addPlanToaDevice = (showLoader = true) => {
    if (showLoader) this.setState({ eligibilityLoader: true })
    let data = {
      ProductUniqueID: this.state.eligiblityCheckValue,
      ConsumerID: parseInt(this.props.user.consumerDetails.data.consumerID),
    }
    let dateOfPurchase = moment(this.state.secondaryDOPValue).format('YYYY-MM-DD')
    if (dateOfPurchase) {
      data.DateOfPurchase = dateOfPurchase
    }
    if (this.state.productPurchaseCost) {
      data.ProductPurchaseCost = this.state.productPurchaseCost
    }
    let selectedPlanProducts = _.get(this.props, 'store.planProducts', []).filter((prod) => {
      if (prod.ProductName === this.state.selectedDeviceModel) return prod
    })
    if (selectedPlanProducts.length) {
      data.ProductID = selectedPlanProducts[0].ProductID
      data.ProductSubCategoryID = selectedPlanProducts[0].ProductSubCategoryID
    }
    data.BrandID = _.get(this, 'props.store.selectedPlanDetails.EntityDetails[0]', []).BrandID
    return this.props
      .addorFetchDeviceAsync({ data })
      .then((response) => {
        if (showLoader) this.setState({ eligibilityLoader: false })
        this.props.setglobalLoaderStatus(false)
        return response
      })
      .catch((err) => {
        this.setState({
          eligibilityLoader: false,
        })
        this.props.setglobalLoaderStatus(false)
        throw err
      })
  }

  addPurchasedPlanToaDevice = () => {
    this.props.setglobalLoaderStatus(true)
    let data = {
      imeiOrSerial: this.props.store.purchaseEligibilityInfo.imeiOrSerial,
      BrandID: this.getBrandDetails(this.props.store.selectedPlanDetails).BrandID,
      ConsumerID: parseInt(this.props.user.consumerDetails.data.consumerID),
    }
    let dateOfPurchase =
      (this.state.secondaryDOPValue || this.state.customPlanDOPValue) &&
      moment(this.state.secondaryDOPValue || this.state.customPlanDOPValue).format('YYYY-MM-DD')
    if (dateOfPurchase) {
      data.DateOfPurchase = dateOfPurchase
    }
    if (this.state.productPurchaseCost) {
      data.ProductPurchaseCost = this.state.productPurchaseCost
    }
    return this.props.addorFetchAsync({ data })
  }

  fetchPlanInfoForPlanID = async (PlanCode, bDontShowLoader) => {
    if (!PlanCode) {
      return this.redirectToLandingPage()
    }
    // reset old purchase details
    this.props.setPurchaseEligibilityInfo(undefined)
    this.props.seteligibleDeviceInfo(undefined)
    if (this.state.isNewPlanSalesFlow) {
      this.props.setConsumerAddress({})
      this.props.setUserData({})
      this.props.saveZipcodeOrState({}, 'selectedState')
    }
    this.setState({
      selectedItems: {},
      eligibilityLoader: false,
    })
    if (!bDontShowLoader) this.props.setglobalLoaderStatus(true)
    const FieldName = 'Brand'
    const fetchPlanParams = {
      data: {
        PlanCode,
        IncludeField: {
          FieldName,
        },
        ConsumerID: _.get(this, 'props.user.userDetails.consumerID', undefined),
        EmailID: _.get(this, 'props.user.userDetails.emailID', undefined),
      },
    }
    this.props.setUndiscountedPlanPrice(null)
    this.props
      .getSelectedPlanDetailsAsync(fetchPlanParams)
      .then(() => {
        if (!bDontShowLoader && !_.get(this, 'props.store.imeiForFilter', false)) this.props.setglobalLoaderStatus(false)
        let planInfo = this.props.store.selectedPlanDetails
        if (planInfo) {
          // reset plan eligibility info
          !_.isEmpty(_.get(this, 'props.store.purchaseEligibilityInfo')) && this.props.setPurchaseEligibilityInfo(undefined)
          this.props.setSelectedBrand(this.getBrandDetails(planInfo))
          if (!_.get(this, 'props.store.selectedPlanDetails.EntityDetails[0].WarrantyCheckDetails.ValidForEntireBrand', false)) {
            this.props.fetchPlanProductsAsync({
              BrandID: this.getBrandDetails(planInfo).BrandID,
              EntityType: 'Product',
              PlanID: this.props.store.selectedPlanDetails.PlanID,
            })
          }
          this.props
            .fetchIPIDAsync({
              EntityValue: this.props.store.selectedPlanDetails.PlanID,
              EntityType: 'PlanID',
              CountryIDArray: [this.props.user.selectedCountryData.CountryID],
              PlanDocumentTypeArray: ['CSD', 'IPID', 'INSURANCE_INTERMEDIARY'],
            })
            .then(() => {
              if (_.get(this, 'props.store.imeiForFilter', false)) {
                this.checkPlanEligibility(
                  '',
                  '',
                  (dob, response) => {
                    this.props.setglobalLoaderStatus(false)
                    if (response && response.success && response.data && response.data.IsPurchasedAllowed) {
                      if (_.get(this, 'props.user.userDetails.consumerID', false) && this.isAddressRequiredForPlanPurchase()) {
                        this.buySelectedPlan({})
                      }
                    } else {
                      this.props.setToaster(response.msg || getTranslationText('error', 'somethingWentWrong'), 'error')
                      this.props.setImeiForFilter('')
                    }
                  },
                  () => {
                    this.props.setglobalLoaderStatus(false)
                    this.props.setToaster(getTranslationText('error', 'somethingWentWrong'), 'error')
                    this.props.setImeiForFilter('')
                  }
                )
              }
            })
          this.setState(
            {
              selectedItems: this.createSelectedItem(planInfo),
              eligiblityCheckValue: '',
              productPurchaseCost: '',
              showEligibilityStatus: false,
              eligibilityStatus: undefined,
              disableIMEI: false,
              disableDeviceModel: false,
              eligibilityMessage: '',
              showDOB: _.get(this, 'props.store.selectedPlanDetails.PlanConfig.SuperGroupEligibilityCheckFields', []).includes('BirthDate'),
            },
            () => {
              if (this.props.store.selectedPlanDetails.PlanConfig.IsCustomPlan) {
                browserHistory.push({
                  pathname: '/store/customized-plan',
                  query: {
                    ...this.props.location.query,
                    id: this.props.store.selectedPlanDetails.PlanCode,
                    [this.props.location.query.region ? 'region' : '']: this.props.location.query.region,
                  },
                })
              } else {
                browserHistory.push({
                  pathname: '/store/buy',
                  query: {
                    ...this.props.location.query,
                    id: PlanCode,
                    [this.props.location.query.region ? 'region' : '']: this.props.location.query.region,
                  },
                })
              }
            }
          )
        }
      })
      .catch(() => {
        if (!bDontShowLoader) this.props.setglobalLoaderStatus(false)
        browserHistory.replace({
          pathname: '/store',
          query: {
            ...this.props.location.query,
            [this.props.location.query.region ? 'region' : '']: this.props.location.query.region,
          },
        })
      })
      .finally(() => {
        // ---------------------- Clever Tap | user Plan Viewed detail -----------------------
        const { PlanName } = _.get(this, 'props.store.selectedPlanDetails')
        const { ValidTillMonths } = _.get(this, 'props.store.selectedPlanDetails.PlanConfig')
        const { PlanPrice } = _.get(this, 'props.store.selectedPlanDetails.planPrice[0]')
        const { BrandName } = _.get(this, 'props.store.selectedPlanDetails.EntityDetails[0]')
        const { utm_source: utmSource, utm_medium: utmMedium, utm_campaign: utmCampaign } = this.props.location.query
        const LoggedIn = _.get(this, 'props.user.consumerDetails.data.consumerID', false)
        const { emailID, mobileNo } = _.get(this, 'props.user.userDetails', false)
        const clevertapUserProfile = LoggedIn ? _.get(this, 'props.user', {}) : {}

        pushToAnalytics(
          'Plan Sales - Plan Details',
          {
            'Logged In': LoggedIn ? 'Yes' : 'No',
            'Email ID': emailID || undefined,
            'Mobile Number': mobileNo || undefined,
            ConsumerID: _.get(this, 'props.user.consumerDetails.data.consumerID', ''),
            Brand: BrandName,
            'Policy Name': PlanName,
            'Plan Validity': ValidTillMonths,
            'Plan Price': PlanPrice,
            'Referral Source': utmSource || undefined,
            'Referral Medium': utmMedium || undefined,
            'Referral Campaign': utmCampaign || undefined,
            Source: 'Web',
            IMEI_checked_on_storepage: !!_.get(this, 'props.store.imeiForFilter', false),
            eureka: !!_.get(this, 'props.store.imeiFromToken', false),
            ProductName: _.get(this, 'props.store.productName', ''),
          },
          clevertapUserProfile
        )
        // ---------------------- Clever Tap | user Plan Viewed detail ------------------------
      })
  }

  viewSelectedPlan = (event) => {
    const {
      currentTarget: { id },
    } = event
    browserHistory.push({
      pathname: '/store/buy',
      query: {
        ...this.props.location.query,
        id,
        [this.props.location.query.region ? 'region' : '']: this.props.location.query.region,
      },
    })
  }

  clearExitingPlanDetails = () => {
    this.setState({
      selectedItems: {},
      eligiblityCheckValue: '',
      productPurchaseCost: '',
      showEligibilityStatus: false,
      eligibilityStatus: undefined,
    })
    this.props.clearExistingPlansRelatedInfo()
  }

  checkIfPaymentRequiredHandling = async (redirectionQry, checkAddress = true) => {
    if (
      (_.get(this, 'props.store.purchaseEligibilityInfo.PlanDiscounts.PlanDiscountOffers', []).length > 0 ||
        this.state.isNewPlanSalesFlow) &&
      checkAddress &&
      (this.isAddressRequiredForPlanPurchase()
        ? !(Object.keys(_.get(this, 'props.store.consumerAddress', {})).length > 0)
        : !this.state.selectedState.StateCode)
    ) {
      redirectionQry && browserHistory.push(redirectionQry)
      this.props.setglobalLoaderStatus(false)
      return
    }
    // ***************** Check if Payment required API callig *******************
    this.props.setglobalLoaderStatus(true)
    if (this.state.selectedState.StateCode) this.props.saveZipcodeOrState(this.state.selectedState, `selectedState`)
    const EntityDetails = _.get(this, 'props.store.selectedPlanDetails.EntityDetails[0]', false)
    let ConsumerProductID = _.get(this, 'props.store.planConsumerProduct.ConsumerProductID', undefined)
    let requestObject = {
      PlanID: EntityDetails.PlanID || undefined,
      BrandID: EntityDetails.BrandID || _.get(this, 'props.store.purchaseEligibilityInfo.PlanDiscounts.ProductObj.BrandID', undefined),
      ConsumerProductID: ConsumerProductID,
      PlanPriceID: _.get(this, 'props.store.planPriceOnProductBasis.PlanPriceID'),
      ExternalPlanCode: _.get(this, 'props.store.purchaseEligibilityInfo.ExternalPlanCode', 'AUTSTG20211021000596'),
      StateCode: this.state.selectedState.StateCode || undefined,
      ProductID: _.get(this, 'props.store.purchaseEligibilityInfo.PlanDiscounts.ProductObj.ProductID', undefined),
      ProductSubCategoryID: _.get(this, 'props.store.purchaseEligibilityInfo.PlanDiscounts.ProductObj.ProductSubCategoryID', undefined),
      EmailID: _.get(this, 'props.user.userDetails.emailID', undefined),
      ConsumerID: _.get(this, 'props.user.consumerDetails.data.consumerID', undefined),
    }
    if (this.state.isNewPlanSalesFlow && !_.get(this, 'props.user.userDetails.consumerID', false)) {
      requestObject.EmailID = _.get(this, 'props.store.userData.email', undefined)
      requestObject.MobileNo = _.get(this, 'props.store.userData.mobile', undefined)
      requestObject.imeiOrSerial = _.get(this, 'props.store.purchaseEligibilityInfo.imeiOrSerial', undefined)
      requestObject.CountryCode = _.get(this, 'props.user.selectedCountryData.CountryCode', undefined)
      requestObject.app = BrandSpecificConfig().header
    }
    await this.props
      .checkIfPaymentRequiredAsync(requestObject)
      .then(() => {
        let availablePaymentOptions = _.get(this, 'props.store.availablePaymentOptions', [])
        if (availablePaymentOptions.length == 1) {
          let selectedPaymentOption = availablePaymentOptions[0]
          this.props.setSelectedPaymentOption({
            ...selectedPaymentOption,
            appliedDiscountOffer:
              selectedPaymentOption &&
              selectedPaymentOption.maxDiscountOffer &&
              selectedPaymentOption.maxDiscountOffer.DiscountCategory != 'PromoCode'
                ? selectedPaymentOption.maxDiscountOffer
                : {},
          })
        }
        if (
          _.get(this, 'props.store.purchaseEligibilityInfo.Disclaimer', false) &&
          _.get(this, 'props.store.checkIfPaymentRequired', false)
        ) {
          this.setState({
            showDisclaimerModal: true,
          })
        } else if (
          // this.state.isNewPlanSalesFlow &&
          !this.isAddressRequiredForPlanPurchase() &&
          this.state.selectedState &&
          Object.keys(this.state.selectedState).length > 0 &&
          _.get(this, 'props.store.availablePaymentOptions[0].PlanDiscounts.PlanDiscountOffers', []).length <= 0
        ) {
          this.validateRequiredPaymentParams()
        } else {
          redirectionQry && browserHistory.push(redirectionQry)
        }
        this.props.setglobalLoaderStatus(false)
      })
      .catch(() => {
        this.props.setglobalLoaderStatus(false)
      })
  }

  closeDisclaimerModal = () => {
    this.props.setglobalLoaderStatus(true)
    const redirectionQry = {
      pathname: '/store/checkout',
      query: {
        step: 1,
        [this.props.location.query.region ? 'region' : '']: this.props.location.query.region,
      },
    }
    this.setState({
      showDisclaimerModal: false,
    })
    if (
      this.state.isNewPlanSalesFlow &&
      !this.isAddressRequiredForPlanPurchase() &&
      this.state.selectedState &&
      Object.keys(this.state.selectedState).length > 0 &&
      _.get(this, 'props.store.availablePaymentOptions[0].PlanDiscounts.PlanDiscountOffers', []).length <= 0
    ) {
      this.validateRequiredPaymentParams()
    } else {
      browserHistory.push(redirectionQry)
      this.props.setglobalLoaderStatus(false)
    }
  }

  buySelectedPlan = async (_e, func) => {
    this.props.setglobalLoaderStatus(true)
    this.props.setBuyPlanStatusAval(true)
    let isEligible = true
    let warrantyCheck = _.get(this, 'props.store.selectedPlanDetails.EntityDetails[0].WarrantyCheckDetails.ValidForEntireBrand', false)
    if (warrantyCheck && (this.state.secondaryDOPValue || this.state.productPurchaseCost)) {
      isEligible = await this.handleSecondaryEligibilityCheck()
    }
    if (isEligible) {
      if (this.props.store.selectedPlanDetails.PlanConfig.IsCustomPlan) {
        this.props.setglobalLoaderStatus(true)
        this.props.addCustomPlanProductPrice(this.state.CustomPlanDevicePrice)
        this.props.addCustomPlanDOP(moment(this.state.customPlanDOPValue).format('YYYY-MM-DD'))
        func && func()
      }
      let redirectionQry = {
        pathname: '/store/checkout',
        query: {
          step: 1,
          [this.props.location.query.region ? 'region' : '']: this.props.location.query.region,
        },
      }
      if (this.props.user.isAnGuestUser && !this.state.isNewPlanSalesFlow) {
        let userDetails = _.get(this, 'props.user.userDetails', {})
        this.props.storeLastKnowPathName({
          path: '/store/checkout',
          obtainProfile: !userDetails.consumerID && this.props.user.loginRedirectionURL,
          params: { step: 1, [this.props.location.query.region ? 'region' : '']: this.props.location.query.region },
        })
        if (!userDetails.consumerID && this.props.user.loginRedirectionURL) {
          window.location.replace(this.props.user.loginRedirectionURL)
        } else {
          browserHistory.push({
            pathname: '/store/signin',
            query: {
              ...this.props.location.query,
            },
          })
        }
        this.props.setglobalLoaderStatus(false)
      } else {
        if (!_.get(this, 'props.user.userDetails.birthDate', false) && this.state.dateOfBirth) {
          this.props
            .updateUserDetailsAsync({
              data: {
                consumerID: _.get(this, 'props.user.userDetails.consumerID'),
                updateObj: {
                  birthDate: moment(this.state.dateOfBirth).format('YYYY-MM-DD'),
                },
              },
            })
            .catch((err) => {
              console.log(err)
            })
        }
        if (isEligible) {
          if (!warrantyCheck) {
            this.addPlanToaDevice(false)
              .then(() => {
                this.setState({
                  buyNowButtonLoder: false,
                })
                // user already logged in, but need to take national id and proof of purchase
                browserHistory.push({
                  pathname: '/store/signin',
                  query: {
                    ...this.props.location.query,
                  },
                })
              })
              .catch(() => {
                this.props.setglobalLoaderStatus(false)
                this.setState({
                  buyNowButtonLoder: false,
                })
              })
          } else {
            if (this.state.isNewPlanSalesFlow && !_.get(this, 'props.user.userDetails.consumerID', false)) {
              const redirectionQry = {
                pathname: '/store/checkout',
                query: { step: 1 },
              }
              // ******** PaymentOnActivation key handling to take payment or not in D+1 flow
              this.checkIfPaymentRequiredHandling(redirectionQry)
            } else {
              this.addPurchasedPlanToaDevice()
                .then(() => {
                  const redirectionQry = {
                    pathname: '/store/checkout',
                    query: { step: 1 },
                  }
                  // ******** PaymentOnActivation key handling to take payment or not in D+1 flow
                  this.checkIfPaymentRequiredHandling(redirectionQry)
                })
                .catch(() => {
                  this.props.setglobalLoaderStatus(false)
                  this.setState({
                    buyNowButtonLoder: false,
                  })
                })
            }
          }
        } else {
          browserHistory.push(redirectionQry)
        }
      }
    } else {
      this.props.setglobalLoaderStatus(false)
    }
  }

  purchasePlanWithoutPayment = () => {
    this.props.setglobalLoaderStatus(true)
    let consumerID = _.get(this, 'props.user.userDetails.consumerID', undefined)
    let planInfo = this.props.store.selectedPlanDetails
    let fetchedPlanPrice = _.get(this, 'props.store.planPriceOnProductBasis', {})
    let planPrice = {
      PlanID: planInfo.PlanID,
      PlanPriceID: planInfo.planPrice.find((planprice) => planprice.isSelected).PlanPriceID,
      PlanPrice: planInfo.planPrice.find((planprice) => planprice.isSelected).PlanPrice,
    }
    if (planInfo.priceRange && fetchedPlanPrice.PlanPrice) {
      planPrice = {
        PlanID: fetchedPlanPrice.PlanID,
        PlanPriceID: fetchedPlanPrice.PlanPriceID,
        PlanPrice: fetchedPlanPrice.PlanPrice,
      }
    }
    let reqObj = {
      ExternalPlanCode: _.get(this, 'props.store.purchaseEligibilityInfo.ExternalPlanCode', undefined),
      ConsumerID: consumerID,
      Source: _.get(this, 'props.user.appConfigDetails.app'),
      ConsumerProductID: _.get(this, 'props.store.planConsumerProduct.ConsumerProductID'),
      CurrencyID: _.get(this, 'props.store.selectedPlanDetails.CurrencyID'),
      PlanArray: [planPrice],
      AmountPaid: planPrice.PlanPrice,
      app: _.get(this, 'props.user.appConfigDetails.app'),
      CountryID: _.get(this, 'props.user.selectedCountryData.CountryID'),
      PhoneCode: _.get(this, 'props.user.selectedCountryData.PhoneCode'),
      RestrictDocumentEmail: true,
      StateCode: this.state.selectedState.StateCode || _.get(this, 'props.state.selectedState.StateCode', undefined),
      ProductID: _.get(this, 'props.store.purchaseEligibilityInfo.PlanDiscounts.ProductObj.ProductID', undefined),
      ProductSubCategoryID: _.get(this, 'props.store.purchaseEligibilityInfo.PlanDiscounts.ProductObj.ProductSubCategoryID', undefined),
      EmailID: _.get(this, 'props.user.userDetails.emailID', undefined),
      PlanDiscountConfigurationID: _.get(
        this,
        'props.store.availablePaymentOptions.maxDiscountOffer.PlanDiscountConfigurationID',
        undefined
      ),
      PlanID: planInfo.PlanID,
      imeiOrSerial: _.get(this, 'props.store.purchaseEligibilityInfo.imeiOrSerial', undefined),
    }

    this.props
      .purchasePlanNoPaymentAsync(
        reqObj,
        this.state.isNewPlanSalesFlow && !_.get(this, 'props.user.userDetails.consumerID', false)
          ? '/ConsumerProduct/purchaseDiagnosisPlan'
          : '/OnlinePayment/purchaseDiagnosisPlan'
      )
      .then(() => {
        const redirectionQry = {
          pathname: '/store/checkout',
          query: {
            step: 3,
            [this.props.location.query.region ? 'region' : '']: this.props.location.query.region,
          },
        }
        browserHistory.push(redirectionQry)
        // send event here
        const { PlanName } = _.get(this, 'props.store.selectedPlanDetails')
        const { ValidTillMonths } = _.get(this, 'props.store.selectedPlanDetails.PlanConfig')
        const { BrandName } = _.get(this, 'props.store.selectedPlanDetails.EntityDetails[0]')
        const productUniqueID = _.get(this, 'props.store.purchaseEligibilityInfo.imeiOrSerial', undefined)
        pushToAnalytics('Plan Sales - Plan Enrolled', {
          ConsumerID: consumerID,
          ExternalPlanCode: _.get(this, 'props.store.purchaseEligibilityInfo.ExternalPlanCode', undefined),
          'Plan Name': PlanName || undefined,
          Brand: BrandName,
          'Plan Validity': ValidTillMonths,
          'Plan Price': planPrice ? planPrice.PlanPrice : undefined,
          'IMEI or Serial': productUniqueID,
          ConsumerProductID: _.get(this, 'props.store.planConsumerProduct.ConsumerProductID', undefined),
          Source: 'Web',
          IMEI_checked_on_storepage: !!_.get(this, 'props.store.imeiForFilter', false),
          eureka: !!_.get(this, 'props.store.imeiFromToken', false),
          'Logged In': consumerID ? 'Yes' : 'No',
          ProductName: _.get(this, 'props.store.purchaseEligibilityInfo.ProductName', ''),
        })
      })
      .catch(() => {})
      .finally(() => {
        this.props.setglobalLoaderStatus(false)
      })
  }

  initiatePlanPayment = () => {
    let consumerID = _.get(this, 'props.user.consumerDetails.data.consumerID', undefined)
    let planInfo = this.props.store.selectedPlanDetails
    this.props.setglobalLoaderStatus(true)
    let planPrice = {
      PlanID: planInfo.PlanID,
      PlanPriceID: planInfo.planPrice.find((planprice) => planprice.isSelected).PlanPriceID,
    }
    let fetchedPlanPrice = _.get(this, 'props.store.planPriceOnProductBasis', {})
    if (planInfo.priceRange && fetchedPlanPrice.PlanPrice) {
      // plan price fetched on product basis
      planPrice = {
        PlanID: fetchedPlanPrice.PlanID,
        PlanPriceID: fetchedPlanPrice.PlanPriceID,
      }
    }
    const taxAddress = _.get(this, 'props.store.taxAddress', undefined)
    let reqObj = {
      data: {
        ConsumerID: consumerID,
        CountryID: this.props.user.selectedCountryData.CountryID,
        Zipcode: this.props.store.Zipcode,
        StateCode: _.get(this, 'props.store.selectedState.StateCode', undefined),
        PaymentMode: _.get(this, 'props.store.selectedPaymentOption.PaymentModeCode'),
        TaxationType: _.get(this, 'props.store.selectedPlanDetails.PlanConfig.TaxationType'),
      },
    }
    if (taxAddress) {
      const address = {
        AddressLine1: taxAddress.UserLandmark,
        AddressLine2: taxAddress.Address,
        City: taxAddress.City,
        State: taxAddress.State,
        Zipcode: taxAddress.PinCode,
      }
      reqObj.data.Address = address
    }
    if (planInfo.PlanConfig.IsCustomPlan) {
      reqObj = {
        ...this.setCustomPlanPayableAmountObject(),
        data: {
          ...this.setCustomPlanPayableAmountObject().data,
          ...reqObj.data,
        },
      }
    } else {
      reqObj = {
        data: {
          ...reqObj.data,
          CurrencyID: planInfo.CurrencyID,
          PlanArray: [
            {
              ...planPrice,
              PlanPriceBillingConfigID: _.get(this, 'props.store.selectedPaymentOption.PlanPriceBillingConfigID', undefined),
            },
          ],
          ProductName: _.get(this, 'props.store.purchaseEligibilityInfo.ProductObj.ProductName', undefined),
          action: 'update',
          PlanDiscountConfigurationID:
            _.get(this, 'props.store.selectedPaymentOption.maxDiscountOffer.DiscountCategory', '') === 'PromoCode'
              ? _.get(this, 'props.store.selectedPaymentOption.appliedDiscountOffer.PlanDiscountConfigurationID', undefined)
              : _.get(this, 'props.store.selectedPaymentOption.maxDiscountOffer.PlanDiscountConfigurationID', undefined),
          isDiscountTaxIncluded: _.get(this, 'props.store.selectedPaymentOption.appliedDiscountOffer.isDiscountTaxIncluded', undefined),
          hasConcession: _.get(this, 'props.store.selectedPaymentOption.maxDiscountOffer.hasConcession', undefined),
          DiscountedPlanPrice: _.get(
            this,
            'props.store.selectedPaymentOption.appliedDiscountOffer.hasConcession',
            _.get(this, 'props.store.selectedPaymentOption.maxDiscountOffer.hasConcession', undefined)
          )
            ? 1
            : undefined,
        },
      }
    }

    reqObj.data.BrandID = _.get(this, 'props.store.purchaseEligibilityInfo.PlanDiscounts.ProductObj.BrandID', undefined)
    reqObj.data.ProductID = _.get(this, 'props.store.purchaseEligibilityInfo.PlanDiscounts.ProductObj.ProductID', undefined)
    reqObj.data.ProductSubCategoryID = _.get(
      this,
      'props.store.purchaseEligibilityInfo.PlanDiscounts.ProductObj.ProductSubCategoryID',
      undefined
    )
    reqObj.data.ExternalPlanCode = _.get(this, 'props.store.purchaseEligibilityInfo.ExternalPlanCode', undefined)
    const { ProductObj, PlanPrice } = _.get(this, 'props.store.purchaseEligibilityInfo', {})
    const { emailID } = _.get(this, 'props.user.userDetails', false)
    reqObj.data.EmailID = emailID
    let apiCall = this.props.getPayableAmountAsync
    if (this.state.isNewPlanSalesFlow && !_.get(this, 'props.user.userDetails.consumerID', false)) {
      reqObj.data.imeiOrSerial = _.get(this, 'props.store.purchaseEligibilityInfo.imeiOrSerial', undefined)
      reqObj.data.app = BrandSpecificConfig().header
      reqObj.data.PaymentInfoDetails = {
        PlanArray: reqObj.data.PlanArray,
        Source: BrandSpecificConfig().header,
        CountryID: this.props.user.selectedCountryData.CountryID,
        CountryCode: this.props.user.selectedCountryData.CountryCode,
        ExternalPlanCode: reqObj.data.ExternalPlanCode,
        Status: 1,
        CurrencyID: reqObj.data.CurrencyID,
        isInternalModule: false,
        interface: 'consumerWeb',
        app: BrandSpecificConfig().header,
      }
      apiCall = this.props.getPayableAmountForNonLoginFlow
    }
    apiCall(reqObj)
      .then(() => {
        // ------------- CleverTap | Plan Sales - Payment Initiated --------/
        const { emailID, mobileNo } = _.get(this, 'props.user.userDetails', false)
        const clevertapUserProfile = _.get(this, 'props.user', {})
        const { PlanName } = _.get(this, 'props.store.selectedPlanDetails')
        const { BrandName } = _.get(this, 'props.store.selectedPlanDetails.EntityDetails[0]')
        const { ValidTillMonths } = _.get(this, 'props.store.selectedPlanDetails.PlanConfig')
        pushToAnalytics(
          'Plan Sales - Payment Initiated',
          {
            'Email ID': emailID || undefined,
            'Mobile Number': mobileNo || undefined,
            ConsumerID: consumerID || undefined,
            'Plan Name': PlanName,
            Brand: BrandName,
            'Policy Name': PlanName,
            'Plan Validity': ValidTillMonths,
            'Plan Price': PlanPrice ? PlanPrice.PlanPrice : undefined,
            'IMEI or Serial': ProductObj ? ProductObj.ProductUniqueID : undefined,
            ConsumerProductID: _.get(this, 'props.store.planConsumerProduct.ConsumerProductID', undefined),
            Source: 'Web',
            IMEI_checked_on_storepage: !!_.get(this, 'props.store.imeiForFilter', false),
            eureka: !!_.get(this, 'props.store.imeiFromToken', false),
            'Logged In': _.get(this, 'props.user.consumerDetails.data.consumerID', false) ? 'Yes' : 'No',
            ProductName: _.get(this, 'props.store.purchaseEligibilityInfo.ProductName', ''),
          },
          clevertapUserProfile
        )
        // ------------- CleverTap | Plan Sales - Payment Initiated --------/
        this.props.setglobalLoaderStatus(false)
        if (this.props.store.planPaymentDetails.gateway === 'instamojo') {
          window.open(this.props.store.planPaymentDetails.paymentUrl, '_blank')
        } else {
          this.props.storeLastKnowPathName({
            path: '/store/checkout',
            params: {
              ...this.props.location.query,
            },
          })
          browserHistory.push({
            pathname: '/payment',
            query: {
              [this.props.location.query.region ? 'region' : '']: this.props.location.query.region,
            },
          })
        }
      })
      .catch((e) => {
        console.log(e)
        this.props.setglobalLoaderStatus(false)
      })
  }

  resetCheckoutComponent = () => {
    this.setState({
      selectedState: {},
    })
    // this.props.clearexistingPaymentDetails({})
  }

  handleBannerNav = (e) => {
    if (e) {
      // Splitting BrandName-PlanType
      let id = e.currentTarget.id.split('-')
      this.handleStoreItemSelection(id[0])

      // Set selected tab based on plan type
      this.setSelectedTab(id[1])
    }
  }

  handleZipcode = (e) => {
    let zipCodeConfig = _.get(this, 'props.user.selectedCountryData.countryConfig.PinCode')
    if (
      regexCheck(
        'onChange',
        _.get(zipCodeConfig, 'REGEX_FN', 'numeric').toLowerCase(),
        e.target.value,
        Math.max(..._.get(zipCodeConfig, 'LENGTH', [6]))
      )
    ) {
      this.setState({
        Zipcode: e.target.value,
      })
    }
  }

  handleSecondaryDOP = (e) => {
    this.setState({ secondaryDOPValue: e, showEligibilityStatus: false })
  }

  handleSecondaryEligibilityCheck = async () => {
    this.setState({ eligibilityLoader: true })
    const brandID = this.getBrandDetails(this.props.store.selectedPlanDetails).BrandID
    const requestObject = {
      getStatusMessage: true,
      imeiOrSerial: this.state.eligiblityCheckValue,
      PlanID: this.state.selectedItems.PlanID,
      BrandID: brandID,
    }
    if (this.state.secondaryDOPValue) {
      requestObject.DateOfPurchase = moment(this.state.secondaryDOPValue).format('YYYY-MM-DD')
    }
    if (this.state.productPurchaseCost) {
      requestObject.ProductPurchaseCost = this.state.productPurchaseCost
    }
    // loginBeforeEligibility key check from appConfig
    const loginBeforeEligibility = _.get(this, 'props.user.appConfigDetails.loginBeforeEligibility', false)
    if (loginBeforeEligibility) {
      requestObject.MobileNo = _.get(this, 'props.user.consumerDetails.MobileNo')
      requestObject.ConsumerID = _.get(this, 'props.user.consumerDetails.data.consumerID')
    }
    try {
      const response = await this.props.getPlanEligibilityStatus(requestObject, 'secondaryCheck')
      !response.data.IsPurchasedAllowed &&
        this.setState({
          eligibilityStatus: response.data.IsPurchasedAllowed,
          showEligibilityStatus: true,
          eligibilityMessage: response.data.Message,
        })
      this.setState({ eligibilityLoader: false })
      if (response.data.PlanPrice) {
        this.props.setFetchedPlanPriceByProduct(response.data.PlanPrice)
      }
      return response.data.IsPurchasedAllowed
    } catch (e) {
      this.setState({
        eligibilityStatus: false,
        eligibilityLoader: false,
        eligibilityMessage: getTranslationText('error', 'somethingWentWrong'),
      })
      return false
    }
  }

  handleOnProductPurchaseCostChange = (e) => {
    let numValue = e.target.value
    if (numValue && numValue.length === 8) {
      if (_.includes([0, 7], numValue.lastIndexOf('.'))) {
        numValue = numValue.replace(/\./g, '')
      }
    }
    if (regexCheck('onChange', 'numericfloat', numValue)) {
      this.setState({ productPurchaseCost: numValue })
    } else if (!numValue) {
      this.setState({ productPurchaseCost: '' })
    }
  }

  showUploadDocsModal = () => {
    this.setState({
      showDeviceDocsUploadModal: true,
    })
  }

  closeDeviceDocsUploadModal = () => {
    this.setState({
      showDeviceDocsUploadModal: false,
    })
  }

  handleUploadDocs = (e) => {
    let file = e.target.files[0]
    let uniqueId = uuid.v1()
    canUpload(e, { allowedTypes: ['image', 'pdf'] })
      .then(() => {
        this.setState({
          docsBtnLoader: true,
        })
        this.props
          .getSignedUrlAsync(
            {
              data: {
                Type: file.type,
                Key: uniqueId,
              },
            },
            file,
            e.target.name,
            true
          )
          .then(() => {
            this.setState({
              docsBtnLoader: false,
            })
          })
          .catch(() => {
            this.setState({
              docsBtnLoader: false,
            })
          })
      })
      .catch((error) => this.props.setToaster(error.message, 'error'))
  }

  getBrandDetails = (planInfo) => {
    return planInfo.EntityDetails[0]
  }

  getEligibilityFieldType = () => {
    let selectedPlan = this.props.store.selectedPlanDetails
    let fieldType = _.get(selectedPlan, 'planConfig.SuperGroupEligibilityCheckFields[0]', 'IMEI')
    fieldType = fieldType.toUpperCase()
    let brandDetails = this.getBrandDetails(selectedPlan)
    if (_.get(brandDetails, 'WarrantyCheckDetails.AllowedValues', []).length) {
      fieldType = (() => {
        let typesList = _.get(brandDetails, 'WarrantyCheckDetails.AllowedValues', []).map((ValueType) => {
          return ValueType.Type
        })
        if (typesList.includes('IMEI') && typesList.includes('SerialNumber')) {
          return 'IMEIORSERIAL'
        } else if (typesList.includes('SerialNumber')) {
          return 'SERIALNO'
        } else {
          return 'IMEI'
        }
      })()
    }
    return fieldType
  }

  handleDatePickerOnChange = (e, id) => {
    if (id === 'date-check') {
      this.setState(
        {
          eligiblityCheckValue: e,
        },
        () => {
          this.checkPlanEligibility()
        }
      )
    }
  }

  // custom plans related handler

  handleCustomPlanEligibilityOnChange = (e, id) => {
    let value = id === 'device-dop' ? e : e.target.value.trim()
    let brand = _.get(this, 'props.store.selectedBrand', {})
    let imeiLengthCheck = 15 // default imei length

    if (brand && brand.WarrantyCheckDetails && brand.WarrantyCheckDetails.AllowedValues) {
      let imeiType = brand.WarrantyCheckDetails.AllowedValues.find((data) => data.Type === 'IMEI')
      imeiLengthCheck = imeiType && imeiType.Length // from warranty check details
    }
    // reset priorityQueue & dependencyQueue
    this.props.setPriorityQueueQuestion()
    this.props.setDependencyQueueQuestion()
    // eligible device info
    if (id === 'device-dop') {
      this.setState({
        customPlanDOPValue: moment(value).format('YYYY-MM-DD'),
        showCustomQuestions: false,
        customPlanDOPStatus: false,
        // showProductPriceInput: this.state.isProductPurchasePriceAvailable,
        customPlanPriceDisabled: true,
        CustomPlanDevicePrice: this.state.isProductPurchasePriceAvailable ? this.state.CustomPlanDevicePrice : '',
      })
    } else if (id === 'device-imei') {
      this.props.seteligibleDeviceInfo()
      this.setState(
        {
          customPlanDOPValue: '',
          showCustomPlanEligibilityStatus: false,
          isCustomPlanEligible: false,
          customPlanIMEIError: false,
          showDOP: false,
          // customPlanDOPStatus: false,
          showCustomQuestions: false,
          showProductPriceInput: false,
          customPlanPriceDisabled: true,
        },
        () => {
          if (regexCheck('onChange', 'numeric', value)) {
            let customEvent = {
              target: {
                id: 'ProductUniqueID',
                value: value,
              },
            }
            let verdict = validateInputChangeForDevice(customEvent, { BrandID: brand && brand.BrandID }, brand.WarrantyCheckDetails)
            if (verdict.isValid) {
              this.setState({
                CustomPlanIMEIValue: value,
                customPlanImeiDisabled: !(value.length >= imeiLengthCheck),
              })
            } else {
              verdict.msg && this.props.setToaster(verdict.msg, 'error')
            }
          }
        }
      )
    } else if (id === 'product-price') {
      this.setState(
        {
          showCustomQuestions: false,
          customPlanPriceDisabled: true,
        },
        () => {
          if (regexCheck('onChange', 'numeric', value, 10)) {
            this.setState({
              CustomPlanDevicePrice: value,
              customPlanPriceDisabled: parseInt(value) < 500,
            })
          }
        }
      )
    }
  }

  checkCustomizedPlanEligibilityCheck = (e) => {
    let brand = _.get(this, 'props.store.selectedPlanDetails.EntityDetails[0]', {})
    const { PlanName } = this.state.selectedItems
    const { BrandName, ValidTillMonths } = _.get(this, 'props.store.selectedPlanDetails.PlanConfig', {})
    const { PlanPrice } = _.get(this, 'props.store.selectedPlanDetails.planPrice[0]', {})
    const LoggedIn = _.get(this, 'props.user.consumerDetails.data.consumerID', false)
    const { emailID, mobileNo } = _.get(this, 'props.user.userDetails', false)
    const clevertTapEligibility = {
      'Logged In': LoggedIn ? 'Yes' : 'No',
      'Email ID': emailID || undefined,
      'Mobile Number': mobileNo || undefined,
      ConsumerID: _.get(this, 'props.user.consumerDetails.data.consumerID', ''),
      'Plan Name': PlanName,
      Brand: BrandName,
      'Policy Name': PlanName,
      'Plan Validity': ValidTillMonths,
      'Plan Price': PlanPrice,
      Source: 'Web',
      'IMEI or Serial': e.currentTarget.id === 'imei-check' ? this.state.CustomPlanIMEIValue : undefined,
      Model: '',
      'Date of Purchase': e.currentTarget.id === 'imei-check' ? undefined : moment(this.state.customPlanDOPValue).format('YYYY-MM-DD'),
    }

    let eligibilityResponse = { status: '', msg: '' }

    if (e.currentTarget.id === 'imei-check') {
      this.props.setglobalLoaderStatus(true)
      this.props
        .getPlanEligibilityStatus({
          getStatusMessage: true,
          BrandID: brand.BrandID,
          imeiOrSerial: this.state.CustomPlanIMEIValue,
          PlanID: this.props.store.selectedPlanDetails.PlanID,
        })
        .then((response) => {
          this.props.setglobalLoaderStatus(false)
          if (response.success) {
            response.data.IsPurchasedAllowed && this.props.seteligibleDeviceInfo(response.data.ProductObj)
            let msg = !response.data.IsPurchasedAllowed && response.data.Message
            let dopFromPlanEligibility = ''
            let productPriceFromPlanEligibilty = ''
            // let isDopAvailable = false
            // let isProductPurchasePriceAvailable = false
            if (!response.data.IsPurchasedAllowed && response.data.status_message === 'INVALID_IMEI_ERROR') {
              msg = getTranslationText('error', 'invalidIMEI')
            }
            dopFromPlanEligibility = _.get(response, 'data.ProductObj.DateOfPurchase', '')
            productPriceFromPlanEligibilty = _.get(response, 'data.ProductObj.ProductPurchaseCost', '')

            eligibilityResponse = {
              status: response.data.IsPurchasedAllowed,
              msg,
            }
            clevertTapEligibility['Date of Purchase'] = dopFromPlanEligibility
            clevertTapEligibility['Model'] = _.get(response, 'data.ProductObj.ModelNo', '')

            this.setState(
              {
                showCustomPlanEligibilityStatus: true,
                eligibilityStatus: response.data.IsPurchasedAllowed,
                isCustomPlanEligible: response.data.IsPurchasedAllowed,
                customPlanIMEIError: msg,
                CustomPlanDevicePrice: productPriceFromPlanEligibilty && productPriceFromPlanEligibilty,
                customPlanDOPValue: dopFromPlanEligibility && moment(dopFromPlanEligibility).format('YYYY-MM-DD'),
                // customPlanDOPStatus: !!(dopFromPlanEligibility),
                // customPlanPriceDisabled: !!(productPriceFromPlanEligibilty),
                isDopAvailable: !!dopFromPlanEligibility,
                isProductPurchasePriceAvailable: !!productPriceFromPlanEligibilty,
                showProductPriceInput: (!productPriceFromPlanEligibilty && !!dopFromPlanEligibility) || !!productPriceFromPlanEligibilty,
                showDOP: response.data.IsPurchasedAllowed || !!dopFromPlanEligibility,
                // showDOP: !(response.data.IsPurchasedAllowed &&
                //   (_.get(this, 'props.store.purchaseEligibilityInfo.ProductObj.DateOfPurchase'))) || '',
                customPlanImeiDisabled: response.data.IsPurchasedAllowed,
              },
              () => {
                if (this.state.isDopAvailable && this.state.isProductPurchasePriceAvailable) {
                  this.getCustomPlanQuestions(this.state.customPlanDOPValue)
                }
              }
            )
          } else {
            this.props.setToaster(response.msg, 'error')
            eligibilityResponse = {
              status: false,
              msg: response.msg,
            }
          }
        })
        .catch(() => {
          this.props.setglobalLoaderStatus(false)
          eligibilityResponse = {
            status: false,
            msg: 'Something went wrong',
          }
        })
        .finally(() => {
          // ------------ Clever Tap | Custome Plan Purchase - Check IMEI ------------
          const clevertapUserProfile = LoggedIn ? _.get(this, 'props.user', {}) : {}

          pushToAnalytics(
            'Plan Sales - Eligibility Check',
            {
              ...clevertTapEligibility,
              Eligible: eligibilityResponse.status,
              eureka: !!_.get(this, 'props.store.imeiFromToken', false),
              Error: eligibilityResponse.status ? undefined : eligibilityResponse.msg,
              ProductName: _.get(this, 'props.store.purchaseEligibilityInfo.ProductName', ''),
            },
            clevertapUserProfile
          )
          // ------------ Clever Tap | Custome Plan Purchase - Check IMEI ------------
        })
    } else if (e.currentTarget.id === 'dop-check') {
      this.props.setglobalLoaderStatus(true)
      this.props
        .getPlanEligibilityStatus(
          {
            ...this.props.store.eligibleDeviceInfo,
            BrandID: brand.BrandID,
            DateOfPurchase: moment(this.state.customPlanDOPValue).format('YYYY-MM-DD'),
            imeiOrSerial: this.state.CustomPlanIMEIValue,
            PlanID: this.props.store.selectedPlanDetails.PlanID,
          },
          'secondaryCheck'
        )
        .then((response) => {
          this.props.setglobalLoaderStatus(false)
          // this.getCustomPlanQuestions(this.state.customPlanDOPValue)
          if (response.success) {
            if (response.data.IsPurchasedAllowed) {
              this.setState({
                customPlanDOPStatus: true,
                showProductPriceInput: response.data.IsPurchasedAllowed,
              })
              eligibilityResponse = {
                status: true,
                msg: '',
              }
              if (this.state.customPlanDOPValue && this.state.CustomPlanDevicePrice) {
                this.getCustomPlanQuestions(this.state.customPlanDOPValue)
              }
            } else {
              this.props.setToaster(response.data.Message, 'error')
              eligibilityResponse = {
                status: false,
                msg: response.data.Message,
              }
            }
          }
        })
        .catch(() => {
          this.props.setglobalLoaderStatus(false)
          eligibilityResponse = {
            status: false,
            msg: 'Something went wrong',
          }
        })
        .finally(() => {
          // ------------ Clever Tap | Custome Plan Purchase - Check DOP ------------
          const clevertapUserProfile = _.get(this, 'props.user', {})

          pushToAnalytics(
            'Plan Sales - Eligibility Check',
            {
              ...clevertTapEligibility,
              Eligible: eligibilityResponse.status,
              eureka: !!_.get(this, 'props.store.imeiFromToken', false),
              Error: eligibilityResponse.status ? undefined : eligibilityResponse.msg,
              ProductName: _.get(this, 'props.store.purchaseEligibilityInfo.ProductName', ''),
            },
            clevertapUserProfile
          )
          // ------------ Clever Tap | Custome Plan Purchase - Check DOP ------------
        })
    } else if (e.currentTarget.id === 'prod-price-check') {
      this.setState(
        {
          customPlanPriceDisabled: true,
          showCustomQuestions: true,
        },
        () => {
          this.getCustomPlanQuestions(this.state.customPlanDOPValue)
        }
      )
    }
  }

  getCustomPlanQuestions = (dop) => {
    this.props.setglobalLoaderStatus(true)
    this.props
      .fetchPlanQuestionsAsync({
        ...this.props.store.purchaseEligibilityInfo.ProductObj,
        DateOfPurchase: dop,
        PlanArray: [this.props.store.selectedPlanDetails.PlanID],
      })
      .then((response) => {
        this.props.setglobalLoaderStatus(false)
        // this.generatePriorityQueue(response.data.data)
        // Priority queue
        let priorityQueue = _.groupBy(response.Questions, 'Priority')
        let priorityQueueTemp = _.map(priorityQueue, (pq) => {
          return _.map(pq, (ques) => {
            return {
              ...ques,
              Answers: ques.Answers.map((value) => ({
                ...value,
                text: value.DisplayValue,
              })),
              isQuestionAnswered: false,
            }
          })
        })
        priorityQueue = priorityQueueTemp

        let dependencyQueue = []
        response.Dependencies.map((dependency) => {
          let existingDepIndex = null
          let hasexistingDep = dependencyQueue.find((value, index) => {
            if (value[dependency.SourceDependencyType] === dependency.SourceDependencyValue) {
              existingDepIndex = index
              return value
            }
          })
          if (hasexistingDep) {
            let hasVal = dependencyQueue[existingDepIndex][dependency.DestinationDependencyType + '_List']
            dependencyQueue[existingDepIndex] = {
              ...hasexistingDep,
              [dependency.DestinationDependencyType + '_List']:
                hasVal && hasVal.length ? [...hasVal, dependency.DestinationDependencyValue] : [dependency.DestinationDependencyValue],
            }
          } else {
            dependencyQueue.push({
              [dependency.SourceDependencyType]: dependency.SourceDependencyValue,
              [dependency.DestinationDependencyType + '_List']: [dependency.DestinationDependencyValue],
            })
          }
        })
        this.props.setPriorityQueueQuestion(priorityQueue)
        this.props.setDependencyQueueQuestion(dependencyQueue)
        this.setState({
          showCustomQuestions: true,
        })
        // this.props.setCurrentQueueQuestion(priorityQueue[0])
      })
      .catch(() => {
        this.props.setglobalLoaderStatus(false)
      })
  }

  // -------------- `getPayableAmount` object for custom plan -------------- //
  setCustomPlanPayableAmountObject = () => {
    let payableAmountAnsList =
      this.props.store.currentQueue &&
      this.props.store.currentQueue.map((ques) => {
        let ans = ques.Answers.find((ans) => ans.isSelected)
        let optionCost = ques.Answers.find((ans) => ans.isSelected && ans.OptionCostID)
        return {
          QuestionID: ques.QuestionID,
          OptionCostID: optionCost && optionCost.OptionCostID,
          QuestionAnswerID: ans && ans.QuestionAnswerID,
        }
      })
    let obj = {
      data: {
        PlanArray: [
          {
            PlanID: this.props.store.selectedPlanDetails.PlanID,
            Answers: payableAmountAnsList,
          },
        ],
        action: 'update',
      },
    }
    return obj
  }

  listPlanViewDetails = (index) => {
    this.setState({
      planViewSelectedTab: parseInt(index),
    })
  }

  handleAddressClick = (e, cfl_id) => {
    this.props.setDistrictListConfig([])
    if (!e) {
      this.setState({
        isCreate: true,
        newAddressDetails: {},
        isStateInputDisabled: false,
        isCityInputDisabled: false,
        isPincodeInputDisabled: false,
        selectedState: {},
        selectedDistrict: {},
        addressTypesList: this.state.addressTypesList.map((data) => ({
          ...data,
          isSelected: false,
        })),
        landmark: '',
      })
    } else {
      let selectedAddress = this.state.locations.filter(
        (address) => address.ConsumerFavouriteLocationID === parseInt(e.target && cfl_id)
      )[0]
      let addressesClone = this.state.locations.map((loc) => {
        if (loc.ConsumerFavouriteLocationID === parseInt(cfl_id)) loc.isSelected = true
        else loc.isSelected = false
        return loc
      })
      if (!selectedAddress.StateCode && selectedAddress.State) {
        const countryStateList = _.get(this, 'props.user.stateAddressListConfig', [])
        let selectedAddressState = countryStateList.length && countryStateList.find((v) => selectedAddress.State === v['State'])
        selectedAddress = { ...selectedAddress, StateCode: selectedAddressState && selectedAddressState['StateCode'] }
      }
      this.setState(
        {
          locations: addressesClone,
          selectedAddress: e.target && cfl_id,
          landmark: selectedAddress.Landmark,
          lat: selectedAddress.Lat,
          lng: selectedAddress.Lng,
          locationObtained: true,
          selectedState: { State: selectedAddress.State, value: selectedAddress.State, StateCode: selectedAddress.StateCode },
          selectedDistrict: { District: selectedAddress.District, value: selectedAddress.District },
          addressTypesList: this.state.addressTypesList.map((data) => {
            return {
              ...data,
              isSelected: selectedAddress.AddressType === data.text,
            }
          }),
        },
        () => {
          let addressConfig = _.get(this, 'props.user.countryAddressConfig.addressConfig', fallbackAddressConfig)
          const isDistrictMendatory = addressConfig.some((v) => v['keyName'] === 'District' && v['mandatory'])
          if (isDistrictMendatory) {
            if (selectedAddress.StateCode) {
              this.getDistrictListDetails(selectedAddress.StateCode)
            }
          }
        }
      )
    }
  }
  getDistrictListDetails = (StateCode) => {
    this.props.setglobalLoaderStatus(true)
    let stateCode = StateCode || this.props.user.userLocation.stateCode
    let countryCode = this.props.user.selectedCountryData.CountryCode
    let reqObj = {
      StateCode: `${stateCode}`,
      CountryCode: countryCode,
    }
    this.props
      .getDistrictListAsync(reqObj)
      .then((res) => {
        if (res && res.length) {
          this.setState({
            showDistrictSelection: false,
          })
        } else {
          this.props.setToaster('No District Found.') // translation string
          this.setState({
            isLoaderSet: false,
            showDistrictSelection: true,
          })
        }
      })
      .finally(() => {
        this.props.setglobalLoaderStatus(false)
      })
  }

  handleTypeaheadSelect = (value) => {
    this.props.setDistrictListConfig([])
    this.setState(
      {
        locationObtained: true,
      },
      () => {
        this.props.setglobalLoaderStatus(true)
        this.props
          .getGeoLocationAsync(value)
          .then(() => {
            this.setState({ landmark: this.props.user.userLocation.landmark })
            let addressConfig = _.get(this, 'props.user.countryAddressConfig.addressConfig', fallbackAddressConfig)
            const isPinCodeEnabled = addressConfig.some((v) => v['keyName'] === 'PinCode' && v['display'])
            if (isPinCodeEnabled && !this.props.user.userLocation.pincode) {
              this.setState({
                locationObtained: false,
              })
              this.setUserAddressStateID(this.props.user.userLocation)
            } else {
              this.setUserAddressStateID(this.props.user.userLocation).then(async () => {
                const isDistrictMendatory = addressConfig.some((v) => v['keyName'] === 'District' && v['mandatory'])
                const isPinCodeEnabled = addressConfig.some((v) => v['keyName'] === 'PinCode' && v['display'])
                const isDistrictPresent = _.get(this, 'props.user.userLocation.District', undefined)
                // district api fetching.
                if (isDistrictMendatory && !isDistrictPresent) {
                  if (_.get(this, 'props.request.userLocation.StateCode') || this.state.selectedState.StateCode) {
                    this.getDistrictListDetails(_.get(this, 'props.user.userLocation.StateCode') || this.state.selectedState.StateCode)
                  }
                }
                let state =
                  (await this.props.user.stateAddressListConfig) &&
                  this.props.user.stateAddressListConfig.length &&
                  this.props.user.stateAddressListConfig.find((stateConfig) => stateConfig.State === this.props.user.userLocation.state)
                isPinCodeEnabled &&
                  state &&
                  state.State &&
                  state.StateCode &&
                  state.StateID &&
                  state.CountryID &&
                  this.onChangeAddressInput({
                    target: {
                      name: 'PinCode',
                      value: this.props.user.userLocation.pincode,
                    },
                  })
              })
            }
          })
          .finally(() => {
            this.props.setglobalLoaderStatus(false)
          })
      }
    )
  }

  setUserAddressStateID = async (userAddressLocation) => {
    const userAddressState = userAddressLocation['state']
    const countryStateList = _.get(this, 'props.user.stateAddressListConfig', [])
    let selectedAddressState =
      (await countryStateList) && countryStateList.length && countryStateList.find((v) => userAddressState === v['State'])
    let selectedAddressStateID = selectedAddressState && selectedAddressState['StateID']
    let selectedAddressStateCode = selectedAddressState && selectedAddressState['StateCode']
    if (!this.state.isCreate && this.state.selectedAddress) {
      let addressesClone = this.state.locations.slice(0)
      let addressIndex = addressesClone.findIndex((address) => address.ConsumerFavouriteLocationID === parseInt(this.state.selectedAddress))
      addressesClone[addressIndex] = {
        ...addressesClone[addressIndex],
        State: _.get(this, 'props.user.userLocation.state', ''),
        City: _.get(this, 'props.user.userLocation.city', ''),
        Landmark: _.get(this, 'props.user.userLocation.landmark', ''),
        UserLandmark: _.get(this, 'props.user.userLocation.UserLandmark', ''),
        PinCode: userAddressLocation.pincode || userAddressLocation.PinCode || userAddressLocation.ZipCode,
        StateID: selectedAddressStateID || addressesClone[addressIndex]['StateID'],
        StateCode: selectedAddressStateCode || addressesClone[addressIndex]['StateCode'],
      }
      await this.setState({
        locations: addressesClone,
      })
    } else {
      const googleApiPincode = userAddressLocation.pincode || userAddressLocation.PinCode || userAddressLocation.ZipCode
      let state =
        (await countryStateList) &&
        countryStateList.length &&
        countryStateList.find((stateConfig) => stateConfig.State === this.props.user.userLocation.state)
      const isGoogleApiPincodeValid =
        !!googleApiPincode &&
        state &&
        state.State &&
        state.StateCode &&
        state.StateID &&
        state.CountryID &&
        _.get(this, 'props.user.countryAddressConfig.addressConfig', [])
          .find((configItem) => configItem.keyName === 'PinCode')
          ?.length?.includes(googleApiPincode.length)
      await this.setState({
        isStateInputDisabled: selectedAddressState && Object.keys(selectedAddressState)?.length > 0,
        isCityInputDisabled: _.get(this, 'props.user.userLocation.city', '').length > 0,
        isPincodeInputDisabled: isGoogleApiPincodeValid,
        newAddressDetails: {
          ...this.state.newAddressDetails,
          State: selectedAddressState?.State,
          City: _.get(this, 'props.user.userLocation.city', ''),
          Landmark: _.get(this, 'props.user.userLocation.landmark', ''),
          UserLandmark: _.get(this, 'props.user.userLocation.UserLandmark', ''),
          StateID: selectedAddressStateID,
          PinCode: googleApiPincode,
        },
      })
    }

    await this.setState(
      {
        lat: this.props.user.userLocation.latitude,
        lng: this.props.user.userLocation.longitude,
        selectedState: {
          State: userAddressState,
          value: userAddressState,
          StateCode: selectedAddressStateCode,
        },
      },
      () => {
        new Promise((resolve) => {
          resolve()
        })
      }
    )
  }

  onChangeTypeahead = (address) => {
    this.setState({
      landmark: address,
      locationObtained: false,
    })
  }

  onChangeStateSelection = (stateObject) => {
    this.setState({ selectedState: stateObject }, () => {
      this.onChangeAddressInput({
        target: {
          name: 'State',
          value: stateObject['value'],
        },
      })
    })
  }

  handleDistrictSelection = (stateObject) => {
    this.setState({ selectedDistrict: stateObject }, () => {
      this.onChangeAddressInput({
        target: {
          name: 'District',
          value: stateObject['value'],
        },
      })
    })
  }

  onChangeAddressInput = (e) => {
    let selectedStateDetails
    let selectedAddressStateID
    let selectedAddressDistrict
    let addressConfig = _.get(this, 'props.user.countryAddressConfig.addressConfig', fallbackAddressConfig)
    if (e.target.name === 'PinCode') {
      let pinCodeType =
        _.get(this, 'props.user.countryAddressConfig.addressConfig').find((config) => config.keyName === 'PinCode')?.regexFunc || 'NUMERIC'
      let pinCodeLength = _.get(this, 'props.user.countryAddressConfig.addressConfig').find((config) => config.keyName === 'PinCode')
        ?.length || [6]
      if (e.target.value.length > Math.max(...pinCodeLength)) {
        return
      } else {
        const isPincodeValid = regexCheck('onChange', pinCodeType.toLowerCase(), e.target.value, Math.max(...pinCodeLength))
        this.setState({ locationObtained: isPincodeValid })
        if (!isPincodeValid) {
          return
        }
      }
    }
    if (e.target.name === 'Address') {
      if (!regexCheck('onChange', 'plainTxt', e.target.value)) {
        return
      }
    }
    if (e.target.name === 'State' && e.target.value) {
      const userAddressState = e.target.value
      const countryStateList = _.get(this, 'props.user.stateAddressListConfig', [])
      selectedStateDetails = countryStateList && countryStateList.length && countryStateList.find((v) => userAddressState === v['State'])

      selectedAddressStateID = selectedStateDetails['StateID']
      const isDistrictMendatory = addressConfig.some((v) => v['keyName'] === 'District' && v['mandatory'])
      if (isDistrictMendatory) {
        if (selectedStateDetails.StateCode) {
          this.getDistrictListDetails(selectedStateDetails.StateCode)
        }
      }
    }
    if (e.target.name === 'District' && e.target.value) {
      selectedAddressDistrict = e.target.value
    }
    if (e.target.name === 'City') {
      if (!regexCheck('onChange', 'plainTxt', e.target.value)) {
        return
      }
    }

    // Do not allow user to enter more characters than input limit
    console.log(e.target.name)
    const inputLimit = addressConfig.find((configItem) => configItem.keyName === e.target.name)?.inputLength || Infinity
    console.log('INPUT LIMIT: ', inputLimit)
    if (inputLimit > 0 && inputLimit < Infinity && e?.target?.value?.length > inputLimit) {
      return
    }

    if (!this.state.isCreate && this.state.selectedAddress) {
      let addressesClone = this.state.locations.slice(0)
      let addressIndex = addressesClone.findIndex((address) => address.ConsumerFavouriteLocationID === parseInt(this.state.selectedAddress))
      addressesClone[addressIndex] = {
        ...addressesClone[addressIndex],
        [e.target.name]: e.target.value,
        StateID: selectedAddressStateID || addressesClone[addressIndex]['StateID'],
        District: selectedAddressDistrict,
      }
      this.setState({
        locations: addressesClone,
      })
    } else {
      let selectedAddressStateID = undefined
      if (this.state.newAddressDetails.State || (e.target.name === 'State' && e.target.value)) {
        const countryStateList = _.get(this, 'props.user.stateAddressListConfig', [])
        let selectedStateDetails =
          countryStateList &&
          countryStateList.length &&
          countryStateList.find((v) => this.state.newAddressDetails.State === v['State'] || e.target.value === v['State'])
        selectedAddressStateID = selectedStateDetails && selectedStateDetails['StateID']
      }
      this.setState({
        newAddressDetails: {
          ...this.state.newAddressDetails,
          StateID: selectedAddressStateID || undefined,
          [e.target.name]: e.target.value,
        },
      })
    }
  }

  toggleAddressPillSelection = (e) => {
    let id = e.currentTarget.id.replace('addressType-', '')
    if (id) {
      this.setState(
        {
          addressTypesList: this.state.addressTypesList.map((data, index) => {
            return {
              ...data,
              isSelected: parseInt(id) === index,
            }
          }),
        },
        () => {
          let selectedAddr = this.state.addressTypesList.find((data) => data.isSelected)
          this.onChangeAddressInput({ target: { name: 'AddressType', value: selectedAddr.text } })
        }
      )
    }
  }

  cancelEditOrCreate = () => {
    this.setState({
      selectedAddress: undefined,
      locationObtained: false,
      isCreate: false,
      landmark: '',
      locations: this.props.user.locations,
      selectedState: {},
      selectedDistrict: {},
    })
  }

  setShowAddress = (bCheck) => {
    this.setState({ showAddress: bCheck ? this.isAddressRequiredForPlanPurchase() : false })
  }

  saveAddress = () => {
    let selectedAddress = ''
    let apiCall = ''
    let addedAddress
    if (!this.state.isCreate && this.state.selectedAddress) {
      selectedAddress = this.state.locations.filter(
        (address) => address.ConsumerFavouriteLocationID === parseInt(this.state.selectedAddress)
      )[0]
      apiCall = this.props.updateAddressAsync
    } else {
      selectedAddress = this.state.newAddressDetails
      if (selectedAddress.State) {
        const countryStateList = _.get(this, 'props.user.stateAddressListConfig', [])
        let selectedStateDetails =
          countryStateList && countryStateList.length && countryStateList.find((v) => selectedAddress.State === v['State'])
        selectedAddress.StateID = selectedStateDetails && selectedStateDetails['StateID']
      }
      apiCall = this.props.addAddressAsync
    }
    this.setState({
      addressComponentLoader: true,
    })
    if (this.state.isNewPlanSalesFlow && !_.get(this, 'props.user.userDetails.consumerID', false)) {
      this.props.setConsumerAddress({
        ...selectedAddress,
        Address: selectedAddress.Address || '',
        Landmark: selectedAddress.Landmark || this.state.landmark,
        UserLandmark: selectedAddress.UserLandmark || '',
        Lat: this.state.lat,
        Lng: this.state.lng,
      })
    } else
      apiCall({
        AuthID: this.props.user.consumerDetails.data.accessToken.ID,
        data: {
          ConsumerID: this.props.user.userDetails.consumerID,
          ...selectedAddress,
          Address: selectedAddress.Address || '',
          Landmark: selectedAddress.Landmark || this.state.landmark,
          UserLandmark: selectedAddress.UserLandmark || '',
          Lat: this.state.lat,
          Lng: this.state.lng,
        },
      })
        .then((response) => {
          addedAddress = response.data
          return this.props.getUserAddressesAsync({
            ConsumerID: this.props.user.userDetails.consumerID,
            AuthID: this.props.user.consumerDetails.data.accessToken.ID,
          })
        })
        .then(() => {
          if (!addedAddress.StateCode && addedAddress.State) {
            const countryStateList = _.get(this, 'props.user.stateAddressListConfig', [])
            let selectedAddressState = countryStateList.length && countryStateList.find((v) => addedAddress.State === v['State'])
            addedAddress = { ...addedAddress, StateCode: selectedAddressState && selectedAddressState['StateCode'] }
          }
          this.setState({
            addressComponentLoader: false,
            selectedState: { State: addedAddress.State, value: addedAddress.State, StateCode: addedAddress.StateCode },
            selectedDistrict: {},
          })
          this.setState({
            locations: this.props.user.locations,
          })
          this.props.setConsumerAddress(addedAddress)
        })
        .catch(() => {
          this.setState({
            addressComponentLoader: false,
          })
        })
  }

  sortedAddressConfig = (addressConfig) => {
    addressConfig = addressConfig.sort((a, b) => {
      return a.displayOrder - b.displayOrder
    })

    let sortedAddressConfig = addressConfig
    let isUserLandmarkPresent = _.findIndex(sortedAddressConfig, (v) => {
      return v['keyName'] === 'UserLandmark'
    })
    let isLandMarkPresent = _.findIndex(sortedAddressConfig, (v) => {
      return v['keyName'] === 'Landmark'
    })
    if (isUserLandmarkPresent > -1) {
      const swap = (arr, index1, index2) =>
        arr.map((val, idx) => {
          if (idx === index1) return arr[index2]
          if (idx === index2) return arr[index1]
          return val
        })
      const arr = addressConfig
      if (isUserLandmarkPresent == 0) {
        sortedAddressConfig = swap(arr, isUserLandmarkPresent, isLandMarkPresent)
      }
    }
    return sortedAddressConfig
  }

  render() {
    // Do not render anything until all api calls done
    if (_.get(this.props, 'toastsAndLoaders.globalLoaderStatus')) return null
    const isNewPlanSalesFlow =
      _.get(this, 'props.store.selectedBrand.BrandName', '').toUpperCase() !== 'APPLE' &&
      _.get(this, 'props.user.appConfigDetails.nonOtpPlanSale', false)
    let renderdiv
    if (this.props.params.p) {
      switch (this.props.params.p) {
        case 'view':
          renderdiv = (
            <ViewProductStoreComponent
              {...this.props}
              productListBoxData={this.state.productListBoxData}
              CategorySelection={this.handleCategoryListSelection}
              BrandSelection={this.handleBrandListSelection}
              listboxdata={this.state.newDeviceListBoxDataValues}
              ProductSelection={this.handleProductListSelection}
              addNewDeviceHome={this.addNewDeviceHome}
              deviceOverviewState={this.state.deviceOverviewState}
              userDevicePoints={this.state.userDevicePoints}
              userDeviceStateLoader={this.state.userDeviceStateLoader}
              viewSelectedPlan={this.viewSelectedPlan}
              listPlanViewDetails={this.listPlanViewDetails}
              planViewSelectedTab={this.state.planViewSelectedTab}
              getDiscountsByType={this.getDiscountsByType}
              isNewPlanSalesFlow={this.state.isNewPlanSalesFlow || isNewPlanSalesFlow}
            />
          )
          break
        case 'buy':
          renderdiv = (
            <BuyStoreMainComponent
              {...this.props}
              getBrandDetails={this.getBrandDetails}
              selectedItems={this.state.selectedItems}
              handlePlanOptionsSelection={this.handlePlanOptionsSelection}
              eligibilityStatus={this.state.eligibilityStatus}
              eligibilityMessage={this.state.eligibilityMessage}
              showUploadDocsModal={this.showUploadDocsModal}
              handleUploadDocs={this.handleUploadDocs}
              closeDeviceDocsUploadModal={this.closeDeviceDocsUploadModal}
              deviceDocs={this.props.user.storeDocs}
              docsBtnLoader={this.state.docsBtnLoader}
              showDeviceDocsUploadModal={this.state.showDeviceDocsUploadModal}
              eligiblityCheckValue={this.state.eligiblityCheckValue}
              checkPlanEligibility={this.checkPlanEligibility}
              checkPlanPurchaseEligibility={this.checkPlanPurchaseEligibility}
              eligibilityValueOnChange={this.eligibilityValueOnChange}
              eligibilityLoader={this.state.eligibilityLoader}
              viewSelectedPlan={this.viewSelectedPlan}
              handleStoreItemSelection={this.handleStoreItemSelection}
              showEligibilityStatus={this.state.showEligibilityStatus}
              buySelectedPlan={this.buySelectedPlan}
              handleSecondaryDOP={this.handleSecondaryDOP}
              secondaryDOPValue={this.state.secondaryDOPValue}
              handleDatePickerOnChange={this.handleDatePickerOnChange}
              handleOnProductPurchaseCostChange={this.handleOnProductPurchaseCostChange}
              handleDeviceModelChange={this.handleDeviceModelChange}
              productPurchaseCost={this.state.productPurchaseCost}
              selectedDeviceModel={this.state.selectedDeviceModel}
              getEligibilityFieldType={this.getEligibilityFieldType}
              hasPriceAvailability={this.state.hasPriceAvailability}
              getValidationTypeLength={this.getValidationTypeLength}
              resetPlanEligibilityChecks={this.resetPlanEligibilityChecks}
              showDisclaimerModal={this.state.showDisclaimerModal}
              closeDisclaimerModal={this.closeDisclaimerModal}
              showDateOfPruchase={this.state.showDateOfPruchase}
              showProductPurchaseCost={this.state.showProductPurchaseCost}
              disableIMEI={this.state.disableIMEI}
              disableDeviceModel={this.state.disableDeviceModel}
              showDOB={this.state.showDOB}
              disableDOB={this.state.disableDOB}
              dateOfBirth={this.state.dateOfBirth}
              dateOfBirthVerified={this.state.dateOfBirthVerified}
              handleDOBOnChange={this.handleDOBOnChange}
              showPaymentDeclPopup={this.state.showPaymentDeclPopup}
              paymentDeclConsentShown={this.state.paymentDeclConsentShown}
              paymentDeclConsentText={this.state.paymentDeclConsentText}
              openConsentsPopup={this.openConsentsPopup}
              closeConsentsPopup={this.closeConsentsPopup}
              showInvalidDOBModal={this.state.showInvalidDOBModal}
              dobInvalidMessage={this.state.dobInvalidMessage}
              setDOBInvalidModal={this.setDOBInvalidModal}
              pickPlanPrice={this.pickPlanPrice}
              pickEMIPlanPrice={this.pickEMIPlanPrice}
              getDiscountsByType={this.getDiscountsByType}
              isNewPlanSalesFlow={this.state.isNewPlanSalesFlow || isNewPlanSalesFlow}
              showIMEILoader={this.state.showIMEILoader}
              isAddressRequiredForPlanPurchase={this.isAddressRequiredForPlanPurchase}
              handleStateSelection={this.handleStateSelection}
              selectedState={this.state.selectedState}
              initialLoggedInState={this.state.initialLoggedInState}
            />
          )
          break
        case 'checkout':
          renderdiv = (
            <CheckoutStoreMainComponent
              {...this.props}
              handleEstimatePlanSelection={this.handleEstimatePlanSelection}
              resetCheckoutComponent={this.resetCheckoutComponent}
              handleCheckoutNavigation={this.handleCheckoutNavigation}
              validateRequiredPaymentParams={this.validateRequiredPaymentParams}
              storeCheckoutWidgetLoader={this.state.storeCheckoutWidgetLoader}
              handleStateSelection={this.handleStateSelection}
              selectedState={this.state.selectedState}
              Zipcode={this.state.Zipcode}
              handleZipcode={this.handleZipcode}
              eligibilityLoader={this.state.eligibilityLoader}
              getBrandDetails={this.getBrandDetails}
              isPaymentModeSelected={this.state.isPaymentModeSelected}
              showPaymentMode={this.state.showPaymentMode}
              showAddress={this.state.showAddress}
              setShowPaymentMode={(show) => {
                this.setState({ showPaymentMode: show })
              }}
              setShowAddress={this.setShowAddress}
              handleAddressClick={this.handleAddressClick}
              handleTypeaheadSelect={this.handleTypeaheadSelect}
              cancel={this.cancelEditOrCreate}
              onChangeTypeahead={this.onChangeTypeahead}
              onChangeAddressInput={this.onChangeAddressInput}
              onChangeStateSelection={this.onChangeStateSelection}
              handleDistrictSelection={this.handleDistrictSelection}
              toggleAddressPillSelection={this.toggleAddressPillSelection}
              saveAddress={this.saveAddress}
              toggleConfirmationPopUp={this.toggleConfirmationPopUp}
              sortedAddressConfig={this.sortedAddressConfig}
              isCreate={this.state.isCreate}
              locations={this.state.locations}
              selectedDistrict={this.state.selectedDistrict}
              pincodeLengthAr={this.state.pincodeLengthAr}
              addressComponentLoader={this.state.addressComponentLoader}
              allowSave={this.state.locationObtained}
              newAddressDetails={this.state.newAddressDetails}
              landmark={this.state.landmark}
              placesAutocompleteOptions={this.state.placesAutocompleteOptions}
              addressTypesList={this.state.addressTypesList}
              isStateInputDisabled={this.state.isStateInputDisabled}
              isCityInputDisabled={this.state.isCityInputDisabled}
              taxErrors={this.state.taxErrors}
              taxAddressId={this.state.taxAddressId}
              isAddAddress={this.state.isAddAddress}
              isEditAddress={this.state.isEditAddress}
              requiredDetails={this.state.requiredDetails}
              setTaxErrors={this.setTaxErrors}
              setIsEditAddress={this.setIsEditAddress}
              setIsAddAddress={this.setIsAddAddress}
              setTaxAddressId={this.setTaxAddressId}
              setRequiredDetails={this.setRequiredDetails}
              isPincodeInputDisabled={this.state.isPincodeInputDisabled}
              showDiscountDetails={this.state.showDiscountDetails}
              setShowDiscountDetails={(show) => {
                this.setState({ showDiscountDetails: show })
              }}
              isValidateLoading={this.state.isValidateLoading}
              checkForDiscountAndRedirect={this.checkForDiscountAndRedirect}
              redirectToPaymentOptionSelection={this.redirectToPaymentOptionSelection}
              pickPlanPrice={this.pickPlanPrice}
              pickEMIPlanPrice={this.pickEMIPlanPrice}
              getDiscountsByType={this.getDiscountsByType}
              checkIfPaymentRequiredHandling={this.checkIfPaymentRequiredHandling}
              isNewPlanSalesFlow={isNewPlanSalesFlow || this.state.isNewPlanSalesFlow}
              checkPlanEligibility={this.checkPlanEligibility}
              isAddressRequiredForPlanPurchase={this.isAddressRequiredForPlanPurchase}
              reviewScreenGtmEventSent={this.state.reviewScreenGtmEventSent}
              setReviewScreenGtmEventSent={(val) => {
                this.setState({ reviewScreenGtmEventSent: val })
              }}
            />
          )
          break
        case 'signin':
        case 'review':
          renderdiv = (
            <ReviewPurchasePlanComponent
              {...this.props}
              navPath={this.state.renderComponent}
              enableLoader={this.state.enableLoader}
              addPurchasedPlanToaDevice={this.addPurchasedPlanToaDevice}
              addPlanToaDevice={this.addPlanToaDevice}
              getBrandDetails={this.getBrandDetails}
              eligibilityStatus={this.state.eligibilityStatus}
              checkIfPaymentRequiredHandling={this.checkIfPaymentRequiredHandling}
              showDisclaimerModal={this.state.showDisclaimerModal}
              closeDisclaimerModal={this.closeDisclaimerModal}
              showUploadDocsModal={this.showUploadDocsModal}
              handleUploadDocs={this.handleUploadDocs}
              closeDeviceDocsUploadModal={this.closeDeviceDocsUploadModal}
              deviceDocs={this.props.user.storeDocs}
              docsBtnLoader={this.state.docsBtnLoader}
              showDeviceDocsUploadModal={this.state.showDeviceDocsUploadModal}
              setStoreDocs={this.props.setStoreDocs}
              dateOfBirth={this.state.dateOfBirth}
              checkPlanEligibility={this.checkPlanEligibility}
              eligiblityCheckValue={this.state.eligiblityCheckValue}
              pickPlanPrice={this.pickPlanPrice}
              pickEMIPlanPrice={this.pickEMIPlanPrice}
              getDiscountsByType={this.getDiscountsByType}
            />
          )
          break
        case 'customized-plan':
          renderdiv = (
            <CustomizedPlanComponent
              {...this.props}
              resetPlanEligibilityChecks={this.resetPlanEligibilityChecks}
              customPlanImeiDisabled={this.state.customPlanImeiDisabled}
              customPlanDOPStatus={this.state.customPlanDOPStatus}
              customPlanPriceDisabled={this.state.customPlanPriceDisabled}
              showProductPriceInput={this.state.showProductPriceInput}
              buySelectedPlan={this.buySelectedPlan}
              customPlanIMEIError={this.state.customPlanIMEIError}
              showCustomPlanEligibilityStatus={this.state.showCustomPlanEligibilityStatus}
              isCustomPlanEligible={this.state.isCustomPlanEligible}
              isDopAvailable={this.state.isDopAvailable}
              isProductPurchasePriceAvailable={this.state.isProductPurchasePriceAvailable}
              CustomPlanDevicePrice={this.state.CustomPlanDevicePrice}
              CustomPlanIMEIValue={this.state.CustomPlanIMEIValue}
              showCustomQuestions={this.state.showCustomQuestions}
              customPlanDOPValue={this.state.customPlanDOPValue}
              showDOP={this.state.showDOP}
              checkCustomizedPlanEligibilityCheck={this.checkCustomizedPlanEligibilityCheck}
              handleCustomPlanEligibilityOnChange={this.handleCustomPlanEligibilityOnChange}
            />
          )
          break
        default:
          browserHistory.replace('/pagenotfound')
      }
    } else {
      renderdiv =
        _.get(this, 'props.store.availableBrandsList', []).length > 1 ? (
          <StoreLandingPage
            {...this.props}
            productListBoxData={this.state.productListBoxData}
            userDeviceStateLoader={this.state.userDeviceStateLoader}
            handleBannerNav={this.handleBannerNav}
            handleStoreItemSelection={this.handleStoreItemSelection}
            userDevicePoints={this.state.userDevicePoints}
            CategorySelection={this.handleCategoryListSelection}
            BrandSelection={this.handleBrandListSelection}
            listboxdata={this.state.newDeviceListBoxDataValues}
            ProductSelection={this.handleProductListSelection}
            addNewDeviceHome={this.addNewDeviceHome}
            loadLandingPageBanner={this.loadLandingPageBanner}
            deviceOverviewState={this.state.deviceOverviewState}
            storeBannersList={this.state.storeBannersList}
          />
        ) : null
    }
    return (
      <div className='store-components mb48 mt20'>
        {!this.state.showCountryModal ? (
          renderdiv
        ) : (
          <Modal showModal className='sfy-modal store-country-modal'>
            <Modal.Header>
              <h2 className='mb20 text-center'>{getTranslationText('store', 'countryModalHeader')}</h2>
            </Modal.Header>
            <div className={'close-icon ' + (window.isDirectionRTL ? ' text-left ' : ' text-right')}></div>
            <div className='col-sm-12 mb8'>
              {_.get(this, 'props.user.countries', []).length === 1
                ? getTranslationText('store', 'countryModalText')
                : getTranslationText('store', 'countriesModalText')}
            </div>
            <div className='col-sm-12'>
              <Dropdown
                id='store-dropdown'
                options={
                  this.props.user.appConfigDetails && _.get(this.props, 'user.countries', []).length
                    ? this.props.user.countries.map((countryObj) => ({
                        ...countryObj,
                        label: countryObj.CountryName,
                        value: countryObj.CountryName,
                      }))
                    : []
                }
                handleOnChange={this.handleCountryChange}
                placeholder={getTranslationText('common', 'searchByText')}
                ValueComponent={!_.isEmpty(this.state.selectedCountryObj) && CountryValueComponent}
                OptionsComponent={CountryOptionComponent}
                value={!_.isEmpty(this.state.selectedCountryObj) ? this.state.selectedCountryObj : ''}
                optionLength={_.get(this, 'props.user.countries.length', 0)}
                isSearchable
                searchText={getTranslationText('common', 'searchByText')}
                filterBy='CountryName'
                // isDisabled={this.props.user.countries.length === 1}
              />
            </div>
            <div className='col-sm-12 mt20'>
              <MediumButton className='button-small' buttonText={getTranslationText('common', 'okay')} onClick={this.handleCountryClick} />
            </div>
          </Modal>
        )}
      </div>
    )
  }
}

const CountryValueComponent = (props) => (
  <div className='country-value-container'>
    <img src={props.value.FlagImageURL} alt='' />
    <div className='country-label'>
      <span>{props.value.CountryName}</span>
    </div>
  </div>
)
CountryValueComponent.propTypes = {
  value: PropTypes.object.isRequired,
}

const CountryOptionComponent = (props) => (
  <div className='country-value-container'>
    <img src={props.option.FlagImageURL} alt='' />
    <div className='country-label'>{props.option.CountryName}</div>
  </div>
)
CountryOptionComponent.propTypes = {
  option: PropTypes.object.isRequired,
}

const mapDispatchToProps = {
  getTranslations,
  setDistrictListConfig,
  getUserAddressesAsync,
  getStateListAsync,
  getGeoLocationAsync,
  updateAddressAsync,
  addAddressAsync,
}

export default connect(null, mapDispatchToProps)(Store)

Store.propTypes = {
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
  toastsAndLoaders: PropTypes.object.isRequired,
  getDeviceOverviewAsync: PropTypes.func.isRequired,
  getProductsList: PropTypes.func.isRequired,
  // UserDevicePointsAsync: PropTypes.func.isRequired,
  setToaster: PropTypes.func.isRequired,
  fetchEntityPlanAsync: PropTypes.func.isRequired,
  // getStateListAsync: PropTypes.func.isRequired,
  eligibilityLoader: PropTypes.func,
  setPlanPaymentDetails: PropTypes.func,
  clearExistingPlansRelatedInfo: PropTypes.func.isRequired,
  getPlanEligibilityStatus: PropTypes.func.isRequired,
  checkPurchaseEligibility: PropTypes.func.isRequired,
  getPlanPriceByIdentifiers: PropTypes.func.isRequired,
  selectedPlanDetails: PropTypes.func,
  getPayableAmountAsync: PropTypes.func.isRequired,
  getPayableAmountForNonLoginFlow: PropTypes.func.isRequired,
  storeLastKnowPathName: PropTypes.func.isRequired,
  authenticateProductsAsync: PropTypes.func.isRequired,
  clearexistingPaymentDetails: PropTypes.func.isRequired,
  getSelectedPlanDetailsAsync: PropTypes.func.isRequired,
  setSelectedBrand: PropTypes.func.isRequired,
  setCheckoutParams: PropTypes.func.isRequired,
  validateZipcodeAsync: PropTypes.func.isRequired,
  saveZipcodeOrState: PropTypes.func.isRequired,
  getCountriesAsync: PropTypes.func.isRequired,
  setSelectedCountryData: PropTypes.func.isRequired,
  setBuyPlanStatusAval: PropTypes.func.isRequired,
  fetchBannersAsync: PropTypes.func.isRequired,
  setglobalLoaderStatus: PropTypes.func.isRequired,
  aysncFetchBrandID: PropTypes.func.isRequired,
  addorFetchAsync: PropTypes.func.isRequired,
  addorFetchDeviceAsync: PropTypes.func.isRequired,
  loadAppConfig: PropTypes.func.isRequired,
  getAppLegalConfigs: PropTypes.func.isRequired,
  seteligibleDeviceInfo: PropTypes.func.isRequired,
  fetchStoreBannersAsync: PropTypes.func.isRequired,
  setPurchaseEligibilityInfo: PropTypes.func.isRequired,
  addDeviceAsync: PropTypes.func.isRequired,
  setSelectedPlan: PropTypes.func.isRequired,
  getSignedUrlAsync: PropTypes.func.isRequired,
  addProductDocAsync: PropTypes.func.isRequired,
  getProductDocsAsync: PropTypes.func.isRequired,
  setStoreDocs: PropTypes.func.isRequired,
  sendotpreport: PropTypes.func.isRequired,
  getUserDetailsAsync: PropTypes.func.isRequired,
  fetchPlanQuestionsAsync: PropTypes.func,
  setPriorityQueueQuestion: PropTypes.func,
  setDependencyQueueQuestion: PropTypes.func,
  addCustomPlanProductPrice: PropTypes.func,
  addCustomPlanDOP: PropTypes.func,
  getEligiblePlans: PropTypes.func.isRequired,
  setTncLink: PropTypes.func.isRequired,
  setPrivacyLink: PropTypes.func.isRequired,
  setDataConsentLink: PropTypes.func.isRequired,
  generateRedirectionUrl: PropTypes.func.isRequired,
  fetchIPIDAsync: PropTypes.func.isRequired,
  fetchPlanProductsAsync: PropTypes.func.isRequired,
  updateDevicesAsync: PropTypes.func.isRequired,
  setFetchedPlanPriceByProduct: PropTypes.func.isRequired,
  checkIfPaymentRequiredAsync: PropTypes.func,
  purchasePlanNoPaymentAsync: PropTypes.func,
  setPaymentOnActivation: PropTypes.func,
  setPurchasePlanNoPayment: PropTypes.func,
  setExternalOriginSource: PropTypes.func,
  setImeiFromToken: PropTypes.func,
}
