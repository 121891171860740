import { connect } from 'react-redux'
import {
  getGeoLocationAsync,
  getBrowserLocationAsync,
  setSelectedDevice,
  getRequestModesAsync,
  raiseNewClaimAsync,
  getRequestSlotsAsync,
  setPartnerServiceLocation,
  setSelectedMode,
  setDateAndTimeAsync,
  setSelectedDamageType,
  setAddressFlgToStore,
  clearDeviceInfo,
  getRequestIssuesForTypeAsync,
  clearRequestState,
  setIssues,
  clearFullState,
  scheduleClaimRepairAsync,
  setRequestModes,
  setUserRequestLocation,
  nonRepairRequestAsync,
  clearDateTimeSlots,
  scheduleNonClaimRepairAsync,
  pickUpRequestAsync,
  carryInRequestAsync,
  fetchPickupInstructionsAsync,
  setLogisticPartner,
  setRequestFlowSource,
  addToVisitedUrl,
  clearUrlList,
  setClaimDetails,
  setInvoiceDocVisibilityState,
  uploadAdditionalDocsAsync,
  setAdditionalUploadData,
  asyncClaimEligibility,
  updateServiceLocationAsync,
  setRequestSlots,
  setActivationPlan,
  activatePlanAsync,
  fetchEligiblePlansNewAsync,
  getTypeOfDamageAsync,
  setSelectedOrder,
  removeVisitedUrl,
  fetchReturnReasons,
  getHandlingCharges,
  setHandlingCharges,
  doortoDoorReplacementAsync,
  createRequestAsync,
  getServiceTypesList,
  routeServiceRequestAsync,
  getConsumerPlanDetailsAsync,
  checkClaimStatusAsync,
  setClaimEligibility,
  checkDecisionMatrixAsync,
  setDecisionMatrixDetails,
  getServicetypeDetailsAsync,
  getAdminFeeDetails,
  getPayoutModesAsync,
  setUserBuyOutDetails,
  setAddressPreviewDetails,
  checkForHighRiskRequestAsync,
  uploadAdditionalDocsToDSAsync,
  getEmptyBoxServiceAvailabilityAsync,
  getAEWithoutDefectiveServiceAvailability,
  clearClaimFormValues,
  validateAddressAsync,
  clearAddressValidationDetails,
  getReplacementOptionsAsync,
  setReplacementOption,
  setReplacementMatrixDetails,
  setAudioUploadData,
  setPlaceOfDamageLocation,
  getSameDayReplacementOptionsAsync,
  loggerActionAsync,
  getServiceAvailabilityResolutionRateAsync,
  updateServiceAvailabilityAsync,
  getPaymentActionsAsync,
  setConsentCoverageConfig,
} from '../../../store/request'
import {
  getSignedUrlAsync,
  getUserAddressesAsync,
  addAddressAsync,
  updateAddressAsync,
  getNotificationsAsync,
  updateUserDetailsAsync,
  getStateListAsync,
  getCountriesAsync,
  unsetSignedUrl,
  getDistrictListAsync,
  getClaimLegalConfigs,
  setCurrentUserLocation,
  setClaimLegalConfigs,
  addLargeFileToDSAsync,
  uploadLargeFileToS3,
  acknowledgeUploadDocToDSAsync,
  getUploadDocToDSAsync,
  setDSFileUploadDetails,
} from '../../../store/user'
import { storeLastKnowPathName } from '../../../store/location'
import {
  addProductDocAsync,
  getProductDocsAsync,
  getProductsList,
  addDeviceAsync,
  getPaginatedDevicesAsync,
  authenticateProductsAsync,
  updateDevicesAsync,
  deleteDeviceProductDocs,
  getProductsByBrand,
  setProductBrandConfig,
} from '../../../store/devices'
import { storeReplacementOptions, setTrackHoldPaymentStatus } from '../../../store/track'
import { setToaster, setglobalLoaderStatus } from '../../../store/toastsAndLoaders'
import {
  advancePayAsync,
  setPaymentRequestStatus,
  clearPaymentDetails,
  getAdvancePaymentDetails,
  unsetChargePaymentId,
} from '../../../store/payment'

import Request from '../components/Request'

const mapDispatchToProps = {
  getGeoLocationAsync,
  getBrowserLocationAsync,
  setSelectedDevice: (obj) => setSelectedDevice(obj),
  setToaster,
  getRequestModesAsync,
  raiseNewClaimAsync,
  getRequestSlotsAsync,
  advancePayAsync,
  getAdvancePaymentDetails,
  setPartnerServiceLocation: (obj) => setPartnerServiceLocation(obj),
  setSelectedMode: (obj) => setSelectedMode(obj),
  setDateAndTimeAsync,
  setSelectedDamageType: (obj) => setSelectedDamageType(obj),
  clearSelectedDamageType: () => setSelectedDamageType({}),
  getRequestIssuesForTypeAsync,
  clearRequestState: () => clearRequestState(),
  setIssues: (obj) => setIssues(obj),
  scheduleClaimRepairAsync,
  clearRequestModes: () => setRequestModes({}),
  clearUserLocation: (key = 'userLocation', address = '') =>
    setUserRequestLocation({
      key,
      value: {
        landmark: address,
        pincode: '',
        latitude: '',
        longitude: '',
      },
    }),
  scheduleNonClaimRepairAsync,
  getSignedUrlAsync,
  addProductDocAsync,
  getProductDocsAsync,
  getProductsList,
  addDeviceAsync,
  getPaginatedDevicesAsync,
  setUserRequestLocation: (obj) => setUserRequestLocation(obj),
  getUserAddressesAsync,
  addAddressAsync,
  pickUpRequestAsync,
  carryInRequestAsync,
  nonRepairRequestAsync,
  clearFullState: () => clearFullState({}),
  setAddressFlgToStore: (obj) => setAddressFlgToStore(obj),
  //scheduleRequestEWAsync,
  clearDateTimeSlots: () => clearDateTimeSlots({}),
  clearDeviceInfo: () => clearDeviceInfo({}),
  // scheduleRequestInstallDemoAsync,
  authenticateProductsAsync,
  getNotificationsAsync,
  setLogisticPartner: (obj) => setLogisticPartner(obj),
  updateDevicesAsync,
  fetchPickupInstructionsAsync,
  setRequestFlowSource: (obj) => setRequestFlowSource(obj),
  addToVisitedUrl: (obj) => addToVisitedUrl(obj),
  clearUrlList: () => clearUrlList(),
  setInvoiceDocVisibilityState: (obj) => setInvoiceDocVisibilityState(obj),
  uploadAdditionalDocsAsync,
  asyncClaimEligibility,
  setAdditionalUploadData: (obj) => setAdditionalUploadData(obj),
  setAudioUploadData,
  setClaimDetails: (obj) => setClaimDetails(obj),
  updateServiceLocationAsync,
  getProductsByBrand,
  setProductBrandConfig,
  setRequestSlots: (obj) => setRequestSlots(obj),
  updateUserDetailsAsync,
  setActivationPlan: (obj) => setActivationPlan(obj),
  activatePlanAsync,
  setglobalLoaderStatus: (bool) => setglobalLoaderStatus(bool),
  storeLastKnowPathName: (obj) => storeLastKnowPathName(obj),
  fetchEligiblePlansNewAsync,
  deleteDeviceProductDocs,
  getTypeOfDamageAsync,
  setPaymentRequestStatus,
  setSelectedOrder: (obj) => setSelectedOrder(obj),
  removeVisitedUrl,
  fetchReturnReasons,
  getHandlingCharges,
  setHandlingCharges: (obj) => setHandlingCharges(obj),
  doortoDoorReplacementAsync,
  createRequestAsync,
  getServiceTypesList,
  routeServiceRequestAsync,
  getConsumerPlanDetailsAsync,
  checkClaimStatusAsync,
  setClaimEligibility,
  clearPaymentDetails,
  getStateListAsync,
  getCountriesAsync,
  updateAddressAsync,
  checkDecisionMatrixAsync,
  setDecisionMatrixDetails,
  unsetSignedUrl,
  getServicetypeDetailsAsync,
  getAdminFeeDetails,
  getPayoutModesAsync,
  setUserBuyOutDetails,
  setAddressPreviewDetails,
  storeReplacementOptions,
  checkForHighRiskRequestAsync,
  getDistrictListAsync,
  getClaimLegalConfigs,
  unsetChargePaymentId,
  uploadAdditionalDocsToDSAsync,
  getEmptyBoxServiceAvailabilityAsync,
  getAEWithoutDefectiveServiceAvailability,
  clearClaimFormValues,
  validateAddressAsync,
  clearAddressValidationDetails,
  getReplacementOptionsAsync,
  setReplacementOption,
  setReplacementMatrixDetails,
  setPlaceOfDamageLocation,
  getSameDayReplacementOptionsAsync,
  setCurrentUserLocation,
  loggerActionAsync,
  getServiceAvailabilityResolutionRateAsync,
  setTrackHoldPaymentStatus,
  setClaimLegalConfigs,
  updateServiceAvailabilityAsync,
  addLargeFileToDSAsync,
  uploadLargeFileToS3,
  acknowledgeUploadDocToDSAsync,
  getUploadDocToDSAsync,
  setDSFileUploadDetails,
  getPaymentActionsAsync,
  setConsentCoverageConfig,
}

const mapStateToProps = (state) => ({
  user: state.user,
  track: state.track,
  request: state.request,
  devices: state.devices,
  payment: state.payment,
  translationData: state.languageDetail.translationText,
})

export default connect(mapStateToProps, mapDispatchToProps)(Request)
