// We only need to import the modules necessary for initial render
import CoreLayout from '../layouts/PageLayout'
import Home from './Home'
import ProfileRoute from './Profile'
import DevicesRoute from './Devices'
import TrackRoute from './Track'
import Login from './Login'
import Messages from './Messages'
import Request from './Request'
import ServiceEstimator from './ServiceEstimator'
import Store from './Store'
import { Subscription, SubscriptionDetails, SubscriptionReview, SubscriptionSuccess } from './Subscription'
// import { Subscription } from './Subscription'
import MyPlans from './MyPlans'
import Payment from './Payment'
import EmptyRoute from './EmptyRoute'
import PurchaseHistory from './PurchaseHistory'
import { AboutUsRoute, TermsAndConditionsRoute, PrivacyPolicyRoute, OnlineConsentRoute } from './CustomRoute'
import AccountLinkComponent from './AccountLink'
import GuestRoute from './GuestRoute'
import FAQ from './FAQ'
import Vouchers from './Vouchers'
/*  Note: Instead of using JSX, we recommend using react-router
    PlainRoute objects to build route definitions.   */

export const createRoutes = () => ({
  path: '/',
  component: CoreLayout,
  indexRoute: Home,
  childRoutes: [
    Login,
    ProfileRoute,
    DevicesRoute,
    Messages,
    TrackRoute,
    Request,
    ServiceEstimator,
    Store,
    SubscriptionReview,
    SubscriptionSuccess,
    SubscriptionDetails,
    Subscription,
    MyPlans,
    Payment,
    PurchaseHistory,
    AboutUsRoute,
    TermsAndConditionsRoute,
    PrivacyPolicyRoute,
    OnlineConsentRoute,
    AccountLinkComponent,
    GuestRoute,
    FAQ,
    Vouchers,
    EmptyRoute,
  ],
})

/*  Note: childRoutes can be chunked or otherwise loaded programmatically
    using getChildRoutes with the following signature:

    getChildRoutes (location, cb) {
      require.ensure([], (require) => {
        cb(null, [
          // Remove imports!
          require('./Counter').default(store)
        ])
      })
    }

    However, this is not necessary for code-splitting! It simply provides
    an API for async route definitions. Your code splitting should occur
    inside the route `getComponent` function, since it is only invoked
    when the route exists and matches.
*/

export default createRoutes
