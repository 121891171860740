import React, { Component } from 'react'
import './GuestRoute.scss'
import { Accordion } from '../../components/UIComponents'

export default class FAQComponent extends Component {
  constructor() {
    super()
    this.state = {
      faq: {
        plan: [
          {
            title: `1. What does Care+ with Theft and Loss, Care+, Care+ Essentials, and NY only Insurance cover?`,
            body: `<p class='ml8'>Care+ with Theft and Loss covers, Lost, Stolen, Accidental Damage, and Mechanical Breakdown.  Care+ covers Accidental Breakdown and Mechanical Breakdown.  Care+ Essentials covers Mechanical Breakdown, NY only Insurance covers Lost and Stolen.  Please see <a href='https://us.servify.tech/termsandconditions/samsung/samsung-b2c' target='_blank'>Terms and Conditions</a> for details.</p>`,
          },
          {
            title: '2. How do I enroll in Care+ with Theft and Loss, Care+, Care+ Essentials, and NY only Insurance?',
            body: `<p class='ml8'>
            All eligible mobile devices can be enrolled on Samsung.com at time of device purchase, or within 60 days of device purchase at <a href='http://samsungcareplus.com/' target='_blank'>Samsungcareplus.com</a></p>`,
          },
          {
            title: '3. If I buy a new device will my current Care+ coverage carry over?',
            body: `<p class='ml8'>No, you must enroll your new device in one of the Care+ products on Samsung.com at time of device purchase. Also, remember to cancel Care+ coverage on your previous device if you do not wish to continue protecting it.</p>`,
          },
          {
            title: '4. What is the cost of Samsung Care+?',
            body: `<p class='ml8'>The cost varies depending on your device. The payment can either be paid up front for a two-year term or it is automatically billed on a monthly basis to the credit card you provide at time of enrollment for three years unless you cancel coverage, or it is canceled for one of the specified reasons stated in the terms and conditions. Please see your program’s <a href='https://us.servify.tech/termsandconditions/samsung/samsung-b2c' target='_blank'>Terms and Conditions</a> for details.</p>`,
          },
          {
            title: '5. What is the service fee for a claim?',
            body: `<p class='ml8'>Samsung Care+ is a service contract that covers mechanical breakdowns and accidental damage from handling with a low service fee for approved claims. The service fee varies depending on the claim. There is no service fee for mechanical breakdowns claims. Please see your program’s <a href='https://us.servify.tech/termsandconditions/samsung/samsung-b2c' target='_blank'>Terms and Conditions</a> for details.</p>`,
          },
          {
            title: '6. Is there a limit to the number of claims I can make?',
            body: `<p class='ml8'>
            <ul>
              <li>Care+ with Theft and Loss and Care+ include unlimited repairs for accidental damage from handling.  There is a maximum of three (3) claims in a 12-month period for Lost or Stolen claims.</li>
              <li>There are no repair limits for mechanical breakdowns.</li>
              <li>Please see your program’s <a href='https://us.servify.tech/termsandconditions/samsung/samsung-b2c' target='_blank'>Terms and Conditions</a> for details.</li>
            </ul>
            </p>`,
          },
          {
            title: '7. What is the length of coverage for my mobile device?',
            body: `<p class='ml8'>Samsung Care+ with Theft and Loss, Care+, and Theft and Loss Coverage only in NY only offer a monthly payment option for 3 years of coverage and a pay up from option with 2 years of coverage.  Care+ Essentials offers a pay up front option with 2 years of coverage.  Please see your program’s <a href='https://us.servify.tech/termsandconditions/samsung/samsung-b2c' target='_blank'>Terms and Conditions</a> for details.</p>`,
          },
          {
            title: '8. When does coverage begin?',
            body: `<p class='ml8'>With the exception of mechanical breakdowns coverage, your coverage begins immediately after purchase. After the Samsung manufacturer warranty expires, coverage for mechanical breakdowns begins. Please see your program’s <a href='https://us.servify.tech/termsandconditions/samsung/samsung-b2c' target='_blank'>Terms and Conditions</a> for details.</p>`,
          },
          {
            title: '9. I don’t live in one of the 50 United States. Can I still purchase Samsung Care+ for my mobile device? ',
            body: `<p class='ml8'>Samsung Care+ protection products are available to customers who live in the continental United States, Hawaii, Alaska, and Washington D.C.</p>`,
          },
          {
            title: '10. Can I cancel my Care+ protection product?',
            body: `<p class='ml8'>You may cancel any time by calling Samsung at 1-866-371-9501, 24 hours a day, 7 days a week. You can also login to your Samsung account on Samsung.com, click on "My account," and then "My Subscriptions." Please see your program’s <a href='https://us.servify.tech/termsandconditions/samsung/samsung-b2c' target='_blank'>Terms and Conditions</a> for details.</p>`,
          },
          {
            title: '11. How can I access 24/7 Expert Support for my Samsung device?',
            body: `<p class='ml8'>Visit us at www. <a href='https://samsung-us.servify.tech/mycare' target='_blank'>https://samsung-us.servify.tech/mycare,</a> or call 1-833-690-0918.</p>`,
          },
          {
            title: '12. How do I file a claim for my Samsung device?',
            body: `<p class='ml8'>Visit us at www. <a href='https://samsung-us.servify.tech/mycare' target='_blank'>https://samsung-us.servify.tech/mycare,</a> or call 1-833-690-0918.</p>`,
          },
          {
            title: '13. If my claim is approved, how soon can I expect to receive my replacement device?',
            body: `<p class='ml8'>In most cases, replacement devices are shipped for next business day delivery.</p>`,
          },
          {
            title: '14. Do I have to return my claimed device?',
            body: `<p class='ml8'>Yes. With the exception of lost or stolen claims, once you receive your replacement device and transfer your data, you must return the old/claimed device within 10 days using the provided packaging. If the old/claimed device is not returned within 10 days, you will be charged an non-return fee, not to exceed manufacturer’s retail price. Please see your program’s <a href='https://us.servify.tech/termsandconditions/samsung/samsung-b2c' target='_blank'>Terms and Conditions</a> for details.</p>`,
          },
          {
            title: '15. Is there a wait period before I can file a claim?',
            body: `<p class='ml8'>No, when you purchase a Care+ protection product your coverage begins immediately. Claims for eligible events that occurred after your coverage effective date may be filed immediately. Please see your program’s <a href='https://us.servify.tech/termsandconditions/samsung/samsung-b2c' target='_blank'>Terms and Conditions</a> for details.</p>`,
          },
          {
            title: '16. What is not covered under Care+ with Theft and Loss, Care+, Care+ Essentials, and NY only Insurance cover?',
            body: `<p class='ml8'>Please refer to the <a href='https://us.servify.tech/termsandconditions/samsung/samsung-b2c' target='_blank'>Terms and Conditions</a> for a complete list of limitations and exclusions.</p>`,
          },
        ],
      },
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    let { faq } = this.state
    return (
      <>
        <div className='col-sm-12 col-lg-12'>
          <div className='full-width faq'>
            <Accordion titleClassName='bookFontStyle fontLarge' subtextClassName='fontMedium' data={faq.plan} />
          </div>
        </div>
      </>
    )
  }
}
