import React from 'react'
import PropTypes from 'prop-types'
import themedVariable from '../../../styles/_base.scss'
import './Headings.scss'

const headingWithStyle = (style) => {
  const Heading = (props) => {
    return (
      <div className={'heading-basic ' + (props.className || '')} style={style}>
        <span>{props.children}</span>
      </div>
    )
  }

  Heading.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
  }

  return Heading
}

export const BoldHeading = headingWithStyle({
  fontFamily: themedVariable.mediumFontFamily,
  fontSize: '16px',
})

export const LightHeading = headingWithStyle({
  fontFamily: themedVariable.lightFontFamily,
  fontSize: '16px',
})

export default {
  BoldHeading,
  LightHeading,
}
