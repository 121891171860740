import CustomRoute from './components/CustomRoute'

export const AboutUsRoute = {
  path: 'about-us',
  component: CustomRoute,
}

export const TermsAndConditionsRoute = {
  path: 'terms-conditions',
  component: CustomRoute,
}

export const PrivacyPolicyRoute = {
  path: 'privacy-policy',
  component: CustomRoute,
}

export const OnlineConsentRoute = {
  path: 'online-consent',
  component: CustomRoute,
}
