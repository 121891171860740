import React, { useState, useEffect } from 'react'
import './TaxAddressForm.scss'
import { Input, Button, Dropdown } from 'sfy-react'
import { getTranslationText } from '../../../utils/languageTranslation'
import AddressBox from '../../AddressBox'
import { sortAddressConfig } from '../../../utils/functions'
import { PillGroup } from '../index'

const defHomeIcon = '/commonAssets/DefAddressTypeBtnIcons/Home.png'
const defOfficeIcon = '/commonAssets/DefAddressTypeBtnIcons/Office.png'
const defOtherIcon = '/commonAssets/DefAddressTypeBtnIcons/Other.png'
const activeHomeIcon = `/${appName}/images/AddressTypeBtnIcons/Home.png`
const activeOfficeIcon = `/${appName}/images/AddressTypeBtnIcons/Office.png`
const activeOtherIcon = `/${appName}/images/AddressTypeBtnIcons/Other.png`

const TaxAddressForm = ({
  requiredDetails,
  handleOnTaxAddressChange,
  errors,
  user,
  addressConfig,
  handleSaveAddress,
  isEditAddress,
  handleOnSavedAddressesClick,
  userAddresses,
}) => {
  const [isLocationObtained, setIsLocationObtained] = useState(false)
  const [isFieldsDisabled, setIsFieldsDisabled] = useState({ zipcode: false, state: false })
  const [sortedAddressConfig, setSortedAddressConfig] = useState([])
  const [addressTypes, setAddressTypes] = useState([
    {
      text: getTranslationText('AddressType', 'addressTypeHome'),
      deactiveImg: defHomeIcon,
      activeImg: activeHomeIcon,
      isSelected: requiredDetails?.addressType === getTranslationText('AddressType', 'addressTypeHome'),
    },
    {
      text: getTranslationText('AddressType', 'addressTypeOffice'),
      deactiveImg: defOfficeIcon,
      activeImg: activeOfficeIcon,
      isSelected: requiredDetails?.addressType === getTranslationText('AddressType', 'addressTypeOffice'),
    },
    {
      text: getTranslationText('AddressType', 'addressTypeOther'),
      deactiveImg: defOtherIcon,
      activeImg: activeOtherIcon,
      isSelected: requiredDetails?.addressType === getTranslationText('AddressType', 'addressTypeOther'),
    },
  ])

  const handleOnLandmarkChange = (value) => {
    if (errors?.state) {
      setIsFieldsDisabled({ state: false, zipcode: false })
    }
    handleOnTaxAddressChange({ landmark: value })
  }

  useEffect(() => {
    setIsLocationObtained(
      !!requiredDetails?.userlandmark ||
        !!requiredDetails?.zipCode ||
        !!requiredDetails?.state ||
        !!requiredDetails?.city ||
        !!requiredDetails?.address ||
        !!requiredDetails?.lat ||
        !!requiredDetails?.lng
    )
  }, [requiredDetails])

  useEffect(() => {
    setSortedAddressConfig(sortAddressConfig(addressConfig))
  }, [addressConfig])

  useEffect(() => {
    if (isEditAddress && !isLocationObtained) {
      const disableFields = {}
      const isZipcodeValid =
        addressConfig
          .find((config) => config.keyName === 'Zipcode' || config.keyName === 'PinCode')
          ?.length.includes(requiredDetails?.zipCode?.length) || false
      disableFields.zipcode = isZipcodeValid
      disableFields.state = !!requiredDetails?.state
      setIsFieldsDisabled(disableFields)
    }
  }, [requiredDetails, addressConfig, isEditAddress, isLocationObtained])

  const handleLandmarkSelection = (value) => {
    const addrComponents = value.address_components
    let addrObj = isEditAddress
      ? {}
      : {
          zipCode: '',
          userlandmark: '',
          city: '',
          state: '',
          address: '',
        }

    let disableFields = { ...isFieldsDisabled }
    addrComponents.forEach((map) => {
      if (map.types[0] === 'postal_code' && !(isEditAddress && requiredDetails?.zipCode)) {
        addrObj.zipCode = map.short_name
      }
      if (map.types[0] === 'administrative_area_level_1' && !(isEditAddress && requiredDetails?.state)) {
        const state = user.stateAddressListConfig.find((state) => state.StateInitials === map.short_name)?.State || ''
        addrObj.state = state
      } else if (isEditAddress) {
        addrObj.state = userAddresses.find(
          (addr) => addr.ConsumerFavouriteLocationID === requiredDetails?.ConsumerFavouriteLocationID
        )?.State
      }
      if (map.types[0] === 'administrative_area_level_2') {
        addrObj.city = map.long_name
      }
    })

    if (!isEditAddress) {
      let zipcode = addrComponents.find((component) => component.types[0] === 'postal_code')?.short_name
      disableFields.zipcode =
        zipcode &&
        addressConfig.find((config) => config.keyName === 'Zipcode' || config.keyName === 'PinCode')?.length.includes(zipcode.length)

      let state = addrComponents.find((component) => component.types[0] === 'administrative_area_level_1')?.short_name
      state = user.stateAddressListConfig.find((stateConfig) => stateConfig.StateInitials === state)?.State
      disableFields.state = !!state
    }

    setIsFieldsDisabled(disableFields)
    handleOnTaxAddressChange({
      landmark: value.formatted_address,
      lat: value.geometry.location.lat(),
      lng: value.geometry.location.lng(),
      ...addrObj,
    })

    setIsLocationObtained(true)
  }

  const handleOnFieldChange = (e) => {
    handleOnTaxAddressChange({ [e.target.id]: e.target.value })
  }

  const handleStateChange = (item) => {
    handleOnTaxAddressChange({ state: item.value })
  }

  const validateFields = () => {
    let isValid = true
    addressConfig.forEach((config) => {
      if ((config.keyName === 'Zipcode' || config.keyName === 'PinCode') && config.display) {
        let pincodeLengthCheck = config['length'].some((i) => [requiredDetails?.zipCode?.length].includes(i))
        let isPincodeValid = config['mandatory'] ? !!pincodeLengthCheck : requiredDetails?.zipCode?.length ? !!pincodeLengthCheck : true
        isValid = isValid && isPincodeValid
      } else if (config.display) {
        let isFieldValid = config['mandatory'] ? !!requiredDetails?.[config.keyName.toLowerCase()]?.trim()?.length > 0 : true
        isValid = isValid && isFieldValid
      }
    })
    isValid = isValid && requiredDetails?.addressType
    return isValid
  }

  const handleAddressPillSelection = (e) => {
    let addressType = e.currentTarget.id.replace('addressType-', '')
    if (addressType) {
      setAddressTypes([
        ...addressTypes.map((type, index) =>
          parseInt(addressType) === index ? { ...type, isSelected: true } : { ...type, isSelected: false }
        ),
      ])
      handleOnTaxAddressChange({ addressType: addressTypes.find((type, index) => parseInt(addressType) === index).text })
    }
  }

  return (
    <>
      {userAddresses.length > 0 && (
        <Button
          type='button'
          text={getTranslationText('store', 'savedAddresses') + ' >'}
          classes='button-link boldFontStyle mt8 mb8 subscription-link-button'
          handleOnClick={() => {
            handleOnSavedAddressesClick()
          }}
        />
      )}
      <AddressBox
        handleTypeaheadSelect={handleLandmarkSelection}
        onChangeTypeahead={handleOnLandmarkChange}
        user={user}
        placesAutocomplete={{
          options: {
            componentRestrictions: {
              country: _.get(this, 'props.user.selectedCountryData.CountryCode', '').toLowerCase(),
            },
          },
          landmark: requiredDetails?.landmark || '',
        }}
        isLoaderSet={false}
        locationLabel={getTranslationText('addressBox', 'yourLocation')}
      />
      {(isLocationObtained || isEditAddress) && (
        <>
          {sortedAddressConfig &&
            sortedAddressConfig.map((config) =>
              (config.keyName === 'Zipcode' || config.keyName === 'PinCode') && config.display && !errors?.state?.length > 0 ? (
                <>
                  <Input
                    key={config.keyName}
                    type='text'
                    value={requiredDetails?.zipCode}
                    label={`${config.label} ${config.mandatory ? '' : getTranslationText('common', 'optional')}`}
                    handleOnChange={handleOnFieldChange}
                    otherProps={{
                      style: window.isDirectionRTL ? { marginLeft: '5px' } : { marginRight: '5px' },
                      maxLength: Math.max(config.length),
                    }}
                    message={errors?.zipCode}
                    id='zipCode'
                    isDisabled={isFieldsDisabled.zipcode}
                    className={{ label: 'mb8 mt8 fontSmall' }}
                  />
                </>
              ) : config.keyName === 'State' && config.display ? (
                <React.Fragment key={config.keyName}>
                  <label className='mt8 mb8 fontSmall' htmlFor={'state'}>
                    {`${config.label} ${config.mandatory ? '' : getTranslationText('common', 'optional')}`}
                  </label>
                  <Dropdown
                    options={user?.stateAddressListConfig?.map((stateConfig) => ({ ...stateConfig, value: stateConfig.State })) || []}
                    value={requiredDetails?.state}
                    filterBy='value'
                    handleOnChange={handleStateChange}
                    id={'state'}
                    isDisabled={isFieldsDisabled['state']}
                    classObject={{ container: errors?.state?.length > 0 ? 'error-border' : '' }}
                  />
                  <div className={'lightFontStyle fontSmall error-message mt4'}>{errors?.state}</div>
                </React.Fragment>
              ) : !errors?.state?.length > 0 ? (
                config.display &&
                config?.keyName !== 'Landmark' && (
                  <Input
                    key={config?.keyName}
                    type='text'
                    value={requiredDetails?.[config?.keyName?.toLowerCase()]}
                    label={`${config.label} ${config.mandatory ? '' : getTranslationText('common', 'optional')}`}
                    handleOnChange={handleOnFieldChange}
                    id={config?.keyName?.toLowerCase()}
                    className={{ label: 'mb8 mt8 fontSmall tax-address-input' }}
                  />
                )
              ) : null
            )}
          {!errors?.state?.length > 0 && (
            <div className='mt8'>
              <div className='address-label font-small bookFontStyle mb8 overrideCasing'>
                <span>{getTranslationText('profile', 'addressType')}</span>
              </div>
              <div className='pills-holder'>
                <PillGroup prefix='addressType' showImg pills={addressTypes} handlePillSelect={handleAddressPillSelection} />
              </div>
            </div>
          )}

          <div className={'lightFontStyle fontSmall error-message mt4'}>{errors?.cta}</div>
          {!errors.state && (
            <Button
              type='button'
              isDisabled={!validateFields()}
              text={getTranslationText('common', 'saveAddress')}
              classes='button-ghost full-width mt20'
              onClick={handleSaveAddress}
            />
          )}
        </>
      )}
    </>
  )
}

export default TaxAddressForm
