const SET_PLAN_DETAILS = 'SET_PLAN_DETAILS'
const SET_SUBCATEGORIES = 'SET_SUBCATEGORIES'
const SET_ALL_BRANDS = 'SET_ALL_BRANDS'
const SET_BRANDS = 'SET_BRANDS'
const SET_PRODUCTS = 'SET_PRODUCTS'
const CLEAR_FIELDS = 'CLEAR_FIELDS'
const SET_FIELDS = 'SET_FIELDS'
const SET_ALL_FIELDS = 'SET_ALL_FIELDS'
const SET_DEVICE_DETAILS = 'SET_DEVICE_DETAILS'
const REMOVE_DEVICE_DETAILS = 'REMOVE_DEVICE_DETAILS'
const SET_SUBSCRIPTION_DETAILS = 'SET_SUBSCRIPTION_DETAILS'
const SET_CONSUMER_PRODUCT_DETAILS = 'SET_CONSUMER_PRODUCT_DETAILS'
const SET_REQUIRED_DETAILS = 'SET_REQUIRED_DETAILS'
const SET_ERRORS = 'SET_ERRORS'
const SET_IS_SUBSCRIBED = 'SET_IS_SUBSCRIBED'
const SET_ENROLLMENT_PLAN_LIST = 'SET_ENROLLMENT_PLAN_LIST'

export const setPlanDetails = (payload) => {
  return {
    type: SET_PLAN_DETAILS,
    payload,
  }
}

export const setSubcategories = (payload) => {
  return {
    type: SET_SUBCATEGORIES,
    payload,
  }
}

export const setAllBrands = (payload) => {
  return {
    type: SET_ALL_BRANDS,
    payload,
  }
}

export const setBrands = (payload) => {
  return {
    type: SET_BRANDS,
    payload,
  }
}

export const setProducts = (payload) => {
  return {
    type: SET_PRODUCTS,
    payload,
  }
}

export const setFields = (payload) => {
  return {
    type: SET_FIELDS,
    payload,
  }
}

export const setAllFields = (payload) => {
  return {
    type: SET_ALL_FIELDS,
    payload,
  }
}

export const clearFields = (payload) => {
  return {
    type: CLEAR_FIELDS,
    payload,
  }
}

export const setDeviceDetails = (payload) => {
  return {
    type: SET_DEVICE_DETAILS,
    payload,
  }
}

export const removeDeviceDetails = (payload) => {
  return {
    type: REMOVE_DEVICE_DETAILS,
    payload,
  }
}

export const setSubscriptionDetails = (payload) => {
  return {
    type: SET_SUBSCRIPTION_DETAILS,
    payload,
  }
}

export const setConsumerProductDetails = (payload) => {
  return {
    type: SET_CONSUMER_PRODUCT_DETAILS,
    payload,
  }
}

export const setRequiredDetails = (payload) => {
  return {
    type: SET_REQUIRED_DETAILS,
    payload,
  }
}

export const setErrors = (payload) => {
  return {
    type: SET_ERRORS,
    payload,
  }
}

export const setIsSubscribed = (payload) => {
  return {
    type: SET_IS_SUBSCRIBED,
    payload,
  }
}

export const setEnrollmentPlanList = (payload) => {
  return {
    type: SET_ENROLLMENT_PLAN_LIST,
    payload,
  }
}

const initialState = {
  fields: [],
  deviceDetails: [],
}

const subscription = (state = initialState, action) => {
  switch (action.type) {
    case SET_PLAN_DETAILS:
      return { ...state, planDetails: action.payload }
    case SET_SUBCATEGORIES:
      return { ...state, subcategories: action.payload }
    case SET_ALL_BRANDS:
      return { ...state, allBrands: action.payload }
    case SET_BRANDS:
      return { ...state, brands: action.payload }
    case SET_PRODUCTS:
      return { ...state, products: action.payload }
    case CLEAR_FIELDS:
      return { ...state, fields: [], deviceDetails: [], errors: {} }
    case SET_FIELDS: {
      let fields = [...state.fields]
      let deviceDetails = [...state.deviceDetails]
      if (action.payload.field) {
        fields[action.payload.id] = { ...fields[action.payload.id], ...action.payload.field }
      } else {
        fields[action.payload.id] = {
          category: '',
          brand: '',
          product: '',
        }
      }
      return { ...state, fields, deviceDetails }
    }
    case SET_ALL_FIELDS:
      return { ...state, fields: [...state.deviceDetails] }
    case SET_DEVICE_DETAILS:
      return { ...state, deviceDetails: [...state.fields] }
    case REMOVE_DEVICE_DETAILS: {
      let fields = [...state.fields]
      fields.splice(action.payload, 1)
      let deviceDetails = [...state.deviceDetails]
      deviceDetails.splice(action.payload, 1)
      return { ...state, fields, deviceDetails }
    }
    case SET_SUBSCRIPTION_DETAILS:
      return { ...action.payload, ...initialState }
    case SET_CONSUMER_PRODUCT_DETAILS:
      return { ...state, consumerProductDetails: action.payload }
    case SET_REQUIRED_DETAILS:
      return { ...state, requiredDetails: { ...state.requiredDetails, ...action.payload } }
    case SET_ERRORS:
      return { ...state, errors: action.payload }
    case SET_IS_SUBSCRIBED:
      return { ...state, isSubscribed: action.payload }
    case SET_ENROLLMENT_PLAN_LIST:
      return { ...state, enrollmentPlanList: action.payload }
    default:
      return state
  }
}

export default subscription
