import { connect } from 'react-redux'
import { setSeviceEstimatorDevice } from '../../../store/devices'
import { setSelectedDevice, setSelectedDamageType, setSelectedOrder } from '../../../store/request'
import { setToaster, setglobalLoaderStatus } from '../../../store/toastsAndLoaders'

import PurchaseHistory from '../components/PurchaseHistory'

const mapDispatchToProps = {
  setToaster,
  setglobalLoaderStatus,
  setSelectedDevice,
  setSelectedDamageType,
  setSelectedOrder,
  setSeviceEstimatorDevice,
}

const mapStateToProps = (state) => ({
  user: state.user,
  request: state.request,
  devices: state.devices,
})

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseHistory)
