import React from 'react'
import PropTypes from 'prop-types'
// import { browserHistory } from 'react-router'
import { BoxHolder, Heading, MediumButton, PillGroup } from '../../../components/UIComponents'
import { Input, Dropdown, Switch } from 'sfy-react'
import _ from 'lodash'
import { getTranslationText } from '../../../utils/languageTranslation.js'
import './ServiceEstimator.scss'
import { getLocaleCurrency, getLocaleDate } from '../../../utils/locale'
import { sortAlphabetically } from '../../../utils/functions'

export class SelectDeviceComponent extends React.Component {
  componentWillMount() {
    this.setState({
      devicelistData: this.loadDeviceList(),
    })
  }
  selectedValue = () => {
    var objLength = Object.getOwnPropertyNames(this.props.deviceInfo).length
    if (objLength) {
      let { ConsumerProductID, TagName, BrandID } = this.props.deviceInfo
      let ProductName = this.props.deviceInfo.Product.ProductName
      return [
        {
          ConsumerProductID,
          TagName,
          BrandID,
          ProductName,
          value: ProductName,
        },
      ]
    } else {
      let selectedObj = []
      if (this.props.listboxdata.categoryenabled) {
        selectedObj = [
          {
            ConsumerProductID: -1,
            TagName: '',
            BrandID: -1,
            ProductName: `+${getTranslationText('common', 'addNewDevice')}`,
            value: `+${getTranslationText('common', 'addNewDevice')}`,
          },
        ]
      }
      return selectedObj
    }
  }

  loadDeviceList = () => {
    let arr = this.props.devices.deviceDetails.supportedDevices.map((data) => {
      let { ConsumerProductID, TagName, BrandID } = data
      let ProductName = data.Product.ProductName
      return {
        ConsumerProductID,
        TagName,
        BrandID,
        ProductName,
        value: ProductName,
      }
    })
    arr.push({
      ConsumerProductID: -1,
      TagName: '',
      BrandID: -1,
      ProductName: `+${getTranslationText('common', 'addNewDevice')}`,
      value: `+${getTranslationText('common', 'addNewDevice')}`,
    })
    return _.compact(arr)
  }

  attachDeviceTypeahead = (typeahead) => {
    this.deviceListTypeahead = typeahead
  }

  getDeviceSelectionFocus = () => {
    let instance = this.deviceListTypeahead.getInstance()
    instance && instance.focus()
  }

  render() {
    // let isNewDevice = (this.props.listboxdata.productenabled)
    let isNewDevice = this.props.listboxdata.categoryenabled
    const dateFormat = _.get(this, 'props.selectedCountryData.countryConfig.DateFormat.DEFAULT', 'DD/MM/YYYY')
    const steps = [
      {
        text: getTranslationText('request', 'selectDevices'),
        image: '/commonAssets/Service Estimater Steps Image/1_Active.png',
        active: true,
      },
      {
        text: getTranslationText('request', 'selectIssues'),
        image: '/commonAssets/Service Estimater Steps Image/2_Inactive.png',
      },
    ]
    let selectedDeviceOption = this.selectedValue()

    return (
      <div className='row mb48'>
        <div className='col-sm-12 mt20'>
          <RequestNavBar steps={steps} {...this.props} />
        </div>
        <div className='col-sm-12 service-estimate-device-flow'>
          <BoxHolder>
            <div className='device-selection'>
              <div className='device-list-input mb8'>
                <div className='bookFontStyle fontSmall overrideCasing'>{getTranslationText('common', 'myDevice')}</div>
                <Dropdown
                  id='ProductName'
                  classObject={{
                    container: `device-list-selection mt8 mb8 list-type-ahead`,
                  }}
                  value={!_.isEmpty(selectedDeviceOption) ? selectedDeviceOption[0].value : ''}
                  options={this.state.devicelistData}
                  placeholder={getTranslationText('common', 'selectDevice')}
                  handleOnChange={(item) => {
                    this.props.handleDeviceSelection([item])
                  }}
                  filterBy='value'
                  searchText='Search'
                  showTooltip
                />
              </div>
              <div className='device-input'>
                <div className='bookFontStyle fontSmall'>{getTranslationText('common', 'category')}</div>
                <Dropdown
                  id={getTranslationText('common', 'category')}
                  classObject={{
                    container: `mt8 mb8 list-type-ahead`,
                  }}
                  value={!_.isEmpty(this.props.listboxdata.categoryvalue) ? this.props.listboxdata.categoryvalue[0].value : ''}
                  options={sortAlphabetically(this.props.listofProducts.categoryDataList, 'value')}
                  placeholder={getTranslationText('addingDeviceWidget', 'selectCategory')}
                  handleOnChange={(item) => {
                    this.props.catergoryListHandler([item])
                  }}
                  filterBy='value'
                  searchText='Search'
                  isDisabled={!this.props.listboxdata.categoryenabled}
                  showTooltip
                />
              </div>
              <div className='device-input'>
                <div className='bookFontStyle fontSmall'>{getTranslationText('common', 'brand')}</div>
                <Dropdown
                  id={getTranslationText('common', 'brand')}
                  classObject={{
                    container: `mt8 mb8 list-type-ahead`,
                  }}
                  value={!_.isEmpty(this.props.listboxdata.brandvalue) ? this.props.listboxdata.brandvalue[0].value : ''}
                  options={sortAlphabetically(this.props.listofProducts.brandDataList, 'value')}
                  placeholder={getTranslationText('common', 'selectBrand')}
                  handleOnChange={(item) => {
                    this.props.brandListHandler([item])
                  }}
                  filterBy='value'
                  searchText='Search'
                  isDisabled={!this.props.listboxdata.brandenabled}
                  showTooltip
                />
              </div>
              <div className='device-input'>
                <div className='bookFontStyle fontSmall'>{getTranslationText('common', 'product')}</div>
                <div className='product-list-typeahead'>
                  <Dropdown
                    id={getTranslationText('common', 'productLabel')}
                    classObject={{
                      container: `mt8 mb8 list-type-ahead`,
                    }}
                    value={!_.isEmpty(this.props.listboxdata.productvalue) ? this.props.listboxdata.productvalue[0].value : ''}
                    options={sortAlphabetically(this.props.listofProducts.productDataList, 'value')}
                    placeholder={getTranslationText('device', 'selectProduct')}
                    handleOnChange={(item) => {
                      this.props.productListHandler([item])
                    }}
                    filterBy='value'
                    searchText='Search'
                    isDisabled={!this.props.listboxdata.productenabled}
                    showTooltip
                  />
                  {this.props.fetchingProductLoader ? <div className='btn-level-loader' /> : ''}
                </div>
              </div>
              {isNewDevice ? (
                <div className='col-sm-12 mt28 buttons-container'>
                  <MediumButton
                    buttonText={getTranslationText('common', 'continue')}
                    onClick={this.props.setSelectedDeviceID}
                    disabled={this.props.btnStatus}
                  />
                </div>
              ) : (
                ''
              )}
            </div>
          </BoxHolder>
          {!isNewDevice && (
            <BoxHolder className='DeviceSubDataReq'>
              <Heading wordBreak title={getTranslationText('device', 'devicesInfo')} className='disabledLabel' />
              <div className='device-info-wrapper mt20'>
                <Input
                  className={{
                    formControl: 'device-input',
                    label: 'overrideCasing fontSmall',
                  }}
                  label={getTranslationText('common', 'deviceTagLabel')}
                  inputType='text'
                  id='device-tag-name'
                  placeholder={getTranslationText('device', 'placeholderMyDevice')}
                  value={this.props.deviceInfo.TagName}
                  isDisabled
                />
                <Input
                  className={{
                    formControl: 'device-input',
                    label: 'overrideCasing fontSmall',
                  }}
                  label={
                    this.props.isAnAppleDevice && this.props.deviceInfo.ProductSubCategoryID === 12
                      ? getTranslationText('common', 'serialNumberLabel')
                      : getTranslationText('common', 'serialNumber')
                  }
                  inputType='text'
                  id='device-serial-no'
                  placeholder='e.g. XHDAS68SCM'
                  value={
                    this.props.deviceInfo.ProductSubCategoryID === 12
                      ? !this.props.isAnAppleDevice
                        ? this.props.deviceInfo.DownloadedDeviceUniqueKey
                        : (_.get(this, 'props.deviceInfo.AlternateUniqueKey') || '').length
                        ? this.props.deviceInfo.AlternateUniqueKey
                        : this.props.deviceInfo.ProductUniqueID
                      : this.props.deviceInfo.AlternateUniqueKey
                  }
                  isDisabled
                />
                {!this.props.isAnAppleDevice && this.props.deviceInfo.ProductSubCategoryID === 12 && (
                  <Input
                    className={{
                      formControl: 'device-input',
                      label: 'overrideCasing fontSmall',
                    }}
                    label={getTranslationText('device', 'imei1')}
                    inputType='text'
                    id='device-imei1-no'
                    placeholder='e.g. 012345678901234'
                    value={this.props.deviceInfo.ProductUniqueID}
                    isDisabled
                  />
                )}
                {!this.props.isAnAppleDevice && this.props.deviceInfo.ProductSubCategoryID === 12 && (
                  <Input
                    className={{
                      formControl: 'device-input',
                      label: 'overrideCasing fontSmall',
                    }}
                    label={getTranslationText('device', 'imei2')}
                    inputType='text'
                    id='device-imei2-no'
                    placeholder='e.g. 012345678901234'
                    value={this.props.deviceInfo.AlternateUniqueKey}
                    isDisabled
                  />
                )}
              </div>
              <div className='row mt20'>
                <div className='col-sm-12'>
                  <div className={'bookFontStyle fontSmall mb8 disabledLabel'}>{getTranslationText('common', 'isDeviceWarranty')}</div>
                  <Switch
                    id='warranty'
                    disabled
                    checked={this.props.deviceRadiobtnvalue !== null ? this.props.deviceRadiobtnvalue : false}
                  />
                </div>
                {this.props.deviceInfo.WarrantyTill && this.props.deviceInfo.IsUnderWarranty && (
                  <div className={'col-sm-12 mb8 bookFontStyle fontSmall addDevice-input-field disabledLabel'}>
                    {getTranslationText('common', 'warrantyTillDate')}&nbsp;
                    <span className='bookFontStyle'>{getLocaleDate(dateFormat, this.props.deviceInfo.WarrantyTill)}</span>
                  </div>
                )}
                <div className='col-sm-12 mt20 buttons-container'>
                  <MediumButton
                    className='button-small'
                    buttonText={getTranslationText('common', 'continue')}
                    onClick={this.props.setSelectedDeviceID}
                    disabled={this.props.btnStatus}
                  />
                </div>
              </div>
            </BoxHolder>
          )}
        </div>
      </div>
    )
  }

  static propTypes = {
    devices: PropTypes.object.isRequired,
    deviceInfo: PropTypes.object,
    handleDeviceSelection: PropTypes.func.isRequired,
    btnStatus: PropTypes.bool.isRequired,
    setSelectedDeviceID: PropTypes.func.isRequired,
    listboxdata: PropTypes.object.isRequired,
    listofProducts: PropTypes.object.isRequired,
    catergoryListHandler: PropTypes.func.isRequired,
    productListHandler: PropTypes.func.isRequired,
    brandListHandler: PropTypes.func.isRequired,
    fetchingProductLoader: PropTypes.bool.isRequired,
    deviceRadiobtnvalue: PropTypes.bool,
    isAnAppleDevice: PropTypes.bool.isRequired,
  }
}

export const SelectIssuesComponent = (props) => {
  const steps = [
    {
      text: getTranslationText('request', 'selectDevices'),
      image: '/commonAssets/Service Estimater Steps Image/1_Inactive.png',
      pathname: '/service-estimator?cpid=' + props.deviceInfo.ConsumerProductID,
    },
    {
      text: getTranslationText('request', 'selectIssues'),
      image: '/commonAssets/Service Estimater Steps Image/2_Active.png',
      active: true,
    },
  ]
  const locale = _.get(props, 'user.selectedCountryData.countryConfig.Locale.DEFAULT', 'en')
  const currencyCode = _.get(props, 'user.selectedCountryData.CurrencyCode', '')
  return (
    <div className='row service-estimate-issue-flow mb48'>
      <div className='col-sm-12 mt20'>
        <RequestNavBar steps={steps} {...props} />
      </div>
      <div className='col-sm-12 col-lg-8 mt8'>
        <BoxHolder>
          <div className='mediumFontStyle fontSmall mb8 overrideCasing'>{getTranslationText('serviceEstimator', 'selectIssues')}</div>
          <PillGroup handlePillSelect={props.handlePillSelect} prefix='issuesPill' pills={props.issuesList} />
        </BoxHolder>
      </div>
      <div className='col-sm-12 col-lg-4 mt8 service-estimate-cost'>
        <BoxHolder className=''>
          {props.deviceInfo && props.deviceInfo.IsUnderWarranty === false ? (
            <Heading title={getTranslationText('serviceEstimator', 'estimatedServiceCost')} />
          ) : (
            ''
          )}
          {props.estimatedPriceFlg ? (
            props.deviceInfo && props.deviceInfo.IsUnderWarranty ? (
              <div>
                <div className='service-cost bookFontStyle'>{getTranslationText('common', 'free')}</div>
                <div className='bookFontStyle fontSmall mt8 mb8'>{getTranslationText('serviceEstimator', 'warrantyValidation')}</div>
              </div>
            ) : (
              <div>
                <div className='service-cost bookFontStyle'>{getLocaleCurrency(locale, currencyCode, props.estimatePrice)}</div>
                <div className='bookFontStyle fontSmall mt8 mb8'>
                  {getTranslationText('serviceEstimator', 'splitStringServiceEstimator')}
                </div>
              </div>
            )
          ) : (
            ''
          )}
          <div className='bookFontStyle fontSmall mt8 mb8'>{getTranslationText('serviceEstimator', 'estimatedCost')}</div>
        </BoxHolder>
      </div>
    </div>
  )
}

SelectIssuesComponent.propTypes = {
  user: PropTypes.object.isRequired,
  estimatePrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  estimatedPriceFlg: PropTypes.bool.isRequired,
  issuesList: PropTypes.array.isRequired,
  handlePillSelect: PropTypes.func.isRequired,
  deviceInfo: PropTypes.object.isRequired,
}

export class RequestNavBar extends React.Component {
  render() {
    return (
      <div className='service-estimator-nav-bar'>
        {this.props.steps.map((step, index) => {
          return (
            <div
              id={'req-nav-' + index}
              key={'key' + index}
              className={'nav-img-holder ' + (step.pathname ? 'clickable' : '')}
              onClick={this.props.handleNavClick}
            >
              <img src={step.image} />
              <div className={'nav-text-holder sentence-casing-uppercase ' + (step.active ? 'active' : '')}>
                <span>
                  {index + 1}. {step.text}
                </span>
              </div>
            </div>
          )
        })}
      </div>
    )
  }
}

RequestNavBar.propTypes = {
  steps: PropTypes.array.isRequired,
  handleNavClick: PropTypes.func,
}
