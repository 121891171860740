import _ from 'lodash'
import { productAuthErrorMessages } from './constants'
import moment from 'moment'

export const defaultValidationFilter = [
  {
    Type: 'SerialNumber',
    MinLength: 6,
    MaxLength: 30,
    ExtraFilter: 'Alphanumeric',
  },
  {
    Type: 'IMEI',
    MinLength: 15,
    MaxLength: 15,
    ExtraFilter: 'Numeric',
  },
  {
    Type: 'Order No.',
    MinLength: 5,
    MaxLength: 17,
    ExtraFilter: 'Numeric',
  },
]

export const validateDevice = async (device, validationApi, imeiFormDetails, brandConfig, brand) => {
  let verdict = {
    isValid: true,
    msg: 'Device validated!',
  }
  let ConsumerProductID = device && device.ConsumerProductID ? device.ConsumerProductID : undefined
  let productConfig = device.ProductConfig
  let warrantyCheckDetails = _.get(productConfig, 'WarrantyCheckDetails.WarrantyCheckValid')
    ? {
        ...productConfig.WarrantyCheckDetails,
        // AllowedValues: brandConfig.AllowedValues
      }
    : !_.isEmpty(brandConfig)
    ? {
        ...brandConfig,
        AllowedValues: brandConfig.ValidForEntireBrand
          ? brandConfig.AllowedValues
          : brandConfig.AllowedValues.filter((warrantyFilter) => _.includes(warrantyFilter.ProductList, device.ProductID)),
      }
    : undefined
  if (device.VerificationStateMeta?.VerificationStateID == 8) {
    let filterType = 'SerialNumber'
    let filterObj = defaultValidationFilter.filter((filter) => filter.Type === filterType)[0]
    if (['LGLA-Web'].includes(brand)) {
      if (!imeiFormDetails?.ProductUniqueID?.length && !imeiFormDetails?.ProductDeliveryDate) {
        verdict.isValid = false
        verdict.msg = 'Incomplete Serial Number'
        return verdict
      } else {
        return verdict
      }
    } else {
      if (!imeiFormDetails.ProductUniqueID?.length && !imeiFormDetails.AlternateUniqueKey?.length) {
        verdict.isValid = false
        verdict.msg = 'Incomplete Serial Number'
        return verdict
      }

      if (!imeiFormDetails.ProductUniqueID?.length && imeiFormDetails.AlternateUniqueKey?.length) {
        if (
          !regexTypesToConfirm[filterObj.ExtraFilter].test(imeiFormDetails.AlternateUniqueKey) ||
          imeiFormDetails.AlternateUniqueKey.length < filterObj.MinLength ||
          imeiFormDetails.AlternateUniqueKey.length > filterObj.MaxLength
        ) {
          verdict.isValid = false
          verdict.msg = 'Invalid Serial Number'
          return verdict
        }
      }

      if (!imeiFormDetails.AlternateUniqueKey?.length && imeiFormDetails.ProductUniqueID?.length) {
        if (
          !regexTypesToConfirm[filterObj.ExtraFilter].test(imeiFormDetails.ProductUniqueID) ||
          imeiFormDetails.ProductUniqueID.length < filterObj.MinLength ||
          imeiFormDetails.ProductUniqueID.length > filterObj.MaxLength
        ) {
          verdict.isValid = false
          verdict.msg = 'Invalid Serial Number'
          return verdict
        }
      }

      if (imeiFormDetails.AlternateUniqueKey?.length && imeiFormDetails.ProductUniqueID?.length) {
        if (
          !regexTypesToConfirm[filterObj.ExtraFilter].test(imeiFormDetails.AlternateUniqueKey) ||
          imeiFormDetails.AlternateUniqueKey.length < filterObj.MinLength ||
          imeiFormDetails.AlternateUniqueKey.length > filterObj.MaxLength
        ) {
          verdict.isValid = false
          verdict.msg = 'Invalid Serial Number'
          return verdict
        }
        if (
          !regexTypesToConfirm[filterObj.ExtraFilter].test(imeiFormDetails.ProductUniqueID) ||
          imeiFormDetails.ProductUniqueID.length < filterObj.MinLength ||
          imeiFormDetails.ProductUniqueID.length > filterObj.MaxLength
        ) {
          verdict.isValid = false
          verdict.msg = 'Invalid Serial Number'
          return verdict
        }
      }
    }
    return verdict
  } else {
    if (['LGLA-Web'].includes(brand)) {
      if (!imeiFormDetails?.ProductUniqueID?.length && !imeiFormDetails?.ProductDeliveryDate) {
        verdict.isValid = false
        verdict.msg = 'Incomplete Serial Number'
        return verdict
      } else {
        return verdict
      }
    }
    if (imeiFormDetails.ProductUniqueID) {
      let filterObj = warrantyCheckDetails && warrantyCheckDetails.AllowedValues.filter((filter) => filter.Type === 'IMEI')[0]
      if (filterObj) {
        let filterObjIMEILengths = warrantyCheckDetails.AllowedValues.filter((filter) => filter.Type === 'IMEI').map((obj) => obj.Length)
        if (
          filterObj &&
          (!regexTypesToConfirm[filterObj.ExtraFilter].test(imeiFormDetails.ProductUniqueID) ||
            !_.includes(filterObjIMEILengths, imeiFormDetails.ProductUniqueID.length))
        ) {
          verdict.isValid = false
          verdict.msg = 'Invalid IMEI'
          if (!_.includes(filterObjIMEILengths, imeiFormDetails.ProductUniqueID.length)) {
            verdict.msg = 'Incomplete IMEI'
          }
          return verdict
        }
      } else if (productConfig && productConfig.ValidIMEILength) {
        if (
          !regexTypesToConfirm['Numeric'].test(imeiFormDetails.ProductUniqueID) ||
          !_.includes(productConfig.ValidIMEILength, imeiFormDetails.ProductUniqueID.length)
        ) {
          verdict.isValid = false
          verdict.msg = 'Invalid IMEI'
          if (imeiFormDetails.ProductUniqueID.length < Math.min(...ProductConfig.ValidIMEILength)) {
            verdict.msg = 'Incomplete IMEI'
          }
          return verdict
        }
      } else {
        let filterObj = defaultValidationFilter.filter((filter) => filter.Type === 'IMEI')[0]
        if (
          !regexTypesToConfirm[filterObj.ExtraFilter].test(imeiFormDetails.ProductUniqueID) ||
          imeiFormDetails.ProductUniqueID.length < filterObj.MinLength ||
          imeiFormDetails.ProductUniqueID.length > filterObj.MaxLength
        ) {
          verdict.isValid = false
          verdict.msg = 'Invalid IMEI'
          if (imeiFormDetails.ProductUniqueID.length < filterObj.MinLength) {
            verdict.msg = 'Incomplete IMEI'
          }
          return verdict
        }
      }
    }
    if (imeiFormDetails.AlternateUniqueKey) {
      let filterObj = warrantyCheckDetails && warrantyCheckDetails.AllowedValues.filter((filter) => filter.Type === 'IMEI')[0]
      if (filterObj) {
        let filterObjIMEILengths = warrantyCheckDetails.AllowedValues.filter((filter) => filter.Type === 'IMEI').map((obj) => obj.Length)
        if (
          filterObj &&
          (!regexTypesToConfirm[filterObj.ExtraFilter].test(imeiFormDetails.AlternateUniqueKey) ||
            !_.includes(filterObjIMEILengths, imeiFormDetails.AlternateUniqueKey.length))
        ) {
          verdict.isValid = false
          verdict.msg = 'Invalid IMEI'
          if (!_.includes(filterObjIMEILengths, imeiFormDetails.ProductUniqueID.length)) {
            verdict.msg = 'Incomplete IMEI'
          }
          return verdict
        }
      } else if (productConfig && productConfig.ValidIMEILength) {
        if (
          !regexTypesToConfirm['Numeric'].test(imeiFormDetails.AlternateUniqueKey) ||
          !_.includes(productConfig.ValidIMEILength, imeiFormDetails.AlternateUniqueKey.length)
        ) {
          verdict.isValid = false
          verdict.msg = 'Invalid IMEI'
          if (imeiFormDetails.AlternateUniqueKey.length < Math.min(...ProductConfig.ValidIMEILength)) {
            verdict.msg = 'Incomplete IMEI'
          }
          return verdict
        }
      } else {
        let filterObj = defaultValidationFilter.filter((filter) => filter.Type === 'IMEI')[0]
        if (
          !regexTypesToConfirm[filterObj.ExtraFilter].test(imeiFormDetails.AlternateUniqueKey) ||
          imeiFormDetails.AlternateUniqueKey.length < filterObj.MinLength ||
          imeiFormDetails.AlternateUniqueKey.length > filterObj.MaxLength
        ) {
          verdict.isValid = false
          verdict.msg = 'Invalid IMEI'
          if (imeiFormDetails.AlternateUniqueKey.length < filterObj.MinLength) {
            verdict.msg = 'Incomplete IMEI'
          }
          return verdict
        }
      }
    }
    if (imeiFormDetails.DownloadedDeviceUniqueKey) {
      let hasOrderType = warrantyCheckDetails && warrantyCheckDetails.AllowedValues.filter((filter) => filter.Type === 'Order No.')[0]
      if (!hasOrderType || (hasOrderType && !hasOrderType.ProductList.find((id) => id === (device.ProductID || device.ProductId)))) {
        // product not eligible for order check details
        hasOrderType = false
      }
      let filterObj =
        hasOrderType || (warrantyCheckDetails && warrantyCheckDetails.AllowedValues.filter((filter) => filter.Type === 'SerialNumber')[0])
      let filterType = (filterObj && filterObj.Type) || 'SerialNumber'
      if (filterObj) {
        let filterObjSerialLengths = warrantyCheckDetails.AllowedValues.filter((filter) => filter.Type === filterType).map(
          (obj) => obj.Length
        )
        if (
          filterObj &&
          (!regexTypesToConfirm[filterObj.ExtraFilter].test(imeiFormDetails.DownloadedDeviceUniqueKey) ||
            !_.includes(filterObjSerialLengths, imeiFormDetails.DownloadedDeviceUniqueKey.length))
        ) {
          verdict.isValid = false
          verdict.msg = hasOrderType ? 'Invalid Order Number' : 'Invalid Serial Number'
          if (!_.includes(filterObjSerialLengths, imeiFormDetails.DownloadedDeviceUniqueKey.length)) {
            verdict.msg = hasOrderType ? 'Incomplete Order Number' : 'Incomplete Serial Number'
          }
          return verdict
        }
      } else {
        let filterObj = defaultValidationFilter.filter((filter) => filter.Type === filterType)[0]
        if (
          !regexTypesToConfirm[filterObj.ExtraFilter].test(imeiFormDetails.DownloadedDeviceUniqueKey) ||
          imeiFormDetails.DownloadedDeviceUniqueKey.length < filterObj.Length ||
          imeiFormDetails.DownloadedDeviceUniqueKey.length > filterObj.Length
        ) {
          verdict.isValid = false
          verdict.msg = hasOrderType ? 'Invalid Order Number' : 'Invalid Serial Number'
          if (imeiFormDetails.DownloadedDeviceUniqueKey.length < filterObj.Length) {
            verdict.msg = hasOrderType ? 'Invalid Order Number' : 'Invalid Serial Number'
          }
          return verdict
        }
      }
    }
  }

  let response
  let imeiOrSerial = imeiFormDetails.ProductUniqueID || imeiFormDetails.AlternateUniqueKey || imeiFormDetails.DownloadedDeviceUniqueKey
  /** added if block for two same IMEI'S */
  if (
    imeiFormDetails.ProductUniqueID &&
    imeiFormDetails.AlternateUniqueKey &&
    imeiFormDetails.ProductUniqueID === imeiFormDetails.AlternateUniqueKey
  ) {
    response = {
      success: false,
      data: {
        status_message: 'IMEI_SAME',
        ...imeiFormDetails,
      },
    }
  } else {
    if (
      warrantyCheckDetails &&
      (warrantyCheckDetails.WarrantyCheckValid ||
        _.includes(brandConfig.ProductList, device.ProductID) ||
        warrantyCheckDetails.ValidForEntireBrand) &&
      ((imeiOrSerial && !device.ProductUniqueID) ||
        (device.ProductUniqueID && device.VerificationStateMeta.IsUniqueIdEditable) ||
        (device.VerificationStateMeta && imeiOrSerial && device.VerificationStateMeta.IsUniqueIdEditable))
    ) {
      // brands with warranty api
      let req = {
        ConsumerProductID: ConsumerProductID,
        BrandID: device.BrandID,
        ProductID: device.ProductID,
        TagName: imeiFormDetails.TagName || undefined,
        imeiOrSerial,
        WarrantyTill: imeiFormDetails.WarrantyTill ? moment(imeiFormDetails.WarrantyTill).format('YYYY-MM-DD') : undefined,
      }
      if (device.ProductSubCategoryID !== 12) {
        req = {
          ...req,
          DownloadedDeviceUniqueKey: imeiFormDetails.DownloadedDeviceUniqueKey,
        }
      }
      response = await validationApi(req)
    } else {
      response = {
        success: true,
        data: {
          ...imeiFormDetails,
        },
      }
    }
  }
  if (response && Object.keys(_.get(response, 'data', {})).length && response.msg) {
    verdict.isValid = response ? response.data && response.success : ''
    verdict.msg = response && response.msg ? response.msg : ''
    verdict.skipUpdateConsumerProduct = true
    verdict.verifyProduct = !!(response && response.success)
  } else {
    verdict.isValid = response ? response.success : true
    verdict.msg =
      response && !response.success
        ? productAuthErrorMessages(
            response.data.status_message || response.msg,
            regexTypes['Numeric'].test(imeiOrSerial) ? 'IMEI' : 'Serial Number'
          )
        : verdict.msg
    verdict.skipUpdateConsumerProduct = false
  }
  verdict.productObj = response ? response.data : undefined
  return verdict
}

export const validateInputChangeForDevice = (e, device, brandConfig, brand) => {
  let verdict = {
    isValid: true,
    // msg: 'Is okay'
  }
  let productConfig = device.ProductConfig
  const ProductId = device.ProductID || device.ProductId
  let warrantyCheckDetails = _.get(productConfig, 'WarrantyCheckDetails.WarrantyCheckValid')
    ? {
        ...productConfig.WarrantyCheckDetails,
        // AllowedValues: brandConfig.AllowedValues
      }
    : Object.keys(brandConfig || {}).length
    ? {
        ...brandConfig,
        AllowedValues: brandConfig.ValidForEntireBrand
          ? brandConfig.AllowedValues
          : brandConfig.AllowedValues.filter((warrantyFilter) => _.includes(warrantyFilter.ProductList, ProductId)),
      }
    : undefined
  if (device.VerificationStateMeta?.VerificationStateID == 8 || ['LGLA-Web'].includes(brand)) {
    let filterObj_ = { Type: 'SerialNumber', MinLength: 6, MaxLength: 30, ExtraFilter: 'Alphanumeric' }
    if (e.target.value && !regexTypes[filterObj_.ExtraFilter].test(e.target.value)) {
      verdict.isValid = false
      return verdict
    } else if (e.target.value.length > filterObj_.MaxLength) {
      verdict.isValid = false
      return verdict
    }
  } else if (e.target.id === 'ProductUniqueID' || e.target.id === 'AlternateUniqueKey') {
    let filterObj = warrantyCheckDetails && warrantyCheckDetails.AllowedValues.filter((filter) => filter.Type === 'IMEI')[0]
    if (filterObj) {
      let filterObjIMEILengths = warrantyCheckDetails.AllowedValues.filter((filter) => filter.Type === 'IMEI').map((obj) => obj.Length)
      let maxLength = Math.max(...filterObjIMEILengths)
      if (e.target.value && filterObj && !regexTypes[filterObj.ExtraFilter].test(e.target.value)) {
        verdict.isValid = false
        return verdict
      } else if (filterObj && e.target.value.length > maxLength) {
        verdict.isValid = false
        return verdict
      }
    } else if (productConfig && productConfig.ValidIMEILength) {
      if (e.target.value && !regexTypes['Numeric'].test(e.target.value)) {
        verdict.isValid = false
        return verdict
      } else if (e.target.value.length > Math.max(productConfig.ValidIMEILength)) {
        verdict.isValid = false
        return verdict
      } else if (e.target.value[0] && productConfig.IMEIShouldStartWith && e.target.value[0] !== productConfig.IMEIShouldStartWith) {
        verdict.isValid = false
        verdict.msg = 'IMEI should start with ' + productConfig.IMEIShouldStartWith
        return verdict
      }
    } else {
      let filterObj = defaultValidationFilter.filter((filter) => filter.Type === 'IMEI')[0]
      if (e.target.value && !regexTypes[filterObj.ExtraFilter].test(e.target.value)) {
        verdict.isValid = false
        return verdict
      } else if (e.target.value.length > filterObj.MaxLength) {
        verdict.isValid = false
        return verdict
      }
    }
  }
  if (e.target.id === 'DownloadedDeviceUniqueKey') {
    let hasOrderType =
      brandConfig && Object.keys(brandConfig || {}).length && brandConfig.AllowedValues.filter((filter) => filter.Type === 'Order No.')[0]
    if (!hasOrderType || (hasOrderType && !hasOrderType.ProductList.find((id) => id === (device.ProductID || device.ProductId)))) {
      // product not eligible for order check details
      hasOrderType = false
    }
    let filterObj =
      hasOrderType || (warrantyCheckDetails && warrantyCheckDetails.AllowedValues.filter((filter) => filter.Type === 'SerialNumber')[0])
    let filterType = (filterObj && filterObj.Type) || 'SerialNumber'
    if (filterObj) {
      let filterObjSerialLengths = warrantyCheckDetails.AllowedValues.filter((filter) => filter.Type === filterType).map(
        (obj) => obj.Length
      )
      let maxLength = Math.max(...filterObjSerialLengths)
      if (e.target.value && filterObj && !regexTypes[filterObj.ExtraFilter].test(e.target.value)) {
        verdict.isValid = false
        return verdict
      } else if (filterObj && e.target.value.length > maxLength) {
        verdict.isValid = false
        return verdict
      }
    } else {
      let filterObj_ = { Type: 'SerialNumber', MinLength: 6, MaxLength: 30, ExtraFilter: 'Alphanumeric' }
      if (e.target.value && !regexTypes[filterObj_.ExtraFilter].test(e.target.value)) {
        verdict.isValid = false
        return verdict
      } else if (e.target.value.length > filterObj_.MaxLength) {
        verdict.isValid = false
        return verdict
      }
    }
  }
  return verdict
}

export const regexTypes = {
  Alphanumeric: /^[a-zA-Z0-9]+$/,
  Numeric: /^[0-9]+$/,
}

export const regexTypesToConfirm = {
  Alphanumeric: /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/,
  Numeric: /^[0-9]+$/,
}

export const getFileType = ({ header, type: mime }) => {
  switch (header) {
    case '89504e47':
      return { mime: 'image/png', format: 'png', type: 'image' }
    case '47494638':
      return { mime: 'image/gif', format: 'gif', type: 'image' }
    case 'ffd8ffe0':
    case 'ffd8ffe1':
    case 'ffd8ffe2':
    case 'ffd8ffe3':
    case 'ffd8ffe8':
      return { mime: 'image/jpeg', format: 'ipeg', type: 'image' }
    default: {
      const mimeParts = mime.split('/')
      const type = mimeParts[0]
      const format = mimeParts[1]
      return { mime, format, type }
    }
  }
}

export const isInvalidFileFormat = (formats, format) => formats.indexOf(format) === -1

export const isValidMimeType = (mimeTypes, mimeType) => mimeTypes.indexOf(mimeType) === -1

export const canUpload = (event, { allowedTypes }, audioType) =>
  new Promise(function (resolve) {
    if (audioType == 'audio') {
      resolve()
    }
    const supportedFileFormats = event.target.accept
    const files = event.target.files
    // const supportedFileFormatsArr = supportedFileFormats.replace(/\./g, '').split(',')
    const errorMsg = `Please upload file having format as ${supportedFileFormats}`
    for (let index = 0; index < files.length; index++) {
      const file = files[index]
      const { type: mime } = file
      const type = mime.split('/')[0]
      // const format = mime.split('/')[1]
      if (isValidMimeType(allowedTypes, type)) {
        throw new Error(errorMsg)
      }
      if (!window.FileReader) {
        resolve({ files })
      }
      var fileReader = new FileReader()
      fileReader.onloadend = ({ target: { result } }) => {
        const arr = new Uint8Array(result).subarray(0, 4)
        let header = ''
        arr.forEach((element) => {
          header += element.toString(16)
        })
        const meta = getFileType({ header, type })
        const { type: typeFromBlob } = meta
        if (isValidMimeType(allowedTypes, typeFromBlob)) {
          throw new Error(errorMsg)
        }
        resolve({ files })
      }
      fileReader.readAsArrayBuffer(file)
    }
  })

export const isValidMobileNumber = (value, CountryCode) => {
  // const PNT = require('google-libphonenumber').PhoneNumberType //To check the type of number
  const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance()
  const phoneNumber = phoneUtil.parseAndKeepRawInput(value, CountryCode)
  return !phoneUtil.isValidNumberForRegion(phoneNumber, CountryCode)
}
