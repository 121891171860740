import React from 'react'
import ReactDOM from 'react-dom'
import createStore from './store/createStore'
import './styles/main.scss'

import { locationChange } from './store/location'
import browserHistory from 'react-router/lib/browserHistory'
// import createRoutes from './routes'
// import App from './components/App'

// Store Initialization
// ------------------------------------
const store = createStore(window.__INITIAL_STATE__)

store.dispatch(locationChange(browserHistory.getCurrentLocation()))

if (!localStorage.getItem('languageCode')) {
  window.isDirectionRTL = isDirectionRTL === 'true'
} else {
  window.isDirectionRTL = ['ar', 'AR'].indexOf(localStorage.getItem('languageCode')) !== -1
  window.isDirectionRTL
    ? document.querySelector('html').setAttribute('dir', 'rtl')
    : document.querySelector('html').setAttribute('dir', 'ltr')
}

window.useDocService = useDocService === true || useDocService === 'true'
window.useLogger = useLogger === true || useLogger === 'true'

// const routes = createRoutes(store)

// Render Setup
// ------------------------------------
const MOUNT_NODE = document.getElementById('root')
let render = () => {
  const App = require('./components/App').default
  const routes = require('./routes/index').default(store)
  ReactDOM.render(<App store={store} routes={routes} />, MOUNT_NODE)
}

// Development Tools
// ------------------------------------
if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    // Setup hot module replacement
    module.hot.accept(['./components/App', './routes/index'], () =>
      setImmediate(() => {
        ReactDOM.unmountComponentAtNode(MOUNT_NODE)
        render()
      })
    )
  }
}
// Let's Go!
// ------------------------------------
if (process.env.NODE_ENV !== 'test') {
  render()
}
