import React from 'react'
import PropTypes from 'prop-types'
// import { BoxHolder } from '../'
import { Loader as SFYLoader } from 'sfy-react'
import './Loader.scss'

const Loader = (props) => {
  if (props.loaderState) {
    return (
      <div
        className={
          props.loaderState
            ? props.miniLoader
              ? `apply-loader mini-loader ${props.className ? props.className : ''}`
              : 'apply-loader'
            : ''
        }
      >
        {props.loaderState ? <SFYLoader isContentLoader /> : null}
        {props.children || ''}
      </div>
    )
  } else {
    return props.children || ''
  }
}
Loader.propTypes = {
  children: PropTypes.node,
  loaderState: PropTypes.bool,
  miniLoader: PropTypes.bool,
}
// Loader.defaultProps = {
// }
export default Loader
