import React, { useCallback, useEffect, useRef, useState } from 'react'
import { BoxHolder } from '../../../components/UIComponents'
import './Vouchers.scss'
import { Accordion } from 'sfy-react'
import { ReactComponent as DownArrow } from '../../../../public/commonAssets/down-arrow.svg'
import { ReactComponent as NoVoucher } from '../../../../public/commonAssets/no-voucher.svg'
import { ReactComponent as RightArrow } from '../../../../public/commonAssets/right-arrow.svg'
import { ReactComponent as CopyIcon } from '../../../../public/commonAssets/copy-icon.svg'
import { browserHistory } from 'react-router'
import { getLocaleDate } from '../../../utils/locale'
import { setToaster, setglobalLoaderStatus } from '../../../store/toastsAndLoaders'
import { useDispatch, useSelector } from 'react-redux'
import { getTranslationText } from '../../../utils/languageTranslation'

export const AllVouchers = ({ voucherCategories, setSelectedVoucher }) => {
  const [openAccordion, setOpenAccordion] = useState({ Unlocked: false, Expired: false })

  const formatDate = (dateString) => {
    const inputDate = new Date(dateString)

    const day = inputDate.getDate()
    const monthIndex = inputDate.getMonth()
    const year = inputDate.getFullYear()

    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    const month = months[monthIndex]

    return `${day} ${month} ${year}`
  }

  const handleOnVoucherSelection = (voucher) => {
    setSelectedVoucher(voucher)
    browserHistory.push(`/vouchers/${voucher.SoldPlanBenefitID}`)
  }

  return (
    <div className='mb20 mt20 all-voucher-container'>
      {Object.values(voucherCategories).length &&
      Object.values(voucherCategories)?.reduce((isEmpty, category) => isEmpty && category.length === 0, true) ? (
        <BoxHolder className='d-flex empty-voucher-container'>
          <NoVoucher className='empty-voucher-img' />
          <div className='mt20 empty-heading mediumFontStyle'>{getTranslationText('voucher', 'noVoucherHeading')}</div>
        </BoxHolder>
      ) : (
        <div className='row mt20'>
          <div className='col-sm-12 col-lg-9'>
            <div>
              <BoxHolder className='voucher-banner-container'>
                <img className='banner-img' src={`/${appName}/images/voucherBanner.svg`} />
              </BoxHolder>
              <BoxHolder className='m0 mt20 all-voucher-box'>
                <div>
                  {Object.entries(voucherCategories).map(([category, vouchers]) => {
                    return (
                      <div key={category}>
                        <Accordion
                          title={
                            <div className='mediumFontStyle mb20'>{`${getTranslationText('voucher', category.toLowerCase())} (${
                              vouchers.length
                            })`}</div>
                          }
                          showAccordion={openAccordion[category]}
                          onClick={() => {
                            setOpenAccordion((openAccordion) => ({
                              ...openAccordion,
                              [category]: !openAccordion[category],
                            }))
                          }}
                          openIcon={<DownArrow className='ml8 mb20' />}
                          closeIcon={<DownArrow className='voucher-tab-close mb20 ml8' />}
                          className={{
                            container: 'vouchers-container',
                            button: 'p0 black-color accordian-button-icon',
                            panel: 'vouchers-accordion__panel',
                          }}
                        >
                          {vouchers.length ? (
                            <div className='vouchers-container'>
                              {vouchers.map((voucher) => {
                                return (
                                  <div
                                    onClick={() => {
                                      if (category !== 'Expired') {
                                        handleOnVoucherSelection(voucher)
                                      }
                                    }}
                                    key={voucher.SoldPlanBenefitID}
                                    className='voucher-detail-container mb20'
                                  >
                                    <div className='voucher-image-container'>
                                      <img
                                        className={`${category === 'Expired' ? 'expired-image' : ''} voucher-image`}
                                        src={voucher.ImageURL}
                                      />
                                    </div>
                                    <hr className='vertical-line' />
                                    <div className='ml20 black-color'>
                                      <div className='fontLarge mediumFontStyle'>{voucher.BenefitName}</div>
                                      <div className='mt4 fontSmall gray-font'>{voucher.BenefitDescription} </div>
                                      {category === 'Available' && (
                                        <div className='mt8 fontSmall mediumFontStyle'>
                                          {`${getTranslationText('voucher', 'validTill')} ${formatDate(voucher.ExpiryDate)}`}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          ) : (
                            <div className='mb32 gray-font'>{getTranslationText('voucher', 'vouchersNotAvailable')}</div>
                          )}
                        </Accordion>
                      </div>
                    )
                  })}
                </div>
              </BoxHolder>
            </div>
          </div>
          <div className='col-sm-12 col-lg-3'>
            <div className='row'>
              <div className='col-sm-6 col-lg-12'>
                <BoxHolder className='voucher-overview-container'>
                  <div className='overview-heading mediumFontStyle'>{getTranslationText('voucher', 'vouchersOverview')}</div>
                  <div className=' mb10 mt20'>
                    <div className='fontMedium overrideCasing gray-font'>{getTranslationText('voucher', 'totalAvailable')}</div>
                    <div className='fontLarge '>{voucherCategories.Available?.length.toString().padStart(2, '0')}</div>
                  </div>
                  <hr className='divider' />
                  <div className='mt20'>
                    <div className='fontMedium overrideCasing gray-font'>{getTranslationText('voucher', 'unlocked')}</div>
                    <div className='fontLarge'>{voucherCategories.Unlocked?.length.toString().padStart(2, '0')}</div>
                  </div>
                </BoxHolder>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export const VouchersDetails = ({ selectedVoucher, markBenefitAsUsed, fetchData }) => {
  const [showMoreDetials, setShowMoreDetials] = useState(false)
  const [dragging, setDragging] = useState(false)
  const [circlePosition, setCirclePosition] = useState(0)
  const [isSwiped, setIsSwiped] = useState(false)
  const [benefitAsUsedDetails, setBenefitAsUsedDetails] = useState('')

  const user = useSelector((state) => state.user)

  const dispatch = useDispatch()

  const pillRef = useRef()

  const handleStart = () => {
    setDragging(true)
  }

  const handleMove = useCallback(
    (clientX) => {
      if (dragging && !isSwiped) {
        const pill = pillRef.current
        const pillWidth = pill.offsetWidth
        const newPosition = Math.min(Math.max(clientX - pill.offsetLeft, 0), pillWidth - 50)
        setCirclePosition(newPosition)
      }
    },
    [dragging, isSwiped]
  )

  const handleEnd = useCallback(async () => {
    if (dragging && selectedVoucher.Status === 'ACTIVE' && !isSwiped) {
      const pill = pillRef.current
      const pillWidth = pill.offsetWidth
      const newPosition = pillWidth - 50
      try {
        dispatch(setglobalLoaderStatus(true))
        const requestObj = {
          SoldPlanID: selectedVoucher.SoldPlanID,
          SoldPlanBenefitID: selectedVoucher.SoldPlanBenefitID,
          ConsumerID: user.consumerDetails.data.consumerID,
        }
        const response = await markBenefitAsUsed(requestObj, user.consumerDetails.data.accessToken.ID)

        if (response) {
          setCirclePosition(newPosition)
          setDragging(false)
          setIsSwiped(true)
          setBenefitAsUsedDetails(response)
          fetchData()
        } else {
          setIsSwiped(false)
          setDragging(false)
          setCirclePosition(0)
        }
        dispatch(setglobalLoaderStatus(false))
      } catch (error) {
        dispatch(setglobalLoaderStatus(false))
        console.error('Error fetching data:', error)
      }
    }
  }, [dispatch, isSwiped, selectedVoucher, user, dragging, markBenefitAsUsed, fetchData])

  const handleTouchStart = (e) => {
    handleStart()
    handleMove(e.touches[0].clientX)
  }

  const handleMouseDown = (e) => {
    handleStart()
    handleMove(e.clientX)
  }

  const handleCopyClick = () => {
    const textarea = document.createElement('textarea')
    textarea.value = selectedVoucher.BenefitInventoryCode
    document.body.appendChild(textarea)

    textarea.select()
    document.execCommand('copy')

    document.body.removeChild(textarea)
    dispatch(setToaster(getTranslationText('voucher', 'copied'), 'success'))
  }

  useEffect(() => {
    const handleGlobalMove = (e) => {
      const clientX = e.clientX || (e.touches && e.touches.length > 0 && e.touches[0].clientX)
      handleMove(clientX)
    }

    const handleGlobalEnd = () => {
      handleEnd()
      document.removeEventListener('mousemove', handleGlobalMove)
      document.removeEventListener('mouseup', handleGlobalEnd)
      document.removeEventListener('touchend', handleGlobalEnd)
      document.removeEventListener('touchmove', handleGlobalMove)
    }

    if (dragging) {
      document.addEventListener('mousemove', handleGlobalMove)
      document.addEventListener('mouseup', handleGlobalEnd)
      document.addEventListener('touchend', handleGlobalEnd)
      document.addEventListener('touchmove', handleGlobalMove)
    }

    return () => {
      document.removeEventListener('mousemove', handleGlobalMove)
      document.removeEventListener('mouseup', handleGlobalEnd)
      document.removeEventListener('touchend', handleGlobalEnd)
      document.removeEventListener('touchmove', handleGlobalMove)
    }
  }, [dragging, handleMove, handleEnd])

  return (
    selectedVoucher && (
      <div className='voucher-details-container'>
        <div className='details-overview-container'>
          <div
            className='back'
            onClick={() => {
              browserHistory.push('/vouchers')
            }}
          >
            <RightArrow className='back-arrow' />
            <span className='primaryColor mediumFontStyle fontMedium ml4 cursor'>{getTranslationText('voucher', 'backToVouchers')}</span>
          </div>
          <div className='d-flex pt20'>
            <div className='image-container'>
              <img src={selectedVoucher.ImageURL} alt='benefit-img' className='image' />
            </div>
            <div className='details ml30'>
              <div className='fontExtraLarge mediumFontStyle'>{selectedVoucher.BenefitName}</div>
              <div className='fontMedium mt10 mediumFontStyle'>{selectedVoucher.BenefitDescription}</div>
              {selectedVoucher.Status === 'ACTIVE' && (
                <div className='mt10 fontMedium'>
                  {getTranslationText('voucher', 'validTill')}:{' '}
                  <span className='mediumFontStyle'>{getLocaleDate('DD/MM/YY', selectedVoucher.ExpiryDate)}</span>
                </div>
              )}
              {showMoreDetials && (
                <>
                  <div className='mt10 mediumFontStyle fontMedium'>{getTranslationText('voucher', 'voucherBenefits')}</div>
                  <span className='fontMedium' dangerouslySetInnerHTML={{ __html: selectedVoucher.Benefits?.replace(/\n/g, '<br>') }} />
                </>
              )}
              <div
                className='primaryColor mediumFontStyle fontMedium mt10'
                onClick={() => {
                  setShowMoreDetials((showMoreDetials) => !showMoreDetials)
                }}
              >
                {showMoreDetials ? getTranslationText('voucher', 'readLess') : getTranslationText('guide', 'readMore')}{' '}
                {<DownArrow className={showMoreDetials ? 'voucher-tab-close' : ''} />}
              </div>
              <div className='d-flex btn-container'>
                <div
                  className='primary pill mt20'
                  ref={pillRef}
                  onClick={() => {
                    !(!isSwiped && selectedVoucher.Status === 'ACTIVE') && handleCopyClick()
                  }}
                  onTouchStart={handleTouchStart}
                  onMouseDown={handleMouseDown}
                >
                  {!isSwiped && selectedVoucher.Status === 'ACTIVE' ? (
                    <>
                      <span className='circle m4' style={{ transform: `translateX(${circlePosition}px)` }}>
                        <RightArrow className='swipe-arrow' />
                      </span>
                      <div className={`mr12 ml8 mediumFontStlye swipe-text`}>{getTranslationText('voucher', 'swipeMessage')}</div>
                    </>
                  ) : (
                    <>
                      <div className='primaryColor mediumFontStyle code ml28 mr20'>
                        {benefitAsUsedDetails.InventoryCode || selectedVoucher.BenefitInventoryCode}
                      </div>
                      <CopyIcon className='m12 copy-icon' />
                    </>
                  )}
                </div>
                {(isSwiped || selectedVoucher.Status !== 'ACTIVE') && selectedVoucher.RedemptionLink && (
                  <a
                    href={selectedVoucher.RedemptionLink}
                    target='_blank'
                    rel='noreferrer'
                    className={`ml20 redeem-now-btn pill mt20 mediumFontStyle pl20 pr20 pt10 pb10`}
                  >
                    {getTranslationText('voucher', 'redeemNow')}
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='instruction-conatiner'>
          {selectedVoucher.RedeemInstructions &&
            JSON.parse(selectedVoucher.RedeemInstructions).map((instruction, i) => (
              <div key={i}>
                <div className='fontLarge mediumFontStyle mb20'>{instruction.sectionHeader}</div>
                <ul>
                  {instruction.sectionList.map((step, j) => (
                    <li key={j}>
                      <span dangerouslySetInnerHTML={{ __html: step.htmlText?.replace(/\n/g, '<br>') }}></span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
        </div>
      </div>
    )
  )
}
