import React, { useState, useEffect, useCallback, useRef } from 'react'
import { browserHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { Accordion, Button, Dropdown, Modal } from 'sfy-react'
import { postRequestAsync } from '../../../../api/genericAPIs'
import {
  setAllBrands,
  setBrands,
  setPlanDetails,
  setProducts,
  setSubcategories,
  setSubscriptionDetails,
  setEnrollmentPlanList,
} from '../../../../store/subscription'
import './PlanDetails.scss'
import { setglobalLoaderStatus, setToaster } from '../../../../store/toastsAndLoaders'
import { getTranslationText } from '../../../../utils/languageTranslation'
import { BrandSpecificConfig } from '../../../../utils/constants'
import pushToAnalytics from '../../../../utils/Analytics'

const PlanDetails = ({ params, location, children }) => {
  const dispatch = useDispatch()
  const faqRef = useRef()

  const isSubscribed = useSelector((state) => state.subscription.isSubscribed)
  const consumerId = useSelector((state) => state.user.userDetails.consumerID)
  const userDetails = useSelector((state) => state.user)
  const appConfigDetails = useSelector((state) => state.user.appConfigDetails)
  const planDetails = useSelector((state) => state.subscription.planDetails)
  const subcategories = useSelector((state) => state.subscription.subcategories)
  const allBrands = useSelector((state) => state.subscription.allBrands)
  const enrollmentPlanList = useSelector((state) => state.subscription.enrollmentPlanList)

  const [showCategoriesModal, setShowCategoriesModal] = useState(false)
  const [showBrandsModal, setShowBrandsModal] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState('')
  const [selectedBrands, setSelectedBrands] = useState([])
  const [showAccordion, setShowAccordion] = useState(false)
  const [seeMoreLength, setSeeMoreLength] = useState(5)

  useEffect(() => {
    if (!isSubscribed) {
      let subscriptionDetails = localStorage.getItem('subscription')
      subscriptionDetails = subscriptionDetails && JSON.parse(subscriptionDetails)
      if (!subscriptionDetails) {
        const getPlanDetails = async () => {
          try {
            dispatch(setglobalLoaderStatus(true))
            if (!enrollmentPlanList?.length > 0) {
              const response = await postRequestAsync('ConsumerProduct/fetchEligibleEnrollmentPlans', {
                app: BrandSpecificConfig().header,
                CountryID: window.CountryID,
              })
              if (response.length === 1) {
                dispatch(setEnrollmentPlanList(response[0].PlanObject))
              }
            }
            const planDetailsResponse = await postRequestAsync('ConsumerProduct/fetchPlanDetails', {
              PlanCode: params.id,
              IncludeField: { FieldName: 'Brand' },
            })
            const { utm_source: utmSource, utm_medium: utmMedium, utm_campaign: utmCampaign } = location.query
            const clevertapUserProfile = consumerId ? userDetails : {}
            const { EmailID } = clevertapUserProfile.userDetails || {}
            pushToAnalytics(
              'Plan Sales - Plan Details Page',
              {
                'Logged In': consumerId ? 'Yes' : 'No',
                'Plan Name': planDetailsResponse.PlanDetails.PlanName,
                'Referral Source': utmSource,
                'Referral Medium': utmMedium,
                'Referral Campaign': utmCampaign,
                Source: 'Web',
                'Email ID': EmailID,
                ConsumerID: consumerId || '',
              },
              clevertapUserProfile
            )
            dispatch(setPlanDetails(planDetailsResponse))
            let response = await postRequestAsync('ConsumerProduct/getPlanSubCategories', {
              PlanArray: [planDetailsResponse.PlanDetails.PlanID],
              app: BrandSpecificConfig().header,
              CountryID: window.CountryID,
            })
            if (response?.length) {
              const sortListOrder = [88, 84]
              const sortedList = response.sort((a, b) => {
                return sortListOrder.indexOf(b.ProductSubCategoryID) - sortListOrder.indexOf(a.ProductSubCategoryID)
              })
              response = sortedList.map((item) => ({ ...item, value: item.ProductSubCategory }))
              dispatch(setSubcategories(response))
              dispatch(setBrands([]))
              dispatch(setProducts([]))
            }
            response = await postRequestAsync('ConsumerProduct/getPlanBrandsForSubcategories', {
              PlanArray: [planDetailsResponse.PlanDetails.PlanID],
              app: BrandSpecificConfig().header,
              CountryID: window.CountryID,
            })
            if (response.length) {
              const index = response.findIndex((brand) => brand.BrandName === BrandSpecificConfig().ClientName)
              if (index) {
                const firstBrand = response.splice(index, 1)
                dispatch(setAllBrands([...firstBrand, ...response]))
              } else {
                dispatch(setAllBrands(response))
              }
            }
            dispatch(setglobalLoaderStatus(false))
          } catch (e) {
            console.log(e)
            browserHistory.replace('/')
            dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
            dispatch(setglobalLoaderStatus(false))
          }
        }
        getPlanDetails()
      } else {
        localStorage.removeItem('subscription')
        dispatch(setSubscriptionDetails(subscriptionDetails))
      }
    }
  }, [params, isSubscribed, location, consumerId, userDetails, dispatch, enrollmentPlanList])

  const handleOnSubcategoryClick = useCallback(
    async (selectedItem) => {
      try {
        dispatch(setglobalLoaderStatus(true))
        let response = await postRequestAsync('ConsumerProduct/getPlanBrandsForSubcategories', {
          PlanArray: [planDetails.PlanDetails.PlanID],
          ProductSubCategoryID: selectedItem.ProductSubCategoryID,
          app: BrandSpecificConfig().header,
          CountryID: window.CountryID,
        })
        if (response?.length) {
          const index = response.findIndex((brand) => brand.BrandName === BrandSpecificConfig().ClientName)
          if (index) {
            const firstBrand = response.splice(index, 1)
            setSelectedBrands([...firstBrand, ...response])
          } else {
            setSelectedBrands(response)
          }
        }
        dispatch(setglobalLoaderStatus(false))
      } catch (e) {
        console.log(e)
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        dispatch(setglobalLoaderStatus(false))
      }
    },
    [dispatch, planDetails]
  )

  const scrollToFAQs = useCallback(() => {
    faqRef.current.scrollIntoView({ behavior: 'smooth' })
  }, [])

  const setFAQLink = useCallback(() => {
    const text = planDetails.PlanConfigDetails.PlanLegalTxt.replace('FAQs', '')
    return (
      <div className='mt20'>
        {text}{' '}
        <span
          className='button button-link fontLarge'
          onClick={() => {
            scrollToFAQs()
          }}
        >
          FAQs
        </span>
      </div>
    )
  }, [planDetails, scrollToFAQs])

  return planDetails ? (
    <>
      <div>
        <div className='subscription-container'>
          <div className='plan-details-container'>
            <img className='plan-image' src={planDetails.PlanDetails.planMeta.PlanImage} alt='plan' />
            <div className='plan-description-container'>
              <h4 className='plan-header mb20' dangerouslySetInnerHTML={{ __html: planDetails.PlanConfigDetails.MainDescriptionHeader }} />
              <h6 dangerouslySetInnerHTML={{ __html: planDetails.PlanConfigDetails.MainDescriptionSubHeader2 }} />
              {planDetails.PlanConfigDetails?.MainDescriptionList?.length > 0 && (
                <div className='plan-description-list'>
                  {planDetails.PlanConfigDetails.MainDescriptionList.map((data, index) => {
                    return (
                      <div key={index} className='mb8 plan-description-item'>
                        <img className='plan-description-image' src={'/commonAssets/star.png'} alt='star' />
                        <div key={data} dangerouslySetInnerHTML={{ __html: data }} />
                      </div>
                    )
                  })}
                </div>
              )}
              {planDetails.PlanConfigDetails.PlanLegalTxt && setFAQLink()}
              {subcategories?.length > 0 && (
                <>
                  <h6 className='mt20'>{getTranslationText('subscription', 'devicesCoveredAre')}</h6>
                  <div className='cover-container pb20'>
                    {subcategories.slice(0, 7).map((subcategory) => (
                      <div key={subcategory.ProductSubCategoryID} className='cover-item'>
                        {appConfigDetails?.productImageUrl && (
                          <img
                            className='cover-image'
                            src={`${appConfigDetails.productImageUrl}${subcategory.ProductSubCategoryID}.png`}
                            alt='subcategory'
                          />
                        )}
                        <div>{subcategory.ProductSubCategory}</div>
                      </div>
                    ))}
                    {subcategories.length > 7 && (
                      <div
                        className='cover-item cursor-pointer'
                        onClick={() => {
                          setShowCategoriesModal(true)
                        }}
                      >
                        <div className='plan-header'>
                          <span className='pr8'>{getTranslationText('subscription', 'seeMore')}</span>
                          <img width='8px' src={`/${appName}/images/chevron-01-copy-2@3x.png`} alt='subcategory' />
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
              {allBrands?.length > 0 && (
                <>
                  <h6 className='mt20'>{`${getTranslationText('subscription', 'brandsTitleCase')} ${getTranslationText(
                    'subscription',
                    'thatWeCover'
                  )}`}</h6>
                  <div className='cover-container cursor-pointer'>
                    {allBrands.slice(0, 7).map((brand) => (
                      <div key={brand.BrandID} className='cover-item'>
                        <img className='cover-image' src={brand.ImageUrl} alt='brand' />
                        <div>{brand.BrandName}</div>
                      </div>
                    ))}
                    {allBrands.length > 7 && (
                      <div
                        className='cover-item'
                        onClick={() => {
                          setShowBrandsModal(true)
                        }}
                      >
                        <div className='plan-header'>
                          <span className='pr8'>{getTranslationText('subscription', 'seeMore')}</span>
                          <img width='8px' src={`/${appName}/images/chevron-01-copy-2@3x.png`} alt='brand' />
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
          {children}
        </div>
        {planDetails.PlanConfigDetails?.Section1?.length > 0 && (
          <div className='plan-section-container'>
            {planDetails.PlanConfigDetails.Section1.map((section) => {
              return (
                <div key={section.SectionHeader} className='section-container'>
                  <h4>{section.SectionHeader}</h4>
                  {section.SectionList?.length > 0 && (
                    <div className='section-description'>
                      {section.SectionList.map((item) => (
                        <div key={item.text1} className='section-details'>
                          <img className='section-image' src={item.image} alt='section' />
                          {item.text2 ? (
                            <div className='mt8 section-description-text'>
                              <h5 className='font-medium mb0' dangerouslySetInnerHTML={{ __html: item.text1 }} />
                              <div dangerouslySetInnerHTML={{ __html: item.text2 }} />
                            </div>
                          ) : (
                            <div dangerouslySetInnerHTML={{ __html: item.text }} />
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        )}
        {planDetails.PlanConfigDetails?.FaqList?.length > 0 && (
          <div ref={faqRef} className='plan-section-container'>
            <div className='section-container'>
              <h4 className='mb20'>{getTranslationText('common', 'faq')}</h4>
              {planDetails.PlanConfigDetails?.FaqList.slice(0, seeMoreLength).map((faq) => (
                <Accordion
                  showAccordion={showAccordion}
                  openIcon={
                    <img src={`/${appName}/images/chevron-01-copy-2@3x.png`} style={{ maxHeight: '15px', transform: 'rotate(-90deg)' }} />
                  }
                  closeIcon={<img src={`/${appName}/images/downarrow@3x.png`} style={{ maxHeight: '8px' }} />}
                  key={faq.title}
                  title={<h6 className='font-family--medium'>{faq.title}</h6>}
                  className={{
                    accordionContainer: 'faq-accordion-container',
                    accordionBody: 'pl5 pr5',
                  }}
                  onClick={() => {
                    setShowAccordion((showAccordion) => !showAccordion)
                  }}
                >
                  <p dangerouslySetInnerHTML={{ __html: faq.body }} />
                </Accordion>
              ))}
              {planDetails.PlanConfigDetails?.FaqList.length > 5 && seeMoreLength < planDetails.PlanConfigDetails?.FaqList.length && (
                <div className='see-more-container mt20'>
                  <Button
                    className='button-link'
                    handleOnClick={() => {
                      setSeeMoreLength((seeMoreLength) => seeMoreLength + 5)
                    }}
                  >
                    <span className='pr5'>{getTranslationText('subscription', 'seeMore')}</span>
                    <img src={`/${appName}/images/downarrow@3x.png`} style={{ maxHeight: '6px' }} />
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <Modal
        className='plan-modal'
        showModal={showCategoriesModal}
        closeModal={() => {
          setShowCategoriesModal(false)
        }}
        showCloseBtn={true}
      >
        <Modal.Header>
          <h4 className='text-center'>{getTranslationText('subscription', 'devicesCoveredAre')}</h4>
        </Modal.Header>
        <Modal.Body className='modal-body--scroll'>
          <div className='cover-container mt20' style={{ maxWidth: '100%' }}>
            {subcategories?.map((subcategory) => (
              <div key={subcategory.ProductSubCategoryID} className='cover-item'>
                {appConfigDetails?.productImageUrl && (
                  <img
                    className='cover-image'
                    src={`${appConfigDetails.productImageUrl}${subcategory.ProductSubCategoryID}.png`}
                    alt='subcategory'
                  />
                )}
                <div>{subcategory.ProductSubCategory}</div>
              </div>
            ))}
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        className='plan-modal'
        showModal={showBrandsModal}
        closeModal={() => {
          setShowBrandsModal(false)
        }}
        showCloseBtn={true}
      >
        <Modal.Header>
          <div style={{ maxWidth: '80%', margin: '0 auto' }}>
            <h4 className='text-center'>{getTranslationText('subscription', 'exploreBrands')}</h4>
            <label className='mt20'>{getTranslationText('addingDeviceWidget', 'selectCategory')}</label>
            <Dropdown
              id='category'
              options={subcategories}
              handleOnChange={(selectedItem) => {
                setSelectedCategory(selectedItem)
                handleOnSubcategoryClick(selectedItem)
              }}
              value={selectedCategory.value || ''}
              // optionLength={_.get(this, 'props.user.countries.length', 0)}
              isSearchable
              placeholder={getTranslationText('addingDeviceWidget', 'selectCategory')}
              searchText={getTranslationText('addingDeviceWidget', 'selectCategory')}
              classObject={{ container: 'mb20' }}
              filterBy='value'
              showTooltip
            />
            {selectedCategory?.value && (
              <p className='text-center price-container'>
                {selectedCategory.value} {getTranslationText('subscription', 'brands')} {getTranslationText('subscription', 'thatWeCover')}
              </p>
            )}
          </div>
        </Modal.Header>
        <Modal.Body className='modal-body--scroll'>
          <div className='cover-container mt20' style={{ maxWidth: '100%' }}>
            {selectedBrands?.map((brand) => (
              <div key={brand.BrandID} className='cover-item'>
                <img className='cover-image' src={brand.ImageUrl} alt='subcategory' />
                <div>{brand.BrandName}</div>
              </div>
            ))}
          </div>
        </Modal.Body>
      </Modal>
    </>
  ) : (
    ''
  )
}

export default PlanDetails
