import axios from 'axios'
import { api } from '../config/variables'
import { hashKey, getTzOffset } from '../utils/AESEncrypt.js'
import { BrandSpecificConfig } from '../utils/constants.js'
import packageJson from '../../package.json'

const IP_LOOKUP_DEFAULT_COUNTRY_CODE = ''

export default async () => {
  const url = `${api.consumerUri}/location/country`
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    TimeZone: getTzOffset(),
    dr9se2q: hashKey({ tzU: new Date().getTime(), appC: '4T2E5RTVX5' }),
    co1cx2: window.iv,
    App: BrandSpecificConfig().header,
    appVersion: packageJson.version,
  }
  const options = { headers }
  const onSuccess = ({ data: { country = IP_LOOKUP_DEFAULT_COUNTRY_CODE } = {} }) => country
  const onError = (error) => {
    console.error('IP Lookup', error)
    return Promise.resolve('')
  }
  return axios.post(url, {}, options).then(onSuccess).catch(onError)
}
