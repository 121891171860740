import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { browserHistory, Link } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Checkbox, Modal } from 'sfy-react'
import PlanDetails from '../Subcomponents/PlanDetails'
import { getTranslationText } from '../../../utils/languageTranslation'
import '../Subscription.scss'
import './Review.scss'
import { setglobalLoaderStatus, setToaster } from '../../../store/toastsAndLoaders'
import { setPlanPaymentDetails } from '../../../store/storeReducer'
import { postRequestAsync } from '../../../api/genericAPIs'
import {
  setAppLegalConfigs,
  setDataConsentLink,
  setPrivacyLink,
  setTncLink,
  setUserAddresses,
  setCountryConfig,
  setStateListConfig,
} from '../../../store/user'
import { setConsumerProductDetails, setErrors, setRequiredDetails, setSubscriptionDetails } from '../../../store/subscription'
import { setPayableValue } from '../../../store/payment'
import { storeLastKnowPathName } from '../../../store/location'
import { BrandSpecificConfig, legalConfigAttributes, legalConfigCodes } from '../../../utils/constants'
import { getConsentTextChunks, replacePlaceholders, getPlaceholders, getFormattedAddress } from '../../../utils/functions'
import pushToAnalytics from '../../../utils/Analytics'
import { fallbackAddressConfig } from '../../../utils/constants'
import { RadioBox } from '../../../components/UIComponents'
import TaxAddressForm from '../../../components/UIComponents/TaxAddressForm'
import { regexCheck } from '../../../utils/regex'
import { setTaxAddress } from '../../../store/storeReducer'

const Review = (props) => {
  const dispatch = useDispatch()

  const isSubscribed = useSelector((state) => state.subscription.isSubscribed)
  const accessToken = useSelector((state) => state.user.consumerDetails?.data?.accessToken?.ID)
  const userDetails = useSelector((state) => state.user.userDetails)
  const user = useSelector((state) => state.user)
  const appConfigDetails = useSelector((state) => state.user.appConfigDetails)
  const planDetails = useSelector((state) => state.subscription.planDetails)
  const deviceDetails = useSelector((state) => state.subscription.deviceDetails)
  const requiredDetails = useSelector((state) => state.subscription.requiredDetails)
  const errors = useSelector((state) => state.subscription.errors)
  const selectedCountryData = useSelector((state) => state.user.selectedCountryData)
  const userAddresses = useSelector((state) => state.user.locations)
  const addressConfig = useSelector((state) => state.user?.countryAddressConfig?.addressConfig || fallbackAddressConfig)
  const enrollmentPlanList = useSelector((state) => state.subscription.enrollmentPlanList)
  const confirmedTaxAddress = useSelector((state) => state.store.taxAddress)

  const [consent, setConsent] = useState()
  const [links, setLinks] = useState({})
  const [purchaseDetails, setPurchaseDetails] = useState({})
  const [requiredParams, setRequiredParams] = useState([])
  const [groupedDetails, setGroupedDetails] = useState()
  const [showDevicesModal, setShowDevicesModal] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)
  const [isPaymentInProcess, setIsPaymentInProcess] = useState(false)
  const [isEditAddress, setIsEditAddress] = useState(false)
  const [isAddAddress, setIsAddAddress] = useState(false)
  const [taxAddressId, setTaxAddressId] = useState()

  useEffect(() => {
    const handleBeforeUnloadEvent = (event) => {
      event.preventDefault()
      event.returnValue = 'Changes that you made may not be saved.'
      return 'Changes that you made may not be saved.'
    }
    const handleUnloadEvent = () => {
      dispatch(setSubscriptionDetails({ isSubscribed: false }))
    }
    window.addEventListener('unload', handleUnloadEvent)
    window.addEventListener('beforeunload', handleBeforeUnloadEvent)
    return () => {
      window.removeEventListener('unload', handleUnloadEvent)
      window.removeEventListener('beforeunload', handleBeforeUnloadEvent)
    }
  }, [dispatch])

  useEffect(() => {
    props.router.setRouteLeaveHook(props.route, (data) => {
      if (data.pathname !== '/subscription' && !isPaymentInProcess) {
        const isConfirmed = window.confirm('Reload site? Changes that you made may not be saved.')
        if (isConfirmed) {
          dispatch(setSubscriptionDetails({ isSubscribed: false }))
          return true
        }
        return false
      }
      return true
    })
  }, [isPaymentInProcess, props.route, dispatch, props.router])

  useEffect(() => {
    if (isSubscribed && accessToken && appConfigDetails && planDetails) {
      localStorage.removeItem('subscription')
      const getConsents = async () => {
        try {
          dispatch(setglobalLoaderStatus(true))
          dispatch(
            setRequiredDetails({
              zipCode: '',
              landmark: '',
              userlandmark: '',
              city: '',
              state: '',
              lat: '',
              lng: '',
              address: '',
              consent: false,
              purchaseDetails: false,
            })
          )
          const response = await postRequestAsync('Consumer/getApplicationLegalConfigs', {
            CountryCode: window.CountryCode,
            app: BrandSpecificConfig().header,
          })
          if (response?.LegalConfigs?.length) {
            dispatch(setAppLegalConfigs(response))
            const selectedConsent = response.LegalConfigs.find(
              (config) => config.legalConfig.LegalConfigCode === legalConfigCodes[window.CountryCode.toLowerCase()].subscription
            )
            if (selectedConsent) {
              setLinks({
                tnc: { label: 'termsAndCondition', link: planDetails.PlanDetails.planMeta.TermsAndConditionsLink },
                privacy: { label: 'privacyPolicy', link: appConfigDetails.Privacy },
              })
              setConsent(selectedConsent)
            }
          }
          dispatch(setglobalLoaderStatus(false))
        } catch (e) {
          console.log(e)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          dispatch(setglobalLoaderStatus(false))
        }
      }
      getConsents()
    } else {
      browserHistory.replace('/subscription')
    }
  }, [isSubscribed, accessToken, appConfigDetails, planDetails, dispatch])

  useEffect(() => {
    if (deviceDetails?.length && userDetails?.consumerID && accessToken) {
      const getAllDetails = async () => {
        try {
          dispatch(setglobalLoaderStatus(true))
          let response = await postRequestAsync(
            'Consumer/createBulkConsumerProduct',
            {
              consumerProducts: deviceDetails.map((device) => {
                const object = {
                  consumerID: userDetails.consumerID,
                  brandID: device.brand.BrandID,
                  productID: device.product.ProductID,
                }
                if (device.serialNumber) {
                  object.productUniqueID = device.serialNumber
                }
                return object
              }),
              countryCode: window.CountryCode,
              countryID: window.CountryID,
            },
            {
              Authorization: accessToken,
            }
          )
          dispatch(setConsumerProductDetails(response))
          dispatch(setglobalLoaderStatus(false))
        } catch (e) {
          console.log(e)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          dispatch(setglobalLoaderStatus(false))
        }
      }
      getAllDetails()
    }
  }, [deviceDetails, userDetails, accessToken, dispatch])

  useEffect(() => {
    if (accessToken && userDetails?.consumerID && appConfigDetails && planDetails?.PlanDetails) {
      const getEligibilityDetails = async () => {
        try {
          dispatch(setglobalLoaderStatus(true))
          let response = await postRequestAsync('ConsumerProduct/checkConsumerEligibility', {
            ConsumerID: userDetails.consumerID,
            CountryID: window.CountryID,
            app: appConfigDetails.app,
            PlanID: planDetails.PlanDetails.PlanID,
          })
          pushToAnalytics('Plan Sales - Eligibility Check', {
            'Logged In': 'Yes', // Only logged in users can access review page
            'Plan Name': planDetails.PlanDetails.PlanName,
            'Error Status Code': response[0].EligibilityStatusCode || '',
            'Error Status Message': response[0].Message || '',
            'Email ID': userDetails?.emailID || '',
            ConsumerID: userDetails.consumerID,
          })
          if (response && response.length && response[0].isEligible) {
            pushToAnalytics('Plan Sales - Purchase Review Page', {
              'Logged In': 'Yes', // Only logged in users can access review page
              'Plan Name': planDetails.PlanDetails.PlanName,
              'Email ID': userDetails?.emailID || '',
              ConsumerID: userDetails.consumerID,
            })
            response = await postRequestAsync(
              'Billing/getRequiredPaymentParamsList',
              {
                AuthID: accessToken,
                CountryID: window.CountryID,
              },
              {
                Authorization: accessToken,
              }
            )
            if (response && response.length > 0) {
              try {
                dispatch(setglobalLoaderStatus(true))
                const [countryConfigResponse, stateListResponse, userLocationsResponse] = await Promise.all([
                  postRequestAsync(
                    'Consumer/getCountries',
                    { countryConfig: true, specificCountryID: window.CountryID },
                    { Authorization: accessToken }
                  ),
                  postRequestAsync(
                    '/ConsumerServicerequest/getStateCodes',
                    {},
                    {
                      Authorization: accessToken,
                    }
                  ),
                  postRequestAsync(
                    'ConsumerFavouritelocation/getUserLocations',
                    { ConsumerID: userDetails.consumerID },
                    { Authorization: accessToken }
                  ),
                ])
                dispatch(setUserAddresses(userLocationsResponse))
                dispatch(setCountryConfig(countryConfigResponse.countries[0].countryConfig))
                dispatch(setStateListConfig(stateListResponse))
                setRequiredParams(response)
                dispatch(setglobalLoaderStatus(false))
              } catch (e) {
                dispatch(setglobalLoaderStatus(false))
                dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
                console.log(e)
              }
            }
          } else {
            if (response && response.length && response[0]) {
              setIsPaymentInProcess(true)
              dispatch(
                setErrors({
                  eligibility: { type: 'error', message: response[0].Message || getTranslationText('error', 'somethingWentWrong') },
                })
              )
            }
            throw response
          }
          dispatch(setglobalLoaderStatus(false))
        } catch (e) {
          console.log(e)
          browserHistory.replace(`/subscription/${planDetails.PlanDetails.PlanCode}`)
          dispatch(setglobalLoaderStatus(false))
        }
      }
      getEligibilityDetails()
    }
  }, [accessToken, appConfigDetails, userDetails, planDetails, dispatch])

  useEffect(() => {
    let result = []
    if (deviceDetails?.length) {
      result = deviceDetails.reduce((r, a) => {
        r[a.category.value] = r[a.category.value] || []
        r[a.category.value].push(a)
        return r
      }, Object.create(null))
    }
    setGroupedDetails(result)
  }, [deviceDetails])

  const handleOnTaxAddressChange = (addrObject) => {
    const newErrors = {}
    if (addrObject.zipCode) {
      const pincode = addressConfig.find((config) => !!config && (config?.keyName === 'PinCode' || config?.keyName === 'Zipcode'))
      if (!regexCheck('onChange', pincode?.regexFunc?.toLowerCase(), addrObject.zipCode, Math.max(...pincode.length))) {
        delete addrObject['zipCode']
      }
    }
    if (addrObject.state || isEditAddress) {
      const eligibleStateIDs =
        enrollmentPlanList.find((enrollmentPlan) => planDetails.PlanDetails.PlanID === enrollmentPlan.PlanID).PlanConfig.EligibleStateIDs ||
        []
      const selectedState = user.stateAddressListConfig.find((state) => addrObject.state === state.State)
      if (
        !(isEditAddress && !addrObject.state) &&
        selectedState &&
        eligibleStateIDs.length > 0 &&
        !eligibleStateIDs.includes(selectedState.StateID)
      ) {
        newErrors.state = getTranslationText('error', 'unavailableAtThisLocation')
      } else {
        newErrors.state = ''
        newErrors.cta = ''
      }
    }
    if (errors?.state) {
      addrObject = {
        ...addrObject,
        ...{ zipCode: '', userlandmark: '', address: '', city: '', state: '' },
      }
    }
    dispatch(setErrors(newErrors))
    setPurchaseDetails({})
    dispatch(setRequiredDetails(addrObject))
  }

  const getPlanPurchaseDetails = async (taxAddress) => {
    try {
      dispatch(setglobalLoaderStatus(true))
      const response = await postRequestAsync(
        'ConsumerProduct/getPlanPurchaseDetails',
        {
          PlanArray: [
            {
              PlanID: planDetails.PlanDetails.PlanID,
              PlanPriceID: planDetails.PlanDetails.planPrice[0].PlanPriceID,
            },
          ],
          CountryID: window.CountryID,
          app: appConfigDetails.app,
          Zipcode: taxAddress.PinCode,
          TaxationType: planDetails.PlanConfigDetails.TaxationType,
          Address: {
            AddressLine1: taxAddress.UserLandmark,
            AddressLine2: taxAddress.Address,
            City: taxAddress.City,
            State: taxAddress.State,
            Zipcode: taxAddress.PinCode,
          },
          PlanPriceBillingConfigID: planDetails.PlanDetails.planPrice[0].planPriceBillingConfig[0].PlanPriceBillingConfigID || undefined,
          isSubscriptionPayment: planDetails.PlanDetails.planPrice[0].planPriceBillingConfig[0].EMIAmount > 0,
        },
        {
          Authorization: accessToken,
        }
      )
      pushToAnalytics('Plan Sales - Zipcode Validation', {
        'Logged In': 'Yes', // Only logged in users can access Review page
        'Plan Name': planDetails.PlanDetails.PlanName,
        'Error Status Message': response.message || '',
        'Email ID': userDetails?.emailID || '',
        ConsumerID: userDetails.consumerID,
        Zipcode: taxAddress.PinCode,
      })
      dispatch(setRequiredDetails({ purchaseDetails: true }))
      setPurchaseDetails(response)
      dispatch(setglobalLoaderStatus(false))
    } catch (e) {
      console.log(e)
      setPurchaseDetails({})
      dispatch(setRequiredDetails({ purchaseDetails: false }))
      dispatch(setErrors({ state: { type: 'error', value: getTranslationText('error', 'taxCalcultaionError') } }))
      dispatch(setglobalLoaderStatus(false))
    }
  }

  const validateFields = useCallback(() => {
    let isValid = true
    if (requiredParams?.length > 0 && (!confirmedTaxAddress || Object.keys(confirmedTaxAddress).length < 1)) return false
    addressConfig.forEach((config) => {
      if ((config.keyName === 'Zipcode' || config.keyName === 'PinCode') && config.display) {
        let pincodeLengthCheck = config['length'].some((i) => [confirmedTaxAddress?.PinCode?.length].includes(i))
        let isPincodeValid = config['mandatory'] ? !!pincodeLengthCheck : confirmedTaxAddress?.PinCode?.length ? !!pincodeLengthCheck : true
        isValid = isValid && isPincodeValid
      } else if (config.display) {
        let isFieldValid = config['mandatory'] ? !!confirmedTaxAddress?.[config.keyName] : true
        isValid = isValid && isFieldValid
      }
    })
    isValid = isValid && requiredDetails?.consent && requiredDetails?.purchaseDetails

    return isValid
  }, [requiredDetails, requiredParams, confirmedTaxAddress, addressConfig])

  useEffect(() => {
    setIsDisabled(!validateFields())
  }, [validateFields])

  const handleOnPayClick = async () => {
    try {
      dispatch(setglobalLoaderStatus(true))
      setIsPaymentInProcess(true)
      let attributes = []
      let placeholders = getPlaceholders(consent.legalConfig.Text)
      placeholders.length &&
        placeholders.forEach((placeholder) => {
          attributes.push({ AttributeType: legalConfigAttributes[placeholder], AttributeValue: links[placeholder].link })
        })
      let requestObject = [
        {
          ApplicationLegalConfigID: consent.ApplicationLegalConfigID,
          ConsumerID: userDetails.consumerID,
          ConsentText: replacePlaceholders(consent.legalConfig.Text),
          Attributes: attributes,
        },
      ]
      await postRequestAsync('Consumer/logConsumerConsents', { ConsentArray: requestObject })
      requestObject = {
        ConsumerID: userDetails.consumerID,
        CountryID: window.CountryID,
        Zipcode: confirmedTaxAddress.PinCode,
        Address: {
          AddressLine1: confirmedTaxAddress.UserLandmark,
          AddressLine2: confirmedTaxAddress.Address,
          City: confirmedTaxAddress.City,
          State: confirmedTaxAddress.State,
          Zipcode: confirmedTaxAddress.PinCode,
        },
        CurrencyID: purchaseDetails.CurrencyID,
        PlanArray: [
          {
            PlanID: planDetails.PlanDetails.PlanID,
            PlanPriceID: planDetails.PlanDetails.planPrice[0].PlanPriceID,
          },
        ],
        ProductName: '',
        TaxationType: planDetails.PlanConfigDetails.TaxationType,
        action: 'update',
      }
      const response = await postRequestAsync(
        'OnlinePayment/getPayableAmount',
        { ...requestObject, isSubscriptionPayment: true },
        {
          Authorization: accessToken,
        }
      )
      dispatch(setPayableValue(response))
      dispatch(setPlanPaymentDetails(response))
      dispatch(
        storeLastKnowPathName({
          path: '/subscription/review',
          nextPath: '/subscription/success',
        })
      )
      dispatch(setglobalLoaderStatus(false))
      pushToAnalytics(
        'Plan Sales - Payment Initiated',
        {
          'Customer Name': userDetails.name,
          Email: userDetails.emailID,
          'Mobile Number': userDetails.mobileNo,
          ConsumerID: userDetails.consumerID,
          'Number of Devices added': deviceDetails?.length || 0,
          Consent: requiredDetails.consent,
          'Plan Name': planDetails.PlanDetails.PlanName,
          'Base Price': purchaseDetails.BaseAmount,
          'Tax Value': purchaseDetails.totalTaxAmount,
          'Total Price': purchaseDetails.Amount,
          'Payment Frequency': 'Monthly',
        },
        user
      )
      browserHistory.replace('/payment')
    } catch (e) {
      console.log(e)
      setIsPaymentInProcess(false)
      dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
      dispatch(setglobalLoaderStatus(false))
    }
  }

  const handleRedirection = (type, customLink) => {
    if (appConfigDetails.SkipRedirection) {
      let link = customLink
      if (type === 'tnc') {
        link = planDetails.PlanDetails.planMeta.TermsAndConditionsLink || appConfigDetails.Terms
        dispatch(setTncLink(link))
      } else if (type === 'privacy') {
        link = appConfigDetails.Privacy
        dispatch(setPrivacyLink(link))
      } else if (type === 'data') {
        link = appConfigDetails.ConsentURL
        dispatch(setDataConsentLink(link))
      }
      return link
    }
  }

  const handleTaxAddressSelection = (e) => {
    setTaxAddressId(parseInt(e.currentTarget.id))
    dispatch(setErrors({ state: '' }))
  }

  const handleSaveAddress = async () => {
    dispatch(setglobalLoaderStatus(true))
    const eligibleStateIDs =
      enrollmentPlanList.find((enrollmentPlan) => planDetails.PlanDetails.PlanID === enrollmentPlan.PlanID).PlanConfig.EligibleStateIDs ||
      []
    const selectedState = user.stateAddressListConfig.find((state) => requiredDetails.state === state.State)
    if (eligibleStateIDs.length > 0 && !eligibleStateIDs.includes(selectedState.StateID)) {
      dispatch(setErrors({ state: getTranslationText('error', 'unavailableAtThisLocation') }))
      dispatch(setglobalLoaderStatus(false))
      return
    }

    try {
      await postRequestAsync(
        '/Billing/validateAddress',
        {
          CountryID: window.CountryID,
          CountryCode: selectedCountryData.CountryCode,
          Address: {
            AddressLine1: requiredDetails.userlandmark,
            AddressLine2: requiredDetails.address,
            State: requiredDetails.state,
            City: requiredDetails.city,
            Zipcode: requiredDetails.zipCode,
          },
        },
        {
          Authorization: accessToken,
        }
      )

      const addressAPI = isEditAddress ? '/ConsumerFavouritelocation/updateLocation' : '/ConsumerFavouritelocation/addLocation'
      const addLocationResponse = await postRequestAsync(
        addressAPI,
        {
          Landmark: requiredDetails.landmark,
          UserLandmark: requiredDetails.userlandmark,
          Address: requiredDetails.address,
          PinCode: requiredDetails.zipCode,
          Lat: requiredDetails.lat,
          Lng: requiredDetails.lng,
          AddressType: requiredDetails?.addressType || 'Other',
          Country: selectedCountryData.CountryCode,
          State: requiredDetails.state,
          StateID: user.stateAddressListConfig.find((state) => state.State === requiredDetails.state).StateID,
          City: requiredDetails.city,
          ConsumerID: userDetails.consumerID,
          ...(isEditAddress && { ConsumerFavouriteLocationID: requiredDetails.ConsumerFavouriteLocationID }),
        },
        {
          Authorization: accessToken,
        }
      )
      dispatch(setTaxAddress(addLocationResponse))
      const userLocationResponse = await postRequestAsync(
        'ConsumerFavouritelocation/getUserLocations',
        { ConsumerID: userDetails.consumerID },
        { Authorization: accessToken }
      )

      dispatch(setUserAddresses(userLocationResponse))
      setTaxAddressId(addLocationResponse.ConsumerFavouriteLocationID)
      setIsEditAddress(false)
      setIsAddAddress(false)
      await getPlanPurchaseDetails(addLocationResponse)
      dispatch(setglobalLoaderStatus(false))
    } catch (err) {
      dispatch(setglobalLoaderStatus(false))
      dispatch(setErrors({ cta: getTranslationText('error', 'incorrectAddress') }))
    }
  }

  const handleOnEditClick = (ConsumerFavouriteLocationID) => {
    const address = userAddresses.find((address) => address.ConsumerFavouriteLocationID === ConsumerFavouriteLocationID)
    dispatch(
      setRequiredDetails({
        landmark: address.Landmark,
        userlandmark: address.UserLandmark,
        address: address.Address,
        state: address.State,
        city: address.City,
        zipCode: address.PinCode,
        ConsumerFavouriteLocationID: ConsumerFavouriteLocationID,
        addressType: address.AddressType,
        lat: address.Lat,
        lng: address.Lng,
      })
    )
    setIsEditAddress(true)
    dispatch(setTaxAddress(null))
  }

  const handleTaxAddressConfirmation = async () => {
    dispatch(setglobalLoaderStatus(true))
    setIsEditAddress(false)
    setIsAddAddress(false)
    try {
      const selectedAddress = userAddresses.find((address) => address.ConsumerFavouriteLocationID === taxAddressId)
      const eligibleStateIDs =
        enrollmentPlanList.find((enrollmentPlan) => planDetails.PlanDetails.PlanID === enrollmentPlan.PlanID).PlanConfig.EligibleStateIDs ||
        []
      const selectedState = user.stateAddressListConfig.find((state) => selectedAddress.State === state.State)
      if (!selectedState) {
        dispatch(setErrors({ state: getTranslationText('error', 'stateInvalid') }))
        dispatch(setglobalLoaderStatus(false))
        return
      }
      if (eligibleStateIDs.length > 0 && !eligibleStateIDs.includes(selectedState.StateID)) {
        dispatch(setErrors({ state: getTranslationText('error', 'unavailableAtThisLocation') }))
        dispatch(setglobalLoaderStatus(false))
        return
      }
      await postRequestAsync(
        '/Billing/validateAddress',
        {
          CountryID: window.CountryID,
          CountryCode: selectedCountryData.CountryCode,
          Address: {
            AddressLine1: selectedAddress.UserLandmark,
            AddressLine2: selectedAddress.Address,
            State: selectedAddress.State,
            City: selectedAddress.City,
            Zipcode: selectedAddress.PinCode,
          },
        },
        {
          Authorization: accessToken,
        }
      )
      dispatch(setTaxAddress(selectedAddress))
      await getPlanPurchaseDetails(selectedAddress)
      dispatch(setglobalLoaderStatus(false))
    } catch (err) {
      console.log(err)
      dispatch(setErrors({ state: getTranslationText('error', 'incorrectAddress') }))
      dispatch(setglobalLoaderStatus(false))
    }
  }

  const handleOnSavedAddressesClick = () => {
    dispatch(setErrors({ state: '' }))
    setIsEditAddress(false)
    setIsAddAddress(false)
    dispatch(setTaxAddress(null))
  }

  return isSubscribed ? (
    <div className='plan-subscription-container'>
      <PlanDetails params={props.params}>
        <div className='subscription-wrapper'>
          <div className='subscription-input-container'>
            {requiredParams?.length > 0 && !confirmedTaxAddress ? (
              userAddresses.length > 0 && !isEditAddress && !isAddAddress ? (
                <>
                  <h6 className='pb20'>
                    {getTranslationText('common', 'hi')} {userDetails.name},
                    <div className='lightFontStyle'>{getTranslationText('store', 'selectAddress')}</div>
                  </h6>
                  <div className='subscription-tax-address-container'>
                    <Button
                      type='button'
                      text={getTranslationText('profile', 'addNewAddress') + ' >'}
                      classes='button-link boldFontStyle mb8 subscription-link-button'
                      handleOnClick={() => {
                        dispatch(
                          setRequiredDetails({
                            zipCode: '',
                            landmark: '',
                            userlandmark: '',
                            city: '',
                            state: '',
                            lat: '',
                            lng: '',
                            address: '',
                            addresType: '',
                          })
                        )
                        setIsAddAddress(true)
                        dispatch(setTaxAddress(null))
                      }}
                    />
                    {userAddresses.map((address) => (
                      <>
                        <RadioBox
                          isSelected={address.ConsumerFavouriteLocationID === taxAddressId}
                          handleOnClick={handleTaxAddressSelection}
                          key={address.ConsumerFavouriteLocationID}
                          id={address.ConsumerFavouriteLocationID}
                        >
                          <h6>{address.AddressType}</h6>
                          <div>{getFormattedAddress(address, addressConfig)}</div>
                          <Button
                            type='button'
                            text={getTranslationText('common', 'edit')}
                            classes='button-link boldFontStyle mt8 subscription-link-button'
                            handleOnClick={() => {
                              handleOnEditClick(address.ConsumerFavouriteLocationID)
                            }}
                          />
                        </RadioBox>
                        {errors !== null && errors?.state?.length > 0 && address.ConsumerFavouriteLocationID === taxAddressId && (
                          <div className='error-message font-small mb20'>{errors.state}</div>
                        )}
                      </>
                    ))}
                    <Button
                      type='button'
                      isDisabled={!taxAddressId}
                      text={getTranslationText('common', 'continue')}
                      classes='button-ghost'
                      onClick={handleTaxAddressConfirmation}
                    />
                  </div>
                </>
              ) : (
                <>
                  <h6 className='pb20'>
                    {getTranslationText('common', 'hi')} {userDetails.name},
                    <div className='lightFontStyle'>{getTranslationText('store', 'enterAddress')}</div>
                  </h6>
                  <TaxAddressForm
                    requiredParams={requiredParams}
                    handleOnTaxAddressChange={handleOnTaxAddressChange}
                    errors={errors}
                    requiredDetails={requiredDetails}
                    user={user}
                    addressConfig={addressConfig}
                    handleSaveAddress={handleSaveAddress}
                    isEditAddress={isEditAddress}
                    handleOnSavedAddressesClick={handleOnSavedAddressesClick}
                    userAddresses={userAddresses}
                    setErrors={(errors) => {
                      dispatch(setErrors({ ...errors }))
                    }}
                    eligibleStateIDs={
                      enrollmentPlanList?.find((enrollmentPlan) => planDetails.PlanDetails.PlanID === enrollmentPlan.PlanID).PlanConfig
                        .EligibleStateIDs || []
                    }
                  />
                </>
              )
            ) : (
              confirmedTaxAddress != null &&
              Object.keys(confirmedTaxAddress).length > 0 && (
                <>
                  <h6 className='pb20'>
                    {getTranslationText('common', 'hi')} {userDetails.name},
                    <div className='lightFontStyle'>{getTranslationText('subscription', 'proceedToSubscribe')}</div>
                  </h6>
                  <div className='subscription-tax-address-container'>
                    {userAddresses.length > 0 && (
                      <Button
                        type='button'
                        text={getTranslationText('store', 'savedAddresses') + ' >'}
                        classes='button-link boldFontStyle mt8 mb8 subscription-link-button'
                        handleOnClick={() => {
                          handleOnSavedAddressesClick()
                        }}
                      />
                    )}
                    <RadioBox isSelected={false} handleOnClick={() => {}} showRadio={false}>
                      <h6>{confirmedTaxAddress.AddressType}</h6>
                      <div>{getFormattedAddress(confirmedTaxAddress, addressConfig)}</div>
                      <Button
                        type='button'
                        text={getTranslationText('common', 'edit')}
                        classes='button-link boldFontStyle mt8 subscription-link-button'
                        handleOnClick={() => {
                          handleOnEditClick(confirmedTaxAddress.ConsumerFavouriteLocationID)
                        }}
                      />
                    </RadioBox>
                    {errors !== null && errors?.state?.length > 0 && <div className='error-message font-small mb20'>{errors.state}</div>}
                  </div>
                </>
              )
            )}
          </div>
          {deviceDetails?.length > 0 && (
            <div className='subscription-save-container mt20 subscription-device-background'>
              <h6 className='mb0'>{getTranslationText('subscription', 'devicesAdded')}</h6>
              <h6 className='mb0'>
                <span>{deviceDetails?.length}</span>
                <Button
                  handleOnClick={() => {
                    setShowDevicesModal(true)
                  }}
                  style={{ minWidth: 'auto', fontSize: 'inherit' }}
                  className='button-link pl8'
                >
                  {getTranslationText('subscription', 'viewDevices')}
                </Button>
              </h6>
            </div>
          )}
          <div className='p20'>
            <p>{getTranslationText('subscription', 'priceBreakup')}</p>
            <div className='price-container'>
              <div className='price-item'>
                <div>{getTranslationText('subscription', 'basePrice')}</div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: `${planDetails?.PlanDetails?.planPrice[0].CurrencySymbol || ''}${
                      planDetails &&
                      planDetails.PlanDetails.planPrice[0] &&
                      planDetails.PlanDetails.planPrice[0].planPriceBillingConfig[0] &&
                      planDetails.PlanDetails.planPrice[0].planPriceBillingConfig[0].EMIAmount
                        ? planDetails?.PlanDetails?.planPrice[0]?.planPriceBillingConfig[0].EMIAmount
                        : (planDetails && planDetails.PlanDetails.planPrice[0].ServiceBasePrice) ||
                          (planDetails && planDetails.PlanDetails.planPrice[0].InsuranceBasePrice)
                        ? planDetails?.PlanDetails?.planPrice[0]?.ServiceBasePrice +
                          planDetails?.PlanDetails?.planPrice[0]?.InsuranceBasePrice
                        : planDetails?.PlanDetails?.planPrice[0]?.PlanPrice || '0'
                    }`,
                  }}
                />
              </div>
              <div className='price-item'>
                <div>{getTranslationText('subscription', 'tax')}</div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: `${planDetails?.PlanDetails?.planPrice[0].CurrencySymbol || purchaseDetails.CurrencySymbol || ''}${
                      purchaseDetails.totalTaxAmount || '0'
                    }`,
                  }}
                />
              </div>
            </div>
          </div>
          <h6 className='total-amount'>
            <span>{getTranslationText('store', 'totalAmountLabel')}</span>
            <span
              dangerouslySetInnerHTML={{
                __html: `${planDetails?.PlanDetails?.planPrice[0].CurrencySymbol || purchaseDetails.CurrencySymbol || ''}${
                  purchaseDetails.Amount ||
                  planDetails?.PlanDetails?.planPrice[0]?.TotalPrice ||
                  planDetails?.PlanDetails?.planPrice[0]?.PlanPrice ||
                  '0'
                }`,
              }}
            />
          </h6>
          {consent?.legalConfig?.Text && (
            <div className='subscription-input-container'>
              <Checkbox
                type='checkbox'
                name='consent'
                value={requiredDetails?.consent}
                handleOnChange={(e) => {
                  dispatch(setRequiredDetails({ consent: e.target.checked }))
                }}
                otherProps={{
                  style: window.isDirectionRTL ? { marginLeft: '5px' } : { marginRight: '5px' },
                }}
              >
                <span>
                  {getConsentTextChunks(consent.legalConfig.Text).length > 0 ? (
                    getConsentTextChunks(consent.legalConfig.Text).map((chunk) => {
                      if (chunk[0] === '@' && chunk[chunk.length - 1] === '@') {
                        let link = chunk.replace(/\@/g, '')
                        return (
                          <Link
                            key={links[link].label}
                            className='termsfontColor'
                            target='_blank'
                            to={links[link].link}
                            onClick={() => {
                              handleRedirection(link, links[link].link)
                            }}
                          >
                            {getTranslationText('common', links[link].label)}
                          </Link>
                        )
                      }
                      return <span key={chunk}>{chunk}</span>
                    })
                  ) : (
                    <span htmlFor='legal-consent-1'>
                      <span dangerouslySetInnerHTML={{ __html: consent.legalConfig.Text }} />
                    </span>
                  )}
                </span>
              </Checkbox>
            </div>
          )}
          <div className='subscription-button-container' style={{ border: 'none' }}>
            <Button isDisabled={isDisabled} handleOnClick={handleOnPayClick} classes='button-primary'>
              {getTranslationText('subscription', 'acceptAndPay')}
            </Button>
          </div>
        </div>
      </PlanDetails>

      <Modal
        className='plan-modal'
        showModal={showDevicesModal}
        closeModal={() => {
          setShowDevicesModal(false)
        }}
        showCloseBtn={true}
      >
        <Modal.Header>
          <h4 className='text-center'>
            {getTranslationText('device', 'totalDevices')} - {deviceDetails?.length}
          </h4>
        </Modal.Header>
        <Modal.Body className='modal-body--scroll'>
          <div className='cover-container mt20' style={{ maxWidth: '100%', flexDirection: 'column' }}>
            {groupedDetails &&
              Object.keys(groupedDetails)?.length > 0 &&
              Object.keys(groupedDetails).map((key) => {
                const devices = groupedDetails[key]
                return (
                  <Fragment key={key}>
                    <p className='device-title pt8 m0'>
                      {key} {getTranslationText('common', 'detailsLabel')}
                    </p>
                    <div className='subscription-device-details'>
                      {devices.map((device, index) => (
                        <Fragment key={index}>
                          <h5 className='subscription-device-container' style={{ maxWidth: 'max-content' }}>
                            {device.brand?.value}&nbsp;|&nbsp;{device.product?.value}
                            {device.serialNumber ? ` | ${device.serialNumber}` : ''}
                          </h5>
                        </Fragment>
                      ))}
                    </div>
                  </Fragment>
                )
              })}
          </div>
          {<p className='p10 review-helper-text'>{getTranslationText('subscription', 'addSerialAfterPlanPurchase')}</p>}
        </Modal.Body>
      </Modal>
    </div>
  ) : null
}

export default Review
