import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Dropdown, Input } from 'sfy-react'
import { setFields } from '../../../store/subscription'
import { getTranslationText } from '../../../utils/languageTranslation'
import { defaultValidationFilter, regexTypesToConfirm } from '../../../utils/validators'

const AddDevice = ({ id, handleOnChange, handleOnSubcategoryClick, handleOnBrandClick, handleOnSaveClick, handleOnRemoveClick }) => {
  const dispatch = useDispatch()

  const subcategories = useSelector((state) => state.subscription.subcategories)
  const brands = useSelector((state) => state.subscription.brands)
  const products = useSelector((state) => state.subscription.products)
  const fields = useSelector((state) => state.subscription.fields || [])
  const deviceDetails = useSelector((state) => state.subscription.deviceDetails)
  const errors = useSelector((state) => state.subscription.errors)

  const [isDisabled, setIsDisabled] = useState(true)

  useEffect(() => {
    !fields[id] &&
      dispatch(
        setFields({
          id,
          field: {
            category: '',
            brand: '',
            product: '',
          },
        })
      )
  }, [fields, id, dispatch])

  const validateFields = useCallback(() => {
    if (fields?.length && fields[id]) {
      return !Object.keys(fields[id]).every((key) => {
        // if (minimumLength[key]) {
        //   if (fields[id][key].length < minimumLength[key]) {
        //     return false
        //   }
        // }
        // if (lengths[key]) {
        //   if (fields[id][key].length !== lengths[key]) {
        //     return false
        //   }
        // }
        if (key === 'serialNumber' && fields[id][key] !== '') {
          const allowedValues = [defaultValidationFilter[0]]
          const isValueAllowed = allowedValues.some((allowValue) => {
            return (
              regexTypesToConfirm[allowValue.ExtraFilter].test(fields[id][key]) &&
              fields[id][key].length >= allowValue.MinLength &&
              fields[id][key].length <= allowValue.MaxLength
            )
          })
          if (!isValueAllowed) {
            return false
          }
        }
        if (!fields[id][key] && key !== 'serialNumber') {
          return false
        }
        return true
      })
    }
    return true
  }, [fields, id])

  useEffect(() => {
    setIsDisabled(validateFields())
  }, [validateFields])

  const handleOnClearClick = () => {
    dispatch(
      setFields({
        id,
        field: {
          category: '',
          brand: '',
          product: '',
          serialNumber: '',
        },
      })
    )
  }

  const handleOnSerialNumberChange = (e) => {
    const allowedValues = [defaultValidationFilter[0]]
    const isValueAllowed = allowedValues.some((allowValue) => {
      return /^[a-zA-Z0-9]*$/.test(e.target.value) && e.target.value.length <= allowValue.MaxLength
    })
    if (isValueAllowed) {
      handleOnChange(e, id)
    }
  }

  return (
    <form className='pl20 pr20 pb8' onSubmit={handleOnSaveClick}>
      {fields?.length > 0 && (
        <>
          {subcategories?.length > 0 && (
            <>
              <label className='mt20'>{getTranslationText('addingDeviceWidget', 'selectCategory')}</label>
              <Dropdown
                id='category'
                options={subcategories}
                handleOnChange={(selectedItem) => {
                  handleOnChange({ target: { name: 'category', value: selectedItem } }, id)
                  handleOnSubcategoryClick(selectedItem, id)
                }}
                value={fields[id]?.category?.value || ''}
                // optionLength={_.get(this, 'props.user.countries.length', 0)}
                isSearchable
                placeholder={getTranslationText('addingDeviceWidget', 'selectCategory')}
                searchText={getTranslationText('addingDeviceWidget', 'selectCategory')}
                classObject={{ container: 'mb20' }}
                filterBy='value'
                showTooltip
              />
            </>
          )}
          <>
            <label>{getTranslationText('addingDeviceWidget', 'selectBrand')}</label>
            <Dropdown
              id='brand'
              options={brands || []}
              handleOnChange={(selectedItem) => {
                handleOnChange({ target: { name: 'brand', value: selectedItem } }, id)
                handleOnBrandClick(selectedItem, id)
              }}
              value={fields[id]?.brand?.value || ''}
              // optionLength={_.get(this, 'props.user.countries.length', 0)}
              isSearchable
              placeholder={getTranslationText('addingDeviceWidget', 'selectBrand')}
              searchText={getTranslationText('addingDeviceWidget', 'selectBrand')}
              classObject={{ container: errors?.brand?.value ? 'mb8' : 'mb20' }}
              isDisabled={!brands?.length}
              filterBy='value'
              showTooltip
            />
            {errors?.brand?.value && <div className={`${errors.brand.type || ''} mb20`}>{errors.brand.value}</div>}
            <label>{getTranslationText('common', 'selectProduct')}</label>
            <Dropdown
              id='product'
              options={products || []}
              handleOnChange={(selectedItem) => {
                handleOnChange({ target: { name: 'product', value: selectedItem } }, id)
              }}
              value={fields[id]?.product?.value || ''}
              // optionLength={_.get(this, 'props.user.countries.length', 0)}
              isSearchable
              placeholder={getTranslationText('common', 'selectProduct')}
              searchText={getTranslationText('common', 'selectProduct')}
              classObject={{ container: errors?.product?.value ? 'mb8' : 'mb20' }}
              isDisabled={!products?.length}
              filterBy='value'
              showTooltip
            />
            {errors?.product?.value && <div className={`${errors.product.type || ''} mb20`}>{errors.product.value}</div>}
            <Input
              id='serialNumber'
              name='serialNumber'
              className={{ formControl: 'mb20' }}
              label={`${getTranslationText('common', 'serialNumber')} ${getTranslationText('common', 'optional')}`}
              placeholder={`${getTranslationText('common', 'enter')} ${getTranslationText('common', 'serialNumber')}`}
              value={fields[id]?.serialNumber || ''}
              handleOnChange={handleOnSerialNumberChange}
            />
            <div className='subscription-save-container'>
              <Button
                handleOnClick={() => {
                  deviceDetails[id] ? handleOnRemoveClick(id) : handleOnClearClick(id)
                }}
                type='button'
                classes='button-link text-left'
              >
                {getTranslationText('common', deviceDetails[id] ? 'delete' : 'clear')}
              </Button>
              <Button type='submit' classes='button-primary' isDisabled={isDisabled}>
                {getTranslationText('common', 'save')}
              </Button>
            </div>
            <hr />
          </>
        </>
      )}
    </form>
  )
}

export default AddDevice
