import React, { Component } from 'react'
import { BoxHolder } from '../../components/UIComponents'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { setglobalLoaderStatus } from '../../store/toastsAndLoaders'
import './EmptyRoute.scss'
import _ from 'lodash'
import { getTranslationText } from '../../utils/languageTranslation'

class EmptyRoute extends Component {
  componentWillMount() {
    window.scrollTo(0, 0)
    this.props.setglobalLoaderStatus(false)
  }

  handleCustomerSupportClick = () => {
    let mailLink = _.get(this, 'props.user.appConfigDetails.BrandConnect.email[0]', 'support@servify.in')
    window.location.href = 'mailto:' + mailLink
  }

  render() {
    return (
      <BoxHolder className='empty-route-container'>
        <div className='empty-route-parent'>
          <div className='watermark'>404</div>
          <div className='empty-route-content'>
            <div className='empty-route-heading boldFontStyle mb28'>
              <span>Oh NO!</span> Page Not Found
            </div>
            <div className='bookFontStyle fontLarge mb20'>The link you clicked or searched may be broken or the page is removed</div>
            <div className='bookFontStyle fontSmall empty-route-footer'>
              You can go back to <Link to='/'>{getTranslationText('common', 'home', 'home')}</Link> or report to our
              <span onClick={this.handleCustomerSupportClick} className='inline-ele-Casing'>
                &nbsp;Customer Support
              </span>
            </div>
          </div>
        </div>
      </BoxHolder>
    )
  }
}

const mapStateToProps = (state) => ({
  location: state.location,
  user: state.user,
})

const mapDispatchToProps = {
  setglobalLoaderStatus: (bool) => setglobalLoaderStatus(bool),
}

export default connect(mapStateToProps, mapDispatchToProps)(EmptyRoute)

EmptyRoute.propTypes = {
  setglobalLoaderStatus: PropTypes.func.isRequired,
}
