import { connect } from 'react-redux'
import {
  getPayableAmountAsync,
  getPayableAmountForNonLoginFlow,
  postPaymentActionAsync,
  postPaymentActionNonLoginFlow,
  addPaymentInfoAsync,
  addStripeSourceAsync,
  makeStripeChargeAsync,
  updatePaymentDetailsAsync,
  set3dSecurePoll,
  clear3dSecurePoll,
  clearPaymentDetails,
  getIyzipayPaymentStatus,
  getBuckarooPaymentStatus,
  getAmazonPaymentStatus,
  getNoonpayPaymentStatus,
  CheckOrCreateTelrPaymentAsync,
  initTelr,
  setTelrCreateDetails,
  setTelrCheckDetails,
  pollPaymentStatusAsync,
  advancePayAsync,
  setPaymentRequestStatus,
  setPaymentID,
} from '../../../store/payment'

import { loggerActionAsync, setRetryPaymentStatus, setRequestHoldPaymentStatus } from '../../../store/request'

import { setTrackHoldPaymentStatus } from '../../../store/track'

import { getNotificationsAsync, unsetSignedUrl } from '../../../store/user'

import { setPlanActivated, setSelectedPaymentOption } from '../../../store/storeReducer'

import { setToaster, setglobalLoaderStatus } from '../../../store/toastsAndLoaders'

import { storeLastKnowPathName } from '../../../store/location'

import Payment from '../components/Payment'
const mapDispatchToProps = {
  getPayableAmountAsync,
  getPayableAmountForNonLoginFlow,
  postPaymentActionAsync,
  postPaymentActionNonLoginFlow,
  addPaymentInfoAsync,
  addStripeSourceAsync,
  makeStripeChargeAsync,
  updatePaymentDetailsAsync,
  CheckOrCreateTelrPaymentAsync,
  initTelr,
  set3dSecurePoll: (obj) => set3dSecurePoll(obj),
  setPlanActivated: (obj) => setPlanActivated(obj),
  clear3dSecurePoll: (obj) => clear3dSecurePoll(obj),
  setTelrCreateDetails: (obj) => setTelrCreateDetails(obj),
  setTelrCheckDetails: (obj) => setTelrCheckDetails(obj),
  pollPaymentStatusAsync,
  setToaster,
  getNotificationsAsync,
  setglobalLoaderStatus: (bool) => setglobalLoaderStatus(bool),
  advancePayAsync,
  setPaymentRequestStatus: (obj) => setPaymentRequestStatus(obj),
  storeLastKnowPathName: (obj) => storeLastKnowPathName(obj),
  setPaymentID: (obj) => setPaymentID(obj),
  clearPaymentDetails,
  getIyzipayPaymentStatus,
  getAmazonPaymentStatus,
  getBuckarooPaymentStatus,
  getNoonpayPaymentStatus,
  setSelectedPaymentOption,
  unsetSignedUrl,
  loggerActionAsync,
  setRetryPaymentStatus,
  setTrackHoldPaymentStatus,
  setRequestHoldPaymentStatus,
}
const mapStateToProps = (state) => ({
  payment: state.payment,
  user: state.user,
  request: state.request,
  location: state.location,
  subscription: state.subscription,
  myPlans: {
    soldPlanDetails: state.myPlans.soldPlanDetails,
  },
  store: {
    selectedPlanDetails: state.store.selectedPlanDetails,
    purchaseEligibilityInfo: state.store.purchaseEligibilityInfo,
    selectedPaymentOption: state.store.selectedPaymentOption,
    planConsumerProduct: state.store.planConsumerProduct,
    planPaymentDetails: state.store.planPaymentDetails,
    consumerAddress: state.store.consumerAddress,
    taxAddress: state.store.taxAddress,
    userData: state.store.userData,
    planPriceOnProductBasis: state.store.planPriceOnProductBasis,
    imeiForFilter: state.store.imeiForFilter,
    imeiFromToken: state.store.imeiFromToken,
    selectedState: state.store.selectedState,
    externalOriginSource: state.store.externalOriginSource,
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Payment)
