import React from 'react'
import PropTypes from 'prop-types'
import './accountLink.scss'
import { LargerButton, InputComponent, LoginSubHeading } from '../../../components/UIComponents'
import { CountryDropdownComponent } from '../../Login/components/LoginSubComponent'
import { Checkbox } from 'sfy-react'
import _ from 'lodash'
import { BrandSpecificConfig } from '../../../utils/constants.js'
import { regexCheck } from '../../../utils/regex'
import { Link } from 'react-router'
import { getTranslationText } from '../../../utils/languageTranslation'
import getRetryMsg from '../../../utils/getRetryMsg.js'
import { ReactComponent as Email } from '../../../../public/commonAssets/email-web.svg'

export const LoginPhNo = (props) => {
  let footerLinks = _.get(props, 'user.appConfigDetails.onePlusLinks', props.user.appConfigDetails)
  return (
    <div className='row login-ph'>
      <div className='col-sm-12'>
        <CountryDropdownComponent {...props} />
        <div className='purchase-selectables mt20'>
          <div className='fontMedium login-footer-txt-oneplus' onClick={props.handleTermsAndCondClick}>
            <div className='oneplus-checkbox'>
              <Checkbox type='checkbox' value={props.termsAndCondCheck} handleOnChange={props.handleTermsAndCondClick} />
              <label htmlFor='checkbox' />
            </div>
            <span>
              * {getTranslationText('common', 'agreeText')}&nbsp;
              <Link className='termsfontColor' target='_blank' to={footerLinks.UserAgreementLink || footerLinks.Terms}>
                {getTranslationText('common', 'userAgreement')}.
              </Link>
              &nbsp;{getTranslationText('common', 'pleaseSeeOur')}&nbsp;
              <Link className='termsfontColor' target='_blank' to={footerLinks.PrivacyLink || footerLinks.Privacy}>
                {getTranslationText('common', 'privacyPolicy')}
              </Link>
              &nbsp;{getTranslationText('common', 'here')}.
            </span>
          </div>
        </div>
        <br />
      </div>
      <div className='col-sm-12 mt20'>
        <LargerButton
          buttonText={getTranslationText('common', 'sendVerificationCode')}
          className={'loginContactDetails_btn '}
          btnLoader={props.BtnLoaderStatus}
          onClick={() => {
            document.getElementById('inputSubmit').click()
            props.validatePhno()
          }}
          disabled={!props.phnoLengthAr.includes(props.Phno.length) || !props.termsAndCondCheck || props.invalidFormat}
        />
      </div>
    </div>
  )
}

LoginPhNo.propTypes = {
  validatePhno: PropTypes.func.isRequired,
  handlePhNoInputChange: PropTypes.func.isRequired,
  Phno: PropTypes.string.isRequired,
  BtnLoaderStatus: PropTypes.bool.isRequired,
  termsAndCondCheck: PropTypes.bool.isRequired,
  handleTermsAndCondClick: PropTypes.func.isRequired,
  selectedCountryObj: PropTypes.object.isRequired,
  handleCountryChange: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  phnoLengthAr: PropTypes.array,
  invalidFormat: PropTypes.bool.isRequired,
}

export const LoginOTP = ({ disableResendOtp, btnDisabled, ...props }) => {
  let otpActiveIamge = props.otpImage
  let showCallCtr = _.get(props, 'user.appConfigDetails.showCallOTP', true)
  let phoneCode = _.get(props, 'user.selectedCountryData.PhoneCode')
  const otpSentText = getTranslationText('login', 'enterotp').replace('@ISD', phoneCode).replace('@MobileNumber', props.Phno)
  return (
    <div className='row login-OTP'>
      <div className='col-sm-12'>
        <div className='bookFontStyle fontLarge mb4'>{otpSentText}</div>
        <div className='otp-input-wrap'>
          <InputComponent
            className='mediumFontStyle fontLarge otp-input'
            type='text'
            style={{ backgroundImage: 'url(' + otpActiveIamge + ')' }}
            pattern='[0-9]{6}'
            maxLength={6}
            required
            onFocus={() => props.updateOTPImage('active')}
            onBlur={() => props.updateOTPImage()}
            onKeyPress={props.checkOTPlength}
            autoComplete='off'
            onChange={props.checkOTPlength}
            value={props.otpValue}
            id={props.id}
          />
          <label className='opt-hidefield mtb20' />
        </div>
        {props.sendOTPCounter === 0 ? (
          <div className='mt20 bookFontStyle fontSmall'>{getTranslationText('login', 'didNotRecieveAnOtp')}</div>
        ) : (
          <div className='mt20 mediumFontStyle fontSmall'>
            ({getTranslationText('login', 'resendOTP')}&nbsp; 00:{String(props.sendOTPCounter).length === 1 ? '0' : ''}
            {props.sendOTPCounter})
          </div>
        )}
        <div className='otp-actions'>
          {props.showResendOTPbtn ? (
            <button
              className='bookFontStyle fontSmall special-link-txt otp-links'
              disabled={disableResendOtp}
              onClick={() => props.ResendOtpHandler('sms')}
              title={disableResendOtp ? getTranslationText('login', 'timerText') : ''}
            >
              {getTranslationText('login', 'smsMeTheOtp')}
            </button>
          ) : props.showOTPTimer ? (
            <span className='mediumFontStyle fontSmall'>{getRetryMsg(props.frequentCalls)}</span>
          ) : (
            ''
          )}
          {(props.showCallOptnBtn && showCallCtr) || props.showCallTimer ? ' | ' : ''}
          {props.showCallOptnBtn && showCallCtr ? (
            <button
              className='bookFontStyle fontSmall special-link-txt otp-links'
              onClick={() => props.ResendOtpHandler('call')}
              disabled={disableResendOtp}
              title={disableResendOtp ? getTranslationText('login', 'timerText') : ''}
            >
              {getTranslationText('login', 'callMeWithTheOtp')}
            </button>
          ) : props.showCallTimer && showCallCtr ? (
            <span className='mediumFontStyle fontSmall opt-call-link'>{getRetryMsg(props.otpViaCallTimer)}</span>
          ) : (
            ''
          )}
        </div>
      </div>
      <div className='col-sm-12 mt8'>
        <LargerButton
          buttonText={getTranslationText('common', 'continue')}
          onClick={props.checkOTP}
          btnLoader={props.BtnLoaderStatus}
          disabled={btnDisabled}
        />
      </div>
    </div>
  )
}

LoginOTP.defaultProps = {
  btnDisabled: false,
}

LoginOTP.propTypes = {
  // user: PropTypes.object,
  updateOTPImage: PropTypes.func,
  otpImage: PropTypes.string,
  checkOTP: PropTypes.func.isRequired,
  checkOTPlength: PropTypes.func.isRequired,
  otpValue: PropTypes.string.isRequired,
  allowResend: PropTypes.bool.isRequired,
  Phno: PropTypes.string.isRequired,
  showResendOTPbtn: PropTypes.bool.isRequired,
  showOTPTimer: PropTypes.bool.isRequired,
  frequentCalls: PropTypes.number.isRequired,
  showCallOptnBtn: PropTypes.bool.isRequired,
  showCallTimer: PropTypes.bool.isRequired,
  otpViaCallTimer: PropTypes.number.isRequired,
  sendOTPCounter: PropTypes.number.isRequired,
  BtnLoaderStatus: PropTypes.bool,
  btnDisabled: PropTypes.bool,
  id: PropTypes.any,
  disableResendOtp: PropTypes.bool.isRequired,
  ResendOtpHandler: PropTypes.func.isRequired,
}

export const LoginHeading = (props) => {
  return (
    <div className='login-main-heading'>
      {props.wordBreak ? (
        props.title
          .split(' ')
          .map((word, index) => <div key={'key' + index} className='boldFontStyle' dangerouslySetInnerHTML={{ __html: word }} />)
      ) : (
        <span>{props.title}</span>
      )}
    </div>
  )
}
LoginHeading.propTypes = {
  title: PropTypes.string.isRequired,
  wordBreak: PropTypes.bool,
}

export const LoginContactDetails = (props) => {
  let btnStatus = regexCheck('validation', 'userName', props.UsrName)
  let email = _.get(props, 'user.userDetails.emailID')
  return (
    <div className='row account-user-creds'>
      <div className='col-sm-12'>
        <div className='row'>
          <div className='col-sm-12 mb28'>
            <LoginSubHeading title={`${getTranslationText('login', 'yourname')}`} />
            <InputComponent
              className='mediumFontStyle fontLarge newUserInput'
              autoComplete='off'
              type='text'
              id='Usrname'
              placeholder={getTranslationText('login', 'inputName')}
              onKeyPress={props.handleUserInputs}
              onChange={props.handleUserInputs}
              value={props.UsrName}
            />
            <br />
          </div>
          <div className='col-sm-12 mb28'>
            <LoginSubHeading title={getTranslationText('common', 'emailId')} />
            <InputComponent
              className='mediumFontStyle fontLarge newUserInput'
              type='email'
              placeholder={getTranslationText('login', 'inputEmail')}
              disabled
              value={email}
            />
            <br />
          </div>
          <div className='col-sm-12'>
            <LargerButton
              buttonText={getTranslationText('common', 'goAhead')}
              onClick={props.validateContactDetails}
              disabled={!btnStatus}
              className={'newUserInput'}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
LoginContactDetails.propTypes = {
  handleUserInputs: PropTypes.func.isRequired,
  UsrName: PropTypes.string.isRequired,
  validateContactDetails: PropTypes.func.isRequired,
}

export class UserAuthLinkComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      userTnC: false,
    }
  }

  handleAuthTnc = () =>
    this.setState({
      userTnC: !this.state.userTnC,
    })

  render() {
    let email = _.get(this, 'props.user.userDetails.emailID')
    let footerLinks = _.get(this, 'props.user.appConfigDetails.onePlusLinks') || _.get(this, 'props.user.appConfigDetails')
    const userAgreementLink = footerLinks.UserAgreementLink || footerLinks.Terms
    const privacyPolicyLink = footerLinks.PrivacyLink || footerLinks.Privacy
    const servifyEmailConsent = getTranslationText('login', 'servifyEmailConsent')
      .replace('@Brand', BrandSpecificConfig().ClientName)
      .replace('@userAgreementLink', userAgreementLink)
      .replace('@privacyPolicyLink', privacyPolicyLink)
    return (
      <div className='user-auth-container'>
        <div className='text-center mediumFontStyle'>
          <Email className='mr8' />
          <span>{email}</span>
        </div>
        <div className='tnc-wrapper mt28 mb8' onClick={this.handleAuthTnc}>
          <Checkbox type='checkbox' value={this.state.userTnC} handleOnChange={this.handleAuthTnc}>
            <div>
              <span dangerouslySetInnerHTML={{ __html: servifyEmailConsent }} />
            </div>
          </Checkbox>
        </div>
        <LargerButton
          buttonText={getTranslationText('login', 'signAuthorize')}
          className=''
          onClick={this.props.handleUserAuth}
          disabled={!this.state.userTnC}
        />
      </div>
    )
  }

  static propTypes = {
    handleUserAuth: PropTypes.func.isRequired,
  }
}
