import React from 'react'
import PropTypes from 'prop-types'

export default function changeStyle(PassedComponent, styles = {}) {
  const NewComponent = (props) => {
    return <PassedComponent styles={{ ...styles, button: { ...styles.button, ...props.rtlStyle } }} {...props} />
  }

  NewComponent.propTypes = {
    styles: PropTypes.object,
  }

  return NewComponent
}
