import _ from 'lodash'
export const filterPlanDetails = (planId) => {
  let appleData
  // Protect+ for iPhone
  if (_.includes([545, 1011], planId)) {
    appleData = {
      TableSection: [
        {
          TableHeading: 'Plan Price',
          TableBody: `<table>
              <tr>
                <th class='applePlanDetailsHeaderColumn'>Product Details</th>
                <th class='applePlanDetailsHeaderRow'>iPhone SE 2, iPhone SE 3</th>
                <th class='applePlanDetailsHeaderRow'>iPhone 15, iPhone 14, iPhone 13, iPhone 13 Mini, iPhone 12, iPhone 12 Mini, iPhone 11, iPhone XR, iPhone 8 Plus, iPhone 7 Plus, iPhone 6S Plus, iPhone 8, iPhone 7, iPhone 6s</th>
                <th class='applePlanDetailsHeaderRow'>iPhone 15 Pro, iPhone 15 Pro Max, iPhone 14 Pro, iPhone 14 Pro Max, iPhone 13 Pro Max, iPhone 13 Pro, iPhone 12 Pro Max, iPhone 12 Pro, iPhone 11 Pro Max, iPhone 11 Pro, iPhone XS Max, iPhone XS, iPhone X</th>
                <th class='applePlanDetailsHeaderRow'>iPhone 15 Plus, iPhone 14 Plus</th>
              </tr>
              <tr>
                <td class='applePlanDetailsHeaderColumn'>Protect+ with AppleCare Services Plan Price</td>
                <td class='applePlanDetailsPrice'>₹ 4,999**</td>
                <td class='applePlanDetailsPrice'>₹ 9,999**</td>
                <td class='applePlanDetailsPrice'>₹ 16,999**</td>
                <td class='applePlanDetailsPrice'>₹ 12,999**</td>
              </tr>
            </table>`,
          TableSubTxt: '**Prices include applicable taxes (GST)',
        },
        {
          TableHeading: 'Service Fees',
          TableBody: `<table>
              <tr>
                <th class='applePlanDetailsHeaderColumn'>Product Details</th>
                <th class='applePlanDetailsHeaderRow'>iPhone SE 2, iPhone SE 3</th>
                <th class='applePlanDetailsHeaderRow'>iPhone 15, iPhone 14, iPhone 13, iPhone 13 Mini, iPhone 12, iPhone 12 Mini, iPhone 11, iPhone XR, iPhone 8 Plus, iPhone 7 Plus, iPhone 6S Plus, iPhone 8, iPhone 7, iPhone 6s</th>
                <th class='applePlanDetailsHeaderRow'>iPhone 15 Pro, iPhone 15 Pro Max, iPhone 14 Pro, iPhone 14 Pro Max, iPhone 13 Pro Max, iPhone 13 Pro, iPhone 12 Pro Max, iPhone 12 Pro, iPhone 11 Pro Max, iPhone 11 Pro, iPhone XS Max, iPhone XS, iPhone X</th>
                <th class='applePlanDetailsHeaderRow'>iPhone 15 Plus, iPhone 14 Plus</th>
              </tr>
              <tr>
                <td class='applePlanDetailsHeaderColumn'>Service Fee for Screen Damage</td>
                <td class='applePlanDetailsPrice'>₹ 2,500**</td>
                <td class='applePlanDetailsPrice'>₹ 2,500**</td>
                <td class='applePlanDetailsPrice'>₹ 2,500**</td>
                <td class='applePlanDetailsPrice'>₹ 2,500**</td>
              </tr>
              <tr>
                <td class='applePlanDetailsHeaderColumn'>Service Fee for Other Damages</td>
                <td class='applePlanDetailsPrice'>₹ 8,900**</td>
                <td class='applePlanDetailsPrice'>₹ 8,900**</td>
                <td class='applePlanDetailsPrice'>₹ 8,900**</td>
                <td class='applePlanDetailsPrice'>₹ 8,900**</td>
              </tr>
            </table>`,
          TableSubTxt: '**Prices include applicable taxes (GST)',
        },
      ],
    }
  } else if (_.includes([547], planId)) {
    // Protect+ for iPad
    appleData = {
      TableSection: [
        {
          TableHeading: 'Plan Price',
          TableBody: `<table>
              <tr>
                <th class='applePlanDetailsHeaderColumn'>Product Details</th>
                <th class='applePlanDetailsHeaderRow'>iPad (8th generation), iPad Air (3rd generation), iPad mini (5th generation)</th>
                <th class='applePlanDetailsHeaderRow'>iPad (9th generation), iPad mini (6th generation)</th>
                <th class='applePlanDetailsHeaderRow'>iPad 10.9"(10th generation)</th>
                <th class='applePlanDetailsHeaderRow'>iPad Air (4th generation)</th>
                <th class='applePlanDetailsHeaderRow'>iPad Air (5th generation)</th>
                <th class='applePlanDetailsHeaderRow'>iPad Pro</th>
                <th class='applePlanDetailsHeaderRow'>iPad Pro 12-inch</th>
              </tr>
              <tr>
                <td class='applePlanDetailsHeaderColumn'>Protect+ with AppleCare Services Plan Price</td>
                <td class='applePlanDetailsPrice'>₹ 4,299**</td>
                <td class='applePlanDetailsPrice'>₹ 4,599**</td>
                <td class='applePlanDetailsPrice'>₹ 4,999**</td>
                <td class='applePlanDetailsPrice'>₹ 5,799**</td>
                <td class='applePlanDetailsPrice'>₹ 5,999**</td>
                <td class='applePlanDetailsPrice'>₹ 12,999**</td>
                <td class='applePlanDetailsPrice'>₹ 14,999**</td>
              </tr>
            </table>`,
          TableSubTxt: '**Prices include applicable taxes (GST)',
        },
        {
          TableHeading: 'Service Fees',
          TableBody: `<table>
              <tr>
                <th class='applePlanDetailsHeaderColumn'>Product Details</th>
                <th class='applePlanDetailsHeaderRow'>iPad (8th generation), iPad Air (3rd generation), iPad mini (5th generation)</th>
                <th class='applePlanDetailsHeaderRow'>iPad (9th generation), iPad mini (6th generation)</th>
                <th class='applePlanDetailsHeaderRow'>iPad 10.9"(10th generation)</th>
                <th class='applePlanDetailsHeaderRow'>iPad Air (4th generation)</th>
                <th class='applePlanDetailsHeaderRow'>iPad Air (5th generation)</th>
                <th class='applePlanDetailsHeaderRow'>iPad Pro</th>
                <th class='applePlanDetailsHeaderRow'>iPad Pro 12-inch</th>
              </tr>
              <tr>
                <td class='applePlanDetailsHeaderColumn'>Service Fee for iPad Damage</td>
                <td class='applePlanDetailsPrice'>₹ 3,900**</td>
                <td class='applePlanDetailsPrice'>₹ 3,900**</td>
                <td class='applePlanDetailsPrice'>₹ 3,900**</td>
                <td class='applePlanDetailsPrice'>₹ 3,900**</td>
                <td class='applePlanDetailsPrice'>₹ 3,900**</td>
                <td class='applePlanDetailsPrice'>₹ 3,900**</td>
                <td class='applePlanDetailsPrice'>₹ 3,900**</td>
              </tr>
              <tr>
                <td class='applePlanDetailsHeaderColumn'>Service Fee for Apple Pencil & Keyboard Damage</td>
                <td class='applePlanDetailsPrice'>₹ 2,500**</td>
                <td class='applePlanDetailsPrice'>₹ 2,500**</td>
                <td class='applePlanDetailsPrice'>₹ 2,500**</td>
                <td class='applePlanDetailsPrice'>₹ 2,500**</td>
                <td class='applePlanDetailsPrice'>₹ 2,500**</td>
                <td class='applePlanDetailsPrice'>₹ 2,500**</td>
                <td class='applePlanDetailsPrice'>₹ 2,500**</td>
              </tr>
            </table>`,
          TableSubTxt: '**Prices include applicable taxes (GST)',
        },
      ],
    }
  } else if (_.includes([548], planId)) {
    // Protect+ for Watch
    appleData = {
      TableSection: [
        {
          TableHeading: 'Plan Price',
          TableBody: `<table>
              <tr>
                <th class='applePlanDetailsHeaderColumn'>Product Details</th>
                <th class='applePlanDetailsHeaderRow'>Watch Nike SE, Watch SE, Watch Series 3, Watch SE (2nd gen)</th>
                <th class='applePlanDetailsHeaderRow'>Watch Series 4, Watch Series 5, Watch Series 6, Watch Series 7, Watch Series 8, Watch Series 9</th>
                <th class='applePlanDetailsHeaderRow'>Watch Ultra Titanium</th>
              </tr>
              <tr>
                <td class='applePlanDetailsHeaderColumn'>Protect+ with AppleCare Services Plan Price</td>
                <td class='applePlanDetailsPrice'>₹ 4,299**</td>
                <td class='applePlanDetailsPrice'>₹ 5,599**</td>
                <td class='applePlanDetailsPrice'>₹ 6,899**</td>
              </tr>
            </table>`,
          TableSubTxt: '**Prices include applicable taxes (GST)',
        },
        {
          TableHeading: 'Service Fees',
          TableBody: `<table>
              <tr>
                <th class='applePlanDetailsHeaderColumn'>Product Details</th>
                <th class='applePlanDetailsHeaderRow'>Watch Nike SE, Watch SE, Watch Series 3, Watch SE (2nd gen)</th>
                <th class='applePlanDetailsHeaderRow'>Watch Series 4, Watch Series 5, Watch Series 6, Watch Series 7, Watch Series 8, Watch Series 9</th>
                <th class='applePlanDetailsHeaderRow'>Watch Ultra, Watch Ultra 2</th>
              </tr>
              <tr>
                <td class='applePlanDetailsHeaderColumn'>Service Fee for All Damages</td>
                <td class='applePlanDetailsPrice'>₹ 5,900**</td>
                <td class='applePlanDetailsPrice'>₹ 5,900**</td>
                <td class='applePlanDetailsPrice'>₹ 5,900**</td>
              </tr>
            </table>`,
          TableSubTxt: '**Prices include applicable taxes (GST)',
        },
      ],
    }
  } else if (_.includes([546], planId)) {
    // Protect+ for Mac
    appleData = {
      TableSection: [
        {
          TableHeading: 'Plan Price',
          TableBody: `<table>
              <tr>
                <th class='applePlanDetailsHeaderColumn'>Product Details</th>
                <th class='applePlanDetailsHeaderRow'>MacBook Air, iMac</th>
                <th class='applePlanDetailsHeaderRow'>MacBook Pro 14"(M1, M2), MacBook Pro 13” (Intel), MacBook Pro 15”, Mac Pro</th>
                <th class='applePlanDetailsHeaderRow'>MacBook Pro 13” (M1,M2)</th>
                <th class='applePlanDetailsHeaderRow'>MacBook Pro 16" (Intel, M1, M2)</th>
                <th class='applePlanDetailsHeaderRow'>Mac Mini</th>
                <th class='applePlanDetailsHeaderRow'>Mac Studio (M1, M2)</th>
                <th class='applePlanDetailsHeaderRow'>MacBook Air (M2)</th>
                <th class='applePlanDetailsHeaderRow'>MacBook Air 15" (M2)</th>
                <th class='applePlanDetailsHeaderRow'>Mac Pro (M2)</th>
              </tr>
              <tr>
                <td class='applePlanDetailsHeaderColumn'>Protect+ with AppleCare Services Plan Price</td>
                <td class='applePlanDetailsPrice'>₹ 13,999**</td>
                <td class='applePlanDetailsPrice'>₹ 18,999**</td>
                <td class='applePlanDetailsPrice'>₹ 16,999**</td>
                <td class='applePlanDetailsPrice'>₹ 26,999**</td>
                <td class='applePlanDetailsPrice'>₹ 8,999**</td>
                <td class='applePlanDetailsPrice'>₹ 11,799**</td>
                <td class='applePlanDetailsPrice'>₹ 14,999**</td>
                <td class='applePlanDetailsPrice'>₹ 15,999**</td>
                <td class='applePlanDetailsPrice'>₹ 37,999**</td>
              </tr>
            </table>`,
          TableSubTxt: '**Prices include applicable taxes (GST)',
        },
        {
          TableHeading: 'Service Fees',
          TableBody: `<table>
            <tr>
                <th class='applePlanDetailsHeaderColumn'>Product Details</th>
                <th class='applePlanDetailsHeaderRow'>MacBook Air, iMac</th>
                <th class='applePlanDetailsHeaderRow'>MacBook Pro 14"(M1, M2), MacBook Pro 13” (Intel), MacBook Pro 15”, Mac Pro</th>
                <th class='applePlanDetailsHeaderRow'>MacBook Pro 13”(M1,M2)</th>
                <th class='applePlanDetailsHeaderRow'>MacBook Pro 16"(Intel, M1, M2)</th>
                <th class='applePlanDetailsHeaderRow'>Mac Mini</th>
                <th class='applePlanDetailsHeaderRow'>Mac Studio (M1, M2)</th>
                <th class='applePlanDetailsHeaderRow'>MacBook Air (M2)</th>
                <th class='applePlanDetailsHeaderRow'>MacBook Air 15" (M2)</th>
                <th>Mac Pro (M2)</th>
            </tr>
            <tr>
                <td class='applePlanDetailsHeaderColumn'>Service Fee for Screen and Enclosure Damage</td>
                <td class='applePlanDetailsPrice'>₹ 8,900**</td>
                <td class='applePlanDetailsPrice'>₹ 8,900**</td>
                <td class='applePlanDetailsPrice'>₹ 8,900**</td>
                <td class='applePlanDetailsPrice'>₹ 8,900**</td>
                <td class='applePlanDetailsPrice'>₹ 8,900**</td>
                <td class='applePlanDetailsPrice'>₹ 8,900**</td>
                <td class='applePlanDetailsPrice'>₹ 8,900**</td>
                <td class='applePlanDetailsPrice'>₹ 8,900**</td>
                <td class='applePlanDetailsPrice'>₹ 8,900**</td>
            </tr>
            <tr>
                <td class='applePlanDetailsHeaderColumn'>Service Fee for all Other Damages</td>
                <td class='applePlanDetailsPrice'>₹ 25,900**</td>
                <td class='applePlanDetailsPrice'>₹ 25,900**</td>
                <td class='applePlanDetailsPrice'>₹ 25,900**</td>
                <td class='applePlanDetailsPrice'>₹ 25,900**</td>
                <td class='applePlanDetailsPrice'>₹ 25,900**</td>
                <td class='applePlanDetailsPrice'>₹ 25,900**</td>
                <td class='applePlanDetailsPrice'>₹ 25,900**</td>
                <td class='applePlanDetailsPrice'>₹ 25,900**</td>
                <td class='applePlanDetailsPrice'>₹ 25,900**</td>
            </tr>
            </table>`,
          TableSubTxt: '**Prices include applicable taxes (GST)',
        },
      ],
    }
  } else if (_.includes([551], planId)) {
    // Protect+ for Airpods / Headphones
    appleData = {
      TableSection: [
        {
          TableHeading: 'Plan Price',
          TableBody: `<table>
              <tr>
                <th class='applePlanDetailsHeaderColumn'>Product Details</th>
                <th class='applePlanDetailsHeaderRow'>Airpods</th>
                <th class='applePlanDetailsHeaderRow'>Airpods Max</th>
                <th class='applePlanDetailsHeaderRow'>Beats earphones, Beats Headphones (except urBeats3)</th>
              </tr>
              <tr>
                <td class='applePlanDetailsHeaderColumn'>Protect+ with AppleCare Services Plan Price</td>
                <td class='applePlanDetailsPrice'>₹ 2,900**</td>
                <td class='applePlanDetailsPrice'>₹ 6,900**</td>
                <td class='applePlanDetailsPrice'>₹ 2,900**</td>
              </tr>
            </table>`,
          TableSubTxt: '**Prices include applicable taxes (GST)',
        },
        {
          TableHeading: 'Service Fees',
          TableBody: `<table>
            <tr>
                <th class='applePlanDetailsHeaderColumn'>Product Details</th>
                <th class='applePlanDetailsHeaderRow'>Airpods</th>
                <th class='applePlanDetailsHeaderRow'>Airpods Max</th>
                <th class='applePlanDetailsHeaderRow'>Beats earphones, Beats Headphones (except urBeats3)</th>
            </tr>
            <tr>
                <td class='applePlanDetailsHeaderColumn'>Service Fee for All Damages</td>
                <td class='applePlanDetailsPrice'>₹ 2,500**</td>
                <td class='applePlanDetailsPrice'>₹ 2,500**</td>
                <td class='applePlanDetailsPrice'>₹ 2,500**</td>
            </tr>
            </table>`,
          TableSubTxt: '**Prices include applicable taxes (GST)',
        },
      ],
    }
  } else if (_.includes([552], planId)) {
    // Protect+ for HomePod
    appleData = {
      TableSection: [
        {
          TableHeading: 'Plan Price',
          TableBody: `<table>
              <tr>
                <th class='applePlanDetailsHeaderColumn'>Product Details</th>
                <th class='applePlanDetailsHeaderRow'>HomePod mini</th>
                <th class='applePlanDetailsHeaderRow'>HomePod</th>
              </tr>
              <tr>
                <td class='applePlanDetailsHeaderColumn'>Protect+ with AppleCare Services Plan Price</td>
                <td class='applePlanDetailsPrice'>₹ 999**</td>
                <td class='applePlanDetailsPrice'>₹ 2,299**</td>
              </tr>
            </table>`,
          TableSubTxt: '**Prices include applicable taxes (GST)',
        },
        {
          TableHeading: 'Service Fees',
          TableBody: `<table>
            <tr>
                <th class='applePlanDetailsHeaderColumn'>Product Details</th>
                <th class='applePlanDetailsHeaderRow'>HomePod</th>
                <th class='applePlanDetailsHeaderRow'>HomePod Mini</th>
            </tr>
            <tr>
                <td class='applePlanDetailsHeaderColumn'>Service Fee for All Damages</td>
                <td class='applePlanDetailsPrice'>₹ 1,290**</td>
                <td class='applePlanDetailsPrice'>₹ 2,900**</td>
            </tr>
            </table>`,
          TableSubTxt: '**Prices include applicable taxes (GST)',
        },
      ],
    }
  } else if (_.includes([549], planId)) {
    // Protect+ for Apple Display
    appleData = {
      TableSection: [
        {
          TableHeading: 'Plan Price',
          TableBody: `<table>
              <tr>
                <th class='applePlanDetailsHeaderColumn'>Product Details</th>
                <th class='applePlanDetailsHeaderRow'>Studio Display</th>
                <th class='applePlanDetailsHeaderRow'>Pro Display XDR</th>
              </tr>
              <tr>
                <td class='applePlanDetailsHeaderColumn'>Protect+ with AppleCare Services Plan Price</td>
                <td class='applePlanDetailsPrice'>₹ 12,799**</td>
                <td class='applePlanDetailsPrice'>₹ 39,699**</td>
              </tr>
            </table>`,
          TableSubTxt: '**Prices include applicable taxes (GST)',
        },
        {
          TableHeading: 'Service Fees',
          TableBody: `<table>
            <tr>
              <th class='applePlanDetailsHeaderColumn'>Product Details</th>
              <th class='applePlanDetailsHeaderRow'>Studio Display</th>
              <th class='applePlanDetailsHeaderRow'>Pro Display XDR</th>
            </tr>
            <tr>
              <td class='applePlanDetailsHeaderColumn'>Service Fee for Screen and Enclosure Damage</td>
              <td class='applePlanDetailsPrice'>₹ 8,900**</td>
              <td class='applePlanDetailsPrice'>₹ 8,900**</td>
            </tr>
            <tr>
              <td>Service Fee for all Other Damages</td>
              <td class='applePlanDetailsPrice'>₹ 25,900**</td>
              <td class='applePlanDetailsPrice'>₹ 25,900**</td>
            </tr>
            </table>`,
          TableSubTxt: '**Prices include applicable taxes (GST)',
        },
      ],
    }
  } else if (_.includes([550], planId)) {
    // Protect+ for Apple Display
    appleData = {
      TableSection: [
        {
          TableHeading: 'Plan Price',
          TableBody: `<table>
              <tr>
                <th class='applePlanDetailsHeaderColumn'>Product Details</th>
                <th class='applePlanDetailsHeaderRow'>Apple TV</th>
              </tr>
              <tr>
                <td class='applePlanDetailsHeaderColumn'>Protect+ with AppleCare Services Plan Price</td>
                <td class='applePlanDetailsPrice'>₹ 1,999**</td>
              </tr>
            </table>`,
          TableSubTxt: '**Prices include applicable taxes (GST)',
        },
        {
          TableHeading: 'Service Fees',
          TableBody: `<table>
            <tr>
              <th class='applePlanDetailsHeaderColumn'>Product Details</th>
              <th class='applePlanDetailsHeaderRow'>Apple TV</th>
            </tr>
            <tr>
              <td class='applePlanDetailsHeaderColumn'>Service Fee for All Damages</td>
              <td class='applePlanDetailsPrice'>₹ 1,290**</td>
            </tr>
            </table>`,
          TableSubTxt: '**Prices include applicable taxes (GST)',
        },
      ],
    }
  }

  // AppleCare+ for iPhone
  if (_.includes([422], planId)) {
    appleData = {
      TableSection: [
        {
          TableHeading: 'Plan Price',
          TableBody: `<table>
              <tr>
                <th class='applePlanDetailsHeaderColumn'>Product Details</th>
                <th class='applePlanDetailsHeaderRow'>iPhone SE 2, iPhone SE 3</th>
                <th class='applePlanDetailsHeaderRow'>iPhone 15, iPhone 14, iPhone 13, iPhone 13 Mini, iPhone 12, iPhone 12 Mini, iPhone 11, iPhone XR, iPhone 8 Plus, iPhone 7 Plus, 
                  iPhone 6S Plus, iPhone 8, iPhone 7, iPhone 6s</th>
                <th class='applePlanDetailsHeaderRow'>iPhone 15 Pro, iPhone 15 Pro Max, iPhone 14 Pro, iPhone 14 Pro Max, iPhone 13 Pro Max, iPhone 13 Pro, iPhone 12 Pro Max, iPhone 12 Pro, iPhone 11 Pro Max, iPhone 11 Pro, iPhone XS Max, 
                  iPhone XS, iPhone X</th>
                <th class='applePlanDetailsHeaderRow'>iPhone 15 Plus, iPhone 14 Plus</th>
              </tr>
              <tr>
                <td class='applePlanDetailsHeaderColumn'>AppleCare+ Plan Price</td>
                <td class='applePlanDetailsPrice'>₹ 7,900**</td>
                <td class='applePlanDetailsPrice'>₹ 14,900**</td>
                <td class='applePlanDetailsPrice'>₹ 20,900**</td>
                <td class='applePlanDetailsPrice'>₹ 17,900**</td>
              </tr>
            </table>`,
          TableSubTxt: '**Prices include applicable taxes (GST)',
        },
        {
          TableHeading: 'Service Fees',
          TableBody: `<table>
              <tr>
                <th class='applePlanDetailsHeaderColumn'>Product Details</th>
                <th class='applePlanDetailsHeaderRow'>iPhone SE 2, iPhone SE 3</th>
                <th class='applePlanDetailsHeaderRow'>iPhone 15, iPhone 14, iPhone 13, iPhone 13 Mini, iPhone 12, iPhone 12 Mini, iPhone 11, iPhone XR, iPhone 8 Plus, iPhone 7 Plus, 
                  iPhone 6S Plus, iPhone 8, iPhone 7, iPhone 6s</th>
                <th class='applePlanDetailsHeaderRow'>iPhone 15 Pro, iPhone 15 Pro Max, iPhone 14 Pro, iPhone 14 Pro Max, iPhone 13 Pro Max, iPhone 13 Pro, iPhone 12 Pro Max, iPhone 12 Pro, iPhone 11 Pro Max, iPhone 11 Pro, iPhone XS Max, 
                  iPhone XS, iPhone X</th>
                <th class='applePlanDetailsHeaderRow'>iPhone 15 Plus, iPhone 14 Plus</th>
              </tr>
              <tr>
                <td class='applePlanDetailsHeaderColumn'>Service Fee for Screen Damage</td>
                <td class='applePlanDetailsPrice'>₹ 2,500**</td>
                <td class='applePlanDetailsPrice'>₹ 2,500**</td>
                <td class='applePlanDetailsPrice'>₹ 2,500**</td>
                <td class='applePlanDetailsPrice'>₹ 2,500**</td>
              </tr>
              <tr>
                <td class='applePlanDetailsHeaderColumn'>Service Fee for Other Damages</td>
                <td class='applePlanDetailsPrice'>₹ 8,900**</td>
                <td class='applePlanDetailsPrice'>₹ 8,900**</td>
                <td class='applePlanDetailsPrice'>₹ 8,900**</td>
                <td class='applePlanDetailsPrice'>₹ 8,900**</td>
              </tr>
            </table>`,
          TableSubTxt: '**Prices include applicable taxes (GST)',
        },
      ],
    }
  } else if (_.includes([423], planId)) {
    // AppleCare+ for iPad
    appleData = {
      TableSection: [
        {
          TableHeading: 'Plan Price',
          TableBody: `<table>
              <tr>
                <th class='applePlanDetailsHeaderColumn'>Product Details</th>
                <th class='applePlanDetailsHeaderRow'>iPad, iPad Mini, iPad Air</th>
                <th class='applePlanDetailsHeaderRow'>iPad Pro 11-inch</th>
                <th class='applePlanDetailsHeaderRow'>iPad Pro 12.9-inch M1</th>
                <th class='applePlanDetailsHeaderRow'>iPad Air (5th Generation)</th>
              </tr>
              <tr>
                <td class='applePlanDetailsHeaderColumn'>AppleCare+ Plan Price</td>
                <td class='applePlanDetailsPrice'>₹ 7,500**</td>
                <td class='applePlanDetailsPrice'>₹ 15,900**</td>
                <td class='applePlanDetailsPrice'>₹ 17,900**</td>
                <td class='applePlanDetailsPrice'>₹ 9,500**</td>
              </tr>
            </table>`,
          TableSubTxt: '**Prices include applicable taxes (GST)',
        },
        {
          TableHeading: 'Service Fees',
          TableBody: `<table>
              <tr>
                <th class='applePlanDetailsHeaderColumn'>Product Details</th>
                <th class='applePlanDetailsHeaderRow'>iPad, iPad Mini, iPad Air</th>
                <th class='applePlanDetailsHeaderRow'>iPad Pro 11-inch</th>
                <th class='applePlanDetailsHeaderRow'>iPad Pro 12.9-inch M1</th>
                <th class='applePlanDetailsHeaderRow'>iPad Air (5th Generation)</th>
              </tr>
              <tr>
                <td class='applePlanDetailsHeaderColumn'>Service Fee for iPad Damage</td>
                <td class='applePlanDetailsPrice'>₹ 3,900**</td>
                <td class='applePlanDetailsPrice'>₹ 3,900**</td>
                <td class='applePlanDetailsPrice'>₹ 3,900**</td>
                <td class='applePlanDetailsPrice'>₹ 3,900**</td>
              </tr>
              <tr>
                <td class='applePlanDetailsHeaderColumn'>Service Fee for Apple Pencil & Keyboard Damage</td>
                <td class='applePlanDetailsPrice'>₹ 2,500**</td>
                <td class='applePlanDetailsPrice'>₹ 2,500**</td>
                <td class='applePlanDetailsPrice'>₹ 2,500**</td>
                <td class='applePlanDetailsPrice'>₹ 2,500**</td>
              </tr>
            </table>`,
          TableSubTxt: '**Prices include applicable taxes (GST)',
        },
      ],
    }
  } else if (_.includes([426], planId)) {
    // AppleCare+ for Watch
    appleData = {
      TableSection: [
        {
          TableHeading: 'Plan Price',
          TableBody: `<table>
              <tr>
                <th class='applePlanDetailsHeaderColumn'>Product Details</th>
                <th class='applePlanDetailsHeaderRow'>Watch Series 3, Watch SE, Watch Nike SE</th>
                <th class='applePlanDetailsHeaderRow'>Watch Series 4, Watch Series 5, Watch Series 6, Watch Series 7, Watch Series 8, Watch Series 9</th>
                <th class='applePlanDetailsHeaderRow'>Watch Ultra, Watch Ultra 2</th>
              </tr>
              <tr>
                <td class='applePlanDetailsHeaderColumn'>AppleCare+ Plan Price</td>
                <td class='applePlanDetailsPrice'>₹ 6,900**</td>
                <td class='applePlanDetailsPrice'>₹ 7,900**</td>
                <td class='applePlanDetailsPrice'>₹ 9,900**</td>
              </tr>
            </table>`,
          TableSubTxt: '**Prices include applicable taxes (GST)',
        },
        {
          TableHeading: 'Service Fees',
          TableBody: `<table>
              <tr>
                <th class='applePlanDetailsHeaderColumn'>Product Details</th>
                <th class='applePlanDetailsHeaderRow'>Watch Series 3, Watch SE, Watch Nike SE </th>
                <th class='applePlanDetailsHeaderRow'>Watch Series 4, Watch Series 5, Watch Series 6, Watch Series 7, Watch Series 8, Watch Series 9</th>
                <th class='applePlanDetailsHeaderRow'>Watch Ultra, Watch Ultra 2</th>        
              </tr>
              <tr>
                <td class='applePlanDetailsHeaderColumn'>Service Fee for All Damages</td>
                <td class='applePlanDetailsPrice'>₹ 5,900**</td>
                <td class='applePlanDetailsPrice'>₹ 5,900**</td>
                <td class='applePlanDetailsPrice'>₹ 6,900**</td>
              </tr>
            </table>`,
          TableSubTxt: '**Prices include applicable taxes (GST)',
        },
      ],
    }
  } else if (_.includes([424], planId)) {
    // AppleCare+ for Mac
    appleData = {
      TableSection: [
        {
          TableHeading: 'Plan Price',
          TableBody: `<table>
              <tr>
                <th class='applePlanDetailsHeaderColumn'>Product Details</th>
                <th class='applePlanDetailsHeaderRow'>iMac</th>
                <th class='applePlanDetailsHeaderRow'>MacBook Air</th>
                <th class='applePlanDetailsHeaderRow'>MacBook Pro 14"(M1, M2), MacBook Pro&nbsp;13”&nbsp;(Intel), MacBook Pro 15”,  Mac Pro”</th>
                <th class='applePlanDetailsHeaderRow'>MacBook Pro 13” (M1,M2)</th>
                <th class='applePlanDetailsHeaderRow'>MacBook Pro 16"&nbsp;(Intel)</th>
                <th class='applePlanDetailsHeaderRow'>Mac Mini</th>
                <th class='applePlanDetailsHeaderRow'>MacBook Pro 16" (M1, M2)</th>
                <th class='applePlanDetailsHeaderRow'>Mac Studio</th>
                <th class='applePlanDetailsHeaderRow'>MacBook Air (M2)</th>
              </tr>
              <tr>
                <td class='applePlanDetailsHeaderColumn'>AppleCare+ Plan Price</td>
                <td class='applePlanDetailsPrice'>₹ 15,900**</td>
                <td class='applePlanDetailsPrice'>₹ 18,900**</td>
                <td class='applePlanDetailsPrice'>₹ 24,900**</td>
                <td class='applePlanDetailsPrice'>₹ 22,900**</td>
                <td class='applePlanDetailsPrice'>₹ 33,900**</td>
                <td class='applePlanDetailsPrice'>₹ 9,900**</td>
                <td class='applePlanDetailsPrice'>₹ 34,900**</td>
                <td class='applePlanDetailsPrice'>₹ 16,900**</td>
                <td class='applePlanDetailsPrice'>₹ 20,900**</td>
              </tr>
            </table>`,
          TableSubTxt: '**Prices include applicable taxes (GST)',
        },
        {
          TableHeading: 'Service Fees',
          TableBody: `<table>
            <tr>
                <th class='applePlanDetailsHeaderColumn'>Product Details</th>
                <th class='applePlanDetailsHeaderRow'>iMac</th>
                <th class='applePlanDetailsHeaderRow'>MacBook Air</th>
                <th class='applePlanDetailsHeaderRow'>MacBook Pro 14"(M1, M2), MacBook Pro&nbsp;13”&nbsp;(Intel), MacBook Pro 15”,  Mac Pro”</th>
                <th class='applePlanDetailsHeaderRow'>MacBook Pro 13” (M1,M2)</th>
                <th class='applePlanDetailsHeaderRow'>MacBook Pro 16"&nbsp;(Intel)</th>
                <th class='applePlanDetailsHeaderRow'>Mac Mini</th>
                <th class='applePlanDetailsHeaderRow'>MacBook Pro 16" (M1, M2)</th>
                <th class='applePlanDetailsHeaderRow'>Mac Studio</th>
                <th class='applePlanDetailsHeaderRow'>MacBook Air (M2)</th>
            </tr>
            <tr>
                <td class='applePlanDetailsHeaderColumn'>Service Fee for Screen and Enclosure Damage</td>
                <td class='applePlanDetailsPrice'>₹ 8,900**</td>
                <td class='applePlanDetailsPrice'>₹ 8,900**</td>
                <td class='applePlanDetailsPrice'>₹ 8,900**</td>
                <td class='applePlanDetailsPrice'>₹ 8,900**</td>
                <td class='applePlanDetailsPrice'>₹ 8,900**</td>
                <td class='applePlanDetailsPrice'>₹ 8,900**</td>
                <td class='applePlanDetailsPrice'>₹ 8,900**</td>
                <td class='applePlanDetailsPrice'>₹ 8,900**</td>
                <td class='applePlanDetailsPrice'>₹ 8,900**</td>
            </tr>
            <tr>
                <td class='applePlanDetailsHeaderColumn'>Service Fee for all Other Damages</td>
                <td class='applePlanDetailsPrice'>₹ 25,900**</td>
                <td class='applePlanDetailsPrice'>₹ 25,900**</td>
                <td class='applePlanDetailsPrice'>₹ 25,900**</td>
                <td class='applePlanDetailsPrice'>₹ 25,900**</td>
                <td class='applePlanDetailsPrice'>₹ 25,900**</td>
                <td class='applePlanDetailsPrice'>₹ 25,900**</td>
                <td class='applePlanDetailsPrice'>₹ 25,900**</td>
                <td class='applePlanDetailsPrice'>₹ 25,900**</td>
                <td class='applePlanDetailsPrice'>₹ 25,900**</td>
            </tr>
            </table>`,
          TableSubTxt: '**Prices include applicable taxes (GST)',
        },
      ],
    }
  } else if (_.includes([427], planId)) {
    // AppleCare+ for Airpods / Headphones
    appleData = {
      TableSection: [
        {
          TableHeading: 'Plan Price',
          TableBody: `<table>
              <tr>
                <th class='applePlanDetailsHeaderColumn'>Product Details</th>
                <th class='applePlanDetailsHeaderRow'>Airpods</th>
                <th class='applePlanDetailsHeaderRow'>Airpods Max</th>
                <th class='applePlanDetailsHeaderRow'>Beats earphones, Beats Headphones (except urBeats3)</th>
              </tr>
              <tr>
                <td class='applePlanDetailsHeaderColumn'>AppleCare+ Plan Price</td>
                <td class='applePlanDetailsPrice'>₹ 2,900**</td>
                <td class='applePlanDetailsPrice'>₹ 6,900**</td>
                <td class='applePlanDetailsPrice'>₹ 2,900**</td>
              </tr>
            </table>`,
          TableSubTxt: '**Prices include applicable taxes (GST)',
        },
        {
          TableHeading: 'Service Fees',
          TableBody: `<table>
            <tr>
                <th class='applePlanDetailsHeaderColumn'>Product Details</th>
                <th class='applePlanDetailsHeaderRow'>Airpods</th>
                <th class='applePlanDetailsHeaderRow'>Airpods Max</th>
                <th class='applePlanDetailsHeaderRow'>Beats earphones, Beats Headphones (except urBeats3)</th>
            </tr>
            <tr>
                <td class='applePlanDetailsHeaderColumn'>Service Fee for All Damages</td>
                <td class='applePlanDetailsPrice'>₹ 2,500**</td>
                <td class='applePlanDetailsPrice'>₹ 2,500**</td>
                <td class='applePlanDetailsPrice'>₹ 2,500**</td>
            </tr>
            </table>`,
          TableSubTxt: '**Prices include applicable taxes (GST)',
        },
      ],
    }
  } else if (_.includes([428], planId)) {
    // AppleCare+ for HomePod
    appleData = {
      TableSection: [
        {
          TableHeading: 'Plan Price',
          TableBody: `<table>
              <tr>
                <th class='applePlanDetailsHeaderColumn'>Product Details</th>
                <th class='applePlanDetailsHeaderRow'>HomePod</th>
                <th class='applePlanDetailsHeaderRow'>HomePod Mini</th>
              </tr>
              <tr>
                <td class='applePlanDetailsHeaderColumn'>AppleCare+ Plan Price</td>
                <td class='applePlanDetailsPrice'>₹ 3,900**</td>
                <td class='applePlanDetailsPrice'>₹ 1,600**</td>
              </tr>
            </table>`,
          TableSubTxt: '**Prices include applicable taxes (GST)',
        },
        {
          TableHeading: 'Service Fees',
          TableBody: `<table>
            <tr>
                <th class='applePlanDetailsHeaderColumn'>Product Details</th>
                <th class='applePlanDetailsHeaderRow'>HomePod</th>
                <th class='applePlanDetailsHeaderRow'>HomePod Mini</th>
            </tr>
            <tr>
                <td class='applePlanDetailsHeaderColumn'>Service Fee for All Damages</td>
                <td class='applePlanDetailsPrice'>₹ 2,900**</td>
                <td class='applePlanDetailsPrice'>₹ 1,290**</td>
            </tr>
            </table>`,
          TableSubTxt: '**Prices include applicable taxes (GST)',
        },
      ],
    }
  } else if (_.includes([429], planId)) {
    // AppleCare+ for iPod
    appleData = {
      TableSection: [
        {
          TableHeading: 'Plan Price',
          TableBody: `<table>
              <tr>
                <th class='applePlanDetailsHeaderColumn'>Product Details</th>
                <th class='applePlanDetailsHeaderRow'>iPod</th>
              </tr>
              <tr>
                <td class='applePlanDetailsHeaderColumn'>AppleCare+ Plan Price</td>
                <td class='applePlanDetailsPrice'>₹ 5,900**</td>
              </tr>
            </table>`,
          TableSubTxt: '**Prices include applicable taxes (GST)',
        },
        {
          TableHeading: 'Service Fees',
          TableBody: `<table>
            <tr>
                <th class='applePlanDetailsHeaderColumn'>Product Details</th>
                <th class='applePlanDetailsHeaderRow'>iPod</th>
            </tr>
            <tr>
                <td class='applePlanDetailsHeaderColumn'>Service Fee for All Damages</td>
                <td class='applePlanDetailsPrice'>₹ 2,500**</td>
            </tr>
            </table>`,
          TableSubTxt: '**Prices include applicable taxes (GST)',
        },
      ],
    }
  } else if (_.includes([425], planId)) {
    // AppleCare+ for Apple Display
    appleData = {
      TableSection: [
        {
          TableHeading: 'Plan Price',
          TableBody: `<table>
              <tr>
                <th class='applePlanDetailsHeaderColumn'>Product Details</th>
                <th class='applePlanDetailsHeaderRow'>Apple Display</th>
                <th class='applePlanDetailsHeaderRow'>Apple Studio Display</th>
              </tr>
              <tr>
                <td class='applePlanDetailsHeaderColumn'>AppleCare+ Plan Price</td>
                <td class='applePlanDetailsPrice'>₹ 50,900**</td>
                <td class='applePlanDetailsPrice'>₹ 13,900**</td>
              </tr>
            </table>`,
          TableSubTxt: '**Prices include applicable taxes (GST)',
        },
        {
          TableHeading: 'Service Fees',
          TableBody: `<table>
            <tr>
              <th class='applePlanDetailsHeaderColumn'>Product Details</th>
              <th class='applePlanDetailsHeaderRow'>Apple Display</th>
              <th class='applePlanDetailsHeaderRow'>Apple Studio Display</th>
            </tr>
            <tr>
              <td class='applePlanDetailsHeaderColumn'>Service Fee for Screen and Enclosure Damage</td>
              <td class='applePlanDetailsPrice'>₹ 8,900**</td>
              <td class='applePlanDetailsPrice'>₹ 8,900**</td>
            </tr>
            <tr>
              <td class='applePlanDetailsHeaderColumn'>Service Fee for all other Damage</td>
              <td class='applePlanDetailsPrice'>₹ 25,900**</td>
              <td class='applePlanDetailsPrice'>₹ 25,900**</td>
            </tr>
            </table>`,
          TableSubTxt: '**Prices include applicable taxes (GST)',
        },
      ],
    }
  }

  return appleData
}
