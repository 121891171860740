import { connect } from 'react-redux'
import {
  getUserDetailsAsync,
  getUserAddressesAsync,
  getUserDocsAsync,
  clearConsumerDetails,
  updateUserDetailsAsync,
  getSignedUrlAsync,
  addDocAsync,
  getBrowserLocationAsync,
  getGeoLocationAsync,
  updateAddressAsync,
  addAddressAsync,
  deleteAddressAsync,
  deleteUserDocsAsync,
  setUserDetails,
  getDeviceOverviewAsync,
  clearSessionAsync,
  getCountriesAsync,
  getStateListAsync,
  addConsumerDocToDSAsync,
  getDistrictListAsync,
  setDistrictListConfig,
} from '../../../store/user'
import { UserDevicePointsAsync } from '../../../store/devices'
import { setToaster, setglobalLoaderStatus } from '../../../store/toastsAndLoaders'
import Profile from '../components/Profile'

const mapDispatchToProps = {
  getUserDetailsAsync,
  getUserAddressesAsync,
  getUserDocsAsync,
  updateUserDetailsAsync,
  getSignedUrlAsync,
  addDocAsync,
  getBrowserLocationAsync,
  getGeoLocationAsync,
  updateAddressAsync,
  addAddressAsync,
  deleteAddressAsync,
  UserDevicePointsAsync,
  clearConsumerDetails,
  deleteUserDocsAsync,
  setglobalLoaderStatus,
  setToaster,
  setUserDetails,
  getDeviceOverviewAsync,
  clearSessionAsync,
  getCountriesAsync,
  getStateListAsync,
  addConsumerDocToDSAsync,
  getDistrictListAsync,
  setDistrictListConfig,
}

const mapStateToProps = (state) => ({
  user: state.user,
  translationData: state.languageDetail.translationText,
  // toastsAndLoaders: state.toastsAndLoaders
})

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
