import _ from 'lodash'
import { api } from '../config/variables'

export default () => (_dispatch, getState) =>
  axios.get(`${api.coreUri}/list/feedback`, {
    headers: {
      Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
      customHeader: true,
    },
  })
