export const SET_TRANSLATION_TEXT = 'SET_TRANSLATION_TEXT'

export const setTranslationText = (payload) => {
  return {
    type: SET_TRANSLATION_TEXT,
    payload,
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SET_TRANSLATION_TEXT]: (state, action) => ({
    ...state,
    translationText: action.payload.translationText,
    languageCode: action.payload.languageCode,
  }),
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  translationText: {},
  languageCode: localStorage.getItem('languageCode'),
}

export default function languageReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
