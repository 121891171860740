// ------------------------------------
// Constants
// ------------------------------------

// import _ from 'lodash'
//
export const SET_TOAST = 'SET_TOAST'
export const UNSET_TOAST = 'UNSET_TOAST'
export const GLOBAL_LOADER_STATUS = 'GLOBAL_LOADER_STATUS'

// ------------------------------------
// Actions
// ------------------------------------
export const setglobalLoaderStatus = (payload) => {
  return {
    type: GLOBAL_LOADER_STATUS,
    payload,
  }
}

export const setToaster = (message, type) => {
  return (dispatch) => {
    if (message) {
      dispatch(
        setToast({
          message,
          type,
        })
      )
    }
  }
}

export const setToast = (payload) => {
  return {
    type: SET_TOAST,
    payload,
  }
}

export const unsetToast = () => {
  return {
    type: UNSET_TOAST,
  }
}

const TOAST_TYPES = {
  error: 'error',
  warn: 'warn',
  success: 'success',
  info: 'info',
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  // [COUNTER_INCREMENT]    : (state, action) => state + action.payload,
  [SET_TOAST]: (state, action) => ({
    ...state,
    type: TOAST_TYPES[action.payload.type] || 'error',
    message: action.payload.message,
  }),
  [UNSET_TOAST]: (state) => ({
    ...state,
    type: '',
    message: '',
  }),
  [GLOBAL_LOADER_STATUS]: (state, action) => ({ ...state, globalLoaderStatus: action.payload }),
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  type: 'error',
  message: '',
  globalLoaderStatus: false,
}

export default function toastsAndLoadersReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
