import { api } from '../config/variables'
import { setToaster } from '../store/toastsAndLoaders'
import safePromise from '../utils/safePromise'
import { getTranslationText } from '../utils/languageTranslation.js'
import { setTranslationText } from '../store/languageTranslation'

// @ToDo uncomment below commented lines after core api release goes

const getCachedTranslations = () => JSON.parse(localStorage.getItem('translationsData'))
const hasCachedTranslations = () => false && !!localStorage.getItem('translationsData')

const fetchTranslations = async (getTranslationsApiUrl, body, options) =>
  hasCachedTranslations() ? { data: getCachedTranslations() } : axios.post(getTranslationsApiUrl, body, options)

export default (config = {}) => {
  const { languageCode = localStorage.getItem('languageCode') } = config
  let body = JSON.stringify({
    Source: 'Consumer',
  })
  const headers = { customHeader: true }
  const options = { headers }
  const getTranslationsApiUrl = api.coreUri + '/LanguagesText/translations/consumer'
  return async (dispatch) => {
    const [error, response] = await safePromise(fetchTranslations(getTranslationsApiUrl, body, options))
    if (error) {
      dispatch(setToaster(getTranslationText('error', 'translationsFailed'), 'error'))
      throw error
    }
    const { data: responseData } = response
    if (!responseData.success) {
      dispatch(setToaster(getTranslationText('error', 'noTranslations'), 'error'))
      throw new Error(getTranslationText('error', 'noTranslations'))
    }
    localStorage.setItem('translationsData', JSON.stringify(responseData))
    dispatch(setTranslationText({ translationText: responseData, languageCode }))
    return responseData
  }
}
