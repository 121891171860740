import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import './CustomRoute.scss'

import PrivacyPolicy from './PrivacyPolicy'
import TermsAndConditions from './TermsAndConditions'

export const routeMapping = {
  'about-us': 'AboutUs',
  'terms-conditions': 'Terms',
  'privacy-policy': 'Privacy',
  'online-consent': 'ConsentURL',
}
const CustomRoute = (props) => {
  const route = routeMapping[props.route.path]
  let link = ''
  if (props.route.path === 'terms-conditions') {
    const planBenefitsLink = localStorage.getItem('planBenefitsLink')
    console.log('planBenefitsLink - ', planBenefitsLink)
    link = planBenefitsLink || props.user.tncLink
    console.log('using the link - ', link)
    if (planBenefitsLink && planBenefitsLink.length > 0) localStorage.setItem('planBenefitsLink', '')
  } else if (props.route.path === 'privacy-policy') link = props.user.privacyLink
  else if (props.route.path === 'online-consent') link = props.user.dataLink
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  )
  if (props && props.user && props.user.appConfigDetails) {
    return pattern.test(link) ? (
      <iframe className='iframe' src={link} />
    ) : (
      <React.Fragment>
        <img className='about-us-logo' src={'/servify/images/logo.png'} />
        {props.user.appConfigDetails[route] ? (
          <div className={`${props.route.path}`} dangerouslySetInnerHTML={{ __html: props.user.appConfigDetails[route] }} />
        ) : props.route.path === 'terms-conditions' ? (
          <TermsAndConditions path={props.route.path} />
        ) : (
          <PrivacyPolicy path={props.route.path} />
        )}
      </React.Fragment>
    )
  }
  return null
}

const mapStateToProps = (state) => ({
  user: state.user,
})

CustomRoute.propTypes = {
  user: PropTypes.object,
  route: PropTypes.object,
}

export default connect(mapStateToProps, null)(CustomRoute)
