import React from 'react'
import './../GuestRoute.scss'

export const Essentials = () => {
  const essentialsBenfits = [
    {
      title: 'Convenient Mail-in Repair',
      text: `With convenient mail-in repair or replacement service, you’re back up and running in no time.`,
      img: `/${appName}/images/mycare/section3/5.svg`,
      url: '',
    },
    {
      title: '24/7 Expert Support',
      text: `Get immediate answers from a Samsung Care+ expert 24/7 via phone or online for device setup and connecting with other devices.`,
      img: `/${appName}/images/mycare/section3/3.svg`,
      url: '',
    },
    {
      title: 'Samsung Genuine Parts and Certified Technicians',
      text: `Rest assured your device will be repaired by a Samsung Certified Technician using Samsung Genuine Parts.`,
      img: `/${appName}/images/mycare/section3/4.svg`,
      url: '',
    },
  ]
  const section1 = [
    { header: 'Tier 1', cost: ['$19', '$19', '$19'] },
    { header: 'Tier 2', cost: ['$39', '$39', '$39'] },
    { header: 'Tier 3', cost: ['$59', '$59', '$59'] },
    { header: 'Tier 4', cost: ['$89', '$89', '$89'] },
  ]

  const section1mobile = {
    tableHeader: ['Tier 1', 'Tier 2', 'Tier 3', 'Tier 4'],
    tableData: [
      {
        header: {
          img: [
            `/${appName}/images/mycare/products/pc.png`,
            `/${appName}/images/mycare/products/earbud.png`,
            `/${appName}/images/mycare/products/rings.png`,
          ],
          title: `PC's, Buds, Rings`,
        },
        data: ['$17', '$39', '$59', '$89'],
      },
    ],
  }

  return (
    <div className='empty-route-container1 background-white'>
      <div className='row home-components1'>
        <div className='col-sm-12 col-lg-12 mb20 ml8'>
          <h1>Samsung Care+ Essentials</h1>
          <div className='fontExtraLarge'>Protection for your Galaxy life covers Mechanical Breakdowns</div>
        </div>

        {essentialsBenfits.map((item, index) => (
          <div key={index} className='col-sm-12 col-lg-4'>
            <div className='plan-benefit-section1'>
              <div className='plan-benefit-1'>
                <h4 className='boldFontStyle'>{item.title}</h4>
                <img src={item.img} />
              </div>
              <p className='fontLarge'>{item.text}</p>
            </div>
          </div>
        ))}
      </div>

      <div className='row home-components1 mt48 text-center'>
        <div className='col-sm-12 col-lg-12 mt48'>
          <h1>Samsung Care+ Essentials Pay Up Front</h1>
          <h4 className='mtb14'>24-month term</h4>
        </div>
        <div className='flex-table-section desktop'>
          <div className='gaps'>
            <div className='table-header'>Device</div>
            <div className='table-container'>
              <div className='table-details-center flex boldFontStyle align-left'>
                <img src={`/${appName}/images/mycare/products/pc.png`} />
                PC's
              </div>
              <br />
              <div className='table-details-center boldFontStyle align-left'>
                <img src={`/${appName}/images/mycare/products/earbud.png`} />
                Buds
              </div>
              <br />
              <div className='table-details-center boldFontStyle align-left'>
                <img src={`/${appName}/images/mycare/products/rings.png`} />
                Rings
              </div>
            </div>
          </div>
          {section1.map((value, index) => (
            <div key={index} className='gaps'>
              <div className='table-header'>{value.header}</div>
              <div className='table-container'>
                {value.cost.map((v, i) => (
                  <>
                    <div className='table-details-center boldFontStyle' key={i}>
                      {v}
                    </div>
                    {value.cost.length == i + 1 ? '' : <br />}
                  </>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className='mobile'>
          <div className='mobile'>
            <div className='table-header-section'>
              {section1mobile.tableHeader?.map((v, i) => (
                <div key={i} className='table-header-title'>
                  {v}
                </div>
              ))}
            </div>
            {section1mobile.tableData?.map((v, i) => (
              <div key={i} className='flex-table-section-mobile'>
                <div className='table-details-center boldFontStyle'>
                  {v.header.img.map((v, i) => (
                    <img key={i} src={v} />
                  ))}
                  {v.header.title}
                </div>
                <div className='table-data'>
                  {v.data.map((v, i) => (
                    <div key={i} className='table-details-center font20 boldFontStyle'>
                      {v}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <p
        className='text-center mt28 fontLarge'
        dangerouslySetInnerHTML={{
          __html: `Please see plan terms and conditions for full coverage details at 
        <a href='https://samsung.servify.tech/us' target='_blank'>
          samsung.servify.tech/us</a>`,
        }}
      />
    </div>
  )
}

export const EssentialsTnc = () => {
  const tnc = [
    {
      title: 'Payment method:',
      conditions: ['A one-time payment with coverage for 2-years'],
      subtext: '',
    },

    {
      title: 'What is covered:',
      conditions: [
        `Mechanical and electrical failure due to defects in materials or workmanship or normal wear and tear (malfunction) - protection begins after the manufacturer’s warranty expires`,
        `24/7 Expert Support - protection begins on the date of enrollment`,
      ],
      subtext: '',
    },
    {
      title: 'Claim limits:',
      conditions: [`Unlimited repairable malfunction claims up to the MSRP value of the device per 12-month period`],
      subtext: ``,
    },
    {
      title: 'Eligible devices:',
      conditions: [`Buds, Rings and PC’s`],
      subtext: ``,
    },
    {
      title: 'Cancellation policy:',
      conditions: [`You may cancel your optional coverage at any time and receive a refund of your unearned premium.`],
      subtext: ``,
    },
    {
      title: 'Important Disclosures:',
      conditions: [
        `Service contract covers out of warranty malfunctions, and tech support. No service fee or deductibles. For approved repairs, unlimited claims are covered, up to the MSRP value of the device per 12-month period. For approved replacements, we will cover one replacement per 12-month period. Coverage is optional. Cancel at any time for a prorated refund. Contains binding arbitration (Certain state exemptions may apply)`,
        `By adding a Samsung Care+ plan, you agree to the Samsung Care+ Terms of Service and the Service Contract Terms and Conditions`,
      ],
      subtext: ``,
    },
  ]
  const section2 = [
    {
      title: 'Enroll',
      text: `<p>Visit a Samsung store or go to<a href='http://samsung.com/us/support/samsung-care-plus' target='_blank'>samsung.com/us/support/samsung-care-plus.</a></p>`,
      img: `/${appName}/images/mycare/section3/6.svg`,
      url: '',
    },
    {
      title: 'Submit a claim',
      text: `<p>If your device is damaged or not working, visit visit <a href='https://samsung.servify.tech/us' target='_blank'>samsung.servify.tech/us</a> or call (833) 690-0918, 24 hours a day, 7 days a week. <br/>
      Claims must be submitted within 30 days of the incident</p>`,
      img: `/${appName}/images/mycare/section2/3.svg`,
      url: '',
    },
    {
      title: 'Contract 24/7 Expert Support',
      text: `<p>From set-up to troubleshooting, if you have questions visit <a href='https://samsung.servify.tech/us' target='_blank'>samsung.servify.tech/us</a> or call (833) 690-0918.</p>`,
      img: `/${appName}/images/mycare/section3/3.svg`,
      url: '',
    },
  ]
  return (
    <>
      <div className='empty-route-container1'>
        <div className='row home-components1'>
          <div className='col-sm-12 col-lg-12 mb20'>
            <h1>How does it work?</h1>
          </div>
          {section2.map((item, index) => (
            <div key={index} className='col-sm-12 col-lg-4'>
              <div className='box-holder1 section3'>
                <img src={item.img} />
                <h4 className='boldFontStyle'>{item.title}</h4>
                <span className='fontLarge' dangerouslySetInnerHTML={{ __html: item.text }} />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className='empty-route-container1 background-white'>
        <div className='row flex home-components1'>
          <div className='col-sm-12 col-lg-12'>
            <h1 className='mb28'>Plan Coverage Summary</h1>
            {tnc.map((item, index) => (
              <div key={index} className='terms-listing'>
                <div className='boldFontStyle fontLarge mb15'>{item.title}</div>
                <ul>
                  {item.conditions.map((value, index) => (
                    <li key={index} className='bookFontStyle fontLarge'>
                      {value}
                    </li>
                  ))}
                </ul>
                <div className='bookFontStyle fontLarge'>{item.subtext}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}
