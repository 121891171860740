import React from 'react'
import PropTypes from 'prop-types'

const TermsAndConditions = ({ path }) => (
  <div className={path}>
    <h2 style={{ textAlign: 'center', margin: '10px 0' }}>Service Lee Technologies Private Limited</h2>
    <h2 style={{ textAlign: 'center', margin: '10px 0' }}>TERMS AND CONDITIONS ("AGREEMENT")</h2>
    <p style={{ textAlign: 'center', margin: '10px 0' }}>
      Last updated: May 21<sup>st</sup>, 2018
    </p>
    <p className='terms-head'>
      <strong>1. Contractual Relationship</strong>
    </p>
    <p className='terms-content'>
      These Terms of Use ("Terms") govern the access or use by you, an individual, from within any country in the world (including the
      United States and its territories and possessions and Mainland China) of applications, websites, content, products, and services (the
      "Services") made available by Service Lee Technologies Private Limited, a private limited liability company incorporated in India
      under the Companies Act 2013, having its registered office at 1022, 2nd Floor, Building No.: 10, Solitaire Corporate Park,
      Andheri-Kurla Road, Andheri – East, Mumbai – 400 093, Maharashtra, India and through its subsidiaries and affiliates (collectively and
      individually known as "Servify"). You or Servify will be called individually as "Party" and collectively as "Parties".
    </p>
    <p className='terms-sub-head'>PLEASE READ THESE TERMS CAREFULLY BEFORE ACCESSING OR USING THE SERVICES.</p>
    <p className='terms-content'>
      Your access and use of the Services constitutes your agreement to be bound by these Terms, which establishes a contractual
      relationship between you and Servify. If you do not agree to these Terms, you may not access or use the Services. These Terms
      expressly supersede prior agreements or arrangements with you. Servify may immediately terminate these Terms or any Services with
      respect to you, or generally cease offering or deny access to the Services or any portion thereof, at any time for any reason.
    </p>
    <p className='terms-content'>
      Supplemental terms may apply to certain Services, such as policies for a particular event, activity or promotion, and such
      supplemental terms will be disclosed to you in connection with the applicable Services. Supplemental terms are in addition to, and
      shall be deemed a part of, the Terms for the purposes of the applicable Services. Supplemental terms shall prevail over these Terms in
      the event of a conflict with respect to the applicable Services.
    </p>
    <p className='terms-content'>
      Servify may amend the Terms related to the Services from time to time. Amendments will be effective upon Servify's posting of such
      updated Terms at this location or the amended policies or supplemental terms on the applicable Service. Your continued access or use
      of the Services after such posting constitutes your consent to be bound by the Terms, as amended.
    </p>
    <p className='terms-content'>
      Our collection and use of personal information in connection with the Services is as provided in Servify's Privacy Policy located at
      <a href='https://servify.tech/privacy'>servify.tech/privacy/</a>. Servify may provide to a claims processor or an insurer any
      necessary information (including your contact information) if there is a complaint, dispute or conflict, or acting as a Master Policy
      Holder in case of Insurance backed Services which may include a claim, involving you and a Third Party Provider (including independent
      third party repair service provider or independent third party logistics providers of Servify) and such information or data is
      necessary to resolve the complaint, dispute or conflict or deliver Services.
    </p>
    <p className='terms-head'>
      <strong>2. The Services</strong>
    </p>
    <p className='terms-content'>
      The Services constitute a technology platform that enables users of Servify's mobile applications or websites provided as part of the
      Services (each, an "Application") to arrange and schedule purchase, repair, sell and/or logistics services through independent third
      party providers of such services, including independent third party sellers, independent third party buyers, independent third party
      repair service providers and independent third party logistics providers under agreement with Servify or certain of Servify's
      affiliates ("Third Party Providers"). Unless otherwise agreed by Servify in a separate written agreement with you, the Services are
      made available solely for your personal, noncommercial use. YOU ACKNOWLEDGE THAT SERVIFY DOES NOT PROVIDE SALES SERVICES OR PURCHASE
      SERVICES OR REPAIR SERVICES OR LOGISTICS SERVICES; OR FUNCTION AS A SELLER OR A PURCHASER OR A REPAIR SERVICE PROVIDER OR LOGISTICS
      PROVIDER AND THAT ALL SUCH SELLER SERVICES OR PURCHASE SERVICES OR REPAIR SERVICES OR LOGISTICS SERVICES ARE PROVIDED BY INDEPENDENT
      THIRD PARTY CONTRACTORS WHO ARE NOT EMPLOYED BY SERVIFY OR ANY OF ITS AFFILIATES.
    </p>
    <p className='terms-sub-head'>License.</p>
    <p className='terms-content'>
      Subject to your compliance with these Terms, Servify grants you a limited, non-exclusive, non-sublicensable, revocable,
      non-transferrable license to: (i) access and use the Applications on your personal device solely in connection with your use of the
      Services; and (ii) access and use any content, information and related materials that may be made available through the Services, in
      each case solely for your personal, noncommercial use. Any rights not expressly granted herein are reserved by Servify and Servify's
      licensors.
    </p>
    <p className='terms-sub-head'>Restrictions.</p>
    <p className='terms-content'>
      You may not: (i) remove any copyright, trademark or other proprietary notices from any portion of the Services; (ii) reproduce,
      modify, prepare derivative works based upon, distribute, license, lease, sell, resell, transfer, publicly display, publicly perform,
      transmit, stream, broadcast or otherwise exploit the Services except as expressly permitted by Servify; (iii) decompile, reverse
      engineer or disassemble the Services; (iv) link to, mirror or frame any portion of the Services; (v) cause or launch any programs or
      scripts for the purpose of scraping, indexing, surveying, or otherwise data mining any portion of the Services or unduly burdening or
      hindering the operation and/or functionality of any aspect of the Services; or (vi) attempt to gain unauthorized access to or impair
      any aspect of the Services or its related systems or networks.
    </p>
    <p className='terms-sub-head'>Provision of the Services.</p>
    <p className='terms-content'>
      You acknowledge that portions of the Services may be made available under Servify's various brands or request options associated with
      sales, purchase, repair or logistics, including the service request brands currently referred to as "Servify," "Servicingly," "Before
      anyone else," "Suuma," "Zuuma," "Onesumer," "Servumer," "Servify++," "ServifyEXCL," and "ServifyLUXE". You also acknowledge that the
      Services may be made available under
      <br /> such brands or request options by or in connection with: (i) certain of Servify's subsidiaries and affiliates; or (ii)
      independent Third Party Providers and their associates, including independent third party sellers, independent third party buyers,
      independent third party repair service providers and independent third party logistics providers or holders of similar permits,
      authorizations or licenses.
    </p>
    <p className='terms-sub-head'>Third Party Services and Content.</p>
    <p className='terms-content'>
      The Services may be made available or accessed in connection with third party services, products, warranty and content (including
      advertising) that Servify may not control directly. You acknowledge that different terms of use, warranty and privacy policies may
      apply to your use of such third party services, product and content. Servify does not endorse such third party services, product,
      warranty and content and in no event shall Servify be responsible or liable for any products, warranty or services of such third party
      providers. Additionally, Apple Inc., Google, Inc., Microsoft Corporation or BlackBerry Limited and/or their applicable international
      subsidiaries and affiliates will be third-party beneficiaries to this contract if you access the Services using Applications developed
      for Apple iOS, Android, Microsoft Windows, or Blackberry-powered mobile devices, respectively. These third party beneficiaries are not
      parties to this contract and are not responsible for the provision or support of the Services in any manner. Your access to the
      Services using these devices is subject to terms set forth in the applicable third party beneficiary's terms of service.
    </p>
    <p className='terms-sub-head'>Pickup and Drop Service for Devices During Repair.</p>
    <p className='terms-content'>
      Servify may, at its sole discretion, offer pickup and drop-off (PUDO) services for repair at select locations and for select brands
      and devices. These services may be charged or complimentary depending on the benefits available to the customer. Servify may avail the
      services of third party service providers for the fulfilment of PUDO services. Servify and its associates or third party vendors or
      contractors or sub-contractors shall undertake reasonable care to ensure the safety of the devices during transit. Servify may, at its
      discretion, also provide reasonable and commercially viable compensation in case of loss to the device during transit. The customer
      agrees to such compensation as determined by Servify as final and binding compensation for the device lost in transit or while in the
      custody of Servify for repair.
    </p>
    <p className='terms-sub-head'>Ownership.</p>
    <p className='terms-content'>
      The Services and all rights therein are and shall remain Servify's property or the property of Servify's licensors. Neither these
      Terms nor your use of the Services convey or grant to you any rights: (i) in or related to the Services except for the limited license
      granted above; or (ii) to use or reference in any manner Servify's company names, logos, product and service names, trademarks or
      services marks or those of Servify's licensors.
    </p>
    <p className='terms-head'>
      <strong>3. Your Use of the Services</strong>
    </p>
    <p className='terms-sub-head'>User Accounts.</p>
    <p className='terms-content'>
      In order to use most aspects of the Services, you must register for and maintain an active personal user Services account ("Account").
      You must be at least 18 years of age, or the age of legal majority in your jurisdiction (if different than 18), to obtain an Account.
      Account registration requires you to submit to Servify certain personal information, such as your name, address, mobile phone number,
      as well as in some cases at least one valid payment method (either a credit card or accepted payment partner). You agree to maintain
      accurate, complete, and up-to-date information in your Account. Your failure to maintain accurate, complete, and up-to-date Account
      information, including having an invalid or expired payment method on file, may result in your inability to access and use the
      Services or Servify's termination of these Terms with you. You are responsible for all activity that occurs under your Account, and
      you agree to maintain the security and secrecy of your Account username and password at all times. Unless otherwise permitted by
      Servify in writing, you may only possess one Account.
    </p>
    <p className='terms-sub-head'>User Requirements and Conduct.</p>
    <p className='terms-content'>
      The Service is not available for use by persons under the age of 18 or the age of legal majority in your jurisdiction (if different
      than 18). You may not authorize third parties to use your Account, and you may not allow persons under the age of 18 to receive
      Services from Third Party Providers unless they are accompanied by you. You may not assign or otherwise transfer your Account to any
      other person or entity. You agree to comply with all applicable laws when using the Services, and you may only use the Services for
      lawful purposes (e.g., no sales of unlawful or hazardous materials or purchase of unlawful services). You will not, in your use of the
      Services, cause nuisance, annoyance, inconvenience, or property damage, whether to the Third Party Provider or any other party. In
      certain instances you may be asked to provide proof of identity to access or use the Services, and you agree that you may be denied
      access to or use of the Services if you refuse to provide proof of identity. You also agree and warrant Servify that you will not
      contract the Third Party Providers of Servify or their associates to obtain any services bypassing Servify by obtaining such Third
      Party Service Provider or associate details through Servify or the Application of Servify.
    </p>
    <p className='terms-sub-head'>Text Messaging and Notifications.</p>
    <p className='terms-content'>
      By creating an Account, you agree that the Services may send you informational text (SMS) messages and notifications as part of the
      normal business operation of your use of the Services. You may opt-out of receiving text (SMS) messages from Servify at any time by
      sending an email to
      <a href='mailto:support@servify.tech'>support@servify.tech</a> indicating that you no longer wish to receive such messages, along with
      the phone number of the mobile device receiving the messages. You acknowledge that opting out of receiving text (SMS) messages may
      impact your use of the Services.
    </p>
    <p className='terms-sub-head'>Promotional Codes.</p>
    <p className='terms-content'>
      Servify may, in Servify's sole discretion, create promotional codes that may be redeemed for Account credit, or other features or
      benefits related to the Services and/or a Third Party Provider's services, subject to any additional terms that Servify establishes on
      a per promotional code basis ("Promo Codes" or "Loyalty Points"). You agree that Promo Codes or Loyalty Points: (i) must be used for
      the intended audience and purpose, and in a lawful manner; (ii) may not be duplicated, sold or transferred in any manner, or made
      available to the general public (whether posted to a public form or otherwise), unless expressly permitted by Servify; (iii) may be
      disabled by Servify at any time for any reason without liability to Servify; (iv) may only be used pursuant to the specific terms that
      Servify establishes for such Promo Code or Loyalty Points; (v) are not valid for cash; and (vi) may expire prior to your use. Servify
      reserves the right to withhold or deduct credits or other features or benefits obtained through the use of Promo Codes or Loyalty
      Points by you or any other user in the event that Servify determines or believes that the use or redemption of the Promo Code or
      Loyalty Points was in error, fraudulent, illegal, or in violation of the applicable Promo Code terms or Loyalty Points Terms these
      Terms.
    </p>
    <p className='terms-sub-head'>User Provided Content.</p>
    <p className='terms-content'>
      Servify may, in Servify's sole discretion, permit you from time to time to submit, upload, publish or otherwise make available to
      Servify through the Services textual, audio, and/or visual content and information, including your device details, personal details or
      commentary and feedback related to the Services, initiation of support requests, and submission of entries for competitions and
      promotions ("User Content"). Any User Content provided by you remains your property. However, by providing User Content to Servify,
      you grant Servify a worldwide, perpetual, irrevocable, transferrable, royalty-free license, with the right to sublicense, to use,
      copy, modify, create derivative works of, distribute, publicly display, publicly perform, and otherwise exploit in any manner such
      User Content in all formats and distribution channels now known or hereafter devised (including in connection with the Services and
      Servify's business and on third-party sites and services), without further notice to or consent from you, and without the requirement
      of payment to you or any other person or entity.
    </p>
    <p className='terms-content'>
      You represent and warrant that: (i) you either are the sole and exclusive owner of all User Content or you have all rights, licenses,
      consents and releases necessary to grant Servify the license to the User Content as set forth above; and (ii) neither the User Content
      nor your submission, uploading, publishing or otherwise making available of such User Content nor Servify's use of the User Content as
      permitted herein will infringe, misappropriate or violate a third party's intellectual property or proprietary rights, or rights of
      publicity or privacy, or result in the violation of any applicable law or regulation.
    </p>
    <p className='terms-content'>
      You agree to not provide User Content that is defamatory, libelous, hateful, violent, obscene, pornographic, unlawful, or otherwise
      offensive, as determined by Servify in its sole discretion, whether or not such material may be protected by law. Servify may, but
      shall not be obligated to, review, monitor, or remove User Content, at Servify's sole discretion and at any time and for any reason,
      without notice to you.
    </p>
    <p className='terms-sub-head'>Network Access and Devices.</p>
    <p className='terms-content'>
      You are responsible for obtaining the data network access necessary to use the Services. Your mobile network's data and messaging
      rates and fees may apply if you access or use the Services from a wireless-enabled device and you shall be responsible for such rates
      and fees. You are responsible for acquiring and updating compatible hardware or devices necessary to access and use the Services and
      Applications and any updates thereto. Servify does not guarantee that the Services, or any portion thereof, will function on any
      particular hardware or devices. In addition, the Services may be subject to malfunctions and delays inherent in the use of the
      Internet and electronic communications.
    </p>
    <p className='terms-head'>
      <strong>4. Payment</strong>
    </p>
    <p className='terms-content'>
      You understand that use of the Services may result in charges to you for the services or goods you receive from a Third Party Provider
      ("Charges "). After you have received services or goods obtained through your use of the Service, Servify will facilitate your payment
      of the applicable Charges on behalf of the Third Party Provider as such Third Party Provider's limited payment collection agent.
      Payment of the Charges in such manner shall be considered the same as payment made directly by you to the Third Party Provider.
      Charges will be inclusive of applicable taxes where required by law. Charges paid by you are final and non-refundable, unless
      otherwise determined by Servify.
    </p>
    <p className='terms-content'>
      All Charges are due immediately and payment will be facilitated by Servify using the preferred payment method designated for obtaining
      services, after which Servify may send you a receipt by email in addition to the Invoice ("In App Invoice") available within the
      Application. If your primary Account payment method is determined to be expired, invalid or otherwise not able to be charged, you
      agree that Servify may, as the Third Party Provider's limited payment collection agent, use a secondary payment method in your
      Account, if available.
    </p>
    <p className='terms-content'>
      As between you and Servify, Servify reserves the right to establish, remove and/or revise Charges for any or all services or goods
      obtained through the use of the Services at any time in Servify's sole discretion. Further, you acknowledge and agree that Charges
      applicable in certain geographical areas may increase substantially during times of high demand. Servify will use reasonable efforts
      to inform you of Charges that may apply, provided that you will be responsible for Charges incurred under your Account regardless of
      your awareness of such Charges or the amounts thereof. Servify may from time to time provide certain users with promotional offers and
      discounts that may result in different amounts charged for the same or similar services or goods obtained through the use of the
      Services, and you agree that such promotional offers and discounts, unless also made available to you, shall have no bearing on your
      use of the Services or the Charges applied to you. You may elect to cancel your request for services or goods from a Third Party
      Provider at any time prior to such Third Party Provider's arrival, in which case you may be charged a cancellation fee.
    </p>
    <p className='terms-content'>
      This payment structure is intended to fully compensate the Third Party Provider for the services or goods provided. Servify does not
      designate any portion of your payment as a tip or gratuity to the Third Party Provider. Any representation by Servify (on Servify's
      website, in the Application, or in Servify's marketing materials) to the effect that tipping is "voluntary," "not required," and/or
      "included" in the payments you make for services or goods provided is not intended to suggest that Servify provides any additional
      amounts, beyond those described above, to the Third Party Provider. You understand and agree that, while you are free to provide
      additional payment as a gratuity to any Third Party Provider who provides you with services or goods obtained through the Service, you
      are under no obligation to do so. Gratuities are voluntary. After you have received services or goods obtained through the Service,
      you will have the opportunity to rate your experience and leave additional feedback about your Third Party Provider.
    </p>
    <p className='terms-head'>
      <strong>5. Disclaimers; Limitation of Liability; Indemnity.</strong>
    </p>
    <p className='terms-sub-head'>DISCLAIMER.</p>
    <p className='terms-content'>
      THE SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE." SERVIFY DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES, EXPRESS, IMPLIED OR
      STATUTORY, NOT EXPRESSLY SET OUT IN THESE TERMS, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE
      AND NON-INFRINGEMENT. IN ADDITION, SERVIFY MAKES NO REPRESENTATION, WARRANTY, OR GUARANTEE REGARDING THE RELIABILITY, TIMELINESS,
      QUALITY, SUITABILITY OR AVAILABILITY OF THE SERVICES OR ANY SERVICES OR GOODS REQUESTED THROUGH THE USE OF THE SERVICES, OR THAT THE
      SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE. SERVIFY DOES NOT GUARANTEE THE QUALITY, SUITABILITY, SAFETY OR ABILITY OF THIRD PARTY
      PROVIDERS. YOU AGREE THAT THE ENTIRE RISK ARISING OUT OF YOUR USE OF THE SERVICES, AND ANY SERVICE OR GOOD REQUESTED IN CONNECTION
      THEREWITH, REMAINS SOLELY WITH YOU, TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW.
    </p>
    <p className='terms-sub-head'>LIMITATION OF LIABILITY.</p>
    <p className='terms-content'>
      SERVIFY SHALL NOT BE LIABLE FOR INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS,
      LOST DATA, PERSONAL INJURY OR PROPERTY DAMAGE RELATED TO, IN CONNECTION WITH, OR OTHERWISE RESULTING FROM ANY USE OF THE SERVICES,
      EVEN IF SERVIFY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SERVIFY SHALL NOT BE LIABLE FOR ANY DAMAGES, LIABILITY OR LOSSES
      ARISING OUT OF: (i) YOUR USE OF OR RELIANCE ON THE SERVICES OR YOUR INABILITY TO ACCESS OR USE THE SERVICES; OR (ii) ANY TRANSACTION
      OR RELATIONSHIP BETWEEN YOU AND ANY THIRD PARTY PROVIDER, EVEN IF SERVIFY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SERVIFY
      SHALL NOT BE LIABLE FOR DELAY OR FAILURE IN PERFORMANCE RESULTING FROM CAUSES BEYOND SERVIFY'S REASONABLE CONTROL. YOU ACKNOWLEDGE
      THAT INDEPENDENT THIRD PARTY PROVIDERS PROVIDING SALES OR PURCHASE OR REPAIR OR LOGISTICS SERVICES REQUESTED THROUGH SOME REQUEST
      BRANDS MAY OFFER SERVICES WITHOUT BRAND AUTHORIZATION AND MAY NOT BE PROFESSIONALLY LICENSED OR PERMITTED. IN NO EVENT SHALL SERVIFY'S
      TOTAL LIABILITY TO YOU IN CONNECTION WITH THE SERVICES FOR ALL DAMAGES, LOSSES AND CAUSES OF ACTION EXCEED TWO HUNDRED RUPEES (INR
      200).
    </p>
    <p className='terms-content'>
      SERVIFY'S SERVICES MAY BE USED BY YOU TO REQUEST AND SCHEDULE REPAIR, PURCHASE GOODS, SELL GOODS OR LOGISTICS SERVICES WITH THIRD
      PARTY PROVIDERS, BUT YOU AGREE THAT SERVIFY HAS NO RESPONSIBILITY OR LIABILITY TO YOU RELATED TO ANY OF THESE SERVICES (INCLUDING BUT
      NOT LIMITED TO GOODS OR LOGISTICS SERVICES) PROVIDED TO YOU BY THIRD PARTY PROVIDERS OTHER THAN AS EXPRESSLY SET FORTH IN THESE TERMS.
    </p>
    <p className='terms-content'>
      THE LIMITATIONS AND DISCLAIMER IN THIS SECTION 5 DO NOT PURPORT TO LIMIT LIABILITY OR ALTER YOUR RIGHTS AS A CONSUMER THAT CANNOT BE
      EXCLUDED UNDER APPLICABLE LAW.
    </p>
    <p className='terms-sub-head'>Indemnity.</p>
    <p className='terms-content'>
      You agree to indemnify and hold Servify and its officers, directors, employees and agents harmless from any and all claims, demands,
      losses, liabilities, and expenses (including attorneys' fees) arising out of or in connection with: (i) your use of the Services or
      services or goods obtained through your use of the Services; (ii) your breach or violation of any of these Terms; (iii) Servify's use
      of your User Content; or (iv) your violation of the rights of any third party, including Third Party Providers.
    </p>
    <p className='terms-head'>
      <strong>6. Governing Law; Arbitration.</strong>
    </p>
    <p className='terms-content'>
      Except as otherwise set forth in these Terms, this Agreement shall be governed and interpreted by, and construed in accordance with,
      the substantive laws of India and, subject to the arbitration agreement, only the courts of Mumbai shall have exclusive jurisdiction.
      Both Parties hereby irrevocably agree that any dispute, conflict, claim or controversy arising out of or broadly in connection with or
      relating to the Services or these Terms, including those relating to its validity, its construction or its enforceability (any
      "Dispute") shall be first sought to be settled by mutual discussions between you and Servify, pursuant to a written notice sent by the
      Party claiming that a Dispute has arisen to the other Party involved in the Dispute, which notice shall contain the details of the
      Dispute. In the event that the Dispute cannot be settled by mutual discussions between the Parties within a period of 60 (Sixty)
      Business Days from the receipt of the notice by the other Party involved in the Dispute, the one sending the notice may commence
      arbitration proceedings under the (Indian) Arbitration and Conciliation Act, 1996 ("Arbitration Act"). Each Party shall be entitled to
      appoint (1) one arbitrator and the two appointed arbitrators shall mutually appoint a third person of repute to act as the presiding
      arbitrator ("Tribunal"). The venue of arbitration shall be in Mumbai. The language to be used in the arbitral proceedings shall be
      English. Any award made by the arbitrators shall be final and binding on both the Parties. The existence and content of the mediation
      and arbitration proceedings, including documents and briefs submitted by the Parties, correspondence from and to the arbitrators,
      correspondence from the mediator, and correspondence, orders and awards issued by the Tribunal, shall remain strictly confidential and
      shall not be disclosed to any third party without the express written consent from the other party unless: (i) the disclosure to the
      third party is reasonably required in the context of conducting the mediation or arbitration proceedings; and (ii) the third party
      agrees unconditionally in writing to be bound by the confidentiality obligation stipulated herein.
    </p>
    <p className='terms-head'>
      <strong>7. Other Provisions</strong>
    </p>
    <p className='terms-sub-head'>Claims of Copyright Infringement.</p>
    <p className='terms-content'>
      Claims of copyright infringement should be sent to Servify's designated agent. Please visit Servify's web page at
      <a href='https://servify.tech/'>servify.tech/</a> for the designated address and additional information.
    </p>
    <p className='terms-sub-head'>Notice.</p>
    <p className='terms-content'>
      Servify may give notice by means of a general notice on the Services, notification within the Application on your Account, electronic
      mail to your email address in your Account, or by written communication sent to your address as set forth in your Account. You may
      give notice to Servify by written communication to Servify's address at 1022, 2nd Floor, Building No.: 10, Solitaire Corporate Park,
      Andheri-Kurla Road, Andheri – East, Mumbai – 400 093, Maharashtra, India.
    </p>
    <p className='terms-sub-head'>General.</p>
    <p className='terms-content'>
      You may not assign or transfer these Terms in whole or in part without Servify's prior written approval. You give your approval to
      Servify for it to assign or transfer these Terms in whole or in part, including to: (i) a subsidiary or affiliate; (ii) an acquirer of
      Servify's equity, business or assets; or (iii) a successor by merger. No joint venture, partnership, employment or agency relationship
      exists between you, Servify or any Third Party Provider as a result of the contract between you and Servify or use of the Services.
    </p>
    <p className='terms-content'>
      If any provision of these Terms is held to be illegal, invalid or unenforceable, in whole or in part, under any law, such provision or
      part thereof shall to that extent be deemed not to form part of these Terms but the legality, validity and enforceability of the other
      provisions in these Terms shall not be affected. In that event, the parties shall replace the illegal, invalid or unenforceable
      provision or part thereof with a provision or part thereof that is legal, valid and enforceable and that has, to the greatest extent
      possible, a similar effect as the illegal, invalid or unenforceable provision or part thereof, given the contents and purpose of these
      Terms. These Terms constitute the entire agreement and understanding of the parties with respect to its subject matter and replaces
      and supersedes all prior or contemporaneous agreements or undertakings regarding such subject matter. In these Terms, the words
      "including" and "include" mean "including, but not limited to."
    </p>
  </div>
)

TermsAndConditions.propTypes = {
  path: PropTypes.string.isRequired,
}

export default TermsAndConditions
