var crypto = require('crypto-js')
const SALT_KEY = salt
// SALT_KEY addes as fallback

export const ivConstant = () => {
  let key3 = ''
  let key1
  for (let i = 0; i < 8; i++) {
    key1 = Math.floor(10000000 + Math.random() * 89999999).toString(16)
    key3 += key1
  }
  return key3.substring(0, 32)
}

export const hashKey = (data) => {
  let passPhrase = 'AQ3IC09T7B7SGYIK'
  window.iv = ivConstant()
  let key = crypto.PBKDF2(passPhrase, crypto.enc.Hex.parse(SALT_KEY), {
    keySize: 8,
    iterations: 64,
    hasher: crypto.algo.SHA1,
  })
  let cipher = crypto.AES.encrypt(JSON.stringify(data), key, {
    iv: crypto.enc.Hex.parse(window.iv),
    mode: crypto.mode.CBC,
    padding: crypto.pad.Pkcs7,
  })
  return cipher.toString()
}

export const getTzOffset = () => {
  let offset = new Date().getTimezoneOffset()
  let sign = '-'
  let offsetInHours
  let offsetInHoursDecimal = offset / 60 // -5.5
  if (offset < 0) {
    sign = '+'
    offsetInHours = Math.ceil(offsetInHoursDecimal)
  } else {
    offsetInHours = Math.floor(offsetInHoursDecimal)
  }

  let offsetInHoursStr = Math.abs(offsetInHours)
  if (offsetInHours < 10) {
    offsetInHoursStr = '0' + offsetInHoursStr
  }
  let mins = '00'
  if (offset % 60) {
    mins = (Math.abs(offsetInHoursDecimal) - Math.abs(offsetInHours)) * 60
    if (mins < 10) {
      mins = '0' + mins
    }
  }
  return sign + offsetInHoursStr + ':' + mins
}

// export const currentTime = () => {
//   let date = new Date()
//   let time = date.getTime()
//   return time
// }

export const decrypt = (req) => {
  var passPhrase = 'AQ3IC09T7B7SGYIK'
  var key = crypto.PBKDF2(passPhrase, crypto.enc.Hex.parse(SALT_KEY), {
    keySize: 8,
    iterations: 64,
  })
  var iv = req.co1cx2
  var decrypted = crypto.AES.decrypt(req.dr9se2q, key, {
    iv: crypto.enc.Hex.parse(iv),
    mode: crypto.mode.CBC,
    padding: crypto.pad.Pkcs7,
  })
  var plaintext = crypto.enc.Utf8.stringify(decrypted)
  var parsedJSON = JSON.parse(plaintext)
  if (parsedJSON['tzU'] && parsedJSON['appC'] && parsedJSON['response']) {
    if (parsedJSON['appC'] === '4T2E5RTVX5') {
      return parsedJSON['response']
    } else {
      return null
    }
  } else {
    return null
  }
}
