import React from 'react'
import PropTypes from 'prop-types'
import { TrackComponent, ReplacementOptions } from './TrackSubComponents'
import { browserHistory } from 'react-router'
import {
  RequestModal,
  CancelReqModal,
  InvoiceModal,
  EstimatedInvoiceModal,
  BEROptionsModal,
  PendingDocsModal,
  BERAccessoriesOptnModal,
  InstructionModal,
  DeliveryPartnerPopUp,
  EngineerDetailsModal,
  TreatmentsModal,
  SwapOptionsModal,
  CourierModal,
  AddDeviceIMEIModal,
  DropOffCenterList,
  DropOffShippingInstructions,
  AddressPincodeModal,
  ServiceThankYouModal,
} from './TrackModals'
import { ImageViewerModal, MediumButton, CustomIconComponent, Heading } from '../../../components/UIComponents'
import { Modal } from 'sfy-react'
import { ModalUploadDocuments } from '../../Request/components/RequestSubComponents'
import RatingModal from '../../../components/UIComponents/ReusableComponents/RatingModal'
import { plainTextCheck, regexCheck } from '../../../utils/regex'
import { getTranslationText } from '../../../utils/languageTranslation.js'
import { canUpload } from '../../../utils/validators'
import moment from 'moment'
import './Track.scss'
import _ from 'lodash'
import uuid from 'node-uuid'
import { sortedAddressConfig, getDocServicePayload, getFormattedTime } from '../../../utils/functions'
import { postRequestAsync } from '../../../api/genericAPIs'
import { docServiceModuleName, fallbackAddressConfig } from '../../../utils/constants'
import pushToAnalytics from '../../../utils/Analytics'
const informationImg = '/commonAssets/unSuccessAlert.png'
const successImg = '/commonAssets/successImg.png'

window.rzp1 = ''
class Track extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedTab: 0,
      showTreatmentsModal: false,
      showSwapOptions: false,
      showRescheduleModal: false,
      showCancelReqModal: false,
      showInvoiceModal: false,
      showRatingModal: false,
      showEstimatedInvoiceModal: false,
      paymentIncluded: false,
      showBERFlowModal: false,
      schedulePickSlotsModal: false,
      BtnStatusLoader: false,
      berModeOptions: [],
      berModeConfirmation: false,
      berBtnTxt: getTranslationText('common', 'confirm'),
      showPendingDocsModal: false,
      imageViewModal: false,
      accessoriesOptionModal: false,
      logisticPartnerInstructionModal: false,
      currentBEROptn: '',
      pendingDocsList: [],
      ratingbtnDisabled: true,
      isDisabledCheckout: false,
      ratingoptns: {
        options: [],
      },
      ratingval: 0,
      usrFeedbck: '',
      pickUpInstructions: [
        { source: 'instruction-docs', isSelected: false },
        { source: 'package', isSelected: false },
      ],
      feedBackOptnsList: [],
      instructionEnabled: true,
      trackRouteLoader: true,
      cancelReqInputValue: '',
      RescheduleModalTitle: '',
      sliderSettings: {
        slidesToShow: Math.ceil(document.body.clientWidth / 450),
        slidesToScroll: Math.ceil(document.body.clientWidth / 450),
      },
      labelPlacement: document.body.clientWidth < 768 ? 'horizontal' : 'vertical',
      direction: document.body.clientWidth < 768 ? 'vertical' : 'horizontal',
      showDeliveryPartnerDetails: false,
      deliveryPartnerDetails: {},
      showEngineerDetailModal: false,
      rescheduleSlots: [],
      reschedulableTimes: [],
      ReferenceID: '',
      vendorData: {},
      selectedVendor: [],
      // paymentGateways: [],
      // formFields: [],
      // paymentGateway: '',
      // formAction: '',
      showVideoMediaModal: false,
      hideReuploadBtn: false,
      uploadVideoFile: '',
      newDeviceDocsList: {},
      showSchedulePickup: false,
      showBottomSheet: false,
      isReschedulable: false,
      ShowDocument: false,
      ShowLogisticsInstruction: false,
      jobsheetS3Url: '',
      dropOffLocationsList: [],
      showPinCodeModal: false,
      showServiceThankYouModal: false,
      externalFeedback: [],
      dropoffPinCode: '',
      selectedReasonValue: '',
      showCancellationReasons: _.get(this, 'props.user.appConfigDetails.showCancellationReasons', false),
      showAdvanceExchangeHoldPaymentModal: false,
    }
  }

  getReschedulingSlots = (slotsData) => {
    let timeslots
    var bestSlot = false // to select the best available slot
    var ctr // to obtain the index of the the best available slot
    var Dateflg = false
    const locale = _.get(this, 'props.user.selectedCountryData.countryConfig.Locale.DEFAULT', 'en')
    const slots = slotsData.map((data, index) => {
      Dateflg = false // to set best avail date pill seleted
      var text = moment(data.date).format('MMM DD, ddd')
      var status = false // to disable time slot pills
      var Tflg = false // to set pills as selected : false by default
      var btndisableflg = false // removing Dates with all disabled slots
      data.slots.map((time) => {
        btndisableflg = false
        if (time.IsActive) {
          // removing the dates with no slots
          btndisableflg = true
        }
      })
      if (btndisableflg) {
        timeslots = data.slots.map((time) => {
          Tflg = false
          if (time.IsActive && bestSlot === false) {
            // selecting the best slot available
            Tflg = true
            Dateflg = true
            ctr = index
            bestSlot = true
          }
          var text = getFormattedTime(time.StartTimeVal, locale) + ' - ' + getFormattedTime(time.EndTimeVal, locale)
          status = false
          if (!time.IsActive) {
            status = true
          }
          return {
            text: text,
            isSelected: Tflg,
            timeFrom: time.StartTimeVal,
            timeTo: time.EndTimeVal,
            IsActive: !status,
          }
        })
        return [{ text: text, isSelected: Dateflg, date: data.date }, timeslots]
      }
    })
    this.setState({
      rescheduleSlots: _.compact(slots),
      reschedulableTimes: slots[ctr][1],
    })
  }

  updateTrackPage = () => {
    const showJobSheet = _.get(this, 'props.user.appConfigDetails.showJobSheet', false)
    let AuthID = window.Authorization || this.props.user.consumerDetails.data.accessToken.ID
    this.props.setglobalLoaderStatus(true)
    let promises = []
    promises[0] = this.props.getTrackDetailsAsync({
      ConsumerServiceRequestID:
        (this.props.location.query?.id && parseInt(window.atob(this.props.location.query?.id?.toString()))) || undefined,
      ReferenceID: this.props.location.query?.refid || undefined,
      AuthID,
    })
    promises[1] = this.props.getRequestDetailsAsync({
      ConsumerServiceRequestID:
        (this.props.location.query?.id && parseInt(window.atob(this.props.location.query?.id?.toString()))) || undefined,
      ReferenceID: this.props.location.query?.refid || undefined,
      AuthID,
    })
    Promise.all(promises)
      .then(() => {
        if (_.includes([17, 23], this.props.track.trackDetails.ServiceTypeID)) {
          this.props.setglobalLoaderStatus(true)
          let damageId =
            _.get(this, 'props.request.raisedRequest.damage', []).length &&
            this.props.request.raisedRequest.damage.filter((obj) => obj.active)[0].damageId
          let reqPayload = {}
          if (_.get(this, 'props.track.trackDetails.ExternalConsumerProducts', []).length) {
            reqPayload['ProductInfo'] = this.props.track.trackDetails.ExternalConsumerProducts.map((info) => ({
              ProductID: info.ProductID,
              ConsumerProductID: info.ConsumerProductID,
              ProductSubCategoryID: info.ProductSubCategoryID,
              BrandID: info.BrandID,
            }))
          } else {
            reqPayload = {
              ProductID: this.props.track.trackDetails.ConsumerProduct.ProductID,
              ConsumerProductID: this.props.track.trackDetails.ConsumerProduct.ConsumerProductID,
              ProductSubCategoryID: this.props.track.trackDetails.ConsumerProduct.ProductSubCategoryID,
              BrandID: this.props.track.trackDetails.ConsumerProduct.BrandID,
            }
          }
          return this.props.getRequestModesAsync(
            {
              ...reqPayload,
              damageId: damageId || undefined,
              Category: this.props.track.requestDetails.servicetype.Category,
              Lat: parseFloat(this.props.track.requestDetails.Lat),
              Lng: parseFloat(this.props.track.requestDetails.Lng),
              SoldPlanID:
                _.get(this, 'props.track.requestDetails.SoldPlanID') && this.props.track.requestDetails.SoldPlanID !== -1
                  ? this.props.track.requestDetails.SoldPlanID
                  : undefined,
              ServiceTypeID: this.props.track.requestDetails.ServiceTypeID,
              CountryID: this.props.user.selectedCountryData.CountryID,
              Partnered: 1, // hardcoded
              Radius: 80, // hardcoded
              Zipcode: this.props.track.requestDetails.PinCode
                ? this.props.track.requestDetails.PinCode
                : parseInt(this.props.track.requestDetails.Zipcode), // 85281
            },
            window.Authorization || this.props.user.consumerDetails.data.accessToken.ID
          )
        }
        this.props.getClaimLegalConfigs({
          PlanCoverageID: _.get(this, 'props.track.trackDetails.PlanCoverageID'),
          brandID: _.get(this, 'props.track.trackDetails.ConsumerProduct.BrandID'),
        })
      })
      .then(async () => {
        this.props.setglobalLoaderStatus(false)
        let latestLog = this.props.track.trackDetails.Logs.find((log) => log.DisplayInfo.GroupId && !log.DisplayInfo.Hidden)
        if (localStorage.getItem('source') !== 'chatbot') {
          this.addressPreviewOrder()
        }
        if (this.state.showCancellationReasons && _.get(this, 'props.track.trackDetails.isCancelable', false)) {
          this.props.getCancelReasonDetailsAsync({
            ServiceTypeID: this.props.track.requestDetails.ServiceTypeID,
            Status: latestLog.DisplayInfo.Status,
          })
        }
        if (latestLog) {
          if (latestLog.DisplayInfo.ShowDropOffCenterDetail && _.get(this, 'props.track.trackDetails.DropOffCenter')) {
            this.setState({
              listDownServiceCenters: true,
            })
          }
          if (latestLog.DisplayInfo.ShowBER || latestLog.DisplayInfo.ShowAccessoriesOption) {
            this.props.fetchBERModeListAysnc({
              Action: latestLog.DisplayInfo.ScreenDisplay[0].Action,
              ConsumerServiceRequestID: window.atob(this.props.location.query.id.toString()),
              Status: latestLog.DisplayInfo.Status,
            })
          }
          if (latestLog.DisplayInfo.ShowLogisticsInstruction || latestLog.DisplayInfo.ShowBottomSheet) {
            this.props.getLogisticsDetailsAsync({
              ConsumerServiceRequestID:
                (this.props.location.query?.id && parseInt(window.atob(this.props.location.query?.id?.toString()))) ||
                this.props.track.trackDetails?.ConsumerProduct?.ConsumerServiceRequestID,
            })
          }
          if ([46, 63].includes(parseInt(this.props.track.requestDetails.ServiceTypeID))) {
            this.props.getServicetypeDetailsAsync({
              ProductSubCategoryID: this.props.track.trackDetails.ConsumerProduct.ProductSubCategoryID,
              Action: [63].includes(parseInt(this.props.track.requestDetails.ServiceTypeID)) ? 'proofOfDamageInstructions' : undefined,
            })
          }
          if (latestLog.DisplayInfo.ShowDocument) {
            this.props
              .pendingDocsListAsync({
                data: {
                  ConsumerServiceRequestID:
                    (this.props.location.query?.id && parseInt(window.atob(this.props.location.query?.id?.toString()))) ||
                    this.props.track.trackDetails?.ConsumerProduct?.ConsumerServiceRequestID,
                  productID: parseInt(this.props.track.trackDetails.ConsumerProduct.ConsumerProductID),
                },
              })
              .then((response) => {
                this.setState({
                  pendingDocsList: _.compact(
                    response.map((doc) => {
                      return {
                        ...doc,
                        isUploaded: !(doc.IsPendingDocument === 1), // if pending then uploaded as false
                      }
                    })
                  ),
                })
              })
            this.props
              .getProductDocsAsync(
                { ConsumerProductID: this.props.track.trackDetails.ConsumerProduct.ConsumerProductID },
                window.Authorization || this.props.user.consumerDetails.data.accessToken.ID
              )
              .then(() => {
                this.setState({
                  deviceDocsList: this.props.devices.productDocs,
                })
              })
          }
          if (showJobSheet && latestLog.DisplayInfo.ShowBottomSheet) {
            this.props
              .viewJobSheetDetailsAsync({
                ConsumerServiceRequestID:
                  (this.props.location.query?.id && parseInt(window.atob(this.props.location.query?.id?.toString()))) ||
                  this.props.track.trackDetails?.ConsumerProduct?.ConsumerServiceRequestID,
                ServiceTypeID: this.props.track.requestDetails.ServiceTypeID,
              })
              .then((res) => {
                this.setState({
                  jobsheetS3Url: res.jobsheetS3Url,
                })
              })
          }
          if (latestLog.DisplayInfo.UpdateCourierDetails) {
            this.props.setglobalLoaderStatus(true)
            let courierPromise = []
            courierPromise[0] = this.props.fetchCourierPartners()
            Promise.all(courierPromise)
              .then(() => {
                this.props.setglobalLoaderStatus(false)
                this.setState({
                  courierVendorList: this.props.track.trackDetails.courierDetails,
                })
              })
              .catch((err) => {
                console.log(err)
                this.props.setglobalLoaderStatus(false)
              })
          }
        }
        this.setState({
          groupDetails: this.props.track.trackDetails.groupDetail,
          logsReversed: [...this.props.track.trackDetails.Logs].reverse(),
          ConsumerProduct: this.props.track.trackDetails.ConsumerProduct,
          ReferenceID: this.props.track.trackDetails.ReferenceID,
          requestDetails: this.props.track.requestDetails,
          trackDetails: this.props.track.trackDetails,
          trackRouteLoader: false,
          BtnStatusLoader: false,
        })
        if (localStorage.getItem('source') !== 'chatbot') {
          this.props.getNotificationsAsync({
            ConsumerID: this.props.user.userDetails.consumerID,
            AuthID,
          })
        }
      })
      .catch(() => {
        this.props.setglobalLoaderStatus(false)
        this.setState({
          trackRouteLoader: false,
        })
      })
  }

  componentDidMount() {
    this.props.clearDeviceState({})
    this.props.getCountriesAsync()
    window.addEventListener('resize', _.throttle(this.handleWindowResize, { trailing: true }))
    /** setting changeDropOffLocation to false when coming back to track */
    _.get(this, 'props.request.source.changeDropOffLocation') &&
      this.props.setRequestFlowSource({
        ...this.props.request.source,
        changeDropOffLocation: false,
      })

    const advanceExchange = _.includes([65], parseInt(_.get(this, 'props.track.trackDetails.ServiceTypeID', '')))
    if (advanceExchange) {
      if (_.get(this, 'props.track.advanceExchangeHoldPaymentStatus', '').includes('Failed')) {
        // failure modal
        this.setState({
          showAdvanceExchangeHoldPaymentModal: true,
          isAdvanceExchanceHoldPaymentTitleText: getTranslationText('track', 'credit-card-hold-failed'),
          isAdvanceExchanceHoldPaymentButton: getTranslationText('common', 'retry-payment'),
          isAdvanceExchanceHoldPaymentTitleDesc: getTranslationText('track', 'deductible-success-hold-failed'),
        })
        return
      } else if (_.get(this, 'props.track.advanceExchangeHoldPaymentStatus', '').includes('Refunded')) {
        this.setState({
          showAdvanceExchangeHoldPaymentModal: true,
          isAdvanceExchanceHoldPaymentTitleText: getTranslationText('track', 'credit-card-hold-failed'),
          isAdvanceExchanceHoldPaymentButton: getTranslationText('common', 'okay'),
          isAdvanceExchanceHoldPaymentTitleDesc: getTranslationText('track', 'retry-hold-payment'),
        })
        return
      } else if (_.get(this, 'props.track.advanceExchangeHoldPaymentStatus', '').includes('Success')) {
        // success modal
        this.setState({
          showAdvanceExchangeHoldPaymentModal: true,
          isAdvanceExchanceHoldPaymentTitleText: getTranslationText('track', 'credit-card-hold-success'),
          isAdvanceExchanceHoldPaymentButton: getTranslationText('common', 'okay'),
          isAdvanceExchanceHoldPaymentTitleDesc: getTranslationText('track', 'credit-card-hold-processed'),
        })
        return
      }
    }

    if (_.get(this, 'props.location.lastpathName.path', '').includes('/request/confirm')) {
      const selectedDevices = _.get(this, 'props.request.selectedOrderDetails.itemList', []).filter((item) => item.isSelected)
      selectedDevices.length > 1 &&
        this.setState({
          showMultipleRequestsInstructionsModal: true,
        })
    }
    window.scrollTo(0, 0)
    this.props.clearPaymentDetails()
    this.updateTrackPage()
  }

  componentWillUnmount() {
    window.rzp1 && window.rzp1.close()
  }

  addressPreviewOrder = async () => {
    let addressConfig = _.get(this, 'props.user.countryAddressConfig.addressConfig', fallbackAddressConfig)
    addressConfig = await sortedAddressConfig(addressConfig)
    addressConfig = await addressConfig.sort((a, b) => {
      return a.addressPreviewOrder - b.addressPreviewOrder
    })

    let array_1 = []
    let isLandmark =
      _.get(this, 'props.track.requestDetails.deliveryAddressDetails', false) &&
      !!_.get(this, 'props.track.requestDetails.deliveryAddressDetails')['PickupLandmark']
    let isUserLandmark =
      _.get(this, 'props.track.requestDetails.deliveryAddressDetails', false) &&
      !!_.get(this, 'props.track.requestDetails.deliveryAddressDetails')['PickupUserLandmark']
    await addressConfig.map((definedConifg) => {
      Object.keys(_.get(this, 'props.track.requestDetails.deliveryAddressDetails', false)).map((addressValue) => {
        if (
          !!_.get(this, 'props.track.requestDetails.deliveryAddressDetails')[addressValue] &&
          !array_1.includes(_.get(this, 'props.track.requestDetails.deliveryAddressDetails')[addressValue])
        ) {
          if (`Pickup${definedConifg['keyName']}` == addressValue) {
            array_1.push(_.get(this, 'props.track.requestDetails.deliveryAddressDetails')[addressValue])
          }
        }
      })
    })
    if (isLandmark && isUserLandmark) {
      array_1.splice(0, 1)
    }
    await this.props.setAddressPreviewDetails({
      key: 'trackDisplayUserAddress',
      value: array_1,
    })
    let csrDeliveryDetail = _.get(this, 'props.track.requestDetails.deliveryAddressDetails', false)
    let array_2 = []
    let isDeliveryLandmark = csrDeliveryDetail && !!csrDeliveryDetail['DeliveryLandmark']
    let isDeliveryUserLandmark = csrDeliveryDetail && !!csrDeliveryDetail['DeliveryUserLandmark']
    if (
      _.includes([1, 11, 17, 23, 31, 57, 58, 52], parseInt(this.props.track.trackDetails.ServiceTypeID)) &&
      !_.isEmpty(this, 'track.requestDetails.deliveryAddressDetails')
    ) {
      addressConfig.map((definedConifg) => {
        Object.keys(csrDeliveryDetail).map((addressValue) => {
          if (!!csrDeliveryDetail[addressValue] && !array_2.includes(csrDeliveryDetail[addressValue])) {
            if (
              `Delivery${definedConifg['keyName']}` == addressValue ||
              `Delivery${definedConifg['keyName']}`.toLowerCase() == addressValue.toLowerCase()
            ) {
              array_2.push(csrDeliveryDetail[addressValue])
            }
          }
        })
      })
      if (isDeliveryLandmark && isDeliveryUserLandmark) {
        array_2.splice(0, 1)
      }
      await this.props.setAddressPreviewDetails({
        key: 'trackDisplayDeliveryAddress',
        value: array_2,
      })
    }
  }

  handleWindowResize = () => {
    this.setState({
      sliderSettings: {
        slidesToShow: Math.ceil(document.body.clientWidth / 450),
        slidesToScroll: Math.ceil(document.body.clientWidth / 450),
      },
    })
    // StepsProgressBar content
    if (document.body.clientWidth < 768) {
      if (this.state.labelPlacement !== 'horizontal') {
        this.setState({
          labelPlacement: 'horizontal',
          direction: 'vertical',
        })
      }
    } else {
      if (this.state.labelPlacement !== 'vertical') {
        this.setState({
          labelPlacement: 'vertical',
          direction: 'horizontal',
        })
      }
    }
  }

  handleTabClick = (e) => {
    this.setState({
      selectedTab: e.target.id,
    })
  }

  handleDateSelect = (e) => {
    let pillId = e.currentTarget.id.replace('datepill-', '')
    let dummyobj = this.state.rescheduleSlots
    dummyobj.map((_data, index) => {
      dummyobj[index][0].isSelected = index === parseInt(pillId)
    })
    var bestAvalSlot = false
    dummyobj[pillId][1].map((data) => {
      if (data.IsActive && !bestAvalSlot) {
        data.isSelected = true
        bestAvalSlot = true
      } else {
        data.isSelected = false
      }
    })
    this.setState({
      rescheduleSlots: dummyobj,
      reschedulableTimes: dummyobj[pillId][1],
    })
  }

  handleTimeSelect = (e) => {
    let pillId = e.currentTarget.id.replace('timepill-', '')
    this.setState({
      reschedulableTimes: this.state.reschedulableTimes.map((pill, index) => {
        return {
          ...pill,
          isSelected: index === parseInt(pillId),
        }
      }),
    })
  }

  updateEstimation = (e) => {
    let Approveflg = e.currentTarget.id === 'approve-invoice-estimation' ? 1 : 0
    this.setState(
      {
        showEstimatedInvoiceModal: false,
      },
      () => {
        this.props.setglobalLoaderStatus(true)
        this.props
          .updateEstimationAsync({
            ConsumerServiceRequestID:
              (this.props.location.query?.id && parseInt(window.atob(this.props.location.query?.id?.toString()))) ||
              this.props.track.trackDetails?.ConsumerProduct?.ConsumerServiceRequestID,
            AuthID: window.Authorization || this.props.user.consumerDetails.data.accessToken.ID,
            IsApproved: Approveflg,
          })
          .then(() => {
            this.props.setglobalLoaderStatus(false)
            this.updateTrackPage()
          })
          .catch(() => {
            this.props.setglobalLoaderStatus(false)
            this.updateTrackPage()
          })
      }
    )
  }

  // @ToDo when Payment Gateways Project goes live
  // setInvoice = async () => {
  //   const [ paymentGatewaysError, paymentGatewaysResponse ] = await safePromise(this.props.getPaymentGateways({
  //     Authorization: this.getAuthorizationAccessToken()
  //   }))
  //   if (paymentGatewaysError) {
  //     return Promise.reject(paymentGatewaysError)
  //   }
  //   const { data: { data: paymentGateways } } = paymentGatewaysResponse
  //   this.setState({ paymentGateways })
  //   const [invoiceError, InvoiceData] = await safePromise(this.props.getInvoiceAsync({
  //     ConsumerServiceRequestID: parseInt(window.atob(this.props.location.query?.id?.toString()))
  // || this.props.track.trackDetails?.ConsumerProduct?.ConsumerServiceRequestID,
  //     AuthID: (window.Authorization || this.props.user.consumerDetails.data.accessToken.ID)
  //   }))
  //   if (invoiceError) {
  //     return Promise.reject(invoiceError)
  //   }
  //   this.setState({
  //     InvoiceData,
  //     BtnStatusLoader : false
  //   })
  //   return Promise.resolve(InvoiceData)
  // }

  setInvoice = async () => {
    return this.props
      .getInvoiceAsync({
        ConsumerServiceRequestID:
          (this.props.location.query?.id && parseInt(window.atob(this.props.location.query?.id?.toString()))) ||
          this.props.track.trackDetails?.ConsumerProduct?.ConsumerServiceRequestID,
        AuthID: window.Authorization || this.props.user.consumerDetails.data.accessToken.ID,
      })
      .then((response) => {
        this.setState({
          InvoiceData: response,
          BtnStatusLoader: false,
        })
      })
  }

  toggleModal = (e, logisticsDetails) => {
    if (e) {
      if (e.currentTarget.id === 'RescheduleReq') {
        if (_.includes([17, 23], this.props.track.trackDetails.ServiceTypeID)) {
          let dropoffLocation = this.props.request.requestModesData.dropOffLocations.filter(
            (obj) => obj.ServiceLocationCode === this.props.track.trackDetails.DropOffCenter.ServiceLocationCode
          )[0]
          this.getReschedulingSlots(dropoffLocation.WorkingHours)
          this.setState({
            showRescheduleModal: true,
            RescheduleModalTitle: getTranslationText('track', 'rescheduleRequest'),
          })
        } else {
          let dte = new Date()
          this.props.setglobalLoaderStatus(true)
          this.props
            .rescheduleSlotsAsync({
              ConsumerServiceRequestID:
                (this.props.location.query?.id && parseInt(window.atob(this.props.location.query?.id?.toString()))) ||
                this.props.track.trackDetails?.ConsumerProduct?.ConsumerServiceRequestID,
              CurrentDate: dte.toLocaleDateString().split('/').reverse().join('/'),
              CurrentTime: dte.toLocaleTimeString(),
              AuthID: window.Authorization || this.props.user.consumerDetails.data.accessToken.ID,
            })
            .then((respSlots) => {
              this.getReschedulingSlots(respSlots)
              this.setState({
                showRescheduleModal: true,
                RescheduleModalTitle: getTranslationText('track', 'rescheduleRequest'),
              })
              this.props.setglobalLoaderStatus(false)
            })
            .catch((e) => {
              console.log(e)
              this.props.setglobalLoaderStatus(false)
            })
        }
      } else if (e.currentTarget.id === 'CancelReq') {
        this.setState({
          showCancelReqModal: true,
          cancelReqInputValue: '',
        })
      } else if (e.currentTarget.id === 'CancelReqClose') {
        this.setState({
          showCancelReqModal: false,
          cancelReqInputValue: '',
        })
      } else if (e.currentTarget.id === 'CancelRescheduleReq') {
        this.setState({
          showRescheduleModal: false,
        })
      } else if (e.currentTarget.id === 'show-treatments') {
        this.props.setglobalLoaderStatus(true)
        this.props
          .treatmentsAsync({ ConsumerServiceRequestID: this.props.request.raisedRequest.ConsumerServiceRequestID }, 'getTreatments')
          .then((response) => {
            this.setState(
              {
                supportTreatments: response.supportTreatments,
                showTreatmentsModal: true,
              },
              () => {
                this.props.setglobalLoaderStatus(false)
              }
            )
          })
          .catch(() => {
            this.props.setglobalLoaderStatus(false)
          })
      } else if (e.currentTarget.id === 'show-swap-options') {
        this.props.setglobalLoaderStatus(true)
        this.props
          .treatmentsAsync({ ConsumerServiceRequestID: this.props.request.raisedRequest.ConsumerServiceRequestID }, 'getSwapSkus')
          .then((response) => {
            this.setState(
              {
                swapOptionsData: response,
                showSwapOptions: true,
              },
              () => {
                this.props.setglobalLoaderStatus(false)
              }
            )
          })
          .catch(() => {
            this.props.setglobalLoaderStatus(false)
          })
      } else if (e.currentTarget.id === 'show-rating-btn') {
        let srcTypeId = parseInt(this.props.track.trackDetails.ServiceTypeID)
        let index = 0
        if (Object.keys(this.props.user.appConfigDetails.Feedbacks).includes(srcTypeId.toString())) {
          index = srcTypeId
        }
        this.setState({
          ratingbtnDisabled: true,
          usrFeedbck: '',
          showRatingModal: true,
          ratingval: 0,
          feedBackOptnsList: this.props.user.appConfigDetails.Feedbacks[index],
        })
      } else if (e.currentTarget.id === 'show-estimated-invoice') {
        this.setInvoice().then(() => {
          this.setState({
            showEstimatedInvoiceModal: true,
          })
        })
      } else if (e.currentTarget.id === 'show-payment' || e.currentTarget.id === 'show-invoice') {
        let id = e.currentTarget.id
        this.setState({ BtnStatusLoader: true }, () => {
          this.setInvoice().then(() => {
            this.setState({
              showInvoiceModal: true,
              paymentIncluded: id === 'show-payment',
            })
          })
        })
      } else if (e.currentTarget.id === 'initiate-BERCase' && this.props.track.trackDetails.berModeList) {
        this.setState({
          showBERFlowModal: true,
          berModeOptions: this.props.track.trackDetails.berModeList.map((data) => {
            return { ...data, isSelected: false }
          }),
        })
      } else if (e.currentTarget.id === 'BER-upload-docs') {
        this.setState({
          showPendingDocsModal: true,
        })
      } else if (e.currentTarget.id === 'upload-proof') {
        this.state.pendingDocsList.map((doc) => {
          if (doc.IsPendingDocument === 1) {
            this.setState({
              showVideoMediaModal: true,
              hideReuploadBtn: true,
            })
          }
        })
        //loader
      } else if (e.currentTarget.id === 'accessories-Option') {
        this.setState({
          accessoriesOptionModal: true,
          berModeOptions: this.props.track.trackDetails.berModeList.map((data) => {
            return { ...data, isSelected: false }
          }),
        })
      } else if (e.currentTarget.id === 'track-Instruction') {
        this.setState(
          {
            showEmailError: false,
            logisticPartnerInstructionModal: true,
            showSchedulePickup: logisticsDetails.ShowSchedulePickup || false,
            showBottomSheet: logisticsDetails.ShowBottomSheet || false,
            isReschedulable: logisticsDetails.isReschedulable || false,
            ShowDocument: logisticsDetails.ShowDocument || false,
            ShowLogisticsInstruction: logisticsDetails.ShowLogisticsInstruction || false,
            emailInput: _.get(this, 'props.user.userDetails.emailID'),
          },
          () => {
            navigator && navigator.clipboard && navigator.clipboard.writeText('')
          }
        )
      } else if (e.currentTarget.id === 'schedule-to-pickup') {
        // check if pickup mode is available
        this.setState(
          {
            showEmailError: false,
            logisticPartnerInstructionModal: false,
          },
          () => {
            let pickMode =
              this.props.request.requestModesData.modes.find((mode) => mode.IsAvailable && [1, 31].includes(mode.ServiceTypeID)) ||
              undefined
            if (pickMode) {
              this.props.setglobalLoaderStatus(true)
              this.props
                .getRequestSlotsAsync({
                  CurrentDate: moment().format('YYYY-MM-DD'),
                  CurrentTime: moment().format('hh:mm:ss'),
                  DeliveryMode: this.props.request.requestModesData.DeliveryMode,
                  Lat: this.props.track.trackDetails.Lat,
                  Lng: this.props.track.trackDetails.Lng,
                  PartnerServiceLocationID: this.props.request.requestModesData.PartnerServiceLocationID,
                  ServiceTypeID: pickMode.ServiceTypeID,
                  ConsumerProductID: this.props.track.trackDetails.ConsumerProduct.ConsumerProductID,
                })
                .then(() => {
                  this.props.setglobalLoaderStatus(false)
                  this.getReschedulingSlots(this.props.request.requestSlotsData)
                  this.setState({
                    schedulePickSlotsModal: true,
                    RescheduleModalTitle: getTranslationText('track', 'schedulePickup'),
                  })
                })
            }
          }
        )
      } else if (e.currentTarget.id === 'show-engineer-details') {
        this.setState({
          showEngineerDetailModal: true,
        })
      } else if (e.currentTarget.id === 'add-billing-details') {
        this.setState({
          showDeliveryPartnerDetails: true,
        })
      } else if (e.currentTarget.id === 'courier-details') {
        this.setState({
          showCourierModal: true,
          showOtherInputBox: false,
        })
      } else if (e.currentTarget.id === 'add-device-imei') {
        this.setState({
          showAddDeviceIMEIModal: true,
          addNewDeviceIMEI: '',
        })
      } else {
        this.closeAllModals()
      }
    } else {
      this.closeAllModals()
    }
  }

  closeAllModals = () => {
    this.setState({
      showRescheduleModal: false,
      showCancelReqModal: false,
      showInvoiceModal: false,
      showRatingModal: false,
      showEstimatedInvoiceModal: false,
      showBERFlowModal: false,
      berModeConfirmation: false,
      showPendingDocsModal: false,
      accessoriesOptionModal: false,
      schedulePickSlotsModal: false,
      showDeliveryPartnerDetails: false,
      showEngineerDetailModal: false,
      showTreatmentsModal: false,
      showSwapOptions: false,
      showCourierModal: false,
      vendorData: {},
      selectedVendor: [],
      showAddDeviceIMEIModal: false,
      showDropOffCenterListModal: false,
      showShippingInstructionsModal: false,
      showPinCodeModal: false,
      showServiceThankYouModal: false,
    })
  }

  handleAddNewDeviceIMEI = (e) => {
    if (e) {
      if (e.target.value) {
        if (e.target.value.length < 16) {
          this.setState({
            deviceImeiInput: e.target.value,
          })
        }
      } else {
        this.setState({
          deviceImeiInput: '',
        })
      }
    }
    // let verdict = validateInputChangeForDevice(
    //   e,
    //   this.state.selectedDeviceDetails,
    //   this.props.devices.productBrandConfig.WarrantyCheckDetails
    // )
    // if (verdict.isValid) {
    //   this.setState({
    //     DeviceDetailsvalues: {
    //       ...this.state.DeviceDetailsvalues,
    //       [e.target.id]: e.target.value,
    //     },
    //   })
    // } else {
    //   this.props.setToaster(verdict.msg, 'error')
    // }
  }

  handleAddNewDeviceIMEISubmit = () => {
    let requestObj = {
      ConsumerServiceRequestID:
        (this.props.location.query?.id && parseInt(window.atob(this.props.location.query?.id?.toString()))) ||
        this.props.track.trackDetails?.ConsumerProduct?.ConsumerServiceRequestID,
      Status: 'Replacement device collected',
      CountryID: this.props.user.selectedCountryData.CountryID,
      replacedDeviceImei: this.state.deviceImeiInput,
    }
    this.props
      .addReplacementDeviceIMEIAsync(requestObj)
      .then(() => {
        this.setState(
          {
            showAddDeviceIMEIModal: false,
          },
          () => {
            this.updateTrackPage()
          }
        )
      })
      .catch(() => {})
      .finally(() => {
        this.setState({
          showAddDeviceIMEIModal: false,
        })
      })
  }

  handleUpdateTreatments = (action) => {
    this.setState({
      BtnStatusLoader: true,
    })
    this.props
      .treatmentsAsync(
        {
          action,
          ConsumerServiceRequestID: this.props.request.raisedRequest.ConsumerServiceRequestID,
        },
        'getSelectedTreatment'
      )
      .then(() => {
        this.setState({
          showTreatmentsModal: false,
          BtnStatusLoader: false,
        })
        this.updateTrackPage()
      })
      .catch(() => {
        this.setState({
          BtnStatusLoader: false,
        })
        this.updateTrackPage()
      })
  }

  handleUpdateSwapOptions = (action) => {
    this.setState(
      {
        showSwapOptions: false,
      },
      () => {
        this.props.setglobalLoaderStatus(true)
      }
    )

    this.props
      .treatmentsAsync(
        {
          PartID: action.PartID,
          ConsumerServiceRequestID: this.props.request.raisedRequest.ConsumerServiceRequestID,
        },
        'getSelectedSku'
      )
      .then(() => {
        this.props.setglobalLoaderStatus(false)
        this.updateTrackPage()
      })
      .catch(() => {
        this.props.setglobalLoaderStatus(false)
        this.updateTrackPage()
      })
  }

  handleupdateRequest = (e) => {
    let AuthID = window.Authorization || this.props.user.consumerDetails.data.accessToken.ID
    let ConsumerServiceRequestID = parseInt(window.atob(this.props.location.query.id.toString()))
    if (e.currentTarget.id === 'updateReq') {
      var ScheduledToTime
      var ScheduledFromTime
      var ScheduledDateTime
      this.state.rescheduleSlots
        .map((data) => {
          return data[0]
        })
        .map((date) => {
          if (date.isSelected === true) {
            ScheduledDateTime = date.date
          }
        })
      this.state.reschedulableTimes.map((time) => {
        if (time.isSelected === true) {
          ScheduledFromTime = time.timeFrom
          ScheduledToTime = time.timeTo
        }
      })
      this.setState(
        {
          showRescheduleModal: false,
          BtnStatusLoader: true,
        },
        () => {
          this.props
            .rescheduleRequestAsync({
              ConsumerServiceRequestID,
              AuthID,
              ScheduledDateTime,
              ScheduledFromTime,
              ScheduledToTime,
            })
            .then(() => {
              this.setState({
                selectedTab: 1,
                BtnStatusLoader: false,
              })
              this.updateTrackPage()
            })
            .catch(() => {
              this.setState({
                selectedTab: 1,
                BtnStatusLoader: false,
              })
              this.updateTrackPage()
            })
        }
      )
    } else {
      this.setState(
        {
          showCancelReqModal: false,
          BtnStatusLoader: true,
        },
        () => {
          this.props.setglobalLoaderStatus(true)
          pushToAnalytics('Cancel_request_submitted', {
            ConsumerServiceRequestID: ConsumerServiceRequestID || '',
            Cancel_Reason: this.state.showCancellationReasons
              ? this.state.cancelReqInputValue || this.state.selectedReasonValue[0].value
              : this.state.cancelReqInputValue || '',
          })
          this.props
            .cancelRequestAsync({
              ConsumerServiceRequestID,
              AuthID,
              remarks: this.state.showCancellationReasons
                ? this.state.cancelReqInputValue || this.state.selectedReasonValue[0].value
                : this.state.cancelReqInputValue,
            })
            .then(() => {
              this.props.setglobalLoaderStatus(false)
              this.updateTrackPage()
              this.setState({
                // showCancelReqModal: false, // to display toaster
                selectedTab: 1,
                BtnStatusLoader: false,
                cancelReqInputValue: '',
                requestCancelledOrConverted: true,
              })
            })
            .catch(() => {
              this.props.setglobalLoaderStatus(false)
              this.updateTrackPage()
              this.setState({
                selectedTab: 1,
                BtnStatusLoader: false,
                cancelReqInputValue: '',
                requestCancelledOrConverted: true,
              })
              this.updateTrackPage()
            })
        }
      )
    }
  }

  navtoRequestModes = async (customeFlag) => {
    if (customeFlag == 'showReassignServiceLocation') {
      this.props.setReassignServiceLocationFlag(true)
    }
    try {
      this.props.setglobalLoaderStatus(true)
      await this.props.getPaginatedDevicesAsync(
        {
          ConsumerID: this.props.track.trackDetails.ConsumerProduct.ConsumerID,
          ConsumerProductID: this.props.track.trackDetails.ConsumerProduct.ConsumerProductID,
        },
        true
      )
      this.props.setglobalLoaderStatus(false)
      if (this.props.devices.deviceDetails.supportedDevices?.length) {
        let selectedDevice = this.props.devices.deviceDetails.supportedDevices[0]
        selectedDevice = {
          ...selectedDevice,
          ConsumerServiceRequestID: this.props.track.trackDetails.ConsumerProduct.ConsumerServiceRequestID,
        }
        this.props.setDeviceDataforRequest(selectedDevice)
        let activeLog = this.props.track.trackDetails.Logs.find((log) => !log.DisplayInfo.Hidden)
        let normalrequestType = activeLog.DisplayInfo.IsNormalRequest || activeLog.DisplayInfo.RaiseRequestAgain
        let reqType = this.props.track.requestDetails.servicetype.Category
        let SoldPlanID = _.get(this, 'props.track.requestDetails.SoldPlanID')
        let SoldPlanCoverageID = _.get(this, 'props.track.trackDetails.SoldPlanCoverageID')
        const selectedPlan = (selectedDevice.PolicyDetails || []).find((product) => product.SoldPlanID === SoldPlanID)
        const PlanID = selectedPlan && selectedPlan.PlanID
        SoldPlanID && this.props.setSelectedDamageType({ SoldPlanID, PlanID, SoldPlanCoverageID })
        let updatedUrl = '/request/modes?claim=' + !normalrequestType + '&csrid=' + this.props.location.query.id + '&type=' + reqType
        let planExists = selectedDevice.PolicyDetails.find(
          (planInfo) =>
            planInfo.PlanType === 'Protect' && planInfo.Status === 1 && planInfo.NoOfRepairsAllowed - planInfo.NoOfRepairsUsed > 0
        )
        if (activeLog.DisplayInfo.RaiseRequestAgain) {
          if (planExists) {
            // re-direct it to plan selection page
            updatedUrl = '/request/plan?type=repair'
          } else {
            updatedUrl = '/request/modes?from=track&type=' + reqType // def to modes
            this.props.setSelectedDamageType({
              PlanType: 'normalflow',
              SoldPlanID: -1,
              isSetProgramatically: true,
            })
          }
        }
        this.props.setRequestFlowSource({
          from: 'track',
          csrid: this.props.location.query.id,
          claim: !normalrequestType,
          recreateReqFlg: activeLog.DisplayInfo.RaiseRequestAgain,
        })
        browserHistory.push(updatedUrl)
      }
    } catch (e) {
      console.log(e)
      this.props.setglobalLoaderStatus(false)
    }
  }

  initiatePayment = () => {
    this.setState(
      {
        isDisabledCheckout: true,
        showInvoiceModal: false,
      },
      () => {
        this.props.setglobalLoaderStatus(true)
        let authId = window.Authorization || this.props.user.consumerDetails.data.accessToken.ID
        let consumerID = parseInt(this.props.user.userDetails.consumerID)
        let csrId = parseInt(window.atob(this.props.location.query.id.toString()))
        let CurrencyID = this.props.user.selectedCountryData.currencies[0].CurrencyID
        let SoldPlanCoverageID = _.get(this, 'props.track.trackDetails.SoldPlanCoverageID')
        let ConsumerProductID = _.get(this, 'props.track.trackDetails.ConsumerProduct.ConsumerProductID')
        const isAdvanceExchangeCardHoldSuccess = _.includes(['FAILED'], _.get(this, 'props.track.trackDetails.CARD_HOLD'))
        if (SoldPlanCoverageID) {
          this.props
            .getAdvancePaymentDetails({
              ConsumerServiceRequestID: csrId,
              SoldPlanCoverageID,
            })
            .then((response) => {
              if ((response.AdvanceChargeApplicable && !response.PaymentStatus) || isAdvanceExchangeCardHoldSuccess) {
                let advancePayReqObj = {
                  SoldPlanCoverageID,
                  ConsumerServiceRequestID: csrId,
                  ConsumerProductID,
                  ConsumerID: consumerID,
                  SoldPlanID: _.get(this, 'props.track.trackDetails.SoldPlanID'),
                  action: 'update',
                  CurrencyID,
                  ServiceTypeID: _.get(this, 'props.track.trackDetails.ServiceTypeID'),
                  PartnerServiceLocationID: _.get(this, 'props.track.requestDetails.PartnerServiceLocationID'),
                  PartnerID: _.get(this, 'props.track.requestDetails.PartnerID'),
                  InitialClaimPPID: _.get(this, 'props.track.trackDetails.PaymentProcessID', undefined),
                  AuthorizeOnlyTxn: isAdvanceExchangeCardHoldSuccess || undefined,
                }
                this.props
                  .advancePayAsync(advancePayReqObj)
                  .then(() => {
                    this.props.setglobalLoaderStatus(false)
                    if (_.get(this, 'props.payment.payableDetails.gateway') === 'instamojo') {
                      window.open(this.props.track.paymentDetails.paymentUrl, '_blank')
                    } else {
                      this.props.storeLastKnowPathName({
                        path: this.props.location.pathname,
                        params: {
                          ...this.props.location.query,
                        },
                      })
                      browserHistory.push({
                        pathname: '/payment',
                        query: {
                          [this.props.location.query.region ? 'region' : '']: this.props.location.query.region,
                        },
                      })
                    }
                  })
                  .catch(() => {
                    this.props.setglobalLoaderStatus(false)
                  })
              } else {
                this.callGetPayPayment(authId, consumerID, CurrencyID, csrId)
              }
            })
        } else {
          this.callGetPayPayment(authId, consumerID, CurrencyID, csrId)
        }
      }
    )
  }

  callGetPayPayment = (authId, consumerID, CurrencyID, csrId) => {
    this.props.setglobalLoaderStatus(true)
    this.props
      .initiatePaymentAsync({
        AuthID: authId,
        paymentObj: {
          CurrencyID: CurrencyID,
          ConsumerServiceRequestID: csrId,
          action: 'update',
          CouponCode: '',
          ConsumerID: consumerID,
          servifyCash: 0,
        },
      })
      .then(() => {
        this.props.setglobalLoaderStatus(false)
        if (this.props.track.paymentDetails.gateway === 'instamojo') {
          window.open(this.props.track.paymentDetails.paymentUrl, '_blank')
        } else {
          this.props.storeLastKnowPathName({
            path: this.props.location.pathname,
            params: {
              ...this.props.location.query,
            },
          })
          browserHistory.push({
            pathname: '/payment',
            query: {
              [this.props.location.query.region ? 'region' : '']: this.props.location.query.region,
            },
          })
        }
      })
      .catch(() => {
        this.props.setglobalLoaderStatus(false)
      })
  }

  selectedBEROptn = (e) => {
    if (e) {
      let id = parseInt(e.currentTarget.id.replace('ber-', ''))
      this.setState(
        {
          berModeOptions: this.state.berModeOptions.map((data, index) => {
            return { ...data, isSelected: index === id }
          }),
          currentBEROptn: 'BERMainModes',
        },
        () => {
          let selectedOptn = this.state.berModeOptions.find((data) => data.isSelected)
          if (['replace', 'refund'].includes(selectedOptn.btnType)) {
            this.setState({
              berBtnTxt: getTranslationText('common', 'confirmProceed'),
            })
          } else if (['repair'].includes(selectedOptn.btnType)) {
            this.setState({
              berBtnTxt: getTranslationText('common', 'confirmProceedPay'),
            })
          } else {
            this.setState({
              berBtnTxt: getTranslationText('common', 'confirm'),
            })
          }
        }
      )
    }
  }

  berModeListBckBtn = () => {
    this.setState({
      berModeConfirmation: false,
      berModeOptions: this.state.berModeOptions.map((data) => {
        return { ...data, isSelected: false }
      }),
    })
  }

  selectBERAccessoriesOptn = (e) => {
    if (e) {
      let id = parseInt(e.currentTarget.id.replace('ber-', ''))
      this.setState({
        berModeOptions: this.state.berModeOptions.map((data, index) => {
          return { ...data, isSelected: index === id }
        }),
        currentBEROptn: 'BERAcessoriesOptn',
        berModeConfirmation: true,
      })
    }
  }

  handleBEROptionCheck = () => {
    let selectedOptn = this.state.berModeOptions.find((data) => data.isSelected)
    if (this.state.berModeConfirmation) {
      this.setState(
        {
          showBERFlowModal: false,
          accessoriesOptionModal: false,
          berModeConfirmation: false,
        },
        () => {
          if (this.state.currentBEROptn === 'BERMainModes') {
            this.props.setglobalLoaderStatus(true)
            this.props
              .berModeSelectionAsync({
                AuthID: window.Authorization || this.props.user.consumerDetails.data.accessToken.ID,
                data: {
                  ConsumerServiceRequestID:
                    (this.props.location.query?.id && parseInt(window.atob(this.props.location.query?.id?.toString()))) ||
                    this.props.track.trackDetails?.ConsumerProduct?.ConsumerServiceRequestID,
                  Status: selectedOptn.NextAction,
                  // completeService:completeService
                },
              })
              .then(() => {
                this.props.setglobalLoaderStatus(false)
                this.updateTrackPage()
              })
              .catch(() => {
                this.props.setglobalLoaderStatus(false)
                this.updateTrackPage()
              })
          } else if (this.state.currentBEROptn === 'BERAcessoriesOptn') {
            this.props.setglobalLoaderStatus(true)
            this.props
              .updateAccessoriesStatusAsync({
                AuthID: window.Authorization || this.props.user.consumerDetails.data.accessToken.ID,
                data: {
                  ConsumerServiceRequestID:
                    (this.props.location.query?.id && parseInt(window.atob(this.props.location.query?.id?.toString()))) ||
                    this.props.track.trackDetails?.ConsumerProduct?.ConsumerServiceRequestID,
                  AccessoriesStatus: selectedOptn.NextAction,
                },
              })
              .then(() => {
                this.props.setglobalLoaderStatus(false)
                this.updateTrackPage()
              })
              .catch(() => {
                this.props.setglobalLoaderStatus(false)
                this.updateTrackPage()
              })
          }
        }
      )
    } else {
      this.setState({
        berModeConfirmation: true,
      })
    }
  }

  /* removeUploadedDoc = (e) => {
    if (e) {
      let id = e.target.id
      let imagesCollection =
      this.state.pendingDocsList[this.state.newImageTypeIndex].consumerServicerequestDocuments.filter(doc => {
        if (id !== doc.id) {
          return doc
        }
      })
      this.setState({
        pendingDocsList:
          this.state.pendingDocsList.map((doc, index) => {
            if (this.state.newImageTypeIndex === index) {
              return {
                ...doc,
                isUploaded: false,
                consumerServicerequestDocuments: imagesCollection
              }
            } else {
              return doc
            }
          })
      })
    }
  }
  */
  handleImageViewModal = () => {
    this.setState({ imageViewModal: true })
  }

  handleImageDocsDel = () => {
    let pendingDocsList = Object.assign([], this.state.pendingDocsList)
    pendingDocsList[0].docDocUrl = ''
    pendingDocsList[0].isUploaded = false
    this.setState({
      imageViewModal: false,
      pendingDocsList,
    })
  }

  uploadPendingDocs = () => {
    let data = []
    this.state.pendingDocsList.map((doc) => {
      if (doc.IsPendingDocument) {
        data.push(doc.docInfo)
      }
    })
    this.setState(
      {
        BtnStatusLoader: true,
      },
      () => {
        this.props.setglobalLoaderStatus(true)
        let uploadPendingDocsPromise = this.props

        if (window.useDocService) {
          uploadPendingDocsPromise = this.props.addCSRDocToDSAsync(data)
        } else {
          uploadPendingDocsPromise = this.props.addUploadedImageToPendingDocsAsync({
            AuthID: window.Authorization || this.props.user.consumerDetails.data.accessToken.ID,
            data,
          })
        }
        uploadPendingDocsPromise
          .then(() => {
            this.props.setglobalLoaderStatus(true)
            this.setState({
              showPendingDocsModal: false,
            })
            this.updateTrackPage()
          })
          .catch(() => {
            this.props.setglobalLoaderStatus(true)
            this.setState({
              BtnStatusLoader: false,
            })
          })
      }
    )
  }

  starRating = (id) => {
    if (id !== this.state.ratingval) {
      this.setState({
        ratingbtnDisabled: false,
        ratingval: id,
      })

      let indexFeedbackOptns = this.state.feedBackOptnsList.map((optnList) => {
        return {
          ...optnList,
          options: optnList.options?.map((data) => {
            return { text: data, isSelected: false }
          }),
        }
      })
      this.setState({
        ratingoptns: {
          ...indexFeedbackOptns[id - 1],
        },
      })
    }
  }

  handlefeedback = () => {
    let userProblems = ''
    this.state.ratingoptns.options.find((pills) => {
      if (pills.isSelected) {
        userProblems += pills.text + ', '
      }
    })
    userProblems = (userProblems.length && userProblems.substring(0, userProblems.length - 2)) || ''
    this.toggleModal()
    pushToAnalytics('Rating_submitted', {
      ConsumerServiceRequestID:
        (this.props.location.query?.id && parseInt(window.atob(this.props.location.query?.id?.toString()))) ||
        this.props.track.trackDetails?.ConsumerProduct?.ConsumerServiceRequestID,
      ratingval: this.state.ratingval,
      userProblems,
      feedback: this.state.usrFeedbck,
    })
    this.props
      .servicefeedbackAsync({
        AuthID: window.Authorization || this.props.user.consumerDetails.data.accessToken.ID,
        ConsumerServiceRequestID:
          (this.props.location.query?.id && parseInt(window.atob(this.props.location.query?.id?.toString()))) ||
          this.props.track.trackDetails?.ConsumerProduct?.ConsumerServiceRequestID,
        ratingval: this.state.ratingval,
        userProblems,
        feedback: this.state.usrFeedbck,
      })
      .then(() => {
        this.updateTrackPage()
        this.setState(
          {
            showRatingModal: false,
          },
          () => {
            if (_.get(this, 'state.ratingoptns.externalFeedback', []).length) {
              this.setState({
                showServiceThankYouModal: true,
                externalFeedback: this.state.ratingoptns.externalFeedback,
              })
            } else {
              browserHistory.replace('/')
            }
          }
        )
      })
      .catch(() => {
        this.updateTrackPage()
      })
  }

  handleRatingInput = (e) => {
    var txt = e.target.value
    if (!plainTextCheck(txt)) {
      e.preventDefault()
    } else {
      this.setState({
        usrFeedbck: txt,
      })
    }
  }

  handlePillSelect = (e) => {
    let pillId = e.currentTarget.id.replace('ratingpill-', '')
    this.setState(
      {
        ratingoptns: {
          ...this.state.ratingoptns,
          options: this.state.ratingoptns.options.map((pill, index) => {
            return {
              ...pill,
              isSelected: index === parseInt(pillId) ? !pill.isSelected : pill.isSelected,
            }
          }),
        },
      },
      () => {
        let userProblems = ''
        this.state.ratingoptns.options.find((pills) => {
          if (pills.isSelected) {
            userProblems += pills.text + ', '
          }
        })
        pushToAnalytics('Rating_like_about_us_options_selected', {
          ConsumerServiceRequestID:
            (this.props.location.query?.id && parseInt(window.atob(this.props.location.query?.id?.toString()))) ||
            this.props.track.trackDetails?.ConsumerProduct?.ConsumerServiceRequestID,
          userProblems,
        })
      }
    )
  }

  handleInstructionSelection = (e) => {
    if (e && this.state.instructionEnabled) {
      this.setState({
        pickUpInstructions: this.state.pickUpInstructions.map((data) => {
          return {
            ...data,
            isSelected: e.currentTarget.id === data.source ? !data.isSelected : data.isSelected,
          }
        }),
      })
    }
  }

  handleEmailInputChange = (e) => {
    if (e) {
      if (e.target.id === 'email-input') {
        this.setState({
          emailInput: regexCheck('onChange', 'emailId', e.target.value) ? e.target.value : this.state.emailInput,
          showEmailError: false,
        })
      } else {
        if (regexCheck('validation', 'emailId', this.state.emailInput)) {
          this.sendLogisticEmail(this.state.emailInput)
        } else {
          this.setState({
            emailInput: '',
            showEmailError: true,
          })
        }
      }
    }
  }

  sendLogisticEmail = (email) => {
    this.setState(
      {
        BtnStatusLoader: true,
      },
      () => {
        this.props
          .sendLogisticEmailAsync({
            ConsumerServiceRequestID:
              (this.props.location.query?.id && parseInt(window.atob(this.props.location.query?.id?.toString()))) ||
              this.props.track.trackDetails?.ConsumerProduct?.ConsumerServiceRequestID,
            EmailID: email,
            AuthID: window.Authorization || this.props.user.consumerDetails.data.accessToken.ID,
          })
          .then(() => {
            this.setState({
              emailSent: true,
              BtnStatusLoader: false,
            })
          })
          .catch(() => {
            this.setState({
              BtnStatusLoader: false,
            })
          })
      }
    )
  }

  handlePickInstructionStatus = () => {
    this.setState({
      instructionEnabled: false,
    })
    this.closeInstructionModal()
  }

  closeInstructionModal = () => {
    this.setState({
      logisticPartnerInstructionModal: false,
      sendDocViaMail: false,
      emailInput: '',
      emailSent: false,
      showEmailError: false,
      pickUpInstructions: this.state.pickUpInstructions.map((data) => {
        if (this.state.instructionEnabled) {
          return {
            ...data,
            isSelected: data.isSelected,
          }
        } else {
          return {
            ...data,
          }
        }
      }),
    })
  }

  handleCancelReqIp = (e) => {
    if (e) {
      if (e.target.value.replace(/[\s]/g, '').length && regexCheck('onChange', 'plainTxt', e.target.value)) {
        this.setState({
          cancelReqInputValue: e.target.value,
        })
      } else {
        this.setState({
          cancelReqInputValue: '',
        })
      }
    }
  }

  handleReasonListSelection = (val) => {
    this.setState({
      selectedReasonValue: val,
    })
  }

  handleSchedulePickUpReq = () => {
    this.props.setglobalLoaderStatus(true)
    this.setState(
      {
        schedulePickSlotsModal: false,
        requestCancelledOrConverted: true,
      },
      () => {
        let dateSlot
        let timeSlot
        this.state.rescheduleSlots
          .map((data) => {
            return data[0]
          })
          .map((date) => {
            if (date.isSelected === true) {
              dateSlot = date.date
            }
          })
        timeSlot = this.state.reschedulableTimes.find((time) => time.isSelected)
        this.props
          .convertDropOffToPickupAsync({
            AuthID: window.Authorization || this.props.user.consumerDetails.data.accessToken.ID,
            data: {
              ConsumerID: this.props.user.userDetails.consumerID,
              ConsumerServiceRequestID: this.props.track.trackDetails.ConsumerProduct.ConsumerServiceRequestID,
              ScheduledDateTime: dateSlot,
              ScheduledFromTime: timeSlot.timeFrom,
              ScheduledToTime: timeSlot.timeTo,
            },
          })
          .then(() => {
            this.updateTrackPage()
          })
          .finally(() => {
            this.setState({
              schedulePickSlotsModal: false,
            })
            this.props.setglobalLoaderStatus(false)
          })
      }
    )
  }

  handleDropOffCenterChange = async () => {
    this.props.setglobalLoaderStatus(true)
    this.props
      .getDropOffCentersAsync({
        ReferenceID: _.get(this, 'props.track.trackDetails.ReferenceID'),
        ZipCode: this.props.track.requestDetails.PinCode
          ? this.props.track.requestDetails.PinCode
          : parseInt(this.props.track.requestDetails.Zipcode),
        Country: this.props.track.requestDetails.CountryCode,
        Lat: this.props.track.requestDetails.Lat,
        Lng: this.props.track.requestDetails.Lng,
        LandMark: this.props.track.requestDetails.Landmark,
        State: this.props.track.requestDetails.Status,
      })
      .then(() => {
        this.props.setglobalLoaderStatus(false)
        const isDropOffLocationAvailable = _.get(this, 'props.track.logisticsVendorDetails.isDropOffLocationAvailable', false)
        if (isDropOffLocationAvailable) {
          this.setState({
            showDropOffCenterListModal: true,
            landmark: _.get(this, 'props.track.requestDetails.Landmark'),
            dropOffLocationsList: _.get(this, 'props.track.logisticsVendorDetails.dropOffLocations'),
          })
        } else {
          const dropOffLocationsURL = _.get(this, 'props.track.logisticsVendorDetails.dropOffLocationsURL')
          window.open(dropOffLocationsURL)
          return
        }
      })
      .catch(() => {
        this.props.setglobalLoaderStatus(false)
      })
    try {
      this.props.setglobalLoaderStatus(true)
      await this.props.getPaginatedDevicesAsync(
        {
          ConsumerID: this.props.track.trackDetails.ConsumerProduct.ConsumerID,
          ConsumerProductID: this.props.track.trackDetails.ConsumerProduct.ConsumerProductID,
        },
        true
      )
      this.props.setglobalLoaderStatus(false)
      if (this.props.devices.deviceDetails.supportedDevices?.length) {
        let deviceInfo = this.props.devices.deviceDetails.supportedDevices[0]
        this.props.setSelectedDevice(deviceInfo)
        this.props.setSelectedMode(this.props.request.requestModesData.modes.filter((mode) => _.includes([17, 23], mode.ServiceTypeID))[0])
        this.props.setUserRequestLocation({
          key: 'userLocation',
          value: {
            landmark: this.props.track.requestDetails.Landmark,
            pincode: this.props.track.requestDetails.PinCode && this.props.track.requestDetails.Zipcode,
            latitude: parseFloat(this.props.track.requestDetails.Lat),
            longitude: parseFloat(this.props.track.requestDetails.Lng),
          },
        })
      }
    } catch (e) {
      console.log(e)
      this.props.setglobalLoaderStatus(false)
    }
  }

  deliveryPartnerOnChange = (e) => {
    let id = e.currentTarget.id || ''
    let deliveryPartnerDetails = this.state.deliveryPartnerDetails
    if (id === 'partner-name') {
      deliveryPartnerDetails = {
        ...deliveryPartnerDetails,
        partnerName: e.currentTarget.value,
      }
    } else if (id === 'billing-number') {
      deliveryPartnerDetails = {
        ...deliveryPartnerDetails,
        billingNumber: regexCheck('onChange', 'userName', e.currentTarget.value)
          ? _.get(e, 'currentTarget.value', '').toUpperCase()
          : deliveryPartnerDetails.billingNumber,
      }
    }
    this.setState({
      deliveryPartnerDetails,
    })
  }

  handleCourierVendorSelection = (selectedCourier) => {
    if (selectedCourier && selectedCourier.length) {
      if (selectedCourier[0].VendorName === 'Other') {
        this.setState({
          showOtherInputBox: true,
          selectedVendor: selectedCourier,
        })
      } else {
        this.setState({
          showOtherInputBox: false,
          vendorName: selectedCourier[0].VendorName,
          selectedVendor: selectedCourier,
        })
      }
    } else {
      this.setState({
        selectedVendor: [],
        showOtherInputBox: false,
        vendorName: '',
      })
    }
  }

  courierChangeHandler = (e) => {
    // let maximumCourierAmount = this.props.track.maximumCourierAmount.MaxSelfRefund || 500
    if (e.target.id === 'amount') {
      //   if (regexCheck('onChange', 'numeric', e.target.value, 5) &&
      //     e.target.value <= maximumCourierAmount) {
      //     this.setState({
      //       vendorData: {
      //         ...this.state.vendorData,
      //         [e.target.id]: e.target.value
      //       }
      //     })
      //   }
    } else {
      this.setState({
        vendorData: {
          ...this.state.vendorData,
          [e.target.id]: e.target.value,
        },
      })
    }
  }

  handleCourierSubmit = () => {
    let { vendorData } = this.state
    this.setState(
      {
        BtnStatusLoader: true,
      },
      () => {
        let obj = {
          ConsumerServiceRequestID: this.props.track.trackDetails.ConsumerProduct.ConsumerServiceRequestID,
          WaybillNumber: this.state.vendorData.waybill,
          VendorID: _.get(this.state, 'selectedVendor[0].VendorID'),
          // CourierAmount: parseInt(this.state.vendorData.amount) || undefined,
          VendorName:
            _.get(this.state, 'selectedVendor[0].VendorName') === 'Other'
              ? vendorData.vendorName
              : _.get(this.state, 'selectedVendor[0].VendorName'),
        }
        this.props.setglobalLoaderStatus(true)
        this.props
          .updateCourierDetailsAsync(obj)
          .then(() => {
            this.props.setglobalLoaderStatus(false)
            this.setState(
              {
                showCourierModal: false,
              },
              () => {
                this.updateTrackPage()
              }
            )
          })
          .catch((err) => {
            console.log('err', err)
            this.setState({
              showCourierModal: false,
              BtnStatusLoader: false,
              selectedVendor: [],
              showOtherInputBox: false,
              vendorData: [],
            })
            this.props.setglobalLoaderStatus(false)
          })
      }
    )
  }

  viewUploadVideo = () => {
    this.closeModalHandler('showVideoMediaModal')
    this.state.pendingDocsList.map((doc) => {
      if (doc.IsPendingDocument === 1) {
        this.setState({
          showVideoMediaModal: true,
          hideReuploadBtn: false,
        })
      }
    })
  }

  closeModalHandler = (stateModelName) => {
    if (stateModelName === 'showAdvanceExchangeHoldPaymentModal') {
      this.props.setTrackHoldPaymentStatus(undefined)
      this.updateTrackPage()
    }

    let newState = {
      [stateModelName]: false,
    }
    if (stateModelName === 'showVideoMediaModal') {
      newState.hideReuploadBtn = false
    }
    this.setState(newState)
  }

  handleDocsImgUpload = (e) => {
    if (e) {
      let id = parseInt(e.currentTarget.id)
      let fileInfo = e.currentTarget.files[0]
      // let currentDoc = this.state.pendingDocsList.find(doc => (id === doc.PendingDocID))
      canUpload(e, { allowedTypes: ['image', 'pdf'] })
        .then(() => {
          let uniqueId = uuid.v1()
          this.props
            .getSignedUrlAsync(
              {
                AuthID: window.Authorization || this.props.user.consumerDetails.data.accessToken.ID,
                data: {
                  Type: fileInfo.type,
                  Key: uniqueId,
                },
              },
              fileInfo
            )
            .then((response) => {
              this.setState({
                pendingDocsList: this.state.pendingDocsList.map((doc) => {
                  if (id === doc.PendingDocID) {
                    return {
                      ...doc,
                      isUploaded: true,
                      docDocUrl: response,
                      docInfo: {
                        ConsumerServiceRequestID:
                          (this.props.location.query?.id && parseInt(window.atob(this.props.location.query?.id?.toString()))) ||
                          this.props.track.trackDetails?.ConsumerProduct?.ConsumerServiceRequestID,
                        Type: 'Image',
                        id: `${uniqueId}.${fileInfo.type.split('/')[1]}`,
                        FilePath: `${uniqueId}.${fileInfo.type.split('/')[1]}`,
                        Tag: doc.document.DocumentType,
                        DocumentID: doc.DocumentID,
                        PendingDocID: doc.PendingDocID,
                      },
                    }
                  } else {
                    return doc
                  }
                }),
              })
            })
        })
        .catch((error) => this.props.setToaster(error.message, 'error'))
    }
  }

  handleDocsImgUploadToDS = async (e) => {
    let file = e.target.files[0]
    if (file) {
      this.props.setglobalLoaderStatus(true)
      return canUpload(e, { allowedTypes: ['image'] })
        .then(async () => {
          let id = parseInt(e?.target?.id)
          let payload = getDocServicePayload(file, 'ConsumerServiceRequestDocument')
          const token = _.get(this, 'props.user.consumerDetails.data.accessToken.ID')

          //upload To doc service and get uploadURL
          const docServiceResponse = await postRequestAsync(
            'internal/document/upload',
            payload,
            { Authorization: token, module: docServiceModuleName },
            window.docServiceUri
          )

          await this.setState({
            pendingDocsList: this.state.pendingDocsList.map((doc) => {
              if (id === doc.PendingDocID) {
                return {
                  ...doc,
                  isUploaded: true,
                  docDocUrl: docServiceResponse.url,
                  docInfo: {
                    ConsumerServiceRequestID:
                      (this.props.location.query?.id && parseInt(window.atob(this.props.location.query?.id?.toString()))) ||
                      this.props.track.trackDetails?.ConsumerProduct?.ConsumerServiceRequestID,
                    Type: 'Image',
                    id: `${docServiceResponse.docServiceUUID}`,
                    FilePath: docServiceResponse.uploadURL,
                    FileName: docServiceResponse.docServiceUUID,
                    Tag: doc.document.DocumentType,
                    DocumentID: doc.DocumentID,
                    PendingDocID: doc.PendingDocID,
                  },
                }
              } else {
                return doc
              }
            }),
          })
          this.props.setglobalLoaderStatus(false)
        })
        .catch((e) => {
          this.props.setglobalLoaderStatus(false)
          this.props.setToaster(e?.message, 'error')
        })
    }
  }

  uploadImageFunc = async (file) => {
    let clientRefId = uuid.v4()
    let data = new FormData()
    data.append('docType', 'ConsumerServiceRequestDocument')
    data.append('clientRefId', 'FECONS-' + clientRefId)
    data.append('entity-type', 'ConsumerID')
    data.append('entity-id', _.get(this, 'props.user.userDetails.consumerID', ''))
    data.append('module', docServiceModuleName)
    data.append('fileName', file.name)
    data.append('mimeType', file.type)
    data.append('sizeInBytes', file.size)
    await this.props.addLargeFileToDSAsync(data, file)
    await this.props.uploadLargeFileToS3(this.props.user.docServiceLargeFileDetails.url, file)
    await this.props.acknowledgeUploadDocToDSAsync(this.props.user.docServiceLargeFileDetails.docServiceUUID)
    await this.props.getUploadDocToDSAsync(this.props.user.docServiceLargeFileDetails.docServiceUUID)
    this.props.setglobalLoaderStatus(false)
    return Promise.resolve('')
  }

  handleUploadPendingDocsToDS = async (e) => {
    this.closeModalHandler('showVideoMediaModal')
    let file = e.target.files[0]
    if (file) {
      this.props.setglobalLoaderStatus(true)
      return canUpload(e, { allowedTypes: ['video'] })
        .then(async () => {
          let payload = getDocServicePayload(file, 'ConsumerServiceRequestDocument')
          const token = _.get(this, 'props.user.consumerDetails.data.accessToken.ID')

          //upload To doc service and get uploadURL
          const docServiceResponse = await postRequestAsync(
            'internal/document/upload',
            payload,
            { Authorization: token, module: docServiceModuleName },
            window.docServiceUri
          )

          //get the pending doc details
          const pendingDocList = await this.props.pendingDocsListAsync({
            data: {
              ConsumerServiceRequestID:
                (this.props.location.query?.id && parseInt(window.atob(this.props.location.query?.id?.toString()))) ||
                this.props.track.trackDetails?.ConsumerProduct?.ConsumerServiceRequestID,
              productID: parseInt(this.props.track.trackDetails.ConsumerProduct.ConsumerProductID),
            },
          })

          //map the uploaded file to the pending doc
          let requestObj = {
            requestDocs: [
              {
                ConsumerProductID: _.get(this, 'props.track.trackDetails.ConsumerProduct.ConsumerProductID'),
                ConsumerServiceRequestID:
                  (this.props.location.query?.id && parseInt(window.atob(this.props.location.query?.id?.toString()))) ||
                  this.props.track.trackDetails?.ConsumerProduct?.ConsumerServiceRequestID,
                DocumentID: pendingDocList[0].DocumentID,
                PendingDocID: pendingDocList[0].PendingDocID,
                FilePath: docServiceResponse.uploadURL,
              },
            ],
          }
          await this.props.addCSRDocToDSAsync(requestObj)
          this.props.setglobalLoaderStatus(false)
          this.props.setToaster('Video Uploaded Successfully!', 'success')
          this.updateTrackPage()
        })
        .catch((err) => {
          console.log(err)
          this.props.setglobalLoaderStatus(false)
          this.props.setToaster(getTranslationText('error', 'somethingWentWrong'), 'error')
        })
    }
  }

  handleVideoUpload = async (e) => {
    this.closeModalHandler('showVideoMediaModal')
    let file = e.target.files[0]
    if (file) {
      const returnAfterS3Upload = e.returnAfterS3Upload
      this.props.setglobalLoaderStatus(true)
      return canUpload(e, { allowedTypes: ['video'] })
        .then(() => {
          this.props.setglobalLoaderStatus(true)
          return this.uploadImageFunc(file)
            .then(() => {
              if (returnAfterS3Upload) {
                return {
                  success: true,
                }
              } else {
                this.props.setglobalLoaderStatus(true)
                this.setState(
                  {
                    hideReuploadBtn: true,
                  },
                  () => {
                    this.props
                      .pendingDocsListAsync({
                        data: {
                          ConsumerServiceRequestID:
                            (this.props.location.query?.id && parseInt(window.atob(this.props.location.query?.id?.toString()))) ||
                            this.props.track.trackDetails?.ConsumerProduct?.ConsumerServiceRequestID,
                          productID: parseInt(this.props.track.trackDetails.ConsumerProduct.ConsumerProductID),
                        },
                      })
                      .then((response) => {
                        let reqObject = {
                          requestDocs: [
                            {
                              ConsumerProductID: _.get(this, 'props.track.trackDetails.ConsumerProduct.ConsumerProductID'),
                              ConsumerServiceRequestID:
                                (this.props.location.query?.id && parseInt(window.atob(this.props.location.query?.id?.toString()))) ||
                                this.props.track.trackDetails?.ConsumerProduct?.ConsumerServiceRequestID,
                              DocumentID: response[0].DocumentID,
                              PendingDocID: response[0].PendingDocID,
                              Type: _.get(this, 'props.user.docServiceLargeFileDetails.Type'),
                              FileName: _.get(this, 'props.user.docServiceLargeFileDetails.fileName'),
                              FilePath: _.get(this, 'props.user.docServiceLargeFileDetails.uploadURL'),
                            },
                          ],
                        }
                        this.props.addCSRDocToDSAsync(reqObject).then(() => {
                          this.updateTrackPage()
                        })
                      })
                      .then(() => {
                        this.props.setglobalLoaderStatus(true)
                      })
                  }
                )
              }
            })
            .finally(() => {
              this.props.setglobalLoaderStatus(false)
              this.props.setToaster('Video Uploaded Successfully!', 'success')
            })
        })
        .catch((error) => this.props.setToaster(error.message, 'error'))
        .finally(() => {
          this.props.setglobalLoaderStatus(false)
        })
    } else {
      this.props.setglobalLoaderStatus(false)
    }
  }

  copyTrackingNumber = (value = '') => {
    navigator && navigator.clipboard && navigator.clipboard.writeText(value)
  }

  handleShippingInstruction = () => {
    this.setState({
      showDropOffCenterListModal: false,
      showShippingInstructionsModal: true,
    })
  }

  handleDropOffCenterList = () => {
    this.setState({
      showDropOffCenterListModal: true,
      showShippingInstructionsModal: false,
    })
  }

  onChangeTypeahead = (address) => {
    this.setState({
      landmark: address,
      locationObtained: false,
      dropOffLocationsList: [],
    })
  }

  handleTypeaheadSelect = (value) => {
    this.setState(
      {
        locationObtained: true,
        addressComponentLoader: true,
      },
      () => {
        this.props
          .getGeoLocationAsync(value)
          .then(() => {
            console.log('this.props.request.userLocation', this.props.request.userLocation)
            if (_.isEmpty(this.props.request.userLocation.pincode)) {
              this.setState({
                showPinCodeModal: true,
                showShippingInstructionsModal: false,
                showDropOffCenterListModal: false,
              })
            } else {
              this.fetchDropOffCenter()
            }
          })
          .finally(() => {
            this.setState({
              addressComponentLoader: false,
            })
          })
      }
    )
  }

  addressPincodeHandler = (e) => {
    if (e) {
      this.setState({
        dropoffPinCode: e.target.value,
      })
    }
  }

  addressPincodeSubmit = async () => {
    this.setState({
      showPinCodeModal: false,
      showShippingInstructionsModal: false,
      showDropOffCenterListModal: false,
    })
    await this.props.setUserRequestLocation({
      key: 'userLocation',
      value: {
        ...this.props.request.userLocation,
        pincode: this.state.dropoffPinCode,
      },
    })
    await this.fetchDropOffCenter()
  }

  fetchDropOffCenter = () => {
    this.setState({ landmark: this.props.request.userLocation.landmark }, () => {
      this.props.setglobalLoaderStatus(true)
      this.props
        .getDropOffCentersAsync({
          ReferenceID: _.get(this, 'props.track.trackDetails.ReferenceID'),
          ZipCode: this.props.request.userLocation.pincode,
          Country: this.props.track.requestDetails.CountryCode,
          Lat: this.props.request.userLocation.latitude,
          Lng: this.props.request.userLocation.longitude,
          LandMark: this.props.request.userLocation.landmark,
          State: this.props.request.userLocation.state,
        })
        .then(() => {
          this.props.setglobalLoaderStatus(false)
          const isDropOffLocationAvailable = _.get(this, 'props.track.logisticsVendorDetails.isDropOffLocationAvailable', false)
          if (isDropOffLocationAvailable) {
            this.setState({
              showPinCodeModal: false,
              showDropOffCenterListModal: true,
              landmark: _.get(this, 'props.request.userLocation.landmark', ''),
              dropOffLocationsList: _.get(this, 'props.track.logisticsVendorDetails.dropOffLocations', ''),
            })
          } else {
            this.setState(
              {
                showPinCodeModal: false,
                showDropOffCenterListModal: false,
              },
              () => {
                const dropOffLocationsURL = _.get(this, 'props.track.logisticsVendorDetails.dropOffLocationsURL')
                window.open(dropOffLocationsURL)
                return
              }
            )
          }
        })
        .catch(() => {
          this.props.setglobalLoaderStatus(false)
        })
    })
  }

  externalLinksRedirection = (externalLinksRedirection) => {
    this.setState({ showServiceThankYouModal: false }, () => {
      browserHistory.replace('/')
      window.open(externalLinksRedirection, '_blank')
    })
  }

  render() {
    const locale = _.get(this, 'props.user.selectedCountryData.countryConfig.Locale.DEFAULT', 'en')
    const currencyCode = _.get(this, 'props.user.selectedCountryData.CurrencyCode')
    const showReplacementOptions = _.get(this, 'props.location.pathname', '').includes('choose-replacement')
    // let ReplacementServiceTypeID = _.includes([46], this.props.track.requestDetails.ServiceTypeID)
    return (
      <div className='row mb48'>
        <div className='col-sm-12'>
          {showReplacementOptions ? (
            <ReplacementOptions {...this.props} />
          ) : (
            !_.isEmpty(this.state.trackDetails) && (
              <TrackComponent
                {...this.props}
                groupDetails={this.state.groupDetails}
                logs={this.state.logsReversed}
                listDownServiceCenters={this.state.listDownServiceCenters}
                ReferenceID={this.state.ReferenceID}
                ConsumerProduct={this.state.ConsumerProduct}
                selectedTab={parseInt(this.state.selectedTab)}
                handleTabClick={this.handleTabClick}
                requestDetails={this.state.requestDetails}
                pills={this.state.reschedulableDates}
                handlePillSelect={this.handleDateSelect}
                loaderState={this.state.trackRouteLoader}
                trackDetails={this.props.track.trackDetails}
                BtnStatusLoader={this.state.BtnStatusLoader}
                navtoRequestModes={this.navtoRequestModes}
                ModalCall={this.toggleModal}
                handleDropOffCenterChange={this.handleDropOffCenterChange}
                requestCancelledOrConverted={this.state.requestCancelledOrConverted}
                labelPlacement={this.state.labelPlacement}
                direction={this.state.direction}
                sliderSettings={this.state.sliderSettings}
                initiatePayment={this.initiatePayment}
                {...this.prop}
              />
            )
          )}

          {this.state.showServiceThankYouModal ? (
            <ServiceThankYouModal
              {...this.props}
              toggleModal={this.toggleModal}
              externalLinksRedirection={this.externalLinksRedirection}
              externalFeedback={this.state.externalFeedback}
              showServiceThankYouModal={this.state.showServiceThankYouModal}
            />
          ) : (
            ''
          )}

          {this.state.showAdvanceExchangeHoldPaymentModal ? (
            <Modal className='sfy-modal empty-fields-modal' showModal={this.props.showAdvanceExchangeHoldPaymentModal}>
              <CustomIconComponent
                className={'close-icon ' + (window.isDirectionRTL ? 'appear-left text-left ' : 'appear-right text-right')}
                onClick={() => this.closeModalHandler('showAdvanceExchangeHoldPaymentModal')}
              />
              <div className='text-center'>
                <img
                  className='hold-modal-icon-width'
                  src={_.get(this, 'props.track.advanceExchangeHoldPaymentStatus', '').includes('Success') ? successImg : informationImg}
                />
                <div className='boldFontStyle fontLarge mt20 mb10'>{this.state.isAdvanceExchanceHoldPaymentTitleText}</div>
                <div className='bookFontStyle fontSmall mt10'>
                  <span>{this.state.isAdvanceExchanceHoldPaymentTitleDesc}</span>
                </div>
                {!this.props.payment.holdAmount?.isHoldAmount && (
                  <div className='bookFontStyle fontSmall mt10'>Payment failure reason: {this.props.payment.holdAmount.Remarks}</div>
                )}
                <MediumButton
                  className='boldFontStyle fontMedium mt20'
                  onClick={() => this.closeModalHandler('showAdvanceExchangeHoldPaymentModal')}
                  buttonText={this.state.isAdvanceExchanceHoldPaymentButton}
                />
              </div>
            </Modal>
          ) : (
            ''
          )}

          {this.state.showVideoMediaModal ? (
            <Modal className='sfy-modal successfully-added-device-content' showModal={true || this.state.showVideoMediaModal}>
              <div className='offset-11 col-1'>
                <CustomIconComponent className='close-icon text-right' onClick={() => this.closeModalHandler('showVideoMediaModal')} />
              </div>
              <Heading title={getTranslationText('request', 'uploadDocuments')} />
              <ModalUploadDocuments
                {...this.props}
                handleInvoiceUpload={this.handleVideoUpload}
                uploadVideoFile={this.state.uploadVideoFile}
                viewUploadVideo={this.viewUploadVideo}
              />
              <div className='text-center'>
                {this.props.user.docServiceLargeFileDetails.fileUrl && this.props.user.docServiceLargeFileDetails.fileUrl.length ? (
                  <React.Fragment>
                    <MediumButton
                      buttonText='Re upload Video'
                      onClick={() => document.getElementById('video-media-upload').click()}
                      className='button-small button-ghost upload-video-file-btn  mt8'
                    />
                    <input onChange={this.handleVideoUpload} type='file' id='video-media-upload' style={{ display: 'none' }} />
                  </React.Fragment>
                ) : null}
              </div>
            </Modal>
          ) : (
            ''
          )}
          <RequestModal
            show={this.state.showRescheduleModal || this.state.schedulePickSlotsModal}
            toggleModal={this.toggleModal}
            BtnStatusLoader={this.state.BtnStatusLoader}
            handleDateSelect={this.handleDateSelect}
            rescheduleSlots={this.state.rescheduleSlots}
            handleTimeSelect={this.handleTimeSelect}
            reschedulableTimes={this.state.reschedulableTimes}
            handleSchedulePickUpReq={this.handleSchedulePickUpReq}
            RescheduleModalTitle={this.state.RescheduleModalTitle}
            schedulePickSlotsModal={this.state.schedulePickSlotsModal}
            handleupdateRequest={this.handleupdateRequest}
            {...this.props}
          />
          <CancelReqModal
            show={this.state.showCancelReqModal}
            toggleModal={this.toggleModal}
            handleupdateRequest={this.handleupdateRequest}
            handleCancelReqIp={this.handleCancelReqIp}
            cancelReqInputValue={this.state.cancelReqInputValue}
            BtnStatusLoader={this.state.BtnStatusLoader}
            handleReasonListSelection={this.handleReasonListSelection}
            selectedReasonValue={this.state.selectedReasonValue}
            requestCancelMasterList={_.get(this, 'props.track.requestCancelMasterList.Reasons')}
            showCancellationReasons={this.state.showCancellationReasons}
            {...this.props}
          />
          {this.state.InvoiceData && this.state.showEstimatedInvoiceModal ? (
            <EstimatedInvoiceModal
              show={this.state.showEstimatedInvoiceModal}
              toggleModal={this.toggleModal}
              data={this.state.InvoiceData}
              updateEstimation={this.updateEstimation}
              locale={locale}
              currencyCode={currencyCode}
            />
          ) : (
            ''
          )}
          {this.state.InvoiceData && this.state.showInvoiceModal ? (
            <InvoiceModal
              show={this.state.showInvoiceModal}
              toggleModal={this.toggleModal}
              isDisabledCheckout={this.state.isDisabledCheckout}
              paymentIncluded={this.state.paymentIncluded}
              initiatePayment={this.initiatePayment}
              data={this.state.InvoiceData}
              locale={locale}
              currencyCode={currencyCode}
            />
          ) : (
            ''
          )}
          {/* @ToDo uncomment below when payment gateway is being implemented */}
          {/* {this.state.InvoiceData && this.state.showInvoiceModal
            ? <InvoiceModal
              show={this.state.showInvoiceModal}
              toggleModal={this.toggleModal}
              isDisabledCheckout={this.state.isDisabledCheckout}
              paymentGateways={this.state.paymentGateways}
              paymentIncluded={this.state.paymentIncluded}
              initiatePayment={this.initiatePayment}
              setFormRef={this.setFormRef}
              formAction={this.state.formAction}
              formFields={this.state.formFields}
              onPaymentGatewaySelection={this.onPaymentGatewaySelection}
              data={this.state.InvoiceData}
            />
            : ''
          } */}
          {this.state.showBERFlowModal && this.state.berModeOptions.length ? (
            <BEROptionsModal
              show={this.state.showBERFlowModal}
              toggleModal={this.toggleModal}
              berModeConfirmation={this.state.berModeConfirmation}
              berModeListBckBtn={this.berModeListBckBtn}
              berModeOptions={this.state.berModeOptions}
              selectedBEROptn={this.selectedBEROptn}
              berBtnTxt={this.state.berBtnTxt}
              handleBEROptionCheck={this.handleBEROptionCheck}
              {...this.props}
            />
          ) : (
            ''
          )}
          {this.state.showPendingDocsModal ? (
            <PendingDocsModal
              show={this.state.showPendingDocsModal}
              toggleModal={this.toggleModal}
              BtnStatusLoader={this.state.BtnStatusLoader}
              deviceDocsList={this.state.deviceDocsList}
              pendingDocsList={this.state.pendingDocsList}
              handleDocsImgUpload={this.handleDocsImgUpload}
              uploadPendingDocs={this.uploadPendingDocs}
              imgViewerHandler={this.handleImageViewModal}
              handleDocsImgUploadToDS={this.handleDocsImgUploadToDS}
            />
          ) : (
            ''
          )}
          {this.state.imageViewModal ? (
            <ImageViewerModal
              show={this.state.imageViewModal}
              closeIconHandler={() => {
                this.setState({ imageViewModal: false })
              }}
              removeBtnStatus
              deleteDocsHandler={this.handleImageDocsDel}
              imagePath={this.state.pendingDocsList.map((d) => d.docDocUrl)[0]}
            />
          ) : (
            ''
          )}
          {this.state.accessoriesOptionModal && this.state.berModeOptions.length ? (
            <BERAccessoriesOptnModal
              show={this.state.accessoriesOptionModal}
              toggleModal={this.toggleModal}
              berModeOptions={this.state.berModeOptions}
              selectBERAccessoriesOptn={this.selectBERAccessoriesOptn}
              handleBEROptionCheck={this.handleBEROptionCheck}
              {...this.props}
            />
          ) : (
            ''
          )}
          {this.state.showRatingModal ? (
            <RatingModal
              show={this.state.showRatingModal}
              setGlobalLoaderStatus={this.props.setglobalLoaderStatus}
              toggleModal={this.toggleModal}
              handleRatingInput={this.handleRatingInput}
              ratingbtnDisabled={this.state.ratingbtnDisabled}
              ratingoptns={this.state.ratingoptns}
              ratingval={this.state.ratingval}
              usrFeedbck={this.state.usrFeedbck}
              handlePillSelect={this.handlePillSelect}
              starRating={this.starRating}
              handlefeedback={this.handlefeedback}
            />
          ) : (
            ''
          )}
          {this.state.logisticPartnerInstructionModal && this.props.track.trackDetails.logisticsDetails ? (
            <InstructionModal
              show={this.state.logisticPartnerInstructionModal}
              BtnStatusLoader={this.state.BtnStatusLoader}
              toggleModal={this.closeInstructionModal}
              sendDocViaMail={this.state.sendDocViaMail}
              logisticsDetails={this.props.track.trackDetails.logisticsDetails}
              pickUpInstructions={this.state.pickUpInstructions}
              emailInput={this.state.emailInput}
              handleEmailInputChange={this.handleEmailInputChange}
              emailSent={this.state.emailSent}
              sendDocumentsTOEmail={() => {
                this.setState({ sendDocViaMail: true })
              }}
              handleInstructionSelection={this.handleInstructionSelection}
              showEmailError={this.state.showEmailError}
              instructionEnabled={this.state.instructionEnabled}
              handlePickInstructionStatus={this.handlePickInstructionStatus}
              requestMode={_.includes([17, 23], this.props.track.trackDetails.ServiceTypeID) ? 'Dropoff' : 'Pickup'}
              handleDropOffCenterChange={this.handleDropOffCenterChange}
              showSchedulePickup={this.state.showSchedulePickup}
              showBottomSheet={this.state.showBottomSheet}
              isReschedulable={this.state.isReschedulable}
              ShowDocument={this.state.ShowDocument}
              ShowLogisticsInstruction={this.state.ShowLogisticsInstruction}
              ModalCall={this.toggleModal}
              copyTrackingNumber={this.copyTrackingNumber}
              jobsheetS3Url={this.state.jobsheetS3Url}
              {...this.props}
            />
          ) : (
            ''
          )}
          {this.state.showTreatmentsModal && (
            <TreatmentsModal
              show={this.state.showTreatmentsModal}
              BtnStatusLoader={this.state.BtnStatusLoader}
              toggleModal={this.toggleModal}
              handleUpdateTreatments={this.handleUpdateTreatments}
              treatmentData={this.state.supportTreatments}
            />
          )}
          {this.state.showSwapOptions && (
            <SwapOptionsModal
              show={this.state.showSwapOptions}
              title={getTranslationText('request', 'selectDevices')}
              toggleModal={this.toggleModal}
              handleRequest={(action) => {
                this.handleUpdateSwapOptions(action)
              }}
              data={this.state.swapOptionsData || []}
            />
          )}
          {this.state.showMultipleRequestsInstructionsModal && (
            <Modal showModal={this.state.showMultipleRequestsInstructionsModal}>
              <h5>{getTranslationText('common', 'note')}:</h5>
              <div>
                <p>{getTranslationText('track', 'packOnePackage')}</p>
                <p>{getTranslationText('track', 'placeLabelPackage')}</p>
              </div>
              <MediumButton
                id='showMultipleRequestsInstructions'
                buttonText={getTranslationText('request', 'okay')}
                onClick={() => {
                  this.setState({ showMultipleRequestsInstructionsModal: false })
                }}
              />
            </Modal>
          )}
          {this.state.showDeliveryPartnerDetails && (
            <DeliveryPartnerPopUp
              show={this.state.showDeliveryPartnerDetails}
              toggleModal={this.toggleModal}
              deliveryPartnerDetails={this.state.deliveryPartnerDetails}
              deliveryPartnerOnChange={this.deliveryPartnerOnChange}
            />
          )}
          {this.state.showEngineerDetailModal && _.get(this, 'state.trackDetails.Engineer') ? (
            <EngineerDetailsModal
              show={this.state.showEngineerDetailModal}
              {...this.props}
              engineerDetails={this.state.trackDetails.Engineer}
              toggleModal={this.toggleModal}
            />
          ) : (
            ''
          )}
          {this.state.showCourierModal && (
            <CourierModal
              show
              toggleModal={this.toggleModal}
              handleSelection={this.handleCourierVendorSelection}
              courierChangeHandler={this.courierChangeHandler}
              BtnStatusLoader={this.state.BtnStatusLoader}
              vendorData={this.state.vendorData || {}}
              maximumCourierAmount={this.props.track.maximumCourierAmount}
              handleCourierSubmit={this.handleCourierSubmit}
              selectedVendor={this.state.selectedVendor}
              showOtherInputBox={this.state.showOtherInputBox}
              vendorList={this.state.courierVendorList}
            />
          )}

          {this.state.showAddDeviceIMEIModal && (
            <AddDeviceIMEIModal
              show
              toggleModal={this.toggleModal}
              deviceImeiInput={this.state.deviceImeiInput || ''}
              handleAddNewDeviceIMEI={this.handleAddNewDeviceIMEI}
              handleAddNewDeviceIMEISubmit={this.handleAddNewDeviceIMEISubmit}
            />
          )}

          {this.state.showDropOffCenterListModal && (
            <DropOffCenterList
              show
              toggleModal={this.toggleModal}
              handleShippingInstruction={this.handleShippingInstruction}
              onChangeTypeahead={this.onChangeTypeahead}
              handleTypeaheadSelect={this.handleTypeaheadSelect}
              dropOffLocationsList={this.state.dropOffLocationsList}
              landmark={this.state.landmark}
              {...this.props}
            />
          )}

          {this.state.showShippingInstructionsModal && (
            <DropOffShippingInstructions
              show
              toggleModal={this.toggleModal}
              handleDropOffCenterList={this.handleDropOffCenterList}
              {...this.props}
            />
          )}

          {this.state.showPinCodeModal && (
            <AddressPincodeModal
              show
              dropoffPinCode={this.state.dropoffPinCode}
              toggleModal={this.toggleModal}
              addressPincodeSubmit={this.addressPincodeSubmit}
              addressPincodeHandler={this.addressPincodeHandler}
              {...this.props}
            />
          )}
        </div>
      </div>
    )
  }

  static propTypes = {
    // getPaymentGateways: PropTypes.func.isRequired,
    // getCCAvenuePaymentDetails: PropTypes.func.isRequired,
    track: PropTypes.object.isRequired,
    request: PropTypes.object.isRequired,
    devices: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    setSelectedDevice: PropTypes.func.isRequired,
    getRequestModesAsync: PropTypes.func.isRequired,
    getRequestSlotsAsync: PropTypes.func.isRequired,
    convertDropOffToPickupAsync: PropTypes.func.isRequired,
    getTrackDetailsAsync: PropTypes.func.isRequired,
    getRequestDetailsAsync: PropTypes.func.isRequired,
    getServicetypeDetailsAsync: PropTypes.func.isRequired,
    cancelRequestAsync: PropTypes.func.isRequired,
    rescheduleSlotsAsync: PropTypes.func.isRequired,
    rescheduleRequestAsync: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    getInvoiceAsync: PropTypes.func.isRequired,
    updateEstimationAsync: PropTypes.func.isRequired,
    servicefeedbackAsync: PropTypes.func.isRequired,
    setDeviceDataforRequest: PropTypes.func.isRequired,
    getNotificationsAsync: PropTypes.func.isRequired,
    initiatePaymentAsync: PropTypes.func.isRequired,
    setToaster: PropTypes.func.isRequired,
    fetchBERModeListAysnc: PropTypes.func.isRequired,
    berModeSelectionAsync: PropTypes.func.isRequired,
    getProductDocsAsync: PropTypes.func.isRequired,
    updateAccessoriesStatusAsync: PropTypes.func.isRequired,
    pendingDocsListAsync: PropTypes.func.isRequired,
    getSignedUrlAsync: PropTypes.func.isRequired,
    addUploadedImageToPendingDocsAsync: PropTypes.func.isRequired,
    addUploadedImageToModalPendingDocsAsync: PropTypes.func.isRequired,
    getLogisticsDetailsAsync: PropTypes.func.isRequired,
    addToVisitedUrl: PropTypes.func.isRequired,
    setRequestFlowSource: PropTypes.func.isRequired,
    sendLogisticEmailAsync: PropTypes.func.isRequired,
    setSelectedMode: PropTypes.func.isRequired,
    setUserRequestLocation: PropTypes.func.isRequired,
    setglobalLoaderStatus: PropTypes.func.isRequired,
    storeLastKnowPathName: PropTypes.func.isRequired,
    setSelectedDamageType: PropTypes.func.isRequired,
    treatmentsAsync: PropTypes.func.isRequired,
    setClaimDetails: PropTypes.func.isRequired,
    fetchCourierPartners: PropTypes.func,
    updateCourierDetailsAsync: PropTypes.func,
    getAdvancePaymentDetails: PropTypes.func,
    advancePayAsync: PropTypes.func,
    clearPaymentDetails: PropTypes.func,
  }
}

export default Track
