import { hashKey, getTzOffset } from '../utils/AESEncrypt.js'
import { BrandSpecificConfig } from '../utils/constants.js'
import packageJson from '../../package.json'

window.isAuthorized = true

const init = () => {
  const requestInterceptor = (config) => {
    if (!config.headers.customHeader) {
      return config
    }
    const LanguageCode = localStorage.getItem('languageCode') || 'en'
    return {
      ...config,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        TimeZone: getTzOffset(),
        dr9se2q: hashKey({ tzU: new Date().getTime(), appC: '4T2E5RTVX5' }),
        co1cx2: window.iv,
        App: BrandSpecificConfig().header,
        ...config.headers,
        LanguageCode,
        CountryID: window.CountryID || config.headers.CountryID,
        CountryCode: window.CountryCode || config.headers.CountryCode,
        Authorization: window.Authorization || config.headers.Authorization,
        appVersion: packageJson.version,
      },
    }
  }

  const requestInterceptorErrorHandler = (error) => Promise.reject(error)

  window.axios.interceptors.request.use(requestInterceptor, requestInterceptorErrorHandler)

  const responseInterceptor = (response) => response

  const responseInterceptorErrorHandler = (error) => {
    const { response: { status: isSessionOver } = {} } = error
    if (window.isAuthorized && isSessionOver) {
      window.isAuthorized = false
    }
    return Promise.reject(error)
  }

  window.axios.interceptors.response.use(responseInterceptor, responseInterceptorErrorHandler)
}

export default {
  init,
}
