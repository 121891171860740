export const setAutocomplete = (reference, handleOnSelect, country, fields = []) => {
  const autocomplete = new window.google.maps.places.Autocomplete(reference, {
    fields: ['address_components', 'formatted_address', 'geometry', ...fields],
    types: [],
  })
  autocomplete.setComponentRestrictions({
    country,
  })
  autocomplete.addListener('place_changed', async () => {
    const address = await autocomplete.getPlace()
    handleOnSelect(address)
  })
  return autocomplete
}

export const getAddress = (requestObject) => {
  return new Promise((resolve, reject) => {
    new window.google.maps.Geocoder().geocode(requestObject, (results, status) => {
      if (status === 'OK') {
        resolve(results[0])
      } else {
        reject(status)
      }
    })
  })
}

export const getCurrentPosition = () => {
  return new Promise((resolve, reject) => {
    window.navigator.geolocation.getCurrentPosition(resolve, reject)
  })
}
