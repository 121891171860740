import SubscriptionComponent from './Subscription'
import DetailsComponent from './Details'
import ReviewComponent from './Review'
import SuccessComponent from './Success'

const SubscriptionReview = {
  path: 'subscription/review',
  component: ReviewComponent,
}

const SubscriptionSuccess = {
  path: 'subscription/success',
  component: SuccessComponent,
}
// Sync route definition
const SubscriptionDetails = {
  path: 'subscription/:id',
  component: DetailsComponent,
}

const Subscription = {
  path: 'subscription',
  component: SubscriptionComponent,
}

// export const Subscription = {
// path: 'subscription',
// component: SubscriptionComponent,
// indexRoute: { onEnter: (nextState, replace) => replace('/subscription/HPCAREGAMING') },
// childRoutes: [
//   { path: 'success', component: SuccessComponent },
//   { path: 'review', component: ReviewComponent },
//   { path: ':id', component: DetailsComponent },
// ],
// }

export { Subscription, SubscriptionDetails, SubscriptionReview, SubscriptionSuccess }
