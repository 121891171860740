import React from 'react'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'
import './PurchaseHistory.scss'
import { RequestTypeSelection } from '../../../components/UIComponents'
import { OrderListComponent } from './PurchaseHistorySubComponents'
import _ from 'lodash'
import { requestTypesList } from '../../../utils/constants'
// import { BrandSpecificConfig, requestTypesList } from '../../../utils/constants.js'

class PurchaseHistoryComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedOrder: {},
      showRequestOptionPopUp: false,
      preSelectedOrderId: this.props.location.query.orderId,
    }
  }

  componentWillMount() {
    this.props.setglobalLoaderStatus(true)
    let ConsumerID = this.props.user.userDetails.consumerID
    this.props
      .getPaginatedDevicesAsync({
        ConsumerID,
        pagination: {
          pageNo: 1,
          itemsPerPage: 5,
        },
      })
      .then(() => {
        if (this.state.preSelectedOrderId) {
          let selectedOrder = _.get(this, 'props.devices.deviceDetails.ordersList', []).find(
            (order) => order.OrderNo === this.state.preSelectedOrderId
          )
          if (selectedOrder) {
            this.setState({
              selectedOrder: {
                ...selectedOrder,
                itemList: selectedOrder.itemList.map((item) => ({
                  ...item,
                  isSelected: true,
                })),
              },
              showRequestOptionPopUp: true,
            })
          }
        }
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        this.props.setglobalLoaderStatus(false)
      })
  }

  handleSelectedItemAction = (cpId, order, isTrack = false) => {
    // let ordersList = _.get(this, 'props.devices.deviceDetails.ordersList')
    this.setState(
      {
        selectedOrder: {
          ...order,
          itemList: order.itemList.map((item) => ({
            ...item,
            isSelected: parseInt(cpId) === parseInt(item.ConsumerProductID),
          })),
        },
        preSelectedOrderId: undefined,
        showRequestOptionPopUp: !isTrack,
      },
      () => {
        if (isTrack) {
          let selectedItem = this.state.selectedOrder.itemList.find((item) => item.isSelected)
          this.props.setSelectedDevice(selectedItem)
          browserHistory.push('/track?id=' + window.btoa(selectedItem.ConsumerServiceRequestID.toString()))
        }
      }
    )
  }

  handleRequest = (requestType) => {
    let selectedItem = this.state.selectedOrder.itemList.find((item) => item.isSelected)
    let reqInfo = requestTypesList().find((req) => req.type === requestType)
    let path = reqInfo && reqInfo.path.concat('&from=myorders')
    switch (requestType) {
      case 'install':
      case 'service':
      case 'repair':
      case 'claim':
        // set device as selected
        this.props.setSelectedDevice(selectedItem)
        // let hasPlan = selectedItem.PolicyDetails.find((planInfo) =>
        //   (planInfo.PlanType === 'Protect' && planInfo.Status === 1 &&
        //   ((planInfo.NoOfRepairsAllowed - planInfo.NoOfRepairsUsed) > 0)))
        // let path = '/request/select-device'
        // let path = reqInfo.path.split('?')
        // if (hasPlan) {
        //   path = '/request/plan'
        // } else {
        //   let planObject = { PlanType: 'normalflow', SoldPlanID: -1, isSetProgramatically: true }
        //   this.props.setSelectedDamageType(planObject)
        // }
        break
      case 'refund':
      case 'replacement':
        this.props.setSelectedOrder(this.state.selectedOrder)
        break
      case 'estimate':
        // this.props.setSelectedDevice(selectedItem)
        this.props.setSeviceEstimatorDevice({
          cpId: selectedItem.ConsumerProductID,
          from: 'myorders',
        })
        // let cpid = selectedItem.ConsumerProduct
        path = reqInfo && reqInfo.path
        // path = reqInfo && reqInfo.path.concat('?from=myorders&cpId=' + cpid)
        break
    }
    this.closePopUpHandler()
    path && browserHistory.push(path)
  }

  closePopUpHandler = () => {
    this.setState({
      showRequestOptionPopUp: false,
    })
  }

  render() {
    let servicesOptionsKeys = this.props.user.appConfigDetails
    if (!_.isEmpty(this.state.selectedOrder)) {
      servicesOptionsKeys = {
        // show selected option of order
        ...servicesOptionsKeys,
        showRepairOptions: servicesOptionsKeys.showRepairOptions && !servicesOptionsKeys.ShowRaiseClaim,
        ShowRaiseClaim: servicesOptionsKeys.ShowRaiseClaim && !servicesOptionsKeys.showNormalRepairOptions,
        showServiceEstimator: false,
        showRefundOption: this.state.selectedOrder.showRefundOption,
        showReplacementOption: this.state.selectedOrder.showReplacementOption,
      }
      if (this.state.preSelectedOrderId) {
        servicesOptionsKeys = {
          ...servicesOptionsKeys,
          showRepairOptions: false,
        }
      } else {
        // show req option of selected item
        let selectedItem = this.state.selectedOrder.itemList.find((item) => item.isSelected)
        let isRepairSupp = _.get(selectedItem, 'ProductSubcategory.SupportedModes', []).includes('repair')
        servicesOptionsKeys = {
          ...servicesOptionsKeys,
          ShowRaiseClaim: isRepairSupp && servicesOptionsKeys.ShowRaiseClaim,
          showRepairOptions: isRepairSupp && servicesOptionsKeys.showRepairOptions,
          showRefundOption: selectedItem.showRefundOption,
          showReplacementOption: selectedItem.showReplacementOption,
        }
      }
    }
    let headingContent
    if (this.state.showRequestOptionPopUp) {
      headingContent = `<div class='mt8'>
        <span>Order Number:</span>
        <span class='mediumFontStyle'>${this.state.selectedOrder.OrderNo}</span>
      </div>`
    }
    return (
      <div className='purchase-history-section-wrapper mt8 mb48'>
        {_.get(this, 'props.devices.deviceDetails.ordersList', []).length > 0 && (
          <OrderListComponent {...this.props} handleSelectedItemAction={this.handleSelectedItemAction} />
        )}
        {this.state.showRequestOptionPopUp && (
          <RequestTypeSelection
            servicesOptionsKeys={servicesOptionsKeys}
            configurableHandler
            headingContent={headingContent}
            handleRequest={this.handleRequest}
            toggle={this.closePopUpHandler}
          />
        )}
      </div>
    )
  }
  static propTypes = {
    user: PropTypes.object.isRequired,
    setglobalLoaderStatus: PropTypes.func.isRequired,
    setSelectedDevice: PropTypes.func.isRequired,
    setSelectedDamageType: PropTypes.func,
    setSelectedOrder: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    setSeviceEstimatorDevice: PropTypes.func,
  }
}

export default PurchaseHistoryComponent
