// import browserHistory from 'react-router/lib/browserHistory'

import { addToVisitedUrl } from './request'
// ------------------------------------
// Constants
// ------------------------------------
export const LOCATION_CHANGE = 'LOCATION_CHANGE'
export const LAST_PATH_NAME = 'LAST_PATH_NAME'
// ------------------------------------
// Actions
// ------------------------------------
export function locationChange(location = '/') {
  return {
    type: LOCATION_CHANGE,
    payload: location,
  }
}

export const storeLastKnowPathName = (payload) => {
  return {
    type: LAST_PATH_NAME,
    payload,
  }
}

const generatedUrlForStack = (queryKeys, queryObj) => {
  let localKeysArray = ''
  queryKeys.forEach((key, index) => {
    localKeysArray += (index === 0 ? '' : '&') + key + '=' + queryObj[key]
  })
  return localKeysArray
}

// ------------------------------------
// Specialized Action Creator
// ------------------------------------
export const updateLocation = ({ dispatch, getState }) => {
  return (nextLocation) => {
    if (/request/.test(nextLocation.pathname)) {
      // console.log('state: ', getState())
      let urlList = getState().request.urlList || []
      let query = Object.keys(nextLocation.query).sort()
      let newUrl = nextLocation.pathname + '?' + generatedUrlForStack(query, nextLocation.query)
      if (!urlList.includes(newUrl)) {
        dispatch(addToVisitedUrl(newUrl))
      }
    }
    return dispatch(locationChange(nextLocation))
  }
}

// ------------------------------------
// Reducer
// ------------------------------------
// const initialState = browserHistory.getCurrentLocation()

const ACTION_HANDLERS = {
  [LAST_PATH_NAME]: (state, action) => ({ ...state, lastpathName: action.payload }),
  [LOCATION_CHANGE]: (state, action) => ({ ...state, ...action.payload }),
}

const initialState = {}
export default function locationReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
  // return action.type === LOCATION_CHANGE
  //   ? action.payload
  //   : state
}
