import moment from 'moment'
/**
 * Returns the currency in locale format
 * @param {String} locale locale string e.g 'en-IN' or 'de-DE' or 'tr-TR'
 * @param {String} currencyCode Code of the required currency e.g 'INR' or 'EUR' or 'TRY'
 * @param {Number} amount value which needs to be formatted
 * @returns {String} locale currency value
 */
export const getLocaleCurrency = (locale, currencyCode, amount, bNoCurrencyCode) => {
  let floatAmount = parseFloat(amount)
  let options = {
    style: 'currency',
    currency: currencyCode,
  }
  if (bNoCurrencyCode) return new Intl.NumberFormat(locale, { minimumFractionDigits: 2 }).format(floatAmount)
  return new Intl.NumberFormat(locale, options).format(floatAmount)
}

/**
 * Returns date in locale format
 * @param {String} format required format e.g 'MM/DD/YYYY' or 'DD MMM YYYY'
 * @param {Date | String} date date which needs to be formatted. can be Date object or iso string.
 * @returns {String} locale date string
 */
export const getLocaleDate = (format = 'DD MMM YYYY', date) => {
  return moment(date).format(format)
}

/**
 * Returns time string in locale format
 * @param {Date} time the date object to be foramtted
 * @param {String} locale locale string e.g 'en-IN' or 'de-DE' or 'tr-TR'
 * @param {Object} options extra information for formatting refer @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleTimeString}
 */
export const getLocaleTime = (time, locale = 'en-IN', options = {}) => {
  return time.toLocaleTimeString(locale, { hour: '2-digit', minute: '2-digit', ...options })
}
