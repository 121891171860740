import React from 'react'
import './../GuestRoute.scss'

export const CarePlus = () => {
  const planBenfits = [
    {
      title: 'Unlimited Repairs',
      text: `Breathe easy with unlimited repairs including batteries and same day $29 cracked screen repairs at 700+ Samsung authorized locations.`,
      img: `/${appName}/images/mycare/lostTheft/1.svg`,
      url: '',
    },
    {
      title: 'Next Business Day Replacements',
      text: `Don’t worry. We’ll replace your device as soon as the next business day and help set it up.`,
      img: `/${appName}/images/mycare/section3/2.svg`,
      url: '',
    },
    {
      title: '24/7 Expert Support',
      text: `Get immediate answers from a Samsung Care+ expert 24/7 via phone or online for device setup, content transfers, and connecting with other devices.`,
      img: `/${appName}/images/mycare/lostTheft/5.svg`,
      url: '',
    },
    {
      title: 'Samsung Genuine Parts and Certified Technicians',
      text: `Rest assured your device will be repaired by a Samsung Certified Technician using Samsung Genuine Parts.`,
      img: `/${appName}/images/mycare/section3/4.svg`,
      url: '',
    },
  ]

  const section2 = [
    { header: 'Tier 1', cost: ['$49', '$49', '$39', '$49', '$39', '$39'] },
    { header: 'Tier 2', cost: ['$99', '$79', '$99', '$99', '$99', '$99'] },
    { header: 'Tier 3', cost: ['$169', '$149', '$149', '$149', '$149', '$149'] },
    { header: 'Tier 4', cost: ['$259', '$199', '$199', '$199', '$199', '$199'] },
  ]
  const section3 = [{ header: 'All Tier', cost: ['$0', '$29', '$29', '$99'] }]

  const section5 = [
    { header: 'Tier 1', cost: ['$3', '$3', '$3'] },
    { header: 'Tier 2', cost: ['$5', '$5', '$5'] },
    { header: 'Tier 3', cost: ['$10', '$8', '$8'] },
    { header: 'Tier 4', cost: ['$13', '$11', '$11'] },
  ]

  const section1mobile = {
    tableHeader: ['Tier 1', 'Tier 2', 'Tier 3', 'Tier 4'],
    tableData: [
      {
        header: { img: `/${appName}/images/mycare/products/mobile.png`, title: 'Phones' },
        data: ['$3', '$5', '$10', '$13'],
      },
      {
        header: { img: `/${appName}/images/mycare/products/tablet.png`, title: 'Tables' },
        data: ['$3', '$5', '$8', '$11'],
      },
      {
        header: { img: `/${appName}/images/mycare/products/watch.png`, title: 'Watches' },
        data: ['$3', '$5', '$8', '$11'],
      },
    ],
  }

  const section2mobile = {
    tableHeader: ['Tier 1', 'Tier 2', 'Tier 3', 'Tier 4'],
    tableData: [
      {
        header: { img: `/${appName}/images/mycare/products/mobile.png`, title: 'Phones' },
        data: ['$49', '$99', '$169', '$259'],
      },
      {
        header: { img: `/${appName}/images/mycare/products/tablet.png`, title: 'Tables' },
        data: ['$49', '$79', '$149', '$199'],
      },
      {
        header: { img: `/${appName}/images/mycare/products/watch.png`, title: 'Watches' },
        data: ['$39', '$99', '$149', '$199'],
      },
      {
        header: { img: `/${appName}/images/mycare/products/pc.png`, title: `PC's` },
        data: ['$49', '$99', '$149', '$199'],
      },
      {
        header: { img: `/${appName}/images/mycare/products/earbud.png`, title: 'Earbuds' },
        data: ['$39', '$99', '$149', '$199'],
      },
      {
        header: { img: `/${appName}/images/mycare/products/rings.png`, title: 'Rings' },
        data: ['$39', '$99', '$149', '$199'],
      },
    ],
  }

  const section3mobile = {
    tableData: [
      {
        header: { title: 'Mechanical Breakdown' },
        data: ['$0'],
      },
      {
        header: { title: 'Repair or replacement (Watch only)' },
        data: ['$29'],
      },
      {
        header: { title: 'Cracked Screen Repair' },
        data: ['$29'],
      },
      {
        header: { title: 'ADH Repair or Replacement' },
        data: ['$99'],
      },
    ],
  }

  return (
    <div className='empty-route-container1 background-white'>
      <div className='row home-components1'>
        <div className='col-sm-12 col-lg-12 mb20 ml8'>
          <h1>Samsung Care+</h1>
          <div className='fontExtraLarge'>Powerful Protection for your Galaxy life covers Drops, Spills, and Mechanical Breakdowns</div>
        </div>
        {planBenfits.map((item, index) => (
          <div key={index} className='col-sm-12 col-lg-4'>
            <div className='plan-benefit-section1'>
              <div className='plan-benefit-1'>
                <h4 className='boldFontStyle'>{item.title}</h4>
                <img src={item.img} />
              </div>
              <p className='fontLarge'>{item.text}</p>
            </div>
          </div>
        ))}
      </div>
      <p
        className='text-center mt28 fontLarge'
        dangerouslySetInnerHTML={{
          __html: `Device tiers may change over time. Please check <a href='https://samsung.servify.tech/us' target='_blank'>
          samsung.servify.tech/us</a> for applicable fees, and charges.`,
        }}
      />
      <div className='row home-components1 mt48 text-center'>
        <div className='col-sm-12 col-lg-12 mt48'>
          <h1>Samsung Care+ Monthly Recurring Charge</h1>
          <h4 className='mtb14'>36-month term</h4>
        </div>
        <div className='flex-table-section desktop'>
          <div className='gaps'>
            <div className='table-header'>Device</div>
            <div className='table-container'>
              <div className='table-details-center flex boldFontStyle align-left'>
                <img src={`/${appName}/images/mycare/products/mobile.png`} />
                Phones
              </div>
              <br />
              <div className='table-details-center boldFontStyle align-left'>
                <img src={`/${appName}/images/mycare/products/tablet.png`} />
                Tablets
              </div>
              <br />
              <div className='table-details-center boldFontStyle align-left'>
                <img src={`/${appName}/images/mycare/products/watch.png`} />
                Watches
              </div>
            </div>
          </div>
          {section5.map((value, index) => (
            <div key={index} className='gaps'>
              <div className='table-header'>{value.header}</div>
              <div className='table-container'>
                {value.cost.map((v, i) => (
                  <>
                    <div className='table-details-center boldFontStyle' key={i}>
                      {v}
                    </div>
                    {value.cost.length == i + 1 ? '' : <br />}
                  </>
                ))}
              </div>
            </div>
          ))}
        </div>

        <div className='mobile'>
          <div className='table-header-section'>
            {section1mobile.tableHeader?.map((v, i) => (
              <div key={i} className='table-header-title'>
                {v}
              </div>
            ))}
          </div>
          {section1mobile.tableData?.map((v, i) => (
            <div key={i} className='flex-table-section-mobile'>
              <div className='table-details-center boldFontStyle'>
                <img src={v.header.img} />
                {v.header.title}
              </div>
              <div className='table-data'>
                {v.data.map((v, i) => (
                  <div key={i} className='table-details-center font20 boldFontStyle'>
                    {v}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className='row home-components1 mt48 text-center'>
        <div className='col-sm-12 col-lg-12 mt48'>
          <h1>Samsung Care+ Pay Up Front</h1>
          <h4 className='mtb14'>24-month term</h4>
        </div>
        <div className='flex-table-section desktop'>
          <div className='gaps'>
            <div className='table-header'>Device</div>
            <div className='table-container'>
              <div className='table-details-center flex fontLarge boldFontStyle align-left'>
                <img src={`/${appName}/images/mycare/products/mobile.png`} />
                Phones
              </div>
              <br />
              <div className='table-details-center fontLarge boldFontStyle align-left'>
                <img src={`/${appName}/images/mycare/products/tablet.png`} />
                Tablets
              </div>
              <br />
              <div className='table-details-center fontLarge boldFontStyle align-left'>
                <img src={`/${appName}/images/mycare/products/pc.png`} />
                PC's
              </div>
              <br />
              <div className='table-details-center fontLarge boldFontStyle align-left'>
                <img src={`/${appName}/images/mycare/products/watch.png`} />
                Watches
              </div>
              <br />
              <div className='table-details-center fontLarge boldFontStyle align-left'>
                <img src={`/${appName}/images/mycare/products/earbud.png`} />
                Earbuds
              </div>
              <br />
              <div className='table-details-center fontLarge boldFontStyle align-left'>
                <img src={`/${appName}/images/mycare/products/rings.png`} />
                Rings
              </div>
            </div>
          </div>
          {section2.map((value, index) => (
            <div key={index} className='gaps'>
              <div className='table-header'>{value.header}</div>
              <div className='table-container'>
                {value.cost.map((v, i) => (
                  <>
                    <div className='table-details-center fontLarge boldFontStyle' key={i}>
                      {v}
                    </div>
                    {value.cost.length == i + 1 ? '' : <br />}
                  </>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className='mobile'>
          <div className='table-header-section'>
            {section2mobile.tableHeader?.map((v, i) => (
              <div key={i} className='table-header-title'>
                {v}
              </div>
            ))}
          </div>
          {section2mobile.tableData?.map((v, i) => (
            <div key={i} className='flex-table-section-mobile'>
              <div className='table-details-center boldFontStyle'>
                <img src={v.header.img} />
                {v.header.title}
              </div>
              <div className='table-data'>
                {v.data.map((v, i) => (
                  <div key={i} className='table-details-center font20 boldFontStyle'>
                    {v}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className='row home-components1 mt48 text-center'>
        <div className='col-sm-12 col-lg-12 mt48 mb28'>
          <h1>Samsung Care+ Service Fees</h1>
        </div>
        <div className='flex-table-section desktop'>
          <div className='gaps'>
            <div className='table-header width20rem'>Service Type</div>
            <div className='table-container width20rem'>
              <div className='table-details-center fontLarge boldFontStyle'>Mechanical Breakdowns</div>
              <br />
              <div className='table-details-center fontLarge boldFontStyle'>Watches/Buds/Rings Repair</div>
              <br />
              <div className='table-details-center fontLarge boldFontStyle'>Cracked Screen Repair</div>
              <br />
              <div className='table-details-center fontLarge boldFontStyle'>ADH Repair or Replacement</div>
            </div>
          </div>
          {section3.map((value, index) => (
            <div key={index} className='gaps'>
              <div className='table-header'>{value.header}</div>
              <div className='table-container'>
                {value.cost.map((v, i) => (
                  <>
                    <div className='table-details-center fontLarge boldFontStyle' key={i}>
                      {v}
                    </div>
                    {value.cost.length == i + 1 ? '' : <br />}
                  </>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className='mobile'>
          <div className='mobile'>
            {section3mobile.tableData?.map((v, i) => (
              <div key={i} className='flex-table-section-mobile'>
                <div className='table-details-center boldFontStyle'>{v.header.title}</div>
                <div className='table-data'>
                  {v.data.map((v, i) => (
                    <div key={i} className='table-details-center font20 boldFontStyle'>
                      {v}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export const CarePlusTnc = () => {
  const tnc = [
    {
      title: 'What is covered:',
      conditions: [
        `Accidental damage from handling (ADH) - protection begins on the date of enrollment.`,
        `Mechanical and electrical failure due to defects in materials or workmanship or normal wear and tear (malfunction) - protection begins after the manufacturer's warranty
      expires.`,
        `24/7 Expert Support – protection begins on the date of enrollment.`,
      ],
      subtext: '',
    },

    {
      title: 'Claim limits:',
      conditions: [`Unlimited ADH claims.`, `Unlimited malfunction claims.`, `Maximum value of $2,500 per claim.`],
      subtext: '',
    },

    { title: 'Eligible devices:', conditions: [`Phones, Tablets, Watches, Buds, Rings, and PC’s`], subtext: `` },

    {
      title: 'Service options you can trust:',
      conditions: [
        `Walk-in repair based on location and subject to parts, technician, and location availability.`,
        `Advance exchange - Next day device replacement.`,
        `Mail-in Repair.`,
      ],
      subtext: '',
    },

    {
      title: 'Cancellation policy:',
      conditions: [`You may cancel your optional coverage at any time and receive a refund of your unearned premium.`],
      subtext: ``,
    },

    {
      title: 'Service fees:',
      conditions: [`All applicable taxes and surcharges are extra.`],
      subtext: ``,
    },
  ]

  const section2 = [
    {
      title: 'Enroll',
      text: `<p>Visit a Samsung store or go to <a href='https://samsungcareplus.com/' target='_blank'>samsungcareplus.com</a></p>`,
      img: `/${appName}/images/mycare/section3/6.svg`,
      url: '',
    },
    {
      title: 'Submit a claim',
      text: `<p>If your device is damaged or not working, visit <a href='https://samsung.servify.tech/us' target='_blank'>samsung.servify.tech/us</a> or call (833) 690-0918. 24 hours a day, 7 days a week. <br/> Claims must be submitted within 30 days of the incident.</p>`,
      img: `/${appName}/images/mycare/section2/3.svg`,
      url: '',
    },
    {
      title: 'Contact 24/7 Expert Support',
      text: `<p>From set-up to troubleshooting, visit <a href='https://samsung.servify.tech/us' target='_blank'>samsung.servify.tech/us</a> or call (833) 690-0918.</p>`,
      img: `/${appName}/images/mycare/section3/3.svg`,
      url: '',
    },
  ]
  return (
    <>
      <div className='empty-route-container1'>
        <div className='row home-components1'>
          <div className='col-sm-12 col-lg-12 mb20'>
            <h1>How does it work?</h1>
          </div>
          {section2.map((item, index) => (
            <div key={index} className='col-sm-12 col-lg-4'>
              <div className='box-holder1 section3'>
                <img src={item.img} />
                <h4 className='boldFontStyle'>{item.title}</h4>
                <span className='fontLarge' dangerouslySetInnerHTML={{ __html: item.text }} />
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className='empty-route-container1 background-white'>
        <div className='row flex home-components1'>
          <div className='col-sm-12 col-lg-12'>
            <h1 className='mb28'>Plan Coverage Summary</h1>
            {tnc.map((item, index) => (
              <div key={index} className='terms-listing'>
                <div className='boldFontStyle fontLarge mb15'>{item.title}</div>
                <ul>
                  {item.conditions.map((value, index) => (
                    <li key={index} className='bookFontStyle fontLarge'>
                      {value}
                    </li>
                  ))}
                </ul>
                <div className='bookFontStyle fontLarge'>{item.subtext}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}
