import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { browserHistory } from 'react-router'
import { Modal, Dropdown } from 'sfy-react'
import { getRequestAsync, postRequestAsync } from '../../api/genericAPIs'
import { MediumButton } from '../../components/UIComponents'
import lookup from '../../utils/ipinfo'
import { setglobalLoaderStatus, setToaster } from '../../store/toastsAndLoaders'
import { setAppConfig, setCountries, setSelectedCountryData } from '../../store/user'
import { BrandSpecificConfig } from '../../utils/constants'
import { getTranslationText } from '../../utils/languageTranslation'
import { setEnrollmentPlanList } from '../../store/subscription'
// import Details from './Details'
// import Review from './Review'
// import Success from './Success'

// const routes = (
//   <Router history={browserHistory}>
//     <Route exact path='subscription'>
//       <Route exact path='success' component={Success} />
//       <Route exact path='review' component={Review} />
//       <Route exact path=':id' component={Details} />
//     </Route>
//   </Router>
// )

const Subscription = (props) => {
  const dispatch = useDispatch()

  const countries = useSelector((state) => state.user.countries)
  const isAnGuestUser = useSelector((state) => state.user.isAnGuestUser)
  const selectedCountry = useSelector((state) => state.user.selectedCountryData)
  const appConfigDetails = useSelector((state) => state.user.appConfigDetails)
  const [showCountryModal, setShowCountryModal] = useState(false)
  const [country, setCountry] = useState({})
  const [allDetailsAvailable, setAllDetailsAvailable] = useState(false)

  useEffect(() => {
    const handleOnBeforeUnload = () => {
      isAnGuestUser && dispatch(setSelectedCountryData({}))
    }
    window.addEventListener('unload', handleOnBeforeUnload)
    return () => {
      window.removeEventListener('unload', handleOnBeforeUnload)
    }
  }, [dispatch, isAnGuestUser])

  const getAppConfigDetails = useCallback(
    async (country) => {
      try {
        dispatch(setglobalLoaderStatus(true))
        if (!country.CountryCode) {
          const regionCode = await lookup()
          window.CountryCode = regionCode
          window.CountryID = undefined
        }
        const response = await getRequestAsync('Consumer/appConfig')
        dispatch(setCountries(response.Countries))
        dispatch(setAppConfig(response))
        if (!country.CountryCode) {
          let countryObj = response.Countries.find((val) => val.CountryCode === window.CountryCode)
          if (!countryObj && Array.isArray(response.Countries) && response.Countries.length === 1) {
            setShowCountryModal(true)
            countryObj = response.Countries[0]
            setCountry(countryObj)
          } else {
            dispatch(
              setSelectedCountryData({
                ...countryObj,
                label: '+' + countryObj.PhoneCode,
                value: countryObj.PhoneCode,
              })
            )
          }
        } else {
          setAllDetailsAvailable(true)
          dispatch(
            setSelectedCountryData({
              ...country,
              label: '+' + country.PhoneCode,
              value: country.PhoneCode,
            })
          )
        }
      } catch (e) {
        console.log(e)
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        dispatch(setglobalLoaderStatus(false))
      }
    },
    [dispatch]
  )

  useEffect(() => {
    const getInitialDetails = async () => {
      if (!props.params.id) {
        if (!Object.keys(selectedCountry).length) {
          await getAppConfigDetails(selectedCountry)
          dispatch(setglobalLoaderStatus(false))
        } else {
          setAllDetailsAvailable(true)
        }
      } else {
        browserHistory.push(`/subscription/${props.params.id}`)
      }
    }
    getInitialDetails()
  }, [props.params, selectedCountry, getAppConfigDetails, dispatch])

  useEffect(() => {
    if (allDetailsAvailable) {
      const getMultipleDevicePlanDetails = async () => {
        try {
          dispatch(setglobalLoaderStatus(true))
          const response = await postRequestAsync('ConsumerProduct/fetchEligibleEnrollmentPlans', {
            app: BrandSpecificConfig().header,
            CountryID: window.CountryID,
          })
          if (response.length) {
            if (response.length === 1) {
              const planCode = response[0].PlanObject[0].PlanCode
              dispatch(setEnrollmentPlanList(response[0].PlanObject))
              dispatch(setglobalLoaderStatus(false))
              browserHistory.push(`/subscription/${planCode}`)
            } else {
              // Show modal only if on subscription page
              setShowCountryModal(true)
              dispatch(setglobalLoaderStatus(false))
            }
          } else {
            throw response
          }
        } catch (e) {
          console.log(e)
          dispatch(setglobalLoaderStatus(false))
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          browserHistory.replace('/')
        }
      }
      getMultipleDevicePlanDetails()
    }
  }, [allDetailsAvailable, dispatch])

  const CountryValueComponent = useCallback(
    (props) => (
      <div className='country-value-container'>
        <img src={props.value.FlagImageURL} alt='' />
        <div className='country-label'>
          <span>{props.value.CountryName}</span>
        </div>
      </div>
    ),
    []
  )

  const CountryOptionComponent = useCallback(
    (props) => (
      <div className='country-value-container'>
        <img src={props.option.FlagImageURL} alt='' />
        <div className='country-label'>{props.option.CountryName}</div>
      </div>
    ),
    []
  )

  const handleCountrySelection = (country) => {
    if (country.CountryCode !== selectedCountry.CountryCode) {
      // call appConfig only when selected country has changed
      setCountry(country)
    }
  }

  const handleCountryClick = async () => {
    window.CountryCode = country.CountryCode
    window.CountryID = country.CountryID
    const fallback = 'en'
    let defaultLanguage = _.get(selectedCountry, 'countryConfig.LanguageCode.DEFAULT', fallback).toLowerCase()
    localStorage.setItem('languageCode', defaultLanguage)
    setShowCountryModal(false)
    await getAppConfigDetails(country)
    dispatch(setglobalLoaderStatus(false))
  }

  return (
    <Modal showModal={showCountryModal} closeModal={() => {}} className='sfy-modal'>
      <Modal.Header>
        <h2 className='mb20 text-center'>{getTranslationText('store', 'countryModalHeader')}</h2>
      </Modal.Header>
      <div className={'close-icon ' + (window.isDirectionRTL ? ' text-left ' : ' text-right')}></div>
      <div className='col-sm-12 mb8'>
        {appConfigDetails && countries.length === 1
          ? getTranslationText('store', 'countryModalText')
          : getTranslationText('store', 'countriesModalText')}
      </div>
      <div className='col-sm-12'>
        <Dropdown
          id='subscription-dropdown'
          handleOnChange={(item) => handleCountrySelection(item)}
          value={Object.keys(country).length > 0 ? country : ''}
          placeholder={getTranslationText('common', 'searchByText')}
          options={
            appConfigDetails && countries.length
              ? countries.map((countryObj) => ({
                  ...countryObj,
                  label: countryObj.CountryName,
                  value: countryObj.CountryName,
                }))
              : []
          }
          ValueComponent={Object.keys(country).length > 0 && CountryValueComponent}
          OptionsComponent={CountryOptionComponent}
          filterBy='CountryName'
          searchText={getTranslationText('common', 'search')}
          // isDisabled={appConfigDetails && countries.length === 1}
        />
      </div>
      <div className='col-sm-12'>
        <MediumButton className='button-small mt20' buttonText={getTranslationText('common', 'okay')} onClick={handleCountryClick} />
      </div>
    </Modal>
  )
}

export default Subscription
