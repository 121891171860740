import React from 'react'
import PropTypes from 'prop-types'
// import { Link } from 'react-router'
import { BoxHolder, Heading, MediumButton } from '../../../components/UIComponents'
import _ from 'lodash'
import './PurchaseHistory.scss'
import { getTranslationText } from '../../../utils/languageTranslation'
import { getLocaleDate } from '../../../utils/locale'

export class OrderListComponent extends React.Component {
  render() {
    let productImageUrl = _.get(this, 'props.user.appConfigDetails.productImageUrl', '')
    let ordersList = this.props.devices.deviceDetails.ordersList
    let dateFormat = _.get(this, 'props.user.selectedCountryData.countryConfig.DateFormat', 'DD MMM YYYY')
    return (
      <div className='order-items-list-widget mt20'>
        <BoxHolder className='title-wrapper'>
          <Heading className='mediumFontStyle fontLarge' title='Purchase history' />
        </BoxHolder>
        {ordersList.map((order, index) => {
          return (
            <div key={index} className={ordersList.length - 1 !== index ? 'mb28' : ''}>
              <div className='order-heading-wrapper'>
                <div>
                  Order Number:
                  <br />
                  <span className='mediumFontStyle'>{order.OrderNo}</span>
                </div>
                <div>
                  Date:
                  <br />
                  <span className='mediumFontStyle'>{getLocaleDate(dateFormat, order.DateOfPurchase)}</span>
                </div>
              </div>
              <div className='order-details-wrapper'>
                {order.itemList.map((item, index) => (
                  <BoxHolder key={index} className='row'>
                    <div className='col-sm-2 item-img-wrapper'>
                      <img src={productImageUrl + item.ProductSubCategoryID + '.png'} />
                    </div>
                    <div className='col-sm-7 item-info-wrapper'>
                      <div className='row'>
                        <div className='col-sm-4 col-6'>
                          <div>
                            {getTranslationText('common', 'productName')}
                            <br />
                            <span className='mediumFontStyle'>{item.Product?.ProductName}</span>
                          </div>
                        </div>
                        <div className='col-sm-4 col-6'>
                          <div>
                            Product Type
                            <br />
                            <span className='mediumFontStyle'>{item.ProductSubCategory.ProductSubCategory}</span>
                          </div>
                        </div>
                        <div className='col-sm-4 col-6'>
                          <div>
                            {getTranslationText('common', 'imei')}
                            <br />
                            <span className='mediumFontStyle'>{item.ProductUniqueID || '-'}</span>
                          </div>
                        </div>
                        {/*
                          <div className='col-sm-4 col-6'>
                            <div>Item Price<br />
                              {currencySign && <span dangerouslySetInnerHTML={{ __html: currencySign }} />}
                              <span className='mediumFontStyle'>5435894</span>
                            </div>
                          </div>
                          */}
                      </div>
                    </div>
                    <div className='col-sm item-request-btn'>
                      {item.ConsumerServiceRequestID ? (
                        <MediumButton
                          className='button-small'
                          buttonText='Track'
                          onClick={() => {
                            this.props.handleSelectedItemAction(item.ConsumerProductID, order, true)
                          }}
                        />
                      ) : (
                        <MediumButton
                          className='button-small button-ghost'
                          buttonText='Raise Service Request'
                          onClick={() => this.props.handleSelectedItemAction(item.ConsumerProductID, order)}
                        />
                      )}
                    </div>
                  </BoxHolder>
                ))}
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  static propTypes = {
    handleSelectedItemAction: PropTypes.func.isRequired,
    devices: PropTypes.object.isRequired,
  }
}
