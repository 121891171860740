export function linkify(inputText) {
  let replacedText, replacePattern1, replacePattern2, replacePattern3

  // URLs starting with http://, https://, or ftp://
  replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim
  replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>')

  // URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  replacePattern2 = /(^|[^/])(www\.[\S]+(\b|$))/gim
  replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>')

  // Change email addresses to mailto:: links.
  replacePattern3 = /(([a-zA-Z0-9\-_.])+@[a-zA-Z_]+?(\.[a-zA-Z]{2,6})+)/gim
  replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>')

  return replacedText.replace(/\n/g, '<br />')
}

export const plainTxtRegex = {
  arabic: /^[\u0600-\u06FF\w\d\s!@#$%&*(),.?':{}-]*$/,
  latinAndGreek: /^[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF\u0370-\u03FF\w\d\s!@#$%&*(),.?':{}\/-]*$/,
}

export const damageLocationRegex = {
  arabic: /^[\u0600-\u06FF\w\d\s!@#$%&*(),.?':{}-]*$/,
  latinAndGreek: /^[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF\u0370-\u03FF\w\d\s!@#$%&*(),.?':{}\/-]*$/,
}

export function addressRegexCheck(value) {
  const addrRegex = /^[\w\d\s-,'./()]*$/
  return addrRegex.test(value)
}

export function plainTextCheck(value) {
  const regex = plainTxtRegex[window.isDirectionRTL ? 'arabic' : 'latinAndGreek']
  return regex.test(value)
}

export function regexCheck(validationType, type, ...args) {
  try {
    return regexRegistory[validationType][type](...args)
  } catch (err) {
    return false
  }
}

let regexRegistory = {
  onChange: {
    numeric: (value, lengthCheck) => {
      const regex = /^[0-9]*$/
      if (lengthCheck) {
        return regex.test(value) && value.length <= lengthCheck
      } else {
        return regex.test(value)
      }
    },
    numericfloat: (value, lengthCheck) => {
      const regex = /^\d*\.?\d*$/
      if (lengthCheck) {
        return regex.test(value) && value.length <= lengthCheck
      } else {
        return regex.test(value)
      }
    },
    userName: (value, length = 40) => {
      const regex = /^(([a-zA-Z\u00C0-\u024F\u1E00-\u1EFF]+)([.\-' ]*))*$/
      return regex.test(value) && value.length <= length
    },
    emailId: (value, length = 40) => {
      const eRegex = /^[A-Za-z0-9@._-]*$/
      return eRegex.test(value) && value.length <= length
    },
    plainTxt: (value, allowLengthCheck = false, length = 200) => {
      const regex = plainTxtRegex[window.isDirectionRTL ? 'arabic' : 'latinAndGreek']
      if (allowLengthCheck) {
        return regex.test(value) && value.length <= length
      } else {
        return regex.test(value)
      }
    },
    alphanumeric: (value, lengthCheck) => {
      const regex = /^[\w\s]*$/
      if (lengthCheck) {
        return regex.test(value) && value?.length <= lengthCheck
      } else {
        return regex.test(value)
      }
    },
    damageLocation: (value) => {
      const regex = damageLocationRegex[window.isDirectionRTL ? 'arabic' : 'latinAndGreek']
      return regex.test(value)
    },
    string: (value) => {
      const regex = /^[0-9]{0,2}$|^[0-9]{2}\-$|^[0-9]{2}\-[0-9]{0,3}$/
      return regex.test(value)
    },
  },
  validation: {
    numeric: (value, lengthRange) => {
      const regex = /^[0-9]*$/
      return value && regex.test(value) && lengthRange.includes(value.length)
    },
    userName: (value, minLength = 2, maxLength = 40) => {
      value = value && value.trim()
      const nameRegex = /^(([a-z'A-Z\u00C0-\u024F\u1E00-\u1EFF]+)([.\-', ]*))*$/
      return value && nameRegex.test(value) && value.length >= minLength && value.length <= maxLength
    },
    emailId: (value) => {
      const eRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+[.][a-zA-Z]+([.][a-zA-Z]+)*$/
      return value && eRegex.test(value)
    },
    hasAlphachars: (value) => {
      const regex = /[A-Za-z]+/
      return value && regex.test(value)
    },
    string: (value, length = 6) => {
      const regex = /^[0-9]{2}\-[0-9]{3}$/
      return regex.test(value) && value.length === length
    },
  },
  getValue: {
    characters: (value) => {
      const regex = /\s*/g
      return (value && value.replace(regex, '')) || ''
    },
  },
}
