import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'
import {
  HomeDeviceOverView,
  Heading,
  MediumButton,
  ContactUsModal,
  RaiseARequestSupportModal,
  BoxHolder,
} from '../../../components/UIComponents'
import { Carousel } from 'sfy-react'
import { ServicesDiv, TrackRequestContainer, WarrantyPlansCarousel, FaqListContainer, ManageSubscriptionModal } from './HomeSubComponents'
import './Home.scss'
import _ from 'lodash'
import { checkIfHasAccessToken, getLegalConsents, replacePlaceholders, getPlaceholders } from '../../../utils/functions'
import safePromise from '../../../utils/safePromise'
import { getTranslationText } from '../../../utils/languageTranslation'
import pushToAnalytics, { pushProfileToAnalytics } from '../../../utils/Analytics'
import { legalConfigCodes, legalConfigAttributes, BrandSpecificConfig } from '../../../utils/constants'
const emptyTrackImage = `/commonAssets/letter-v.png`
const leftArrowImg = '/commonAssets/left.svg'
const rightArrowImg = '/commonAssets/right.svg'
import { ReactComponent as DiscountImg } from '../../../../public/commonAssets/discount.svg'
import { getLocaleCurrency, getLocaleDate } from '../../../utils/locale'
import lookup from '../../../utils/ipinfo'

class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      productListBoxData: {
        categoryList: [],
        brandList: [],
        productList: [],
      },
      newDeviceListBoxDataValues: {
        categoryValue: undefined,
        brandEnabled: false,
        brandValue: undefined,
        productEnabled: false,
        productValue: undefined,
      },
      deviceOverviewState: {
        noOfDevices: '00',
        noOfDevicesProtected: '00',
      },
      // loader state
      deviceOverviewloaderState: true,
      beginnerLvlloaderState: true,
      devicedependentLoader: true,
      faqListInfo: [],
      showContactUsModal: false,
      isLanguageChecked: false,
      showRequestTypePopUp: false,
      isConfigFetched: false,
      trackReqInfo: [],
      missedPaymentsList: [],
      showManageSubscriptionModal: false,
      showManageSubscriptionButton: _.get(this, 'props.user.appConfigDetails.showManageSubscriptionButton', false),
      isContractPurchasedFromThirdParty: false,
    }
  }

  fetchMissedPayments = () => {
    const dateFormat = _.get(this, 'props.user.selectedCountryData.countryConfig.DateFormat.DEFAULT', 'DD/MM/YYYY')
    const locale = _.get(this, 'props.user.selectedCountryData.countryConfig.Locale.DEFAULT', 'en')
    const currencyCode = _.get(this, 'props.user.selectedCountryData.CurrencyCode')
    this.props.getPurchasedPlansAsync().then((response) => {
      const plans = response.PlanArray.filter((plan) => {
        return plan.Status === -9 && plan.Category !== 'NORMAL' && plan.FrequencyUnit && plan.RequiresExternalPayment === 'SERVIFY_PAYMENT'
      })
      const plans_with_banner_text = plans.map((plan) => {
        return {
          ...plan,
          bannerTxt: getTranslationText('device', 'autoDebitFailedMsg')
            .replace('@amount@', getLocaleCurrency(locale, currencyCode, plan.PlanAmount))
            .replace('@plan_name@', plan.PlanName)
            .replace('@pending_months@', plan.NoOfPaymentPending)
            .replace('@billing_date@', plan.EffectiveDate ? getLocaleDate(dateFormat, plan.EffectiveDate) : '')
            .replace('@grace_period_enddate@', plan.HoldPeriodDate ? getLocaleDate(dateFormat, plan.HoldPeriodDate) : ''),
        }
      })
      // console.log('plans_with_banner_text - ', plans_with_banner_text)
      this.setState({
        missedPaymentsList: plans_with_banner_text,
      })
    })
  }

  fetchDiscounts = () => {
    this.props.fetchDiscountsAsync({
      ConsumerID: _.get(this, 'props.user.userDetails.consumerID', undefined),
      EmailID: _.get(this, 'props.user.userDetails.emailID', undefined),
    })
  }
  fetchBenefits = () => {
    this.props.fetchSoldPlanBenefits(
      {
        ConsumerID: _.get(this, 'props.user.userDetails.consumerID', undefined),
        app: BrandSpecificConfig().header,
        appFilters: true,
      },
      _.get(this, 'props.user.consumerDetails.data.accessToken.ID', undefined)
    )
  }

  fetchBanners = () => {
    this.props.fetchBannersAsync().then(() => {
      let homeBannersList = []
      this.props.user.bannerList.forEach((banner) => {
        if (banner.location.includes('home')) {
          const { LocalImgTxt, BrandName, imageLink, bannerLink, orderPreference, PlanType } = banner
          let BannerLink = bannerLink
          let imagePath = imageLink || ''
          let BannerBtn = ''
          // eslint-disable-next-line max-len

          // Direct to store page if the banner has any one of the below Local Image Text
          const directToStoreMap = ['BANNER_STORE_1', 'BANNER_STORE_2', 'BANNER_STORE_3', 'BANNER_STORE_4', 'BANNER_BUY_PLAN']

          const directToStorePage = directToStoreMap.includes(LocalImgTxt) || (BannerLink && BannerLink.includes('store'))
          const directToAddDevice = LocalImgTxt === 'BANNER_ADD_NEW_DEVICE'

          if (directToStorePage) {
            BannerLink = `/store/view?id=${BrandName}${PlanType ? `&plan=${PlanType}` : ``}`
          } else if (directToAddDevice) {
            var devicePath = 'device'
            localStorage.setItem('checkTitlePath', devicePath)
            BannerLink = '/devices/add-device'
          } else {
            // book a repair
            BannerLink = '/request/select-device?type=repair'
            BannerBtn = 'FILE_CLAIM'
          }

          homeBannersList[orderPreference] = {
            ...banner,
            imagePath,
            BannerLink,
            BannerBtn,
          }
        }
      })
      if (window.isDirectionRTL) {
        homeBannersList = homeBannersList.reverse()
      }
      this.setState({
        homeBannersList,
      })
    })
  }

  handleSSOLogin = async () => {
    // Lookup the region code using a helper function (assumed to be defined elsewhere)
    const regionCode = await lookup()

    // Set global variables for country code and country ID
    window.CountryCode = regionCode

    try {
      await this.props.loginUsingSSOAsync()
    } catch (error) {
      console.error('SSO login failed:', error)
    }
  }

  componentDidMount() {
    let token = checkIfHasAccessToken()
    window.scrollTo(0, 0)
    const disable_change_payment_method = BrandSpecificConfig().header == 'RealmeEU-Web'
    this.props.clearTrackState()
    let AuthID = _.get(this, 'props.user.consumerDetails.data.accessToken.ID')
    let promise = new Promise((resolve) => {
      resolve()
    })
    this.props.setglobalLoaderStatus(true)
    if (!AuthID && token) {
      promise = this.props.getAccessTokenFromCookie({})
    }
    const isSSOEnabled = _.get(this, 'props.user.appConfigDetails.isSSOEnabled', false)
    const callbackURL = _.get(this, 'props.user.appConfigDetails.callbackURL', false)
    let urlParams = new URLSearchParams(window.location.search)
    const isSamsungSSOLoginFlow = urlParams.get('code')
    if (isSamsungSSOLoginFlow) {
      promise = this.handleSSOLogin()
    } else if (!AuthID && isSSOEnabled && callbackURL) {
      promise = this.props.loginUsingSSOAsync()
    }
    promise
      .then(() => {
        this.props
          .getUserDetailsAsync(null, false) // fetch
          .then(() => {
            this.props
              .loadAppConfig()
              .then(() => {
                this.props
                  .getAppLegalConfigs()
                  .then(() => {
                    this.props.setIsSSOFlow(false)
                    if (
                      !_.get(this, 'props.user.userDetails.isConsumerLinked', true) &&
                      _.get(this, 'props.user.appConfigDetails.isSSOEnabled', false)
                    ) {
                      browserHistory.replace('/link-account')
                      return
                    }
                    if (_.get(this, 'props.location.lastpathName.path') && _.get(this, 'props.location.lastpathName.obtainProfile')) {
                      browserHistory.replace(this.props.location.lastpathName.path)
                      return
                    }
                    if (isSSOEnabled && callbackURL && localStorage.getItem('log_consents')) {
                      localStorage.removeItem('log_consents')
                      let cc = _.get(this, 'props.user.selectedCountryData.CountryCode', 'other').toLowerCase()
                      if (!legalConfigCodes[cc]) cc = 'other'
                      var consents = getLegalConsents(_.get(this, 'props.user.appLegalConfigs.LegalConfigs', []), [
                        legalConfigCodes[cc].tnc,
                      ])
                      if (consents.length && _.get(this, 'props.user.userDetails.consumerID')) {
                        let loginLinks = {
                          termsACondLink: this.handleRedirection('tnc', '/terms-conditions'),
                          privacyLink: this.handleRedirection('privacy', '/privacy-policy'),
                          dataLink: this.handleRedirection('data', '/online-consent'),
                        }
                        let getLink = (link) => {
                          if (link === 'tnc') return _.get(this.props, 'user.tncLink', loginLinks.termsACondLink)
                          if (link === 'privacy') return _.get(this.props, 'user.privacyLink', loginLinks.privacyLink)
                          if (link === 'dataconsent') return _.get(this.props, 'user.dataLink', loginLinks.dataLink)
                        }
                        let consents_array = consents.map((consent) => {
                          let attributes = []
                          let phs = getPlaceholders(consent.legalConfig.Text)
                          phs.length &&
                            phs.map((ph) => {
                              attributes.push({ AttributeType: legalConfigAttributes[ph], AttributeValue: getLink(ph) })
                            })
                          return {
                            ApplicationLegalConfigID: consent.ApplicationLegalConfigID,
                            ConsumerID: _.get(this, 'props.user.userDetails.consumerID'),
                            ConsentText: replacePlaceholders(consent.legalConfig.Text),
                            Attributes: attributes,
                          }
                        })
                        console.log('consents - ', consents_array)
                        this.props.logConsentsAsync({ ConsentArray: consents_array })
                      }
                    }
                    if (
                      isSSOEnabled &&
                      callbackURL &&
                      localStorage.getItem('subscription') &&
                      JSON.parse(localStorage.getItem('subscription'))
                    ) {
                      pushProfileToAnalytics(
                        _.get(this, 'props.user.userDetails.emailID', ''),
                        _.get(this, 'props.user.userDetails.consumerID', '')
                      )
                      pushToAnalytics(
                        'Plan Sales - Login Successful',
                        {
                          Name: this.props.user.userDetails.name,
                          Email: this.props.user.userDetails.emailID,
                          'Mobile Number': this.props.user.userDetails.mobileNo,
                          ConsumerID: _.get(this, 'props.user.userDetails.consumerID', ''),
                          Plan_Name: JSON.parse(localStorage.getItem('subscription')).planDetails.PlanDetails.PlanName || '',
                        },
                        this.props.user
                      )
                      browserHistory.replace('/subscription/review')
                      return
                    }

                    //* SAMSUNG SSO: DEVICE PAGE REDIRECTION WITH CP_DETAILS */
                    if (isSamsungSSOLoginFlow) {
                      localStorage.setItem(
                        'languageCode',
                        _.get(this, 'props.user.selectedCountryData.countryConfig.LanguageCode.DEFAULT', 'en')
                      )
                      const imeiOrSerialNumber = _.get(this, 'props.user.consumerDetails.data.imei')
                      browserHistory.push(`/devices?cpid=${imeiOrSerialNumber}`)
                      return
                    }
                    this.setState({
                      isConfigFetched: true,
                    })
                    let ConsumerID = _.get(this, 'props.user.userDetails.consumerID')

                    pushProfileToAnalytics(
                      _.get(this, 'props.user.userDetails.emailID', ''),
                      _.get(this, 'props.user.userDetails.consumerID', '')
                    )
                    const LoggedIn = _.get(this, 'props.user.userDetails.consumerID', false)
                    pushToAnalytics(
                      'Homepage',
                      {
                        Name: this.props.user.userDetails.name,
                        Email: this.props.user.userDetails.emailID,
                        'Mobile Number': this.props.user.userDetails.mobileNo,
                        ConsumerID: _.get(this, 'props.user.userDetails.consumerID', ''),
                        Logged_In: LoggedIn ? 'Yes' : 'No',
                      },
                      this.props.user
                    )
                    this.props.setSelectedDevice({})
                    this.props.setglobalLoaderStatus(false)
                    this.props
                      .getDeviceOverviewAsync({
                        ConsumerID,
                        AuthID,
                      })
                      .then((response) => {
                        this.setState((prevState) => ({
                          deviceOverviewloaderState: false,
                          productImageUrl: this.props.user.appConfigDetails.productImageUrl,
                          deviceOverviewState: {
                            ...prevState.deviceOverviewState,
                            noOfDevices: this.deviceOverviewCounts(response.NoOfDevices),
                            noOfDevicesProtected: this.deviceOverviewCounts(response.NoOfDevicesProtected),
                          },
                        }))
                      })
                    this.loadCategoryList()
                    this.props.setglobalLoaderStatus(true)

                    if (this.state.showManageSubscriptionButton) {
                      this.props
                        .checkIfContractPurchasedFromThirdPartyAsync({
                          app: _.get(this.props, 'user.appConfigDetails.AppName', undefined),
                          CountryID: _.get(this.props, 'user.selectedCountryData.CountryID', undefined),
                          ConsumerID: _.get(this, 'props.user.userDetails.consumerID', ''),
                        })
                        .then((response) => {
                          this.props.setglobalLoaderStatus(false)
                          this.setState({
                            isContractPurchasedFromThirdParty: response?.ContractPurchasedFromThirdParty,
                          })
                        })
                        .catch(() => {
                          this.props.setglobalLoaderStatus(false)
                        })
                    }

                    this.props
                      .getDeviceRepairHistory({
                        getActiveRequests: true,
                      })
                      .then((response) => {
                        this.props.setglobalLoaderStatus(false)
                        this.setState({
                          trackReqInfo: response,
                        })
                      })
                      .catch(() => {
                        this.props.setglobalLoaderStatus(false)
                      })
                    this.fetchBanners()
                    this.fetchFaq()
                    this.fetchDiscounts()
                    this.fetchBenefits()
                    if (!disable_change_payment_method) this.fetchMissedPayments()

                    //* WEBHOOK: FreshChat Webhook added */
                    if (window.fcWidget) {
                      const isMobileLogin = this.props.user.userDetails?.primaryIdentifier == 'MOBILE_NUMBER'
                      let emailObject = { email: this.props.user.userDetails.emailID }
                      let phoneNumberObject = { phone: this.props.user.userDetails.mobileNo }
                      window.fcWidgetMessengerConfig = isMobileLogin ? phoneNumberObject : emailObject
                      window.fcWidget?.user.setProperties({
                        phone: this.props.user.userDetails.mobileNo,
                        email: this.props.user.userDetails.emailID,
                      })
                    }
                  })
                  .catch((e) => {
                    console.log(e)
                    this.unsetAccessToken()
                    browserHistory.replace('/login')
                  })
              })
              .catch(() => {
                this.unsetAccessToken()
                // this.props.setToaster(err.message || 'Something went wrong!!')
                browserHistory.replace('/login')
              })
          })
          .catch(() => {
            this.unsetAccessToken()
            this.props.setglobalLoaderStatus(false)
            browserHistory.replace('/login')
          })
      })
      .catch(() => {
        this.unsetAccessToken()
        this.props.setglobalLoaderStatus(false)
        browserHistory.replace('/login')
      })
  }

  handleRedirection = (type, customLink) => {
    let skipRedirection = _.get(this, 'props.user.appConfigDetails.SkipRedirection', false)
    let link = customLink
    if (type === 'tnc') {
      link = this.props.user.appConfigDetails.Terms
      skipRedirection && this.props.setTncLink(link)
    } else if (type === 'privacy') {
      link = this.props.user.appConfigDetails.Privacy
      skipRedirection && this.props.setPrivacyLink(link)
    } else if (type === 'data') {
      link = this.props.user.appConfigDetails.ConsentURL
      skipRedirection && this.props.setDataConsentLink(link)
    }
    if (skipRedirection) {
      link = customLink
    }
    return link
  }

  fetchFaq = async () => {
    const [error, response] = await safePromise(this.props.getFaq())
    if (error) {
      console.error('Something went wrong while fetching FAQ!')
      return
    }
    const faqs = _.get(response, 'data.data', [])
    if (!faqs.length) {
      // console.info('FAQ details not found')
      return
    }
    this.setState({ faqListInfo: faqs })
  }

  componentDidUpdate = (prevProps) => {
    if (this.props !== prevProps) {
      const previousTranslationData = JSON.stringify(prevProps.translationData)
      const currentTranslationData = JSON.stringify(this.props.translationData)
      const hasLanguageChanged = previousTranslationData !== currentTranslationData
      const disable_change_payment_method = BrandSpecificConfig().header == 'RealmeEU-Web'
      if (hasLanguageChanged) {
        this.fetchBanners()
        this.fetchFaq()
        this.fetchDiscounts()
        this.fetchBenefits()
        if (!disable_change_payment_method) this.fetchMissedPayments()
      }
    }
  }

  unsetAccessToken = () => {
    this.props.sendotpreport({
      success: false,
      data: { accessToken: { id: undefined } },
    })
  }

  closeContactUsModal = () => {
    this.setState({
      showContactUsModal: false,
    })
  }

  openManageSubscriptionModal = () => {
    this.setState({
      showManageSubscriptionModal: true,
    })
  }

  closeModal = (ModalName) => {
    this.setState({
      [ModalName]: false,
    })
  }

  openContactUsModal = () => {
    this.setState({
      showContactUsModal: true,
    })
  }

  // to convert value from (0....9) to (00, 01, ..., 09)
  deviceOverviewCounts = (val) => {
    if (val && val.toString().length < 2) {
      return '0' + val.toString()
    } else {
      return (val && val.toString()) || '00'
    }
  }

  loadCategoryList = () => {
    let categorydata = this.props.user.appConfigDetails.Products.map((data) => {
      return { text: data.ProductSubCategory, CategoryId: data.ProductSubCategoryID }
    })
    this.setState(
      {
        productListBoxData: {
          ...this.state.productListBoxData,
          categoryList: categorydata,
        },
      },
      () => {
        if (categorydata.length === 1) {
          return this.handleCategoryListSelection(this.state.productListBoxData.categoryList)
        }
      }
    )
  }

  handleCategoryListSelection = (val) => {
    if (val.length) {
      var categoryList = { ...this.state.newDeviceListBoxDataValues }
      categoryList.categoryValue = val
      // set list of brand for selected category
      let branddata = []
      this.props.user.appConfigDetails.Products.filter((data) => {
        if (data.ProductSubCategoryID === val[0].CategoryId) {
          branddata = data.Brand.map((brand) => {
            return { text: brand.BrandName, BrandId: brand.BrandID }
          })
        }
      })
      categoryList.brandEnabled = true
      this.setState(
        {
          productListBoxData: {
            categoryList: [...this.state.productListBoxData.categoryList],
            brandList: branddata,
            productList: [],
          },
          newDeviceListBoxDataValues: {
            // if not more than 1 set brand name as pre-selected
            ...categoryList,
            brandValue: [],
          },
        },
        () => {
          if (branddata && branddata.length === 1) {
            this.handleBrandListSelection(branddata)
          }
        }
      )
    } else {
      this.setState({
        newDeviceListBoxDataValues: {
          ...this.state.newDeviceListBoxDataValues,
          categoryValue: [],
          brandValue: [],
          brandEnabled: false,
          productEnabled: false,
        },
        productListBoxData: {
          categoryList: [...this.state.productListBoxData.categoryList],
          brandList: [],
          productList: [],
        },
      })
    }
  }

  handleBrandListSelection = (val) => {
    if (val.length) {
      var brandList = { ...this.state.newDeviceListBoxDataValues }
      brandList.brandValue = val
      this.props
        .getProductsList({
          categoryID: this.state.newDeviceListBoxDataValues.categoryValue[0].CategoryId,
          brandID: val[0].BrandId,
        })
        .then((response) => {
          let productData = response.map((data) => {
            return { text: data.ProductName, ProductId: data.ProductID }
          })
          brandList.productEnabled = true
          this.setState({
            productListBoxData: {
              categoryList: [...this.state.productListBoxData.categoryList],
              brandList: [...this.state.productListBoxData.brandList],
              productList: productData,
            },
            newDeviceListBoxDataValues: brandList,
          })
        })
    } else {
      this.setState({
        newDeviceListBoxDataValues: {
          ...this.state.newDeviceListBoxDataValues,
          brandValue: undefined,
          productValue: [],
          productEnabled: false,
        },
        productListBoxData: {
          categoryList: [...this.state.productListBoxData.categoryList],
          brandList: [...this.state.productListBoxData.brandList],
          productList: [],
        },
      })
    }
  }

  handleProductListSelection = (val) => {
    if (val.length) {
      var productList = { ...this.state.newDeviceListBoxDataValues }
      productList.productValue = val
      this.setState({
        newDeviceListBoxDataValues: productList,
      })
    } else {
      this.setState({
        newDeviceListBoxDataValues: {
          ...this.state.newDeviceListBoxDataValues,
          productValue: undefined,
        },
      })
    }
  }

  addNewDeviceHome = () => {
    this.props.setglobalLoaderStatus(true)
    let ProductSubCategoryID = this.state.newDeviceListBoxDataValues.categoryValue[0].CategoryId
    let ConsumerID = this.props.user.userDetails.consumerID
    let AuthID = this.props.user.consumerDetails.data.accessToken.ID
    let { ProductID, BrandID } = ''
    if (this.state.newDeviceListBoxDataValues.brandValue.length) {
      BrandID = this.state.newDeviceListBoxDataValues.brandValue[0].BrandId
    }
    if (this.state.newDeviceListBoxDataValues.productValue.length) {
      ProductID = this.state.newDeviceListBoxDataValues.productValue[0].ProductId
    }
    if (ProductSubCategoryID && ConsumerID && AuthID && BrandID && ProductID) {
      let data = {
        ConsumerID,
        ProductSubCategoryID,
        BrandID,
        ProductID,
        show: false,
        IsUnderWarranty: null,
        IsActive: 1,
        validationError: false,
        msg: '',
        showP: false,
        TagName: '',
        ModelNo: '',
        WarrantyTill: null,
        DownloadedDeviceUniqueKey: '',
        ProductUniqueID: null,
        AlternateUniqueKey: null,
      }
      let addedDeviceCPid
      this.props
        .addDeviceAsync({
          data,
          AuthID,
        })
        .then((response) => {
          this.props.setglobalLoaderStatus(false)
          addedDeviceCPid = response.ConsumerProductID ? window.btoa(response.ConsumerProductID) : ''
          browserHistory.push('/devices?cpid=' + addedDeviceCPid)
        })
        .catch(() => {
          this.props.setglobalLoaderStatus(false)
        })
    }
  }

  handleTrackRequest = (e) => {
    if (e) {
      let id = parseInt(e.currentTarget.id.replace('id-', ''))
      browserHistory.push('/track?id=' + window.btoa(id.toString()))
      let currentDeviceInfo = this.state.trackReqInfo.find((data) => parseInt(data.ConsumerServiceRequestID) === id)
      currentDeviceInfo && this.props.setSelectedDevice(currentDeviceInfo)
    }
  }

  handleRecommendedPlanSelection = (e) => {
    browserHistory.push({
      ...this.props.location.query,
      pathname: '/store/buy',
      query: { id: e.currentTarget.id },
    })
  }

  openModal = () => {
    this.setState({ showRequestTypePopUp: true })
  }

  changePaymentMethod = (plan) => {
    // console.log('plan - ', plan)
    window.open(plan.PaymentLink || '', '_blank')
  }

  render() {
    const dateFormat = _.get(this, 'props.user.selectedCountryData.countryConfig.DateFormat.DEFAULT', 'DD/MM/YYYY')
    const isCustomBannerFont = _.get(this, 'props.user.appConfigDetails.isCustomBannerFont', false)
    return (
      <div>
        {(this.state.isConfigFetched && _.get(this, 'props.user.discounts', []).length) ||
        _.get(this, 'props.vouchers.benefits', []).length ||
        this.state.missedPaymentsList.length ? (
          <div className='home-discount-banner-container'>
            <Carousel
              items={[
                ..._.get(this, 'props.user.discounts', []),
                ..._.get(this, 'props.vouchers.benefits', []),
                ...this.state.missedPaymentsList,
              ].filter((item) => item)}
              showDots={false}
              showArrows={true}
              individualBanner={(banner, index) => (
                <div
                  key={index}
                  className=''
                  onClick={() => {
                    if (banner && banner.BenefitID) {
                      browserHistory.push({
                        ...this.props.location.query,
                        pathname: `/vouchers/${banner.SoldPlanBenefitID}`,
                      })
                    } else if (banner && banner.SoldPlanID) {
                      this.changePaymentMethod(banner)
                    } else if (banner && banner.PlanCode) {
                      browserHistory.push({
                        ...this.props.location.query,
                        pathname: '/store/buy',
                        query: { id: banner.PlanCode },
                      })
                    } else {
                      browserHistory.push({
                        ...this.props.location.query,
                        pathname: '/store',
                      })
                    }
                  }}
                >
                  <div className='home-discount-banner-content'>
                    {!banner.BenefitID && !banner.SoldPlanID && <DiscountImg className='home-discount-banner-img mr4' />}{' '}
                    <div className='bookFontStyle fontMedium mr4'>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: banner.HomePageDisplayText,
                        }}
                      />
                      {banner.BenefitID ? (
                        <span className='voucher-code-button'>{getTranslationText('voucher', 'getVoucherCode')}</span>
                      ) : banner.SoldPlanID && banner.bannerTxt && banner.bannerTxt.length > 0 ? (
                        <>
                          <span
                            className='missed-payment-banner-text'
                            dangerouslySetInnerHTML={{
                              __html: banner.bannerTxt,
                            }}
                          />
                          &nbsp;&nbsp;
                          <span className='boldFontStyle fontMedium missed-payment-banner-text-link'>
                            {getTranslationText('common', 'pay')}
                          </span>
                        </>
                      ) : (
                        <>
                          &nbsp;&nbsp;
                          <span className='boldFontStyle fontMedium home-discount-banner-buynow-text'>
                            {getTranslationText('home', 'buynow')}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}
              leftArrowImg={leftArrowImg}
              rightArrowImg={rightArrowImg}
              className={{
                container: 'home-discount-banner',
                leftArrow: 'banner-arrow',
                rightArrow: 'banner-arrow',
                arrow: 'banner-carousel-btn',
              }}
            />
          </div>
        ) : (
          ''
        )}
        {this.state.isConfigFetched ? (
          <div className='row mb48 mt20 home-components'>
            <div className='col-sm-12 col-lg-9'>
              {_.get(this, 'state.homeBannersList', []).length ? (
                <div className='banner-slider'>
                  <Carousel
                    items={this.state.homeBannersList.filter((item) => item)}
                    showDots={true}
                    showArrows={false}
                    className={{
                      dotContainer: 'sfy-slider-dots',
                    }}
                    individualBanner={(banner, index) => (
                      <div
                        key={index}
                        className='slider-slide-img'
                        onClick={() => {
                          if (BrandSpecificConfig().header !== 'AppleEU-Web') !banner.Button_Text && browserHistory.push(banner.BannerLink)
                        }}
                      >
                        <img src={banner.imagePath} />
                        <div className='banner-text-section'>
                          <div className='slider-plainTxt-content'>
                            <div className={`slider-main-heading slider-heading-color mb20 ${isCustomBannerFont ? 'white' : ''}`}>
                              <span dangerouslySetInnerHTML={{ __html: banner.Main_Text }} />
                            </div>
                            {banner.Sub_Text && <Heading title={banner.Sub_Text} className='slider-heading-color mb20' />}
                            {banner.Button_Text && (
                              <MediumButton
                                className='button-small'
                                buttonText={banner.Button_Text}
                                onClick={() => {
                                  if (
                                    !_.get(this, 'props.user.appConfigDetails.isRepairAllowed', true) &&
                                    banner.BannerLink.includes('repair')
                                  ) {
                                    this.openModal()
                                  } else {
                                    const isContactus = banner.LocalImgTxt == 'BANNER_CONTACT_US'
                                    if (isContactus) {
                                      const mailLink = _.get(this, 'props.user.appConfigDetails.footerLinks.support.email', false)
                                      const isShowContactDetailsInModal = _.get(
                                        this,
                                        'props.user.appConfigDetails.ShowContactDetailsInModal',
                                        false
                                      )
                                      isShowContactDetailsInModal
                                        ? this.openContactUsModal()
                                        : (window.location.href = 'mailto:' + mailLink)
                                    } else {
                                      if (banner.BannerBtn === 'FILE_CLAIM') {
                                        pushToAnalytics('File_claim_button_click', {
                                          ConsumerID: _.get(this, 'props.user.userDetails.consumerID', ''),
                                        })
                                      }
                                      browserHistory.push(banner.BannerLink)
                                    }
                                  }
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  />
                </div>
              ) : (
                ''
              )}
              <div className='row removeMargin'>
                {_.get(this, 'props.store.recommendedPlanList', []).length > 0 && (
                  <div className='col-sm-6 recommended-plans-carousel'>
                    <WarrantyPlansCarousel
                      recommendedPlans={this.props.store.recommendedPlanList.slice(0, 10)}
                      handleRecommendedPlanSelection={this.handleRecommendedPlanSelection}
                    />
                  </div>
                )}
                <div className={'col-sm-' + (_.get(this, 'props.store.recommendedPlanList', []).length ? '6' : '12')}>
                  <ServicesDiv
                    {...this.props}
                    openContactUsModal={this.openContactUsModal}
                    openModal={this.openModal}
                    isContractPurchasedFromThirdParty={this.state.isContractPurchasedFromThirdParty}
                    openManageSubscriptionModal={this.openManageSubscriptionModal}
                    showManageSubscriptionButton={this.state.showManageSubscriptionButton}
                  />
                </div>
                {_.get(this, 'state.faqListInfo', []).length > 0 && (
                  <div className='col-sm-12'>
                    <FaqListContainer faqList={this.state.faqListInfo} />
                  </div>
                )}
              </div>
              {_.get(this, 'props.user.appConfigDetails.ShowContactDetailsInModal') && this.state.showContactUsModal && (
                <ContactUsModal
                  {...this.props}
                  showContactUsModal={this.state.showContactUsModal}
                  closeContactUsModal={this.closeContactUsModal}
                />
              )}

              {this.state.showManageSubscriptionModal && (
                <ManageSubscriptionModal
                  {...this.props}
                  closeModal={this.closeModal}
                  showManageSubscriptionModal={this.state.showManageSubscriptionModal}
                  openManageSubscriptionModal={this.openManageSubscriptionModal}
                />
              )}

              {this.state.showRequestTypePopUp && (
                <RaiseARequestSupportModal
                  user={this.props.user}
                  showRaiseARequestSupportModal={this.state.showRequestTypePopUp}
                  closeModal={() => this.setState({ showRequestTypePopUp: false })}
                />
              )}
            </div>
            <div className='col-sm-12 col-lg-3'>
              <div className='row home-side-widgets'>
                <div className='col-sm-6 col-lg-12'>
                  <HomeDeviceOverView deviceOverviewState={this.state.deviceOverviewState} />
                </div>
                {_.get(this, 'state.trackReqInfo', []).length > 0 ? (
                  <div className='col-sm-6 col-lg-12 mb8'>
                    <TrackRequestContainer
                      trackReqInfo={this.state.trackReqInfo}
                      handleTrackRequest={this.handleTrackRequest}
                      productImageUrl={this.state.productImageUrl}
                      dateFormat={dateFormat}
                    />
                  </div>
                ) : _.get(this, 'props.user.appConfigDetails.AppName') === 'ServifyCare-Web' ? (
                  <div className='col-sm-6 col-lg-12 home-empty-track-wrapper'>
                    <BoxHolder className='device-overview-container'>
                      <div className='col-sm-12 mb8 track-info-header rm_pl'>
                        <Heading title={getTranslationText('track', 'trackRequest')} />
                      </div>
                      <hr className='col-sm-11' />
                      <div className='d-flex'>
                        <img src={emptyTrackImage} className='home-track-img' />
                        <p className='pl8 mediumFontStyle'>{getTranslationText('track', 'emptyTrackText')}</p>
                      </div>
                    </BoxHolder>
                  </div>
                ) : (
                  ''
                )}
                {/* <div className='col-sm-6 col-lg-12'>
                  {showAddDevice &&
                    <AddingDeviceWidget productListBoxData={this.state.productListBoxData}
                      CategorySelection={this.handleCategoryListSelection}
                      BrandSelection={this.handleBrandListSelection}
                      listboxdata={this.state.newDeviceListBoxDataValues}
                      ProductSelection={this.handleProductListSelection}
                      addNewDeviceHome={this.addNewDeviceHome} />}
                </div> */}
              </div>
            </div>
          </div>
        ) : (
          <div className='mb48' />
        )}
      </div>
    )
  }
}

export default Home

Home.propTypes = {
  loadAppConfig: PropTypes.func.isRequired,
  getAppLegalConfigs: PropTypes.func.isRequired,
  logConsentsAsync: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
  getDeviceOverviewAsync: PropTypes.func.isRequired,
  getProductsList: PropTypes.func.isRequired,
  setSelectedDevice: PropTypes.func.isRequired,
  getUserDetailsAsync: PropTypes.func.isRequired,
  getAccessTokenFromCookie: PropTypes.func.isRequired,
  loginUsingSSOAsync: PropTypes.func.isRequired,
  fetchBannersAsync: PropTypes.func.isRequired,
  fetchDiscountsAsync: PropTypes.func.isRequired,
  fetchSoldPlanBenefits: PropTypes.func.isRequired,
  addDeviceAsync: PropTypes.func.isRequired,
  setglobalLoaderStatus: PropTypes.func.isRequired,
  getDeviceRepairHistory: PropTypes.func.isRequired,
  location: PropTypes.object,
  translationData: PropTypes.object,
  sendotpreport: PropTypes.func.isRequired,
  setToaster: PropTypes.func,
  setSelectedOrder: PropTypes.func.isRequired,
  // generateRedirectionUrl: PropTypes.func.isRequired,
  getFaq: PropTypes.func.isRequired,
  getPurchasedPlansAsync: PropTypes.func.isRequired,
  getPayableAmountForNonLoginFlow: PropTypes.func.isRequired,
  storeLastKnowPathName: PropTypes.func.isRequired,
  setSoldPlanDetails: PropTypes.func.isRequired,
}
