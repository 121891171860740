import { connect } from 'react-redux'
import { fetchSoldPlanBenefits, markBenefitAsUsed } from '../../../store/vouchers'
import Vouchers from '../components/Vouchers'

const mapDispatchToProps = {
  fetchSoldPlanBenefits,
  markBenefitAsUsed,
}

const mapStateToProps = (state) => ({
  user: state.user,
  request: state.request,
  translationData: state.languageDetail.translationText,
  location: state.location,
})

export default connect(mapStateToProps, mapDispatchToProps)(Vouchers)
