/* eslint-disable */
// ------------------------------------
// Constants
// ------------------------------------
import { api } from '../config/variables'
import { setToaster } from './toastsAndLoaders'
import moment from 'moment'
import { getTranslationText } from '../utils/languageTranslation.js'
import _ from 'lodash'
import { legalConfigAttributes } from '../utils/constants'
export const SET_SELECTED_DEVICE_HANDLER = 'SET_SELECTED_DEVICE_HANDLER'
export const SET_USER_REQUEST_LOCATION = 'SET_USER_REQUEST_LOCATION'
export const SET_BROWSER_REQUEST_LOCATION = 'SET_BROWSER_REQUEST_LOCATION'
export const SET_REQUEST_MODES = 'SET_REQUEST_MODES'
export const SET_ASC_LOCATIONS = 'SET_ASC_LOCATIONS'
export const SET_REQUEST_SLOTS = 'SET_REQUEST_SLOTS'
export const SET_PARTNER_SERVICE_LOCATION = 'SET_PARTNER_SERVICE_LOCATION'
export const SET_SELECTED_MODE = 'SET_SELECTED_MODE'
export const SET_DATE_AND_TIME = 'SET_DATE_AND_TIME'
export const SET_REQUEST_DAMAGE_TYPES = 'SET_REQUEST_DAMAGE_TYPES'
export const SET_SELECTED_DAMAGE_TYPE = 'SET_SELECTED_DAMAGE_TYPE'
export const SET_REQUEST_ISSUES_FOR_TYPE = 'SET_REQUEST_ISSUES_FOR_TYPE'
export const CLEAR_REQUEST_STATE = 'CLEAR_REQUEST_STATE'
export const SET_ISSUES = 'SET_ISSUES'
export const SET_REQUEST_DETAILS = 'SET_REQUEST_DETAILS'
export const CLEAR_DEVICE_INFO = 'CLEAR_DEVICE_INFO'
export const CLEAR_FULL_STATE = 'CLEAR_FULL_STATE'
export const SET_ADDRESS_FLG = 'SET_ADDRESS_FLG'
export const CLEAR_DT_SLOTS = 'CLEAR_DT_SLOTS'
export const SET_LOGISTIC_PARTNER = 'SET_LOGISTIC_PARTNER'
export const SET_PICK_UP_INSTR = 'SET_PICK_UP_INSTR'
export const SET_REQUEST_FLOW_SOURCE = 'SET_REQUEST_FLOW_SOURCE'
export const VISITED_URL = 'VISITED_URL'
export const CLEAR_VISITED_URL_LIST = 'CLEAR_VISITED_URL_LIST'
export const SET_INVOICEDOC_VISIBILITY_STATE = 'SET_INVOICEDOC_VISIBILITY_STATE'
export const SET_ADDITIONAL_INFO = 'SET_ADDITIONAL_INFO'
export const SET_CALIM_FORM_DETAILS = 'SET_CALIM_FORM_DETAILS'
export const SET_CLAIM_ELIGIBLE = 'SET_CLAIM_ELIGIBLE'
export const SET_ELIGIBLE_PLANS_NEW = 'SET_ELIGIBLE_PLANS_NEW'
export const SET_ACTIVATION_PLAN = 'SET_ACTIVATION_PLAN'
export const ACTIVATE_PLAN = 'ACTIVATE_PLAN'
export const SET_TYPES_OF_DAMAGE = 'SET_TYPES_OF_DAMAGE'
export const ADVANCE_PAY = 'ADVANCE_PAY'
export const SET_SELECTED_ORDER_DETAILS = 'SET_SELECTED_ORDER_DETAILS'
export const REMOVE_VISTED_URL = 'REMOVE_VISTED_URL'
export const SET_RETURN_REASON = 'SET_RETURN_REASON'
export const SET_HANDLING_CHARGES = 'SET_HANDLING_CHARGES '
export const SET_REQUEST_PLAN_DETAILS = 'SET_REQUEST_PLAN_DETAILS'
export const SET_DECISION_MATRIX_DETAILS = 'SET_DECISION_MATRIX_DETAILS'
export const SET_MEDIA_CLAIM_INSTRUCTIONS_DETAILS = 'SET_MEDIA_CLAIM_INSTRUCTIONS_DETAILS'
export const SET_ADMIN_FEES = 'SET_ADMIN_FEES'
export const SET_BUYOUT_MODES = 'SET_BUYOUT_MODES'
export const SET_USER_BUYOUT_DETAILS = 'SET_USER_BUYOUT_DETAILS'
export const SET_ADDRESS_PREVIEW_DETAILS = 'SET_ADDRESS_PREVIEW_DETAILS'
export const SET_DROPOFF_EMPTY_BOX_DETAILS = 'SET_DROPOFF_EMPTY_BOX_DETAILS'
export const SET_ADVANCE_EXCHANGE_DETAILS = 'SET_ADVANCE_EXCHANGE_DETAILS'
export const CLEAR_CLAIM_FORM = 'CLEAR_CLAIM_FORM'
export const SET_ADDRESS_VALIDATION_DETAILS = 'SET_ADDRESS_VALIDTAION_DETAILS'
export const CLEAR_ADDERSS_VALIDATION_DETAILS = 'CLEAR_ADDERSS_VALIDATION_DETAILS'
export const SET_REPLACEMENT_MATRIX_DETAILS = 'SET_REPLACEMENT_MATRIX_DETAILS'
export const SET_REPLACEMENT_OPTION = 'SET_REPLACEMENT_OPTION'
export const SET_AUDIO_INFO = 'SET_AUDIO_INFO'
export const SET_PLACE_OF_DAMAGE = 'SET_PLACE_OF_DAMAGE'
export const SET_RETRY_PAYMENT_STATUS = 'SET_RETRY_PAYMENT_STATUS'
export const SET_HOLD_PAYMENT_STATUS = 'SET_HOLD_PAYMENT_STATUS'
export const SET_CONSENT_PAYMENT_DETAILS = 'SET_CONSENT_PAYMENT_DETAILS'
export const SET_COVERAGE_CONSENT_DETAILS = 'SET_COVERAGE_CONSENT_DETAILS'

const GOOGLE_MAPS_GEOCODING_API_KEY = api.googleApiKey
import { fetchCity } from '../utils/KSACountryMapping'

// ------------------------------------
// Actions
// ------------------------------------

export const setConsentCoverageConfig = (payload) => {
  return {
    type: SET_COVERAGE_CONSENT_DETAILS,
    payload,
  }
}

export const setRequestHoldPaymentStatus = (payload) => {
  return {
    type: SET_HOLD_PAYMENT_STATUS,
    payload,
  }
}

export const setRetryPaymentStatus = (payload) => {
  return {
    type: SET_RETRY_PAYMENT_STATUS,
    payload,
  }
}
export const setProductReturnReasons = (payload) => {
  return {
    type: SET_RETURN_REASON,
    payload,
  }
}
export const setSelectedOrder = (payload) => {
  return {
    type: SET_SELECTED_ORDER_DETAILS,
    payload,
  }
}
export const setClaimDetails = (payload) => {
  return {
    type: SET_CALIM_FORM_DETAILS,
    payload,
  }
}

export const setAdditionalUploadData = (payload) => {
  return {
    type: SET_ADDITIONAL_INFO,
    payload,
  }
}

export const setAudioUploadData = (payload) => {
  return {
    type: SET_AUDIO_INFO,
    payload,
  }
}

export const setInvoiceDocVisibilityState = (payload) => {
  return {
    type: SET_INVOICEDOC_VISIBILITY_STATE,
    payload,
  }
}

export const clearUrlList = () => {
  return {
    type: CLEAR_VISITED_URL_LIST,
  }
}

export const addToVisitedUrl = (payload) => {
  return {
    type: VISITED_URL,
    payload,
  }
}

export const setRequestFlowSource = (payload) => {
  return {
    type: SET_REQUEST_FLOW_SOURCE,
    payload,
  }
}

export const setLogisticPartner = (payload) => {
  return {
    type: SET_LOGISTIC_PARTNER,
    payload,
  }
}

export const setAddressFlgToStore = (payload) => {
  return {
    type: SET_ADDRESS_FLG,
    payload,
  }
}

export const clearDeviceInfo = () => {
  return {
    type: CLEAR_DEVICE_INFO,
  }
}

export const setSelectedDevice = (payload) => {
  return {
    type: SET_SELECTED_DEVICE_HANDLER,
    payload,
  }
}

export const setUserRequestLocation = (payload) => {
  return {
    type: SET_USER_REQUEST_LOCATION,
    payload,
  }
}

const setBrowserRequestLocation = (payload) => {
  return {
    type: SET_BROWSER_REQUEST_LOCATION,
    payload,
  }
}

export const setRequestModes = (payload) => {
  return {
    type: SET_REQUEST_MODES,
    payload,
  }
}

export const setAscLocationsData = (payload) => {
  return {
    type: SET_ASC_LOCATIONS,
    payload,
  }
}

export const setRequestSlots = (payload) => {
  return {
    type: SET_REQUEST_SLOTS,
    payload,
  }
}

export const setPartnerServiceLocation = (payload) => {
  return {
    type: SET_PARTNER_SERVICE_LOCATION,
    payload,
  }
}

export const setSelectedMode = (payload) => {
  return {
    type: SET_SELECTED_MODE,
    payload,
  }
}

export const setDateAndTime = (payload) => {
  return {
    type: SET_DATE_AND_TIME,
    payload,
  }
}

const setRequestDamageTypes = (payload) => {
  return {
    type: SET_REQUEST_DAMAGE_TYPES,
    payload,
  }
}

const setEligiblePlansNew = (payload) => {
  return {
    type: SET_ELIGIBLE_PLANS_NEW,
    payload,
  }
}

export const setSelectedDamageType = (payload) => {
  return {
    type: SET_SELECTED_DAMAGE_TYPE,
    payload,
  }
}

const setRequestIssuesForType = (payload) => {
  return {
    type: SET_REQUEST_ISSUES_FOR_TYPE,
    payload,
  }
}

export const clearRequestState = () => {
  return {
    type: CLEAR_REQUEST_STATE,
  }
}

export const setIssues = (payload) => {
  return {
    type: SET_ISSUES,
    payload,
  }
}

export const setRequestDetails = (payload) => {
  return {
    type: SET_REQUEST_DETAILS,
    payload,
  }
}

export const clearFullState = () => {
  return {
    type: CLEAR_FULL_STATE,
  }
}

export const clearDateTimeSlots = () => {
  return {
    type: CLEAR_DT_SLOTS,
  }
}

export const setPickUpInstructions = (payload) => {
  return {
    type: SET_PICK_UP_INSTR,
    payload,
  }
}

export const setClaimEligibility = (payload) => {
  return {
    type: SET_CLAIM_ELIGIBLE,
    payload,
  }
}

export const setActivationPlan = (payload) => {
  return {
    type: SET_ACTIVATION_PLAN,
    payload,
  }
}

export const activatePlan = (payload) => {
  return {
    type: ACTIVATE_PLAN,
    payload,
  }
}

export const setTypesOfDamage = (payload) => {
  return {
    type: SET_TYPES_OF_DAMAGE,
    payload,
  }
}

export const removeVisitedUrl = () => {
  return {
    type: REMOVE_VISTED_URL,
  }
}

export const setHandlingCharges = (payload) => {
  return {
    type: SET_HANDLING_CHARGES,
    payload,
  }
}

export const setDevicePlanDetails = (payload) => {
  return {
    type: SET_REQUEST_PLAN_DETAILS,
    payload,
  }
}

export const setDecisionMatrixDetails = (payload) => {
  return {
    type: SET_DECISION_MATRIX_DETAILS,
    payload,
  }
}

export const setServicetypeDetailsInstruction = (payload) => {
  return {
    type: SET_MEDIA_CLAIM_INSTRUCTIONS_DETAILS,
    payload,
  }
}

export const setAdminFees = (payload) => {
  return {
    type: SET_ADMIN_FEES,
    payload,
  }
}

const setPaymentModes = (payload) => {
  return {
    type: SET_BUYOUT_MODES,
    payload,
  }
}

export const setUserBuyOutDetails = (payload) => {
  return {
    type: SET_USER_BUYOUT_DETAILS,
    payload,
  }
}

export const setAddressPreviewDetails = (payload) => {
  return {
    type: SET_ADDRESS_PREVIEW_DETAILS,
    payload,
  }
}

export const setDropoffEmptyBoxflag = (payload) => {
  return {
    type: SET_DROPOFF_EMPTY_BOX_DETAILS,
    payload,
  }
}

export const setAdvanceExchangeDetails = (payload) => {
  return {
    type: SET_ADVANCE_EXCHANGE_DETAILS,
    payload,
  }
}

export const clearClaimFormValues = () => {
  return {
    type: CLEAR_CLAIM_FORM,
  }
}

export const setAddressValidationDetails = (payload) => {
  return {
    type: SET_ADDRESS_VALIDATION_DETAILS,
    payload,
  }
}

export const clearAddressValidationDetails = () => {
  return {
    type: CLEAR_ADDERSS_VALIDATION_DETAILS,
  }
}

export const setReplacementMatrixDetails = (payload) => {
  return {
    type: SET_REPLACEMENT_MATRIX_DETAILS,
    payload,
  }
}

export const setReplacementOption = (payload) => {
  return {
    type: SET_REPLACEMENT_OPTION,
    payload,
  }
}

export const setPlaceOfDamageLocation = (payload) => {
  return {
    type: SET_PLACE_OF_DAMAGE,
    payload,
  }
}

export const setConsentPaymentDetails = (payload) => {
  return {
    type: SET_CONSENT_PAYMENT_DETAILS,
    payload,
  }
}

// ------------------------------------
// Async Actions
// ------------------------------------
export const setDateAndTimeAsync = (obj) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      resolve(dispatch(setDateAndTime(obj)))
    })
  }
}

export const getServiceTypesList = () => {
  return (dispatch, getState) => {
    let requestReducer = getState().request
    let damageObj = (requestReducer.claimFormDetails.typesOfDamage || []).find((obj) => obj.isSelected) || {}
    let body = JSON.stringify({
      SoldPlanID: requestReducer.selectedDamageType.SoldPlanID,
      DamageTypeID: damageObj.id || 3,
    })
    return axios
      .post(api.coreUri + '/ServicetypeConfigurations/fetchServiceTypeDetails', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(err.message, 'error'))
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          return _.get(response, 'data.data.eligibleServiceTypes', [])
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const convertDropOffToPickupAsync = (requestObj) => {
  return (dispatch, getState) => {
    let body = JSON.stringify({
      ...requestObj.data,
    })
    return axios
      .post(api.coreUri + '/ConsumerServicerequest/convertDropOffToPickup', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(err.message, 'error'))
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          return response.data.data
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(err.message, 'error'))
          throw err
        }
      })
  }
}

export const uploadAdditionalDocsToDSAsync = (requestObj) => {
  return (dispatch, getState) => {
    let body = JSON.stringify(requestObj.uploadInfo)
    return axios
      .post(api.coreUri + '/ConsumerServicerequest/requestDocsForDS', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          return response.data.data
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const uploadAdditionalDocsAsync = (requestObj) => {
  return (dispatch, getState) => {
    let body = JSON.stringify(requestObj.uploadInfo)
    return axios
      .post(api.coreUri + '/ConsumerServicerequest/requestDocs', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          return response.data.data
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const fetchPickupInstructionsAsync = (requestObj) => {
  return (dispatch, getState) => {
    let body = JSON.stringify({
      ...requestObj.data,
    })
    return axios
      .post(api.coreUri + '/ConsumerServicerequest/serviceModeDetails', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(setPickUpInstructions(response.data.data))
        } else {
          let err = new Error(response.data.message)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const asyncClaimEligibility = (requestObj) => {
  return (dispatch, getState) => {
    let body = JSON.stringify({
      ...requestObj,
      CoverageTypes: [
        'ADLD',
        'EW',
        'THEFT',
        'REPLACEMENT',
        'SDP',
        'ADH',
        'BATTERY_REPLACE',
        'BACK_COVER',
        'ADLD_AND_THEFT',
        'SDP_AND_BACK_COVER',
        'IW',
        'THEFT_OR_LOSS',
        'PREVENTIVE_MAINTENANCE',
      ],
    })
    return axios
      .post(api.coreUri + '/Claim/checkClaimEligibility', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(setClaimEligibility(response.data.data))
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(err.message || getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

// onsite repair && Install or demo request
export const nonRepairRequestAsync = (requestObj) => {
  return (dispatch, getState) => {
    let body = JSON.stringify(requestObj)
    return axios
      .post(api.coreUri + '/ConsumerServicerequest/scheduleRequest', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(setToaster(getTranslationText('success', 'requestRaised'), 'success'))
          dispatch(setRequestDetails(response.data.data))
        } else {
          let err = new Error(response.data.message)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

const getLossDateTimeObj = (claimForm = {}) => {
  let dte = _.get(claimForm, 'deviceDamageDate', undefined)
  let tme = _.get(claimForm, 'deviceDamageTime.time', undefined)
  let lossDateTime = ''
  if (claimForm.dateOfTheft) {
    return undefined
  }
  if (!dte && !tme) {
    lossDateTime = moment().format('YYYY-MM-DD')
  } else if (dte && !tme) {
    lossDateTime = moment(dte).format('YYYY-MM-DD')
  } else if (tme && !dte) {
    lossDateTime = moment(tme, 'hh:mm a').format()
  } else {
    tme = new Date(tme)
    dte = new Date(dte)
    dte.setHours(tme.getHours())
    dte.setMinutes(tme.getMinutes())
    lossDateTime = moment(dte, 'YYYY-MM-DD hh:mm A').format()
  }
  return lossDateTime
}

const getselectedPlan = (path) => {
  let planDetails = _.get(path, 'selectedDamageType')
  return planDetails.PlanID ? planDetails : undefined
}

// carryIn normal request
export const carryInRequestAsync = (requestObj) => {
  return (dispatch, getState) => {
    const body = JSON.stringify(requestObj)
    return axios
      .post(api.coreUri + '/ConsumerServicerequest/carryInRequest', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(setToaster(getTranslationText('success', 'requestRaised'), 'success'))
          dispatch(setRequestDetails(response.data.data))
        } else {
          let err = new Error(response.data.msg || getTranslationText('error', 'somethingWentWrong'))
          dispatch(setToaster(err.message, 'error'))
          throw err
        }
      })
  }
}

// normal pickup request
export const pickUpRequestAsync = (requestObj) => {
  return (dispatch, getState) => {
    const body = JSON.stringify(requestObj)

    return axios
      .post(api.coreUri + '/ConsumerServicerequest/pickupRequestWithValidation', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(setToaster(getTranslationText('success', 'requestRaised'), 'success'))
          dispatch(setRequestDetails(response.data.data))
        } else {
          let err = new Error(response.data.msg || getTranslationText('error', 'somethingWentWrong'))
          dispatch(setToaster(err.message, 'error'))
          throw err
        }
      })
  }
}

export const doortoDoorReplacementAsync = (requestObj) => {
  return (dispatch, getState) => {
    let body = JSON.stringify(requestObj)
    return axios
      .post(api.coreUri + '/ReplacementRequest/createReplacementRequest', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(setToaster(getTranslationText('success', 'requestRaised'), 'success'))
          dispatch(setRequestDetails(response.data.data))
        } else {
          let err = new Error(response.data.msg || getTranslationText('error', 'somethingWentWrong'))
          dispatch(setToaster(err.message, 'error'))
          throw err
        }
      })
  }
}

const getClaimReqDetails = (requestReducer = {}) => {
  let damageObj = {}
  const selectedPlan = getselectedPlan(requestReducer)
  if (selectedPlan && _.get(selectedPlan, 'ClaimForm.RequiresTypeOfDamage')) {
    damageObj = _.get(requestReducer, 'claimFormDetails.typesOfDamage', false)
      ? _.get(requestReducer, 'claimFormDetails.typesOfDamage').find((obj) => obj.isSelected)
      : {}
  }
  const isTheft = _.get(requestReducer, 'claimFormDetails.incidentType', undefined) == 'Theft'
  const isLoss = _.get(requestReducer, 'claimFormDetails.incidentType', undefined) == 'Loss'
  return {
    DescriptionRecording: _.get(requestReducer, 'audioUploadInfo.audioUploadPath', undefined),
    lossDateTime: getLossDateTimeObj(requestReducer.claimFormDetails),
    damageId: damageObj.id || 0,
    descriptionOfLoss: requestReducer.claimFormDetails.deviceDamageDescp || undefined,
    locationOfDamage: isTheft || isLoss ? undefined : _.get(requestReducer, 'claimFormDetails.deviceDamagePlace', undefined),
    placeOfDamage: isLoss ? undefined : _.get(requestReducer, 'claimFormDetails.deviceDamagePlace.landmark', undefined),
    deviceSwitchOn: requestReducer.claimFormDetails.deviceOnState || undefined,
    placeOfDevice: requestReducer.claimFormDetails.deviceDamageLocation || undefined,
    reimbursementCost: requestReducer.claimFormDetails.deviceRepairAmount || undefined,
    dateOfRepair: requestReducer.claimFormDetails.devicerepairDate || undefined,
    repairInvoice: requestReducer.claimFormDetails.repairInvoiceUrl || undefined,
    userSignature: requestReducer.claimFormDetails.signImgUrl || undefined,
    policeReportDate: isLoss
      ? undefined
      : requestReducer.claimFormDetails.policeReportDate
      ? moment(requestReducer.claimFormDetails.policeReportDate).format('YYYY-MM-DD')
      : undefined,
    policeReportUrl: isLoss ? undefined : _.get(requestReducer, 'claimFormDetails.policeReportUrl', undefined),
    policeReportNumber: isLoss ? undefined : _.get(requestReducer, 'claimFormDetails.policeReportNumber', undefined),
    dateOfTheft: isLoss
      ? undefined
      : requestReducer.claimFormDetails.dateOfTheft
      ? moment(requestReducer.claimFormDetails.dateOfTheft).format('YYYY-MM-DD')
      : undefined,
    placeOfTheft: isLoss ? undefined : _.get(requestReducer, 'claimFormDetails.deviceDamagePlace.landmark', undefined),
    locationOfTheft: isTheft ? requestReducer.claimFormDetails.deviceDamagePlace : undefined,
    policeAgency: isTheft ? requestReducer.claimFormDetails.policeAgency : undefined,
    theftDetails: isTheft ? requestReducer.claimFormDetails.theftDetails : undefined,
    locationOfLoss: isLoss ? _.get(requestReducer, 'claimFormDetails.deviceDamagePlace', undefined) : undefined,
    placeOfLoss: isLoss ? _.get(requestReducer, 'claimFormDetails.deviceDamagePlace.landmark', undefined) : undefined,
    lossDetails: isLoss ? requestReducer.claimFormDetails.lossDetails : undefined,
    RequiresIDUrl: isLoss ? requestReducer.claimFormDetails.policeReportUrl : undefined,
    accountHolderFullName: isLoss ? requestReducer.claimFormDetails.userFullName : undefined,
    accountHolderEmailID: isLoss ? requestReducer.claimFormDetails.lossEmailId : undefined,
    accountHolderMobileNo: isLoss ? requestReducer.claimFormDetails.lossMobileNumber : undefined,
    accountHolderBillingAddress: isLoss ? requestReducer.claimFormDetails.lossBillingAddress : undefined,
    dateOfLoss:
      isLoss && requestReducer.claimFormDetails.dateOfLoss
        ? moment(requestReducer.claimFormDetails.dateOfLoss).format('YYYY-MM-DD')
        : undefined,
    incidentType: requestReducer.claimFormDetails.incidentType || undefined,
    IncidentTypeID: requestReducer.claimFormDetails.IncidentTypeID || undefined,
    Issues:
      _.get(requestReducer, 'selectedIssues') && _.get(requestReducer, 'selectedIssues').length
        ? requestReducer.selectedIssues.filter((issues) => issues.isSelected)
        : undefined,
  }
}

export const createRequestAsync = (requestObj) => {
  // for auto approval flow, for dropoff & for self repair (TapSafe)

  try {
    return (dispatch, getState) => {
      let requestReducer = getState().request
      const damageId_ = _.get(getState(), 'track.requestDetails.damage[0].damageId', 0)
      let claimConsents = _.get(getState(), 'user.claimLegalConfigs', [])

      let isThumbEWCoverage =
        ['ThumbUS-Web'].includes(_.get(getState(), 'user.appConfigDetails.app', '')) &&
        [
          _.get(getState(), 'request.selectedDamageType.CoverageType', ''),
          _.get(getState(), 'track.trackDetails.coverageType', ''),
        ].includes('EW')

      let consents_array = []
      if (claimConsents.LegalConfigs && claimConsents.LegalConfigs.length) {
        claimConsents = claimConsents.LegalConfigs
        consents_array = claimConsents
          .filter((consent) => consent.Active)
          .map((consent) => {
            let isAffidavit = _.includes(['ACKNOWLEDGEMENT'], consent?.legalConfig?.LegalConfigCode)
            let attributes = []
            const isLoss = _.get(requestReducer, 'claimFormDetails.incidentType', undefined) == 'Loss'
            attributes.push({
              AttributeType: legalConfigAttributes.imei_serial,
              AttributeValue:
                _.get(requestReducer, 'selectedDeviceInfo.DownloadedDeviceUniqueKey') ||
                _.get(requestReducer, 'selectedDeviceInfo.ProductUniqueID'),
            })
            if (isAffidavit && isLoss) {
              attributes.push(
                {
                  AttributeType: 'date',
                  AttributeValue: moment().format('DD-MM-YYYY'),
                },
                {
                  AttributeType: 'fullName',
                  AttributeValue: _.get(requestReducer, 'claimFormDetails.userFullName'),
                }
              )
            }
            return {
              ...consent,
              ApplicationLegalConfigID: consent.ApplicationLegalConfigID,
              ConsumerID: _.get(getState(), 'user.userDetails.consumerID'),
              ConsentText: _.get(consent, 'legalConfig.Text', consent?.Text || consent?.ConsentText),
              timestamp: moment().format('MM ddd, YYYY hh:mm:ss a'),
              Attributes: attributes,
              LegalConfigCode: _.get(consent, 'legalConfig.LegalConfigCode', '') || consent?.LegalConfigCode,
            }
          })
      }
      consents_array = _.compact(consents_array)
      // let addressObj = getState().user.locations[0]
      const selectedPlan = getselectedPlan(requestReducer)
      const hasClaimForm = selectedPlan && selectedPlan.RequiresClaimForm
      const dateOfDamage = _.get(getState(), 'request.claimFormDetails.deviceDamageDate') || undefined
      let deiliveryStoreAddress = _.get(requestReducer, 'partnerServiceLocation.address', undefined)
      if (_.includes([66], _.get(requestReducer, 'selectedMode.ServiceTypeID', ''))) {
        let address, City, State, Zipcode
        address = _.get(requestReducer, 'partnerServiceLocation.address', undefined)
        City = _.get(requestReducer, 'partnerServiceLocation.City', undefined)
        State = _.get(requestReducer, 'partnerServiceLocation.State', undefined)
        Zipcode = _.get(requestReducer, 'partnerServiceLocation.Zipcode', undefined)
        deiliveryStoreAddress = `${address}, ${City}, ${State}, ${Zipcode}`
      }

      let body = JSON.stringify({
        ConsumerID: getState().user.consumerDetails.data.consumerID,
        ConsumerProductID: _.get(requestReducer, 'selectedDeviceInfo.ConsumerProductID'),
        PaymentID: _.get(getState(), 'payment.chargePaymentId', requestObj.PaymentID),
        PaymentProcessID: requestObj.PaymentProcessID,
        ServiceTypeID: selectedPlan ? 10 : undefined,
        // request.ServiceTypeID For tapSafe
        IncidentDate: hasClaimForm ? moment(dateOfDamage).format('YYYY-MM-DD') + 'T00:00:00.000Z' : undefined,
        RequiresClaimForm: hasClaimForm,
        PlanID: selectedPlan && selectedPlan.PlanID,
        SoldPlanID: selectedPlan && selectedPlan.SoldPlanID,
        SoldPlanCoverageID: selectedPlan && selectedPlan.SoldPlanCoverageID,
        PlanCoverageID: selectedPlan && selectedPlan.PlanCoverageID,
        consumerServiceRequestDetails: hasClaimForm
          ? getClaimReqDetails(requestReducer)
          : {
              damageId: damageId_,
            },
        DecisionMatrix: requestObj.DecisionMatrix || undefined,
        SkipClaimApproval: requestObj.SkipClaimApproval || undefined,
        CountryCode: getState().user.selectedCountryData.CountryCode,
        CountryID: getState().user.selectedCountryData.CountryID,
        skipRaiseClaim: requestObj.skipRaiseClaim,
        ConsumerServiceRequestID: requestObj.ConsumerServiceRequestID,
        ConsumerServiceRequest: {
          ClientServiceLocationDetails: requestObj.ClientServiceLocationID
            ? {
                ClientServiceLocationID: requestObj.ClientServiceLocationID,
                ServiceLocationName: _.get(getState(), 'request.partnerServiceLocation.ServiceLocationName', undefined),
                address: deiliveryStoreAddress,
                ContactNo1: _.get(getState(), 'request.partnerServiceLocation.ContactNo1', undefined),
              }
            : undefined,
          ...requestObj,
          skipRaiseClaim: undefined,
          ConsumerServiceRequestID: undefined,
          CustomerCurrentLat: undefined,
          CustomerCurrentLng: undefined,
          CardHold:
            isThumbEWCoverage || _.get(getState(), 'payment.isCardHoldSkipped', false)
              ? undefined
              : !_.get(getState(), 'payment.holdAmount.isHoldAmount', undefined)
              ? false
              : true,
          Consent: (consents_array.length && consents_array) || [],
          ExternalTimezoneString: _.get(getState(), 'request.partnerServiceLocation.TimezoneString', undefined),
          isCardHoldSkipped: _.get(getState(), 'payment.isCardHoldSkipped', undefined),
        },
        Consent:
          (!_.get(requestReducer, 'selectedMode.ServiceTypeID', undefined) && consents_array.length && consents_array) ||
          _.get(getState(), 'request.consentCoverageDetails', []),
        CustomerCurrentLat: requestObj.CustomerCurrentLat || undefined,
        CustomerCurrentLng: requestObj.CustomerCurrentLng || undefined,
        HoldAmount: _.get(getState(), 'payment.holdAmount', undefined),
        chargeMaxDeductible: _.get(requestReducer, 'consentPaymentDetails.chargeMaxDeductible', undefined),
        tokenizeFlow: _.get(requestReducer, 'consentPaymentDetails.tokenizeFlow', undefined),
      })
      return axios
        .post(api.coreUri + '/ConsumerServicerequest/createRequest', body, {
          headers: {
            Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
            customHeader: true,
          },
        })
        .catch((err) => {
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          // console.log(err)
          throw err
        })
        .then((response) => {
          if (response.status === 200 && response.data.success) {
            dispatch(setToaster(getTranslationText('success', 'requestRaised'), 'success'))
            dispatch(setRequestDetails(response.data.data))
            return response.data
          } else {
            let err = new Error(response.data.msg)
            dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
            throw err
          }
        })
    }
  } catch (err) {
    console.log('error:', err)
  }
}

export const raiseNewClaimAsync = (requestObject = {}) => {
  return (dispatch, getState) => {
    let requestReducer = getState().request
    const selectedPlan = getselectedPlan(requestReducer)
    let claimConsents = _.get(getState(), 'user.claimLegalConfigs', [])
    let consents_array = []
    if (claimConsents.LegalConfigs && claimConsents.LegalConfigs.length) {
      claimConsents = claimConsents.LegalConfigs
      consents_array = claimConsents
        .filter((consent) => consent.Active)
        .map((consent) => {
          let isAffidavit = _.includes(['ACKNOWLEDGEMENT'], consent?.legalConfig?.LegalConfigCode)
          let attributes = []
          const isLoss = _.get(requestReducer, 'claimFormDetails.incidentType', undefined) == 'Loss'
          if (isLoss) {
            attributes.push({
              AttributeType: legalConfigAttributes.imei_serial,
              AttributeValue:
                _.get(requestReducer, 'selectedDeviceInfo.DownloadedDeviceUniqueKey') ||
                _.get(requestReducer, 'selectedDeviceInfo.ProductUniqueID'),
            })
            if (isAffidavit && isLoss) {
              attributes.push(
                {
                  AttributeType: 'date',
                  AttributeValue: moment().format('DD-MM-YYYY'),
                },
                {
                  AttributeType: 'fullName',
                  AttributeValue: _.get(requestReducer, 'claimFormDetails.userFullName'),
                }
              )
            }
            return {
              ...consent,
              ApplicationLegalConfigID: consent.ApplicationLegalConfigID,
              ConsumerID: _.get(getState(), 'user.userDetails.consumerID'),
              ConsentText: _.get(consent, 'legalConfig.Text', consent?.Text || consent?.ConsentText),
              Attributes: attributes,
              LegalConfigCode: _.get(consent, 'legalConfig.LegalConfigCode', consent?.LegalConfigCode),
            }
          } else {
            return {
              ...consent,
              ApplicationLegalConfigID: consent?.ApplicationLegalConfigID,
              ConsumerID: _.get(getState(), 'user.userDetails.consumerID'),
              ConsentText: _.get(consent, 'legalConfig.Text', consent?.Text || consent?.ConsentText),
              Attributes: [],
              LegalConfigCode: _.get(consent, 'legalConfig.LegalConfigCode', consent?.LegalConfigCode),
            }
          }
        })
    }
    consents_array = _.compact(consents_array)
    let body = JSON.stringify({
      ...requestObject,
      consumerServiceRequestDetails: getClaimReqDetails(requestReducer),
      ConsumerID: getState().user.consumerDetails.data.consumerID,
      ConsumerProductID: _.get(getState(), 'request.selectedDeviceInfo.ConsumerProductID'),
      ServiceTypeID: 10,
      SoldPlanID: selectedPlan && selectedPlan.SoldPlanID,
      Description: requestReducer.claimFormDetails.deviceDamageDescp,
      SoldPlanCoverageID: selectedPlan && selectedPlan.SoldPlanCoverageID,
      PlanCoverageID: selectedPlan && selectedPlan.PlanCoverageID,
      Consent: (consents_array.length && consents_array) || {},
    })
    return axios
      .post(api.coreUri + '/ConsumerServicerequest/raiseNewClaim', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          return response.data
        } else {
          let err = new Error(response.data.msg)
          console.log('err.......', err)
          dispatch(setToaster(err.message, 'error'))
          throw err
        }
      })
  }
}

export const scheduleClaimRepairAsync = (requestObj) => {
  return (dispatch, getState) => {
    let requestReducer = getState().request
    let claimConsents = _.get(getState(), 'user.claimLegalConfigs', [])
    let consents_array = []
    if (claimConsents.LegalConfigs && claimConsents.LegalConfigs.length) {
      claimConsents = claimConsents.LegalConfigs
      consents_array = claimConsents
        .filter((consent) => consent.Active)
        .map((consent) => {
          let isAffidavit = _.includes(['ACKNOWLEDGEMENT'], consent?.legalConfig?.LegalConfigCode)
          let attributes = []
          const isLoss = _.get(requestReducer, 'claimFormDetails.incidentType', undefined) == 'Loss'
          attributes.push({
            AttributeType: legalConfigAttributes.imei_serial,
            AttributeValue:
              _.get(requestReducer, 'selectedDeviceInfo.DownloadedDeviceUniqueKey') ||
              _.get(requestReducer, 'selectedDeviceInfo.ProductUniqueID'),
          })
          if (isAffidavit && isLoss) {
            attributes.push(
              {
                AttributeType: 'date',
                AttributeValue: moment().format('DD-MM-YYYY'),
              },
              {
                AttributeType: 'fullName',
                AttributeValue: _.get(requestReducer, 'claimFormDetails.userFullName'),
              }
            )
          }
          return {
            ...consent,
            ApplicationLegalConfigID: consent.ApplicationLegalConfigID,
            ConsumerID: _.get(getState(), 'user.userDetails.consumerID'),
            ConsentText: _.get(consent, 'legalConfig.Text', consent?.Text || consent?.ConsentText),
            timestamp: moment().format('MM ddd, YYYY hh:mm:ss a'),
            Attributes: attributes,
            LegalConfigCode: _.get(consent, 'legalConfig.LegalConfigCode', '') || consent?.LegalConfigCode,
          }
        })
    }
    consents_array = _.compact(consents_array)
    let body = JSON.stringify({
      ...requestObj,
      chargeMaxDeductible: _.get(requestReducer, 'consentPaymentDetails.chargeMaxDeductible', undefined),
      tokenizeFlow: _.get(requestReducer, 'consentPaymentDetails.tokenizeFlow', undefined),
      Consent: (consents_array.length && consents_array) || [],
    })
    return axios
      .post(api.coreUri + '/ConsumerServicerequest/scheduleRepair', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(setToaster(getTranslationText('success', 'requestRaised'), 'success'))
          dispatch(setRequestDetails(response.data.data))
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const scheduleNonClaimRepairAsync = (requestObj) => {
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/ConsumerServicerequest/raiseNormalRepair', requestObj, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(setToaster(getTranslationText('success', 'requestRaised'), 'success'))
          dispatch(setRequestDetails(response.data.data))
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const getGeoLocationAsync = (value, addressKey = 'userLocation') => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      const isAECountry = _.get(getState(), 'user.selectedCountryData.CountryCode').toLowerCase() == 'ae'
      let { lat, lng } = value.geometry.location
      let formattedAddress = value.formatted_address
      let searchAddressComponents = value.address_components
      let searchPostalCode = ''
      let addressCity = ''
      let stateCode = ''
      let state = ''

      let street_number = ''
      let route = ''
      let premise = ''
      let subpremise = ''

      searchAddressComponents.forEach((map) => {
        if (map.types[0] === 'street_number') {
          street_number = map.short_name
        }
        if (map.types[0] === 'route') {
          route = map.short_name
        }
        if (map.types[0] === 'premise') {
          premise = map.short_name
        }
        if (map.types[0] === 'subpremise') {
          subpremise = map.short_name
        }

        if (map.types[0] === 'postal_code') {
          searchPostalCode = map.short_name
        }
        if (!addressCity) {
          if (map.types[0] === 'locality') {
            addressCity = map.long_name
          } else if (map.types[0] === 'administrative_area_level_2') {
            addressCity = map.long_name
          } else if (map.types[0] === 'administrative_area_level_1') {
            addressCity = map.long_name
          }
        }

        if (map.types[0] === 'administrative_area_level_1') {
          state = map.long_name
          stateCode = map.short_name
        }
      })
      dispatch(
        setUserRequestLocation({
          key: addressKey,
          value: {
            latitude: lat(),
            longitude: lng(),
            landmark: formattedAddress,
            pincode: searchPostalCode,
            city: isAECountry ? fetchCity(addressCity) : addressCity,
            state,
            stateCode,
            UserLandmark: ['US'].includes(getState().user.selectedCountryData.CountryCode) ? `${street_number} ${route}` : undefined,
            Address: ['US'].includes(getState().user.selectedCountryData.CountryCode) ? `${premise} ${subpremise}` : undefined,
          },
        })
      )
      resolve()
    })
  }
}

export const getBrowserLocationAsync = () => {
  return (dispatch) => {
    return axios
      .post(`https://www.googleapis.com/geolocation/v1/geolocate?key=${GOOGLE_MAPS_GEOCODING_API_KEY}`)
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch(setBrowserRequestLocation(response.data))
        } else {
          let err = new Error('Get browser location failed')
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const getRequestModesAsync = (requestObj, AuthID, isDropoffCheck = false) => {
  return (dispatch) => {
    return axios
      .post(api.coreUri + '/ConsumerServicerequest/getServiceAvailabilityWithLocations', requestObj, {
        headers: {
          Authorization: AuthID,
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          if (!isDropoffCheck) {
            dispatch(setRequestModes(response.data.data))
            return response.data.data
          } else {
            return response.data.data
          }
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const getServiceAvailabilityResolutionRateAsync = (requestObj) => {
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/ConsumerServicerequest/getServiceAvailabilityResolutionRate', requestObj, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          let serviceLocations = _.get(getState(), 'request.requestModesData.serviceLocations', [])
          let serviceLocationsUpdatedData = serviceLocations.map((item) => {
            let newdata = response.data.data.find(
              (detail) =>
                (item.ClientServiceLocationID && item.ClientServiceLocationID == detail.ClientServiceLocationID) ||
                item.PartnerServiceLocationID == detail.PartnerServiceLocationID
            )
            newdata = Object.keys(newdata || {}).length ? newdata : { Score: 0 }
            return { ...newdata, ...item }
          })
          serviceLocationsUpdatedData = _.sortBy(serviceLocationsUpdatedData, ['Score']).reverse()
          dispatch(setAscLocationsData(serviceLocationsUpdatedData))
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const getRequestSlotsAsync = (requestObj) => {
  console.log('request slots---->', requestObj)
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/ConsumerServicerequest/getSlot', requestObj, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(setRequestSlots(response.data.data))
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const getTypeOfDamageAsync = (requestObj) => {
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/ConsumerServicerequestDetails/fetchDamageTypes', requestObj, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch(setTypesOfDamage(response.data.data))
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const fetchEligiblePlansNewAsync = (requestObj, AuthID) => {
  return (dispatch, getState) => {
    return axios
      .post(
        api.coreUri + '/ConsumerProduct/fetchCustomerPlans',
        {
          ...requestObj,
          AllowPlanRegistration: true,
          PlanType: ['Protect', 'Secure', 'Combo'],
          action: 'ActivationWithClaimRaise',
          CheckCoverageBasedRaiseClaim: true,
          ConsumerID: getState().user.consumerDetails.data.consumerID,
        },
        {
          headers: {
            Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
            customHeader: true,
          },
        }
      )
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          let isActivationEnabled = _.get(getState(), 'user.appConfigDetails.showActivateOption')
          let plans = response.data.data
          if (plans && !isActivationEnabled) {
            plans = plans.filter((plan) => plan.GroupStatus === 1)
          }
          dispatch(setEligiblePlansNew(plans))
        } else {
          let err = new Error(response.data.msg || getTranslationText('error', 'somethingWentWrong'))
          dispatch(setToaster(err.message, 'error'))
          throw err
        }
      })
  }
}

export const getRequestIssuesForTypeAsync = (requestObj) => {
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/Issuemaster/getIssuesList', requestObj, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200) {
          // let issueList = []
          // // debugger
          // response.data.data.map((list) => {
          //   issueList.push(...list.Issues)
          // })
          // console.log('list: ', issueList)
          dispatch(setRequestIssuesForType(response.data.data))
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const updateServiceLocationAsync = (requestObj) => {
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/ConsumerServicerequest/updateServiceLocation', requestObj, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status !== 200 || !response.data.success) {
          // let issueList = []
          // // debugger
          // response.data.data.map((list) => {
          //   issueList.push(...list.Issues)
          // })
          // console.log('list: ', issueList)
          let err = new Error(response.data.msg)
          dispatch(setToaster(err.message, 'error'))
          throw err
        } else {
          return true
        }
      })
  }
}

export const activatePlanAsync = (requestObj) => {
  return (dispatch, getState) => {
    let body = JSON.stringify(requestObj)
    return axios
      .post(api.coreUri + '/ConsumerProduct/verifyAndUpdateSoldPlan', body, {
        headers: {
          Authorization: getState().user.consumerDetails.data.accessToken.ID,
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status !== 200 || !response.data.success) {
          // let issueList = []
          // // debugger
          // response.data.data.map((list) => {
          //   issueList.push(...list.Issues)
          // })
          // console.log('list: ', issueList)
          let err = new Error(response.data.msg)
          dispatch(setToaster(err.message, 'error'))
          throw err
        } else {
          dispatch(setToaster(response.data.msg, 'success'))
          dispatch(activatePlan(response.data.data))
          return response.data
        }
      })
  }
}

export const fetchReturnReasons = (reqObj) => {
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/ConsumerProduct/getReturnReasons', reqObj, {
        headers: {
          Authorization: getState().user.consumerDetails.data.accessToken.ID,
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        throw err
      })
      .then((response) => {
        if (response.status !== 200 || !response.data.success) {
          let err = new Error(response.data.msg)
          dispatch(setToaster(err.message, 'error'))
          throw err
        } else {
          dispatch(setProductReturnReasons(response.data.data))
          return response.data
        }
      })
  }
}

export const getHandlingCharges = (reqObj) => {
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/CarrierIntegration/calculateHandlingCharges', reqObj, {
        headers: {
          Authorization: getState().user.consumerDetails.data.accessToken.ID,
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        throw err
      })
      .then((response) => {
        if (response.status !== 200 || !response.data.success) {
          let err = new Error(response.data.msg)
          dispatch(setToaster(err.message, 'error'))
          throw err
        } else {
          dispatch(setHandlingCharges(response.data.data.handlingCharges))
          return response.data
        }
      })
  }
}

export const routeServiceRequestAsync = (requestObj) => {
  // Vodafone specific
  return (dispatch, getState) => {
    let isCourierType = [51, 52, 48].includes(parseInt(getState().request.selectedMode.ServiceTypeID))
    let isDoorToDoorType = [40, 42].includes(parseInt(getState().request.selectedMode.ServiceTypeID))
    let srcLocationDetails = {}
    let reqDetails = getState().request
    let DeliveryMode, VendorName
    if (isCourierType) {
      srcLocationDetails = _.get(reqDetails, 'requestModesData.selfCourierServiceLocations[0]', {})
    } else if (isDoorToDoorType) {
      DeliveryMode = _.get(reqDetails, 'requestModesData.doorToDoorDetail.DeliveryMode', undefined)
      VendorName = _.get(reqDetails, 'requestModesData.doorToDoorDetail.VendorName', undefined)
      srcLocationDetails = _.get(reqDetails, 'requestModesData.doorToDoorDetail', {})
    } else {
      DeliveryMode = _.get(reqDetails, 'requestModesData.DeliveryMode', undefined)
      VendorName = _.get(reqDetails, 'requestModesData.VendorName', undefined)
      srcLocationDetails = {
        PartnerID: reqDetails.requestModesData.PartnerID,
        PartnerServiceLocationID: reqDetails.requestModesData.PartnerServiceLocationID,
      }
      if (_.get(reqDetails, 'requestModesData.isChooseLogisticsPartner')) {
        DeliveryMode = _.get(reqDetails, 'selectedVendor.VendorID', undefined)
        VendorName = _.get(reqDetails, 'selectedVendor.VendorName', undefined)
      }
    }

    let ServiceTypeID = getState().request.selectedMode.ServiceTypeID
    switch (ServiceTypeID) {
      case 1:
        ServiceTypeID = 11
        break
      case 2:
        ServiceTypeID = 12
        break
    }
    // let vodafonePlanID
    // let vodafoneSoldPlanID
    let { PlanID, SoldPlanID } = getState().request.selectedDamageType
    let body = JSON.stringify({
      Address: getState().request.userLocation.Address,
      AddressType: getState().request.userLocation.AddressType,
      Landmark: getState().request.userLocation.landmark,
      Landmark: _.get(getState(), 'request.userLocation.landmark') || _.get(getState(), 'request.userLocation.Landmark'),
      City: _.get(getState(), 'request.userLocation.city', undefined) || _.get(getState(), 'request.userLocation.City', undefined),
      State: _.get(getState(), 'request.userLocation.state', undefined) || _.get(getState(), 'request.userLocation.State', undefined),
      StateID: _.get(getState(), 'request.userLocation.StateID'),
      Lat: (_.get(getState(), 'request.userLocation.latitude') || _.get(getState(), 'request.userLocation.Lat')).toString(),
      Lng: (_.get(getState(), 'request.userLocation.longitude') || _.get(getState(), 'request.userLocation.Lng')).toString(),
      ScheduledDateTime: !isCourierType ? getState().request.dateAndTime.date.date : moment().format('YYYY-MM-DD'),
      ScheduledFromTime: !isCourierType ? getState().request.dateAndTime.time.StartTimeVal : moment().format('HH:mm:ss'),
      ScheduledToTime: !isCourierType ? getState().request.dateAndTime.time.EndTimeVal : moment().format('HH:mm:ss'),
      ConsumerServiceRequestID: parseInt(getState().request.selectedDeviceInfo.ConsumerServiceRequestID),
      ProductSubCategoryID: getState().request.selectedDeviceInfo.ProductSubCategoryID,
      ServiceTypeID,
      DeliveryMode,
      Zipcode: _.get(getState(), 'request.userLocation.pincode') || _.get(getState(), 'request.userLocation.PinCode'),
      IsUnderWarranty: getState().request.selectedDeviceInfo.IsUnderWarranty,
      ConsumerID: getState().request.selectedDeviceInfo.ConsumerID,
      ConsumerProductID: getState().request.selectedDeviceInfo.ConsumerProductID,
      BrandID: getState().request.selectedDeviceInfo.BrandID,
      PartnerID: srcLocationDetails.PartnerID || undefined,
      PartnerServiceLocationID: srcLocationDetails.PartnerServiceLocationID || undefined,
      ProductUniqueID: getState().request.selectedDeviceInfo.ProductUniqueID,
      ConsumerFavouriteLocationID: _.get(getState(), 'request.userLocation.ConsumerFavouriteLocationID', undefined),
      deliveryAddress: !isCourierType && {
        ..._.omit(getState().request.deliveryLocation, [
          'header',
          'originSite',
          'timezone',
          'x-client-ip',
          'isInternalModule',
          'StateMeta',
        ]),
        Landmark:
          _.get(getState(), 'request.deliveryLocation.landmark', undefined) || _.get(getState(), 'request.deliveryLocation.Landmark'),
        Lat: (_.get(getState(), 'request.deliveryLocation.latitude') || _.get(getState(), 'request.deliveryLocation.Lat')).toString(),
        Lng: (_.get(getState(), 'request.deliveryLocation.longitude') || _.get(getState(), 'request.deliveryLocation.Lng')).toString(),
        Zipcode: _.get(getState(), 'request.deliveryLocation.pincode') || _.get(getState(), 'request.deliveryLocation.PinCode'),
        City: _.get(getState(), 'request.deliveryLocation.city', undefined) || _.get(getState(), 'request.deliveryLocation.City'),
        State:
          _.get(getState(), 'request.deliveryLocation.state', undefined) || _.get(getState(), 'request.deliveryLocation.State', undefined),
        Address: _.get(getState().request, 'deliveryLocation.Address'),
        StateID: _.get(getState(), 'request.deliveryLocation.StateID', undefined),
        ConsumerFavouriteLocationID:
          _.get(getState(), 'request.deliveryLocation.ConsumerFavouriteLocationID', undefined) ||
          _.get(getState(), 'request.userLocation.ConsumerFavouriteLocationID', undefined),
      },
      VendorName,
      IsClaimRequest: 1,
      PlanID,
      ServiceRequestType: 'Now',
      IsNonPartner: 0,
      // SKU: (getState().request.vodafone.benefitIdentifier === 'UPGRADE')
      //   ? getState().request.vodafone.selectedVariant.SKU
      //   : undefined,
      // shopifyProductID: (getState().request.vodafone.benefitIdentifier === 'UPGRADE')
      //   ? getState().request.vodafone.selectedVariant.MasterProductID
      //   : undefined, // upgrade only
      IsUrgent: 0,
      SoldPlanID,
      // productVariantID: (getState().request.vodafone.benefitIdentifier === 'UPGRADE')
      //   ? getState().request.vodafone.selectedVariant.ProductVariantID
      //   : undefined, // upgrade only,
      PaymentID: getState().payment.paymentID,
      PaymentProcessID: getState().payment.payableDetails.id,
      Amount: getState().payment.payableDetails.amount,
      LanguageCode: getState().user.LanguageCode,
      // IsScreenDamaged: true,
      PhoneCode: getState().user.selectedCountryData.PhoneCode,
      DoorToDoorPincodeMappingID: isDoorToDoorType
        ? getState().request.requestModesData.doorToDoorDetail.DoorToDoorPincodeMappingID
        : undefined, // Door-to-door only
    })
    return axios
      .post(api.coreUri + '/ConsumerServicerequest/routeServiceRequest', body, {
        headers: {
          Authorization: getState().user.consumerDetails.data.accessToken.ID,
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(setToaster(getTranslationText('success', 'requestRaised'), 'success'))
          dispatch(setRequestDetails(response.data.data))
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

// for cooling period, fetching device plans details
export const getConsumerPlanDetailsAsync = (requestObj, AuthID) => {
  return (dispatch, getState) => {
    let body = JSON.stringify(requestObj)
    return axios
      .post(api.coreUri + '/CarrierIntegration/getDetailsForConsumer', body, {
        headers: {
          Authorization: AuthID,
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster('Something went wrong', 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(setDevicePlanDetails(response.data.data))
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(response.data.msg, 'error'))
          throw err
        }
      })
  }
}

export const checkClaimStatusAsync = () => {
  return (dispatch, getState) => {
    let requestReducer = getState().request

    let body = JSON.stringify({
      consumerServiceRequestDetails: getClaimReqDetails(requestReducer),
      ConsumerID: getState().user.consumerDetails.data.consumerID,
      ConsumerProductID: _.get(getState(), 'request.selectedDeviceInfo.ConsumerProductID'),
      SoldPlanID: requestReducer.selectedDamageType.SoldPlanID,
      ServiceTypeID: 10,
      SoldPlanCoverageID: _.get(getState(), 'request.selectedDamageType.SoldPlanCoverageID'),
    })
    return axios
      .post(api.coreUri + '/Claim/checkAndRejectClaim', body, {
        headers: {
          Authorization: getState().user.consumerDetails.data.accessToken.ID,
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster('Something went wrong', 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          return response.data
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(response.data.msg, 'error'))
          throw err
        }
      })
  }
}

export const checkDecisionMatrixAsync = (requestObj) => {
  return (dispatch, getState) => {
    let body = JSON.stringify(requestObj)
    return axios
      .post(api.coreUri + '/ReplacementRequest/getValueOfDecisionMatrix', body, {
        headers: {
          Authorization: getState().user.consumerDetails.data.accessToken.ID,
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster('Something went wrong', 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(setDecisionMatrixDetails(response.data.data))
          return response.data
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(response.data.msg, 'error'))
          throw err
        }
      })
  }
}

export const getServicetypeDetailsAsync = (requestObj) => {
  return (dispatch, getState) => {
    let body = JSON.stringify(requestObj)
    return axios
      .post(api.coreUri + '/ConsumerServiceRequest/getServicetypeDetails', body, {
        headers: {
          Authorization: getState().user.consumerDetails.data.accessToken.ID,
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster('Something went wrong', 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(setServicetypeDetailsInstruction(response.data.data))
          return response.data
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(response.data.msg, 'error'))
          throw err
        }
      })
  }
}

export const getAdminFeeDetails = (requestObj) => {
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/ConsumerServicerequest/getAdvanceDetails', requestObj, {
        headers: {
          Authorization: getState().user.consumerDetails.data.accessToken.ID,
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(setAdminFees(response.data.data))
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const getPayoutModesAsync = (requestObj) => {
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/PayoutPayment/getPayoutModes', requestObj, {
        headers: {
          Authorization: getState().user.consumerDetails.data.accessToken.ID,
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(setPaymentModes(response.data.data))
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const checkForHighRiskRequestAsync = (requestObj) => {
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/ConsumerServicerequest/checkForHighRiskRequest', requestObj, {
        headers: {
          Authorization: getState().user.consumerDetails.data.accessToken.ID,
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          return response.data.data
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const getEmptyBoxServiceAvailabilityAsync = (requestObj) => {
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/ConsumerServicerequest/getEmptyBoxServiceAvailability', requestObj, {
        headers: {
          Authorization: getState().user.consumerDetails.data.accessToken.ID,
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(setDropoffEmptyBoxflag(response.data.data))
          return response.data.data
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const getAEWithoutDefectiveServiceAvailability = (requestObj) => {
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/ConsumerServicerequest/getAEWithoutDefectiveServiceAvailability', requestObj, {
        headers: {
          Authorization: getState().user.consumerDetails.data.accessToken.ID,
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(setAdvanceExchangeDetails(response.data.data))
          return response.data.data
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const validateAddressAsync = (requestObj) => {
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/ConsumerServicerequest/verifyAddress', requestObj, {
        headers: {
          Authorization: getState().user.consumerDetails.data.accessToken.ID,
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(setAddressValidationDetails(response.data.data))
          return response.data.data
        } else {
          let err = new Error(response.data.msg)
          throw err
        }
      })
  }
}

export const getReplacementOptionsAsync = (requestObj) => {
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/ConsumerServicerequest/getReplacementOptions', requestObj, {
        headers: {
          Authorization: getState().user.consumerDetails.data.accessToken.ID,
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(setReplacementMatrixDetails(response.data.data))
          dispatch(setReplacementOption({}))
          return response.data.data
        } else {
          let err = new Error(response.data.msg)
          throw err
        }
      })
  }
}

export const getSameDayReplacementOptionsAsync = (requestObj) => {
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/ConsumerServicerequest/getCollectionServiceableLocation', requestObj, {
        headers: {
          Authorization: getState().user.consumerDetails.data.accessToken.ID,
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(setReplacementMatrixDetails(response.data.data))
          dispatch(setReplacementOption({}))
          return response.data.data
        } else {
          let err = new Error(response.data.msg)
          throw err
        }
      })
  }
}

export const loggerActionAsync = (requestObj) => {
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/Prime/collect', requestObj, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID', undefined),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        throw err
      })
      .then(() => {
        return
      })
  }
}

export const updateServiceAvailabilityAsync = (requestObj) => {
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/ConsumerServicerequest/updateServiceAvailability', requestObj, {
        headers: {
          Authorization: getState().user.consumerDetails.data.accessToken.ID,
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          return response.data.data
        } else {
          let err = new Error(response.data.msg)
          throw err
        }
      })
  }
}

export const getPaymentActionsAsync = (requestObj) => {
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/ConsumerServicerequest/getPaymentActions', requestObj, {
        headers: {
          Authorization: getState().user.consumerDetails.data.accessToken.ID,
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(setConsentPaymentDetails(response.data.data))
          return response.data.data
        } else {
          let err = new Error(response.data.msg)
          throw err
        }
      })
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SET_USER_REQUEST_LOCATION]: (state, action) => ({
    ...state,
    [action.payload.key]: action.payload.value,
  }),
  [SET_BROWSER_REQUEST_LOCATION]: (state, action) => ({
    ...state,
    browserLocation: action.payload,
  }),
  [SET_REQUEST_MODES]: (state, action) => ({
    ...state,
    requestModesData: {
      // ContactDetails: state.requestModesData && state.requestModesData.ContactDetails,
      ...action.payload,
    },
  }),
  [SET_ASC_LOCATIONS]: (state, action) => ({
    ...state,
    requestModesData: {
      ...state.requestModesData,
      serviceLocations: action.payload,
    },
  }),
  [SET_REQUEST_SLOTS]: (state, action) => ({
    ...state,
    requestSlotsData: action.payload,
  }),
  [SET_PARTNER_SERVICE_LOCATION]: (state, action) => ({
    ...state,
    partnerServiceLocation: action.payload,
  }),
  [SET_SELECTED_MODE]: (state, action) => ({
    ...state,
    selectedMode: action.payload,
  }),
  [SET_DATE_AND_TIME]: (state, action) => ({
    ...state,
    dateAndTime: action.payload,
  }),
  [SET_REQUEST_DAMAGE_TYPES]: (state, action) => ({
    ...state,
    requestDamageTypes: action.payload,
  }),
  [SET_ELIGIBLE_PLANS_NEW]: (state, action) => ({
    ...state,
    eligiblePlansNew: action.payload,
  }),
  [SET_SELECTED_DAMAGE_TYPE]: (state, action) => ({
    ...state,
    selectedDamageType: action.payload,
  }),
  [SET_REQUEST_ISSUES_FOR_TYPE]: (state, action) => ({
    ...state,
    requestIssuesForType: action.payload,
  }),
  [SET_SELECTED_DEVICE_HANDLER]: (state, action) => ({
    ...state,
    selectedDeviceInfo: action.payload,
  }),
  [CLEAR_REQUEST_STATE]: (state) => ({
    browserLocation: state.browserLocation,
    activationPlanDetails: state.activationPlanDetails,
    requestModesData: undefined,
    selectedDeviceInfo: undefined,
    source: undefined,
  }),
  [CLEAR_DEVICE_INFO]: (state) => ({
    browserLocation: state.browserLocation,
    requestModesData: {},
    selectedDeviceInfo: state.selectedDeviceInfo,
    selectedOrderDetails: state.selectedOrderDetails,
    source: state.source,
  }),
  [SET_ISSUES]: (state, action) => ({
    ...state,
    selectedIssues: action.payload,
  }),
  [SET_REQUEST_DETAILS]: (state, action) => ({
    ...state,
    raisedRequest: action.payload,
  }),
  [CLEAR_FULL_STATE]: (state) => ({
    browserLocation: state.browserLocation,
    selectedDeviceInfo: state.selectedDeviceInfo,
    requestModesData: {},
    newAddressFlgStore: state.newAddressFlgStore,
  }),
  [SET_ADDRESS_FLG]: (state, action) => ({
    ...state,
    newAddressFlgStore: action.payload,
  }),
  [CLEAR_DT_SLOTS]: (state) => ({
    ...state,
    partnerServiceLocation: undefined,
    requestSlotsData: undefined,
    dateAndTime: undefined,
  }),
  [SET_LOGISTIC_PARTNER]: (state, action) => ({
    ...state,
    selectedVendor: action.payload,
  }),
  [SET_PICK_UP_INSTR]: (state, action) => ({
    ...state,
    pickupInstructionsList: action.payload,
  }),
  [SET_CLAIM_ELIGIBLE]: (state, action) => ({
    ...state,
    ClaimEligibilityList: action.payload,
  }),
  [SET_REQUEST_FLOW_SOURCE]: (state, action) => ({
    ...state,
    source: action.payload,
  }),
  [REMOVE_VISTED_URL]: (state) => {
    let list = state.urlList
    if ((state.urlList || []).length) {
      list.pop()
    }
    return { ...state, urlList: list }
  },
  [VISITED_URL]: (state, action) => {
    let list = state.urlList || []
    list.push(action.payload)
    return {
      ...state,
      urlList: list,
    }
  },
  [CLEAR_VISITED_URL_LIST]: (state) => ({
    ...state,
    urlList: undefined,
  }),
  [SET_INVOICEDOC_VISIBILITY_STATE]: (state, action) => ({
    ...state,
    docAlreadyAdded: action.payload,
  }),
  [SET_CALIM_FORM_DETAILS]: (state, action) => ({
    ...state,
    claimFormDetails: { ...action.payload },
  }),
  [SET_ACTIVATION_PLAN]: (state, action) => ({
    ...state,
    activationPlanDetails: action.payload,
  }),
  [ACTIVATE_PLAN]: (state, action) => ({
    ...state,
    selectedDamageType: {
      ...state.activationPlanDetails,
      ...action.payload[0].data,
    },
  }),
  [SET_ADDITIONAL_INFO]: (state, action) => ({
    ...state,
    additionalUploadInfo: action.payload,
  }),
  [SET_TYPES_OF_DAMAGE]: (state, action) => ({
    ...state,
    typesOfDamage: action.payload,
  }),
  [SET_SELECTED_ORDER_DETAILS]: (state, action) => ({
    ...state,
    selectedOrderDetails: action.payload,
  }),
  [SET_RETURN_REASON]: (state, action) => ({
    ...state,
    returnReasons: action.payload.returnReasons,
  }),
  [SET_HANDLING_CHARGES]: (state, action) => ({
    ...state,
    requesthandlingCharges: action.payload,
  }),
  [SET_REQUEST_PLAN_DETAILS]: (state, action) => ({
    ...state,
    coolingOffPlansDetails: action.payload,
  }),
  [SET_DECISION_MATRIX_DETAILS]: (state, action) => ({
    ...state,
    decisionMatrixDetails: action.payload,
  }),
  [SET_MEDIA_CLAIM_INSTRUCTIONS_DETAILS]: (state, action) => ({
    ...state,
    mediaInstructionsDetails: action.payload,
  }),
  [SET_ADMIN_FEES]: (state, action) => ({
    ...state,
    adminFeeDetails: action.payload,
  }),
  [SET_BUYOUT_MODES]: (state, action) => ({
    ...state,
    buyoutModesDetails: action.payload,
  }),
  [SET_USER_BUYOUT_DETAILS]: (state, action) => ({
    ...state,
    userBuyoutDetails: action.payload,
  }),
  [SET_ADDRESS_PREVIEW_DETAILS]: (state, action) => ({
    ...state,
    [action.payload.key]: action.payload,
  }),
  [SET_DROPOFF_EMPTY_BOX_DETAILS]: (state, action) => ({
    ...state,
    emptyBoxServiceable: action.payload,
  }),
  [SET_ADVANCE_EXCHANGE_DETAILS]: (state, action) => ({
    ...state,
    advanceExchangeDetails: action.payload,
  }),
  [CLEAR_CLAIM_FORM]: (state) => ({
    ...state,
    claimFormDetails: {},
  }),
  [SET_ADDRESS_VALIDATION_DETAILS]: (state, action) => ({
    ...state,
    addrValidationDetails: action.payload,
  }),
  [CLEAR_ADDERSS_VALIDATION_DETAILS]: (state) => ({
    ...state,
    addrValidationDetails: {},
  }),
  [SET_REPLACEMENT_MATRIX_DETAILS]: (state, action) => ({
    ...state,
    replacementMatrixDetails: action.payload,
  }),
  [SET_REPLACEMENT_OPTION]: (state, action) => ({
    ...state,
    selectedReplacementOption: action.payload,
  }),
  [SET_AUDIO_INFO]: (state, action) => ({
    ...state,
    audioUploadInfo: action.payload,
  }),
  [SET_PLACE_OF_DAMAGE]: (state, action) => ({
    ...state,
    address_placeOfDamage: action.payload,
  }),
  [SET_RETRY_PAYMENT_STATUS]: (state, action) => ({
    ...state,
    retryPaymentStatus: action.payload,
  }),
  [SET_HOLD_PAYMENT_STATUS]: (state, action) => ({
    ...state,
    retryHoldPaymentStatus: action.payload,
  }),
  [SET_CONSENT_PAYMENT_DETAILS]: (state, action) => ({
    ...state,
    consentPaymentDetails: action.payload,
  }),
  [SET_COVERAGE_CONSENT_DETAILS]: (state, action) => ({
    ...state,
    consentCoverageDetails: action.payload,
  }),
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  requestModesData: {},
}

export default function requestReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
