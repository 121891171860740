import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import {
  Heading,
  SubHeading,
  MediumButton,
  BoxImagesComponent,
  InputComponent,
  MapComponent,
  RadioBtnPills,
  CustomIconComponent,
  CustomRadioComponent,
} from '../../../components/UIComponents'
import { Dropdown, Modal, Radio } from 'sfy-react'
const dummyUsrIcon = '/commonAssets/user_img.png'
import { getTranslationText } from '../../../utils/languageTranslation.js'
import _ from 'lodash'
import { getLocaleCurrency } from '../../../utils/locale'
import moment from 'moment'
import { ReactComponent as AddSvg } from '../../../../public/commonAssets/add-plus.svg'
import { ReactComponent as Email } from '../../../../public/commonAssets/email-web.svg'
import { ReactComponent as DownloadSvg } from '../../../../public/commonAssets/download-file.svg'
import { ReactComponent as FileSvg } from '../../../../public/commonAssets/File.svg'
import { ReactComponent as RightArrow } from '../../../../public/commonAssets/right-arrow.svg'
import { ReactComponent as Copy } from '../../../../public/commonAssets/Copy.svg'
import { ReactComponent as Call } from '../../../../public/commonAssets/Call.svg'
import { ReactComponent as Arrow } from '../../../../public/commonAssets/Arrow.svg'
import { getFormattedTime } from '../../../utils/functions'
import AddressBox from '../../../components/AddressBox'
import pushToAnalytics from '../../../utils/Analytics'
const tickIcon = '/commonAssets/tick.gif'

export const InvoiceSubModal = (props) => {
  return (
    <div className='row mt20 mb8 fontSmall'>
      <div className='col-6 pb8 invoice-label'>{props.label}</div>
      <div className={'col-6 invoice-amt boldFontStyle ' + (window.isDirectionRTL ? ' text-left ' : ' text-right')}>
        {/* <span dangerouslySetInnerHTML={{ __html: props.currencySymbol }} /> */}
        {getLocaleCurrency(props.locale, props.currencyCode, props.value)}
      </div>
    </div>
  )
}
InvoiceSubModal.propTypes = {
  label: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  currencySymbol: PropTypes.string.isRequired,
}

export class TreatmentsModal extends React.Component {
  state = {
    selected: null,
  }

  handleCustomRadioOnClick = (e) => {
    this.setState({
      selected: e.target.id,
    })
  }
  render() {
    return (
      <Modal className='sfy-modal treatments-modal' showModal={this.props.show} closeModal={this.props.toggleModal}>
        <div className='row'>
          <div className={'col-12 ' + (window.isDirectionRTL ? ' text-left ' : ' text-right')}>
            <CustomIconComponent className='close-modal-btn' onClick={this.props.toggleModal} alt='Cancel' />
          </div>
        </div>
        <div className='row custom-radio-container'>
          {this.props.treatmentData &&
            this.props.treatmentData.map((radioDataItem) => (
              <CustomRadioComponent
                key={radioDataItem}
                {...this.props}
                radioDataItem={radioDataItem}
                selected={this.state.selected}
                handleCustomRadioOnClick={this.handleCustomRadioOnClick}
              >
                {radioDataItem}
              </CustomRadioComponent>
            ))}
        </div>
        <div className='col-sm-12 text-center mt20'>
          <MediumButton
            id='updateTreatments'
            btnLoader={this.props.BtnStatusLoader}
            buttonText={getTranslationText('common', 'save')}
            onClick={() => this.props.handleUpdateTreatments(this.state.selected)}
            disabled={!this.state.selected}
          />
        </div>
      </Modal>
    )
  }
}
TreatmentsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  handleUpdateTreatments: PropTypes.func.isRequired,
  treatmentData: PropTypes.any,
  BtnStatusLoader: PropTypes.bool,
}

export const SwapOptionsModal = (props) => (
  <Modal className='sfy-modal request-type-options-popup' showModal={props.show}>
    <div className='row request-type-options-wrapper'>
      <div className='col-sm-8'>
        <Heading title={props.title} />
      </div>
      <div className={'col-sm-4 ' + (window.isDirectionRTL ? ' text-left ' : ' text-right')}>
        <CustomIconComponent className='close-icon' onClick={props.toggleModal} />
      </div>
      <div className='col-sm-12 mt28'>
        {props.data.map((item, index) => (
          <div className='row mb20' key={index} onClick={() => props.handleRequest(item)}>
            <div className='col-sm-4 col-12 service-action-head'>
              <img src={item.image} alt='data' />
            </div>
            <div className='col-sm-8 col-12 service-action-descp'>
              <div>{item.Description}</div>
              <RightArrow />
            </div>
          </div>
        ))}
      </div>
    </div>
  </Modal>
)
SwapOptionsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string,
  toggleModal: PropTypes.func.isRequired,
  handleRequest: PropTypes.func.isRequired,
  data: PropTypes.any,
}

export const InvoiceModal = (props) => {
  // const { setFormRef, formAction } = props
  return (
    <Modal className='sfy-modal invoice-modal' showModal={props.show} closeModal={props.toggleModal}>
      <div className='row'>
        <div className='col-10'>
          <Heading
            title={props.paymentIncluded ? getTranslationText('track', 'paymentDetails') : getTranslationText('common', 'invoice')}
          />
        </div>
        <div className={'col-2 ' + (window.isDirectionRTL ? ' text-left ' : ' text-right')}>
          <CustomIconComponent className='close-modal-btn' onClick={props.toggleModal} alt='Cancel' />
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-12 mt8 invoice-index'>
          {props.data &&
            props.data.other.values.map((data, index) => {
              var Idata = Object.keys(data)
              return (
                <InvoiceSubModal
                  label={Idata}
                  value={data[Idata]}
                  key={index}
                  currencySymbol={props.data.CurrencySymbol}
                  locale={props.locale}
                  currencyCode={props.currencyCode}
                />
              )
            })}
          <div className='row mt20 mb8 fontSmall'>
            <div className='col-6 pb8 invoice-label boldFontStyle'>{props.data.ConsumerAmount.displayName}</div>
            <div className={'col-6 invoice-amt boldFontStyle ' + (window.isDirectionRTL ? ' text-left ' : ' text-right')}>
              {/* <span dangerouslySetInnerHTML={{ __html: props.data.CurrencySymbol }} /> */}
              {getLocaleCurrency(props.locale, props.currencyCode, props.data.ConsumerAmount.value)}
            </div>
          </div>
        </div>
        <div className='col-sm-12 mt20 text-center'>
          {props.paymentIncluded ? (
            <MediumButton
              buttonText={getTranslationText('common', 'pay')}
              disabled={props.isDisabledCheckout}
              className='invoice-payment-btn'
              onClick={props.initiatePayment}
            />
          ) : (
            ''
          )}
        </div>
        <div className='col-sm-12 mt20 fontMicro'>
          <p>{getTranslationText('track', 'repairEstimateText')}</p>
        </div>
      </div>
    </Modal>
  )
}

InvoiceModal.propTypes = {
  data: PropTypes.object,
  show: PropTypes.bool.isRequired,
  isDisabledCheckout: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  initiatePayment: PropTypes.func.isRequired,
  paymentIncluded: PropTypes.bool.isRequired,
}

export const EstimatedInvoiceModal = (props) => {
  return (
    <Modal className='sfy-modal estimated-invoice-modal' showModal={props.show} closeModal={props.toggleModal}>
      <div className='row'>
        <div className='col-10'>
          <Heading title={getTranslationText('track', 'paymentDetails')} />
        </div>
        <div className={'col-2 ' + (window.isDirectionRTL ? ' text-left ' : ' text-right')}>
          <CustomIconComponent className='close-modal-btn' onClick={props.toggleModal} alt='Cancel' />
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-12 mt8 invoice-index'>
          {props.data &&
            props.data.other.values.map((data, index) => {
              var Idata = Object.keys(data)
              return (
                <InvoiceSubModal
                  label={Idata}
                  value={data[Idata]}
                  key={index}
                  currencySymbol={props.data.CurrencySymbol}
                  currencyCode={props.currencyCode}
                  locale={props.locale}
                />
              )
            })}
          <div className='row mt20 mb8 fontSmall'>
            <div className='col-6 pb8 invoice-label boldFontStyle'>{props.data.ConsumerAmount.displayName}</div>
            <div className={'col-6 invoice-amt boldFontStyle ' + (window.isDirectionRTL ? ' text-left ' : ' text-right')}>
              {/* <span dangerouslySetInnerHTML={{ __html: props.data.CurrencySymbol }} /> */}
              {getLocaleCurrency(props.locale, props.currencyCode, props.data.ConsumerAmount.value)}
            </div>
          </div>
        </div>
        <div className='col-sm-12 mt20 estimate-invoice-btn'>
          <div className='row'>
            <div className='col-sm-6'>
              <MediumButton
                buttonText={getTranslationText('common', 'approve')}
                id='approve-invoice-estimation'
                className='mb8'
                onClick={props.updateEstimation}
              />
            </div>
            <div className='col-sm-6'>
              <MediumButton
                buttonText={getTranslationText('common', 'reject')}
                id='reject-invoice-estimation'
                onClick={props.updateEstimation}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
EstimatedInvoiceModal.propTypes = {
  data: PropTypes.object,
  show: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  updateEstimation: PropTypes.func.isRequired,
}

export const CancelReqModal = (props) => {
  return (
    <Modal showModal={props.show} className='sfy-modal' closeModal={props.toggleModal}>
      {props.showCancellationReasons ? (
        <>
          <div className='modal-cross-icon'>
            <CustomIconComponent className='text-right' onClick={props.toggleModal} alt='CancelRescheduleReq' />
          </div>
          <div className='boldFontStyle fontLarge text-center'>{getTranslationText('track', 'cancelRequest')}</div>
          <p className='mt8 mb28 text-center'>{getTranslationText('track', 'select-cancel-reason')}</p>
          <div className='row'>
            <div className='col-sm-12 mb28'>
              <p className=''>{getTranslationText('track', 'claim-cancel-reason')}</p>
              <Dropdown
                classObject={{
                  container: `mt4 list-type-ahead`,
                }}
                id='CancelReasonMasterName'
                handleOnChange={(item) => {
                  props.handleReasonListSelection([item])
                  pushToAnalytics('cancel_request_reason_selected', {
                    Cancel_Reason: item.value,
                  })
                }}
                value={!_.isEmpty(props.selectedReasonValue) ? props.selectedReasonValue[0].value : ''}
                placeholder={getTranslationText('track', 'please-select-reason')}
                options={
                  Array.isArray(props.requestCancelMasterList)
                    ? props.requestCancelMasterList.map((each) => ({ ...each, value: each.Reason }))
                    : ''
                }
                filterBy='value'
                searchText='Search'
              />
            </div>

            {_.get(props, 'selectedReasonValue[0].value') == 'Other' ? (
              <div className='col-sm-12 mb8'>
                <InputComponent
                  type='text'
                  className='cancel-ip mb20 bookFontStyle'
                  value={props.cancelReqInputValue}
                  placeholder={getTranslationText('track', 'please-specify-reason')}
                  onChange={props.handleCancelReqIp}
                />
              </div>
            ) : (
              ''
            )}

            <div className='text-center col-sm-12 mb8 CancelReqOptns'>
              <MediumButton
                className='button-small mb8 fontMedium'
                id='cancelReq'
                buttonText={getTranslationText('common', 'submit')}
                btnLoader={props.BtnStatusLoader}
                disabled={
                  _.get(props, 'selectedReasonValue[0].value') == 'Other'
                    ? !props.cancelReqInputValue.length
                    : !_.get(props, 'selectedReasonValue[0].value', []).length
                }
                onClick={props.handleupdateRequest}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='row'>
            <div className='col-sm-8 mb20'>
              <Heading title={getTranslationText('track', 'cancelRequest')} />
            </div>
            <div className={'col-sm-4 ' + (window.isDirectionRTL ? ' text-left ' : ' text-right')}>
              <CustomIconComponent className='close-modal-btn' onClick={props.toggleModal} alt='CancelRescheduleReq' />
            </div>
            <div className='col-sm-12 mb8'>
              <SubHeading title={getTranslationText('track', 'requestAbort')} className='mb8' />
              <InputComponent
                type='text'
                className='cancel-ip mb20 bookFontStyle'
                value={props.cancelReqInputValue}
                placeholder={`${getTranslationText('common', 'remarks')}...`}
                onChange={props.handleCancelReqIp}
              />
            </div>
            <div className='col-sm-12 mb8 CancelReqOptns'>
              <MediumButton
                className='button-small button-ghost'
                buttonText={getTranslationText('common', 'no')}
                onClick={props.toggleModal}
              />
              <MediumButton
                className='button-small mb8 fontMedium'
                id='cancelReq'
                buttonText={getTranslationText('common', 'yes')}
                btnLoader={props.BtnStatusLoader}
                disabled={!props.cancelReqInputValue.length}
                onClick={props.handleupdateRequest}
              />
            </div>
          </div>
        </>
      )}
    </Modal>
  )
}
CancelReqModal.propTypes = {
  show: PropTypes.bool,
  toggleModal: PropTypes.func,
  handleupdateRequest: PropTypes.func,
  cancelReqInputValue: PropTypes.string,
  handleCancelReqIp: PropTypes.func.isRequired,
  BtnStatusLoader: PropTypes.bool,
}

export const RequestModal = (props) => {
  return (
    <Modal showModal={props.show} className='sfy-modal' closeModal={props.toggleModal}>
      <div className='row'>
        <div className='col-sm-8 mb20'>
          <Heading title={props.RescheduleModalTitle} />
        </div>
        <div className={'col-sm-4 ' + (window.isDirectionRTL ? ' text-left ' : ' text-right')}>
          <CustomIconComponent className='close-modal-btn' onClick={props.toggleModal} alt='CancelRescheduleReq' />
        </div>
        <div className='col-sm-12 mb8'>
          <SubHeading title={getTranslationText('track', 'selectDate')} className='mb8 overrideCasing' />
          <RadioBtnPills
            prefix='datepill'
            noSlots
            optionList={
              props.rescheduleSlots
                ? props.rescheduleSlots.map((data) => {
                    return data[0]
                  })
                : []
            }
            onClick={props.handleDateSelect}
          />
        </div>
        <div className='col-sm-12 mb20'>
          <SubHeading title={getTranslationText('track', 'selectTime')} className='mb8 overrideCasing' />
          <RadioBtnPills prefix='timepill' noSlots={false} optionList={props.reschedulableTimes} onClick={props.handleTimeSelect} />
        </div>
        <div className='col-sm-12'>
          {props.schedulePickSlotsModal ? (
            <MediumButton buttonText={getTranslationText('common', 'continue')} onClick={props.handleSchedulePickUpReq} />
          ) : (
            <MediumButton
              id='updateReq'
              buttonText={getTranslationText('common', 'continue')}
              btnLoader={props.BtnStatusLoader}
              onClick={props.handleupdateRequest}
            />
          )}
        </div>
      </div>
    </Modal>
  )
}

RequestModal.propTypes = {
  show: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  handleDateSelect: PropTypes.func.isRequired,
  rescheduleSlots: PropTypes.array,
  reschedulableTimes: PropTypes.array.isRequired,
  handleupdateRequest: PropTypes.func,
  handleTimeSelect: PropTypes.func.isRequired,
  schedulePickSlotsModal: PropTypes.bool,
  handleSchedulePickUpReq: PropTypes.func,
  RescheduleModalTitle: PropTypes.string.isRequired,
  BtnStatusLoader: PropTypes.bool,
}

export const BEROptionsModal = (props) => {
  return (
    <Modal showModal={props.show} closeModal={props.toggleModal} className='sfy-modal ber-modal'>
      <div className='row'>
        <div className='col-sm-8 mb20'>
          <Heading
            title={
              props.berModeConfirmation ? getTranslationText('track', 'confirmOprion') : getTranslationText('track', 'beyondEconomicOption')
            }
          />
        </div>
        <div className={'col-4 ' + (window.isDirectionRTL ? ' text-left ' : ' text-right')}>
          <CustomIconComponent className='close-modal-btn' onClick={props.toggleModal} alt='Cancel' />
        </div>
        <div className='col-sm-12 mb20'>
          <div className='row'>
            {props.berModeConfirmation
              ? props.berModeOptions.map((data, index) => {
                  if (data.isSelected) {
                    return (
                      <div className='col-sm-12 mb8 mt8' key={index} id={'ber-' + index} onClick={props.selectedBEROptn}>
                        <div className='ber-options'>
                          <div>
                            <div className='mediumFontStyle fontMedium'>{data.SectionHeading}</div>
                            <div className='bookFontStyle fontSmall'>{data.SectionDescription}</div>
                          </div>
                        </div>
                      </div>
                    )
                  }
                })
              : props.berModeOptions.map((data, index) => {
                  return (
                    <div className='col-sm-12 mb8 mt8' key={index} id={'ber-' + index} onClick={props.selectedBEROptn}>
                      <div className='ber-options'>
                        <div className='ber-optn-selector'>
                          <Radio
                            classes='radio-styling'
                            name='BEROption'
                            value={data.isSelected || index}
                            selectedValue={data.isSelected}
                          />
                        </div>
                        <div className='ber-optns-content'>
                          <div className='mediumFontStyle fontMedium'>{data.SectionHeading}</div>
                          <div className='bookFontStyle fontSmall'>{data.SectionDescription}</div>
                        </div>
                      </div>
                    </div>
                  )
                })}
          </div>
        </div>
        {props.berModeConfirmation ? (
          <div className='col-sm-12'>
            <MediumButton
              className={`button-small button-ghost ${window.isDirectionRTL ? ' ml8 ' : ' mr8'}`}
              buttonText={getTranslationText('common', 'backLabel')}
              onClick={props.berModeListBckBtn}
            />
            <MediumButton className='button-small' id='updateReq' buttonText={props.berBtnTxt} onClick={props.handleBEROptionCheck} />
          </div>
        ) : props.berBtnTxt && props.berBtnTxt.length ? (
          <div className='col-sm-12'>
            <MediumButton
              id='updateReq'
              buttonText={props.berBtnTxt}
              onClick={props.handleBEROptionCheck}
              disabled={!props.berModeOptions.find((data) => data.isSelected)}
            />
          </div>
        ) : (
          ''
        )}
      </div>
    </Modal>
  )
}

BEROptionsModal.propTypes = {
  show: PropTypes.bool,
  toggleModal: PropTypes.func,
  berModeListBckBtn: PropTypes.func.isRequired,
  berModeOptions: PropTypes.array.isRequired,
  selectedBEROptn: PropTypes.func.isRequired,
  berBtnTxt: PropTypes.string,
  handleBEROptionCheck: PropTypes.func.isRequired,
  berModeConfirmation: PropTypes.bool.isRequired,
}

export const PendingDocsModal = (props) => {
  return (
    <Modal showModal={props.show} closeModal={props.toggleModal} className='sfy-modal pending-docs-modal'>
      <div className='row'>
        <div className='col-sm-8 col-8 mb20'>
          <Heading title={getTranslationText('track', 'pendingDocs')} />
        </div>
        <div className={'col-sm-4 col-4 mb20 ' + (window.isDirectionRTL ? ' text-left ' : ' text-right')}>
          <CustomIconComponent className='close-icon' onClick={props.toggleModal} alt='CancelReqClose' />
        </div>
        <div className='col-sm-12'>
          <div className='row mb20'>
            {props.pendingDocsList.map((doc) => {
              return (
                <div className='col-6 mb20 mt20' key={doc.document.DocumentType}>
                  <div className='mediumFontStyle fontSmall mb4 '>{doc.document.DocumentType}</div>
                  <div className='doc-upload text-center'>
                    {doc.IsPendingDocument === 1 ? (
                      <BoxImagesComponent
                        loaderState={false}
                        isUploaded={doc.isUploaded}
                        className='box-img-container'
                        imgFilePath={doc.isUploaded ? doc.docDocUrl : <AddSvg />}
                        inputName='invoice-upload'
                        inputId={doc.PendingDocID}
                        imgViewerHandler={props.imgViewerHandler}
                        fileUploadHander={window.useDocService ? props.handleDocsImgUploadToDS : props.handleDocsImgUpload}
                      />
                    ) : (
                      doc.consumerServicerequestDocuments.map((csrDoc, index) => {
                        return (
                          <BoxImagesComponent
                            key={index}
                            loaderState={false}
                            isUploaded
                            className='box-img-container'
                            imgFilePath={csrDoc.FilePath}
                          />
                        )
                      })
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div className='col-sm-12 text-center mt20'>
          <MediumButton
            id='updateReq'
            buttonText={getTranslationText('common', 'upload')}
            onClick={props.uploadPendingDocs}
            btnLoader={props.BtnStatusLoader}
            disabled={!!props.pendingDocsList.find((doc) => !doc.isUploaded)}
          />
        </div>
      </div>
    </Modal>
  )
}

PendingDocsModal.propTypes = {
  show: PropTypes.bool,
  toggleModal: PropTypes.func,
  pendingDocsList: PropTypes.array,
  handleDocsImgUpload: PropTypes.func.isRequired,
  uploadPendingDocs: PropTypes.func.isRequired,
  BtnStatusLoader: PropTypes.bool,
  imgViewerHandler: PropTypes.func.isRequired,
}

export const BERAccessoriesOptnModal = (props) => {
  return (
    <Modal showModal={props.show} closeModal={props.toggleModal} className='sfy-modal ber-modal'>
      <div className='row'>
        <div className='col-sm-8 col-8 mb20'>
          <Heading title={getTranslationText('track', 'BERoptions')} />
        </div>
        <div className={'col-sm-4 col-4 mb20 ' + (window.isDirectionRTL ? ' text-left ' : ' text-right')}>
          <CustomIconComponent className='close-icon' onClick={props.toggleModal} />
        </div>
        <div className='col-sm-12 mb20'>
          <div className='row'>
            {props.berModeOptions.map((data, index) => {
              return (
                <div className='col-sm-12 mb8 mt8' key={index} id={'ber-' + index} onClick={props.selectBERAccessoriesOptn}>
                  <div className='ber-options'>
                    <div className='ber-optn-selector'>
                      <Radio name='BEROption' value={data.isSelected || index} selectedValue={data.isSelected} />
                    </div>
                    <div className='ber-optns-content'>
                      <div className='mediumFontStyle fontMedium'>{data.SectionHeading}</div>
                      <div className='bookFontStyle fontSmall'>{data.SectionDescription}</div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div className='col-sm-12 text-center'>
          <MediumButton
            id='updateReq'
            buttonText={getTranslationText('common', 'confirm')}
            onClick={props.handleBEROptionCheck}
            disabled={!props.berModeOptions.find((data) => data.isSelected)}
          />
        </div>
      </div>
    </Modal>
  )
}

BERAccessoriesOptnModal.propTypes = {
  show: PropTypes.bool,
  toggleModal: PropTypes.func,
  berModeOptions: PropTypes.array.isRequired,
  selectBERAccessoriesOptn: PropTypes.func.isRequired,
  handleBEROptionCheck: PropTypes.func.isRequired,
}

export const InstructionModal = (props) => {
  let dropOffText = getTranslationText('track', 'viewDropOffDetails')
  let pickupText = getTranslationText('track', 'viewPickupDetails')
  let isPickup = _.includes([1, 11], _.get(props, 'track.trackDetails.ServiceTypeID'))
  let isPickupServiceAvailable = _.get(props, 'request.requestModesData.modes', []).some((mode) => _.includes([1, 11], mode.ServiceTypeID))
  let isDropoff = _.includes([17, 23], _.get(props, 'track.trackDetails.ServiceTypeID'))
  const locale = _.get(props, 'user.selectedCountryData.countryConfig.Locale.DEFAULT', 'en')
  const showJobSheet = _.get(props, 'user.appConfigDetails.showJobSheet', false)
  return (
    <Modal showModal={props.show} closeModal={props.toggleModal} className='sfy-modal pickup-instruction-modal'>
      <div className='row'>
        <CustomIconComponent className='drop-off-close-icon' onClick={props.toggleModal} alt='Cancel' />
        <div className='col-sm-12 col-12 mb20'>
          <div className='text-center fontLarge boldFontStyle'>{isPickup ? pickupText : dropOffText}</div>
        </div>

        <div className='col-sm-12 mb20 content-card'>
          <div className='col-sm-8'>
            <p className='lightFontStyle fontSmall mb4'>{getTranslationText('track', 'trackingNumber')}</p>
            <div className='display-flex'>
              {props.logisticsDetails.WayBillNumber}
              <span className='ml8 display-flex' onClick={() => props.copyTrackingNumber(props.logisticsDetails.WayBillNumber)}>
                <Copy />
                <span className='ml8 special-link-txt lightFontStyle'>{getTranslationText('track', 'copy')}</span>
              </span>
            </div>
          </div>
        </div>

        {(props.ShowDocument || props.ShowLogisticsInstruction) && !_.get(props, 'track.trackDetails.ShowDeliveryAddress', false) ? (
          <div className='col-sm-12 mb20 content-card'>
            <div className='col-sm-12'>
              <div className='lightFontStyle fontSmall mb8'>{getTranslationText('track', 'packYourDevice')}</div>
              <div>
                {props.logisticsDetails.logisticsDocs
                  ? props.logisticsDetails.logisticsDocs.map((doc, index) => {
                      return (
                        <span key={index}>
                          <Link
                            to={doc.ImageUrl}
                            target='_blank'
                            disabled={props.pickUpInstructions[0].isSelected}
                            className='mediumFontStyle special-link-txt'
                          >
                            <DownloadSvg />
                            <span className='ml8 special-link-txt lightFontStyle'>
                              {doc.ImageText || getTranslationText('track', 'downloadLabel')}
                            </span>
                            {props.logisticsDetails.logisticsDocs.length > index && (
                              <span className='fontLarge  separator lightFontStyle'> | </span>
                            )}
                          </Link>
                        </span>
                      )
                    })
                  : ''}
                {_.get(props, 'logisticsDetails.logisticsDocs', []).length >= 1 ? (
                  <>
                    <Email />
                    <span className='ml8 special-link-txt lightFontStyle' onClick={props.sendDocumentsTOEmail}>
                      {getTranslationText('track', 'sendOnEmail')}
                    </span>
                  </>
                ) : (
                  ''
                )}
                {showJobSheet && props.jobsheetS3Url ? (
                  <span>
                    <span className='fontLarge  separator lightFontStyle'> | </span>
                    <Link to={props.jobsheetS3Url} target='_blank' className='mediumFontStyle special-link-txt'>
                      <FileSvg />
                      <span className='ml8 special-link-txt lightFontStyle'>{getTranslationText('track', 'viewJobSheet')}</span>
                    </Link>
                  </span>
                ) : (
                  ''
                )}
                {props.sendDocViaMail ? (
                  <div className='downloadable-content mediumFontStyle fontMicro special-link-txt displayInRow mt20'>
                    {props.emailSent ? (
                      <div className='fontSmall mt4'>
                        <span className='bookFontStyle' style={{ color: '#4bd800' }}>
                          {getTranslationText('track', 'emailSent')} &nbsp;
                        </span>
                        <span className={'mediumFontStyle ' + (window.isDirectionRTL ? ' ml8 ' : ' mr8')} style={{ color: 'black' }}>
                          {props.emailInput}
                        </span>
                      </div>
                    ) : (
                      <div className='inline-ele-Casing'>
                        <InputComponent
                          type='email'
                          id='email-input'
                          className='bookFontStyle fontSmall'
                          value={props.emailInput}
                          onChange={props.handleEmailInputChange}
                        />
                        {props.BtnStatusLoader ? (
                          <div className='btn-level-loader' />
                        ) : (
                          <MediumButton
                            buttonText={getTranslationText('common', 'send')}
                            id='send-btn'
                            onClick={props.handleEmailInputChange}
                            className={window.isDirectionRTL ? ' ml8 ' : ' mr8'}
                            disabled={!(props.emailInput && props.emailInput.length > 0)}
                          />
                        )}
                        {props.showEmailError ? <span style={{ color: 'red' }}>{getTranslationText('track', 'invalidEmail')}</span> : ''}
                      </div>
                    )}
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div className='lightFontStyle fontSmall note-card mt20'>{getTranslationText('track', 'dropOffNote')}</div>
            </div>
          </div>
        ) : (
          ''
        )}

        {isPickup ? (
          <div className='col-sm-12 mb20 content-card'>
            <div className='col-sm-12'>
              {_.get(props, 'track.trackDetails.ShowDeliveryAddress', false) ? (
                <>
                  <div className='lightFontStyle fontSmall mb4'>{getTranslationText('track', 'deliveryAddress')}</div>
                  <div className='mb8 fontMedium'>
                    {_.get(props, 'track.trackDisplayDeliveryAddress.value', []).length &&
                      _.get(props, 'track.trackDisplayDeliveryAddress.value').map((v, i) => {
                        return v + (_.get(props, 'track.trackDisplayDeliveryAddress.value').length !== i + 1 ? ', ' : '.')
                      })}
                  </div>
                </>
              ) : (
                <>
                  <div className='lightFontStyle fontSmall mb4'>{getTranslationText('track', 'pickUpAddress')}</div>
                  <div className='mb8 fontMedium'>
                    {_.get(props, 'track.trackDisplayUserAddress.value', []).length &&
                      _.get(props, 'track.trackDisplayUserAddress.value').map((v, i) => {
                        return v + (_.get(props, 'track.trackDisplayUserAddress.value').length !== i + 1 ? ', ' : '.')
                      })}
                  </div>
                </>
              )}
              {!_.get(props, 'track.trackDetails.ShowDeliveryAddress', false) ? (
                <>
                  <div className='lightFontStyle fontSmall mt20'>{getTranslationText('track', 'scheduledDate')}</div>
                  <div className='mb8 fontMedium'>
                    {`${moment(
                      _.get(props, 'track.trackDetails.logisticsDetails.RescheduleDate') ||
                        _.get(props, 'track.trackDetails.logisticsDetails.ScheduledPickUpFromTime')
                    ).format('MMMM Do YYYY')}`}
                    {` | ${getFormattedTime(_.get(props, 'track.trackDetails.logisticsDetails.ScheduledFromTime'), locale)} -
                     ${getFormattedTime(_.get(props, 'track.trackDetails.logisticsDetails.ScheduledToTime'), locale)}`}
                  </div>
                </>
              ) : (
                ''
              )}
            </div>
          </div>
        ) : _.get(props, 'track.trackDetails.DropOffCenter', false) &&
          isDropoff &&
          _.get(props, 'track.trackDetails.DropOffCenter.ContactNo1') ? (
          <div className='col-sm-12 mb20 content-card'>
            <div className='col-sm-12'>
              {/* <div className='lightFontStyle fontSmall mb4'>{getTranslationText('track', 'dropOffAddress')}</div>
              <div className='mb8 fontMedium'>
                {_.get(props, 'track.trackDetails.DropOffCenter.ServiceLocationName')} <br />
                {`${_.get(props, 'track.trackDetails.DropOffCenter.Address')}`}
              </div> */}
              <div className=''>
                {_.get(props, 'track.trackDetails.DropOffCenter.ContactNo1') ? (
                  <>
                    <Call />
                    <span className='ml8 special-link-txt lightFontStyle'>
                      {_.get(props, 'track.trackDetails.DropOffCenter.ContactNo1')}
                    </span>{' '}
                    <span className='fontLarge  separator lightFontStyle'> | </span>{' '}
                  </>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
        <div className='col-sm-12 text-center'>
          {isDropoff && _.get(props, 'logisticsDetails.VendorTrackingUrl') ? (
            <a href={_.get(props, 'logisticsDetails.VendorTrackingUrl')} target='_blank' rel='noreferrer'>
              <MediumButton id='updateReq' buttonText={getTranslationText('track', 'trackLiveStatus')} className='col-sm-4' />
            </a>
          ) : (
            ''
          )}
          {isPickup && _.get(props, 'logisticsDetails.VendorTrackingUrl') ? (
            <a href={_.get(props, 'logisticsDetails.VendorTrackingUrl')} target='_blank' rel='noreferrer'>
              <MediumButton id='updateReq' buttonText={getTranslationText('track', 'trackLiveStatus')} className='col-sm-4 offset-1' />
            </a>
          ) : (
            ''
          )}
        </div>
        {isPickupServiceAvailable &&
        props.showSchedulePickup &&
        _.get(props, 'request.requestModesData.dropOffVendorList', []).length >= 1 ? (
          <div className='mt8 col-sm-12 special-link-txt text-center' id='schedule-to-pickup' onClick={props.ModalCall}>
            {getTranslationText('track', 'scheduleAPick')}
          </div>
        ) : (
          ''
        )}
      </div>
    </Modal>
  )
}

InstructionModal.propTypes = {
  show: PropTypes.bool,
  toggleModal: PropTypes.func,
  logisticsDetails: PropTypes.object.isRequired,
  handleInstructionSelection: PropTypes.func.isRequired,
  pickUpInstructions: PropTypes.array.isRequired,
  sendDocumentsTOEmail: PropTypes.func.isRequired,
  sendDocViaMail: PropTypes.bool,
  emailSent: PropTypes.bool,
  showEmailError: PropTypes.bool,
  emailInput: PropTypes.string,
  handleEmailInputChange: PropTypes.func.isRequired,
  handlePickInstructionStatus: PropTypes.func.isRequired,
  instructionEnabled: PropTypes.bool,
  requestMode: PropTypes.string.isRequired,
  BtnStatusLoader: PropTypes.bool,
}

export const DeliveryPartnerPopUp = (props) => {
  return (
    <Modal showModal={props.show} className='sfy-modal' closeModal={props.toggleModal}>
      <div className='row billing-partner-details'>
        <div className='col-sm-12 mb28'>
          <Heading title={getTranslationText('track', 'addAirwayBill')} />
        </div>
        <div className='col-sm-12 mb8'>
          <div className='row'>
            <div className='col-sm-12'>
              <div className='boldFontStyle mb4'>{getTranslationText('track', 'deliveryPartner')}</div>
              <InputComponent
                className='lightFontStyle'
                id='partner-name'
                type='text'
                value={props.deliveryPartnerDetails.partnerName}
                onChange={props.deliveryPartnerOnChange}
              />
            </div>
            <div className='col-sm-12'>
              <div className='boldFontStyle mb4'>{getTranslationText('track', 'airwayBill')}</div>
              <InputComponent
                className='lightFontStyle'
                id='billing-number'
                type='text'
                value={props.deliveryPartnerDetails.billingNumber}
                onChange={props.deliveryPartnerOnChange}
              />
            </div>
          </div>
        </div>
        <div className='col-sm-12 mb8 CancelReqOptns'>
          <MediumButton className='mb8 fontMedium' buttonText={getTranslationText('track', 'addAirwayBill')} onClick={null} />
        </div>
      </div>
    </Modal>
  )
}

DeliveryPartnerPopUp.propTypes = {
  show: PropTypes.bool,
  toggleModal: PropTypes.func,
  deliveryPartnerDetails: PropTypes.object.isRequired,
  deliveryPartnerOnChange: PropTypes.func.isRequired,
}

export const DropOffCenterList = (props) => {
  let distance = _.get(props, 'user.appConfigDetails.distances', [{ multiplier: 1, displayName: 'km' }])
  return (
    <Modal className='sfy-modal shipping-locations' showModal={props.show} closeModal={props.toggleModal}>
      <CustomIconComponent
        className={'close-icon ' + (window.isDirectionRTL ? 'appear-left text-left ' : 'appear-right text-right')}
        onClick={props.toggleModal}
        alt='Cancel'
      />
      <div className='row'>
        <div className='col-12 text-center'>
          <Heading title={getTranslationText('track', 'mail-in-address')} />
        </div>
        <div className='row text-center'>
          <div className='col-sm-12'>
            <SubHeading title={getTranslationText('request', 'shippingLocationSubHeader')} className='mt8 bookFontStyle fontSmall' />
            <div className='dropoff-return-address mt20'>
              <span>{getTranslationText('common', 'returnAddress')} : </span>
              <span>
                {_.get(props, 'track.trackDisplayDeliveryAddress.value', []).length &&
                  _.get(props, 'track.trackDisplayDeliveryAddress.value').map((v, i) => {
                    return v + (_.get(props, 'track.trackDisplayDeliveryAddress.value').length !== i + 1 ? ', ' : '.')
                  })}
              </span>
            </div>
          </div>
          <div className='col-sm-12 mt20 mb8 text-align-left'>
            <AddressBox
              handleTypeaheadSelect={props.handleTypeaheadSelect}
              onChangeTypeahead={props.onChangeTypeahead}
              user={props.user}
              placesAutocomplete={{
                options: {
                  componentRestrictions: {
                    country: _.get(this, 'props.user.selectedCountryData.CountryCode', '').toLowerCase(),
                  },
                },
                landmark: props.landmark,
              }}
              isLoaderSet={false}
              locationLabel={getTranslationText('addressBox', 'selectLocation')}
            />
            <div className='shippling-locations-list'>
              {props.dropOffLocationsList.map((location, index) => (
                <div className='dropoff-location-info' key={index}>
                  <p className='mediumFontStyle fontSmall mb8'>
                    {index + 1}. {location.ServiceLocationName}
                  </p>
                  <p className='bookFontStyle fontSmall mb8'>{`${location.address} ${location.City} ${location.StateProvinceCode || ''}
                    ${location.PinCode} ${location.CountryCode}`}</p>

                  <div className='dropoff-icons-container'>
                    <div className='bookFontStyle mb8 distance-component'>
                      <Arrow />
                      <span>
                        {(distance[0].multiplier * location.distance).toPrecision(3)} {distance[0].displayName}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className='row billing-partner-details'>
        <div className='col-sm-12 mt20 text-center'>
          <MediumButton
            className='button-small button-ghost'
            buttonText={getTranslationText('common', 'cancel')}
            onClick={props.toggleModal}
          />
          <MediumButton
            className={`button-small mb8 fontMedium ${window.isDirectionRTL ? ' mr8 ' : ' ml8'}`}
            buttonText={getTranslationText('track', 'dropOffInstructionsBtn')}
            onClick={props.handleShippingInstruction}
          />
        </div>
      </div>
    </Modal>
  )
}

export const DropOffShippingInstructions = (props) => {
  return (
    <Modal className='sfy-modal shipping-locations' showModal={props.show} closeModal={props.toggleModal}>
      <CustomIconComponent
        className={'close-icon ' + (window.isDirectionRTL ? 'appear-left text-left ' : 'appear-right text-right')}
        onClick={props.toggleModal}
        alt='Cancel'
      />
      <div className='row'>
        <div className='col-12 text-center'>
          <Heading title={getTranslationText('request', 'shippingInstructions')} />
        </div>
        <div className='text-center'>
          <div className='col-sm-12 mb20'>
            <SubHeading
              title={_.get(
                props,
                'track.logisticsVendorDetails.dropOffInstructions.DropOffInstructionsTitle',
                getTranslationText('request', 'shippingInstructionsSubheader')
              )}
              className='mt8 bookFontStyle fontSmall'
            />
          </div>
        </div>
      </div>
      <div className='row billing-partner-details'>
        <div className='col-sm-12 mb8'>
          {props.track.logisticsVendorDetails.dropOffInstructions.DropOffInstructions.map((instruction) => {
            return (
              <div className='instruction mb8' key={instruction.index}>
                {instruction.image && (
                  <div className='img'>
                    <img src={instruction.image} />
                  </div>
                )}
                <div className='text'>{instruction.text}</div>
              </div>
            )
          })}
        </div>
        <div className='col-sm-12 mt20 text-center'>
          <MediumButton
            className='button-small button-ghost'
            buttonText={getTranslationText('common', 'cancel')}
            onClick={props.toggleModal}
          />
          <MediumButton
            className={`button-small mb8 fontMedium ${window.isDirectionRTL ? ' mr8 ' : ' ml8'}`}
            buttonText={getTranslationText('request', 'backToShippingLocations')}
            onClick={props.handleDropOffCenterList}
          />
        </div>
      </div>
    </Modal>
  )
}

DropOffCenterList.propTypes = {
  show: PropTypes.bool,
  toggleModal: PropTypes.func,
  deliveryPartnerDetails: PropTypes.object.isRequired,
  deliveryPartnerOnChange: PropTypes.func.isRequired,
}

export class EngineerDetailsModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      usrLoc: {},
      preEngLoc: {},
      engLoc: {},
      finalLoc: {
        lat: 19.113004,
        lng: 72.855795,
      },
    }
  }

  navToGoogleMaps = () => {
    let url =
      'https://www.google.com/maps?daddr=' +
      this.state.usrLoc.lat +
      ',' +
      this.state.usrLoc.lng +
      '&saddr=' +
      this.state.engLoc.lat +
      ',' +
      this.state.engLoc.lng +
      `&directionsmode=driving, '_blank'`
    window.open(url)
  }

  render() {
    let showMap = true
    let timeLeft = _.get(this, 'state.fetchPath.routes[0].legs[0].duration.text')
    let profilePic = _.get(this, 'props.engineerDetails.ProfileImage', dummyUsrIcon)
    return (
      <Modal showModal={this.props.show} className='sfy-modal'>
        <div className='row engineer-info-modal'>
          <div className='col-8'>
            <Heading title={getTranslationText('track', 'engineerDetails')} />
          </div>
          <div className={'col-4 ' + (window.isDirectionRTL ? ' text-left ' : ' text-right')}>
            <CustomIconComponent className='close-icon' onClick={this.props.toggleModal} alt='Cancel' />
          </div>
          <div className='col-sm-12 mt28'>
            <div className='engineer-info'>
              <img className='eng-profile-pic' src={profilePic} />
              <div>
                <div className='mediumFontStyle mb8'>{this.props.engineerDetails.Name}</div>
                <div className='lightFontStyle theme-color ph-no-icon'>
                  <img src={PhoneNo} />
                  {this.props.engineerDetails.MobileNo}
                </div>
              </div>
            </div>
            {showMap && (
              <div className='map-wrapper'>
                <div className='engineer-tracking-map'>
                  <LiveTrackingMap
                    {...this.state}
                    language={_.get(this, 'props.user.selectedCountryData.countryConfig.LanguageCode.DEFAULT', 'en')}
                  />
                </div>
                <div className='directions-content row'>
                  <div className='col-6'>
                    {timeLeft && (
                      <div>
                        {timeLeft}&nbsp;{getTranslationText('common', 'away')}
                        <span className='lightFontStyle Note_Text fontSmall'>( {getTranslationText('track', 'lastUpdated')} )</span>
                      </div>
                    )}
                  </div>
                  <div className='mediumFontStyle theme-color col-6' onClick={this.navToGoogleMaps}>
                    {getTranslationText('track', 'viewOnMaps')}
                    <RightArrow />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
    )
  }
}
EngineerDetailsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func,
  engineerDetails: PropTypes.object.isRequired,
  setToaster: PropTypes.func.isRequired,
}

export class LiveTrackingMap extends React.Component {
  render() {
    let usrLatLng = new google.maps.LatLng(this.props.usrLoc)
    let engLatLng = new google.maps.LatLng(this.props.engLoc)
    let center = {
      lat: google.maps.geometry.spherical.interpolate(engLatLng, usrLatLng, 0.5).lat() || 19.112273,
      lng: google.maps.geometry.spherical.interpolate(engLatLng, usrLatLng, 0.5).lng() || 72.861271,
    }
    return (
      <MapComponent
        {...this.props}
        mapStyles={{ disableDefaultUI: true, zoomControl: false }}
        mapProps={{ center, zoom: 15 }}
        mapHeight='180px'
      />
    )
  }
}

LiveTrackingMap.propTypes = {
  usrLoc: PropTypes.any.isRequired,
  engLoc: PropTypes.any.isRequired,
}

export const AddDeviceIMEIModal = (props) => {
  return (
    <Modal className='sfy-modal courier-modal' showModal closeModal={props.toggleModal}>
      <div className='row'>
        <div className='modal-cross-icon'>
          <CustomIconComponent id='deviceImei' className='close-modal-btn' onClick={props.toggleModal} alt='Cancel' />
        </div>
      </div>
      <div className='row mt28'>
        <div className='col-sm-12 text-center'>
          <div className='boldFontStyle fontLarge'>{`Add New Device Details`}</div>
          <div className='bookFontStyle fontMedium mb20 mt4'>Please Provide device details of your new device.</div>
        </div>
        <div className='col-sm-12 mb20 mt28'>
          <div className='fontMedium lightFontStyle'>New Device IMEI Number</div>
          <InputComponent
            className='vendor-data-input'
            id='deviceImei'
            maxLength={15}
            type='number'
            autoComplete='off'
            placeholder='Enter new device IMEI number'
            value={props.deviceImeiInput}
            onChange={props.handleAddNewDeviceIMEI}
          />
        </div>
        <div className='col-sm-12 mt28 text-center'>
          <MediumButton
            className={`button-small mb8 fontMedium ${window.isDirectionRTL ? ' mr8 ' : ' ml8'}`}
            buttonText='Confirm'
            disabled={!(props.deviceImeiInput && props.deviceImeiInput.length == 15)}
            onClick={props.handleAddNewDeviceIMEISubmit}
          />
        </div>
      </div>
    </Modal>
  )
}

export const CourierModal = (props) => {
  let vendorName = _.get(props, 'vendorData.vendorName', '')
  let waybill = _.get(props, 'vendorData.waybill', '')
  let isDisabled =
    waybill.replace(/\s+/g, '').length > 5 &&
    !_.isEmpty(props.selectedVendor) &&
    (props.selectedVendor[0].VendorName === 'Other' ? vendorName.replace(/\s+/g, '').length > 2 : true)
  return (
    <Modal className='sfy-modal courier-modal' showModal closeModal={props.toggleModal}>
      <div className='row'>
        <div className='col-10'>
          <Heading title={'Courier Details'} />
        </div>
        <div className={'col-2 ' + (window.isDirectionRTL ? ' text-left ' : ' text-right')}>
          <CustomIconComponent className='close-modal-btn' onClick={props.toggleModal} alt='Cancel' />
        </div>
      </div>
      <div className='row mt28'>
        <div className='col-sm-12 mb20'>
          <div className='bookFontStyle fontMedium'>Courier Service Provider</div>
          <Dropdown
            classObject={{
              container: `mt4 list-type-ahead`,
            }}
            id='VendorName'
            handleOnChange={(item) => props.handleSelection([item])}
            value={!_.isEmpty(props.selectedVendor) ? props.selectedVendor[0].value : ''}
            placeholder='Select Courier Service Provider'
            options={Array.isArray(props.vendorList) ? props.vendorList.map((each) => ({ ...each, value: each.VendorName })) : []}
            filterBy='value'
            searchText='Search'
          />
        </div>
        {props.showOtherInputBox ? (
          <div className='col-sm-12 mb20'>
            <div className='fontMedium bookFontStyle'>Vendor Name</div>
            <InputComponent
              className='vendor-data-input'
              placeholder='Enter Courier Service Provider'
              autoComplete='off'
              id='vendorName'
              value={props.vendorData.vendorName}
              onChange={props.courierChangeHandler}
            />
          </div>
        ) : (
          ''
        )}
        <div className='col-sm-12 mb20'>
          <div className='fontMedium bookFontStyle'>Tracking Number</div>
          <InputComponent
            className='vendor-data-input'
            id='waybill'
            maxLength={30}
            autoComplete='off'
            placeholder='Enter Tracking Number'
            value={props.vendorData.waybill}
            onChange={props.courierChangeHandler}
          />
        </div>
        {/* <div className='col-sm-6 courier-amount'>
          <div className='fontMedium bookFontStyle'>Amount</div>
          <span dangerouslySetInnerHTML={{ __html: props.maximumCourierAmount.CurrencySymbol }} />
          <InputComponent className='vendor-data-input' id='amount' type='number'
            autoComplete='off'
            placeholder='Enter amount' value={props.vendorData.amount}
            onChange={props.courierChangeHandler} />
        </div> */}
        <div className='col-sm-12 mt28 textRight'>
          <MediumButton className='button-small button-ghost' buttonText='Cancel' onClick={props.toggleModal} />
          <MediumButton
            className={`button-small mb8 fontMedium ${window.isDirectionRTL ? ' mr8 ' : ' ml8'}`}
            buttonText='Update'
            disabled={!isDisabled}
            btnLoader={props.BtnStatusLoader}
            onClick={props.handleCourierSubmit}
          />
        </div>
      </div>
    </Modal>
  )
}
CourierModal.propTypes = {
  vendorList: PropTypes.array,
  showOtherInputBox: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  courierChangeHandler: PropTypes.func.isRequired,
  handleSelection: PropTypes.func.isRequired,
  handleCourierSubmit: PropTypes.func.isRequired,
  vendorData: PropTypes.object,
  selectedVendor: PropTypes.any,
  BtnStatusLoader: PropTypes.any,
  // maximumCourierAmount: PropTypes.any
}

export const AddressPincodeModal = (props) => {
  return (
    <Modal className='sfy-modal empty-fields-modal' showModal={props.showPinCodeModal}>
      <CustomIconComponent
        className={'close-icon ' + (window.isDirectionRTL ? 'appear-left text-left ' : 'appear-right text-right')}
        onClick={props.toggleModal}
      />
      <div className='custom-modal-title mb20 d-flex'>
        <h5 className='pr-2'>{getTranslationText('request', 'serviceAreaAdditionalDetails')}</h5>
      </div>
      <div className='custom-modal-content'>
        <div id='input-container' className='input-container'>
          <input
            name='dropoffPinCode'
            className='input pl-2'
            value={props.dropoffPinCode || ''}
            type='tel'
            placeholder={`${getTranslationText('store', 'enterZipCodeLabel')} `}
            onChange={props.addressPincodeHandler}
          />
        </div>
      </div>
      <MediumButton
        className={'mt20 mb8 fontMedium'}
        disabled={!(props.dropoffPinCode && props.dropoffPinCode.length)}
        onClick={props.addressPincodeSubmit}
        buttonText={getTranslationText('common', 'continue')}
      />
    </Modal>
  )
}

export const ServiceThankYouModal = (props) => {
  return (
    <Modal className='sfy-modal service-thank-you-modal empty-fields-modal' showModal={props.showServiceThankYouModal}>
      <CustomIconComponent
        className={'close-icon ' + (window.isDirectionRTL ? 'appear-left text-left ' : 'appear-right text-right')}
        onClick={props.toggleModal}
      />
      <img className='success-tick-img' src={tickIcon} />

      <div className='custom-modal-title mb20'>
        <h4 className='boldFontStyle'>{getTranslationText('track', 'thank-you-feedback')}</h4>
        <p className='mt28'>{getTranslationText('track', 'rate-us-description')}</p>
      </div>
      <p className='boldFontStyle'>{getTranslationText('track', 'external-rate-us')}</p>

      <div className='mb20 d-flex'>
        {props.externalFeedback &&
          props.externalFeedback.map((externalLinksData) => {
            return (
              <>
                <MediumButton
                  className={'button-small mb10 fontSmall mr8'}
                  onClick={() => {
                    props.externalLinksRedirection(externalLinksData.hyperlink)
                    pushToAnalytics(externalLinksData.displayText.replaceAll(' ', '_'), {
                      link: externalLinksData.hyperlink,
                    })
                  }}
                  buttonText={externalLinksData.displayText}
                />
              </>
            )
          })}
      </div>
    </Modal>
  )
}
