// ------------------------------------
// Constants
// ------------------------------------
import { api } from '../config/variables'
import { setToaster } from './toastsAndLoaders'
import { setSelectedDevice } from './request'
import { setPayableValue } from './payment'
import { setUploadDetails, setSignedUrl } from './user'
import { setServicetypeDetailsInstruction } from './request'
import _ from 'lodash'
import { getTranslationText } from '../utils/languageTranslation.js'
export const SET_TRACK_DETAILS = 'SET_TRACK_DETAILS'
export const SET_REQUEST_DETAILS = 'SET_REQUEST_DETAILS'
export const SET_PAYMENT_DETAILS = 'SET_PAYMENT_DETAILS'
export const SET_BERMODE_LIST = 'SET_BERMODE_LIST'
export const SET_LOGISTICS_DATA = 'SET_LOGISTICS_DATA'
export const SET_LOGISTICS_EMAIL = 'SET_LOGISTICS_EMAIL'
export const SET_COURIER_PARTNERS = 'SET_COURIER_PARTNERS'
export const SET_MAXIMUM_ALLOWED_COURIER_AMOUNT = 'SET_MAXIMUM_ALLOWED_COURIER_AMOUNT'
export const SET_REPLACEMENT_OPTIONS = 'SET_REPLACEMENT_OPTIONS'
export const SET_ADDRESS_PREVIEW_DETAILS = 'SET_ADDRESS_PREVIEW_DETAILS'
export const CLEAR_TRACK_STATE = 'CLEAR_TRACK_STATE'
export const SET_LOGISTICS_PARTNER_DETAILS = 'SET_LOGISTICS_PARTNER_DETAILS'
export const SET_CANCEL_REASON_MASTER_LIST = 'SET_CANCEL_REASON_MASTER_LIST'
export const SET_HOLD_PAYMENT_STATUS = 'SET_HOLD_PAYMENT_STATUS'
export const SET_REASSIGN_ASC_LOCATION_STATUS = 'SET_REASSIGN_ASC_LOCATION_STATUS'

// ------------------------------------
// Actions
// ------------------------------------

export const setTrackHoldPaymentStatus = (payload) => {
  return {
    type: SET_HOLD_PAYMENT_STATUS,
    payload,
  }
}

export const setReassignServiceLocationFlag = (payload) => {
  console.log('setReassignServiceLocationFlag', payload)
  return {
    type: SET_REASSIGN_ASC_LOCATION_STATUS,
    payload,
  }
}

export const setCancelReasonMasterList = (payload) => {
  return {
    type: SET_CANCEL_REASON_MASTER_LIST,
    payload,
  }
}

export const setTrackDetails = (payload) => {
  return {
    type: SET_TRACK_DETAILS,
    payload,
  }
}

export const setRequestDetails = (payload) => {
  return {
    type: SET_REQUEST_DETAILS,
    payload,
  }
}

export const setPaymentDetails = (payload) => {
  return {
    type: SET_PAYMENT_DETAILS,
    payload,
  }
}

export const setBERModeList = (payload) => {
  return {
    type: SET_BERMODE_LIST,
    payload,
  }
}

export const setLogisticsDetails = (payload) => {
  return {
    type: SET_LOGISTICS_DATA,
    payload,
  }
}

export const setLogisticEmail = (payload) => {
  return {
    type: SET_LOGISTICS_EMAIL,
    payload,
  }
}

export const setCourierPartners = (payload) => {
  return {
    type: SET_COURIER_PARTNERS,
    payload,
  }
}

export const setMaximumAllowedCourierAmount = (payload) => {
  return {
    type: SET_MAXIMUM_ALLOWED_COURIER_AMOUNT,
    payload,
  }
}

export const setReplacementOptions = (payload) => {
  return {
    type: SET_REPLACEMENT_OPTIONS,
    payload,
  }
}

export const setAddressPreviewDetails = (payload) => {
  return {
    type: SET_ADDRESS_PREVIEW_DETAILS,
    payload,
  }
}

export const clearTrackState = () => {
  return {
    type: CLEAR_TRACK_STATE,
  }
}

// Async Actions
export const setDeviceDataforRequest = (data) => {
  return (dispatch) => {
    dispatch(setSelectedDevice(data))
  }
}

export const setLogisticsPartners = (payload) => {
  return {
    type: SET_LOGISTICS_PARTNER_DETAILS,
    payload,
  }
}

export const getLogisticsDetailsAsync = (requestObj) => {
  let body = JSON.stringify({
    ConsumerServiceRequestID: requestObj.ConsumerServiceRequestID,
  })
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/ConsumerServicerequest/getLogisticsRequestDetails', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(setLogisticsDetails(response.data.data))
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const sendLogisticEmailAsync = (requestObj) => {
  let body = JSON.stringify({
    ...requestObj,
  })
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/ConsumerServicerequest/sendLogisticsDoc', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(setLogisticEmail(response.data.data))
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const getSignedUrlAsync = (requestObj, file) => {
  let body = JSON.stringify({
    ...requestObj.data,
    Key: requestObj.data.Key + '.' + requestObj.data.Type.split('/')[1],
  })
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/FileUpload/getUrl', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(
            setSignedUrl({
              filepath: response.data.data,
              id: requestObj.data.Key,
              FileName: file.name,
              Type: file.type,
            })
          )
          return dispatch(uploadImageToS3(response.data.data, requestObj.data.key, file))
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const uploadImageToS3 = (url, uid, file) => {
  let body = file
  return (dispatch) => {
    return axios
      .put(url, body, {
        headers: {
          'Cache-Control': 'public,max-age=3600',
          'x-amz-acl': 'public-read',
          'Content-Type': file.type,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch(setUploadDetails())
          return url.split('?')[0]
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

// Add Consumer Service Request Document to Doc Service
export const addCSRDocToDSAsync = (requestObj) => {
  let body = JSON.stringify(requestObj)
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/RequestDocument/createRequestDocumentsForDS', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          return response.data.data
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
      .catch((err) => {
        throw err
      })
  }
}

export const addUploadedImageToPendingDocsAsync = (requestObj) => {
  let body = JSON.stringify({
    requestDocs: requestObj.data,
  })
  return (dispatch, getState) => {
    // return axios.post('http://staging.servify.in/coreapi/api/RequestDocument/createRequestDocuments', body, {
    return axios
      .post(api.coreUri + '/RequestDocument/createRequestDocuments', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          // debugger // eslint-disable
          return response.data
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const addUploadedImageToModalPendingDocsAsync = (requestObj) => {
  let body = JSON.stringify({ ...requestObj })
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/RequestDocument/createRequestDocuments', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          return response.data
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const updateAccessoriesStatusAsync = (requestObj) => {
  let body = JSON.stringify({
    ...requestObj.data,
  })
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/ConsumerServicerequest/updateAccessoriesStatus', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          // return response.data.data
          // dispatch(setBERModeList(response.data.data))
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const fetchBERModeListAysnc = (requestObj) => {
  let body = JSON.stringify({
    ...requestObj,
  })
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/ConsumerServicerequest/getCurrentStatusScreenDisplayconfig', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(setBERModeList(response.data.data))
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const berModeSelectionAsync = (requestObj) => {
  let body = JSON.stringify({
    ...requestObj.data,
  })
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/ConsumerServicerequest/BERClaimRequest', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          return response.data
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const initiatePaymentAsync = (requestObj) => {
  return (dispatch, getState) => {
    var p = window.location.href.split('/track')
    let body = JSON.stringify({
      ...requestObj.paymentObj,
      CurrencyID: getState().user.selectedCountryData.currencies[0].CurrencyID,
      CountryID: getState().user.selectedCountryData.CountryID,
      redirect_url: p.length > 1 ? p[0] + '/payment?status=1' : window.location.href,
      WebAppPay: true,
    })
    return axios
      .post(api.coreUri + '/OnlinePayment/getPay', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          // return response.data
          dispatch(setPaymentDetails(response.data.data))
          dispatch(setPayableValue(response.data.data))
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const servicefeedbackAsync = (requestObj) => {
  let body = JSON.stringify({
    ConsumerServiceRequestID: requestObj.ConsumerServiceRequestID,
    Rating: requestObj.ratingval,
    UserProblems: requestObj.userProblems,
    Feedback: requestObj.feedback,
  })
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/ConsumerServicerequestFeedback/rateServiceRequest', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(setToaster(getTranslationText('success', 'feedbackSubmitted'), 'success'))
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const updateEstimationAsync = (requestObj) => {
  let body = JSON.stringify({
    ConsumerServiceRequestID: requestObj.ConsumerServiceRequestID,
    IsApproved: requestObj.IsApproved,
  })
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/ConsumerServicerequest/updateEstimation', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (!response.status === 200 || !response.data.success) {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const getInvoiceAsync = (requestObj) => {
  let body = JSON.stringify({
    ConsumerServiceRequestID: requestObj.ConsumerServiceRequestID,
  })
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/ConsumerServicerequest/getInvoice', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          return response.data.data
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const rescheduleSlotsAsync = (requestObj) => {
  let body = JSON.stringify({
    ConsumerServiceRequestID: requestObj.ConsumerServiceRequestID,
    CurrentDate: requestObj.CurrentDate,
    CurrentTime: requestObj.CurrentTime,
    DropOffCenter: requestObj.DropOffCenter,
  })
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/ConsumerServicerequest/rescheduleSlots', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          return response.data.data
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(response.data.msg, 'error'))
          throw err
        }
      })
  }
}

export const rescheduleRequestAsync = (requestObj) => {
  let body = JSON.stringify({
    ConsumerServiceRequestID: requestObj.ConsumerServiceRequestID,
    ScheduledDateTime: requestObj.ScheduledDateTime,
    ScheduledFromTime: requestObj.ScheduledFromTime,
    ScheduledToTime: requestObj.ScheduledToTime,
    Remarks: '',
  })
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/ConsumerServicerequest/rescheduleRequest', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (!response.status === 200 || !response.data.success) {
          let err = new Error(response.data.msg)
          dispatch(setToaster(response.data.msg, 'error'))
          throw err
        } else {
          dispatch(setToaster(getTranslationText('success', 'slotUpdated'), 'success'))
        }
      })
  }
}

export const getCancelReasonDetailsAsync = (requestObj) => {
  let body = JSON.stringify({
    ...requestObj,
    Action: 'Service cancel',
    AppName: 'ConsumerWeb',
  })
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/ConsumerServicerequest/getReasonsForConsumerPortal', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (!response.status === 200 || !response.data.success) {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        } else {
          dispatch(setCancelReasonMasterList(response.data.data))
        }
      })
  }
}

export const cancelRequestAsync = (requestObj) => {
  let body = JSON.stringify({
    ConsumerServiceRequestID: requestObj.ConsumerServiceRequestID,
    Remarks: requestObj.remarks,
  })
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/ConsumerServicerequest/cancelRequest', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (!response.status === 200 || !response.data.success) {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        } else {
          dispatch(setToaster(getTranslationText('success', 'requestCancelled'), 'success'))
        }
      })
  }
}

export const getTrackDetailsAsync = (requestObj) => {
  let body = JSON.stringify({
    ConsumerServiceRequestID: requestObj?.ConsumerServiceRequestID,
    ReferenceID: requestObj?.ReferenceID,
  })
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/ConsumerServicerequest/trackRequest', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(setTrackDetails(response.data.data))
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const getServicetypeDetailsAsync = (requestObj) => {
  return (dispatch, getState) => {
    let body = JSON.stringify(requestObj)
    return axios
      .post(api.coreUri + '/ConsumerServiceRequest/getServicetypeDetails', body, {
        headers: {
          Authorization: getState().user.consumerDetails.data.accessToken.ID,
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster('Something went wrong', 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(setServicetypeDetailsInstruction(response.data.data))
          return response.data
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(response.data.msg, 'error'))
          throw err
        }
      })
  }
}

export const getRequestDetailsAsync = (requestObj) => {
  let body = JSON.stringify({
    ConsumerServiceRequestID: requestObj?.ConsumerServiceRequestID,
    ReferenceID: requestObj?.ReferenceID,
  })
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/ConsumerServicerequest/requestDetails', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(setRequestDetails(response.data.data))
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const treatmentsAsync = (payload, apiEndPoint) => {
  let body = JSON.stringify(payload)
  return (dispatch, getState) => {
    return axios
      .post(`${api.coreUri}/ConsumerServicerequest/${apiEndPoint}`, body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          return response.data.data
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const updateCourierDetailsAsync = (requestObj) => {
  let body = JSON.stringify({
    ...requestObj,
  })
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/SelfCourier/updateCourierDetails', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster('Something went wrong', 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(setToaster(getTranslationText('success', 'courierDetailsUpdated'), 'success'))
          return response
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster('Something went wrong', 'error'))
          throw err
        }
      })
  }
}

export const fetchCourierPartners = () => {
  return (dispatch, getState) => {
    return axios
      .get(api.coreUri + '/Logistics/getCourierVendorList', {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster('Something went wrong', 'error'))
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(setCourierPartners(response.data.data))
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster('Something went wrong', 'error'))
          throw err
        }
      })
  }
}

export const storeReplacementOptions = (requestObj) => {
  let body = JSON.stringify({
    Status: 'Pending for replacement',
    ...requestObj,
  })
  return (dispatch, getState) => {
    return axios
      .post(
        api.coreUri + '/ConsumerServicerequest/replacementFulfillment',
        body,

        {
          headers: {
            Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
            customHeader: true,
          },
        }
      )
      .catch((err) => {
        dispatch(setToaster('Something went wrong', 'error'))
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          return response.data.data
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster('Something went wrong', 'error'))
          throw err
        }
      })
  }
}

export const viewJobSheetDetailsAsync = (requestObj) => {
  let body = JSON.stringify({
    ...requestObj,
  })
  return (dispatch, getState) => {
    return axios
      .post(
        api.coreUri + '/ConsumerServicerequest/fetchJobsheetUrl',
        body,

        {
          headers: {
            Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
            customHeader: true,
          },
        }
      )
      .catch((err) => {
        dispatch(setToaster('Something went wrong', 'error'))
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          return response.data.data
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster('Something went wrong', 'error'))
          throw err
        }
      })
  }
}

export const addReplacementDeviceIMEIAsync = (requestObj) => {
  let body = JSON.stringify({
    ...requestObj,
  })
  return (dispatch, getState) => {
    return axios
      .post(
        api.coreUri + '/ConsumerServicerequest/replacementFulfillment',
        body,

        {
          headers: {
            Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
            customHeader: true,
          },
        }
      )
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong')))
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          return response.data.data
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong')))
          throw err
        }
      })
  }
}

export const getDropOffCentersAsync = (requestObj) => {
  let body = JSON.stringify({
    ...requestObj,
  })
  return (dispatch, getState) => {
    return axios
      .post(
        api.coreUri + '/ConsumerServicerequest/getDropOffCenters',
        body,

        {
          headers: {
            Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
            customHeader: true,
          },
        }
      )
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong')))
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(setLogisticsPartners(response.data.data))
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong')))
          throw err
        }
      })
  }
}

// export const getMaximumAllowedCourierAmount = (reqObj) => {
//   let body = JSON.stringify({
//     ConsumerProductID: reqObj.ConsumerProductID
//   })
//   return (dispatch, getState) => {
//     return axios.post(api.coreUri + '/SelfCourier/getMaximumAllowedCourierAmount', body, {
//       headers: {
//         Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
//         customHeader: true
//       }
//     })
//     .catch(err => {
//       dispatch(setToaster('Something went wrong', 'error'))
//       throw err
//     })
//     .then(response => {
//       if (response.status === 200 && response.data.success) {
//         dispatch(setMaximumAllowedCourierAmount(response.data.data))
//       } else {
//         let err = new Error(response.data.msg)
//         dispatch(setToaster('Something went wrong', 'error'))
//         throw err
//       }
//     })
//   }
// }

export const actions = {
  getTrackDetailsAsync,
  getRequestDetailsAsync,
  getServicetypeDetailsAsync,
  treatmentsAsync,
  rescheduleSlotsAsync,
  rescheduleRequestAsync,
  cancelRequestAsync,
  getInvoiceAsync,
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SET_TRACK_DETAILS]: (state, action) => {
    return { ...state, trackDetails: action.payload }
  },
  [SET_REQUEST_DETAILS]: (state, action) => {
    return { ...state, requestDetails: action.payload }
  },
  [SET_PAYMENT_DETAILS]: (state, action) => {
    return { ...state, paymentDetails: action.payload }
  },
  [SET_BERMODE_LIST]: (state, action) => {
    return {
      ...state,
      trackDetails: { ...state.trackDetails, berModeList: action.payload },
    }
  },
  [SET_LOGISTICS_DATA]: (state, action) => {
    return {
      ...state,
      trackDetails: { ...state.trackDetails, logisticsDetails: action.payload },
    }
  },
  [SET_LOGISTICS_EMAIL]: (state, action) => {
    return {
      ...state,
      trackDetails: { ...state.trackDetails, logisticsEmailDetails: action.payload },
    }
  },
  [SET_COURIER_PARTNERS]: (state, action) => {
    return {
      ...state,
      trackDetails: { ...state.trackDetails, courierDetails: action.payload },
    }
  },
  [SET_MAXIMUM_ALLOWED_COURIER_AMOUNT]: (state, action) => {
    return {
      ...state,
      maximumCourierAmount: action.payload,
    }
  },
  [SET_REPLACEMENT_OPTIONS]: (state, action) => {
    return {
      ...state,
      replacementOptions: action.payload,
    }
  },
  [CLEAR_TRACK_STATE]: () => {
    return {}
  },
  [SET_ADDRESS_PREVIEW_DETAILS]: (state, action) => ({
    ...state,
    [action.payload.key]: action.payload,
  }),
  [SET_LOGISTICS_PARTNER_DETAILS]: (state, action) => ({
    ...state,
    logisticsVendorDetails: action.payload,
  }),
  [SET_CANCEL_REASON_MASTER_LIST]: (state, action) => ({
    ...state,
    requestCancelMasterList: action.payload,
  }),
  [SET_HOLD_PAYMENT_STATUS]: (state, action) => {
    return {
      ...state,
      advanceExchangeHoldPaymentStatus: action.payload,
    }
  },
  [SET_REASSIGN_ASC_LOCATION_STATUS]: (state, action) => {
    return {
      ...state,
      trackDetails: { ...state.trackDetails, isReassignServiceLocationFlow: action.payload },
    }
  },
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}
export default function trackReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
