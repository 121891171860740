import { connect } from 'react-redux'
import {
  updateDevicesAsync,
  addDeviceAsync,
  getProductsList,
  getDeviceRepairHistory,
  getProductDocsAsync,
  authenticateProductsAsync,
  setSeviceEstimatorDevice,
  deleteDeviceProductDocs,
  addProductDocAsync,
  getProductsByBrand,
  checkCancellationEligibility,
  getPlanCancellationOptions,
  cancelContract,
  getBillingHistory,
  deleteDeviceAsync,
  getEligibleDeviceDetailsAsync,
  addDeviceToSubscriptionAsync,
  fetchConsumerProductsForEligiblePlansAsync,
  fetchAllConsumerProductsForEligiblePlansAsync,
  clearDeviceState,
  setEligibleDevicePlansList,
  checkDeviceEligibilityAsync,
  setSelectedEligiblePlanDeviceInfo,
  addProductDocToDSAsync,
  getPaginatedDevicesAsync,
} from '../../../store/devices'
import {
  clearMyPlanDetails,
  setSelectedPurchasedPlanDetails,
  getPurchasedPlansAsync,
  setDeviceAdditionModal,
  fetchCardDetailsAsync,
} from '../../../store/myPlansReducer'
import { getSignedUrlAsync, setTncLink, setPrivacyLink } from '../../../store/user'
import {
  setSelectedDevice,
  fetchEligiblePlansNewAsync,
  setSelectedDamageType,
  getConsumerPlanDetailsAsync,
  clearRequestState,
  loggerActionAsync,
} from '../../../store/request'
import { getPayableAmountForNonLoginFlow } from '../../../store/payment'
import { servicefeedbackAsync, setTrackDetails, setRequestDetails } from '../../../store/track'
import { storeLastKnowPathName } from '../../../store/location'
import { setToaster, setglobalLoaderStatus } from '../../../store/toastsAndLoaders'
/*  This is a container component. Notice it does not contain any JSX,
    nor does it import React. This component is **only** responsible for
    wiring in the actions and state necessary to render a presentational
    component - in this case, the counter:   */

import Devices from '../components/Devices'

/*  Object of action creators (can also be function that returns object).
    Keys will be passed as props to presentational components. Here we are
    implementing our wrapper around increment; the component doesn't care   */

const mapDispatchToProps = {
  updateDevicesAsync,
  addDeviceAsync,
  setToaster,
  getProductsList,
  getDeviceRepairHistory,
  getProductDocsAsync,
  authenticateProductsAsync,
  setSelectedDevice,
  deleteDeviceProductDocs,
  deleteDeviceAsync,
  addProductDocAsync,
  getSignedUrlAsync,
  getProductsByBrand,
  checkCancellationEligibility,
  getPlanCancellationOptions,
  cancelContract,
  getBillingHistory,
  fetchEligiblePlansNewAsync,
  setglobalLoaderStatus,
  setSeviceEstimatorDevice,
  setSelectedDamageType,
  servicefeedbackAsync,
  setTncLink,
  setPrivacyLink,
  getConsumerPlanDetailsAsync,
  clearRequestState,
  setTrackDetails,
  setRequestDetails,
  getEligibleDeviceDetailsAsync,
  addDeviceToSubscriptionAsync,
  fetchConsumerProductsForEligiblePlansAsync,
  fetchAllConsumerProductsForEligiblePlansAsync,
  clearDeviceState,
  clearMyPlanDetails,
  setEligibleDevicePlansList,
  checkDeviceEligibilityAsync,
  setSelectedEligiblePlanDeviceInfo,
  setSelectedPurchasedPlanDetails,
  getPurchasedPlansAsync,
  addProductDocToDSAsync,
  setDeviceAdditionModal,
  loggerActionAsync,
  getPaginatedDevicesAsync,
  fetchCardDetailsAsync,
  getPayableAmountForNonLoginFlow,
  storeLastKnowPathName,
}

const mapStateToProps = (state) => ({
  user: state.user,
  request: state.request,
  devices: state.devices,
  translationData: state.languageDetail.translationText,
  selectedPlanDetails: state.myPlans.selectedPlanDetails,
})

/*  Note: mapStateToProps is where you should use `reselect` to create selectors, ie:

    import { createSelector } from 'reselect'
    const counter = (state) => state.counter
    const tripleCount = createSelector(counter, (count) => count * 3)
    const mapStateToProps = (state) => ({
      counter: tripleCount(state)
    })

    Selectors can compute derived data, allowing Redux to store the minimal possible state.
    Selectors are efficient. A selector is not recomputed unless one of its arguments change.
    Selectors are composable. They can be used as input to other selectors.
    https://github.com/reactjs/reselect    */

export default connect(mapStateToProps, mapDispatchToProps)(Devices)
