import Buttons from './Buttons'
import ReusableComponents from './ReusableComponents'
import SliderComponent_ from './SliderComponent'
import Headings from './Headings'
import Loader_ from './Loader'
import Accordion_ from './Accordion'
import MapComponent_ from './Maps'
import InputComponent_ from './InputComponent'
import SignatureComponent_ from './SignatureCanvas'
import RadioBox_ from './RadioBox'
import TaxAddressForm from './TaxAddressForm'

export const MediumButton = Buttons.MediumButton
export const LargerButton = Buttons.LargerButton
export const PillButton = Buttons.PillButton
// export const LoginInput = ReusableComponents.LoginInput
// export const LoginHeading = ReusableComponents.LoginHeading
export const LoginSubHeading = ReusableComponents.LoginSubHeading
export const FooterHeading = ReusableComponents.FooterHeading
export const FooterMiniHeading = ReusableComponents.FooterMiniHeading
export const FooterText = ReusableComponents.FooterText
export const GetService = ReusableComponents.GetService
export const Heading = ReusableComponents.Heading
export const SubHeading = ReusableComponents.SubHeading
export const Carousel = ReusableComponents.Carousel
export const BoxHolder = ReusableComponents.BoxHolder
export const PillGroup = ReusableComponents.PillGroup
export const TabHolder = ReusableComponents.TabHolder
export const PurpleLink = ReusableComponents.PurpleLink
export const SliderComponent = SliderComponent_
export const BoldHeading = Headings.BoldHeading
export const LightHeading = Headings.LightHeading
export const HorizontalBar = ReusableComponents.HorizontalBar
export const HighlightedContent = ReusableComponents.HighlightedContent
export const ListTypeAhead = ReusableComponents.ListTypeAhead
export const RadioBtnPills = ReusableComponents.RadioBtnPills
export const HomeDeviceOverView = ReusableComponents.HomeDeviceOverView
export const HomeUserLevels = ReusableComponents.HomeUserLevels
export const AddingDeviceWidget = ReusableComponents.AddingDeviceWidget
export const ImageWithDescription = ReusableComponents.ImageWithDescription
export const Loader = Loader_
export const ContactUsModal = ReusableComponents.ContactUsModal
export const RaiseARequestSupportModal = ReusableComponents.RaiseARequestSupportModal
export const CustomIconComponent = ReusableComponents.CustomIconComponent
export const BoxImagesComponent = ReusableComponents.BoxImagesComponent
export const ImageViewerModal = ReusableComponents.ImageViewerModal
export const Accordion = Accordion_
export const MapComponent = MapComponent_
export const InputComponent = InputComponent_
export const ConfirmationPopUp = ReusableComponents.ConfirmationPopUp
export const StepsIntrPopUp = ReusableComponents.StepsIntrPopUp
export const RequestTypeSelection = ReusableComponents.RequestTypeSelection
export const StepFlowPopUp = ReusableComponents.StepFlowPopUp
export const CustomRadioComponent = ReusableComponents.CustomRadioComponent
export const SignatureComponent = SignatureComponent_
export const RadioBox = RadioBox_

export default {
  MediumButton,
  LargerButton,
  PillButton,
  LoginSubHeading,
  FooterHeading,
  FooterMiniHeading,
  FooterText,
  GetService,
  Heading,
  SubHeading,
  Carousel,
  BoxHolder,
  PillGroup,
  TabHolder,
  PurpleLink,
  SliderComponent,
  BoldHeading,
  LightHeading,
  HorizontalBar,
  HighlightedContent,
  ListTypeAhead,
  RadioBtnPills,
  HomeDeviceOverView,
  HomeUserLevels,
  AddingDeviceWidget,
  ImageWithDescription,
  Loader_,
  ContactUsModal,
  RaiseARequestSupportModal,
  CustomIconComponent,
  BoxImagesComponent,
  ImageViewerModal,
  Accordion,
  MapComponent,
  InputComponent,
  ConfirmationPopUp,
  StepsIntrPopUp,
  RequestTypeSelection,
  StepFlowPopUp,
  CustomRadioComponent,
  SignatureComponent,
  RadioBox,
  TaxAddressForm,
}
