import React from 'react'
import './../GuestRoute.scss'

export const LostTheft = () => {
  const planBenfits = [
    {
      title: 'Unlimited Repairs',
      text: `Breathe easy with unlimited repairs including batteries and same day $29 cracked screen repairs at 700+ Samsung authorized locations.`,
      img: `/${appName}/images/mycare/lostTheft/1.svg`,
      url: '',
    },
    {
      title: `Theft and Loss Coverage`,
      text: `Replacing your lost or stolen phone is a breeze so you won't miss a beat in your busy life.`,
      img: `/${appName}/images/mycare/lostTheft/2.svg`,
      url: '',
    },
    {
      title: `Same-Day Replacement and Set Up`,
      text: `Keep calm. We'll replace your lost or stolen phone as soon as the same day and help you set it up.`,
      img: `/${appName}/images/mycare/lostTheft/3.svg`,
      url: '',
    },
    {
      title: 'Knox Guard Security',
      text: `Stay protected with Samsung Knox Guard including remote lock and wipe so your content remains private.`,
      img: `/${appName}/images/mycare/lostTheft/4.svg`,
      url: '',
    },
    {
      title: '24/7 Expert Support',
      text: `Get immediate answers from a Samsung Care+ expert 24/7 via phone or online for device setup, content transfers, and connecting with other devices.`,
      img: `/${appName}/images/mycare/lostTheft/5.svg`,
      url: '',
    },
    {
      title: 'Samsung Genuine Parts and Certified Technicians',
      text: `Rest assured your device will be repaired by a Samsung Certified Technician using Samsung Genuine Parts.`,
      img: `/${appName}/images/mycare/lostTheft/6.svg`,
      url: '',
    },
  ]

  const section2 = [
    { header: 'Tier 1', cost: ['$129', '$129', '$129'] },
    { header: 'Tier 2', cost: ['$189', '$189', '$189'] },
    { header: 'Tier 3', cost: ['$269', '$249', '$249'] },
    { header: 'Tier 4', cost: ['$349', '$299', '$299'] },
  ]
  const section3 = [{ header: 'All Tier', cost: ['$0', '$29', '$29', '$99'] }]
  const section4 = [{ header: 'Deductible', cost: ['$99', '$149', '$149', '$199'] }]

  const section5 = [
    { header: 'Tier 1', cost: ['$8', '$8', '$8'] },
    { header: 'Tier 2', cost: ['$10', '$10', '$10'] },
    { header: 'Tier 3', cost: ['$15', '$13', '$13'] },
    { header: 'Tier 4', cost: ['$18', '$16', '$16'] },
  ]

  const section1mobile = {
    tableHeader: ['Tier 1', 'Tier 2', 'Tier 3', 'Tier 4'],
    tableData: [
      {
        header: { img: `/${appName}/images/mycare/products/mobile.png`, title: 'Phones' },
        data: ['$8', '$10', '$15', '$18'],
      },
      {
        header: { img: `/${appName}/images/mycare/products/tablet.png`, title: 'Tables' },
        data: ['$8', '$10', '$13', '$16'],
      },
      {
        header: { img: `/${appName}/images/mycare/products/watch.png`, title: 'Watches' },
        data: ['$8', '$10', '$13', '$16'],
      },
    ],
  }

  const section2mobile = {
    tableHeader: ['Tier 1', 'Tier 2', 'Tier 3', 'Tier 4'],
    tableData: [
      {
        header: { img: `/${appName}/images/mycare/products/mobile.png`, title: 'Phones' },
        data: ['$129', '$189', '$269', '$349'],
      },
      {
        header: { img: `/${appName}/images/mycare/products/tablet.png`, title: 'Tables' },
        data: ['$129', '$189', '$249', '$299'],
      },
      {
        header: { img: `/${appName}/images/mycare/products/watch.png`, title: 'Watches' },
        data: ['$129', '$189', '$249', '$299'],
      },
    ],
  }

  const section3mobile = {
    tableData: [
      {
        header: { title: 'Mechanical Breakdown' },
        data: ['$0'],
      },
      {
        header: { title: 'Repair or replacement (Watch only)' },
        data: ['$29'],
      },
      {
        header: { title: 'Cracked Screen Repair' },
        data: ['$29'],
      },
      {
        header: { title: 'ADH Repair or Replacement' },
        data: ['$99'],
      },
    ],
  }

  const section4mobile = {
    tableData: [
      {
        header: { title: 'Tier 1' },
        data: ['$99'],
      },
      {
        header: { title: 'Tier 2' },
        data: ['$149'],
      },
      {
        header: { title: 'Tier 3' },
        data: ['$149'],
      },
      {
        header: { title: 'Tier 4' },
        data: ['$199'],
      },
    ],
  }

  return (
    <div className='empty-route-container1 background-white'>
      <div className='row home-components1'>
        <div className='col-sm-12 col-lg-12 mb28 ml8'>
          <h1>Samsung Care+ with Theft and Loss</h1>
          <div className='fontExtraLarge'>
            The Ultimate Protection for your Galaxy life covers Unlimited Drops, Spills, and Mechanical Breakdowns plus Theft and Loss
            coverage
          </div>
        </div>
        {planBenfits.map((item, index) => (
          <div key={index} className='col-sm-12 col-lg-4'>
            <div className='plan-benefit-section1'>
              <div className='plan-benefit-1'>
                <h4 className='boldFontStyle'>{item.title}</h4>
                <img src={item.img} />
              </div>
              <p className='fontLarge'>{item.text}</p>
            </div>
          </div>
        ))}
      </div>
      <div className='row home-components1 mt48 text-center'>
        <div className='col-sm-12 col-lg-12 mt48'>
          <h1>Samsung Care+ with Theft and Loss Monthly Recurring Charge</h1>
          <h4 className='mtb14'>36-month term</h4>
        </div>
        <div className='flex-table-section desktop'>
          <div className='gaps'>
            <div className='table-header'>Device</div>
            <div className='table-container'>
              <div className='table-details-center flex boldFontStyle align-left'>
                <img src={`/${appName}/images/mycare/products/mobile.png`} />
                Phones
              </div>
              <br />
              <div className='table-details-center boldFontStyle align-left'>
                <img src={`/${appName}/images/mycare/products/tablet.png`} />
                Tablets
              </div>
              <br />
              <div className='table-details-center boldFontStyle align-left'>
                <img src={`/${appName}/images/mycare/products/watch.png`} />
                Watches
              </div>
            </div>
          </div>
          {section5.map((value, index) => (
            <div key={index} className='gaps'>
              <div className='table-header'>{value.header}</div>
              <div className='table-container'>
                {value.cost.map((v, i) => (
                  <>
                    <div className='table-details-center boldFontStyle' key={i}>
                      {v}
                    </div>
                    {value.cost.length == i + 1 ? '' : <br />}
                  </>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className='mobile'>
          <div className='table-header-section'>
            {section1mobile.tableHeader?.map((v, i) => (
              <div key={i} className='table-header-title'>
                {v}
              </div>
            ))}
          </div>
          {section1mobile.tableData?.map((v, i) => (
            <div key={i} className='flex-table-section-mobile'>
              <div className='table-details-center boldFontStyle'>
                <img src={v.header.img} />
                {v.header.title}
              </div>
              <div className='table-data'>
                {v.data.map((v, i) => (
                  <div key={i} className='table-details-center font20 boldFontStyle'>
                    {v}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className='row home-components1 mt48 text-center'>
        <div className='col-sm-12 col-lg-12 mt48'>
          <h1>Samsung Care+ with Theft and Loss Pay up Front*</h1>
          <h4 className='mtb14'>24-month term</h4>
        </div>
        <div className='flex-table-section desktop'>
          <div className='gaps'>
            <div className='table-header'>Device</div>
            <div className='table-container'>
              <div className='table-details-center flex boldFontStyle align-left'>
                <img src={`/${appName}/images/mycare/products/mobile.png`} />
                Phones
              </div>
              <br />
              <div className='table-details-center boldFontStyle align-left'>
                <img src={`/${appName}/images/mycare/products/tablet.png`} />
                Tablets
              </div>
              <br />
              <div className='table-details-center boldFontStyle align-left'>
                <img src={`/${appName}/images/mycare/products/watch.png`} />
                Watches
              </div>
            </div>
          </div>
          {section2.map((value, index) => (
            <div key={index} className='gaps'>
              <div className='table-header'>{value.header}</div>
              <div className='table-container'>
                {value.cost.map((v, i) => (
                  <>
                    <div className='table-details-center boldFontStyle' key={i}>
                      {v}
                    </div>
                    {value.cost.length == i + 1 ? '' : <br />}
                  </>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className='mobile'>
          <div className='table-header-section'>
            {section2mobile.tableHeader?.map((v, i) => (
              <div key={i} className='table-header-title'>
                {v}
              </div>
            ))}
          </div>
          {section2mobile.tableData?.map((v, i) => (
            <div key={i} className='flex-table-section-mobile'>
              <div className='table-details-center boldFontStyle'>
                <img src={v.header.img} />
                {v.header.title}
              </div>
              <div className='table-data'>
                {v.data.map((v, i) => (
                  <div key={i} className='table-details-center font20 boldFontStyle'>
                    {v}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className='col-sm-12 col-lg-12'>
          <p className='text-center mt20 fontLarge'>*Samsung Care+ with Theft and Loss Pay Up Front not available for NY residents</p>
        </div>
      </div>

      <div className='row home-components1 mt48 text-center'>
        <div className='col-sm-12 col-lg-12 mt48 mb28'>
          <h1>Samsung Care+ Service Fees</h1>
        </div>
        <div className='flex-table-section desktop custome'>
          <div className='gaps'>
            <div className='table-header width20rem'>Service Type</div>
            <div className='table-container width20rem'>
              <div className='table-details-center boldFontStyle'>Mechanical Breakdowns</div>
              <br />
              <div className='table-details-center boldFontStyle'>Repair or replacement (Watch only)</div>
              <br />
              <div className='table-details-center boldFontStyle'>Cracked Screen Repair</div>
              <br />
              <div className='table-details-center boldFontStyle'>ADH Repair or Replacement</div>
            </div>
          </div>
          {section3.map((value, index) => (
            <div key={index} className='gaps'>
              <div className='table-header'>{value.header}</div>
              <div className='table-container'>
                {value.cost.map((v, i) => (
                  <>
                    <div className='table-details-center boldFontStyle' key={i}>
                      {v}
                    </div>
                    {value.cost.length == i + 1 ? '' : <br />}
                  </>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className='mobile'>
          {section3mobile.tableData?.map((v, i) => (
            <div key={i} className='flex-table-section-mobile'>
              <div className='table-details-center boldFontStyle'>{v.header.title}</div>
              <div className='table-data'>
                {v.data.map((v, i) => (
                  <div key={i} className='table-details-center font20 boldFontStyle'>
                    {v}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className='row home-components1 mt48 text-center'>
        <div className='col-sm-12 col-lg-12 mt48 mb28'>
          <h1>Samsung Care+ with Theft and Loss Deductible</h1>
        </div>
        <div className='flex-table-section desktop'>
          <div className='gaps'>
            <div className='table-header width20rem'>Device Tier</div>
            <div className='table-container width20rem'>
              <div className='table-details-center boldFontStyle'>Tier 1</div>
              <br />
              <div className='table-details-center boldFontStyle'>Tier 2</div>
              <br />
              <div className='table-details-center boldFontStyle'>Tier 3</div>
              <br />
              <div className='table-details-center boldFontStyle'>Tier 4</div>
            </div>
          </div>
          {section4.map((value, index) => (
            <div key={index} className='gaps'>
              <div className='table-header'>{value.header}</div>
              <div className='table-container'>
                {value.cost.map((v, i) => (
                  <>
                    <div className='table-details-center boldFontStyle' key={i}>
                      {v}
                    </div>
                    {value.cost.length == i + 1 ? '' : <br />}
                  </>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className='mobile'>
          {section4mobile.tableData?.map((v, i) => (
            <div key={i} className='flex-table-section-mobile'>
              <div className='table-details-center boldFontStyle'>{v.header.title}</div>
              <div className='table-data'>
                {v.data.map((v, i) => (
                  <div key={i} className='table-details-center font20 boldFontStyle'>
                    {v}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export const LostTheftTnc = () => {
  const tnc = [
    {
      title: 'What is covered:',
      conditions: [],
      updatedConditions: [
        { title: 'Insurance:', description: [`Lost, stolen, and unrecoverable (excluding ADH).`] },
        {
          title: 'Service contract:',
          description: [
            `Accidental Damage from Handling (ADH) - protection begins on the date of enrollment.`,
            `Mechanical breakdowns due to defects in materials or workmanship or normal wear and tear (malfunction) - protection begins after the manufacturer's warranty expires.`,
            `24/7 Expert support: Protection begins on the date of enrollment.`,
          ],
        },
      ],
    },

    {
      title: 'Claim limits:',
      conditions: [],
      updatedConditions: [
        {
          title: 'Insurance:',
          description: [`Maximum of three (3) claims within any 12 consecutive month period.`, `Maximum value of $2,500 per claim.`],
        },
        {
          title: 'Service contract:',
          description: [
            `Unlimited ADH claims`,
            `$0 Unlimited mechanical breakdowns claims up to the MSRP value of the device per 12-month period.`,
            `Maximum value of $2,500 per claim.`,
          ],
        },
      ],
    },

    {
      title: 'Service contract:',
      conditions: [
        `Accidental Damage from Handling (ADH) - protection begins on the date of enrollment.`,
        `Mechanical breakdowns due to defects in materials or workmanship or normal wear and tear (malfunction) – protection begins after the manufacturer’s warranty expires.`,
        `24/7 Expert support: Protection begins on the date of enrollment.`,
      ],
      subtext: '',
    },

    {
      title: 'Eligible devices:',
      conditions: [`Phones, Tablets, and Watches`],
      subtext: '',
    },

    {
      title: 'Service options you can trust:',
      conditions: [
        `Same-day replacement for lost or stolen devices based on location and subject to stock availability.`,
        `Walk-in repair based on location and subject to parts, technician, and location availability`,
        `Advance exchange - next day device replacement`,
        `Mail-in Repair`,
      ],
      subtext: '',
    },

    {
      title: 'Cancellation policy:',
      conditions: [`Cancel at any time for a prorated refund.`],
      subtext: '',
    },

    {
      title: 'Service fees:',
      conditions: [
        `A non-refundable service fee will be charged for each approved accidental damage claim. All applicable taxes and surcharges apply. Offers may be modified or discounted at any time`,
      ],
      subtext: '',
    },

    {
      title: '',
      conditions: [],
      subtext:
        'Eligible devices and available markets are subject to change at any time. <br/> * All plan offerings may not be available in every state',
    },
  ]

  const section2 = [
    {
      title: 'Enroll',
      text: `<p>Visit a Samsung store or go to <a href='https://samsungcareplus.com/' target='_blank'>samsungcareplus.com</a></p>`,
      img: `/${appName}/images/mycare/section3/6.svg`,
      url: '',
    },
    {
      title: 'Submit a claim',
      text: `<p>If your device is damaged or not working, visit samsung. <a href='https://samsung.servify.tech/us' target='_blank'>samsung.servify.tech/us</a> or call (833) 690-0918. 24 hours a day, 7 days a week. <br/> Claims must be filed within 30 days of the incident.</p>`,
      img: `/${appName}/images/mycare/section2/3.svg`,
      url: '',
    },
    {
      title: 'Contact 24/7 Expert Support',
      text: `<p>From set-up to troubleshooting, visit <a href='https://samsung.servify.tech/us' target='_blank'>samsung.servify.tech/us</a> or call (833) 690-0918.</p>`,
      img: `/${appName}/images/mycare/section3/3.svg`,
      url: '',
    },
  ]
  return (
    <>
      <div className='empty-route-container1'>
        <div className='row home-components1'>
          <div className='col-sm-12 col-lg-12 mb20'>
            <h1>How does it work?</h1>
          </div>
          {section2.map((item, index) => (
            <div key={index} className='col-sm-12 col-lg-4'>
              <div className='box-holder1 section3'>
                <img src={item.img} />
                <h4 className='boldFontStyle'>{item.title}</h4>
                <span className='fontLarge' dangerouslySetInnerHTML={{ __html: item.text }} />
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className='empty-route-container1 background-white'>
        <div className='row flex home-components1'>
          <div className='col-sm-12 col-lg-12'>
            <h1 className='mb28'>Plan Coverage Summary*</h1>
            {tnc.map((item, index) => (
              <div key={index} className='terms-listing'>
                <div className='boldFontStyle fontLarge mb15'>{item.title}</div>
                <ul>
                  {item.conditions &&
                    item.conditions.map((value, index) => (
                      <li key={index} className='bookFontStyle fontLarge'>
                        {value}
                      </li>
                    ))}
                  {item.updatedConditions &&
                    item.updatedConditions.map((value1) => (
                      <>
                        <div className='mt20 mb8 fontLarge'>{value1.title}</div>
                        {value1.description.map((v, index) => (
                          <li key={index} className='bookFontStyle fontLarge'>
                            {v}
                          </li>
                        ))}
                      </>
                    ))}
                </ul>
                <div className='bookFontStyle fontLarge' dangerouslySetInnerHTML={{ __html: item.subtext }} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}
