import React, { Component } from 'react'
import { Link } from 'react-router'
import PropTypes from 'prop-types'
import changeStyle from './changeStyle'
import themedVariable from '../../../styles/_base.scss'
import './Buttons.scss'
import { Button as SFYButton } from 'sfy-react'
class Button extends Component {
  static propTypes = {
    styles: PropTypes.object,
    buttonText: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    className: PropTypes.string,
    id: PropTypes.string,
    disabled: PropTypes.bool,
    link: PropTypes.string,
    btnLoader: PropTypes.bool,
    showImg: PropTypes.bool,
    action: PropTypes.string,
    imgSource: PropTypes.string,
  }

  render() {
    if (this.props.link) {
      return (
        <span>
          <Link to={this.props.link}>
            <button
              dir={this.props.dir}
              className={'button ' + (this.props.className || '')}
              id={this.props.id}
              style={this.props.styles.button}
              disabled={this.props.disabled || this.props.btnLoader}
            >
              <div className='align-middle'>
                <div className='btn-txt vertical-align-middle'>{this.props.buttonText}</div>
                {this.props.btnLoader && (
                  <div className='btn-loader-container'>
                    <div className='btn-level-loader' />
                  </div>
                )}
              </div>
            </button>
          </Link>
        </span>
      )
    } else {
      return (
        <SFYButton
          type={this.props.action || 'button'}
          classes={`button-primary ${this.props.className || ''}`}
          handleOnClick={this.props.onClick}
          isDisabled={this.props.disabled || this.props.btnLoader}
          text={!this.props.btnLoader && this.props.buttonText}
          isLoading={this.props.btnLoader}
          style={this.props.styles ? this.props.styles.button : {}}
          id={this.props.id}
          dir={this.props.dir}
        >
          <div className='align-middle'>{this.props.showImg && <img src={this.props.imgSource} />}</div>
        </SFYButton>
      )
    }
  }
}

export const PillButton = changeStyle(Button, {
  button: {
    height: 'auto',
    minWidth: '120px',
    border: '1px solid var(--black-400)',
    padding: '10px 16px',
    margin: '0 16px 16px 0',
    background: 'transparent',
    color: 'var(--black-700)',
    fontFamily: themedVariable.lightFontFamily,
    fontSize: '14px',
    // fontSize: '14px',
    borderRadius: '40px',
    objectFit: 'contain',
  },
})

export const MediumButton = Button

export const LargerButton = changeStyle(Button, {
  button: {
    padding: '14px 60px',
    fontFamily: themedVariable.bookFontFamily,
    fontSize: '16px',
    borderRadius: '3px',
  },
})
