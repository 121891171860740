import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Link, browserHistory } from 'react-router'
import Confetti from 'react-confetti'
import useWindowSize from 'react-use/lib/useWindowSize'
import {
  BoxHolder,
  Heading,
  MediumButton,
  HomeDeviceOverView,
  RadioBtnPills,
  Loader,
  Accordion,
  CustomIconComponent,
  BoxImagesComponent,
  AddingDeviceWidget,
  PillGroup,
  InputComponent,
  ImageViewerModal,
  SubHeading,
  RadioBox,
} from '../../../components/UIComponents'
import TaxAddressForm from '../../../components/UIComponents/TaxAddressForm'
import AddressBox from '../../../components/AddressBox'
import {
  Carousel,
  Input,
  Dropdown,
  Modal,
  Checkbox,
  DatePicker,
  Captcha,
  getCaptcha,
  Loader as SFYLoader,
  Button,
  Tooltip,
  DotProgressBar,
} from 'sfy-react'
const activeFirst = '/commonAssets/Request/NavBar/active-first.png'
const deactiveMiddle = '/commonAssets/Request/NavBar/deactive-middle.png'
const deactiveLast = '/commonAssets/Request/NavBar/deactive-last.png'
const informationImg = '/commonAssets/information.png'
const errorImg = '/commonAssets/failed.png'
const discountImg = '/commonAssets/discount.svg'
const discountWhiteImg = '/commonAssets/discount-white.svg'
const up = '/commonAssets/up.svg'
const down = '/commonAssets/down.svg'
import { ReactComponent as UpIcon } from '../../../../public/commonAssets/up.svg'
import { ReactComponent as DownIcon } from '../../../../public/commonAssets/down.svg'
import { ReactComponent as Disable } from '../../../../public/commonAssets/disable.svg'
import moment from 'moment'

import { UserDetailsComponent, MobileNoComponent, OTPComponent } from '../../Login/components/LoginSubComponent'
import { BrandSpecificConfig, legalConfigCodes, legalConfigAttributes, fallbackAddressConfig } from '../../../utils/constants'
import _ from 'lodash'
import { getTranslationText } from '../../../utils/languageTranslation.js'
import pushToAnalytics from '../../../utils/Analytics'
import {
  getLegalConsents,
  getConsentTextChunks,
  replacePlaceholders,
  getPlaceholders,
  hasConsent,
  getFormattedAddress,
} from '../../../utils/functions'
import { regexCheck } from '../../../utils/regex'
import { getLocaleCurrency, getLocaleDate } from '../../../utils/locale'
import { ReactComponent as AddIcon } from '../../../../public/commonAssets/add-plus.svg'
import { ReactComponent as InfoIcon } from '../../../../public/commonAssets/info-alert.svg'
import { ReactComponent as DownArrow } from '../../../../public/commonAssets/down-arrow.svg'
import { ReactComponent as CalendarIcon } from '../../../../public/commonAssets/calendar.svg'
import { ReactComponent as RightArrow } from '../../../../public/commonAssets/right-arrow.svg'
import { ReactComponent as SearchIcon } from '../../../../public/commonAssets/Search.svg'
import {
  getPlansFromImei,
  setImeiForFilter,
  setPlansListForImei,
  setUserData,
  setConsumerAddress,
  saveZipcodeOrState,
} from '../../../store/storeReducer'
import { useDispatch, useSelector } from 'react-redux'

export const HomeSideWidgets = (props) => {
  return (
    <div className='col-sm-12 col-lg-3'>
      <div className='row'>
        <div className='col-sm-6 col-lg-12'>
          <HomeDeviceOverView {...props} deviceOverviewState={props.deviceOverviewState} />
        </div>
        {_.get(this, 'props.user.appConfigDetails.showAddDevice', false) && (
          <div className='col-sm-6 col-lg-12'>
            <AddingDeviceWidget {...props} />
          </div>
        )}
      </div>
    </div>
  )
}
HomeSideWidgets.propTypes = {
  deviceOverviewState: PropTypes.object.isRequired,
  userDeviceStateLoader: PropTypes.bool,
}

// Store landing Page compoenents

const Tab = (props) => {
  return (
    <div
      id={props.id}
      className={'store-tab-plans fontMedium overrideCasing ' + (props.id === parseInt(props.selectedTab) ? 'active' : '')}
      onClick={() => {
        props.handleClick(props.id)
      }}
    >
      <div className={props.id === parseInt(props.selectedTab) ? ' boldFontStyle ' : ' mediumFontStyle'}>{props.children}</div>
    </div>
  )
}

Tab.propTypes = {
  id: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  handleClick: PropTypes.func.isRequired,
  selectedTab: PropTypes.number.isRequired,
}

export class StoreLandingPage extends React.Component {
  componentDidMount() {
    if (!/checkout/.test(this.props.location.pathname) && this.props.store.planPaymentDetails) {
      this.props.clearexistingPaymentDetails()
      this.props.setPlanConsumerProduct(undefined)
    }
    this.props.loadLandingPageBanner()
  }

  render() {
    let storeBannersList =
      this.props.storeBannersList && this.props.storeBannersList.length
        ? window.isDirectionRTL
          ? this.props.storeBannersList.reverse()
          : this.props.storeBannersList
        : ''
    return (
      <div className='row mt20'>
        <div className={'col-sm-12 ' + (this.props.user.isAnGuestUser ? '' : 'col-lg-9')}>
          <div className='store-landing-components'>
            {storeBannersList && storeBannersList.length ? (
              <div className='banner-slider mb4'>
                <Carousel
                  items={storeBannersList.filter((item) => item)}
                  showDots
                  showArrows={false}
                  className={{
                    dotContainer: 'sfy-slider-dots',
                  }}
                  individualBanner={(banner) => (
                    <div className='slider-slide-img' key={banner.orderPreference}>
                      <img src={banner.imagePath} />
                      <div className='banner-text-section'>
                        <div className='slider-plainTxt-content'>
                          <div className='slider-main-heading mb20 boldFontStyle slider-heading-color'>
                            <span dangerouslySetInnerHTML={{ __html: banner.Main_Text }} />
                          </div>
                          <Heading title={banner.Sub_Text} className='slider-heading-color' />
                          {banner.Button_Text && (
                            <MediumButton
                              className='button-small mt28'
                              buttonText={banner.Button_Text}
                              id={banner.BrandName + '-' + banner.PlanType}
                              onClick={banner.onClick || this.props.handleBannerNav}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                />
              </div>
            ) : (
              ''
            )}
            <div className='row'>
              <div className='col-sm-12'>
                {this.props.store.availableBrandsList ? (
                  <BrandSpecificPlansComponent
                    dataList={this.props.store.availableBrandsList}
                    MainTitle={getTranslationText('store', 'brandList')}
                    containOffers={false}
                    handleStoreItemSelection={this.props.handleStoreItemSelection}
                  />
                ) : (
                  <div />
                )}
              </div>
            </div>
          </div>
        </div>
        {!this.props.user.isAnGuestUser && <HomeSideWidgets {...this.props} />}
      </div>
    )
  }
}
StoreLandingPage.propTypes = {
  user: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
  MockDataList: PropTypes.array,
  handleStoreItemSelection: PropTypes.func.isRequired,
  availableBrandsList: PropTypes.object,
  clearexistingPaymentDetails: PropTypes.func.isRequired,
  handleBannerNav: PropTypes.func.isRequired,
  storeBannersList: PropTypes.array.isRequired,
  setPlanConsumerProduct: PropTypes.func.isRequired,
}

export const BrandSpecificPlansComponent = (props) => {
  return (
    <BoxHolder className='fullWidth'>
      <div className='row'>
        <div className='col-sm-9 mb20'>
          <Heading wordBreak title={props.MainTitle} />
        </div>
        <div className='col-sm-12'>
          <div className='row'>
            {_.get(props, 'dataList', []).length > 0 &&
              props.dataList.map((data, index) => {
                return (
                  <div
                    key={index}
                    className='col-sm-6 col-lg-4 store-brand-List-container'
                    onClick={props.handleStoreItemSelection}
                    id={'Brand-' + data.BrandName}
                  >
                    <div className='brand-img-box'>
                      <img src={data.ImageUrl} />
                    </div>
                    <div className='brand-descp'>
                      <div className='boldFontStyle fontMedium special-link-txt'>{data.BrandName}</div>
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    </BoxHolder>
  )
}
BrandSpecificPlansComponent.propTypes = {
  MainTitle: PropTypes.string.isRequired,
  dataList: PropTypes.array.isRequired,
  handleStoreItemSelection: PropTypes.func.isRequired,
}

// View Product related info under store
export const ViewProductStoreComponent = (props) => (
  <div className='row mt20'>
    <div className={'col-sm-12 ' + (props.user.isAnGuestUser ? '' : 'col-lg-9')}>
      <ListofPlans {...props} />
    </div>
    {props.user.isAnGuestUser ? '' : <HomeSideWidgets {...props} />}
  </div>
)
ViewProductStoreComponent.propTypes = {
  user: PropTypes.object.isRequired,
}

const Banners = (props) => (
  <div key={props.BannerID} className='slider-slide-img brand-img-holder'>
    <img src={props.ImageUrl} />
    {props.BannerText && (
      <div className='banner-text-section'>
        <div className='slider-plainTxt-content'>
          <div className='store-slider-main-heading slider-heading-color mb20'>
            <span dangerouslySetInnerHTML={{ __html: props.BannerText }} />
          </div>
        </div>
      </div>
    )}
  </div>
)

Banners.defaultProps = {
  BannerText: '',
}

Banners.propTypes = {
  BannerID: PropTypes.number.isRequired,
  ImageUrl: PropTypes.string.isRequired,
  BannerText: PropTypes.string,
}

export const FindPlansForImei = ({ store }) => {
  let imeiFromToken = _.get(store, 'imeiFromToken')
  const imeiForFilter = _.get(store, 'imeiForFilter')
  const [imeiOrSerial, setImeiOrSerial] = useState(imeiFromToken || imeiForFilter)
  const [showLoader, setShowLoader] = useState(false)
  const [validationMatrix, setValidationMatrix] = useState({
    imei: {
      lengthObj: [15],
      type: 'numeric',
    },
    serial: {
      lengthObj: [11],
      type: 'alphanumeric',
    },
  })

  const dispatch = useDispatch()

  const user = useSelector((state) => state.user)
  const fetchingPlans = useSelector((state) => state.store.fetchingPlans) || false

  const loadPlansForImei = (value) => {
    getPlansFromImei(
      dispatch,
      {
        imeiOrSerial: value,
        BrandID: store.selectedBrand.BrandID,
        app: BrandSpecificConfig().header,
        CountryID: user.selectedCountryData.CountryID,
        ConsumerID: _.get(user, 'userDetails.consumerID', undefined),
        EmailID: _.get(user, 'userDetails.emailID', undefined),
        MobileNo: _.get(user, 'userDetails.mobileNo', undefined),
      },
      user,
      store
    )
  }
  const handleOnChange = (e) => {
    Object.keys(validationMatrix).forEach((key) => {
      if (regexCheck('onChange', validationMatrix[key].type, e.target.value, Math.max(...validationMatrix[key].lengthObj))) {
        setImeiOrSerial(e.target.value)
        return false
      }
    })
    if (
      (regexCheck('validation', 'hasAlphachars', e.target.value) &&
        validationMatrix.serialnumber?.lengthObj.includes(e.target.value.length)) ||
      validationMatrix.imei?.lengthObj.includes(e.target.value.length)
    ) {
      setShowLoader(true)
      loadPlansForImei(e.target.value)
    }
  }

  useEffect(() => {
    store.brandPlansListForImei?.data && dispatch(setImeiForFilter(imeiOrSerial))
  }, [store.brandPlansListForImei?.data, dispatch, imeiOrSerial])

  useEffect(() => {
    setShowLoader(fetchingPlans)
  }, [fetchingPlans])

  useEffect(() => {
    const handleBackButtonPress = (e) => {
      if (
        e.key === 'Backspace' &&
        ((regexCheck('validation', 'hasAlphachars', e.target.value) &&
          validationMatrix.serialnumber?.lengthObj.includes(e.target.value.length)) ||
          validationMatrix.imei?.lengthObj.includes(e.target.value.length))
      ) {
        dispatch(setImeiForFilter(''))
        dispatch(setPlansListForImei([]))
      }
    }

    window.addEventListener('keydown', handleBackButtonPress)
    return () => {
      window.removeEventListener('keydown', handleBackButtonPress)
    }
  }, [validationMatrix, dispatch])

  useEffect(() => {
    const brandDetails = store.availableBrandsList.find((brandDetails) => brandDetails.BrandID === store.selectedBrand.BrandID)
    const brandSpecificValidationObj = brandDetails?.WarrantyCheckDetails?.AllowedValues
    const modifiedValidationObject = {}
    brandSpecificValidationObj?.forEach((obj) => {
      const objType = obj.Type.toLowerCase()
      if (modifiedValidationObject[objType]) {
        modifiedValidationObject[objType].lengthObj.push(obj.Length)
      } else {
        modifiedValidationObject[objType] = {
          lengthObj: [obj.Length],
          type: obj.ExtraFilter.toLowerCase(),
        }
      }
    })
    setValidationMatrix(modifiedValidationObject)
  }, [store.availableBrandsList, store.selectedBrand])

  return (
    <>
      <BoxHolder className='p0'>
        <div className='plansFilterContainer'>
          <div className='mediumFontStyle fontMedium overrideCasing mb8'>{getTranslationText('store', 'filterPlans')}</div>
          <div className={`plansFilter overview-wrapper mb4 ${imeiFromToken && imeiFromToken.length > 0 ? 'disablePlanFilter' : ''}`}>
            <SearchIcon className='search-icon-input' />
            <span className='search-input filter-input'>
              <input
                className='form-control bookFontStyle fontMedium'
                id='search-plan-input'
                type='text'
                placeholder={getTranslationText('store', 'enterIMEIorSerial')}
                value={imeiOrSerial}
                aria-label='Search'
                onChange={handleOnChange}
                autoComplete='off'
                disabled={imeiFromToken && imeiFromToken.length > 0}
              />
            </span>
            {imeiOrSerial && imeiOrSerial.length > 0 && !showLoader && !imeiFromToken && (
              <span
                className='cross-icon'
                onClick={() => {
                  dispatch(setImeiForFilter(''))
                  setImeiOrSerial('')
                  dispatch(setPlansListForImei([]))
                }}
              >
                &#x2715;
              </span>
            )}
            {showLoader && <span className='content-loader align-right'></span>}
          </div>
          <div className='mt4 bookFontStyle fontMicro'>
            {getTranslationText('store', 'IMEIHintLabel')}
            <Tooltip
              content={getTranslationText(
                'store',
                _.get(user, 'appConfigDetails.app', '') === 'SamsungEU-Web' ? 'IMEIHintTextShort' : 'IMEIHintText'
              )}
              position='bottom'
            >
              <InfoIcon className='ml4' />
            </Tooltip>
          </div>
          {store.brandPlansListForImei?.error !== 'no plans' && (
            <div className='mt4 error bookFontStyle fontMicro'>{store.brandPlansListForImei?.error}</div>
          )}
        </div>
      </BoxHolder>
      <br />
    </>
  )
}

export const ListofPlans = (props) => {
  const cardRef = useRef([])

  let priceArr = []
  let plansList = props.store.brandPlansList || []
  let plansForImei = props.store.brandPlansListForImei || []
  const plansToBeShown = plansForImei?.data?.length ? plansForImei?.data : plansList
  let brandBannersList = _.get(props, 'store.brandBannersList', []).length
    ? window.isDirectionRTL
      ? props.store.brandBannersList.reverse()
      : props.store.brandBannersList
    : ''
  const locale = _.get(props, 'user.selectedCountryData.countryConfig.Locale.DEFAULT', 'en')
  const currencyCode = _.get(props, 'user.selectedCountryData.CurrencyCode')
  const showDiscount = true
  const newFlow = props.isNewPlanSalesFlow
  const loggedIn = _.get(props, 'user.userDetails.consumerID', false)
  const getEMIPrice = (planObj) => {
    if (planObj && planObj.planPrice && planObj.planPrice.planPriceBillingConfig && planObj.planPrice.planPriceBillingConfig.length > 0) {
      let emiObj = planObj.planPrice.planPriceBillingConfig.filter((obj) => {
        return obj.FrequencyUnitDisplay === 'month'
      })
      return (emiObj.length > 0 && (emiObj[0].TotalEMIAmount || emiObj[0].EMIInsuranceBasePrice + emiObj[0].EMIServiceBasePrice)) || 0
    }
    return (planObj && (planObj.TotalEMIAmount || planObj.EMIInsuranceBasePrice + planObj.EMIServiceBasePrice)) || 0
  }
  const hasDiscountToShow = (offer) => {
    return (
      offer.length === 1 &&
      (offer[0].DiscountedBasePlanPrice > 0 || offer[0].DiscountedTotalPlanPrice > 0 || offer[0].hasConcession) &&
      offer[0].requiredFieldsPresent &&
      offer[0].DiscountCategory !== 'PromoCode'
    )
  }
  useEffect(() => {
    const handleOnResize = () => {
      if (window.innerWidth < 680) return
      if (cardRef.current) {
        const cardElements = cardRef.current
        let max = 0
        cardElements &&
          Object.keys(cardElements).length > 0 &&
          Object.values(cardElements).forEach((card) => {
            if (card) card.style.height = `auto`
          })
        setTimeout(() => {
          // if (props.isNewPlanSalesFlow)
          {
            cardElements &&
              Object.keys(cardElements).length > 0 &&
              Object.values(cardElements).forEach((card) => {
                const cardHeight = card && card.getBoundingClientRect() ? card.getBoundingClientRect().height : 0
                if (cardHeight > max) {
                  max = cardHeight
                }
              })
            cardElements &&
              Object.keys(cardElements).length > 0 &&
              Object.values(cardElements).forEach((card) => {
                if (card) card.style.height = `${max}px`
              })
          }
        }, 100)
      }
    }
    handleOnResize()
    window.addEventListener('resize', handleOnResize)
    return () => {
      window.removeEventListener('resize', handleOnResize)
    }
  }, [plansToBeShown])

  return props.store.availableBrandsList && plansList.length ? (
    <div className='store-view-container'>
      <BoxHolder className='plan-info-container'>
        {_.get(props, 'store.availableBrandsList', []).length === 1 ? (
          ''
        ) : (
          <Link className='bck-btn mb28 mediumFontStyle fontMedium special-link-txt' to='/store' params={{ ...props.location.query }}>
            <div className='bck-img-container'>
              <DownArrow width='12' height='12' />
            </div>
            <span>{getTranslationText('common', 'backLabel')}</span>
          </Link>
        )}
        {_.get(props, 'store.brandBannersList', []).length ? (
          <div className='row'>
            <div className='col-sm-12 banner-slider'>
              <Carousel
                items={props.store.brandBannersList.filter((item) => item)}
                showDots
                showArrows={false}
                className={{
                  dotContainer: 'sfy-slider-dots',
                }}
                individualBanner={(banner) => <Banners {...banner} />}
              />
            </div>
            {brandBannersList && brandBannersList.length > 0 && brandBannersList[0].BannerDesc && (
              <div className='col-sm-12 mt20 bookFontStyle fontMedium bannerDesc'>
                <span dangerouslySetInnerHTML={{ __html: brandBannersList[0].BannerDesc }} />
              </div>
            )}
          </div>
        ) : (
          ''
        )}
      </BoxHolder>
      <br />
      {newFlow ? (
        <FindPlansForImei store={props.store} eligibilityValueOnChange={props.eligibilityValueOnChange} />
      ) : (
        ''
        // <div className='store-tab-section'>
        //   {_.compact(
        //     plansList.map((List, rootIndex) => {
        //       return (
        //         <Tab
        //           key={rootIndex}
        //           id={rootIndex}
        //           selectedTab={props.planViewSelectedTab}
        //           handleClick={() => props.listPlanViewDetails(rootIndex)}
        //         >
        //           {List.Heading}
        //         </Tab>
        //       )
        //     })
        //   )}
        // </div>
      )}
      <BoxHolder className={'p20'}>
        {plansForImei?.data?.length > 0 && <div className='mb20'> {getTranslationText('store', 'plansEligibleTitle')}</div>}
        {plansForImei?.error === 'no plans' ? (
          <div className='flex no-plans-container'>
            <Disable className='mr8' />
            {getTranslationText('store', 'noPlansForDevice')}{' '}
          </div>
        ) : (
          <div className='list-of-plans-container'>
            {_.compact(
              plansToBeShown.map((List, rootIndex) => {
                // if (newFlow || props.planViewSelectedTab === rootIndex)
                {
                  return (
                    <div className='mb10' key={rootIndex}>
                      <div className='mediumFontStyle fontMedium overrideCasing mb20'>
                        {List.Heading + ' (' + List.PlanList.length + ')'}
                      </div>
                      {List.Message?.length > 0 && <div className='no-plans-for-category-message'>{List.Message}</div>}
                      <div className='row m0' key={rootIndex}>
                        {List.PlanList.map((plan, index) => {
                          if (List.IsCustomPlan) {
                            return (
                              <div key={plan.PlanCode} className='col-12'>
                                {getTranslationText('store', 'customizePlanMessage')}
                                {getTranslationText('store', 'customizeAdditional')}
                                <div className='mt4'>
                                  <div className='special-link-txt' id={plan.PlanCode} onClick={props.viewSelectedPlan}>
                                    <span className='overrideCasing mediumFontStyle'>{getTranslationText('store', 'startCustomize')}</span>
                                    <span>
                                      <RightArrow />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )
                          } else {
                            /* Show discounts in plans list
                        get discounts for both upfront and monthly types to show discounted prices individually
                        get all discounts also to show aviilable discount options as a tooltip
                        */
                            const discountOnFullPayment = props.getDiscountsByType(plan.PlanDiscounts, 'UPFRONT')
                            const discountOnMonthly = props.getDiscountsByType(plan.PlanDiscounts, 'MONTHLY')
                            const discountsAll = _.get(plan, 'PlanDiscounts.PlanDiscountOffers', [])
                            const discountOffersToShow = discountsAll.filter((offer) => {
                              if (
                                discountOnFullPayment.length === 1 &&
                                discountOnFullPayment[0].PlanDiscountConfigurationID === offer.PlanDiscountConfigurationID &&
                                hasDiscountToShow(discountOnFullPayment)
                              )
                                return false
                              if (
                                discountOnMonthly.length === 1 &&
                                discountOnMonthly[0].PlanDiscountConfigurationID === offer.PlanDiscountConfigurationID &&
                                hasDiscountToShow(discountOnMonthly)
                              )
                                return false
                              return true
                            })
                            const emiPrice = getEMIPrice(plan)
                            return (
                              <div key={index} className={`${!loggedIn ? 'plans-details-container' : 'col-md-6'}`}>
                                <div className='list-of-plans mb20 transitionClass' id={plan.PlanCode} onClick={props.viewSelectedPlan}>
                                  <div ref={(el) => (cardRef.current[plan.PlanCode] = el)} className='plan-div-container'>
                                    {!newFlow ? (
                                      <div className='plan-img'>
                                        <img src={plan.PlanEmailImage} />
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                    <div className={'plan-div-content-big'}>
                                      <div className={'plan-details-other-than-validity'}>
                                        <div className={'mediumFontStyle fontLarge mb5'} id={'plan-' + index}>
                                          {plan.AlternatePlanDisplayName && plan.AlternatePlanDisplayName.length > 0 ? (
                                            <span dangerouslySetInnerHTML={{ __html: plan.AlternatePlanDisplayName }} />
                                          ) : (
                                            plan.PlanName
                                          )}
                                        </div>
                                        {plan.PlanDescription && newFlow ? (
                                          <div className={`${newFlow ? 'gray-text' : ''} bookFontStyle  fontSmall overrideCasing mt4 mb20`}>
                                            {plan.PlanDescription}
                                          </div>
                                        ) : (
                                          ''
                                        )}
                                        {plan.PlanPrice || plan.priceRange ? (
                                          <div className={'plan-price mediumFontStyle'}>
                                            <span className={'bookFontStyle gray-text'}>
                                              {plansForImei?.data?.length > 0
                                                ? getTranslationText('common', 'priceLabel')
                                                : getTranslationText('store', 'from')}
                                              :{' '}
                                            </span>
                                            {showDiscount && hasDiscountToShow(discountOnFullPayment) ? (
                                              <span>
                                                {getLocaleCurrency(
                                                  locale,
                                                  currencyCode,
                                                  plan.TotalPrice > 0
                                                    ? discountOnFullPayment[0].DiscountedTotalPlanPrice
                                                    : discountOnFullPayment[0].DiscountedBasePlanPrice
                                                )}
                                                &nbsp;
                                                {plan.ActualPrice > 0 && (
                                                  <span className='lightFontStyle'>
                                                    <del>{getLocaleCurrency(locale, currencyCode, plan.ActualPrice)} </del>
                                                    &nbsp;
                                                    <span
                                                      className='boldFontStyle fontMedium off-price'
                                                      dangerouslySetInnerHTML={{
                                                        __html: discountOnFullPayment[0].OfferDisplayText,
                                                      }}
                                                    />
                                                  </span>
                                                )}
                                                {plan.PlanPriceArray?.length > 1 ? (
                                                  (() => {
                                                    priceArr = plan.PlanPriceArray.map((price) => {
                                                      return price.PlanPrice
                                                    })
                                                    return (
                                                      <span className='lightFontStyle'>
                                                        <del>{getLocaleCurrency(locale, currencyCode, Math.min(...priceArr))} </del>
                                                        &nbsp;
                                                        <span
                                                          className='boldFontStyle fontMedium off-price'
                                                          dangerouslySetInnerHTML={{
                                                            __html: discountOnFullPayment[0].OfferDisplayText,
                                                          }}
                                                        />
                                                      </span>
                                                    )
                                                  })()
                                                ) : (
                                                  <span className='lightFontStyle'>
                                                    <del>{getLocaleCurrency(locale, currencyCode, plan.PlanPrice)} </del>
                                                    &nbsp;
                                                    <span
                                                      className='boldFontStyle fontMedium off-price'
                                                      dangerouslySetInnerHTML={{
                                                        __html: discountOnFullPayment[0].OfferDisplayText,
                                                      }}
                                                    />
                                                  </span>
                                                )}
                                              </span>
                                            ) : (
                                              <span>
                                                {plan.ActualPrice > 0 && (
                                                  <span>
                                                    <del>{getLocaleCurrency(locale, currencyCode, plan.ActualPrice)} </del>&nbsp;
                                                  </span>
                                                )}
                                                {plan.PlanPriceArray?.length > 1
                                                  ? (() => {
                                                      priceArr = plan.PlanPriceArray.map((price) => {
                                                        return price.PlanPrice
                                                      })
                                                      return getLocaleCurrency(locale, currencyCode, Math.min(...priceArr))
                                                    })()
                                                  : getLocaleCurrency(locale, currencyCode, plan.PlanPrice)}
                                              </span>
                                            )}
                                            {showDiscount &&
                                            (hasDiscountToShow(discountOnMonthly) ||
                                              (discountOnMonthly.length === 1 && discountOnMonthly[0].hasConcession)) ? (
                                              <span>
                                                &nbsp;|&nbsp;
                                                {!(discountOnMonthly.length > 0 && discountOnMonthly[0].hasConcession)
                                                  ? getLocaleCurrency(
                                                      locale,
                                                      currencyCode,
                                                      plan.TotalPrice > 0
                                                        ? discountOnMonthly[0].DiscountedTotalPlanPrice
                                                        : discountOnMonthly[0].DiscountedBasePlanPrice
                                                    )
                                                  : ''}
                                                &nbsp;
                                                <span className='lightFontStyle'>
                                                  <del>{getLocaleCurrency(locale, currencyCode, emiPrice)}</del>
                                                  {discountOnMonthly.length === 1 && discountOnMonthly[0].hasConcession ? (
                                                    <del>/{getTranslationText('common', 'month')}</del>
                                                  ) : (
                                                    ''
                                                  )}
                                                </span>
                                                {!(discountOnMonthly.length === 1 && discountOnMonthly[0].hasConcession) ? (
                                                  <span>/{getTranslationText('common', 'month')}</span>
                                                ) : (
                                                  ''
                                                )}
                                                <span
                                                  className='boldFontStyle fontMedium off-price'
                                                  dangerouslySetInnerHTML={{
                                                    __html: ' ' + discountOnMonthly[0].OfferDisplayText,
                                                  }}
                                                />
                                              </span>
                                            ) : (
                                              emiPrice > 0 && (
                                                <span>
                                                  {' | ' +
                                                    getLocaleCurrency(locale, currencyCode, emiPrice) +
                                                    '/' +
                                                    getTranslationText('common', 'month')}
                                                </span>
                                              )
                                            )}
                                          </div>
                                        ) : (
                                          ''
                                        )}
                                        {showDiscount && discountOffersToShow.length > 0 ? (
                                          <div className='discount-text-parent'>
                                            <div className='discount-text-continer mt4'>
                                              <img className='ml8' src={discountImg} />
                                              <div className='ml4 mr8'>
                                                <span
                                                  className='discount-text mediumFontStyle fontMedium overrideCasing'
                                                  dangerouslySetInnerHTML={{
                                                    __html: discountOffersToShow[0].OfferDisplayText,
                                                  }}
                                                />
                                                <span
                                                  className='bookFontStyle fontSmall overrideCasing ml4'
                                                  dangerouslySetInnerHTML={{
                                                    __html: discountOffersToShow[0].CheckOutPageDisplayText,
                                                  }}
                                                />
                                              </div>
                                            </div>
                                            {discountOffersToShow.length > 1 ? (
                                              <div>
                                                <Tooltip
                                                  className={{
                                                    tooltipText: 'discount-text-tooltip bookFontStyle fontSmall',
                                                  }}
                                                  content={discountOffersToShow.map((item) => {
                                                    return (
                                                      <div key={item.PlanDiscountConfigurationID}>
                                                        <div className='discount-text-continer mb4'>
                                                          <div>
                                                            <img className='mr4' src={discountWhiteImg} />
                                                          </div>
                                                          <span
                                                            dangerouslySetInnerHTML={{
                                                              __html: item.OfferDisplayText + ' ' + item.CheckOutPageDisplayText,
                                                            }}
                                                          />
                                                        </div>
                                                      </div>
                                                    )
                                                  })}
                                                  position='right'
                                                  width='300px'
                                                >
                                                  <div className='discount-show-more bookFontStyle fontSmall ml32 mt4 mb4'>
                                                    +{discountOffersToShow.length - 1 + ' ' + getTranslationText('store', 'moreOffers')}
                                                  </div>
                                                </Tooltip>
                                                <div className='more-offers-note lightFontStyle fontMicro overrideCasing ml8'>
                                                  {getTranslationText('store', 'discountNote')}
                                                </div>
                                              </div>
                                            ) : (
                                              ''
                                            )}
                                          </div>
                                        ) : (
                                          ''
                                        )}
                                      </div>
                                      {plan.PlanValidity ? (
                                        <div className={`${newFlow ? 'boldFontStyle mt20' : ''} fontSmall validity-text overrideCasing`}>
                                          {getTranslationText('store', 'validity')}: {plan.PlanValidity}
                                        </div>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                    {/* <div className='plan-nav-icon-container'>
                                      <RightArrow />
                                    </div> */}
                                  </div>
                                </div>
                              </div>
                            )
                          }
                        })}
                      </div>
                    </div>
                  )
                }
              })
            )}
          </div>
        )}
      </BoxHolder>
    </div>
  ) : null
}
ListofPlans.propTypes = {
  user: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  selectedPlanDetails: PropTypes.func,
  planViewSelectedTab: PropTypes.any,
}

export class BuyStoreMainComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      buytermsConditionState: false,
      showMoreOffers: false,
      moreOffersText: getTranslationText('store', 'viewMoreOffers'),
      showReadMore: false,
      readMoreText: getTranslationText('store', 'readMore'),
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    window.rzp1 && window.rzp1.close()
    window.rzp1 = ''
    const brandConfig = _.get(this, 'props.user.appConfigDetails.app', '')
    this.setState({
      planTAndCLink: this.handleRedirection('tnc', '/terms-conditions'),
      privacyLink: this.handleRedirection('privacy', '/privacy-policy'),
      dataLink: this.handleRedirection('data', '/online-consent'),
      showReadMore: !!['Samsung-Web', 'SamsungEU-Web'].includes(brandConfig),
      readMoreText: ['Samsung-Web', 'SamsungEU-Web'].includes(brandConfig)
        ? getTranslationText('store', 'readLess')
        : getTranslationText('store', 'readMore'),
    })
    if (
      _.get(this, 'props.store.imeiForFilter', false) &&
      _.get(this, 'props.user.userDetails.consumerID', false) &&
      this.props.isAddressRequiredForPlanPurchase()
    ) {
      this.props.setglobalLoaderStatus(true)
    }
  }

  onClickReadMore = () => {
    this.setState({
      showReadMore: !this.state.showReadMore,
      readMoreText: getTranslationText('store', !this.state.showReadMore ? 'readLess' : 'readMore'),
    })
  }

  onClickShowMoreOffers = () => {
    this.setState({
      showMoreOffers: !this.state.showMoreOffers,
      moreOffersText: getTranslationText('store', !this.state.showMoreOffers ? 'viewLessOffers' : 'viewMoreOffers'),
    })
  }

  handleRedirection = (type, customLink) => {
    let skipRedirection = _.get(this, 'props.user.appConfigDetails.SkipRedirection', false)
    let link = customLink
    if (type === 'tnc') {
      link = _.get(this, 'props.store.selectedPlanDetails.planMeta.TermsAndConditionsLink') || this.props.user.appConfigDetails.Terms
      skipRedirection && this.props.setTncLink(link)
    } else if (type === 'privacy') {
      link = this.props.user.appConfigDetails.Privacy
      skipRedirection && this.props.setPrivacyLink(link)
    } else if (type === 'data') {
      link = this.props.user.appConfigDetails.ConsentURL
      skipRedirection && this.props.setDataConsentLink(link)
    }
    if (skipRedirection) {
      link = customLink
    }
    return link
  }

  handleSidebarIpSelection = () => {
    this.setState({
      buytermsConditionState: !this.state.buytermsConditionState,
    })
  }

  handleLegalConsent1Selection = () => {
    this.setState({
      legalConsent1State: !this.state.legalConsent1State,
    })
  }

  handleLegalConsent2Selection = () => {
    this.setState({
      legalConsent2State: !this.state.legalConsent2State,
    })
  }
  render() {
    return Object.keys(_.get(this, 'props.selectedItems', {})).length &&
      Object.keys(_.get(this, 'props.store.selectedBrand', {})).length ? (
      // hardcoded for apple plans
      <div className='row mb20 mt20 buy-store-components'>
        <div className='col-sm-12 col-lg-9 mb8'>
          <StoreDeviceView
            {...this.props}
            pickPlanPrice={this.props.pickPlanPrice}
            pickEMIPlanPrice={this.props.pickEMIPlanPrice}
            getDiscountsByType={this.props.getDiscountsByType}
            showMoreOffers={this.state.showMoreOffers}
            moreOffersText={this.state.moreOffersText}
            onClickShowMoreOffers={this.onClickShowMoreOffers}
            showReadMore={this.state.showReadMore}
            readMoreText={this.state.readMoreText}
            onClickReadMore={this.onClickReadMore}
          />
        </div>
        <div className='col-sm-12 col-lg-3 mb8 buy-store-sidebar'>
          <div className='row fullHeight'>
            <div className='col-sm-6 col-lg-12 mb10'>
              {this.props.isNewPlanSalesFlow ||
              (this.props.initialLoggedInState && _.get(this, 'props.store.selectedBrand.BrandName', '').toUpperCase() === 'APPLE') ? (
                <NewBuyStoreSideBar {...this.props} handleRedirection={this.handleRedirection} />
              ) : (
                <BuyStoreSideBar
                  {...this.props}
                  planTAndCLink={this.state.planTAndCLink}
                  privacyLink={this.state.privacyLink}
                  dataLink={this.state.dataLink}
                  buytermsConditionState={this.state.buytermsConditionState}
                  handleSidebarIpSelection={this.handleSidebarIpSelection}
                  legalConsent1State={this.state.legalConsent1State}
                  legalConsent2State={this.state.legalConsent2State}
                  handleLegal1ConsentSelection={this.state.handleLegalConsent1Selection}
                  handleLegalConsent2Selection={this.state.handleLegalConsent2Selection}
                  handleRedirection={this.handleRedirection}
                />
              )}
            </div>
            <StoreSidebarOfferView {...this.props} />
          </div>
        </div>
        <div className='col-sm-12 col-lg-12 product-specific-container mb8'>
          <CommonProductContainer planInfo={this.props.selectedItems} user={this.props.user} />
        </div>
        <Modal className='sfy-modal' showModal={this.props.showDisclaimerModal}>
          <div className='row' />
          <img className='image-center mb8' src={informationImg} />
          <div className='custom-modal-content align-center'>
            <div className='docs-list-container'>{_.get(this, 'props.store.purchaseEligibilityInfo.Disclaimer.description', '')}</div>
          </div>
          <span className='align-center'>
            <MediumButton
              onClick={this.props.closeDisclaimerModal}
              className='mt20'
              buttonText={getTranslationText('common', 'understood')}
            />
          </span>
        </Modal>
        <Modal className='sfy-modal' showModal={this.props.showInvalidDOBModal}>
          <CustomIconComponent
            className={'close-icon ' + (window.isDirectionRTL ? 'appear-left text-left ' : 'appear-right text-right')}
            onClick={() => {
              this.props.setDOBInvalidModal(false, '')
            }}
          />
          <div className='row' />
          <img className='eligibility-error-image image-center mb8' src={errorImg} />
          <h3 className='text-center mt8'>{getTranslationText('error', 'failure')}</h3>
          <div className='custom-modal-content align-center mt4 mb4'>
            <div className='docs-list-container'>{this.props.dobInvalidMessage}</div>
          </div>
          <span className='align-center'>
            <MediumButton
              onClick={() => {
                this.props.setDOBInvalidModal(false, '')
              }}
              className='mt4'
              buttonText={getTranslationText('common', 'okay')}
            />
          </span>
        </Modal>
      </div>
    ) : null
  }
}
BuyStoreMainComponent.propTypes = {
  user: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
  viewSelectedPlan: PropTypes.func,
  location: PropTypes.object,
  selectedItems: PropTypes.object.isRequired,
  eligibilityLoader: PropTypes.bool,
  setTncLink: PropTypes.func.isRequired,
  setPrivacyLink: PropTypes.func.isRequired,
  showDisclaimerModal: PropTypes.bool,
  closeDisclaimerModal: PropTypes.func,
  checkIfPaymentRequiredHandling: PropTypes.func,
  // handlePlanOptionsSelection: PropTypes.func.isRequired
}

export const StoreSidebarOfferView = (props) => {
  let offerDetails = {
    header: 'OFFER',
    text: 'Exclusive 5% off (already applied on MRP) for Airtel Customers',
  }
  const brandConfig = _.get(props, 'user.appConfigDetails.app', '')
  let isDisplayOffer =
    ['Poshvine-Web', 'SamsungEU-Web'].includes(brandConfig) &&
    !_.isEmpty(_.get(props, 'store.selectedPlanDetails.PlanConfig.benefits[0]', {}))
  let benefitsDetails = _.get(props, 'store.selectedPlanDetails.PlanConfig.benefits[0]', offerDetails)

  let isAirtelUser = ['Airtel-Web'].includes(brandConfig) && _.get(props, 'store.selectedPlanDetails.PlanConfig.benefits', false)
  return (
    <React.Fragment>
      {isDisplayOffer ? (
        <div className='col-sm-6 col-lg-12 mb8'>
          <BoxHolder className='proceed-to-purchase-sidebar'>
            <div className='bookFontStyle fontMedium'> {benefitsDetails.header} </div>
            <div className='fontSmall'>{benefitsDetails.text}</div>
          </BoxHolder>
        </div>
      ) : (
        isAirtelUser && (
          <div className='col-sm-6 col-lg-12 mb8'>
            <BoxHolder className='proceed-to-purchase-sidebar'>
              <div className='bookFontStyle fontMedium'> {offerDetails.header} </div>
              <div className='fontSmall'>{offerDetails.text}</div>
            </BoxHolder>
          </div>
        )
      )}
    </React.Fragment>
  )
}
StoreSidebarOfferView.propTypes = {}

export const StoreDeviceView = (props) => {
  const [mobileView, setMoilbleView] = useState(window.innerWidth < 600)
  let planPriceInfo = props.selectedItems.planPrice.find((data) => data.isSelected) || {}
  let planConfig = _.get(props, 'selectedItems.PlanConfig', {})
  const locale = _.get(props, 'user.selectedCountryData.countryConfig.Locale.DEFAULT', 'en')
  const currencyCode = _.get(props, 'user.selectedCountryData.CurrencyCode')
  const orignalPrice = _.get(props, 'store.planPriceOnProductBasis.OriginalPlanPrice', '')
  const discountPrice = props.pickPlanPrice(_.get(props, 'store.planPriceOnProductBasis', {}))
  const orignalEMIPrice = Number(_.get(props, 'store.planPriceOnProductBasis.planPriceBillingConfig[0].OriginalEMIAmount', 0))
  let discountEMIPrice = Number(props.pickEMIPlanPrice(_.get(props, 'store.planPriceOnProductBasis.planPriceBillingConfig[0]', {})))
  if (isNaN(discountEMIPrice)) discountEMIPrice = 0
  /* Show discounts in plan details page
  get discounts for both upfront and monthly types to show discounted prices individually
  get all discounts also to show aviilable offers
  */
  // const discounts = _.get(props, 'store.selectedPlanDetails.PlanDiscounts.PlanDiscountOffers', [])
  let dicsountsAll = _.get(props, 'store.selectedPlanDetails.PlanDiscounts', [])
  let discountsAll_ = _.get(props, 'store.selectedPlanDetails.PlanDiscounts.PlanDiscountOffers', [])
  let priceFromPlanPriceOnProductBasis = 0
  let emiPriceFromPlanPriceOnProductBasis = 0
  if (_.get(props, 'store.purchaseEligibilityInfo.IsPurchasedAllowed', false)) {
    dicsountsAll = _.get(props, 'store.purchaseEligibilityInfo.PlanDiscounts', [])
    discountsAll_ = _.get(props, 'store.purchaseEligibilityInfo.PlanDiscounts.PlanDiscountOffers', [])
    priceFromPlanPriceOnProductBasis = props.pickPlanPrice(_.get(props, 'store.planPriceOnProductBasis', {}))
    emiPriceFromPlanPriceOnProductBasis = props.pickEMIPlanPrice(
      _.get(props, 'store.planPriceOnProductBasis.planPriceBillingConfig[0]', {})
    )
    if (priceFromPlanPriceOnProductBasis)
      priceFromPlanPriceOnProductBasis = getLocaleCurrency(locale, currencyCode, priceFromPlanPriceOnProductBasis)
    if (emiPriceFromPlanPriceOnProductBasis)
      emiPriceFromPlanPriceOnProductBasis = getLocaleCurrency(locale, currencyCode, emiPriceFromPlanPriceOnProductBasis)
  }
  const discountOnFullPayment = props.getDiscountsByType(dicsountsAll, 'UPFRONT')
  const discountOnMonthly = props.getDiscountsByType(dicsountsAll, 'MONTHLY')
  const hasDiscountToShow = (offer) => {
    return offer.length === 1 && offer[0].requiredFieldsPresent && offer[0].DiscountCategory !== 'PromoCode'
  }
  const discountOffersToShow = discountsAll_.filter((offer) => {
    if (
      discountOnFullPayment.length === 1 &&
      discountOnFullPayment[0].PlanDiscountConfigurationID === offer.PlanDiscountConfigurationID &&
      hasDiscountToShow(discountOnFullPayment)
    )
      return false
    if (
      discountOnMonthly.length === 1 &&
      discountOnMonthly[0].PlanDiscountConfigurationID === offer.PlanDiscountConfigurationID &&
      hasDiscountToShow(discountOnMonthly)
    )
      return false
    return true
  })
  // discounts
  const showDiscountedPrice =
    discountOnFullPayment.length <= 0 &&
    discountOnMonthly.length <= 0 &&
    orignalPrice &&
    discountPrice &&
    _.get(props, 'store.purchaseEligibilityInfo.IsPurchasedAllowed', false)

  window.addEventListener('resize', () => {
    setMoilbleView(!!(window.innerWidth < 600))
  })

  return (
    <BoxHolder className='buy-store-widget'>
      <div className='row store-device-view'>
        <div
          className='col-12 bck-btn mb20 special-link-txt'
          id={props.store.selectedBrand.BrandName}
          onClick={(e) => {
            if (!_.get(props, 'user.userDetails.birthDate', false)) props.handleDOBOnChange('')
            props.handleStoreItemSelection(e)
          }}
        >
          <div className='bck-img-container'>
            <DownArrow width='12' height='12' />
          </div>
          <span className='overrideCasing mediumFontStyle fontMedium'>{getTranslationText('common', 'backLabel')}</span>
        </div>
        <div className='col-sm-12 col-md-5 device-view-img'>
          <div className='selected-slider-img mb8'>
            <img src={_.get(props, 'store.selectedPlanDetails.planMeta.PlanEmailImage')} />
          </div>
        </div>
        <div className='col-sm-12 col-md-7'>
          <div>
            {planConfig.MainDescriptionHeader && (
              <div className='mediumFontStyle device-heading' dangerouslySetInnerHTML={{ __html: planConfig.MainDescriptionHeader }} />
            )}
          </div>
          {planConfig.MainDescriptionHeader && <hr size='2' className='mt20 mb20' />}
          <div className='bookFontStyle fontLarge'>
            <span
              dangerouslySetInnerHTML={{
                __html: (planConfig.MainDescriptionSubHeader1 || '') + ' ' + (planConfig.MainDescriptionText1 || ''),
              }}
            />
          </div>
          {(props.selectedItems.priceRange || planPriceInfo.PlanPrice) && (
            <div className='mediumFontStyle fontMedium mb8 mt16'>
              {_.get(props, 'store.selectedBrand.BrandName', '').toUpperCase() === 'APPLE' ? (
                <span>
                  <span className='gray-text'>
                    {_.get(props, 'selectedItems.PlanType') === 'Protect'
                      ? `${getTranslationText('store', 'startingPrice')} : `
                      : `${getTranslationText('common', 'priceLabel')} : `}
                  </span>
                  {_.get(props, 'selectedItems.planPrice[0].ActualPrice') > 0 && (
                    <span>
                      <del className={props.isNewPlanSalesFlow ? 'gray-text lightFontStyle fontLarge' : ''}>
                        {getLocaleCurrency(locale, currencyCode, _.get(props, 'selectedItems.planPrice[0].ActualPrice'))}
                      </del>{' '}
                      &nbsp;
                    </span>
                  )}
                  <span className='priceFont'>{getLocaleCurrency(locale, currencyCode, props.selectedItems.planPrice[0].PlanPrice)}</span>
                </span>
              ) : (
                <React.Fragment>
                  <div className='row'>
                    <div className={`${props.isNewPlanSalesFlow ? 'fontLarge' : ''} gray-text col-auto no-right-padding `}>
                      {getTranslationText('common', 'priceLabel')}&nbsp;:&nbsp;
                    </div>
                    <div className={`${props.isNewPlanSalesFlow ? 'priceFont' : ''} col-auto no-left-padding`}>
                      {showDiscountedPrice ? (
                        <span>
                          <span
                            className={`lightFontStyle strikethrough-text mr4 ${props.isNewPlanSalesFlow ? 'gray-text fontLarge' : ''}`}
                          >
                            {getLocaleCurrency(locale, currencyCode, orignalPrice, true)}
                          </span>
                          {getLocaleCurrency(locale, currencyCode, discountPrice)}
                        </span>
                      ) : (props.selectedItems.priceRange || props.selectedItems.basePriceRange) &&
                        discountOnFullPayment.length === 1 &&
                        discountOnFullPayment[0].requiredFieldsPresent &&
                        !(discountOnFullPayment.length === 1 && discountOnFullPayment[0].DiscountCategory === 'PromoCode') ? (
                        !priceFromPlanPriceOnProductBasis &&
                        ((props.selectedItems.totalDiscountedPriceRange && props.selectedItems.totalDiscountedPriceRange.length > 0) ||
                          (props.selectedItems.baseDiscountedPriceRange && props.selectedItems.baseDiscountedPriceRange.length > 0)) ? (
                          <span>
                            {props.selectedItems.totalDiscountedPriceRange || props.selectedItems.baseDiscountedPriceRange}
                            &nbsp;
                            <span className='lightFontStyle'>
                              <del className={props.isNewPlanSalesFlow ? 'gray-text lightFontStyle fontLarge' : ''}>
                                {props.selectedItems.priceRange || props.selectedItems.basePriceRange}
                              </del>
                            </span>
                            {discountOnFullPayment.length === 1 && discountOnFullPayment[0].requiredFieldsPresent && (
                              <span
                                className={`boldFontStyle ${props.isNewPlanSalesFlow ? 'fontLarge' : 'fontMedium'} off-price`}
                                dangerouslySetInnerHTML={{
                                  __html: ' ' + discountOnFullPayment[0].OfferDisplayText,
                                }}
                              />
                            )}
                          </span>
                        ) : discountOnFullPayment.length === 1 &&
                          (discountOnFullPayment[0].DiscountedBasePlanPrice > 0 || discountOnFullPayment[0].DiscountedTotalPlanPrice > 0) &&
                          discountOnFullPayment[0].requiredFieldsPresent &&
                          discountOnFullPayment[0].DiscountCategory !== 'PromoCode' ? (
                          <span className={props.isNewPlanSalesFlow ? 'priceFont mediumFontStyle' : ''}>
                            {getLocaleCurrency(
                              locale,
                              currencyCode,
                              planPriceInfo.TotalPrice > 0
                                ? discountOnFullPayment[0].DiscountedTotalPlanPrice
                                : discountOnFullPayment[0].DiscountedBasePlanPrice
                            )}
                            &nbsp;
                            <span className={props.isNewPlanSalesFlow ? 'mediumFontStyle' : 'lightFontStyle'}>
                              <del className={props.isNewPlanSalesFlow ? 'gray-text lightFontStyle fontLarge' : ''}>
                                {priceFromPlanPriceOnProductBasis || getLocaleCurrency(locale, currencyCode, planPriceInfo.PlanPrice)}{' '}
                              </del>
                            </span>
                            &nbsp;
                            <span
                              className={`boldFontStyle ${props.isNewPlanSalesFlow ? 'fontLarge' : 'fontMedium'} off-price`}
                              dangerouslySetInnerHTML={{
                                __html: discountOnFullPayment[0].OfferDisplayText,
                              }}
                            />
                          </span>
                        ) : (
                          <span className={props.isNewPlanSalesFlow ? 'priceFont mediumFontStyle' : ''}>
                            {_.get(props, 'selectedItems.ActualpriceRange', false) && (
                              <span>
                                <del className={props.isNewPlanSalesFlow ? 'gray-text lightFontStyle fontLarge' : ''}>
                                  {_.get(props, 'selectedItems.ActualpriceRange')}
                                </del>
                                &nbsp;
                              </span>
                            )}
                            {priceFromPlanPriceOnProductBasis || props.selectedItems.priceRange}
                          </span>
                        )
                      ) : props.selectedItems.priceRange || priceFromPlanPriceOnProductBasis ? (
                        <span className={props.isNewPlanSalesFlow ? 'priceFont mediumFontStyle' : ''}>
                          {_.get(props, 'selectedItems.ActualpriceRange', false) && (
                            <span>
                              <del className={props.isNewPlanSalesFlow ? 'gray-text lightFontStyle fontLarge' : ''}>
                                {_.get(props, 'selectedItems.ActualpriceRange')}
                              </del>
                              &nbsp;
                            </span>
                          )}
                          {priceFromPlanPriceOnProductBasis || props.selectedItems.priceRange}
                        </span>
                      ) : (
                        planPriceInfo ||
                        (priceFromPlanPriceOnProductBasis && (
                          <span className={props.isNewPlanSalesFlow ? 'fontLarge mediumFontStyle' : ''}>
                            {planPriceInfo.ActualPrice > 0 && (
                              <span className={props.isNewPlanSalesFlow ? 'priceFont' : ''}>
                                <del className={props.isNewPlanSalesFlow ? 'gray-text lightFontStyle fontLarge' : ''}>
                                  {getLocaleCurrency(locale, currencyCode, planPriceInfo.ActualPrice)}
                                </del>
                                &nbsp;
                              </span>
                            )}
                            {priceFromPlanPriceOnProductBasis || getLocaleCurrency(locale, currencyCode, planPriceInfo.PlanPrice)}
                          </span>
                        ))
                      )}
                      {!showDiscountedPrice &&
                      !discountOnMonthly.length &&
                      !_.get(props, 'store.purchaseEligibilityInfo.IsPurchasedAllowed', false) &&
                      (props.selectedItems.monthlyPriceRange || props.selectedItems.monthlyBasePriceRange) ? (
                        <span className={props.isNewPlanSalesFlow ? 'fontLarge mediumFontStyle' : ''}>
                          <br />
                          <span className={props.isNewPlanSalesFlow ? 'priceFont' : ''}>
                            {emiPriceFromPlanPriceOnProductBasis ||
                              props.selectedItems.monthlyPriceRange ||
                              props.selectedItems.monthlyBasePriceRange}
                          </span>
                          /{getTranslationText('common', 'month')}
                        </span>
                      ) : (
                        ''
                      )}
                      {!showDiscountedPrice ? (
                        (emiPriceFromPlanPriceOnProductBasis !== 0 ||
                          props.selectedItems.monthlyPriceRange ||
                          props.selectedItems.monthlyBasePriceRange) &&
                        discountOnMonthly.length === 1 &&
                        discountOnMonthly[0].requiredFieldsPresent &&
                        !(discountOnMonthly.length === 1 && discountOnMonthly[0].DiscountCategory === 'PromoCode') ? (
                          (props.selectedItems.totalMonthlyDiscountedPriceRange &&
                            props.selectedItems.totalMonthlyDiscountedPriceRange.length > 0) ||
                          (props.selectedItems.baseMonthlyDiscountedPriceRange &&
                            props.selectedItems.baseMonthlyDiscountedPriceRange.length > 0) ||
                          emiPriceFromPlanPriceOnProductBasis !== 0 ? (
                            <span className={props.isNewPlanSalesFlow ? 'fontLarge mediumFontStyle' : ''}>
                              <br />
                              {discountOnMonthly.length === 1 && !discountOnMonthly[0].hasConcession ? (
                                <span className={props.isNewPlanSalesFlow ? 'priceFont' : ''}>
                                  {emiPriceFromPlanPriceOnProductBasis !== 0
                                    ? getLocaleCurrency(
                                        locale,
                                        currencyCode,
                                        planPriceInfo.TotalPrice > 0
                                          ? discountOnMonthly[0].DiscountedTotalPlanPrice
                                          : discountOnMonthly[0].DiscountedBasePlanPrice
                                      )
                                    : props.selectedItems.totalMonthlyDiscountedPriceRange ||
                                      props.selectedItems.baseMonthlyDiscountedPriceRange}
                                  &nbsp;
                                </span>
                              ) : (
                                ''
                              )}
                              <span className={props.isNewPlanSalesFlow ? 'priceFont mediumFontStyle' : 'lightFontStyle'}>
                                <del className={props.isNewPlanSalesFlow ? 'gray-text lightFontStyle fontLarge' : ''}>
                                  {emiPriceFromPlanPriceOnProductBasis ||
                                    props.selectedItems.monthlyPriceRange ||
                                    props.selectedItems.monthlyBasePriceRange}{' '}
                                </del>
                                {discountOnMonthly.length === 1 && discountOnMonthly[0].hasConcession ? (
                                  <del className={props.isNewPlanSalesFlow ? 'gray-text lightFontStyle fontLarge' : ''}>
                                    /{getTranslationText('common', 'month')}
                                  </del>
                                ) : (
                                  ''
                                )}
                              </span>
                              {discountOnMonthly.length === 1 && !discountOnMonthly[0].hasConcession ? (
                                <span className={`${props.isNewPlanSalesFlow ? 'fontLarge' : ''}`}>
                                  /{getTranslationText('common', 'month')}
                                </span>
                              ) : (
                                ''
                              )}
                              {discountOnMonthly.length === 1 && discountOnMonthly[0].requiredFieldsPresent && (
                                <span
                                  className={`boldFontStyle ${props.isNewPlanSalesFlow ? 'fontLarge' : 'fontMedium'} off-price`}
                                  dangerouslySetInnerHTML={{
                                    __html: ' ' + discountOnMonthly[0].OfferDisplayText,
                                  }}
                                />
                              )}
                              {discountOnMonthly.length === 1 &&
                              discountOnMonthly[0].hasConcession &&
                              emiPriceFromPlanPriceOnProductBasis !== 0 ? (
                                <div className={` ${props.isNewPlanSalesFlow ? 'priceFont mediumFontStyle' : 'fontMedium bookFontStyle'}`}>
                                  {'(' + emiPriceFromPlanPriceOnProductBasis}
                                  <span className={props.isNewPlanSalesFlow ? 'fontLarge' : ''}>
                                    {'/' + getTranslationText('store', 'afterOfferPeriodLabel') + ')'}
                                  </span>
                                </div>
                              ) : (
                                ''
                              )}
                            </span>
                          ) : (
                            <span className={props.isNewPlanSalesFlow ? 'fontLarge mediumFontStyle' : ''}>
                              <br />
                              <span className={props.isNewPlanSalesFlow ? 'priceFont' : ''}>
                                {emiPriceFromPlanPriceOnProductBasis ||
                                  props.selectedItems.monthlyPriceRange ||
                                  props.selectedItems.monthlyBasePriceRange}
                              </span>
                              /{getTranslationText('common', 'month')}
                            </span>
                          )
                        ) : discountOnMonthly.length === 1 &&
                          (discountOnMonthly[0].DiscountedBasePlanPrice > 0 || discountOnMonthly[0].DiscountedTotalPlanPrice > 0) &&
                          discountOnMonthly[0].requiredFieldsPresent &&
                          (discountOnMonthly[0].DiscountCategory !== 'PromoCode' || discountOnMonthly[0].hasConcession) ? (
                          <span className={props.isNewPlanSalesFlow ? 'priceFont mediumFontStyle' : ''}>
                            <br />
                            {!discountOnMonthly[0].hasConcession
                              ? getLocaleCurrency(
                                  locale,
                                  currencyCode,
                                  planPriceInfo.TotalPrice > 0
                                    ? discountOnMonthly[0].DiscountedTotalPlanPrice
                                    : discountOnMonthly[0].DiscountedBasePlanPrice
                                )
                              : ''}
                            &nbsp;
                            <span className='lightFontStyle fontSmall'>
                              <del className={props.isNewPlanSalesFlow ? 'priceFont lightFontStyle mediumFontStyle' : ''}>
                                {getLocaleCurrency(
                                  locale,
                                  currencyCode,
                                  props.selectedItems.TotalEMIAmount ||
                                    props.selectedItems.EMIInsuranceBasePrice + props.selectedItems.EMIServiceBasePrice
                                )}
                              </del>
                              {discountOnMonthly[0].hasConcession ? (
                                <del className={props.isNewPlanSalesFlow ? 'fontLarge lightFontStyle mediumFontStyle' : ''}>
                                  /{getTranslationText('common', 'month')}
                                </del>
                              ) : (
                                ''
                              )}
                            </span>
                            {!discountOnMonthly[0].hasConcession ? (
                              <span className={props.isNewPlanSalesFlow ? 'fontLarge mediumFontStyle' : ''}>
                                /{getTranslationText('common', 'month')}
                              </span>
                            ) : (
                              ''
                            )}
                            &nbsp;
                            <span
                              className='boldFontStyle fontMedium off-price'
                              dangerouslySetInnerHTML={{
                                __html: discountOnMonthly[0].OfferDisplayText,
                              }}
                            />
                            {discountOnMonthly.length === 1 &&
                            discountOnMonthly[0].hasConcession &&
                            emiPriceFromPlanPriceOnProductBasis !== 0 ? (
                              <div className={` ${props.isNewPlanSalesFlow ? 'priceFont mediumFontStyle' : 'fontMedium bookFontStyle'}`}>
                                {'(' + emiPriceFromPlanPriceOnProductBasis}
                                <span className={props.isNewPlanSalesFlow ? 'fontLarge' : ''}>
                                  {'/' + getTranslationText('store', 'afterOfferPeriodLabel') + ')'}
                                </span>
                              </div>
                            ) : (
                              ''
                            )}
                          </span>
                        ) : props.selectedItems.TotalEMIAmount ||
                          props.selectedItems.EMIAmount ||
                          (emiPriceFromPlanPriceOnProductBasis && emiPriceFromPlanPriceOnProductBasis !== 0) ? (
                          <span className={props.isNewPlanSalesFlow ? 'mediumFontStyle' : ''}>
                            <br />
                            <span className={props.isNewPlanSalesFlow ? 'PriceFont' : ''}>
                              {emiPriceFromPlanPriceOnProductBasis ||
                                getLocaleCurrency(
                                  locale,
                                  currencyCode,
                                  props.selectedItems.TotalEMIAmount || props.selectedItems.EMIAmount
                                )}
                              <span className={props.isNewPlanSalesFlow ? 'fontLarge' : ''}>
                                {'/' + getTranslationText('common', 'month') + ' '}
                              </span>
                            </span>
                          </span>
                        ) : (
                          ''
                        )
                      ) : (
                        discountEMIPrice > 0 && (
                          <span className={props.isNewPlanSalesFlow ? 'mediumFontStyle' : ''}>
                            <br />
                            <span className={props.isNewPlanSalesFlow ? 'priceFont' : ''}>
                              {orignalEMIPrice && (
                                <span
                                  className={`lightFontStyle strikethrough-text mr4 ${
                                    props.isNewPlanSalesFlow ? 'gray-text fontLarge' : ''
                                  }`}
                                >
                                  {getLocaleCurrency(locale, currencyCode, orignalEMIPrice, true)}
                                </span>
                              )}
                              {getLocaleCurrency(locale, currencyCode, discountEMIPrice)}
                            </span>
                            <span className={props.isNewPlanSalesFlow ? 'fontLarge' : ''}>
                              {'/' + getTranslationText('common', 'month')}
                            </span>
                          </span>
                        )
                      )}
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
          )}
          {discountOffersToShow.length > 0 && !props.showMoreOffers ? (
            <div className='discount-offers-text-continer'>
              <div className='discount-offers-title'>{getTranslationText('store', 'availableOffers')}</div>
              <div className='discount-offers-text mt8'>
                <img className='ml8' src={discountImg} />
                <div className='bookFontStyle fontMedium overrideCasing ml4'>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: discountOffersToShow[0].OfferDisplayText + ' ' + discountOffersToShow[0].CheckOutPageDisplayText,
                    }}
                  />
                </div>
              </div>
              {discountOffersToShow.length > 1 ? (
                <div>
                  <div className='view-more-less-container mt4' onClick={props.onClickShowMoreOffers}>
                    <div className='view-more-less bookFontStyle fontSmall overrideCasing overrideCasing ml8'>{props.moreOffersText}</div>
                    <img className='view-more-less-img ml3' src={props.showMoreOffers ? up : down} />
                  </div>
                  <div className='more-offers-note lightFontStyle fontSmall overrideCasing mt4 mb4 ml8'>
                    {getTranslationText('store', 'discountNote')}
                  </div>
                </div>
              ) : (
                <div className='mt8'></div>
              )}
            </div>
          ) : (
            ''
          )}
          {/* discounts - show available offers */}
          {discountOffersToShow.length > 0 && props.showMoreOffers ? (
            <div className='discount-offers-text-continer'>
              <div className='discount-offers-title mb8'>{getTranslationText('store', 'availableOffers')}</div>
              {discountsAll_.map((offer) => {
                return (
                  <div key={offer.PlanDiscountConfigurationID} className='discount-offers-text mb8'>
                    <img className='ml8' src={discountImg} />
                    <div className='bookFontStyle fontMedium overrideCasing ml4'>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: offer.OfferDisplayText + ' ' + offer.CheckOutPageDisplayText,
                        }}
                      />
                    </div>
                  </div>
                )
              })}
              <div className='view-more-less-container mt4' onClick={props.onClickShowMoreOffers}>
                <div className='view-more-less bookFontStyle fontSmall overrideCasing ml8'>{props.moreOffersText}</div>
                <img className='view-more-less-img ml3' src={props.showMoreOffers ? up : down} />
              </div>
              <div className='more-offers-note lightFontStyle fontSmall overrideCasing mt4 mb4 ml8'>
                {getTranslationText('store', 'discountNote')}
              </div>
            </div>
          ) : (
            ''
          )}
          {/* discounts - show available offers ends */}
          <div className='plan-details-read-more mt16 mb16' onClick={props.onClickReadMore}>
            <div className='read-more-less bookFontStyle fontSmall overrideCasing'>{props.readMoreText}</div>
            {props.showReadMore ? <UpIcon className='ml3' width='16' height='16' /> : <DownIcon className='ml3' width='16' height='16' />}
          </div>
          {(props.showReadMore || !mobileView) && (
            <div>
              <div className={`${props.isNewPlanSalesFlow ? 'fontLarge' : 'fontMedium'} bookFontStyle fontMedium mt20 mb20`}>
                <div className='mb4' dangerouslySetInnerHTML={{ __html: planConfig.MainDescriptionSubHeader2 }} />
                <ul className='faq-list-items'>
                  {_.get(props, 'selectedItems.PlanConfig.MainDescriptionList', []).length > 0 &&
                    planConfig.MainDescriptionList.map((data) => {
                      return <li className='mb4' key={data} dangerouslySetInnerHTML={{ __html: data }} />
                    })}
                </ul>
              </div>
              {props.selectedItems.planPrice && props.selectedItems.planPrice.length > 1 && !props.selectedItems.priceRange && (
                <div className={'plan-storage-selection' + (props.eligibilityLoader ? ' disabledDiv' : '')}>
                  <div className='bookFontStyle fontMedium mb4 '>{getTranslationText('common', 'selectPlan')}:</div>
                  <RadioBtnPills
                    prefix='sub-plans'
                    noSlots
                    optionList={props.selectedItems.planPrice}
                    onClick={props.handlePlanOptionsSelection}
                  />
                </div>
              )}
              {planConfig.PlanLegalTxt && <div className='bookFontStyle fontMedium mt8'>{planConfig.PlanLegalTxt}</div>}
            </div>
          )}
        </div>
      </div>
    </BoxHolder>
  )
}
StoreDeviceView.propTypes = {
  store: PropTypes.object.isRequired,
  selectedItems: PropTypes.object.isRequired,
  handlePlanOptionsSelection: PropTypes.func.isRequired,
  handleStoreItemSelection: PropTypes.func,
  eligibilityLoader: PropTypes.bool,
}

export const EligibilityFieldComponent = (props) => {
  let typeCheck = ''
  let EligibilityTypeCheck = props.getEligibilityFieldType()
  const dateFormat = _.get(props, 'user.selectedCountryData.countryConfig.DateFormat.DEFAULT', 'DD/MM/YYYY')
  const language = localStorage.getItem('languageCode')
  const locale = _.get(this, 'props.user.selectedCountryData.countryConfig.Locale.DEFAULT', 'en-IN')
  switch (EligibilityTypeCheck) {
    case 'DOP':
      {
        let curdate = new Date()
        typeCheck = (
          <DatePicker
            id='date-check'
            placeholder={dateFormat}
            format={dateFormat.toLowerCase()}
            maxDay={new Date()}
            minDay={new Date(curdate.getFullYear() - 10, curdate.getMonth(), curdate.getDate())}
            handleOnChange={(date) => props.handleDatePickerOnChange(date, 'date-check')}
            date={props.eligiblityCheckValue || ''}
            locale={locale}
            language={language}
          >
            <CalendarIcon className='calendar-icon' />
          </DatePicker>
        )
      }
      break
    case 'IMEIORSERIAL':
      typeCheck = (
        <Input
          className={{
            input: 'input-text boldFontStyle',
            formControl: props.isNewPlanSalesFlow ? 'input-container-eligibility-fields' : '',
            inputContainer: `flex-row-reverse ${
              props.eligibilityMessage && _.get(props, 'store.purchaseEligibilityInfo.EligibilityStatusCode') !== 'INELIGIBLE_DOB'
                ? 'error-outline '
                : ''
            }`,
          }}
          inputType='text'
          id='imeiorserial'
          placeholder={getTranslationText('store', 'enterIMEIorSerialeg')}
          handleOnChange={(e) => props.onEligiblityValueChange(e, props.autoCheck)}
          value={
            props.isNewPlanSalesFlow && _.get(props, 'store.imeiForFilter', '')
              ? _.get(props, 'store.imeiForFilter', '')
              : props.eligiblityCheckValue
          }
          otherProps={{
            dir: window.isDirectionRTL ? 'rtl' : 'ltr',
          }}
          isDisabled={props.isNewPlanSalesFlow && !!_.get(props, 'store.imeiForFilter')}
        >
          {props.showIMEILoader && !_.get(props, 'store.imeiForFilter', false) && <SFYLoader miniLoader />}
        </Input>
      )
      break
    case 'SERIALNO':
      typeCheck = (
        <Input
          className={{
            input: 'input-text boldFontStyle',
            formControl: props.isNewPlanSalesFlow ? 'input-container-eligibility-fields' : '',
          }}
          inputType='text'
          id='serialno'
          placeholder={getTranslationText('store', 'enterSerial')}
          handleOnChange={(e) => props.onEligiblityValueChange(e, props.autoCheck)}
          value={props.eligiblityCheckValue}
        >
          {props.showIMEILoader && <span className='content-loader align-right'></span>}
        </Input>
      )
      break
    case 'PRODUCTUNIQUEID':
    case 'IMEI':
    default:
      typeCheck = (
        <Input
          className={{
            input: 'input-text boldFontStyle',
            inputContainer: 'errorBoarder',
            formControl: props.isNewPlanSalesFlow ? 'input-container-eligibility-fields' : '',
          }}
          inputType='text'
          id='imeionly'
          placeholder={getTranslationText('store', 'enterIMEI')}
          handleOnChange={(e) => props.onEligiblityValueChange(e, props.autoCheck)}
          value={props.eligiblityCheckValue}
        >
          {props.showIMEILoader && <span className='content-loader align-right'></span>}
        </Input>
      )
  }
  return EligibilityTypeCheck ? (
    <div className='mt8 eligibility-check'>
      <div
        className={
          'eligibility-input-box ' +
          ((props.store.eligibleDeviceInfo && props.hasPriceAvailability) || props.disableIMEI ? 'disable-ip' : '')
        }
      >
        {typeCheck}
      </div>
      {/* {props.autoCheck ? (
        <div className='col-12 mt4 fontSmall'>
          <div className='fontSmall d-flex'>
            <InfoIcon className='mr6' />
            <span>{getTranslationText('store', 'enterIMEILabel')}</span>
          </div>
        </div>
      ) : (
        ''
      )} */}
      {props.showIMEIHint && (
        <div className={`mt4 ${props.isNewPlanSalesFlow ? 'fontMicro' : 'fontSmall'}`}>
          {getTranslationText('store', 'IMEIHintLabel')}
          <Tooltip
            content={getTranslationText(
              'store',
              _.get(props, 'user.appConfigDetails.app', '') === 'SamsungEU-Web' ? 'IMEIHintTextShort' : 'IMEIHintText'
            )}
            position='bottom'
          >
            <InfoIcon className='ml4' />
          </Tooltip>
        </div>
      )}
      {props.eligibilityMessage &&
        props.showEligibilityFailure &&
        _.get(props, 'store.purchaseEligibilityInfo.EligibilityStatusCode') !== 'INELIGIBLE_DOB' && (
          <div className={`mt4 fontSmall eligibility-${props.eligibilityStatus ? 'success' : 'error'}-msg`}>{props.eligibilityMessage}</div>
        )}
    </div>
  ) : (
    ''
  )
}
EligibilityFieldComponent.propTypes = {
  // selectedItems: PropTypes.object.isRequired,
  onEligiblityValueChange: PropTypes.func.isRequired,
  eligiblityCheckValue: PropTypes.string,
  store: PropTypes.object.isRequired,
  getEligibilityFieldType: PropTypes.func,
  handleDatePickerOnChange: PropTypes.func,
  hasPriceAvailability: PropTypes.bool,
  autoCheck: PropTypes.bool,
  showEligibilityFailure: PropTypes.bool,
}

export const CommonProductContainer = (props) => {
  /* eslint-disable max-len */
  /* eslint-disable no-useless-escape */
  // eslint-disable-next-line no-lone-blocks
  {
    _.get(props, 'user.appConfigDetails.SkipHeader', false) &&
      props.planInfo.PlanConfig.FaqList.map((_data, index) => {
        if (
          props.planInfo.PlanConfig.FaqList[index].body.includes(
            '<p>For the complete list of exclusions under the plan, kindly read the terms and conditions of the plan <a href= "https://servify.in/termsandconditions/nokia-phone-screen-protection/?mobile-tab" target="_blank">here</a></p>'
          )
        ) {
          props.planInfo.PlanConfig.FaqList[index].body = props.planInfo.PlanConfig.FaqList[index].body.replace(
            '<p>For the complete list of exclusions under the plan, kindly read the terms and conditions of the plan <a href= "https://servify.in/termsandconditions/nokia-phone-screen-protection/?mobile-tab" target="_blank">here</a></p>',
            ''
          )
        } else if (
          props.planInfo.PlanConfig.FaqList[index].body.includes(
            '<p>For the complete list of exclusions under the plan, kindly read the terms and conditions of the plan <a href= "https://in.servify.tech/termsandconditions/nokia-phone-insurance/?mobile-tab" target="_blank">here</a></p>'
          )
        ) {
          props.planInfo.PlanConfig.FaqList[index].body = props.planInfo.PlanConfig.FaqList[index].body.replace(
            '<p>For the complete list of exclusions under the plan, kindly read the terms and conditions of the plan <a href= "https://in.servify.tech/termsandconditions/nokia-phone-insurance/?mobile-tab" target="_blank">here</a></p>',
            ''
          )
        } else if (
          props.planInfo.PlanConfig.FaqList[index].body.includes(
            '<p>For the complete list of exclusions under the plan, kindly read the terms and conditions of the plan <a href= "https://in.servify.tech/termsandconditions/nokia-phone-extended-warranty/?mobile-tab" target="_blank">here</a></p>'
          )
        ) {
          props.planInfo.PlanConfig.FaqList[index].body = props.planInfo.PlanConfig.FaqList[index].body.replace(
            '<p>For the complete list of exclusions under the plan, kindly read the terms and conditions of the plan <a href= "https://in.servify.tech/termsandconditions/nokia-phone-extended-warranty/?mobile-tab" target="_blank">here</a></p>',
            ''
          )
        }
      })
  }
  const PlanConfig = _.get(props, 'planInfo.PlanConfig', {})
  return (
    <div className='row store-common-container'>
      <div className='col-sm-12'>
        {/* 1st section */}
        {_.get(PlanConfig, 'Section1', []).length ? (
          <BoxHolder className='mb20'>
            <div className='row'>
              {PlanConfig.Section1.map((planSection, index) => {
                return (
                  <div className='col-sm-12' key={index}>
                    <Heading title={planSection.SectionHeader} />
                    <div className='row mt20 key-benefits'>
                      {planSection.SectionList.map((data, idx) => (
                        <PlanImageTextContainer {...data} key={idx} mutliTxt={!!data.text2} />
                      ))}
                    </div>
                    {props.planInfo.PlanConfig.Section1.length !== index + 1 ? <hr size='2' className='mb28' /> : ''}
                  </div>
                )
              })}
            </div>
          </BoxHolder>
        ) : (
          ''
        )}
        {/* 2nd section */}
        {_.get(PlanConfig, 'Section2', []).length ? (
          <BoxHolder className='mb20'>
            <div className='row'>
              {PlanConfig.Section2.map((planSection, index) => {
                return (
                  <div className='col-sm-12' key={index}>
                    <Heading title={planSection.SectionHeader} />
                    <div className='row mt20'>
                      {planSection.SectionList.map((data, idx) => (
                        <PlanImageTextContainer {...data} key={idx} mutliTxt={!!data.text2} />
                      ))}
                    </div>
                    {PlanConfig.Section2.length !== index + 1 ? <hr size='2' className='mb28' /> : ''}
                  </div>
                )
              })}
            </div>
          </BoxHolder>
        ) : (
          ''
        )}
        {_.get(PlanConfig, 'TableSection', []).length ? (
          <BoxHolder className='mb20 fontSmall mobile-plan-table'>
            {PlanConfig.TableSection.map((tblData, index) => (
              <React.Fragment key={index}>
                <Heading title={tblData.TableHeading} />
                <div
                  className='mt20'
                  dangerouslySetInnerHTML={{
                    __html: tblData.TableBody,
                  }}
                />
                <div className='mt8 applePlanDetailsTaxNote'>{tblData.TableSubTxt}</div>
                {index + 1 < PlanConfig.TableSection.length && <hr size='2' className='mb20 mt20' />}
              </React.Fragment>
            ))}
          </BoxHolder>
        ) : (
          ''
        )}
        {/* 3rd Section */}
        {_.get(PlanConfig, 'Section3', []).length ? (
          <BoxHolder className='mb20'>
            {props.planInfo.PlanConfig.Section3.map((planSection, index) => {
              return (
                <div className='row' key={index}>
                  <div className='col-sm-12 mb20'>
                    <Heading title={planSection.SectionHeader} />
                  </div>
                  {planSection.SectionList && planSection.SectionList.length ? (
                    <div className='col-sm-12'>
                      <ul className='faq-list-items fontMedium'>
                        {planSection.SectionList.map((data, idx) => (
                          <li key={idx} dangerouslySetInnerHTML={{ __html: data }} />
                        ))}
                      </ul>
                    </div>
                  ) : (
                    ''
                  )}
                  {!_.get(props, 'user.appConfigDetails.SkipHeader', false) && (
                    <div className='col-sm-12 mt8'>
                      <div className='fontMedium' dangerouslySetInnerHTML={{ __html: planSection.endText }} />
                    </div>
                  )}
                </div>
              )
            })}
          </BoxHolder>
        ) : (
          ''
        )}
        {_.get(PlanConfig, 'CoverageSection', []).length ? (
          <BoxHolder className='mb20 fontSmall'>
            {PlanConfig.CoverageSection.map((data, index) => (
              <div className={index + 1 < PlanConfig.CoverageSection.length ? 'mb28' : ''} key={index}>
                <Heading className='mb20' title={data.CoverageHeading} />
                <div className='mediumFontStyle mt8'>{data.CoverageSubTxt}</div>
                <div className='mt20 mb8 progress-bar-wrapper'>
                  <div className='progress-bar-label mb8'>
                    {[0, 1, 2].map((data, index) => (
                      <div key={index}>Y{data}</div>
                    ))}
                  </div>
                  <div className='progress-bar-value mb8'>
                    <div style={{ width: data.progressBarValue + '%' }} />
                  </div>
                </div>
                <div className='lightFontStyle'>{data.CoverageFooterTxt}</div>
              </div>
            ))}
          </BoxHolder>
        ) : (
          ''
        )}

        {/* Faq List */}
        {_.get(PlanConfig, 'FaqList', []).length ? (
          <BoxHolder>
            <div className='row'>
              <div className='col-sm-12'>
                <Heading title={getTranslationText('common', 'faq')} />
              </div>
              <div className='col-sm-12'>
                <Accordion data={PlanConfig.FaqList} titleClassName='fontMedium' />
              </div>
            </div>
          </BoxHolder>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}
CommonProductContainer.propTypes = {
  planInfo: PropTypes.object.isRequired,
}

export const PlanImageTextContainer = (props) => {
  if (props.mutliTxt) {
    return (
      <div className='image-text-container col-sm-6 col-12 col-md-4 mt8 displayFlex'>
        <div
          className={'image-holder ' + (window.isDirectionRTL ? ' ml8 ' : ' mr8')}
          style={{ backgroundImage: 'url(' + props.image + ')' }}
        />
        <div className='text-holder'>
          <div
            className='mediumFontStyle'
            dangerouslySetInnerHTML={{
              __html: props.text1,
            }}
          />
          <div
            className='bookFontStyle fontMedium'
            dangerouslySetInnerHTML={{
              __html: props.text2,
            }}
          />
        </div>
      </div>
    )
  } else {
    return (
      <div
        className={
          'image-text-container col-sm-3 col-md-4 col-6 mb20 justify-content-center ' +
          (window.isDirectionRTL ? ' text-right ' : ' text-left')
        }
      >
        <div className='image-text-section'>
          <div className='image-holder mutliTxt' style={{ backgroundImage: 'url(' + props.image + ')' }} />
          <div
            className='mediumFontStyle fontMedium text-holder'
            dangerouslySetInnerHTML={{
              __html: props.text,
            }}
          />
        </div>
      </div>
    )
  }
}
PlanImageTextContainer.propTypes = {
  image: PropTypes.string.isRequired,
  text: PropTypes.string,
  mutliTxt: PropTypes.bool,
  text1: PropTypes.string,
  text2: PropTypes.string,
}

export const NewBuyStoreSideBar = (props) => {
  const dispatch = useDispatch()
  useEffect(() => {
    const requiredParams = _.get(props, 'store.requiredPaymentParams', [])
    if (!props.isAddressRequiredForPlanPurchase() && !(requiredParams && requiredParams.length > 0)) {
      props.getRequiredPaymentParamsAsync()
    }
  })
  const dateFormat = _.get(this, 'props.user.selectedCountryData.countryConfig.DateFormat.DEFAULT', 'DD/MM/YYYY')
  const language = localStorage.getItem('languageCode')
  const locale = _.get(this, 'props.user.selectedCountryData.countryConfig.Locale.DEFAULT', 'en-IN')
  const currentProgressBarIndex = 0
  const progressBarItems =
    !props.isAddressRequiredForPlanPurchase() || (_.get(props, 'user.userDetails.consumerID', '') && props.productUniqueID)
      ? ['', '']
      : ['', '', '']
  const [buyBtnStatus, setBuyBtnStatus] = useState(
    props.isAddressRequiredForPlanPurchase() ? !!_.get(props, 'user.userDetails.consumerID', false) : false
  )
  let IMEI = _.get(props, 'store.imeiForFilter', false)
  const showUserDetails = !!IMEI
  const name = ''
  const emailID = ''
  const [mobileNo, setMobileNo] = useState('')
  const [mobileNoCmpRef, setMobileNoCmpRef] = useState({})
  const [userDetailsCmpRef, setUserDetailsCmpRef] = useState({})
  const phnoLengthAr = _.get(props, 'user.selectedCountryData.countryConfig.MobileNumber.LENGTH', [10])
  const getMobileComponentRef = (ref) => setMobileNoCmpRef(ref)
  const getUserDetailsRef = (ref) => setUserDetailsCmpRef(ref)
  const [legalConsentState, setLegalConsentState] = useState(false)
  const [DOB, setDOB] = useState('')
  let dobInvalid = false
  let curdate = new Date()
  const onChangeDateOfBirth = (e) => {
    setDOB(e)
    dobInvalid = !e
    btnStatusHandler(!legalConsentState, e)
  }
  const btnStatusHandler = (consentState = legalConsentState, dob = DOB) => {
    let btnStatus = false
    if (!_.get(props, 'user.userDetails.consumerID', false)) {
      if (userDetailsCmpRef && userDetailsCmpRef.isProfileCompleted()) {
        btnStatus = true
      }
      btnStatus = btnStatus && mobileNoCmpRef && mobileNoCmpRef.getPhNoStatus()
    } else btnStatus = true
    if (
      !props.isAddressRequiredForPlanPurchase() &&
      requiredParams &&
      requiredParams.length === 1 &&
      requiredParams[0].RequiredParameter !== 'Zipcode'
    ) {
      btnStatus = !!(btnStatus && props.selectedState && Object.keys(props.selectedState).length > 0)
    }
    if (consents && consents.length > 0) {
      btnStatus = btnStatus && consentState
    }
    if (props.showDOB) btnStatus = btnStatus && !!dob
    IMEI = _.get(props, 'store.imeiForFilter', false)
    setBuyBtnStatus(btnStatus)
  }
  const handleLegalConsentSelection = () => {
    setLegalConsentState(!legalConsentState)
    btnStatusHandler(!legalConsentState)
  }
  const handleIPIDRedirection = (e) => {
    e.preventDefault()
    window.open(_.get(this.props, 'store.ipidUrl.DocumentURL'))
  }
  const handleOtherRelatedDocsRedirection = (e) => {
    e.preventDefault()
    window.open(_.get(this.props, 'store.otherRelatedDocsUrl.DocumentURL'))
  }
  const mobileNoVerificationCallBack = (mobileRef) => {
    let mobileNo = mobileRef.getPhNoStatus() && mobileRef.getMobileNo()
    setMobileNo(mobileNo)
  }
  const requiredParams = _.get(props, 'store.requiredPaymentParams', [])
  let cc = _.get(props, 'user.selectedCountryData.CountryCode', 'other').toLowerCase()
  if (!legalConfigCodes[cc]) cc = 'other'
  let has_gst_consent = hasConsent(_.get(props, 'user.appLegalConfigs.LegalConfigs', []), legalConfigCodes[cc].buy_tnc_gst_credit_na)
  const consents = getLegalConsents(_.get(props, 'user.appLegalConfigs.LegalConfigs', []), [
    has_gst_consent ? legalConfigCodes[cc].buy_tnc_gst_credit_na : legalConfigCodes[cc].buy_tnc,
    legalConfigCodes[cc].data,
    legalConfigCodes[cc].tnc_insurance,
  ])
  let linksData = {
    tnc: { label: 'planTermsAndCondition', link: props.planTAndCLink },
    privacy: { label: 'privacyPolicy', link: props.privacyLink },
    dataconsentstore: { label: 'dataConsentStore', link: props.dataLink },
  }
  const getLink = (link) => {
    if (link === 'tnc')
      return _.get(
        props,
        'user.tncLink',
        props.planTAndCLink || _.get(props, 'store.selectedPlanDetails.planMeta.TermsAndConditionsLink', props.user.appConfigDetails.Terms)
      )
    if (link === 'privacy') return _.get(props, 'user.privacyLink', props.privacyLink)
    if (link === 'dataconsentstore') return _.get(props, 'user.dataLink', props.dataLink)
    if (link === 'insurancecard' || link === 'ipid') return _.get(props, 'store.ipidUrl.DocumentURL', '')
    if (link === 'relateddocuments') return _.get(props, 'store.otherRelatedDocsUrl.DocumentURL', '')
    if (link === 'country') return getTranslationText('common', 'localized' + countryName.replaceAll(' ', '')) || countryName
  }
  const logConsent = () => {
    const consentsAr =
      consents.length &&
      consents.map((consent) => {
        let attributes = []
        let phs = getPlaceholders(consent.legalConfig.Text)
        let phs_unique = [...new Set(phs)]
        phs_unique.length &&
          phs_unique.map((ph) => {
            if (getLink(ph)) attributes.push({ AttributeType: legalConfigAttributes[ph], AttributeValue: getLink(ph) })
          })
        return {
          ApplicationLegalConfigID: consent.ApplicationLegalConfigID,
          MobileNo: !_.get(props, 'user.userDetails.consumerID', false) ? `${mobileNoCmpRef.getMobileNo()}` : '',
          ConsumerID: _.get(props, 'user.userDetails.consumerID', undefined),
          ConsentText: replacePlaceholders(consent.legalConfig.Text, _.get(props, 'user.selectedCountryData.CountryName', '')),
          Attributes: attributes,
        }
      })
    if (!_.get(props, 'user.userDetails.consumerID', false)) consentsAr[0].EmailID = userDetailsCmpRef.getUserEmailId()
    props.logConsentsAsync({ ConsentArray: consentsAr })
  }
  return (
    <>
      <div className='col-sm-12 dot-progress-bar no-padding'>
        <DotProgressBar
          currentIndex={currentProgressBarIndex}
          progressBarItems={progressBarItems}
          showEveryStepCount={true}
          showBackgroundColor={true}
        />
      </div>
      <BoxHolder className='box-holder-right proceed-to-purchase-sidebar'>
        {currentProgressBarIndex === 0 && (
          <div>
            {!(IMEI && _.get(props, 'user.userDetails.consumerID', false)) ? (
              <>
                <div className={'headings bookFontStyle ' + (window.isDirectionRTL ? ' pr0 ' : ' pl0 ')}>
                  <span className={`mediumFontStyle`}>{getTranslationText('store', 'proceedToPurchaseLabel')}</span>
                </div>
                <div className='mt20'>
                  <PlanEligibilityField
                    {...props}
                    mobileNoVerificationCallBack={mobileNoVerificationCallBack}
                    onEligiblityValueChange={props.eligibilityValueOnChange}
                    autoCheckEligibility={true}
                    showEligibilityFailure={true}
                    showIMEIHint={true}
                  />
                </div>
                <div className={`${props.eligibilityStatus ? '' : 'hide-visibility'} verified-status bookFontStyle fontMicro mt10 mb10`}>
                  {getTranslationText('store', 'deviceEligible')}
                </div>
              </>
            ) : (
              <div className={'headings bookFontStyle ' + (window.isDirectionRTL ? ' pr0 ' : ' pl0 ')}>
                <span className={`mediumFontStyle`}>{getTranslationText('store', 'selectYourAddress')}</span>
              </div>
            )}
            {!_.get(props, 'user.userDetails.consumerID', false) && (showUserDetails || props.eligibilityStatus) && (
              <div className='row mt20'>
                <div className='col-sm-12'>
                  <UserDetailsComponent
                    getUserDetailsRef={getUserDetailsRef}
                    EmailID={emailID}
                    Name={name}
                    userIpOnChangeCallBack={btnStatusHandler}
                  />
                </div>
                <div className={'col-sm-12 mt20'}>
                  <MobileNoComponent
                    getMobileComponentRef={getMobileComponentRef}
                    phnoLengthAr={phnoLengthAr}
                    mobileNo={mobileNo}
                    selectedCountryData={_.get(props, 'user.selectedCountryData', {})}
                    mobileNoOnChangeCallback={btnStatusHandler}
                  />
                </div>
                {props.showDOB && (
                  <div className={'col-sm-12 mt20'}>
                    <div className='bookFontStyle fontMedium mb8 overrideCasing'>
                      <span>{getTranslationText('common', 'dateOfBirthLabel', 'Date of Birth')}</span>
                    </div>
                    <div className='eligibility-input-box'>
                      <DatePicker
                        placeholder={dateFormat}
                        format={dateFormat.toLowerCase()}
                        classes={{ container: dobInvalid ? 'error-outline' : '' }}
                        maxDay={new Date()}
                        minDay={new Date(curdate.getFullYear() - 150, curdate.getMonth(), curdate.getDate())}
                        handleOnChange={onChangeDateOfBirth}
                        date={DOB ? new Date(DOB) : ''}
                        focusCalendarInitially={false}
                        locale={locale}
                        language={language}
                      />
                    </div>
                    {dobInvalid && (
                      <div className='mt4 fontSmall eligibility-error-msg'>{getTranslationText('common', 'invalidInput')}</div>
                    )}
                  </div>
                )}
              </div>
            )}
            <div className='row'>
              <div className='col-sm-12'>
                {(showUserDetails || props.eligibilityStatus) &&
                  !props.isAddressRequiredForPlanPurchase() &&
                  requiredParams &&
                  requiredParams.length === 1 &&
                  requiredParams[0].RequiredParameter !== 'Zipcode' && (
                    <div className='mt20'>
                      <div className='bookFontStyle mb10'>{getTranslationText('profile', 'stateLabel')}</div>
                      <Dropdown
                        id='store-dropdown'
                        options={_.get(requiredParams[0], 'StateList', []).map((state) => ({
                          ...state,
                          label: state.State,
                          value: state.State,
                        }))}
                        handleOnChange={(obj) => {
                          props.handleStateSelection(obj)
                          btnStatusHandler()
                        }}
                        value={props.selectedState.State || ''}
                        optionLength={_.get(requiredParams[0], 'StateList.length')}
                        isSearchable
                        searchText={getTranslationText('common', 'searchByStateText')}
                        filterBy='State'
                        placeholder={getTranslationText('store', 'selectStateLabel')}
                      />
                    </div>
                  )}
                {consents && consents.length && (showUserDetails || props.eligibilityStatus) ? (
                  <div className='mt20' id='legal-consent-1'>
                    <Checkbox
                      type='checkbox'
                      value={legalConsentState}
                      handleOnChange={handleLegalConsentSelection}
                      otherProps={{
                        style: window.isDirectionRTL ? { marginLeft: '5px' } : { marginRight: '5px' },
                      }}
                    >
                      <div className='bookFontStyle fontMedium'>
                        {getConsentTextChunks(consents[0].legalConfig.Text).length > 0 ? (
                          getConsentTextChunks(consents[0].legalConfig.Text).map((chunk, key) => {
                            if (chunk[0] === '@' && chunk[chunk.length - 1] === '@') {
                              let link = chunk.replace(/\@/g, '')
                              if (link === 'insurancecard') {
                                return (
                                  <Link className='termsfontColor' key={key} to={{}} onClick={handleIPIDRedirection}>
                                    {getTranslationText('store', 'insuranceCard')}
                                  </Link>
                                )
                              }
                              if (link === 'relateddocuments') {
                                return (
                                  <Link className='termsfontColor' key={key} to={{}} onClick={handleOtherRelatedDocsRedirection}>
                                    {getTranslationText('store', 'relateddocuments')}
                                  </Link>
                                )
                              }
                              if (link === 'country') {
                                return (
                                  <span key={key}>
                                    {getTranslationText(
                                      'common',
                                      'localized' + _.get(props, 'user.selectedCountryData.CountryName', '').replaceAll(' ', '')
                                    )}
                                  </span>
                                )
                              }
                              return (
                                <Link
                                  className='termsfontColor'
                                  target='_blank'
                                  to={props.handleRedirection(link, linksData[link].link)}
                                  onClick={() => {
                                    props.handleRedirection(link, linksData[link].link)
                                  }}
                                  key={key}
                                >
                                  {getTranslationText('common', linksData[link].label)}
                                </Link>
                              )
                            }
                            return <span key={key} dangerouslySetInnerHTML={{ __html: chunk.replace(/\n/g, '<br />') }} />
                          })
                        ) : (
                          <span htmlFor='legal-consent-1'>
                            <span dangerouslySetInnerHTML={{ __html: consents[0].legalConfig.Text }} />
                          </span>
                        )}
                      </div>
                    </Checkbox>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
            <MediumButton
              className={'full-width mt40'}
              onClick={async () => {
                if (
                  requiredParams &&
                  requiredParams.length === 1 &&
                  requiredParams[0].RequiredParameter !== 'Zipcode' &&
                  props.selectedState.StateCode
                )
                  dispatch(saveZipcodeOrState(props.selectedState, 'selectedState'))
                if (!_.get(props, 'user.userDetails.consumerID', false)) {
                  props.setglobalLoaderStatus(true)
                  dispatch(
                    setUserData({
                      email: userDetailsCmpRef.getUserEmailId(),
                      mobile: mobileNoCmpRef.getMobileNo(),
                      name: userDetailsCmpRef.getUserName(),
                      BirthDate: DOB,
                    })
                  )
                  const response = await props.validateUserDetails({
                    emailID: userDetailsCmpRef.getUserEmailId(),
                    mobileNo: mobileNoCmpRef.getMobileNo(),
                    name: userDetailsCmpRef.getUserName(),
                  })
                  pushToAnalytics('Plan Sales - Personal Details Confirmed', {
                    'Logged In': _.get(props, 'user.consumerDetails.data.consumerID', false) ? 'Yes' : 'No',
                    ConsumerID: _.get(this, 'props.user.consumerDetails.data.consumerID', undefined),
                    IMEI_checked_on_storepage: !!_.get(props, 'store.imeiForFilter', false),
                    eureka: !!_.get(props, 'store.imeiFromToken', false),
                    ProductName: _.get(props, 'store.purchaseEligibilityInfo.ProductName', ''),
                  })
                  if (response && response.success) {
                    dispatch(setConsumerAddress({}))
                    await props.checkPlanEligibility(
                      '',
                      '',
                      (dob, response) => {
                        if (response && response.success && response.data && response.data.IsPurchasedAllowed) {
                          props.buySelectedPlan({})
                          if (consents && consents.length > 0) {
                            if (consents && consents.length > 0) logConsent()
                          }
                        } else {
                          this.props.setToaster(response.msg || getTranslationText('error', 'somethingWentWrong'), 'error')
                        }
                      },
                      () => {
                        props.setToaster(getTranslationText('error', 'somethingWentWrong'), 'error')
                      }
                    )
                  } else props.setToaster((response && response.msg) || getTranslationText('error', 'somethingWentWrong'), 'error')
                } else {
                  dispatch(setConsumerAddress({}))
                  if (consents && consents.length > 0) logConsent()
                  props.buySelectedPlan({})
                }
              }}
              buttonText={getTranslationText('common', 'buyNowLabel')}
              disabled={!((props.eligibilityStatus || !!IMEI) && buyBtnStatus)}
            />
          </div>
        )}
      </BoxHolder>
    </>
  )
}
export class BuyStoreSideBar extends React.Component {
  constructor(props) {
    super(props)
    let brandName = _.get(props, 'store.selectedPlanDetails.EntityDetails[0].BrandName', '')
    this.userDetailsCmpRef = {}
    let cc = _.get(this, 'props.user.selectedCountryData.CountryCode', 'other').toLowerCase()
    if (!legalConfigCodes[cc]) cc = 'other'
    let has_gst_consent = hasConsent(_.get(this, 'props.user.appLegalConfigs.LegalConfigs', []), legalConfigCodes[cc].buy_tnc_gst_credit_na)
    this.state = {
      isAnApplePlan: brandName.toUpperCase() === 'APPLE',
      btnStatus: false,
      isOTPScreenEnabled: false,
      isMobileNoFieldReq: props.user.isAnGuestUser && brandName.toUpperCase() === 'APPLE',
      autoCheckEligibility: _.get(this, 'props.store.selectedPlanDetails.EntityDetails[0].WarrantyCheckDetails.ValidForEntireBrand', false),
      mobileNo: undefined,
      buytermsConditionState: false,
      legalConsent1State: false,
      legalConsent2State: false,
      showCaptcha: _.get(props, 'user.appConfigDetails.sendCaptcha', false),
      showNewUserWidget: false,
      newUserDetails: {
        EmailID: '',
        Name: '',
        status: false,
        loader: false,
      },
      // captchaSize: 'compact'
      consents: getLegalConsents(_.get(this, 'props.user.appLegalConfigs.LegalConfigs', []), [
        has_gst_consent ? legalConfigCodes[cc].buy_tnc_gst_credit_na : legalConfigCodes[cc].buy_tnc,
        legalConfigCodes[cc].data,
        legalConfigCodes[cc].tnc_insurance,
      ]),
      consents_all: getLegalConsents(_.get(this, 'props.user.appLegalConfigs.LegalConfigs', []), [
        has_gst_consent ? legalConfigCodes[cc].buy_tnc_gst_credit_na : legalConfigCodes[cc].buy_tnc,
        legalConfigCodes[cc].data,
        legalConfigCodes[cc].tnc_insurance,
        legalConfigCodes[cc].payment_decl,
        legalConfigCodes[cc].payment_decl2,
      ]),
      showDateOfPruchase: false,
      showProductPurchaseCost: false,
      disableIMEI: false,
      disableDeviceModel: false,
      disableDOB: true,
      dateOfBirthVerified: false,
      showEligibiltyPopup: false,
      dobInvalid: false,
      showEligibilityFailure: true,
    }
  }

  componentDidUpdate = (prevProps) => {
    if (this.props !== prevProps) {
      if (!_.get(this, 'props.store.selectedPlanDetails.EntityDetails[0].WarrantyCheckDetails.ValidForEntireBrand', false)) {
        this.btnStatusHandler(this.props)
        return
      }
      let hasCheckForEligibility = !_.isEmpty(_.get(this.props, 'store.purchaseEligibilityInfo', {}))
      if (this.state.isAnApplePlan && hasCheckForEligibility) {
        this.setState(
          {
            buytermsConditionState: !this.props.eligibilityStatus,
          },
          () => this.btnStatusHandler(this.props)
        )
      } else {
        this.btnStatusHandler(this.props)
      }
    }
  }

  componentDidMount() {
    if (!(this.state.consents_all.length && this.state.consents_all[0].legalConfig.Text))
      this.props.setToaster(getTranslationText('common', 'general_failure'), 'error')
    this.backListener = browserHistory.listen((location) => {
      if (location.action === 'POP' && !_.get(this, 'props.user.userDetails.birthDate', false)) {
        //clear DOB on back press
        this.props.handleDOBOnChange('')
      }
    })

    if (!this.props.isAddressRequiredForPlanPurchase() && !(_.get(this, 'props.store.requiredPaymentParams', []).length > 0)) {
      this.props.getRequiredPaymentParamsAsync()
    }
  }

  componentWillUnmount() {
    this.backListener()
  }

  handleSidebarIpSelection = () => {
    this.setState(
      {
        buytermsConditionState: !this.state.buytermsConditionState,
      },
      () => {
        this.btnStatusHandler(this.props)
      }
    )
  }

  handleLegalConsent1Selection = () => {
    this.setState(
      {
        legalConsent1State: !this.state.legalConsent1State,
      },
      () => {
        this.btnStatusHandler(this.props)
      }
    )
  }

  handleLegalConsent2Selection = () => {
    this.setState(
      {
        legalConsent2State: !this.state.legalConsent2State,
      },
      () => {
        this.btnStatusHandler(this.props)
      }
    )
  }

  handleIPIDRedirection = (e) => {
    e.preventDefault()
    window.open(_.get(this.props, 'store.ipidUrl.DocumentURL'))
  }

  handleOtherRelatedDocsRedirection = (e) => {
    e.preventDefault()
    window.open(_.get(this.props, 'store.otherRelatedDocsUrl.DocumentURL'))
  }

  onEligiblityValueChange = (e, autoCheck) => {
    this.props.eligibilityValueOnChange(e, autoCheck)
    this.setState({ showEligibiltyPopup: false, showEligibilityFailure: true })
  }

  mobileNoVerificationCallBack = (mobileRef) => {
    let mobileNo = mobileRef.getPhNoStatus() && mobileRef.getMobileNo()
    this.setState(
      {
        mobileNo,
      },
      () => this.btnStatusHandler(this.props)
    )
  }

  onChangeDateOfBirth = (e) => {
    this.props.handleDOBOnChange(e)
    this.setState({ dobInvalid: !e }, () => {
      this.btnStatusHandler(this.props)
    })
  }

  btnStatusHandler = (props) => {
    let btnStatus = this.state.buytermsConditionState
    if (this.state.consents.length) {
      btnStatus =
        this.state.consents.length === 2 ? this.state.legalConsent1State && this.state.legalConsent2State : this.state.legalConsent1State
    } else btnStatus = true
    let { buytermsConditionState, showCaptcha } = this.state
    let { hasPriceAvailability } = props
    if (this.state.isMobileNoFieldReq) {
      let eligiblityCheckValue = props.eligiblityCheckValue
      let type = /[A-z]/.test(eligiblityCheckValue) ? 'SerialNumber' : 'IMEI'
      let brandDetails = _.get(props, 'store.selectedPlanDetails.EntityDetails[0]', {})
      btnStatus = btnStatus && !!this.state.mobileNo && props.getValidationTypeLength(brandDetails, type) === eligiblityCheckValue.length
      if (hasPriceAvailability) {
        showCaptcha = false
        buytermsConditionState = true
        btnStatus = true
      }
    } else {
      let priceAvailability = !!hasPriceAvailability
      if (_.get(props, 'store.selectedPlanDetails.priceRange')) {
        priceAvailability = _.get(props, 'store.planPriceOnProductBasis')
      }
      let buyBtnStatus = true
      if (
        !!_.get(props, 'store.purchaseEligibilityInfo') &&
        _.get(props, 'store.selectedPlanDetails.EntityDetails[0].WarrantyCheckDetails.ValidForEntireBrand', false)
      ) {
        // Enable the button if IsPurchasedAllowed is true
        // Keep the button enabled if IsPurchasedAllowed is false but eligibility failed on the DOB
        buyBtnStatus =
          _.get(props, 'store.purchaseEligibilityInfo.EligibilityStatusCode', '') === 'INELIGIBLE_DOB' ||
          !!(_.get(props, 'store.purchaseEligibilityInfo.IsPurchasedAllowed', true) && priceAvailability)
      } else {
        buyBtnStatus = false
        if (this.props.eligiblityCheckValue) {
          let eligiblityCheckValue = this.props.eligiblityCheckValue
          let type = /[A-z]/.test(eligiblityCheckValue) ? 'SerialNumber' : 'IMEI'
          let brandDetails = _.get(props, 'store.selectedPlanDetails.EntityDetails[0]', {})
          buyBtnStatus =
            props.getValidationTypeLength(brandDetails, type) === eligiblityCheckValue.length &&
            !!(_.get(props, 'store.purchaseEligibilityInfo.IsPurchasedAllowed', false) && priceAvailability)
        }
        if (!props.showDOB) buyBtnStatus = buyBtnStatus && !!this.props.selectedDeviceModel
      }
      if (props.showDOB) buyBtnStatus = buyBtnStatus && !!props.dateOfBirth
      let dopDocumentsRequired = props.user.appConfigDetails.ShowStoreDocsUpload
      let productInfo = _.get(props, 'store.purchaseEligibilityInfo.InputParams', {})
      if (productInfo.DateOfPurchase || this.props.showDateOfPruchase) {
        // DOP Required
        if (dopDocumentsRequired) {
          buyBtnStatus = buyBtnStatus && props.secondaryDOPValue && _.get(props, 'user.storeDocs', []).length
        } else {
          buyBtnStatus = buyBtnStatus && !!props.secondaryDOPValue
        }
      }
      let isProductPurchaseCostAvailable = true
      if (productInfo.ProductPurchaseCost || this.props.showProductPurchaseCost) {
        isProductPurchaseCostAvailable = this.props.productPurchaseCost
      }
      btnStatus = btnStatus && buyBtnStatus && isProductPurchaseCostAvailable
      if (props.showDOB) btnStatus = btnStatus && !!props.dateOfBirth
    }
    this.setState({
      btnStatus,
      showCaptcha,
      buytermsConditionState,
    })
  }

  eligibilityHandler = (captchaToken) => {
    // apple plan purchase flow, not checking captchaToken
    if (_.get(this, 'state.isAnApplePlan', false)) {
      captchaToken = undefined
    }
    if (this.state.isMobileNoFieldReq && !this.props.hasPriceAvailability) {
      this.setState(
        {
          mobileNoLoader: true,
        },
        () => {
          let reqBody = {
            mobileNo: `${this.state.mobileNo}`,
          }
          if (this.state.showCaptcha && captchaToken) {
            reqBody = {
              ...reqBody,
              captcha: captchaToken,
            }
          }
          this.props
            .generateOTPAsync(reqBody)
            .then((data) => {
              if (data.success) {
                this.setState({
                  requestOtpResponse: data.data,
                  mobileNoLoader: false,
                  isOTPScreenEnabled: true,
                })
              }
            })
            .catch(() => {
              this.setState(
                {
                  mobileNo: '',
                  mobileNoLoader: false,
                  appleCaptchaValue: '',
                },
                () => {
                  this.btnStatusHandler(this.props)
                }
              )
            })
        }
      )
    } else {
      if (this.props.user.isAnGuestUser && this.state.isAnApplePlan) {
        this.setState({
          showNewUserWidget: true,
        })
      } else {
        this.props.buySelectedPlan({})
      }
    }
  }

  confirmBtnHandler = (shouldCheckDOBEligibility) => {
    let skipRemainingSteps = false
    if (shouldCheckDOBEligibility) {
      this.setState({ showEligibiltyPopup: true, showEligibilityFailure: false })
      this.props.checkPlanEligibility(this.state.mobileNo, this.props.dateOfBirth, this.confirmBtnHandler)
      return
    }
    let cc = _.get(this, 'props.user.selectedCountryData.CountryCode', 'other').toLowerCase()
    this.setState({ bleh: 'bleh' })
    if (!legalConfigCodes[cc]) cc = 'other'
    if (
      this.state.consents.length ||
      hasConsent(_.get(this, 'props.user.appLegalConfigs.LegalConfigs', []), legalConfigCodes[cc].payment_decl) ||
      hasConsent(_.get(this, 'props.user.appLegalConfigs.LegalConfigs', []), legalConfigCodes[cc].payment_decl2)
    ) {
      let consent = _.get(this, 'props.user.appLegalConfigs.LegalConfigs', []).find(
        (cfg) =>
          cfg.legalConfig &&
          (cfg.legalConfig.LegalConfigCode === legalConfigCodes[cc].payment_decl ||
            cfg.legalConfig.LegalConfigCode === legalConfigCodes[cc].payment_decl2)
      )
      if (
        !this.props.paymentDeclConsentShown &&
        (hasConsent(_.get(this, 'props.user.appLegalConfigs.LegalConfigs', []), legalConfigCodes[cc].payment_decl) ||
          hasConsent(_.get(this, 'props.user.appLegalConfigs.LegalConfigs', []), legalConfigCodes[cc].payment_decl2))
      ) {
        this.props.openConsentsPopup(consent.legalConfig.Text)
        skipRemainingSteps = true
      } else {
        this.props.closeConsentsPopup()
      }
    }
    // trigger log legal consent api
    if (!skipRemainingSteps) {
      if (
        (this.state.consents.length ||
          hasConsent(_.get(this, 'props.user.appLegalConfigs.LegalConfigs', []), legalConfigCodes[cc].payment_decl) ||
          hasConsent(_.get(this, 'props.user.appLegalConfigs.LegalConfigs', []), legalConfigCodes[cc].payment_decl2)) &&
        _.get(this, 'props.user.userDetails.consumerID')
      ) {
        let countryName = _.get(this.props, 'user.selectedCountryData.CountryName', '')
        let getLink = (link) => {
          if (link === 'tnc') return _.get(this, 'props.user.tncLink', this.props.planTAndCLink)
          if (link === 'privacy') return _.get(this, 'props.user.privacyLink', this.props.privacyLink)
          if (link === 'dataconsentstore') return _.get(this, 'props.user.dataLink', this.props.dataLink)
          if (link === 'insurancecard' || link === 'ipid') return _.get(this.props, 'store.ipidUrl.DocumentURL', '')
          if (link === 'relateddocuments') return _.get(this.props, 'store.otherRelatedDocsUrl.DocumentURL', '')
          if (link === 'country') return getTranslationText('common', 'localized' + countryName.replaceAll(' ', '')) || countryName
        }
        let consents_all = this.state.consents
        if (this.props.paymentDeclConsentText) {
          consents_all.push(
            _.get(this, 'props.user.appLegalConfigs.LegalConfigs', []).find(
              (cfg) =>
                cfg.legalConfig &&
                (cfg.legalConfig.LegalConfigCode === legalConfigCodes[cc].payment_decl ||
                  cfg.legalConfig.LegalConfigCode === legalConfigCodes[cc].payment_decl2)
            )
          )
        }
        let consents = consents_all.map((consent) => {
          let attributes = []
          let phs = getPlaceholders(consent.legalConfig.Text)
          let phs_unique = [...new Set(phs)]
          phs_unique.length &&
            phs_unique.map((ph) => {
              attributes.push({ AttributeType: legalConfigAttributes[ph], AttributeValue: getLink(ph) })
            })
          return {
            ApplicationLegalConfigID: consent.ApplicationLegalConfigID,
            ConsumerID: _.get(this, 'props.user.userDetails.consumerID'),
            ConsentText: replacePlaceholders(consent.legalConfig.Text, _.get(this.props, 'user.selectedCountryData.CountryName', '')),
            Attributes: attributes,
          }
        })
        this.props.logConsentsAsync({ ConsentArray: consents })
      }
      if (this.state.isMobileNoFieldReq && this.state.showCaptcha && this.state.isAnApplePlan) {
        this.setState(
          {
            mobileNoLoader: true,
          },
          async () => {
            const captcha = await getCaptcha()
            this.eligibilityHandler(captcha)
          }
        )
      } else if (!_.get(this, 'props.store.selectedPlanDetails.EntityDetails[0].WarrantyCheckDetails.ValidForEntireBrand', false)) {
        this.props.checkPlanPurchaseEligibility()
      } else {
        this.eligibilityHandler()
      }
    }
  }

  setAppleNewUserDetails = () => {
    this.setState({ showNewUserWidget: true, isOTPScreenEnabled: false })
  }

  applePlanEligiblity = () => {
    this.setState(
      {
        isOTPScreenEnabled: false,
        isMobileNoFieldReq: false,
        buytermsConditionState: true,
      },
      () => {
        this.props.checkPlanEligibility(this.state.mobileNo, '', (dob, response) => {
          if (response && response.success && response.data && response.data.IsPurchasedAllowed) {
            this.props.buySelectedPlan({})
          }
        })
      }
    )
  }

  getUserDetailsRef = (ref) => (this.userDetailsCmpRef = ref)

  newUserBtnStatusHandler = () => {
    let btnStatus = false
    let newUserDetails = this.state.newUserDetails
    if (this.userDetailsCmpRef.isProfileCompleted()) {
      btnStatus = true
      newUserDetails = {
        ...newUserDetails,
        EmailID: this.userDetailsCmpRef.getUserEmailId(),
        Name: this.userDetailsCmpRef.getUserName(),
      }
    }
    this.setState({
      newUserDetails: {
        ...newUserDetails,
        status: btnStatus,
      },
    })
  }

  newUserDetailsHander = () => {
    this.setState(
      {
        mobileNoLoader: true,
      },
      () => {
        this.props
          .updateUserDetailsAsync({
            data: {
              consumerID: _.get(this, 'props.user.consumerDetails.data.consumerID'),
              updateObj: {
                emailID: this.state.newUserDetails.EmailID,
                name: this.state.newUserDetails.Name,
              },
            },
          })
          .then(() => {
            this.setState(
              {
                showNewUserWidget: false,
                mobileNoLoader: false,
              },
              () => {
                if (this.state.isAnApplePlan) this.applePlanEligiblity()
                else this.props.buySelectedPlan({})
              }
            )
          })
          .catch(() => {
            // this.props.resetPlanEligibilityChecks()
          })
      }
    )
  }

  closeEligibilityModal = () => {
    this.setState({
      showEligibiltyPopup: false,
    })
  }
  currentProgressBarIndex = 0
  progressBarItems = _.get(this, 'props.user.userDetails.consumerID', false) ? ['', ''] : ['', '', '']

  render() {
    let dopDocumentsRequired = this.props.user.appConfigDetails.ShowStoreDocsUpload
    let productInfo = _.get(this.props, 'store.purchaseEligibilityInfo.InputParams', {})
    let curdate = new Date()
    let loaderState = !!(this.props.eligibilityLoader || this.state.mobileNoLoader)
    let linksData = {
      tnc: { label: 'planTermsAndCondition', link: this.props.planTAndCLink },
      privacy: { label: 'privacyPolicy', link: this.props.privacyLink },
      dataconsentstore: { label: 'dataConsentStore', link: this.props.dataLink },
    }
    const dateFormat = _.get(this, 'props.user.selectedCountryData.countryConfig.DateFormat.DEFAULT', 'DD/MM/YYYY')
    const language = localStorage.getItem('languageCode')
    const locale = _.get(this, 'props.user.selectedCountryData.countryConfig.Locale.DEFAULT', 'en-IN')
    const requiredParams = _.get(this, 'props.store.requiredPaymentParams', [])
    return (
      <Loader loaderState={loaderState}>
        {this.props.isNewPlanSalesFlow || _.get(this, 'props.store.selectedBrand.BrandName', '').toUpperCase() === 'APPLE' ? (
          <div className='col-sm-12 dot-progress-bar no-padding'>
            <DotProgressBar
              currentIndex={this.currentProgressBarIndex + (this.state.mobileNo && this.state.showNewUserWidget ? 1 : 0)}
              progressBarItems={this.progressBarItems}
              showEveryStepCount={true}
              showBackgroundColor={true}
            />
          </div>
        ) : (
          ''
        )}
        <BoxHolder className={'box-holder-right proceed-to-purchase-sidebar ' + (loaderState ? 'disableBlock' : '')}>
          {this.state.isOTPScreenEnabled ? (
            <div className='row eligibility-otp-widget'>
              <SignUpSideBar
                {...this.props}
                isAnApplePurchaseFlow
                mobileNo={this.state.mobileNo}
                emailID={this.state.newUserDetails.EmailID}
                requestOtpResponse={this.state.requestOtpResponse}
                applePlanEligiblity={this.applePlanEligiblity}
                setAppleNewUserDetails={this.setAppleNewUserDetails}
              />
            </div>
          ) : this.state.mobileNo && this.state.showNewUserWidget ? (
            <React.Fragment>
              <div className='col-sm-12 '>
                <Heading title={getTranslationText('store', 'signInPurchase')} />
                <div className={'headings bookFontStyle ' + (window.isDirectionRTL ? ' pr0 ' : ' pl0 ')}>
                  <span className={`mediumFontStyle`}>{getTranslationText('store', 'enterYourDetails')}</span>
                </div>
                <form
                  onSubmit={this.state.newUserDetails.status ? this.newUserDetailsHander : () => {}}
                  className='mt20'
                  target='remember_store'
                  autoComplete='on'
                >
                  <div className='row'>
                    <div className='col-sm-12 mb20'>
                      <UserDetailsComponent
                        getUserDetailsRef={this.getUserDetailsRef}
                        EmailID={this.state.newUserDetails.EmailID}
                        Name={this.state.newUserDetails.Name}
                        userIpOnChangeCallBack={this.newUserBtnStatusHandler}
                      />
                    </div>
                    {!this.props.isAddressRequiredForPlanPurchase() &&
                      requiredParams &&
                      requiredParams.length === 1 &&
                      requiredParams[0].RequiredParameter !== 'Zipcode' && (
                        <div className='col-sm-12 mb20'>
                          <div className='bookFontStyle mb10'>{getTranslationText('profile', 'stateLabel')}</div>
                          <Dropdown
                            id='store-dropdown'
                            options={_.get(requiredParams[0], 'StateList', []).map((state) => ({
                              ...state,
                              label: state.State,
                              value: state.State,
                            }))}
                            handleOnChange={(obj) => {
                              this.props.handleStateSelection(obj)
                            }}
                            value={this.props.selectedState.State || ''}
                            optionLength={_.get(requiredParams[0], 'StateList.length')}
                            isSearchable
                            searchText={getTranslationText('common', 'searchByStateText')}
                            filterBy='State'
                            placeholder={getTranslationText('store', 'selectStateLabel')}
                          />
                        </div>
                      )}
                    <div className='col-sm-12 mt20 mb10'>
                      <MediumButton
                        buttonText={getTranslationText('common', 'continue')}
                        disabled={!(this.state.newUserDetails.status && this.props.selectedState.State)}
                        className={'btnSignIn'}
                        onClick={this.newUserDetailsHander}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className={'headings bookFontStyle ' + (window.isDirectionRTL ? ' pr0 ' : ' pl0 ')}>
                <span className={`mediumFontStyle`}>{getTranslationText('store', 'proceedToPurchaseLabel')}</span>
              </div>
              <div className='proceed-to-purchase-content mt20'>
                {!this.state.autoCheckEligibility ? (
                  <React.Fragment>
                    <Input
                      className={{
                        input: `input-text boldFontStyle input-field ${this.props.showEligibilityStatus ? 'mb4' : 'mb20'}`,
                        label: 'bookFontStyle fontMedium mb8 overrideCasing',
                      }}
                      label={getTranslationText('store', 'deviceBrandLabel')}
                      inputType='text'
                      placeholder={_.get(this, 'props.store.selectedPlanDetails.EntityDetails[0].BrandName', '')}
                      isDisabled
                    />
                  </React.Fragment>
                ) : (
                  ''
                )}
                {_.get(this.props, 'selectedItems.PlanConfig.SuperGroupEligibilityCheckFields') ||
                _.get(this.props, 'store.selectedPlanDetails.EntityDetails[0]') ? (
                  <div className='mt20 mb20'>
                    <PlanEligibilityField
                      {...this.props}
                      onEligiblityValueChange={this.onEligiblityValueChange}
                      isMobileNoFieldReq={this.state.isMobileNoFieldReq}
                      mobileNo={this.state.mobileNo}
                      mobileNoVerificationCallBack={this.mobileNoVerificationCallBack}
                      autoCheckEligibility={this.state.autoCheckEligibility}
                      showEligibilityFailure={this.state.showEligibilityFailure}
                      showIMEIHint={true}
                    />
                  </div>
                ) : (
                  ''
                )}
                {this.props.showDOB ? (
                  <div className='mt20 mb20 eligibility-check'>
                    <div className='bookFontStyle fontMedium mb8 overrideCasing'>
                      <span>{getTranslationText('common', 'dateOfBirthLabel', 'Date of Birth')}</span>
                    </div>
                    <div className='eligibility-input-box'>
                      <DatePicker
                        placeholder={dateFormat}
                        format={dateFormat.toLowerCase()}
                        classes={{ container: this.state.dobInvalid ? 'error-outline' : '' }}
                        maxDay={new Date()}
                        minDay={new Date(curdate.getFullYear() - 150, curdate.getMonth(), curdate.getDate())}
                        handleOnChange={this.onChangeDateOfBirth}
                        date={this.props.dateOfBirth ? new Date(this.props.dateOfBirth) : ''}
                        isDisabled={this.props.disableDOB}
                        focusCalendarInitially={false}
                        locale={locale}
                        language={language}
                      >
                        <CalendarIcon className='calendar-icon' />
                      </DatePicker>
                    </div>
                    {this.state.dobInvalid && (
                      <div className='mt4 fontSmall eligibility-error-msg'>{getTranslationText('common', 'invalidInput')}</div>
                    )}
                  </div>
                ) : (
                  ''
                )}
                {productInfo.DateOfPurchase || this.props.showDateOfPruchase ? (
                  <div className='mt20 mb20 eligibility-check'>
                    <div className='bookFontStyle fontMedium mb8 overrideCasing'>
                      <span>{getTranslationText('common', 'dateOfPurchaseLabel', 'Date of Purchase')}</span>
                    </div>
                    <div className='eligibility-input-box'>
                      <DatePicker
                        placeholder={dateFormat}
                        format={dateFormat.toLowerCase()}
                        maxDay={new Date()}
                        minDay={new Date(curdate.getFullYear() - 10, curdate.getMonth(), curdate.getDate())}
                        handleOnChange={this.props.handleSecondaryDOP}
                        date={this.props.secondaryDOPValue || ''}
                        locale={locale}
                        language={language}
                      >
                        <CalendarIcon className='calendar-icon' />
                      </DatePicker>
                    </div>
                    <div className='col-sm-12'>
                      {this.props.secondaryDOPValue && dopDocumentsRequired && (
                        <div className='mt20 mb8' onClick={this.props.showUploadDocsModal}>
                          <div className='bookFontStyle fontMedium special-link-txt'>{getTranslationText('store', 'uploadDocuments')}</div>
                          {this.props.deviceDocs && this.props.deviceDocs.length > 0 && (
                            <div className='bookFontStyle fontSmall'>
                              ({this.props.deviceDocs.length + ' ' + getTranslationText('store', 'uploadDocuments')})
                            </div>
                          )}
                        </div>
                      )}
                      {this.props.showDeviceDocsUploadModal ? (
                        <Modal className='sfy-modal docs-container-modal' showModal={this.props.showDeviceDocsUploadModal}>
                          <div className='docs-container'>
                            <div className='custom-modal-title mb20'>
                              <Heading title={getTranslationText('store', 'uploadDeviceDocuments')} />
                              <CustomIconComponent
                                className='close-icon'
                                onClick={this.props.closeDeviceDocsUploadModal}
                                alt={getTranslationText('common', 'cancel')}
                              />
                            </div>
                            <div className='custom-modal-content'>
                              <div className='docs-list-container'>
                                <BoxImagesComponent
                                  isUploaded={false}
                                  className='store-device-docs-add'
                                  imgFilePath={<AddIcon />}
                                  inputName='deviceDocs-upload'
                                  inputId={'upload-img-ip-id'}
                                  fileUploadHander={this.props.handleUploadDocs}
                                />
                                {_.get(this.props, 'deviceDocs', []).length > 0 &&
                                  this.props.deviceDocs.map((data) => {
                                    return (
                                      <BoxImagesComponent
                                        key={data.ConsumerProductDocumentID}
                                        className='store-device-docs'
                                        isUploaded
                                        imgFilePath={data.fileUrl}
                                        imageID={data.ConsumerProductDocumentID}
                                      />
                                    )
                                  })}
                              </div>
                            </div>
                            <div className='center-align'>
                              <MediumButton
                                className='mt20'
                                onClick={this.props.closeDeviceDocsUploadModal}
                                btnLoader={this.props.docsBtnLoader}
                                disabled={!(this.props.deviceDocs && this.props.deviceDocs.length)}
                                buttonText={getTranslationText('common', 'done')}
                              />
                            </div>
                          </div>
                        </Modal>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                ) : (
                  ''
                )}
                {productInfo.ProductPurchaseCost || this.props.showProductPurchaseCost ? (
                  <React.Fragment>
                    <Input
                      className={{
                        input: `input-text boldFontStyle input-field ${this.props.showEligibilityStatus ? 'mb4' : 'mb20'}`,
                        label: 'bookFontStyle fontMedium mb8 overrideCasing',
                      }}
                      id='productPurchaseCost'
                      label={getTranslationText('store', 'productPurchaseCost')}
                      inputType='text'
                      placeholder={getTranslationText('store', 'productPurchaseCost')}
                      maxLength={6}
                      handleOnChange={this.props.handleOnProductPurchaseCostChange}
                    />
                  </React.Fragment>
                ) : (
                  ''
                )}
                {!this.state.autoCheckEligibility && _.get(this.props, 'store.planProducts', []).length ? (
                  <React.Fragment>
                    <div className='bookFontStyle fontMedium mb8 mt20 overrideCasing'>
                      {getTranslationText('store', 'deviceModelLabel')}
                    </div>
                    <Dropdown
                      id='device-model-dropdown'
                      options={
                        _.get(this.props, 'store.planProducts', []).length
                          ? _.get(this.props, 'store.planProducts', []).map((productsObj) => ({
                              ...productsObj,
                              value: productsObj.ProductName,
                            }))
                          : []
                      }
                      isDisabled={this.props.disableDeviceModel}
                      handleOnChange={this.props.handleDeviceModelChange}
                      value={this.props.selectedDeviceModel || ''}
                      placeholder={getTranslationText('store', 'selectDeviceModel')}
                      searchText={getTranslationText('store', 'searchByModel')}
                      filterBy='ProductName'
                      showMoreText={getTranslationText('common', 'showMoreText')}
                    />
                  </React.Fragment>
                ) : (
                  ''
                )}

                {this.props.showEligibilityStatus &&
                this.props.eligibilityStatus &&
                (_.get(this, 'props.store.selectedPlanDetails.PlanConfig.SuperGroupEligibilityCheckFields', []).length <= 1 ||
                  (_.get(this, 'props.store.selectedPlanDetails.PlanConfig.SuperGroupEligibilityCheckFields', []).length == 2 &&
                    _.get(this, 'props.store.selectedPlanDetails.PlanConfig.SuperGroupEligibilityCheckFields', []).includes(
                      'ProductUniqueID'
                    ) &&
                    _.get(this, 'props.store.selectedPlanDetails.PlanConfig.SuperGroupEligibilityCheckFields', []).includes(
                      'DateOfPurchase'
                    ))) ? (
                  <div className='verified-status bookFontStyle mb20'>{getTranslationText('store', 'deviceEligible')}</div>
                ) : (
                  ''
                )}

                {this.state.consents && this.state.consents.length ? (
                  <React.Fragment>
                    <div className='purchase-selectables mt20 mb20' id='legal-consent-1'>
                      <Checkbox type='checkbox' value={this.state.legalConsent1State} handleOnChange={this.handleLegalConsent1Selection}>
                        <div className='bookFontStyle fontMedium'>
                          {getConsentTextChunks(this.state.consents[0].legalConfig.Text).length > 0 ? (
                            getConsentTextChunks(this.state.consents[0].legalConfig.Text).map((chunk, key) => {
                              if (chunk[0] === '@' && chunk[chunk.length - 1] === '@') {
                                let link = chunk.replace(/\@/g, '')
                                if (link === 'insurancecard') {
                                  return (
                                    <Link key={key} className='termsfontColor' to={{}} onClick={this.handleIPIDRedirection}>
                                      {getTranslationText('store', 'insuranceCard')}
                                    </Link>
                                  )
                                }
                                if (link === 'relateddocuments') {
                                  return (
                                    <Link key={key} className='termsfontColor' to={{}} onClick={this.handleOtherRelatedDocsRedirection}>
                                      {getTranslationText('store', 'relateddocuments')}
                                    </Link>
                                  )
                                }
                                if (link === 'country') {
                                  return (
                                    <span key={key}>
                                      {getTranslationText(
                                        'common',
                                        'localized' + _.get(this.props, 'user.selectedCountryData.CountryName', '').replaceAll(' ', '')
                                      )}
                                    </span>
                                  )
                                }
                                return (
                                  <Link
                                    className='termsfontColor'
                                    target='_blank'
                                    to={this.props.handleRedirection(link, linksData[link].link)}
                                    onClick={() => {
                                      this.props.handleRedirection(link, linksData[link].link)
                                    }}
                                    key={key}
                                  >
                                    {getTranslationText('common', linksData[link].label)}
                                  </Link>
                                )
                              }
                              return <span key={key} dangerouslySetInnerHTML={{ __html: chunk.replace(/\n/g, '<br />') }} />
                            })
                          ) : (
                            <span htmlFor='legal-consent-1'>
                              <span dangerouslySetInnerHTML={{ __html: this.state.consents[0].legalConfig.Text }} />
                            </span>
                          )}
                        </div>
                      </Checkbox>
                    </div>
                    {this.state.consents[1] && this.state.consents[1].legalConfig.Text ? (
                      <div className='purchase-selectables mt8 mb20' id='legal-consent-2'>
                        <Checkbox
                          type='checkbox'
                          id='legal-consent-2'
                          value={this.state.legalConsent2State}
                          handleOnChange={this.handleLegalConsent2Selection}
                        >
                          <div className='bookFontStyle fontMedium'>
                            {getConsentTextChunks(this.state.consents[1].legalConfig.Text).length > 0 ? (
                              getConsentTextChunks(this.state.consents[1].legalConfig.Text).map((chunk, key) => {
                                if (chunk[0] === '@' && chunk[chunk.length - 1] === '@') {
                                  let link = chunk.replace(/\@/g, '')
                                  if (link === 'insurancecard') {
                                    return (
                                      <Link key={key} className='termsfontColor' to={{}} onClick={this.handleIPIDRedirection}>
                                        {getTranslationText('store', 'insuranceCard')}
                                      </Link>
                                    )
                                  }
                                  if (link === 'relateddocuments') {
                                    return (
                                      <Link key={key} className='termsfontColor' to={{}} onClick={this.handleOtherRelatedDocsRedirection}>
                                        {getTranslationText('store', 'relateddocuments')}
                                      </Link>
                                    )
                                  }
                                  if (link === 'country') {
                                    return (
                                      <span key={key}>
                                        {getTranslationText(
                                          'common',
                                          'localized' + _.get(this.props, 'user.selectedCountryData.CountryName', '').replaceAll(' ', '')
                                        )}
                                      </span>
                                    )
                                  }
                                  return (
                                    <Link
                                      key={key}
                                      className='termsfontColor'
                                      target='_blank'
                                      to={linksData[link].link}
                                      onClick={() => {
                                        this.props.handleRedirection(link, linksData[link].link)
                                      }}
                                    >
                                      {getTranslationText('common', linksData[link].label)}
                                    </Link>
                                  )
                                }
                                return <span key={key} dangerouslySetInnerHTML={{ __html: chunk.replace(/\n/g, '<br />') }} />
                              })
                            ) : (
                              <span htmlFor='legal-consent-2'>
                                <span dangerouslySetInnerHTML={{ __html: this.state.consents[1].legalConfig.Text }} />
                              </span>
                            )}
                          </div>
                        </Checkbox>
                      </div>
                    ) : (
                      ''
                    )}
                  </React.Fragment>
                ) : (
                  ''
                )}
                {this.state.isMobileNoFieldReq && this.state.showCaptcha && <Captcha recaptcha={recaptcha} />}
                <MediumButton
                  className='full-width mt8'
                  onClick={() => {
                    this.confirmBtnHandler(this.props.showDOB && this.props.dateOfBirth)
                  }}
                  buttonText={getTranslationText('common', 'buyNowLabel')}
                  disabled={!this.state.btnStatus}
                />
              </div>
            </React.Fragment>
          )}
        </BoxHolder>
        <Modal className='sfy-modal successfully-added-device-content consent-modal' showModal={this.props.showPaymentDeclPopup}>
          <CustomIconComponent
            className={'close-icon ' + (window.isDirectionRTL ? 'appear-left text-left ' : 'appear-right text-right')}
            onClick={this.props.closeConsentsPopup}
          />
          <div className='text-center'>
            <div className='bookFontStyle fontLarge mt8'>{getTranslationText('common', 'declaration')}</div>
          </div>
          <div className='text-left'>
            {getConsentTextChunks(this.props.paymentDeclConsentText).length > 0 ? (
              getConsentTextChunks(this.props.paymentDeclConsentText).map((chunk, key) => {
                if (chunk[0] === '@' && chunk[chunk.length - 1] === '@') {
                  let link = chunk.replace(/\@/g, '')
                  if (link === 'insurancecard') {
                    return (
                      <Link key={key} className='termsfontColor' to={{}} onClick={this.handleIPIDRedirection}>
                        {getTranslationText('store', 'insuranceCard')}
                      </Link>
                    )
                  }
                  if (link === 'relateddocuments') {
                    return (
                      <Link key={key} className='termsfontColor' to={{}} onClick={this.handleOtherRelatedDocsRedirection}>
                        {getTranslationText('store', 'relateddocuments')}
                      </Link>
                    )
                  }
                  if (link === 'country') {
                    return (
                      <span key={key}>
                        {getTranslationText(
                          'common',
                          'localized' + _.get(this.props, 'user.selectedCountryData.CountryName', '').replaceAll(' ', '')
                        ) || _.get(this.props, 'user.selectedCountryData.CountryName', '')}
                      </span>
                    )
                  }
                  return (
                    <Link
                      key={key}
                      className='termsfontColor'
                      target='_blank'
                      to={linksData[link].link}
                      onClick={() => {
                        this.props.handleRedirection(link, linksData[link].link)
                      }}
                    >
                      {getTranslationText('common', linksData[link].label)}
                    </Link>
                  )
                }
                return <span key={chunk} dangerouslySetInnerHTML={{ __html: chunk.replace(/\n/g, '<br />') }} />
              })
            ) : (
              <div
                className='bookFontStyle fontSmall mt8'
                dangerouslySetInnerHTML={{ __html: this.props.paymentDeclConsentText.replace(/\n/g, '<br />') }}
              />
            )}
            {/* <div
                  className='bookFontStyle fontSmall mt8'
                  dangerouslySetInnerHTML={{ __html: this.state.paymentDeclConsentText.replace(/\n/g, '<br />') }}
                /> */}
          </div>
          <div className='text-center'>
            <MediumButton
              className='boldFontStyle fontMedium mt8'
              onClick={() => {
                this.confirmBtnHandler(this.props.showDOB && this.props.dateOfBirth && !this.props.dateOfBirthVerified)
              }}
              buttonText={getTranslationText('common', 'confirm')}
            />
          </div>
        </Modal>
        {this.state.showEligibiltyPopup && this.props.showEligibilityStatus && !this.props.eligibilityStatus ? (
          <Modal className='sfy-modal successfully-added-device-content' showModal={this.props.showEligibilityStatus}>
            <CustomIconComponent
              className={'close-icon ' + (window.isDirectionRTL ? 'appear-left text-left ' : 'appear-right text-right')}
              onClick={this.closeEligibilityModal}
            />
            <div className='text-center'>
              {<img src='/commonAssets/failed.png' />}
              <div className='bookFontStyle fontLarge mt8'>{getTranslationText('common', 'sorry')}</div>
              <div className='bookFontStyle fontSmall mt8'>
                <span>{this.props.eligibilityMessage || getTranslationText('store', 'notEligibleLabel')}</span>
              </div>
              <MediumButton
                className='boldFontStyle fontMedium mt8'
                onClick={this.closeEligibilityModal}
                buttonText={getTranslationText('common', 'okay')}
              />
            </div>
          </Modal>
        ) : (
          ''
        )}
      </Loader>
    )
  }
  static propTypes = {
    user: PropTypes.object.isRequired,
    handleSidebarIpSelection: PropTypes.func,
    buyNowButtonLoder: PropTypes.bool,
    docsBtnLoader: PropTypes.bool,
    buytermsConditionState: PropTypes.bool,
    legalConsent1State: PropTypes.bool,
    legalConsent2State: PropTypes.bool,
    consents: PropTypes.object,
    getBrandDetails: PropTypes.func.isRequired,
    eligibilityStatus: PropTypes.bool,
    selectedItems: PropTypes.object,
    buySelectedPlan: PropTypes.func.isRequired,
    store: PropTypes.object.isRequired,
    eligibilityLoader: PropTypes.bool,
    eligiblityCheckValue: PropTypes.string,
    handleSecondaryDOP: PropTypes.func,
    productPurchaseCost: PropTypes.string,
    selectedDeviceModel: PropTypes.string,
    handleOnProductPurchaseCostChange: PropTypes.func,
    handleDeviceModelChange: PropTypes.func,
    secondaryDOPValue: PropTypes.any,
    dateOfBirth: PropTypes.any,
    showUploadDocsModal: PropTypes.func,
    closeDeviceDocsUploadModal: PropTypes.func,
    handleUploadDocs: PropTypes.func,
    enableSecondaryBtnLoader: PropTypes.bool,
    showDeviceDocsUploadModal: PropTypes.bool,
    showEligibilityStatus: PropTypes.bool,
    eligibilityMessage: PropTypes.string,
    deviceDocs: PropTypes.array,
    hasPriceAvailability: PropTypes.bool,
    setToaster: PropTypes.func,
    generateOTPAsync: PropTypes.func,
    checkPlanEligibility: PropTypes.func.isRequired,
    checkPlanPurchaseEligibility: PropTypes.func.isRequired,
    updateUserDetailsAsync: PropTypes.func,
    planTAndCLink: PropTypes.string,
    privacyLink: PropTypes.string,
    showDateOfPruchase: PropTypes.bool,
    showProductPurchaseCost: PropTypes.bool,
    disableIMEI: PropTypes.bool,
    disableDeviceModel: PropTypes.bool,
    showDOB: PropTypes.bool,
    disableDOB: PropTypes.bool,
    dateOfBirthVerified: PropTypes.bool,
  }
}

export class PlanEligibilityField extends React.Component {
  constructor(props) {
    super(props)
    this.mobileNoCmpRef = {}
    this.state = {
      btnStatus: false,
      phnoLengthAr: _.get(this.props, 'user.selectedCountryData.countryConfig.MobileNumber.LENGTH', [10]),
    }
  }

  getMobileComponentRef = (ref) => (this.mobileNoCmpRef = ref)

  mobileNoOnChange = () => {
    this.props.mobileNoVerificationCallBack(this.mobileNoCmpRef)
  }

  render() {
    return (
      <React.Fragment>
        <div className='bookFontStyle fontMedium mt20'>{getTranslationText('store', 'serialOrIMEI')}</div>
        <EligibilityFieldComponent {...this.props} autoCheck={!this.props.isMobileNoFieldReq && this.props.autoCheckEligibility} />
        {this.props.isMobileNoFieldReq && (
          <div className='mt20'>
            <MobileNoComponent
              getMobileComponentRef={this.getMobileComponentRef}
              phnoLengthAr={this.state.phnoLengthAr}
              mobileNo={this.props.mobileNo}
              selectedCountryData={this.props.user.selectedCountryData}
              mobileNoOnChangeCallback={this.mobileNoOnChange}
            />
          </div>
        )}
      </React.Fragment>
    )
  }

  static propTypes = {
    user: PropTypes.object.isRequired,
    mobileNoVerificationCallBack: PropTypes.func.isRequired,
    isMobileNoFieldReq: PropTypes.bool,
    autoCheckEligibility: PropTypes.bool,
    mobileNo: PropTypes.string,
    showDateOfPruchase: PropTypes.bool,
    showProductPurchaseCost: PropTypes.bool,
    disableIMEI: PropTypes.bool,
    showDOB: PropTypes.bool,
    disableDOB: PropTypes.bool,
    showEligibilityFailure: PropTypes.bool,
  }
}

// checkout component
export class CheckoutStoreMainComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      steps: [
        {
          text: getTranslationText('store', 'reviewPurchase'),
          image: activeFirst,
          active: true,
        },
        {
          text: getTranslationText('store', 'payment'),
          image: deactiveMiddle,
        },
        {
          text: getTranslationText('request', 'confirmation'),
          image: deactiveLast,
        },
      ],
      renderComponent: 1,
      stateList: [],
      enableLoader: true,
      storeLoader: true,
      showMoreOffers: false,
      moreOffersText: getTranslationText('store', 'viewMoreOffers'),
    }
  }

  onClickShowMoreOffers = () => {
    this.setState({
      showMoreOffers: !this.state.showMoreOffers,
      moreOffersText: getTranslationText('store', !this.state.showMoreOffers ? 'viewLessOffers' : 'viewMoreOffers'),
    })
  }

  componentDidMount() {
    if (this.props.store.selectedPlanDetails && Object.keys(this.props.store.selectedPlanDetails).length) {
      let qry = parseInt(this.props.location.query.step)
      if (qry && qry === 1 && this.props.store.buyplanStatus) {
        window.rzp1 && window.rzp1.close()
        window.rzp1 = ''
        this.props.resetCheckoutComponent()
        const cb = () => {
          this.setState({
            enableLoader: false,
            storeLoader: false,
          })
          const selectedState = _.get(this, 'props.store.selectedState', undefined)
          if (
            this.props.isNewPlanSalesFlow &&
            !this.props.isAddressRequiredForPlanPurchase() &&
            selectedState &&
            Object.keys(selectedState).length > 0
          ) {
            this.props.validateRequiredPaymentParams()
          }
          if (!_.get(this, 'props.store.requiredPaymentParams', []).length > 0) {
            if (_.get(this, 'props.store.availablePaymentOptions', []).length > 1) {
              this.props.setShowPaymentMode(true)
            }
            let shouldShowAddress =
              _.get(this, 'props.store.selectedPlanDetails.PlanConfig.RequiredFieldsForPurchase', []).find(
                (e) => e.FieldName === 'ConsumerAddress' && e.Optional === false
              ) && !_.get(this, 'props.store.taxAddress')
            if (
              (shouldShowAddress &&
                _.get(this, 'props.store.purchaseEligibilityInfo.PlanDiscounts.PlanDiscountOffers', []).length > 0 &&
                Object.keys(_.get(this, 'props.store.consumerAddress', {})).length > 0) ||
              (this.props.isNewPlanSalesFlow && Object.keys(_.get(this, 'props.store.consumerAddress', {})).length > 0) ||
              (_.get(this, 'props.store.consumerAddress.Landmark', false) &&
                this.props.isNewPlanSalesFlow &&
                !_.get(this, 'props.user.userDetails.consumerID', false))
            ) {
              shouldShowAddress = false
            }
            this.props.setShowAddress(shouldShowAddress)
          }
          this.checkoutRenderingComponent(qry)
        }
        if (this.props.isNewPlanSalesFlow) {
          cb()
        } else {
          this.props
            .getRequiredPaymentParamsAsync({
              AuthID: _.get(this, 'props.user.consumerDetails.data.accessToken.ID', undefined),
            })
            .then(() => {
              cb()
            })
        }
      } else if (
        qry &&
        qry === 3 &&
        ((this.props.store.planPaymentDetails && Object.keys(this.props.store.planPaymentDetails).length) ||
          _.get(this, 'props.store.checkIfPaymentRequired', false))
      ) {
        // check with PaymentOnActivation key for D+1 flow (redirecting confirmation page)
        this.checkoutRenderingComponent(qry)
      } else {
        browserHistory.push({
          pathname: '/store',
          [this.props.location.query.region ? 'region' : '']: this.props.location.query.region,
        })
      }
    } else {
      browserHistory.push({
        pathname: '/store',
        [this.props.location.query.region ? 'region' : '']: this.props.location.query.region,
      })
    }
  }

  componentWillUnmount() {
    this.props.setDiscountDetails(null)
  }

  componentWillReceiveProps(nxtProps) {
    if (this.props !== nxtProps) {
      if (this.state.renderComponent) {
        if (window.rzp1 && this.state.renderComponent !== 2) {
          this.checkoutRenderingComponent(2)
        } else {
          if (parseInt(nxtProps.location.query.step) === 3 && parseInt(this.state.renderComponent) !== 3) {
            this.checkoutRenderingComponent(3)
          }
        }
      }
    }
  }

  checkoutRenderingComponent = (step) => {
    let renderComponentFlg = 1
    if (step) {
      renderComponentFlg = parseInt(step)
      if (parseInt(step) === 1 && !this.props.reviewScreenGtmEventSent) {
        this.props.setReviewScreenGtmEventSent(true)
        const { PlanName } = _.get(this, 'props.store.selectedPlanDetails')
        const { ValidTillMonths } = _.get(this, 'props.store.selectedPlanDetails.PlanConfig')
        const { BrandName } = _.get(this, 'props.store.selectedPlanDetails.EntityDetails[0]')
        const { PlanPrice } = _.get(this, 'props.store.purchaseEligibilityInfo', {})
        const { emailID, mobileNo } = _.get(this, 'props.user.userDetails', false)
        // ------------- CleverTap | Plan Sales - Purchase Reviewed --------/
        const clevertapUserProfile = _.get(this, 'props.user', {})

        pushToAnalytics(
          'Plan Sales - Purchase Reviewed',
          {
            'Email ID': emailID || undefined,
            'Mobile Number': mobileNo || undefined,
            ConsumerID: _.get(this, 'props.user.consumerDetails.data.consumerID', undefined),
            'Plan Name': PlanName,
            Brand: BrandName,
            'Policy Name': PlanName,
            'Plan Validity': ValidTillMonths,
            'Plan Price': PlanPrice ? PlanPrice.PlanPrice : undefined,
            State: _.get(this, 'state.selectedState.State'),
            Source: 'Web',
            eureka: !!_.get(this, 'props.store.imeiFromToken', false),
            ProductName: _.get(this, 'props.store.purchaseEligibilityInfo.ProductName', ''),
          },
          clevertapUserProfile
        )
        // ------------- CleverTap | Plan Sales - Purchase Reviewed --------/
      }
    }
    if (parseInt(step) === 3) {
      this.props.setBuyPlanStatusAval(false)
      this.props.setImeiForFilter('')
    }
    this.setState({
      renderComponent: renderComponentFlg,
    })
  }

  render() {
    if (this.state.renderComponent === 1 || this.state.renderComponent === 2) {
      return (
        <>
          {this.state.storeLoader && <SFYLoader />}
          <PurchaseReviewComponent
            {...this.props}
            store={this.props.store}
            navPath={this.state.renderComponent}
            storeCheckoutWidgetLoader={this.props.storeCheckoutWidgetLoader}
            selectedPlanDetails={this.props.store.selectedPlanDetails}
            stateList={this.props.store.stateList}
            handleStateSelection={this.props.handleStateSelection}
            selectedState={this.props.selectedState}
            handleCheckoutNavigation={this.props.handleCheckoutNavigation}
            enableLoader={this.state.enableLoader}
            handleAddressClick={this.props.handleAddressClick}
            handleTypeaheadSelect={this.props.handleTypeaheadSelect}
            cancel={this.props.cancel}
            onChangeTypeahead={this.props.onChangeTypeahead}
            onChangeAddressInput={this.props.onChangeAddressInput}
            onChangeStateSelection={this.props.onChangeStateSelection}
            handleDistrictSelection={this.props.handleDistrictSelection}
            toggleAddressPillSelection={this.props.toggleAddressPillSelection}
            saveAddress={this.props.saveAddress}
            toggleConfirmationPopUp={this.props.toggleConfirmationPopUp}
            sortedAddressConfig={this.props.sortedAddressConfig}
            isCreate={this.props.isCreate}
            locations={this.props.locations}
            selectedDistrict={this.props.selectedDistrict}
            pincodeLengthAr={this.props.pincodeLengthAr}
            addressComponentLoader={this.props.addressComponentLoader}
            allowSave={this.props.allowSave}
            newAddressDetails={this.props.newAddressDetails}
            landmark={this.props.landmark}
            placesAutocompleteOptions={this.props.placesAutocompleteOptions}
            addressTypesList={this.props.addressTypesList}
            pickPlanPrice={this.props.pickPlanPrice}
            pickEMIPlanPrice={this.props.pickEMIPlanPrice}
            getDiscountsByType={this.props.getDiscountsByType}
            showMoreOffers={this.state.showMoreOffers}
            moreOffersText={this.state.moreOffersText}
            onClickShowMoreOffers={this.onClickShowMoreOffers}
            isNewPlanSalesFlow={this.props.isNewPlanSalesFlow}
            setTncLink={this.props.setTncLink}
            setPrivacyLink={this.props.setPrivacyLink}
            setDataConsentLink={this.props.setDataConsentLink}
            checkPlanEligibility={this.props.checkPlanEligibility}
          />
        </>
      )
    } else if (this.state.renderComponent === 3) {
      return (
        <Confirmation
          productName={this.props.store.purchaseEligibilityInfo.ProductName}
          paymentDetails={this.props.store.planPaymentDetails}
          user={this.props.user}
          PaymentOnActivation={_.get(this, 'props.store.checkIfPaymentRequired', false)}
          storeCheckoutWidgetLoader={this.props.storeCheckoutWidgetLoader}
          selectedPlanDetails={this.props.store.selectedPlanDetails}
          isNewPlanSalesFlow={this.props.isNewPlanSalesFlow}
          userData={this.props.store.userData}
        />
      )
    } else {
      return <div />
    }
  }
}
CheckoutStoreMainComponent.propTypes = {
  location: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
  handleCheckoutNavigation: PropTypes.func.isRequired,
  getRequiredPaymentParamsAsync: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  payment: PropTypes.object,
  handleStateSelection: PropTypes.func.isRequired,
  selectedState: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  resetCheckoutComponent: PropTypes.func.isRequired,
  setBuyPlanStatusAval: PropTypes.func.isRequired,
  setToaster: PropTypes.func.isRequired,
  storeCheckoutWidgetLoader: PropTypes.bool,
  PaymentOnActivation: PropTypes.bool,
  isCreate: PropTypes.bool,
}

export const PurchaseReviewComponent = (props) => {
  let purchaseBtn = (
    <MediumButton
      buttonText={getTranslationText('common', 'continue')}
      id='checkout-state-1'
      disabled={props.enableLoader}
      btnLoader={props.eligibilityLoader}
      onClick={props.handleCheckoutNavigation}
    />
  )

  let reqParam = _.get(props, 'store.requiredPaymentParams', [])
  const { imeiOrSerial, ProductName: productName } = props.store.purchaseEligibilityInfo
  const { BrandID } = props.store.selectedBrand
  const PlanValidity = _.get(props, 'store.selectedPlanDetails.planMeta.PlanValidity')
  const locale = _.get(props, 'user.selectedCountryData.countryConfig.Locale.DEFAULT', 'en')
  const currencyCode = _.get(props, 'user.selectedCountryData.CurrencyCode')
  const discountAmount = Number(_.get(props, 'store.discountDetails.discount', 0))
  const planAmount = Number(_.get(props, 'store.discountDetails.Amount', 0))
  const planPrice = Number(_.get(props, 'store.planPriceOnProductBasis.PlanPrice', 0))
  const undiscountedPlanPrice = Number(_.get(props, 'store.undiscountedPlanPrice'))
  const orignalPrice = Number(_.get(props, 'store.planPriceOnProductBasis.OriginalPlanPrice', 0))
  const discountPrice = Number(props.pickPlanPrice(_.get(props, 'store.planPriceOnProductBasis', {})))
  const orignalEMIPrice = Number(_.get(props, 'store.planPriceOnProductBasis.planPriceBillingConfig[0].OriginalEMIAmount', 0))
  let discountEMIPrice = Number(props.pickEMIPlanPrice(_.get(props, 'store.planPriceOnProductBasis.planPriceBillingConfig[0]', {})))
  if (isNaN(discountEMIPrice)) discountEMIPrice = 0
  const selectedState = _.get(props, 'store.selectedState', {})
  /* Show discounts in purchase review
  get discounts for both upfront and monthly types to show discounted prices individually
  get all discounts also to show aviilable offers
  */
  // let discounts = _.get(props, 'store.purchaseEligibilityInfo.PlanDiscounts.PlanDiscountOffers', [])
  let discountsAll = _.get(props, 'store.purchaseEligibilityInfo.PlanDiscounts', [])
  let discountsAll_ = _.get(props, 'store.purchaseEligibilityInfo.PlanDiscounts.PlanDiscountOffers', [])
  let discountOnFullPayment = props.getDiscountsByType(discountsAll, 'UPFRONT')
  let discountOnMonthly = props.getDiscountsByType(discountsAll, 'MONTHLY')
  const showDiscountedPrice =
    discountOnFullPayment.length <= 0 &&
    discountOnMonthly.length <= 0 &&
    ((orignalPrice && discountPrice) ||
      (orignalEMIPrice && discountEMIPrice && _.get(props, 'store.purchaseEligibilityInfo.IsPurchasedAllowed', false)))
  const hasDiscountToShow = (offer) => {
    return offer.length === 1 && offer[0].requiredFieldsPresent && offer[0].DiscountCategory !== 'PromoCode'
  }
  const discountOffersToShow = discountsAll_.filter((offer) => {
    if (
      discountOnFullPayment.length === 1 &&
      discountOnFullPayment[0].PlanDiscountConfigurationID === offer.PlanDiscountConfigurationID &&
      hasDiscountToShow(discountOnFullPayment)
    )
      return false
    if (
      discountOnMonthly.length === 1 &&
      discountOnMonthly[0].PlanDiscountConfigurationID === offer.PlanDiscountConfigurationID &&
      hasDiscountToShow(discountOnMonthly)
    )
      return false
    return true
  })
  let discountOffer = []
  let discountOfferEMI = []
  let showAvailableOffers = true
  if (selectedState && selectedState.StateCode) {
    /*
    If address or state is selected, pick discounted price from maxdiscount offer object of 
    respective payment options(upfront and monthly)
    */
    showAvailableOffers = false
    const availablePaymentOptions = _.get(props, 'store.availablePaymentOptions', [])
    if (availablePaymentOptions.length === 1) {
      // discounts = _.get(props, 'store.availablePaymentOptions[0].PlanDiscounts.PlanDiscountOffers', [])

      discountOnFullPayment = _.get(
        props,
        'store.availablePaymentOptions[0].maxDiscountOffer',
        props.getDiscountsByType(_.get(props, 'store.availablePaymentOptions[0].PlanDiscounts', []), 'UPFRONT')
      )
      discountOffer = Object.keys(discountOnFullPayment).length > 0 ? discountOnFullPayment : []
      if (discountOffer.requiredFieldsPresent && discountOffer.DiscountCategory === 'PromoCode') showAvailableOffers = true
    } else {
      let fullPaymentOption =
        availablePaymentOptions.length && availablePaymentOptions.find((option) => option.PaymentModeCode === 'ONLINE_PAYMENT')
      let monthlyPaymentOption =
        availablePaymentOptions.length && availablePaymentOptions.find((option) => option.PaymentModeCode === 'EMI')
      // discounts = (fullPaymentOption && fullPaymentOption.PlanDiscounts && fullPaymentOption.PlanDiscounts.PlanDiscountOffers) || []
      discountOnFullPayment =
        fullPaymentOption &&
        fullPaymentOption.maxDiscountOffer &&
        fullPaymentOption.maxDiscountOffer.DiscountCategory !== 'PromoCode' &&
        fullPaymentOption.maxDiscountOffer.requiredFieldsPresent &&
        Object.keys(fullPaymentOption.maxDiscountOffer)
          ? fullPaymentOption.maxDiscountOffer
          : (fullPaymentOption &&
              fullPaymentOption.PlanDiscounts &&
              props.getDiscountsByType(fullPaymentOption.PlanDiscounts, 'UPFRONT')) ||
            []
      discountOffer = Object.keys(discountOnFullPayment).length > 0 ? discountOnFullPayment : []

      discountOnMonthly =
        monthlyPaymentOption &&
        monthlyPaymentOption.maxDiscountOffer &&
        monthlyPaymentOption.maxDiscountOffer.DiscountCategory !== 'PromoCode' &&
        monthlyPaymentOption.maxDiscountOffer.requiredFieldsPresent &&
        Object.keys(monthlyPaymentOption.maxDiscountOffer)
          ? monthlyPaymentOption.maxDiscountOffer
          : (monthlyPaymentOption &&
              monthlyPaymentOption.PlanDiscounts &&
              props.getDiscountsByType(monthlyPaymentOption.PlanDiscounts, 'EMI')) ||
            []
      discountOfferEMI = Object.keys(discountOnMonthly).length > 0 ? discountOnMonthly : []
    }
  } else {
    if (discountOnFullPayment.length === 1) discountOffer = discountOnFullPayment[0]
    if (discountOnMonthly.length === 1) discountOfferEMI = discountOnMonthly[0]
  }
  const appliedDiscountOffer = _.get(props, 'store.selectedPaymentOption.appliedDiscountOffer', {})
  /* Util function to pick discounted price to show
  Check if promocode is applied, if not pick from maxDiscountOffer if available
  also consider picking plan price base plan price or total price
  */
  const getDiscountedPriceByPaymentType = (paymentType) => {
    const offer = paymentType === 'UPFRONT' ? discountOffer : discountOfferEMI
    const price =
      props.store.planPriceOnProductBasis && props.store.planPriceOnProductBasis.TotalPrice > 0
        ? appliedDiscountOffer.PaymentType === paymentType
          ? appliedDiscountOffer.DiscountedTotalPlanPrice || appliedDiscountOffer.DiscountedPlanPrice || 0
          : offer.DiscountCategory !== 'PromoCode'
          ? offer.DiscountedTotalPlanPrice || offer.DiscountedPlanPrice
          : 0
        : appliedDiscountOffer.PaymentType === paymentType
        ? appliedDiscountOffer.DiscountedBasePlanPrice || appliedDiscountOffer.DiscountedPlanPrice || 0
        : offer.DiscountCategory !== 'PromoCode'
        ? offer.DiscountedBasePlanPrice || offer.DiscountedPlanPrice
        : 0
    return price
  }
  const discountedPriceToShow = getDiscountedPriceByPaymentType('UPFRONT')
  const discountedEMIPriceToShow = getDiscountedPriceByPaymentType('MONTHLY')
  // discounts
  return (
    <div className='row'>
      {/* <div className='col-sm-12'>{!props.isNewPlanSalesFlow && <RequestNavBar steps={steps} />}</div> */}
      <div className='col-sm-12 store-product-checkout-flow'>
        <Loader loaderState={props.storeCheckoutWidgetLoader}>
          <div className='row override-col-prop'>
            <div className={'col-sm-' + (reqParam?.length > 0 || props.showPaymentMode || props.showAddress ? '8' : '12')}>
              <BoxHolder className='purchase-revice-container'>
                <Heading title={getTranslationText('store', 'reviewPurchase')} />
                {props.isNewPlanSalesFlow ? (
                  <div className='row store-device-view'>
                    <div className='col-sm-5 device-view-img'>
                      <div className='selected-slider-img mb8'>
                        <img src={_.get(props, 'selectedPlanDetails.planMeta.PlanEmailImage')} />
                      </div>
                    </div>
                    <div className='col-sm-7'>
                      <div className='mediumFontStyle device-heading mb4'>
                        {_.get(props, 'selectedPlanDetails.AlternatePlanDisplayName', '').length ? (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: props.selectedPlanDetails.AlternatePlanDisplayName,
                            }}
                          />
                        ) : (
                          props.selectedPlanDetails.PlanName
                        )}
                      </div>
                      <hr className='mb20 mt20' />
                      <div
                        className='bannerDesc fontMedium mb8'
                        dangerouslySetInnerHTML={{
                          __html: _.get(props, 'selectedPlanDetails.PlanConfig.PlanDescriptionSubHeading'),
                        }}
                      />
                      {PlanValidity && (
                        <div className='bookFontStyle fontLarge mb4'>
                          <span>{getTranslationText('device', 'validity')} : </span>
                          {PlanValidity}
                        </div>
                      )}
                      {props.selectedPlanDetails.PlanConfig.IsCustomPlan ? (
                        <div className='mediumFontStyle fontMedium mb8'>
                          {parseInt(props.store.customPlanPrice) ? (
                            <React.Fragment>
                              <span>{getTranslationText('common', 'priceLabel')}&nbsp;:&nbsp;</span>
                              <span>{getLocaleCurrency(locale, currencyCode, props.store.customPlanPrice)}</span>
                            </React.Fragment>
                          ) : (
                            ''
                          )}
                        </div>
                      ) : showDiscountedPrice ? (
                        <div className='mediumFontStyle priceFont mb8'>
                          <React.Fragment>
                            <span className='fontLarge'>{getTranslationText('common', 'priceLabel')}&nbsp;:&nbsp;</span>
                            {!!orignalPrice && (
                              <span
                                className={`lightFontStyle strikethrough-text mr4 ${props.isNewPlanSalesFlow ? 'gray-text fontLarge' : ''}`}
                              >
                                {getLocaleCurrency(locale, currencyCode, orignalPrice, true)}
                              </span>
                            )}
                            <span className='mr4'>{getLocaleCurrency(locale, currencyCode, discountPrice)}</span>
                            {(!!orignalEMIPrice || !!discountEMIPrice) && (
                              <span className='fontLarge'>{' ' + getTranslationText('payment', 'oneTimePayment')} | </span>
                            )}
                            {!!orignalEMIPrice && (
                              <span
                                className={`lightFontStyle strikethrough-text mr4 ${props.isNewPlanSalesFlow ? 'gray-text fontLarge' : ''}`}
                              >
                                {getLocaleCurrency(locale, currencyCode, orignalEMIPrice, true)}
                              </span>
                            )}
                            {!!discountEMIPrice && (
                              <span>
                                <span className='mr4'>{getLocaleCurrency(locale, currencyCode, discountEMIPrice)}</span>
                                <span className='fontLarge'>/{getTranslationText('common', 'month')}</span>
                              </span>
                            )}
                          </React.Fragment>
                        </div>
                      ) : (
                        <div className='row pl10'>
                          <div className='col-auto no-right-padding no-left-padding'>
                            <span className={`${props.isNewPlanSalesFlow ? 'mediumFontStyle' : ''} fontLarge`}>
                              {getTranslationText('common', 'priceLabel')}&nbsp;:&nbsp;
                            </span>
                          </div>
                          <div
                            className={`col-sm-8 ${
                              props.isNewPlanSalesFlow ? 'priceFont' : 'fontMedium'
                            }  mediumFontStyle mb8 no-left-padding`}
                          >
                            {parseInt(_.get(props, 'store.planPriceOnProductBasis.PlanPrice')) ? (
                              <React.Fragment>
                                {props.store.selectedPlanDetails.priceRange && props.store.planPriceOnProductBasis ? (
                                  <React.Fragment>
                                    {undiscountedPlanPrice > 0 && discountAmount <= 0 && planPrice < undiscountedPlanPrice && (
                                      <span
                                        className={`${
                                          props.isNewPlanSalesFlow ? 'lightFonStyle gray-text fontLarge' : ''
                                        } strikethrough-text mr4`}
                                      >
                                        {getLocaleCurrency(locale, currencyCode, undiscountedPlanPrice)}
                                      </span>
                                    )}
                                    {discountedPriceToShow > 0 ? (
                                      <span>
                                        <span
                                          className={` ${
                                            props.isNewPlanSalesFlow ? 'priceFont mediumFontStyle' : 'fontMedium boldFontStyle'
                                          } mr4`}
                                        >
                                          {getLocaleCurrency(locale, currencyCode, discountedPriceToShow)}
                                        </span>
                                        <span>
                                          <del
                                            className={`bookFontStyle ${
                                              props.isNewPlanSalesFlow ? 'fontLarge gray-text lightFontStyle' : 'fontMedium'
                                            } mr4`}
                                          >
                                            {' '}
                                            {getLocaleCurrency(
                                              locale,
                                              currencyCode,
                                              props.pickPlanPrice(props.store.planPriceOnProductBasis)
                                            )}
                                          </del>
                                          &nbsp;
                                        </span>
                                        <span
                                          className={`boldFontStyle ${props.isNewPlanSalesFlow ? 'fontLarge' : 'fontMedium'} off-price`}
                                          dangerouslySetInnerHTML={{
                                            __html: appliedDiscountOffer.OfferDisplayText || discountOffer.OfferDisplayText,
                                          }}
                                        />
                                      </span>
                                    ) : (
                                      <span
                                        className={
                                          discountAmount > 0
                                            ? `${props.isNewPlanSalesFlow ? 'gray-text fontLarge' : ''} strikethrough-text`
                                            : ''
                                        }
                                      >
                                        {getLocaleCurrency(locale, currencyCode, props.pickPlanPrice(props.store.planPriceOnProductBasis))}
                                      </span>
                                    )}
                                    {discountAmount > 0 && (
                                      <span>
                                        {' | ' + getLocaleCurrency(locale, currencyCode, planAmount) + ' ' +
                                        (
                                          <span className={props.isNewPlanSalesFlow ? 'fontLarge mediumFontStyle' : ''}>
                                            {getTranslationText('store', 'afterDiscount')}
                                          </span>
                                        )}
                                      </span>
                                    )}
                                  </React.Fragment>
                                ) : (
                                  <>
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: props.store.selectedPlanDetails.planPrice.find((price) => price.isSelected).text,
                                      }}
                                    />
                                  </>
                                )}
                              </React.Fragment>
                            ) : (
                              ''
                            )}
                            {!!_.get(props, 'store.planPriceOnProductBasis.planPriceBillingConfig[0]', false) && (
                              <React.Fragment>
                                <span className={props.isNewPlanSalesFlow ? 'mediumFontStyle fontLarge' : 'lightFontStyle'}>
                                  {' ' + getTranslationText('payment', 'oneTimePayment')}
                                  {discountedEMIPriceToShow > 0 || discountOfferEMI.hasConcession ? (
                                    <span>
                                      <br />
                                      <span
                                        className={` ${
                                          props.isNewPlanSalesFlow ? 'priceFont mediumFontStyle' : 'fontMedium boldFontStyle'
                                        } mr4`}
                                      >
                                        {!discountOfferEMI.hasConcession
                                          ? getLocaleCurrency(locale, currencyCode, discountedEMIPriceToShow)
                                          : ''}
                                      </span>
                                      <span>
                                        <del
                                          className={`bookFontStyle ${
                                            props.isNewPlanSalesFlow ? 'fontLarge gray-text lightFontStyle' : 'fontMedium'
                                          } mr4`}
                                        >
                                          {' '}
                                          {getLocaleCurrency(
                                            locale,
                                            currencyCode,
                                            props.pickEMIPlanPrice(
                                              _.get(props, 'store.planPriceOnProductBasis.planPriceBillingConfig[0]', {})
                                            )
                                          )}
                                          {discountOfferEMI.hasConcession ? (
                                            <del className={props.isNewPlanSalesFlow ? 'fontLarge' : ''}>
                                              /{getTranslationText('common', 'month')}
                                            </del>
                                          ) : (
                                            ''
                                          )}
                                        </del>
                                      </span>
                                      {!discountOfferEMI.hasConcession ? (
                                        <span className={props.isNewPlanSalesFlow ? 'fontLarge' : ''}>
                                          /{getTranslationText('common', 'month')}
                                        </span>
                                      ) : (
                                        ''
                                      )}
                                      &nbsp;
                                      <span
                                        className={`boldFontStyle ${props.isNewPlanSalesFlow ? 'fontLarge' : 'fontMedium'} off-price`}
                                        dangerouslySetInnerHTML={{
                                          __html: appliedDiscountOffer.OfferDisplayText || discountOfferEMI.OfferDisplayText,
                                        }}
                                      />
                                      {discountOfferEMI.hasConcession ? (
                                        <div
                                          className={` ${
                                            props.isNewPlanSalesFlow ? 'priceFont mediumFontStyle' : 'fontMedium bookFontStyle'
                                          }`}
                                        >
                                          {'(' +
                                            getLocaleCurrency(
                                              locale,
                                              currencyCode,
                                              props.pickEMIPlanPrice(
                                                _.get(props, 'store.planPriceOnProductBasis.planPriceBillingConfig[0]', {})
                                              )
                                            )}
                                          <span className={props.isNewPlanSalesFlow ? 'fontLarge' : ''}>
                                            {'/' + getTranslationText('store', 'afterOfferPeriodLabel') + ')'}
                                          </span>
                                        </div>
                                      ) : (
                                        ''
                                      )}
                                    </span>
                                  ) : (
                                    <span>
                                      <br />
                                      <span className={`${props.isNewPlanSalesFlow ? 'priceFont' : ''} mediumFontStyle`}>
                                        {getLocaleCurrency(
                                          locale,
                                          currencyCode,
                                          props.pickEMIPlanPrice(
                                            _.get(props, 'store.planPriceOnProductBasis.planPriceBillingConfig[0]', {})
                                          )
                                        )}
                                      </span>
                                      <span className={props.isNewPlanSalesFlow ? 'fontLarge' : ''}>
                                        /{getTranslationText('common', 'month')}
                                      </span>
                                    </span>
                                  )}
                                </span>
                              </React.Fragment>
                            )}
                          </div>
                        </div>
                      )}
                      {productName && (
                        <div className='bookFontStyle fontLarge mb4'>
                          <span>{getTranslationText('common', 'productName')} : </span>
                          {productName}
                        </div>
                      )}
                      {imeiOrSerial && (
                        <div className='bookFontStyle fontLarge mb4'>
                          <span>
                            {`${getTranslationText('device', 'imei')}
                          ${BrandID === 4 ? '/ ' + getTranslationText('common', 'serialNumber') : ''}`}
                            &nbsp;:&nbsp;
                          </span>
                          {imeiOrSerial}
                        </div>
                      )}
                      {/* Show available offers */}
                      {showAvailableOffers &&
                      !(
                        appliedDiscountOffer.DiscountedTotalPlanPrice ||
                        appliedDiscountOffer.DiscountedBasePlanPrice ||
                        discountedPriceToShow > 0 ||
                        discountedEMIPriceToShow > 0
                      ) &&
                      discountOffersToShow.length > 0 &&
                      !props.showMoreOffers ? (
                        <div className='discount-offers-text-continer mt8'>
                          <div className='discount-offers-title'>{getTranslationText('store', 'availableOffers')}</div>
                          <div className='discount-offers-text mt8'>
                            <img className='ml8' src={discountImg} />
                            <div className='bookFontStyle fontMedium overrideCasing ml4'>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: discountOffersToShow[0].OfferDisplayText + ' ' + discountOffersToShow[0].CheckOutPageDisplayText,
                                }}
                              />
                            </div>
                          </div>
                          {discountOffersToShow.length > 1 ? (
                            <div>
                              <div className='view-more-less-container mt4' onClick={props.onClickShowMoreOffers}>
                                <div className='view-more-less bookFontStyle fontSmall overrideCasing overrideCasing ml8'>
                                  {props.moreOffersText}
                                </div>
                                <img className='view-more-less-img ml3' src={props.showMoreOffers ? up : down} />
                              </div>
                              <div className='more-offers-note lightFontStyle fontSmall overrideCasing mt4 mb4 ml8'>
                                {getTranslationText('store', 'discountNote')}
                              </div>
                            </div>
                          ) : (
                            <div className='mt8'></div>
                          )}
                        </div>
                      ) : (
                        ''
                      )}
                      {showAvailableOffers &&
                      !(
                        appliedDiscountOffer.DiscountedTotalPlanPrice ||
                        appliedDiscountOffer.DiscountedBasePlanPrice ||
                        discountedPriceToShow > 0 ||
                        discountedEMIPriceToShow > 0
                      ) &&
                      discountOffersToShow.length > 0 &&
                      props.showMoreOffers ? (
                        <div className='discount-offers-text-continer mt8'>
                          <div className='discount-offers-title mb8'>{getTranslationText('store', 'availableOffers')}</div>
                          {discountOffersToShow.map((offer) => {
                            return (
                              <div key={offer.PlanDiscountConfigurationID} className='discount-offers-text mb8'>
                                <img className='ml8' src={discountImg} />
                                <div className='bookFontStyle fontMedium overrideCasing ml4'>
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: offer.OfferDisplayText + ' ' + offer.CheckOutPageDisplayText,
                                    }}
                                  />
                                </div>
                              </div>
                            )
                          })}
                          <div className='view-more-less-container mt4' onClick={props.onClickShowMoreOffers}>
                            <div className='view-more-less bookFontStyle fontSmall overrideCasing ml8'>{props.moreOffersText}</div>
                            <img className='view-more-less-img ml3' src={props.showMoreOffers ? up : down} />
                          </div>
                          <div className='more-offers-note lightFontStyle fontSmall overrideCasing mt4 mb4 ml8'>
                            {getTranslationText('store', 'discountNote')}
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                      {/* Show available offers ends*/}
                      {!(reqParam?.length > 0 || props.showPaymentMode || props.showAddress) && purchaseBtn}
                    </div>
                  </div>
                ) : (
                  <div className='row mt30'>
                    <div className='col-sm-4 mb20 selected-plan-img'>
                      <img src={_.get(props, 'selectedPlanDetails.planMeta.PlanEmailImage')} />
                    </div>
                    <div className='col-sm-8'>
                      <div className='mediumFontStyle mb4'>
                        <span>{getTranslationText('store', 'policyName')} : </span>
                        {_.get(props, 'selectedPlanDetails.AlternatePlanDisplayName', '').length ? (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: props.selectedPlanDetails.AlternatePlanDisplayName,
                            }}
                          />
                        ) : (
                          props.selectedPlanDetails.PlanName
                        )}
                      </div>
                      <div
                        className='bannerDesc fontMedium mb8'
                        dangerouslySetInnerHTML={{
                          __html: _.get(props, 'selectedPlanDetails.PlanConfig.PlanDescriptionSubHeading'),
                        }}
                      />
                      {PlanValidity && (
                        <div className='mediumFontStyle mb4'>
                          <span>{getTranslationText('device', 'validity')} : </span>
                          {PlanValidity}
                        </div>
                      )}
                      {props.selectedPlanDetails.PlanConfig.IsCustomPlan ? (
                        <div className='mediumFontStyle fontMedium mb8'>
                          {parseInt(props.store.customPlanPrice) ? (
                            <React.Fragment>
                              <span className='mediumFontStyle fontMedium'>{getTranslationText('common', 'priceLabel')} :&nbsp;</span>
                              <span>{getLocaleCurrency(locale, currencyCode, props.store.customPlanPrice)}</span>
                            </React.Fragment>
                          ) : (
                            ''
                          )}
                        </div>
                      ) : showDiscountedPrice ? (
                        <div className='mediumFontStyle fontMedium mb8'>
                          <React.Fragment>
                            <span className='mediumFontStyle fontMedium'>{getTranslationText('common', 'priceLabel')} :&nbsp;</span>
                            {!!orignalPrice && (
                              <span
                                className={`${props.isNewPlanSalesFlow ? 'gray-text fontLarge' : ''} lightFontStyle strikethrough-text mr4`}
                              >
                                {getLocaleCurrency(locale, currencyCode, orignalPrice, true)}
                              </span>
                            )}
                            <span className='mr4'>{getLocaleCurrency(locale, currencyCode, discountPrice)}</span>
                            {(!!orignalEMIPrice || !!discountEMIPrice) && (
                              <span className='lightFontStyle'>{' ' + getTranslationText('payment', 'oneTimePayment')} | </span>
                            )}
                            {!!orignalEMIPrice && (
                              <span
                                className={`lightFontStyle strikethrough-text mr4 ${props.isNewPlanSalesFlow ? 'gray-text fontLarge' : ''}`}
                              >
                                {getLocaleCurrency(locale, currencyCode, orignalEMIPrice, true)}
                              </span>
                            )}
                            {!!discountEMIPrice && (
                              <span>
                                <span className='mr4'>{getLocaleCurrency(locale, currencyCode, discountEMIPrice)}</span>
                                <span className={`${props.isNewPlanSalesFlow ? 'mediumFontStyle fontLarge' : 'lightFontStyle'}`}>
                                  /{getTranslationText('common', 'month')}
                                </span>
                              </span>
                            )}
                          </React.Fragment>
                        </div>
                      ) : (
                        <div className='row'>
                          <div className='ml10 no-right-padding mediumFontStyle'>
                            <span>{getTranslationText('common', 'priceLabel')}&nbsp;:&nbsp;</span>
                          </div>
                          <div className='col-sm-8 mediumFontStyle fontMedium mb8 no-left-padding'>
                            {parseInt(_.get(props, 'store.planPriceOnProductBasis.PlanPrice')) ? (
                              <React.Fragment>
                                {props.store.selectedPlanDetails.priceRange && props.store.planPriceOnProductBasis ? (
                                  <React.Fragment>
                                    {undiscountedPlanPrice > 0 && discountAmount <= 0 && planPrice < undiscountedPlanPrice && (
                                      <span className={`${props.isNewPlanSalesFlow ? 'gray-text fontLarge' : ''} strikethrough-text mr4`}>
                                        {getLocaleCurrency(locale, currencyCode, undiscountedPlanPrice)}
                                      </span>
                                    )}
                                    {discountedPriceToShow > 0 ? (
                                      <span>
                                        <span className='boldFontStyle fontMedium mr4'>
                                          {getLocaleCurrency(locale, currencyCode, discountedPriceToShow)}
                                        </span>
                                        <span>
                                          <del
                                            className={`bookFontStyle ${
                                              props.isNewPlanSalesFlow ? 'fontLarge gray-text lightFontStyle' : 'fontMedium'
                                            } mr4`}
                                          >
                                            {' '}
                                            {getLocaleCurrency(
                                              locale,
                                              currencyCode,
                                              props.pickPlanPrice(props.store.planPriceOnProductBasis)
                                            )}
                                          </del>
                                          &nbsp;
                                        </span>
                                        <span
                                          className={`boldFontStyle ${props.isNewPlanSalesFlow ? 'fontLarge' : 'fontMedium'} off-price`}
                                          dangerouslySetInnerHTML={{
                                            __html: appliedDiscountOffer.OfferDisplayText || discountOffer.OfferDisplayText,
                                          }}
                                        />
                                      </span>
                                    ) : (
                                      <span
                                        className={
                                          discountAmount > 0
                                            ? `${props.isNewPlanSalesFlow ? 'gray-text fontLarge' : ''} strikethrough-text`
                                            : ''
                                        }
                                      >
                                        {getLocaleCurrency(locale, currencyCode, props.pickPlanPrice(props.store.planPriceOnProductBasis))}
                                      </span>
                                    )}
                                    {discountAmount > 0 && (
                                      <span>
                                        {' | ' +
                                          getLocaleCurrency(locale, currencyCode, planAmount) +
                                          ' ' +
                                          getTranslationText('store', 'afterDiscount')}
                                      </span>
                                    )}
                                  </React.Fragment>
                                ) : (
                                  <>
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: props.store.selectedPlanDetails.planPrice.find((price) => price.isSelected).text,
                                      }}
                                    />
                                  </>
                                )}
                              </React.Fragment>
                            ) : (
                              ''
                            )}
                            {!!_.get(props, 'store.planPriceOnProductBasis.planPriceBillingConfig[0]', false) && (
                              <React.Fragment>
                                <span className='lightFontStyle'>
                                  {' ' + getTranslationText('payment', 'oneTimePayment')}
                                  {discountedEMIPriceToShow > 0 || discountOfferEMI.hasConcession ? (
                                    <span>
                                      <br />
                                      <span className='boldFontStyle fontMedium mr4'>
                                        {!discountOfferEMI.hasConcession
                                          ? getLocaleCurrency(locale, currencyCode, discountedEMIPriceToShow)
                                          : ''}
                                      </span>
                                      <span>
                                        <del
                                          className={`bookFontStyle ${
                                            props.isNewPlanSalesFlow ? 'fontLarge gray-text lightFontStyle' : 'fontMedium'
                                          } mr4`}
                                        >
                                          {' '}
                                          {getLocaleCurrency(
                                            locale,
                                            currencyCode,
                                            props.pickEMIPlanPrice(
                                              _.get(props, 'store.planPriceOnProductBasis.planPriceBillingConfig[0]', {})
                                            )
                                          )}
                                          {discountOfferEMI.hasConcession ? (
                                            <del className={props.isNewPlanSalesFlow ? 'fontLarge gray-text lightFontStyle' : ''}>
                                              /{getTranslationText('common', 'month')}
                                            </del>
                                          ) : (
                                            ''
                                          )}
                                        </del>
                                      </span>
                                      {!discountOfferEMI.hasConcession ? (
                                        <span className={props.isNewPlanSalesFlow ? 'fontLarge mediumFontStyle' : ''}>
                                          /{getTranslationText('common', 'month')}
                                        </span>
                                      ) : (
                                        ''
                                      )}
                                      &nbsp;
                                      <span
                                        className={`boldFontStyle ${props.isNewPlanSalesFlow ? 'fontLarge' : 'fontMedium'} off-price`}
                                        dangerouslySetInnerHTML={{
                                          __html: appliedDiscountOffer.OfferDisplayText || discountOfferEMI.OfferDisplayText,
                                        }}
                                      />
                                      {discountOfferEMI.hasConcession ? (
                                        <div
                                          className={` ${
                                            props.isNewPlanSalesFlow ? 'priceFont mediumFontStyle' : 'fontMedium bookFontStyle'
                                          }`}
                                        >
                                          &nbsp;
                                          {'(' +
                                            getLocaleCurrency(
                                              locale,
                                              currencyCode,
                                              props.pickEMIPlanPrice(
                                                _.get(props, 'store.planPriceOnProductBasis.planPriceBillingConfig[0]', {})
                                              )
                                            )}
                                          <span className={props.isNewPlanSalesFlow ? 'fontLarge' : ''}>
                                            {'/' + getTranslationText('store', 'afterOfferPeriodLabel') + ')'}
                                          </span>
                                        </div>
                                      ) : (
                                        ''
                                      )}
                                    </span>
                                  ) : (
                                    <span>
                                      <br />
                                      <span className='mediumFontStyle'>
                                        {getLocaleCurrency(
                                          locale,
                                          currencyCode,
                                          props.pickEMIPlanPrice(
                                            _.get(props, 'store.planPriceOnProductBasis.planPriceBillingConfig[0]', {})
                                          )
                                        )}
                                      </span>
                                      <span className={props.isNewPlanSalesFlow ? 'fontLarge' : ''}>
                                        /{getTranslationText('common', 'month')}
                                      </span>
                                    </span>
                                  )}
                                </span>
                              </React.Fragment>
                            )}
                          </div>
                        </div>
                      )}
                      {productName && (
                        <div className='mediumFontStyle mb4'>
                          <span>{getTranslationText('common', 'productName')} : </span>
                          {productName}
                        </div>
                      )}
                      {imeiOrSerial && (
                        <div className='mediumFontStyle mb4'>
                          <span>
                            {`${getTranslationText('device', 'imei')}
                          ${BrandID === 4 ? '/ ' + getTranslationText('common', 'serialNumber') : ''}`}
                            &nbsp;:&nbsp;
                          </span>
                          {imeiOrSerial}
                        </div>
                      )}
                      {/* Show available offers */}
                      {showAvailableOffers &&
                      !(
                        appliedDiscountOffer.DiscountedTotalPlanPrice ||
                        appliedDiscountOffer.DiscountedBasePlanPrice ||
                        discountedPriceToShow > 0 ||
                        discountedEMIPriceToShow > 0
                      ) &&
                      discountOffersToShow.length > 0 &&
                      !props.showMoreOffers ? (
                        <div className='discount-offers-text-continer mt8'>
                          <div className='discount-offers-title'>{getTranslationText('store', 'availableOffers')}</div>
                          <div className='discount-offers-text mt8'>
                            <img className='ml8' src={discountImg} />
                            <div className='bookFontStyle fontMedium overrideCasing ml4'>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: discountOffersToShow[0].OfferDisplayText + ' ' + discountOffersToShow[0].CheckOutPageDisplayText,
                                }}
                              />
                            </div>
                          </div>
                          {discountOffersToShow.length > 1 ? (
                            <div>
                              <div className='view-more-less-container mt4' onClick={props.onClickShowMoreOffers}>
                                <div className='view-more-less bookFontStyle fontSmall overrideCasing overrideCasing ml8'>
                                  {props.moreOffersText}
                                </div>
                                <img className='view-more-less-img ml3' src={props.showMoreOffers ? up : down} />
                              </div>
                              <div className='more-offers-note lightFontStyle fontSmall overrideCasing mt4 mb4 ml8'>
                                {getTranslationText('store', 'discountNote')}
                              </div>
                            </div>
                          ) : (
                            <div className='mt8'></div>
                          )}
                        </div>
                      ) : (
                        ''
                      )}
                      {showAvailableOffers &&
                      !(
                        appliedDiscountOffer.DiscountedTotalPlanPrice ||
                        appliedDiscountOffer.DiscountedBasePlanPrice ||
                        discountedPriceToShow > 0 ||
                        discountedEMIPriceToShow > 0
                      ) &&
                      discountOffersToShow.length > 0 &&
                      props.showMoreOffers ? (
                        <div className='discount-offers-text-continer mt8'>
                          <div className='discount-offers-title mb8'>{getTranslationText('store', 'availableOffers')}</div>
                          {discountOffersToShow.map((offer) => {
                            return (
                              <div key={offer.PlanDiscountConfigurationID} className='discount-offers-text mb8'>
                                <img className='ml8' src={discountImg} />
                                <div className='bookFontStyle fontMedium overrideCasing ml4'>
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: offer.OfferDisplayText + ' ' + offer.CheckOutPageDisplayText,
                                    }}
                                  />
                                </div>
                              </div>
                            )
                          })}
                          <div className='view-more-less-container mt4' onClick={props.onClickShowMoreOffers}>
                            <div className='view-more-less bookFontStyle fontSmall overrideCasing ml8'>{props.moreOffersText}</div>
                            <img className='view-more-less-img ml3' src={props.showMoreOffers ? up : down} />
                          </div>
                          <div className='more-offers-note lightFontStyle fontSmall overrideCasing mt4 mb4 ml8'>
                            {getTranslationText('store', 'discountNote')}
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                      {/* Show available offers ends*/}
                      {!(reqParam?.length > 0 || props.showPaymentMode || props.showAddress) && purchaseBtn}
                    </div>
                  </div>
                )}
              </BoxHolder>
            </div>
            {props.showAddress ? (
              <AddressesComponent
                {...props}
                isEdit={false}
                isCreate={props.isNewPlanSalesFlow && !_.get(props, 'user.userDetails.consumerID', false) ? true : props.isCreate}
                locations={props.locations}
                handleAddressClick={props.handleAddressClick}
                handleTypeaheadSelect={props.handleTypeaheadSelect}
                cancel={props.cancel}
                onChangeTypeahead={props.onChangeTypeahead}
                onChangeAddressInput={props.onChangeAddressInput}
                onChangeStateSelection={props.onChangeStateSelection}
                handleDistrictSelection={props.handleDistrictSelection}
                selectedDistrict={props.selectedDistrict}
                selectedState={props.selectedState}
                placesAutocomplete={{
                  options: {
                    ...props.placesAutocompleteOptions,
                    componentRestrictions: {
                      country: _.get(this, 'props.user.selectedCountryData.CountryCode', '').toLowerCase(),
                    },
                  },
                  landmark: props.landmark,
                }}
                addressTypesList={props.addressTypesList}
                pincodeLengthAr={props.pincodeLengthAr}
                loaderState={props.addressComponentLoader}
                toggleAddressPillSelection={props.toggleAddressPillSelection}
                newAddressDetails={props.newAddressDetails}
                allowSave={props.allowSave}
                saveAddress={props.saveAddress}
                user={props.user}
                sortedAddressConfig={props.sortedAddressConfig}
                setTncLink={props.setTncLink}
                setPrivacyLink={props.setPrivacyLink}
                setDataConsentLink={props.setDataConsentLink}
                checkPlanEligibility={props.checkPlanEligibility}
              />
            ) : props.showPaymentMode ? (
              <PaymentOptionSelection {...props} />
            ) : props.showDiscountDetails ? (
              <DiscountDetails {...props} />
            ) : (
              <ReviewAdditionalInfoComponent {...props} isAdditionalInfoReq={reqParam} />
            )}
          </div>
        </Loader>
      </div>
    </div>
  )
}
PurchaseReviewComponent.propTypes = {
  store: PropTypes.object,
  selectedPlanDetails: PropTypes.object,
  handleCheckoutNavigation: PropTypes.func.isRequired,
  handleStateSelection: PropTypes.func.isRequired,
  selectedState: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  navPath: PropTypes.number,
  enableLoader: PropTypes.bool,
  Zipcode: PropTypes.string,
  handleZipcode: PropTypes.func,
  eligibilityLoader: PropTypes.bool,
  storeCheckoutWidgetLoader: PropTypes.bool,
  isCreate: PropTypes.bool,
}

export class ReviewAdditionalInfoComponent extends React.Component {
  constructor(props) {
    super(props)
    // this.userDetailsCmpRef = {}
    this.state = {
      btnStatus: props.enableLoader,
    }
  }

  // getUserDetailsRef = (ref) => (this.userDetailsCmpRef = ref)

  componentDidMount = () => {
    let availablePaymentOptions = _.get(this, 'props.store.availablePaymentOptions', [])
    if (availablePaymentOptions && availablePaymentOptions.length > 1) {
      this.props.setSelectedPaymentOption(null)
    } else {
      let selectedPaymentOption = availablePaymentOptions && availablePaymentOptions[0]
      this.props.setSelectedPaymentOption({
        ...selectedPaymentOption,
        appliedDiscountOffer: {},
      })
      // this.props.handleCheckoutNavigation()
    }
  }

  handleOnEditClick = (ConsumerFavouriteLocationID) => {
    const address = _.get(this, 'props.user.locations').find(
      (address) => address.ConsumerFavouriteLocationID === ConsumerFavouriteLocationID
    )
    this.props.setRequiredDetails({
      landmark: address.Landmark,
      userlandmark: address.UserLandmark,
      address: address.Address,
      state: address.State,
      city: address.City,
      zipCode: address.PinCode,
      ConsumerFavouriteLocationID: ConsumerFavouriteLocationID,
      addressType: address.AddressType,
      lat: address.Lat,
      lng: address.Lng,
    })
    this.props.setIsAddAddress(false)
    this.props.setIsEditAddress(true)
    this.props.setTaxErrors({ state: '', cta: '' })
    this.props.setTaxAddress(null)
  }

  handleTaxAddressSelection = (e) => {
    this.props.setTaxAddressId(parseInt(e.currentTarget.id))
    this.props.setTaxErrors({ state: '' })
  }

  validateAddress = async (address) => {
    this.props.setglobalLoaderStatus(true)
    try {
      await this.props.validateAddressAsync(address)
      this.props.setglobalLoaderStatus(false)
    } catch (err) {
      console.log(err)
      this.props.setglobalLoaderStatus(false)
      throw Error
    }
  }

  handleTaxAddressConfirmation = async () => {
    this.props.setIsAddAddress(false)
    this.props.setIsEditAddress(false)
    try {
      const selectedAddress = _.get(this, 'props.user.locations').find(
        (address) => address.ConsumerFavouriteLocationID === _.get(this, 'props.taxAddressId')
      )
      const eligibleStateIDs = _.get(this, 'props.store.brandPlansList[0].PlanList[0].PlanConfig.EligibleStateIDs', [])
      const selectedState = _.get(this, 'props.user.stateAddressListConfig').find((state) => selectedAddress.State === state.State)
      if (!selectedState) {
        this.props.setTaxErrors({ state: getTranslationText('error', 'stateInvalid') })
        return
      }
      if (eligibleStateIDs.length > 0 && !eligibleStateIDs?.includes(selectedState.StateID)) {
        this.props.setTaxErrors({ state: getTranslationText('error', 'unavailableAtThisLocation') })
        return
      }
      const address = {
        AddressLine1: selectedAddress.UserLandmark,
        AddressLine2: selectedAddress.Address,
        Zipcode: selectedAddress.PinCode,
        State: selectedAddress.State,
        City: selectedAddress.City,
      }

      await this.validateAddress(address)
      this.props.setTaxAddress(selectedAddress)
    } catch (err) {
      this.props.setTaxErrors({ state: getTranslationText('error', 'incorrectAddress') })
      console.log(err)
    }
  }

  handleOnSavedAddressesClick = () => {
    this.props.setIsAddAddress(false)
    this.props.setIsEditAddress(false)
    this.props.setTaxAddressId(null)
    this.props.setTaxAddress(null)
  }

  handleOnAddAddressClick = () => {
    this.props.setRequiredDetails({
      zipCode: '',
      landmark: '',
      userlandmark: '',
      city: '',
      state: '',
      lat: '',
      lng: '',
      ConsumerFavouriteLocationID: '',
      address: '',
      addressType: '',
    })
    this.props.setIsAddAddress(true)
    this.props.setIsEditAddress(false)
    this.props.setTaxErrors({ state: '', cta: '' })
  }

  handleOnTaxAddressChange = (addrObject) => {
    let errors = { state: '' }
    if (addrObject.zipCode) {
      const pincode = _.get(this, 'props.user.countryAddressConfig.addressConfig', []).find(
        (config) => !!config && (config?.keyName === 'PinCode' || config?.keyName === 'Zipcode')
      )
      if (!regexCheck('onChange', pincode?.regexFunc?.toLowerCase(), addrObject.zipCode, Math.max(...pincode.length))) {
        delete addrObject['zipCode']
      }
    }
    if (addrObject.state) {
      const eligibleStateIDs = _.get(this, 'props.store.brandPlansList[0].PlanList[0].PlanConfig.EligibleStateIDs', [])
      const selectedState = _.get(this, 'props.user.stateAddressListConfig').find((state) => addrObject.state === state.State)
      if (eligibleStateIDs.length > 0 && !eligibleStateIDs.includes(selectedState.StateID)) {
        errors.state = getTranslationText('error', 'unavailableAtThisLocation')
      }
    }
    if (this?.props?.taxErrors?.state) {
      addrObject = {
        ...addrObject,
        ...{ zipCode: '', userlandmark: '', address: '', city: '', state: '' },
      }
    }
    this.props.setTaxErrors(errors)
    this.props.setRequiredDetails({
      ...addrObject,
    })
  }

  handleSaveAddress = async () => {
    const eligibleStateIDs = _.get(this, 'props.store.brandPlansList[0].PlanList[0].PlanConfig.EligibleStateIDs', [])
    const selectedState = _.get(this, 'props.user.stateAddressListConfig', []).find(
      (state) => _.get(this, 'props.requiredDetails.state') === state.State
    )
    if (eligibleStateIDs.length > 0 && !eligibleStateIDs.includes(selectedState.StateID)) {
      this.props.setTaxErrors({ state: getTranslationText('error', 'unavailableAtThisLocation') })
      return
    }
    const address = {
      Zipcode: _.get(this, 'props.requiredDetails.zipCode'),
      State: _.get(this, 'props.requiredDetails.state'),
      City: _.get(this, 'props.requiredDetails.city'),
      UserLandmark: _.get(this, 'props.requiredDetails.userlandmark'),
      Address: _.get(this, 'props.requiredDetails.address'),
      Landmark: _.get(this, 'props.requiredDetails.landmark'),
    }
    this.props.setglobalLoaderStatus(true)
    try {
      await this.validateAddress(address)
      let reqBody = {
        ...address,
        Lat: _.get(this, 'props.requiredDetails.lat'),
        Lng: _.get(this, 'props.requiredDetails.lng'),
        AddressType: _.get(this, 'props.requiredDetails.addressType') || 'Other',
        Country: _.get(this, 'props.user.selectedCountryData.CountryCode'),
        StateID: _.get(this, 'props.user.stateAddressListConfig', []).find((state) => state.State === this.props.requiredDetails.state)
          .StateID,
        ConsumerID: _.get(this, 'props.user.userDetails.consumerID'),
      }
      if (this?.props?.isEditAddress) {
        reqBody.ConsumerFavouriteLocationID = _.get(this, 'props.requiredDetails.ConsumerFavouriteLocationID')
        const updatedAddress = await this.props.updateAddressAsync({ data: reqBody })
        this.props.setTaxAddress(updatedAddress.data)
      } else {
        const addedAddress = await this.props.addAddressAsync({ data: reqBody })
        this.props.setTaxAddress(addedAddress.data)
      }
      await this.props.getUserAddressesAsync({
        ConsumerID: _.get(this, 'props.user.userDetails.consumerID'),
        AuthID: _.get(this, 'props.user.consumerDetails.data.accessToken.ID'),
      })
      this.props.setglobalLoaderStatus(false)
    } catch (err) {
      console.log(err)
      this.props.setTaxErrors({ cta: getTranslationText('error', 'incorrectAddress') })
      this.props.setglobalLoaderStatus(false)
    }
  }

  handleSubmitPayment = () => {
    this.props.setglobalLoaderStatus(true)
    const selectedAddress = this.props.store?.taxAddress
    selectedAddress &&
      pushToAnalytics('Plan Sales - Save Address', {
        Address: selectedAddress.Address || '',
        UserLandmark: selectedAddress.UserLandmark || '',
        Landmark: selectedAddress.Landmark || this.state.landmark,
        City: selectedAddress.City,
        State: selectedAddress.State,
        ZipCode: selectedAddress.ZipCode,
        PinCode: selectedAddress.PinCode,
        AddressType: selectedAddress.AddressType,
      })
    const EntityDetails = _.get(this, 'props.store.selectedPlanDetails.EntityDetails[0]', false)
    let ConsumerProductID = _.get(this, 'props.store.planConsumerProduct.ConsumerProductID', undefined)
    let requestObject = {
      PlanID: EntityDetails.PlanID || undefined,
      BrandID: EntityDetails.BrandID || _.get(this, 'props.store.purchaseEligibilityInfo.PlanDiscounts.ProductObj.BrandID', undefined),
      ConsumerProductID: ConsumerProductID,
      PlanPriceID: _.get(this, 'props.store.planPriceOnProductBasis.PlanPriceID'),
      ProductID: _.get(this, 'props.store.purchaseEligibilityInfo.PlanDiscounts.ProductObj.ProductID', undefined),
      ProductSubCategoryID: _.get(this, 'props.store.purchaseEligibilityInfo.PlanDiscounts.ProductObj.ProductSubCategoryID', undefined),
      EmailID: _.get(this, 'props.user.userDetails.emailID', undefined),
      ConsumerID: _.get(this, 'props.user.consumerDetails.data.consumerID', undefined),
    }
    this.props
      .checkIfPaymentRequiredAsync(requestObject)
      .then(() => {
        this.props.setglobalLoaderStatus(false)
        this.props.validateRequiredPaymentParams()
      })
      .catch((err) => {
        console.log(err)
        this.props.setglobalLoaderStatus(false)
      })
  }

  render() {
    const requiredParams = this.props.isAdditionalInfoReq
    const addressConfig = _.get(this, 'props.user.countryAddressConfig.addressConfig', fallbackAddressConfig)
    const AdditionalInfoType = _.get(this, 'props.store.requiredPaymentParams[0].RequiredParameter', '')
    const disableButton =
      AdditionalInfoType !== 'Zipcode'
        ? !(this.props.selectedState && Object.keys(this.props.selectedState).length)
        : !(this.props.Zipcode.length >= Math.min(...this.props.store.requiredPaymentParams[0].allowedLength))
    let currentProgressBarIndex = 0
    const progressBarItems = ['', '', '']
    const purchaseBtn = (
      <MediumButton
        className='fullWidth'
        buttonText={getTranslationText('common', 'continue')}
        id='checkout-state-1'
        disabled={this.props.enableLoader || disableButton}
        btnLoader={this.props.eligibilityLoader}
        onClick={() => {
          if (AdditionalInfoType !== 'Zipcode') {
            pushToAnalytics('Plan Sales - Address Confirmed', {
              State: this.props.selectedState,
              ConsumerID: this.props.user.consumerDetails.data.consumerID || undefined,
              Plan_Name: _.get(this, 'props.store.selectedPlanDetails.PlanName', undefined),
              IMEI_checked_on_storepage: !!_.get(this, 'props.store.imeiForFilter', false),
              eureka: !!_.get(this, 'props.store.imeiFromToken', false),
              'Logged In': _.get(this, 'props.user.consumerDetails.data.consumerID', false) ? 'Yes' : 'No',
              ProductName: _.get(this, 'props.store.purchaseEligibilityInfo.ProductName', ''),
            })
          }
          this.props.validateRequiredPaymentParams()
        }}
      />
    )

    if (requiredParams && requiredParams.length > 1) {
      return (
        <div className='col-sm-4'>
          <BoxHolder>
            {!_.get(this, 'props.store.taxAddress') ? (
              _.get(this, 'props.user.locations.length', 0) > 0 && !this?.props?.isEditAddress && !this?.props?.isAddAddress ? (
                <>
                  <h6 className='pb8 boldFontStyle'>{getTranslationText('store', 'selectAddress')}</h6>
                  <div>
                    <Button
                      type='button'
                      text={getTranslationText('profile', 'addNewAddress') + ' >'}
                      classes='button-link boldFontStyle mb8 subscription-link-button'
                      handleOnClick={this.handleOnAddAddressClick}
                    />
                    <div className='store-location-container'>
                      {_.get(this, 'props.user.locations').map((address) => (
                        <React.Fragment key={address.ConsumerFavouriteLocationID}>
                          <RadioBox
                            isSelected={address.ConsumerFavouriteLocationID === _.get(this, 'props.taxAddressId')}
                            handleOnClick={this.handleTaxAddressSelection}
                            key={address.ConsumerFavouriteLocationID}
                            id={address.ConsumerFavouriteLocationID}
                          >
                            <h6 className='boldFontStyle'>{address.AddressType}</h6>
                            <div>{getFormattedAddress(address, addressConfig)}</div>
                            <Button
                              type='button'
                              text={getTranslationText('common', 'edit')}
                              classes='button-link boldFontStyle mt8 subscription-link-button'
                              handleOnClick={() => {
                                this.handleOnEditClick(address.ConsumerFavouriteLocationID)
                              }}
                            />
                          </RadioBox>
                          {_.get(this, 'props.taxErrors.state', false) &&
                            address.ConsumerFavouriteLocationID === _.get(this, 'props.taxAddressId') && (
                              <div className='error-message font-small mb20'>{_.get(this, 'props.taxErrors.state')}</div>
                            )}
                        </React.Fragment>
                      ))}
                      <Button
                        type='button'
                        isDisabled={!_.get(this, 'props.taxAddressId')}
                        text={getTranslationText('common', 'continue')}
                        classes='button-ghost'
                        onClick={this.handleTaxAddressConfirmation}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <h6 className='pb8 boldFontStyle'>{getTranslationText('store', 'enterAddress')}</h6>
                  <TaxAddressForm
                    handleOnTaxAddressChange={this.handleOnTaxAddressChange}
                    errors={_.get(this, 'props.taxErrors', {})}
                    requiredDetails={_.get(this, 'props.requiredDetails', {})}
                    user={_.get(this, 'props.user')}
                    addressConfig={addressConfig}
                    handleSaveAddress={this.handleSaveAddress}
                    isEditAddress={this?.props?.isEditAddress}
                    handleOnSavedAddressesClick={this.handleOnSavedAddressesClick}
                    userAddresses={_.get(this, 'props.user.locations')}
                  />
                </>
              )
            ) : (
              _.get(this, 'props.store.taxAddress', false) && (
                <div className='store-location-container'>
                  <h6 className='pb8 boldFontStyle'>{getTranslationText('store', 'proceedToPurchasePlan')}</h6>
                  {_.get(this, 'props.user.locations.length', 0) > 0 && (
                    <Button
                      type='button'
                      text={getTranslationText('store', 'savedAddresses') + ' >'}
                      classes='button-link boldFontStyle mt8 mb8 subscription-link-button'
                      handleOnClick={this.handleOnSavedAddressesClick}
                    />
                  )}
                  <RadioBox isSelected={false} handleOnClick={() => {}} showRadio={false}>
                    <h6 className='boldFontStyle'>{_.get(this, 'props.store.taxAddress.AddressType')}</h6>
                    <div>{getFormattedAddress(_.get(this, 'props.store.taxAddress'), addressConfig)}</div>
                    <Button
                      type='button'
                      text={getTranslationText('common', 'edit')}
                      classes='button-link boldFontStyle mt8 subscription-link-button'
                      handleOnClick={() => {
                        this.handleOnEditClick(_.get(this, 'props.store.taxAddress.ConsumerFavouriteLocationID'))
                      }}
                    />
                  </RadioBox>
                  <Button type='button' classes='button-primary mt20' handleOnClick={this.handleSubmitPayment}>
                    {getTranslationText('payment', 'submitPayment')}
                  </Button>
                </div>
              )
            )}
          </BoxHolder>
        </div>
      )
    } else if (requiredParams && requiredParams.length === 1) {
      return (
        <div className='col-sm-4'>
          <div className='col-sm-12 dot-progress-bar no-padding'>
            <DotProgressBar
              currentIndex={currentProgressBarIndex + 1}
              progressBarItems={progressBarItems}
              showEveryStepCount={true}
              showBackgroundColor={true}
            />
          </div>
          <BoxHolder className='box-holder-right1'>
            {this.props.store.requiredPaymentParams[0].RequiredParameter !== 'Zipcode' ? (
              <React.Fragment>
                <div className={'headings bookFontStyle ' + (window.isDirectionRTL ? ' pr0 ' : ' pl0 ')}>
                  <span className={`mediumFontStyle`}>{getTranslationText('store', 'enterYourDetails')}</span>
                </div>
                <div className='bookFontStyle mt20 mb10'>{getTranslationText('profile', 'stateLabel')}</div>
                {_.get(this, 'props.store.requiredPaymentParams[0].StateList.length', 0) > 0 && (
                  <Dropdown
                    id='store-dropdown'
                    options={_.get(this, 'props.store.requiredPaymentParams[0].StateList', []).map((state) => ({
                      ...state,
                      label: state.State,
                      value: state.State,
                    }))}
                    handleOnChange={this.props.handleStateSelection}
                    value={this.props.selectedState.State || ''}
                    optionLength={_.get(this, 'props.store.requiredPaymentParams[0].StateList.length')}
                    isSearchable
                    searchText={getTranslationText('common', 'searchByStateText')}
                    filterBy='State'
                    placeholder={getTranslationText('store', 'selectStateLabel')}
                  />
                )}
                <div className='mt40'></div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className='bookFontStyle fontSmall mb8'>{getTranslationText('store', 'enterZipCodeLabel')}</div>
                <InputComponent
                  className='plan-zipcode'
                  type='text'
                  placeholder={getTranslationText('common', 'zipCodeLabel')}
                  value={this.props.Zipcode}
                  onChange={this.props.handleZipcode}
                />
                <div className='mb20 bookFontStyle fontSmall info-content'>
                  <InfoIcon className='mr6' />
                  {getTranslationText('store', 'zipMandatoryMsg')}
                </div>
              </React.Fragment>
            )}
            {purchaseBtn}
          </BoxHolder>
        </div>
      )
    } else {
      return (
        <React.Fragment>
          <div />
        </React.Fragment>
      )
    }
  }

  static propTypes = {
    enableLoader: PropTypes.bool,
    eligibilityLoader: PropTypes.bool,
    isAdditionalInfoReq: PropTypes.any,
    selectedState: PropTypes.object,
    Zipcode: PropTypes.string,
    store: PropTypes.object,
    handleCheckoutNavigation: PropTypes.func.isRequired,
    handleStateSelection: PropTypes.func,
    handleZipcode: PropTypes.func,
  }
}

export const PaymentOptionSelection = (props) => {
  const { width, height } = useWindowSize()
  const [paymentOptions, setPaymentOptions] = useState(
    _.get(props, 'store.availablePaymentOptions', []).map((option) => ({ ...option, isSelected: false }))
  )
  const locale = _.get(props, 'user.selectedCountryData.countryConfig.Locale.DEFAULT', 'en')
  const currencyCode = _.get(props, 'user.selectedCountryData.CurrencyCode', 'INR')
  const availablePaymentOptions = _.get(props, 'store.availablePaymentOptions', [])
  // discounts promo code
  let fullPaymentOption =
    availablePaymentOptions.length && availablePaymentOptions.find((option) => option.PaymentModeCode === 'ONLINE_PAYMENT')
  let monthlyPaymentOption = availablePaymentOptions.length && availablePaymentOptions.find((option) => option.PaymentModeCode === 'EMI')
  // discounts = (fullPaymentOption && fullPaymentOption.PlanDiscounts && fullPaymentOption.PlanDiscounts.PlanDiscountOffers) || []
  let discountOnFullPayment =
    fullPaymentOption && fullPaymentOption.PlanDiscounts ? props.getDiscountsByType(fullPaymentOption.PlanDiscounts, 'UPFRONT') : []
  let discountOnMonthly =
    monthlyPaymentOption && monthlyPaymentOption.PlanDiscounts
      ? props.getDiscountsByType(monthlyPaymentOption.PlanDiscounts, 'MONTHLY')
      : []
  let promoCodeOffersFullPayment =
    discountOnFullPayment.length > 0 &&
    discountOnFullPayment.filter((discount) => {
      return discount.DiscountCategory === 'PromoCode'
    })
  if (!promoCodeOffersFullPayment) promoCodeOffersFullPayment = []
  // console.log('promoCodeOffersFullPayment - ', promoCodeOffersFullPayment)
  let promoCodeOffersEMIPayment =
    discountOnMonthly.length > 0 &&
    discountOnMonthly.filter((discount) => {
      return discount.DiscountCategory === 'PromoCode'
    })
  if (!promoCodeOffersEMIPayment) promoCodeOffersEMIPayment = []
  // console.log('promoCodeOffersEMIPayment - ', promoCodeOffersEMIPayment)
  const promoCodeOffers = promoCodeOffersFullPayment.concat(promoCodeOffersEMIPayment)
  const promoCodes_orig =
    promoCodeOffers.length > 0 &&
    promoCodeOffers.map((offer) => {
      return {
        ...offer,
        isSelected: false,
        code: offer.PromoCode,
        val: getLocaleCurrency(
          locale,
          currencyCode,
          fullPaymentOption && Object.keys(fullPaymentOption).length > 1
            ? fullPaymentOption.TotalPrice > 0
              ? offer.DiscountedTotalAmount
              : offer.DiscountedBaseAmount
            : offer.DiscountedTotalAmount || offer.DiscountedBaseAmount
        ),
        desc: (
          <span
            dangerouslySetInnerHTML={{
              __html: offer.OfferDisplayText,
            }}
          />
        ),
      }
    })
  const [showPromoCodeBtn, setShowPromoCodeBtn] = useState(promoCodeOffers.length > 0)
  const [showPromoCodeModal, setShowPromoCodeModal] = useState(false)
  const [showPromoCodeSuccessModal, setShowPromoCodeSuccessModal] = useState(false)
  const [appliedPromoCodeObj, setAppliedPromoCodeObj] = useState({})
  const [showAppliedPromoCodeBtn, setShowAppliedPromoCodeBtn] = useState(false)
  const [promoCodes, setPromoCodes] = useState(promoCodes_orig)
  const [appliedPromoCode, setAppliedPromoCode] = useState('')
  const [showPromoCodeError, setShowPromoCodeError] = useState(false)
  const [showConfetti, setShowConfetti] = useState(false)

  useEffect(() => {
    if (showConfetti)
      setTimeout(() => {
        setShowConfetti(false)
      }, 8000)
  }, [showConfetti, setShowConfetti])

  const setAppliedPromoCodeOffer = async (promoCodeObj, remove = false) => {
    // console.log('setAppliedPromoCodeOffer - promoCodeObj - ', promoCodeObj)
    let selectedPaymentOption = _.get(props, 'store.selectedPaymentOption', {})
    let appliedDiscountOffer = !remove && promoCodeObj && Object.keys(promoCodeObj).length > 0 ? promoCodeObj : {}
    if (appliedDiscountOffer.desc) appliedDiscountOffer.desc = ''
    // console.log('setAppliedPromoCodeOffer - appliedDiscountOffer - ', appliedDiscountOffer)
    // console.log('setAppliedPromoCodeOffer - selectedPaymentOption - ', selectedPaymentOption)
    await props.setSelectedPaymentOption({
      ...selectedPaymentOption,
      appliedDiscountOffer,
    })
  }

  const showPromoCodes = () => {
    setShowPromoCodeModal(true)
  }
  const handlePromoCodeOptionSelection = (code) => {
    setShowPromoCodeError(false)
    let updatedList = promoCodes.map((option) => {
      return { ...option, isSelected: code === option.code }
    })
    // console.log('updatedList - ', updatedList)
    setPromoCodes(updatedList)
    let applied = updatedList.filter((option) => {
      return option.isSelected === true
    })
    //console.log('applied - ', applied)
    setAppliedPromoCode((applied && applied[0].code) || '')
  }
  const closePromoCodeModal = () => {
    setShowPromoCodeModal(false)
  }
  const applyPromoCode = () => {
    let obj = promoCodes_orig.filter((option) => {
      return option.code === appliedPromoCode
    })
    if (!obj || !obj.length) {
      setShowPromoCodeError(true)
      return
    }
    closePromoCodeModal()
    setAppliedPromoCodeObj(obj && obj[0])
    setShowConfetti(true)
    setShowPromoCodeSuccessModal(true)
    setAppliedPromoCodeOffer(obj && obj[0])
  }
  const closePromoCodeSuccessModal = () => {
    setShowPromoCodeSuccessModal(false)
    setShowConfetti(false)
    setShowPromoCodeBtn(false)
    setShowAppliedPromoCodeBtn(true)
  }
  const handlePromoCodeEdit = (e) => {
    e && setShowPromoCodeError(false)
    e && setAppliedPromoCode(e.target.value.trim())
  }
  const removePromoCode = () => {
    setShowPromoCodeBtn(true)
    setShowAppliedPromoCodeBtn(false)
    setAppliedPromoCodeObj({})
    let updatedList = promoCodes.map((option) => {
      return { ...option, isSelected: false }
    })
    setPromoCodes(updatedList)
    setAppliedPromoCode('')
    setAppliedPromoCodeOffer(null, true)
  }

  const handlePaymentOptionSelection = (paymentID) => {
    let updatedList = paymentOptions.map((option) => {
      return { ...option, isSelected: paymentID === option.PlanPartnerPaymentID }
    })
    setPaymentOptions(updatedList)
  }

  const handlePaymentOptionConfirmation = async () => {
    let selectedOption = paymentOptions.find((option) => option.isSelected)
    let selectedPaymentOption = _.get(props, 'store.selectedPaymentOption', {})
    await props.setSelectedPaymentOption({
      ...selectedOption,
      appliedDiscountOffer:
        (selectedPaymentOption && selectedPaymentOption.appliedDiscountOffer) ||
        (selectedOption.maxDiscountOffer && selectedOption.maxDiscountOffer.DiscountCategory != 'PromoCode'
          ? selectedOption.maxDiscountOffer
          : {}) ||
        {},
    })
    pushToAnalytics('Plan Sales - Payment Frequency Confirmed', {
      Payment_Mode: selectedOption.PaymentModeCode,
      ConsumerID: _.get(props, 'user.consumerDetails.data.consumerID', undefined),
      Plan_Name: _.get(props, 'store.selectedPlanDetails.PlanName', undefined),
      IMEI_checked_on_storepage: !!_.get(props, 'store.imeiForFilter', false),
      eureka: !!_.get(props, 'store.imeiFromToken', false),
      'Logged In': _.get(props, 'user.consumerDetails.data.consumerID', false) ? 'Yes' : 'No',
      ProductName: _.get(props, 'store.purchaseEligibilityInfo.ProductName', ''),
    })
    props.handleCheckoutNavigation()
  }
  /* Util function to pick payment option price
  consider applied promocode price first
  then look for maxDiscountOffer price
  otherwise pick plan price */
  const getPriceByPaymentOption = (paymentType, option) => {
    let price =
      _.get(props, 'store.selectedPaymentOption.appliedDiscountOffer.PaymentType', '') === paymentType
        ? _.get(
            props,
            'store.selectedPaymentOption.appliedDiscountOffer.' +
              (option.TotalPrice > 0 ? 'DiscountedPlanPrice' : 'DiscountedBasePlanPrice'),
            paymentType === 'MONTHLY' ? props.pickEMIPlanPrice(option) : props.pickPlanPrice(option)
          )
        : (option.maxDiscountOffer &&
            option.maxDiscountOffer.DiscountCategory !== 'PromoCode' &&
            (option.TotalPrice > 0
              ? option.maxDiscountOffer.DiscountedPlanPrice
              : option.maxDiscountOffer.DiscountedBasePlanPrice || option.maxDiscountOffer.EMIAmount)) ||
          (paymentType === 'MONTHLY' ? props.pickEMIPlanPrice(option) : props.pickPlanPrice(option))
    // console.log("getPriceByPaymentOption - ", price)
    return price
  }
  // discounts promo code
  let currentStep = 2
  let progressBarItems = ['', '', '']
  if (
    (_.get(props, 'store.imeiForFilter', false) && _.get(props, 'user.userDetails.consumerID', false)) ||
    !props.isAddressRequiredForPlanPurchase()
  ) {
    currentStep = 1
    progressBarItems = ['', '']
  }
  return (
    <div className='col-sm-4'>
      {props.isNewPlanSalesFlow && (
        <div className='col-sm-12 dot-progress-bar'>
          <DotProgressBar currentIndex={currentStep} progressBarItems={progressBarItems} showEveryStepCount={true} />
        </div>
      )}
      <BoxHolder className='box-holder-right'>
        <div className={'mediumFontStyle  mb20' + (props.isNewPlanSalesFlow ? ' payment-heading' : 'fontLarge payment-mode-title')}>
          {getTranslationText('payment', 'selectPaymentOption')}
        </div>
        {showPromoCodeModal ? (
          <Modal className='sfy-modal successfully-added-device-content' showModal={showPromoCodeModal}>
            <CustomIconComponent
              className={'close-icon ' + (window.isDirectionRTL ? 'appear-left text-left ' : 'appear-right text-right')}
              onClick={closePromoCodeModal}
            />
            <div className='text-center'>
              <div className='bookFontStyle fontLarge mt8'>{getTranslationText('store', 'applyPromocode')}</div>
            </div>
            <div className='text-left'>
              <div className='mt8'>
                <div className='bookFontStyle fontMedium mb4'>{getTranslationText('store', 'promoCode')}</div>
                <Input
                  inputType='text'
                  value={appliedPromoCode}
                  className={{
                    input: 'promocode-input',
                  }}
                  handleOnChange={handlePromoCodeEdit}
                  name='promocode'
                  placeholder={getTranslationText('store', 'enterCouponCode')}
                />
                {showPromoCodeError ? (
                  <div className='lightFontStyle fontSmall promocode-error-msg mt3 mb4'>
                    {getTranslationText('store', 'invalidPromocode')}
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className='text-left'>
              <div className='mt20'>
                <div className='mediumFontStyle fontMedium mb4'>{getTranslationText('store', 'availableOffers')}</div>
                <div className='promocode-radio-container'>
                  {promoCodes.map((option) => (
                    <RadioBox
                      key={option.code}
                      name={'promocode-radio'}
                      className={{ container: 'promocode-radio' }}
                      isSelected={option.isSelected}
                      handleOnClick={() => handlePromoCodeOptionSelection(option.code)}
                    >
                      <div className='mt8 fontLarge mediumFontStyle'>{option.code}</div>
                      <div className='fontMedium mediumFontStyle'>
                        {getTranslationText('store', 'promocodeYouWillSave', '', '@val@', option.val)}
                      </div>
                      <div className='fontSmall bookFontStyle'>{option.desc}</div>
                    </RadioBox>
                  ))}
                </div>
              </div>
            </div>
            <div className='text-center'>
              <MediumButton
                className='boldFontStyle fontMedium mt8'
                onClick={applyPromoCode}
                buttonText={getTranslationText('store', 'applyBtn')}
                disabled={!appliedPromoCode && appliedPromoCode.length}
              />
            </div>
          </Modal>
        ) : (
          ''
        )}
        {showPromoCodeSuccessModal ? (
          <Modal className='sfy-modal promocode-success-content' showModal={showPromoCodeSuccessModal}>
            <CustomIconComponent
              className={'close-icon ' + (window.isDirectionRTL ? 'appear-left text-left ' : 'appear-right text-right')}
              onClick={closePromoCodeSuccessModal}
            />
            <div className='text-center'>
              <img className='promocode-success-img' src={'/commonAssets/successImg.png'} />
              <div className='bookFontStyle fontExtraLarge mt8'>{getTranslationText('common', 'hurray') + '!'}</div>
              <div className='bookFontStyle fontExtraLarge promocode-success-text mt4'>
                <img className='promocode-success-icon mr8' src={discountImg} />
                <span>
                  <span>
                    <b>{`${appliedPromoCodeObj.val}`}</b>
                  </span>
                  &nbsp;{getTranslationText('store', 'promocodeSaved')}
                </span>
              </div>
              <div className='bookFontStyle fontSmall mt20'>
                {getTranslationText('store', 'promocodeSuccessMsg', '', '@code@', appliedPromoCodeObj.code)}
              </div>
            </div>
            <div className='text-center'>
              <MediumButton
                className='button-small button-ghost bookFontStyle fontLarge mt20'
                onClick={closePromoCodeSuccessModal}
                buttonText={getTranslationText('common', 'continue')}
              />
            </div>
            {showConfetti && <Confetti width={width} height={height} initialVelocityY={30} numberOfPieces={200} />}
          </Modal>
        ) : (
          ''
        )}
        {showPromoCodeBtn ? (
          <div className='promocode-continer' onClick={showPromoCodes}>
            <img className='mr8' src={discountImg} />
            <div className='promocode-text'>{getTranslationText('store', 'applyPromocode')}</div>
          </div>
        ) : (
          ''
        )}
        {showAppliedPromoCodeBtn ? (
          <div className='promocode-applied-continer'>
            <div>
              <div className='fontMedium lightFontStyle'>
                {getTranslationText('store', 'appliedPromocode', '', '@code@', appliedPromoCodeObj.code)}
              </div>
              <div className='fontSmall bookFontStyle'>
                {getTranslationText('store', 'promocodeYouSaved', '', '@val@', appliedPromoCodeObj.val)}
              </div>
            </div>
            <div className='fontMedium lightFontStyle promocode-remove' onClick={removePromoCode}>
              {getTranslationText('common', 'remove')}
            </div>
          </div>
        ) : (
          ''
        )}
        <div className='payment-mode-radio-container mt20'>
          {paymentOptions.map((option) => (
            <RadioBox
              key={option.PlanPartnerPaymentID}
              name={'payment-mode-radio'}
              className={{ container: 'payment-mode-radio radio-button' }}
              isSelected={option.isSelected}
              handleOnClick={() => handlePaymentOptionSelection(option.PlanPartnerPaymentID)}
            >
              <div className={props.isNewPlanSalesFlow ? 'fontLarge' : 'fontMedium'}>
                {option.EMIAmount ? getTranslationText('payment', 'payMonthly') : getTranslationText('payment', 'payFull')}
              </div>
              <div className={`${props.isNewPlanSalesFlow ? 'fontLarge' : 'fontMedium primaryFontColor'} mediumFontStyle `}>
                {option.maxDiscountOffer && option.maxDiscountOffer.hasConcession ? (
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: option.maxDiscountOffer.OfferDisplayText,
                      }}
                    />
                    <div className='first-month-free-option-note lightFontStyle fontSmall overrideCasing'>
                      {getTranslationText('store', 'firstMonthEMIFreeNote')}
                    </div>
                  </span>
                ) : option.EMIAmount ? (
                  `${getLocaleCurrency(locale, currencyCode, getPriceByPaymentOption('MONTHLY', option))}/${getTranslationText(
                    'common',
                    'month'
                  )}`
                ) : (
                  getLocaleCurrency(locale, currencyCode, getPriceByPaymentOption('UPFRONT', option))
                )}
              </div>
            </RadioBox>
          ))}
          <MediumButton
            buttonText={getTranslationText('common', 'continue')}
            onClick={handlePaymentOptionConfirmation}
            disabled={!paymentOptions.some((option) => option.isSelected)}
            className='payment-mode-btn mb20 mt20'
          />
        </div>
      </BoxHolder>
    </div>
  )
}

export const DiscountDetails = (props) => {
  const handleVerifyAndPurchase = () => {
    props.setShowDiscountDetails(false)
    props.setShowPaymentMode(true)
    browserHistory.push({
      pathname: '/store/checkout',
      query: { step: 1 },
    })
  }
  const locale = _.get(props, 'user.selectedCountryData.countryConfig.Locale.DEFAULT', 'en')
  const currencyCode = _.get(props, 'user.selectedCountryData.CurrencyCode', 'INR')
  const discountAmount = _.get(props, 'store.discountDetails.discount', 0)
  return (
    <div className='col-sm-4'>
      <BoxHolder>
        <Heading title={getTranslationText('store', 'greetingMsg') + '!'} className={'mb28'} />
        <div className='discount-wrapper'>
          <div className='mt8 boldFontStyle'>
            {getTranslationText('store', 'discountMsg') + ' ' + getLocaleCurrency(locale, currencyCode, discountAmount)}
          </div>
          <div className='mt8 mb20 boldFontStyle'>{getTranslationText('store', 'discountProceed')}</div>
          <MediumButton
            buttonText={getTranslationText('store', 'verifyPurchase')}
            id='checkout-state-1'
            className='discount-btn'
            onClick={handleVerifyAndPurchase}
          />
        </div>
      </BoxHolder>
    </div>
  )
}

export const Confirmation = (props) => {
  const dateFormat = _.get(props, 'user.selectedCountryData.countryConfig.DateFormat.DEFAULT', 'DD/MM/YYYY')
  const username = props.user.userDetails.name || props.userData.name
  return (
    <div className='row'>
      {/* <div className='col-sm-12'>{!props.isNewPlanSalesFlow && <RequestNavBar steps={steps} />}</div> */}
      <div className='col-sm-12 store-product-checkout-flow'>
        <Loader loaderState={props.storeCheckoutWidgetLoader}>
          <BoxHolder className='purchased-order-container'>
            <div className='bookFontStyle mb20 overrideCasing'>
              {props.isNewPlanSalesFlow ? (
                <>
                  <div className='mediumFontStyle fontLarge pb5'>
                    {getTranslationText('store', 'greetingMsg') + ' ' + (username && username.split(' ')[0]) + '!'}
                  </div>
                  <div className='fontLarge'>{getTranslationText('store', 'planPurchaseSuccessMessage')}</div>
                </>
              ) : (
                <div>
                  {getTranslationText('store', 'greetingMsg') + ' ' + props.user.userDetails.name.split(' ')[0] + '!'}
                  &nbsp;{getTranslationText('store', 'purchaseSuccessMsg')}&nbsp;
                  {_.get(props, 'selectedPlanDetails.AlternatePlanDisplayName', '').length ? (
                    <span dangerouslySetInnerHTML={{ __html: props.selectedPlanDetails.AlternatePlanDisplayName }} />
                  ) : (
                    props.selectedPlanDetails.PlanName
                  )}
                  {_.get(props, 'selectedPlanDetails.EntityDetails[0].BrandName', '').toUpperCase() === 'APPLE'
                    ? '. Your Proof of Coverage document will be emailed to you within 72 hours.'
                    : ''}
                </div>
              )}
            </div>
            <div className='row mb30'>
              <div className='col-sm-7'>
                <div className='purchased-order-content'>
                  <div className={'image-container'}>
                    <div className={'bookFontStyle fontMedium mb10 overrideCasing title'}>
                      {getTranslationText('store', 'orderConfirmation')}
                    </div>
                    <div className='purchased-order-img'>
                      <img src={_.get(props, 'selectedPlanDetails.planMeta.PlanEmailImage')} />
                    </div>
                  </div>
                  <div className='purchased-order-info mb8'>
                    <div className='bookFontStyle fontMedium overrideCasing'>{getTranslationText('store', 'planPurchased')}</div>
                    <div className='mediumFontStyle fontMedium'>
                      {_.get(props, 'selectedPlanDetails.AlternatePlanDisplayName', '').length ? (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: props.selectedPlanDetails.AlternatePlanDisplayName,
                          }}
                        />
                      ) : (
                        props.selectedPlanDetails.PlanName
                      )}
                    </div>

                    <div className='bookFontStyle mt8 fontMedium overrideCasing'>{getTranslationText('common', 'device')}</div>
                    <div className='mediumFontStyle fontMedium'>{props.productName}</div>
                    {/* <div className='bookFontStyle mt8 fontMedium overrideCasing'>{getTranslationText('store', 'invoiceNumber')}</div>
                        <div className='mediumFontStyle fontMedium'>invoice number</div> */}
                    <div className='bookFontStyle fontMedium mt8 overrideCasing'>{getTranslationText('store', 'purchaseDate')}</div>
                    <div className='mediumFontStyle fontMedium'>
                      {getLocaleDate(
                        dateFormat,
                        props.PaymentOnActivation
                          ? _.get(props, 'store.purchasePlanNoPayment[0].DateOfPurchase')
                          : props.paymentDetails.DateOfPurchase
                      )}
                    </div>
                    {props.PaymentOnActivation && (
                      <div className='mt8 bookFontStyle fontMedium'>
                        We have sent an SMS on your registered mobile number with your unique activation code. Please follow the Next steps
                        to complete the plan purchase
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {_.get(props, 'user.appConfigDetails.PaymentConfirmationText', false) && (
                <div className='col-sm-12'>
                  <div className='bookFontStyle mb20 overrideCasing'>{_.get(props, 'user.appConfigDetails.PaymentConfirmationText')}</div>
                </div>
              )}
            </div>
            <Button
              className='button-ghost p10 mediumFontStyle fontLarge button-small'
              text={
                _.get(props, 'user.userDetails.consumerID', false)
                  ? getTranslationText('store', 'backToHomePage')
                  : getTranslationText('store', 'backToStore')
              }
              handleOnClick={() => {
                _.get(props, 'user.userDetails.consumerID', false) ? browserHistory.push('/') : browserHistory.push('/store/view')
              }}
            />
            {props.isNewPlanSalesFlow && !_.get(props, 'user.userDetails.consumerID', false) && (
              <Button
                className='button-ghost p10 mediumFontStyle fontLarge button-small ml16'
                text={getTranslationText('store', 'goToMyAccount')}
                handleOnClick={() => {
                  browserHistory.push('/')
                }}
              />
            )}
          </BoxHolder>
        </Loader>
      </div>
    </div>
  )
}
Confirmation.propTypes = {
  user: PropTypes.object.isRequired,
  selectedPlanDetails: PropTypes.object.isRequired,
  paymentDetails: PropTypes.object.isRequired,
  storeCheckoutWidgetLoader: PropTypes.bool,
  PaymentOnActivation: PropTypes.bool,
}

export class RequestNavBar extends React.Component {
  render() {
    return (
      <div className='request-nav-bar'>
        {this.props.steps.map((step, index) => {
          return (
            <div id={'req-nav-' + index} key={'key' + index} className={'nav-img-holder ' + (step.pathname ? 'clickable' : '')}>
              <img src={step.image} />
              <div className={'nav-text-holder sentence-casing-uppercase ' + (step.active ? 'active' : '')}>
                <span>
                  {index + 1}. {step.text}
                </span>
              </div>
            </div>
          )
        })}
      </div>
    )
  }
}
RequestNavBar.propTypes = {
  steps: PropTypes.array.isRequired,
}

export class SignUpSideBar extends React.Component {
  constructor(props) {
    super(props)
    this.mobileNoCmpRef = (() => {
      if (props.isAnApplePurchaseFlow) {
        return {
          getMobileNo: () => {
            return props.mobileNo
          },
        }
      } else {
        return {}
      }
    })()
    this.userDetailsCmpRef = (() => {
      if (props.isAnApplePurchaseFlow) {
        return {
          getUserEmailId: () => {
            return props.emailID
          },
        }
      } else {
        return {}
      }
    })()
    this.otpCompRef = {}
    let cc = _.get(this, 'props.user.selectedCountryData.CountryCode', 'other').toLowerCase()
    if (!legalConfigCodes[cc]) cc = 'other'
    this.state = {
      loginPageId: props.isAnApplePurchaseFlow ? 'otp_page' : 'login',
      BtnLoaderStatus: false,
      phnoLengthAr: _.get(props, 'user.selectedCountryData.countryConfig.MobileNumber.LENGTH', [10]),
      termsAndCondCheck: false,
      btnStatus: false,
      isMobileNoFieldReq: true,
      requestOtpResponse: {
        allowResend: _.get(props, 'requestOtpResponse.allowResend', true),
        sendCaptcha: _.get(props, 'requestOtpResponse.sendCaptcha', false),
      },
      storeLinks: {},
      showCaptcha: _.get(props, 'user.appConfigDetails.sendCaptcha'),
      hideTnC: !!getLegalConsents(_.get(this, 'props.user.appLegalConfigs.LegalConfigs', []), [
        legalConfigCodes[cc].buy_tnc,
        legalConfigCodes[cc].buy_tnc_gst_credit_na,
        legalConfigCodes[cc].data,
        legalConfigCodes[cc].tnc_insurance,
        legalConfigCodes[cc].payment_decl,
        legalConfigCodes[cc].payment_decl2,
      ]).length,
      showDataRecheckPopup: false,
      showEligibiltyPopup: false,
      eligibilityMessage: '',
    }
  }

  componentWillMount() {
    // if (Object.keys(_.get(this, 'props.user.consumerDetails')).length &&
    //   this.props.user.consumerDetails.data.isNew) {
    //   this.setState({
    //     loginPageId: 'login'
    //   })
    // }
  }
  closeEligibilityModal = () => {
    this.setState({ showEligibiltyPopup: false }, () => {
      // navigate back to store page
      browserHistory.goBack()
    })
  }
  closeDataRecheckModal = () => {
    this.setState({ showDataRecheckPopup: false }, () => {
      this.props.setglobalLoaderStatus(true)
      this.props.checkPlanEligibility(
        undefined,
        _.get(this, 'props.user.userDetails.birthDate', this.props.dateOfBirth),
        () => {
          this.props.setglobalLoaderStatus(false)
          if (!this.props.isAnApplePurchaseFlow) {
            this.proceedToPurchase()
          } else {
            this.setState({
              BtnLoaderStatus: false,
            })
          }
          if (this.props.isAnApplePurchaseFlow && this.props.location.pathname !== '/store/signin') {
            this.props.applePlanEligiblity(true)
          }
        },
        (msg) => {
          this.props.setglobalLoaderStatus(false)
          this.setState({ showEligibiltyPopup: true, eligibilityMessage: msg, BtnLoaderStatus: false })
        }
      )
    })
  }
  getOtpCompRef = (ref) => (this.otpCompRef = ref)

  getMobileComponentRef = (ref) => (this.mobileNoCmpRef = ref)

  getUserDetailsRef = (ref) => (this.userDetailsCmpRef = ref)

  handleTandCClick = () => {
    this.setState(
      {
        termsAndCondCheck: !this.state.termsAndCondCheck,
      },
      this.btnStatusHandler
    )
  }

  componentDidMount() {
    this.setState({
      storeLinks: {
        tnCLink: this.handleRedirection('tnc', 'terms-conditions'),
        privacyLink: this.handleRedirection('privacy', 'privacy-policy'),
      },
    })
    pushToAnalytics('Plan Sales - Login')
  }

  handleRedirection = (type, customLink) => {
    let skipRedirection = _.get(this, 'props.user.appConfigDetails.SkipRedirection', false)
    let link = customLink
    if (type === 'tnc') {
      link = this.props.user.appConfigDetails.Terms
      skipRedirection && this.props.setTncLink(link)
    } else if (type === 'privacy') {
      link = this.props.user.appConfigDetails.Privacy
      skipRedirection && this.props.setPrivacyLink(link)
    }
    if (skipRedirection) {
      link = customLink
    }
    return link
  }

  btnStatusHandler = () => {
    let btnStatus = false
    if (this.userDetailsCmpRef.isProfileCompleted() && (this.state.hideTnC || this.state.termsAndCondCheck)) {
      btnStatus = true
    }
    if (this.state.isMobileNoFieldReq) {
      btnStatus = btnStatus && this.mobileNoCmpRef.getPhNoStatus()
    }
    this.setState({
      btnStatus,
    })
  }

  proceedToPurchase = () => {
    this.props.setglobalLoaderStatus(true)
    this.props
      .loadAppConfig()
      .then(() => {
        this.props.setglobalLoaderStatus(true)
        this.props
          .getAppLegalConfigs()
          .then(() => {
            if (!this.props.isAnApplePurchaseFlow) {
              if (_.get(this, 'props.store.selectedPlanDetails.EntityDetails[0].WarrantyCheckDetails.ValidForEntireBrand', false)) {
                this.props.setglobalLoaderStatus(true)
                this.props
                  .addPurchasedPlanToaDevice()
                  .then(() => {
                    const redirectionQry = {
                      pathname: '/store/checkout',
                      query: { step: 1 },
                    }
                    // ******** PaymentOnActivation key handling to take payment or not in D+1 flow
                    this.props.checkIfPaymentRequiredHandling(redirectionQry)
                  })
                  .catch((err) => {
                    console.log(err)
                    this.props.setglobalLoaderStatus(false)
                    browserHistory.goBack()
                  })
              } else {
                this.props.addPlanToaDevice().catch(() => {
                  this.props.setglobalLoaderStatus(false)
                  browserHistory.goBack()
                })
              }
            }
            if (this.props.isAnApplePurchaseFlow && this.props.location.pathname !== '/store/signin') {
              this.props.applePlanEligiblity(true)
            }
          })
          .catch((err) => {
            console.log(err)
            this.props.setglobalLoaderStatus(false)
          })
      })
      .catch((err) => {
        this.props.setglobalLoaderStatus(false)
        console.log(err)
      })
  }

  updateUserDetailsHandler = (shouldUpdateOnlyDOB, updateDOB = false) => {
    // if (this.props.isAnApplePurchaseFlow && this.props.location.pathname !== '/store/signin') {
    //   this.props.setAppleNewUserDetails()
    //   return
    // }

    const emailID = this.userDetailsCmpRef.getUserEmailId()
    const mobileNo = this.mobileNoCmpRef.getMobileNo()
    const name = this.userDetailsCmpRef.getUserName()

    this.props.setglobalLoaderStatus(true)
    const isEmailLogin = _.get(this, 'props.user.appConfigDetails.EnableEmailLogin', false)

    this.props
      .updateUserDetailsAsync({
        data: {
          consumerID: this.props.user.consumerDetails.data.consumerID,
          updateObj: {
            ...(!isEmailLogin && !shouldUpdateOnlyDOB && emailID ? { emailID } : {}),
            ...(isEmailLogin && !shouldUpdateOnlyDOB && mobileNo ? { mobileNo } : {}),
            ...(!shouldUpdateOnlyDOB && name ? { name } : {}),
            ...((shouldUpdateOnlyDOB || updateDOB) && this.props.dateOfBirth
              ? { birthDate: moment(this.props.dateOfBirth).format('YYYY-MM-DD') }
              : {}),
          },
        },
      })
      .then(() => {
        // ------------ Clever Tap | pushProfile | profileupdate plan purchase -----------
        const clevertapUserProfile = _.get(this, 'props.user', {})

        pushToAnalytics('pushProfile', {}, clevertapUserProfile)
        // ------------ Clever Tap | pushProfile ------------
        if (this.props.isAnApplePurchaseFlow && this.props.location.pathname !== '/store/signin') {
          this.props.setglobalLoaderStatus(false)
        } else this.proceedToPurchase()
      })
      .catch((err) => {
        console.log(err)
        this.props.setglobalLoaderStatus(false)
      })
  }

  verifyOTPHandler = async (reqpayload = {}) => {
    const isEmailLogin = _.get(this.props, 'user.appConfigDetails.EnableEmailLogin', false)
    let userMobile = this.mobileNoCmpRef.getMobileNo()
    let userEmail = this.userDetailsCmpRef.getUserEmailId()
    let cc = _.get(this, 'props.user.selectedCountryData.CountryCode', 'other').toLowerCase()
    let countryName = _.get(this.props, 'user.selectedCountryData.CountryName', '')
    const isGuestUser = _.get(this, 'props.user.isAnGuestUser')

    if (!legalConfigCodes[cc]) cc = 'other'
    let has_gst_consent = hasConsent(_.get(this, 'props.user.appLegalConfigs.LegalConfigs', []), legalConfigCodes[cc].buy_tnc_gst_credit_na)
    let consents = getLegalConsents(_.get(this, 'props.user.appLegalConfigs.LegalConfigs', []), [
      has_gst_consent ? legalConfigCodes[cc].buy_tnc_gst_credit_na : legalConfigCodes[cc].buy_tnc,
      legalConfigCodes[cc].data,
      legalConfigCodes[cc].tnc_insurance,
      legalConfigCodes[cc].payment_decl,
      legalConfigCodes[cc].payment_decl2,
    ])
    let getLink = (link) => {
      if (link === 'tnc') return _.get(this, 'props.user.tncLink', this.props.planTAndCLink)
      if (link === 'privacy') return _.get(this, 'props.user.privacyLink', this.props.privacyLink)
      if (link === 'dataconsentstore') return _.get(this, 'props.user.dataLink', this.props.dataLink)
      if (link === 'insurancecard' || link === 'ipid') return _.get(this.props, 'store.ipidUrl.DocumentURL', '')
      if (link === 'relateddocuments') return _.get(this.props, 'store.otherRelatedDocsUrl.DocumentURL', '')
      if (link === 'country') return getTranslationText('common', 'localized' + countryName.replaceAll(' ', '')) || countryName
    }
    let consentsAr =
      consents.length &&
      consents.map((consent) => {
        let attributes = [{ attributeType: legalConfigAttributes.otp, attributeValue: reqpayload.OTP }]
        let phs = getPlaceholders(consent.legalConfig.Text)
        let phs_unique = [...new Set(phs)]
        phs_unique.length &&
          phs_unique.map((ph) => {
            if (getLink(ph)) attributes.push({ attributeType: legalConfigAttributes[ph], attributeValue: getLink(ph) })
          })
        return {
          applicationLegalConfigID: consent.ApplicationLegalConfigID,
          mobileNo: `${this.mobileNoCmpRef.getMobileNo()}`,
          consentText: replacePlaceholders(consent.legalConfig.Text, _.get(this.props, 'user.selectedCountryData.CountryName', '')),
          attributes: attributes,
        }
      })
    const mainLoginCredential = isEmailLogin ? { emailID: userEmail } : { mobileNo: userMobile, phoneCode: '+' + window.PhoneCode }
    return this.props
      .verifyOTPAsync({
        ...reqpayload,
        ...mainLoginCredential,
        consentArray: consentsAr || [],
        checkLinkedAccount: !isGuestUser,
      })
      .then(async (data) => {
        let recheckDOB = _.get(this, 'props.store.selectedPlanDetails.PlanConfig.SuperGroupEligibilityCheckFields', []).includes(
          'BirthDate'
        )
        if (data.isLoginSuccess) {
          const shouldUpdateDOB = !_.get(this, 'props.user.userDetails.birthDate', false) && this.props.dateOfBirth
          if (recheckDOB && this.props.dateOfBirth) {
            let dob = moment(this.props.dateOfBirth).format('YYYY-MM-DD')
            recheckDOB = _.get(this, 'props.user.userDetails.birthDate', '') != dob
          }
          if (data.isNew || !data.profileComplete) {
            if (this.props.isAnApplePurchaseFlow && this.props.location.pathname !== '/store/signin') {
              this.props.setAppleNewUserDetails()
            } else this.updateUserDetailsHandler(false, shouldUpdateDOB)
          } else if (data.consumer) {
            if (shouldUpdateDOB) {
              try {
                this.updateUserDetailsHandler(true)
              } catch (err) {
                console.log(err)
              }
            }
            // ------------ Clever Tap | pushProfile | updating profile ------------
            const clevertapUserProfile = _.get(this, 'props.user', {})
            pushToAnalytics('pushProfile', {}, clevertapUserProfile)
            // ------------ Clever Tap | pushProfile ------------
            if (!this.props.isAnApplePurchaseFlow) {
              if (recheckDOB) {
                this.setState({ showDataRecheckPopup: true })
              } else this.proceedToPurchase()
            } else {
              this.setState({
                BtnLoaderStatus: false,
              })
            }
            if (this.props.isAnApplePurchaseFlow && this.props.location.pathname !== '/store/signin') {
              this.props.applePlanEligiblity(true)
            }
          } else {
            this.setState({
              BtnLoaderStatus: false,
            })
          }
        } else {
          this.setState({
            requestOtpResponse: {
              allowResend: data.allowResend,
              showCaptcha: data.sendCaptcha,
            },
          })
        }
        if (!recheckDOB && !this.props.isAnApplePurchaseFlow) {
          try {
            let params = {
              getStatusMessage: true,
              BrandID: _.get(this, 'props.store.selectedPlanDetails.EntityDetails[0].BrandID'),
              PlanID: _.get(this, 'props.store.selectedPlanDetails.PlanID'),
              imeiOrSerial: this.props.eligiblityCheckValue,
              ConsumerID: _.get(this, 'props.user.consumerDetails.data.consumerID'),
              MobileNo: _.get(this, 'props.user.consumerDetails.MobileNo'),
            }
            const response = await this.props.getPlanEligibilityStatus(params, 'primaryCheck')
            this.props.setFetchedPlanPriceByProduct(response.data.PlanPrice)
          } catch (err) {
            console.log(err)
          }
        }
      })
      .catch((err) => {
        console.log(err)
        this.resetOTPFields()
      })
  }

  resetOTPFields = () => this.otpCompRef.clearOTPValue && this.otpCompRef.clearOTPValue()

  fetchOTPfunc = async (reqPayload = {}) => {
    let userMobile = this.mobileNoCmpRef.getMobileNo()
    let userEmail = this.userDetailsCmpRef.getUserEmailId()
    const isEmailLogin = _.get(this.props, 'user.appConfigDetails.EnableEmailLogin', false)
    const mainLoginCredential = isEmailLogin ? { emailID: userEmail } : { mobileNo: userMobile, phoneCode: `+${window.PhoneCode}` }
    return this.props
      .generateOTPAsync(
        {
          ...reqPayload,
          ...mainLoginCredential,
        },
        isEmailLogin
      )
      .then((data) => {
        if (data.success) {
          this.resetOTPFields()
          let state = {
            BtnLoaderStatus: false,
            requestOtpResponse: data.data,
          }
          if (this.state.loginPageId !== 'otp_page') {
            state = {
              ...state,
              loginPageId: 'otp_page',
            }
          }
          this.setState(state)
        }
      })
      .finally(() => {
        this.setState({
          BtnLoaderStatus: false,
        })
      })
  }

  validatePhno = (userDetails) => {
    this.userDetails = userDetails
    if (!this.state.BtnLoaderStatus) {
      this.setState(
        {
          BtnLoaderStatus: true,
        },
        async () => {
          pushToAnalytics('Plan Sales - Login Confirm')
          if (this.state.showCaptcha) {
            const captcha = await getCaptcha()
            this.fetchOTPfunc({
              captcha,
            })
          } else {
            this.fetchOTPfunc()
          }
        }
      )
    }
  }
  currentProgressBarIndex = 0
  progressBarItems = ['', '', '']
  render() {
    if (this.state.loginPageId === 'otp_page') {
      const isEmailLogin = _.get(this, 'props.user.appConfigDetails.EnableEmailLogin', false)
      const mobileNumber = this.mobileNoCmpRef.getMobileNo()
      const emailID = this.userDetailsCmpRef.getUserEmailId()
      const otpSentFullText = `${isEmailLogin ? getTranslationText('login', 'entercode') : getTranslationText('store', 'verificationCode')}`
      const enterCodeText = getTranslationText('store', 'enterVerificationCode')
      const sentToText = getTranslationText('store', 'otpSentTo')
      let otpHeading = `<div class='headings mediumFontStyle mb10'>${otpSentFullText}</div>
      <div class='bookFontStyle fontMedium'>${enterCodeText}</div>
      <div class='bookFontStyle fontMedium mb10'>(${sentToText + ' ' + (isEmailLogin ? emailID : mobileNumber)})</div>
      `
      return (
        <div>
          <OTPComponent
            OTPHeading={otpHeading}
            buttonText={getTranslationText('login', 'verify')}
            user={this.props.user}
            hideOTPImage
            requestOtpResponse={this.state.requestOtpResponse}
            getOtpCompRef={this.getOtpCompRef}
            resendOTPMainFunc={this.fetchOTPfunc}
            verifyOTPHandler={this.verifyOTPHandler}
          />
          {this.state.showDataRecheckPopup ? (
            <Modal className='sfy-modal successfully-added-device-content' showModal={this.state.showDataRecheckPopup}>
              <CustomIconComponent
                className={'close-icon ' + (window.isDirectionRTL ? 'appear-left text-left ' : 'appear-right text-right')}
                onClick={this.closeDataRecheckModal}
              />
              <div className='text-center'>
                <div className='bookFontStyle fontLarge mt8'>{getTranslationText('store', 'existingDataFoundTitle')}</div>
                <div className='bookFontStyle fontSmall mt8'>
                  <span>{getTranslationText('store', 'existingDataFoundMessage')}</span>
                </div>
                <MediumButton
                  className='boldFontStyle fontMedium mt8'
                  onClick={this.closeDataRecheckModal}
                  buttonText={getTranslationText('common', 'understood')}
                />
              </div>
            </Modal>
          ) : (
            ''
          )}
          {this.state.showEligibiltyPopup ? (
            <Modal className='sfy-modal successfully-added-device-content' showModal={this.state.showEligibiltyPopup}>
              <CustomIconComponent
                className={'close-icon ' + (window.isDirectionRTL ? 'appear-left text-left ' : 'appear-right text-right')}
                onClick={this.closeEligibilityModal}
              />
              <div className='text-center'>
                {<img src='/commonAssets/failed.png' />}
                <div className='bookFontStyle fontLarge mt8'>{getTranslationText('common', 'sorry')}</div>
                <div className='bookFontStyle fontSmall mt8'>
                  <span>{this.state.eligibilityMessage || getTranslationText('store', 'notEligibleLabel')}</span>
                </div>
                <MediumButton
                  className='boldFontStyle fontMedium mt8'
                  onClick={this.closeEligibilityModal}
                  buttonText={getTranslationText('common', 'okay')}
                />
              </div>
            </Modal>
          ) : (
            ''
          )}
        </div>
      )
    } else {
      return (
        <div className='col-sm-12 signin-box'>
          <form
            onSubmit={this.state.btnStatus ? this.validatePhno : () => {}}
            className={this.state.BtnLoaderStatus ? 'disabledBlock' : ''}
            target='remember_store'
            autoComplete='on'
          >
            <div className='row'>
              <div className='col-sm-12 mb20'>
                <UserDetailsComponent getUserDetailsRef={this.getUserDetailsRef} userIpOnChangeCallBack={this.btnStatusHandler} />
              </div>
              {this.state.isMobileNoFieldReq && (
                <div className='col-sm-12 mb20'>
                  <MobileNoComponent
                    getMobileComponentRef={this.getMobileComponentRef}
                    phnoLengthAr={this.state.phnoLengthAr}
                    selectedCountryData={this.props.user.selectedCountryData}
                    mobileNoOnChangeCallback={this.btnStatusHandler}
                  />
                </div>
              )}

              <div className='col-sm-12'>
                {!this.state.hideTnC ? (
                  <div className='purchase-selectables mb20'>
                    <Checkbox type='checkbox' value={this.state.termsAndCondCheck} handleOnChange={this.handleTandCClick}>
                      <div className='bookFontStyle fontSmall'>
                        <span onClick={this.handleTandCClick}>{getTranslationText('login', 'toAgree')}</span>&nbsp;
                        {getTranslationText('common', 'our')}
                        <br />
                        <Link
                          className='termsfontColor'
                          target='_blank'
                          to={this.state.storeLinks.tnCLink}
                          onClick={() => {
                            this.handleRedirection('tnc', '/terms-conditions')
                          }}
                        >
                          {getTranslationText('common', 'termsAndCondition')}
                        </Link>
                        &nbsp;
                        {getTranslationText('common', 'and')}&nbsp;
                        <Link
                          className='termsfontColor'
                          target='_blank'
                          to={this.state.storeLinks.privacyLink}
                          onClick={() => {
                            this.handleRedirection('privacy', '/privacy-policy')
                          }}
                        >
                          {getTranslationText('common', 'privacyPolicy')}
                        </Link>
                        <br />
                        <span
                          dangerouslySetInnerHTML={{
                            __html: _.get(this.props, 'user.appConfigDetails.configLegalParams.consent.Text', ''),
                          }}
                        />
                      </div>
                    </Checkbox>
                  </div>
                ) : (
                  ''
                )}
                {this.state.showCaptcha && <Captcha recaptcha={recaptcha} />}
              </div>
              <div className='col-sm-12 mb8'>
                <MediumButton
                  buttonText={getTranslationText('common', 'confirm')}
                  disabled={!this.state.btnStatus}
                  className={'btnSignIn'}
                  btnLoader={this.state.BtnLoaderStatus}
                  onClick={this.validatePhno}
                />
              </div>
            </div>
          </form>
        </div>
      )
    }
  }
}
SignUpSideBar.propTypes = {
  generateOTPAsync: PropTypes.func.isRequired,
  verifyOTPAsync: PropTypes.func.isRequired,
  getUserDetailsAsync: PropTypes.func.isRequired,
  updateUserDetailsAsync: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  loadAppConfig: PropTypes.func.isRequired,
  getAppLegalConfigs: PropTypes.func.isRequired,
  addPurchasedPlanToaDevice: PropTypes.func,
  addPlanToaDevice: PropTypes.func,
  eligibleDeviceInfo: PropTypes.object,
  setTncLink: PropTypes.func.isRequired,
  setPrivacyLink: PropTypes.func.isRequired,
  isAnApplePurchaseFlow: PropTypes.bool,
  applePlanEligiblity: PropTypes.func,
  setAppleNewUserDetails: PropTypes.func,
  mobileNo: PropTypes.string,
  location: PropTypes.object,
  setglobalLoaderStatus: PropTypes.func,
  checkIfPaymentRequiredHandling: PropTypes.func,
  checkPlanEligibility: PropTypes.func,
  dateOfBirth: PropTypes.any,
}

export class DetailsSideBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showCustomerID: _.get(this, 'props.user.appConfigDetails.displayConfig.profilePage.showCustomerID', false),
      customerID: props.customerID || _.get(this, 'props.user.userDetails.identityNumber', ''),
      customerIDLength: props.customerIDLength || 11,
      disableCustomerID: !!(props.customerID || _.get(this, 'props.user.userDetails.identityNumber', '')),
      btnStatus: false,
      showDeviceDocsModal: false,
      selectedDocImg: null,
    }
  }

  componentDidMount() {}

  handleCustomerIDInput = (e) => {
    regexCheck('onChange', 'numeric', e.target.value, this.state.customerIDLength) &&
      this.setState(
        {
          customerID: e.target.value,
        },
        () => {
          this.btnStatusHandler()
        }
      )
  }

  btnStatusHandler = () => {
    let status = this.state.showCustomerID
      ? this.state.customerIDLength === this.state.customerID.length && this.props.deviceDocs && this.props.deviceDocs.length
      : this.props.deviceDocs && this.props.deviceDocs.length
    this.setState({
      btnStatus: status,
    })
  }

  closeDocsUploadModal = () => {
    this.props.closeDeviceDocsUploadModal()
    this.btnStatusHandler()
  }

  proceedToPurchase = () => {
    let consumerProductID = _.get(this, 'props.store.planConsumerProduct.ConsumerProductID', undefined)
    if (consumerProductID) this.addDocsToProduct(consumerProductID)
    const redirectionQry = {
      pathname: '/store/checkout',
      query: { step: 1 },
    }
    // ******** PaymentOnActivation key handling to take payment or not in D+1 flow
    this.props.checkIfPaymentRequiredHandling(redirectionQry)
  }

  addDocsToProduct = (ConsumerProductID) => {
    this.props
      .addProductDocAsync({
        ConsumerProductID: ConsumerProductID,
        AuthID: this.props.user.consumerDetails.data.accessToken.ID,
      })
      .then(() => {
        this.props
          .getProductDocsAsync(
            {
              ConsumerProductID: ConsumerProductID,
            },
            this.props.user.consumerDetails.data.accessToken.ID
          )
          .then(() => {})
          .catch(() => {
            this.props.setglobalLoaderStatus(false)
          })
      })
      .catch(() => {
        this.props.setglobalLoaderStatus(false)
      })
  }

  submitDetails = () => {
    this.props.setglobalLoaderStatus(true)

    this.props
      .updateUserDetailsAsync({
        AuthID: this.props.user.consumerDetails.data.accessToken.ID,
        data: {
          consumerID: _.get(this.props, 'user.consumerDetails.data.consumerID'),
          updateObj: {
            identityNumber: this.state.customerID || '',
          },
        },
      })
      .then(() => {
        this.proceedToPurchase()
      })
      .catch(() => {
        this.props.setglobalLoaderStatus(false)
      })
  }

  handleDeviceDocsDel = () => {
    this.props.setStoreDocs({ remove: true, fileUrl: this.state.selectedDocImg.fileUrl })
    this.setState({
      showDeviceDocsModal: false,
    })
  }

  render() {
    return (
      <div className='col-sm-12 signin-box'>
        <div className='row user-details-container'>
          <div className='col-sm-12 mb20'>
            <div className='bookFontStyle mb8'>{getTranslationText('store', 'proofOfPurchase')}</div>
            <div className='mb8' onClick={this.props.showUploadDocsModal}>
              <div className='bookFontStyle fontMedium special-link-txt'>{getTranslationText('store', 'uploadDocsChooseFile')}</div>
              {this.props.deviceDocs && this.props.deviceDocs.length > 0 && (
                <div className='bookFontStyle fontSmall'>
                  ({this.props.deviceDocs.length + ' ' + getTranslationText('store', 'uploadDocuments')})
                </div>
              )}
            </div>

            <Modal className='sfy-modal docs-container-modal' showModal={this.props.showDeviceDocsUploadModal}>
              <div className='docs-container'>
                <div className='custom-modal-title mb20'>
                  <Heading title={getTranslationText('store', 'uploadDeviceDocuments')} />
                  <CustomIconComponent
                    className='close-icon'
                    onClick={this.closeDocsUploadModal}
                    alt={getTranslationText('common', 'cancel')}
                  />
                </div>
                <div className='custom-modal-content'>
                  <div className='docs-list-container'>
                    <BoxImagesComponent
                      isUploaded={false}
                      className='store-device-docs-add'
                      imgFilePath={<AddIcon />}
                      inputName='deviceDocs-upload'
                      inputId={'upload-img-ip-id'}
                      fileUploadHander={this.props.handleUploadDocs}
                    />
                    {this.props.deviceDocs.length > 0 &&
                      this.props.deviceDocs.map((data, index) => {
                        return !data.isRemoved ? (
                          <BoxImagesComponent
                            key={data.ConsumerProductDocumentID + '_' + index}
                            className='store-device-docs'
                            isUploaded
                            imgFilePath={data.fileUrl}
                            imageID={data.ConsumerProductDocumentID}
                            imgViewerHandler={() => {
                              this.setState({
                                showDeviceDocsModal: true,
                                selectedDocImg: data,
                              })
                            }}
                          />
                        ) : (
                          ''
                        )
                      })}
                  </div>
                </div>
                <div className='center-align'>
                  <MediumButton
                    className='mt20'
                    onClick={this.closeDocsUploadModal}
                    btnLoader={this.props.docsBtnLoader}
                    disabled={!(this.props.deviceDocs && this.props.deviceDocs.length > 0)}
                    buttonText={getTranslationText('common', 'done')}
                  />
                </div>
              </div>
            </Modal>
            {this.state.showDeviceDocsModal ? (
              <ImageViewerModal
                show={this.state.showDeviceDocsModal}
                closeIconHandler={() => {
                  this.setState({ showDeviceDocsModal: false })
                }}
                removeBtnStatus
                deleteDocsHandler={this.handleDeviceDocsDel}
                imagePath={this.state.selectedDocImg.fileUrl}
              />
            ) : (
              ''
            )}
          </div>
          {this.state.showCustomerID ? (
            <div className='col-sm-12 mb20'>
              <div className='bookFontStyle mb8'>{getTranslationText('store', 'customerID')}</div>
              <InputComponent
                className='mediumFontStyle'
                type='numeric'
                id='UserCustomerID'
                placeholder={getTranslationText('store', 'inputCustomerID')}
                onKeyPress={this.handleCustomerIDInput}
                onChange={this.handleCustomerIDInput}
                value={this.state.customerID}
                disabled={this.state.disableCustomerID}
              />
            </div>
          ) : (
            ''
          )}
          <div className='col-sm-12 mt8 mb8'>
            <MediumButton
              buttonText={getTranslationText('common', 'continue')}
              disabled={!this.state.btnStatus}
              className={'btnSignIn'}
              btnLoader={this.state.BtnLoaderStatus}
              onClick={this.submitDetails}
            />
          </div>
        </div>
      </div>
    )
  }
}
DetailsSideBar.propTypes = {
  customerID: PropTypes.number,
  customerIDLength: PropTypes.number,
  updateUserDetailsAsync: PropTypes.func.isRequired,
  setglobalLoaderStatus: PropTypes.func.isRequired,
  addPurchasedPlanToaDevice: PropTypes.func.isRequired,
  addPlanToaDevice: PropTypes.func.isRequired,
  checkIfPaymentRequiredHandling: PropTypes.func.isRequired,
  showUploadDocsModal: PropTypes.func,
  deviceDocs: PropTypes.array,
  showDeviceDocsUploadModal: PropTypes.bool,
  closeDeviceDocsUploadModal: PropTypes.func,
  handleUploadDocs: PropTypes.func,
  docsBtnLoader: PropTypes.bool,
  addProductDocAsync: PropTypes.func.isRequired,
  getProductDocsAsync: PropTypes.func.isRequired,
  setStoreDocs: PropTypes.func.isRequired,
}

export const ReviewPurchasePlanComponent = (props) => {
  let hasWarrantyAPI = !!(
    _.get(props, 'store.selectedPlanDetails.EntityDetails', []).length &&
    props.getBrandDetails(props.store.selectedPlanDetails).WarrantyCheckDetails &&
    props.getBrandDetails(props.store.selectedPlanDetails).WarrantyCheckDetails.ValidForEntireBrand
  )
  const { imeiOrSerial, ProductName: productName } = props.store.purchaseEligibilityInfo || {}
  const { BrandID } = props.store.selectedBrand
  const PlanValidity = _.get(props, 'store.selectedPlanDetails.planMeta.PlanValidity')
  const locale = _.get(props, 'user.selectedCountryData.countryConfig.Locale.DEFAULT', 'en')
  const currencyCode = _.get(props, 'user.selectedCountryData.CurrencyCode')
  const discountAmount = Number(_.get(props, 'store.discountDetails.discount', 0))
  const planAmount = Number(_.get(props, 'store.discountDetails.Amount', 0))
  // const handleVerifyAndPurchase = () => {
  //   const redirectionQry = {
  //     pathname: '/store/checkout',
  //     query: { step: 1 },
  //   }
  //   // ******** PaymentOnActivation key handling to take payment or not in D+1 flow
  //   props.checkIfPaymentRequiredHandling(redirectionQry)
  // }
  const orignalPrice = Number(_.get(props, 'store.planPriceOnProductBasis.OriginalPlanPrice', 0))
  const discountPrice = Number(_.get(props, 'store.planPriceOnProductBasis.PlanPrice', 0))
  const orignalEMIPrice = Number(_.get(props, 'store.planPriceOnProductBasis.planPriceBillingConfig[0].OriginalEMIAmount', 0))
  const discountEMIPrice = Number(props.pickEMIPlanPrice(_.get(props, 'store.planPriceOnProductBasis.planPriceBillingConfig[0]', {})))
  // const discounts = _.get(props, 'store.purchaseEligibilityInfo.PlanDiscounts.PlanDiscountOffers', [])
  let discountsAll = _.get(props, 'store.purchaseEligibilityInfo.PlanDiscounts', [])
  let discountOnFullPayment = props.getDiscountsByType(discountsAll, 'UPFRONT')
  let discountOnMonthly = props.getDiscountsByType(discountsAll, 'MONTHLY')
  const showDiscountedPrice =
    (orignalPrice && discountPrice && discountOnFullPayment.length <= 0 && discountOnMonthly.length <= 0) ||
    (orignalEMIPrice &&
      discountEMIPrice &&
      discountOnFullPayment.length <= 0 &&
      discountOnMonthly.length <= 0 &&
      _.get(props, 'store.purchaseEligibilityInfo.IsPurchasedAllowed', false))
  return (
    <div className='row'>
      <div className='col-sm-12 store-product-checkout-flow'>
        <div className='row override-col-prop'>
          <div className='col-sm-9'>
            <Loader loaderState={props.enableLoader}>
              <BoxHolder className='purchase-revice-container'>
                <Heading title={getTranslationText('store', 'reviewPurchase')} />
                <div className='row mt28'>
                  <div className='col-sm-4 mb20 selected-plan-img'>
                    <img src={_.get(props, 'store.selectedPlanDetails.planMeta.PlanEmailImage')} />
                  </div>
                  <div className='col-sm-8'>
                    <div className='mediumFontStyle mb4'>
                      <span>{getTranslationText('store', 'policyName')} : </span>
                      {props.store.selectedPlanDetails.AlternatePlanDisplayName &&
                      props.store.selectedPlanDetails.AlternatePlanDisplayName.length ? (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: props.store.selectedPlanDetails.AlternatePlanDisplayName,
                          }}
                        />
                      ) : (
                        props.store.selectedPlanDetails.PlanName
                      )}
                    </div>
                    <div
                      className='bannerDesc fontMedium mb8'
                      dangerouslySetInnerHTML={{
                        __html:
                          props.store.selectedPlanDetails.PlanConfig &&
                          props.store.selectedPlanDetails.PlanConfig.PlanDescriptionSubHeading,
                      }}
                    />
                    {PlanValidity && (
                      <div className='mediumFontStyle mb4'>
                        <span>{getTranslationText('device', 'validity')} : </span>
                        {PlanValidity}
                      </div>
                    )}
                    {props.store.selectedPlanDetails.PlanConfig.IsCustomPlan ? (
                      <div className='mediumFontStyle fontMedium mb8'>
                        {parseInt(props.store.customPlanPrice) ? (
                          <>
                            <span>{getTranslationText('common', 'priceLabel')} :&nbsp;</span>
                            <span>{getLocaleCurrency(locale, currencyCode, props.store.customPlanPrice)}</span>
                          </>
                        ) : (
                          ''
                        )}
                      </div>
                    ) : showDiscountedPrice ? (
                      <div className='mediumFontStyle fontMedium mb8'>
                        <span>{getTranslationText('common', 'priceLabel')} :&nbsp;</span>
                        {!!orignalPrice && (
                          <span
                            className={`lightFontStyle strikethrough-text mr4 ${props.isNewPlanSalesFlow ? 'gray-text fontLarge' : ''}`}
                          >
                            {getLocaleCurrency(locale, currencyCode, orignalPrice, true)}
                          </span>
                        )}
                        <span className='mr4'>{getLocaleCurrency(locale, currencyCode, discountPrice)}</span>
                        {(!!orignalEMIPrice || !!discountEMIPrice) && (
                          <span className='lightFontStyle'>{' ' + getTranslationText('payment', 'oneTimePayment')} | </span>
                        )}
                        {!!orignalEMIPrice && (
                          <span
                            className={`lightFontStyle strikethrough-text mr4 ${props.isNewPlanSalesFlow ? 'gray-text fontLarge' : ''}`}
                          >
                            {getLocaleCurrency(locale, currencyCode, orignalEMIPrice, true)}
                          </span>
                        )}
                        {!!discountEMIPrice && (
                          <span>
                            <span className='mr4'>{getLocaleCurrency(locale, currencyCode, discountEMIPrice)}</span>
                            <span className='lightFontStyle'>/{getTranslationText('common', 'month')}</span>
                          </span>
                        )}
                      </div>
                    ) : (
                      <div className='mediumFontStyle fontMedium mb20'>
                        {parseInt(_.get(props, 'store.planPriceOnProductBasis.PlanPrice')) ? (
                          <React.Fragment>
                            <span>{getTranslationText('common', 'priceLabel')} :&nbsp;</span>
                            {props.store.selectedPlanDetails.priceRange && hasWarrantyAPI && props.store.planPriceOnProductBasis ? (
                              <React.Fragment>
                                <span
                                  className={
                                    discountAmount > 0 ? `strikethrough-text ${props.isNewPlanSalesFlow ? 'gray-text fontLarge' : ''}` : ''
                                  }
                                >
                                  {getLocaleCurrency(locale, currencyCode, props.store.planPriceOnProductBasis.PlanPrice)}
                                </span>
                                {discountAmount > 0 && (
                                  <span>
                                    {' | ' +
                                      getLocaleCurrency(locale, currencyCode, planAmount) +
                                      ' ' +
                                      getTranslationText('store', 'afterDiscount')}
                                  </span>
                                )}
                              </React.Fragment>
                            ) : (
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: props.store.selectedPlanDetails.planPrice.find((price) => price.isSelected).text,
                                }}
                              />
                            )}
                            {!!_.get(props, 'store.planPriceOnProductBasis.planPriceBillingConfig[0]', false) && (
                              <React.Fragment>
                                <span className='lightFontStyle'>
                                  {' ' + getTranslationText('payment', 'oneTimePayment')}
                                  <br />
                                  <span className='mediumFontStyle'>
                                    {getLocaleCurrency(
                                      locale,
                                      currencyCode,
                                      props.pickEMIPlanPrice(_.get(props, 'store.planPriceOnProductBasis.planPriceBillingConfig[0]', {}))
                                    )}
                                  </span>
                                  /{getTranslationText('common', 'month')}
                                </span>
                              </React.Fragment>
                            )}
                          </React.Fragment>
                        ) : (
                          ''
                        )}
                      </div>
                    )}
                    {productName && (
                      <div className='mediumFontStyle mb4'>
                        <span>{getTranslationText('common', 'productName')} : </span>
                        {productName}
                      </div>
                    )}
                    {imeiOrSerial && (
                      <div className='mediumFontStyle mb4'>
                        <span>
                          {`${getTranslationText('device', 'imei')}
                            ${BrandID === 4 ? '/ ' + getTranslationText('common', 'serialNumber') : ''}`}
                          &nbsp;:&nbsp;
                        </span>
                        {imeiOrSerial}
                      </div>
                    )}
                  </div>
                </div>
              </BoxHolder>
            </Loader>
          </div>
          <div className={'col-sm-3'}>
            {_.get(props, 'user.userDetails', '').length ? (
              <BoxHolder>
                <Heading title={getTranslationText('store', 'selectStateLabel')} className={'mb28'} />
                {_.get(props, 'store.stateList.length') > 0 && (
                  <Dropdown
                    id='store-dropdown'
                    options={props.store.stateList.map((state) => ({
                      ...state,
                      label: state.State,
                      value: state.State,
                    }))}
                    handleOnChange={props.handleStateSelection}
                    value={props.selectedState.State || ''}
                    optionLength={_.get(props, 'store.stateList.length')}
                    isSearchable
                    searchText={getTranslationText('common', 'searchByStateText')}
                    filterBy='State'
                    placeholder={getTranslationText('store', 'selectStateLabel')}
                  />
                )}
                <div className='mb20 bookFontStyle fontMedium info-content'>
                  <img src={`/${appName}/images/info@3x.png`} />
                  {getTranslationText('store', 'stateMandatoryLabel')}
                </div>
                <MediumButton
                  buttonText={getTranslationText('common', 'continue')}
                  id='checkout-state-1'
                  disabled={!(props.selectedState && Object.keys(props.selectedState).length)}
                  onClick={props.handleCheckoutNavigation}
                />
              </BoxHolder>
            ) : !hasWarrantyAPI && !props.user.isAnGuestUser ? (
              <BoxHolder className='proceed-to-purchase-sidebar'>
                <Heading title={getTranslationText('store', 'detailsHeader')} />
                <div className='row proceed-to-purchase-content mt28'>
                  <DetailsSideBar
                    user={props.user}
                    store={props.store}
                    updateUserDetailsAsync={props.updateUserDetailsAsync}
                    setglobalLoaderStatus={props.setglobalLoaderStatus}
                    addPurchasedPlanToaDevice={props.addPurchasedPlanToaDevice}
                    addPlanToaDevice={props.addPlanToaDevice}
                    checkIfPaymentRequiredHandling={props.checkIfPaymentRequiredHandling}
                    showUploadDocsModal={props.showUploadDocsModal}
                    deviceDocs={props.deviceDocs}
                    showDeviceDocsUploadModal={props.showDeviceDocsUploadModal}
                    closeDeviceDocsUploadModal={props.closeDeviceDocsUploadModal}
                    handleUploadDocs={props.handleUploadDocs}
                    docsBtnLoader={props.docsBtnLoader}
                    addProductDocAsync={props.addProductDocAsync}
                    getProductDocsAsync={props.getProductDocsAsync}
                    setStoreDocs={props.setStoreDocs}
                  />
                </div>
              </BoxHolder>
            ) : (
              <BoxHolder className='proceed-to-purchase-sidebar'>
                <Heading title={getTranslationText('login', 'signIn')} />
                <div className='row proceed-to-purchase-content mt28'>
                  <SignUpSideBar
                    {...props}
                    fetchDiscountDetailsAsync={props.fetchDiscountDetailsAsync}
                    user={props.user}
                    store={props.store}
                    eligibleDeviceInfo={props.store.eligibleDeviceInfo || {}}
                    getUserDetailsAsync={props.getUserDetailsAsync}
                    updateUserDetailsAsync={props.updateUserDetailsAsync}
                    generateOTPAsync={props.generateOTPAsync}
                    addPurchasedPlanToaDevice={props.addPurchasedPlanToaDevice}
                    addPlanToaDevice={props.addPlanToaDevice}
                    checkIfPaymentRequiredHandling={props.checkIfPaymentRequiredHandling}
                    verifyOTPAsync={props.verifyOTPAsync}
                    loadAppConfig={props.loadAppConfig}
                    getAppLegalConfigs={props.getAppLegalConfigs}
                    setTncLink={props.setTncLink}
                    setPrivacyLink={props.setPrivacyLink}
                    setglobalLoaderStatus={props.setglobalLoaderStatus}
                    getPlanEligibilityStatus={props.getPlanEligibilityStatus}
                    eligiblityCheckValue={props.eligiblityCheckValue}
                    setFetchedPlanPriceByProduct={props.setFetchedPlanPriceByProduct}
                  />
                </div>
              </BoxHolder>
            )}
          </div>
          <React.Fragment>
            <Modal className='sfy-modal' showModal={props.showDisclaimerModal}>
              <div className='row' />
              <img className='image-center mb8' src={informationImg} />
              <div className='custom-modal-content align-center'>
                <div className='docs-list-container'>{_.get(props, 'store.purchaseEligibilityInfo.Disclaimer.description', '')}</div>
              </div>
              <span className='align-center'>
                <MediumButton
                  onClick={props.closeDisclaimerModal}
                  className='mt20'
                  buttonText={getTranslationText('common', 'understood')}
                />
              </span>
            </Modal>
          </React.Fragment>
        </div>
      </div>
    </div>
  )
}
ReviewPurchasePlanComponent.propTypes = {
  store: PropTypes.object,
  handleCheckoutNavigation: PropTypes.func,
  handleStateSelection: PropTypes.func,
  selectedState: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  enableLoader: PropTypes.bool,
  user: PropTypes.object,
  getBrandDetails: PropTypes.func,
  generateOTPAsync: PropTypes.func.isRequired,
  verifyOTPAsync: PropTypes.func.isRequired,
  loadAppConfig: PropTypes.func.isRequired,
  getAppLegalConfigs: PropTypes.func.isRequired,
  addPurchasedPlanToaDevice: PropTypes.func,
  addPlanToaDevice: PropTypes.func,
  setTncLink: PropTypes.func.isRequired,
  setPrivacyLink: PropTypes.func.isRequired,
  getUserDetailsAsync: PropTypes.func,
  updateUserDetailsAsync: PropTypes.func,
  setglobalLoaderStatus: PropTypes.func.isRequired,
  checkIfPaymentRequiredHandling: PropTypes.func,
  showDisclaimerModal: PropTypes.bool,
  closeDisclaimerModal: PropTypes.func,
  showUploadDocsModal: PropTypes.func,
  deviceDocs: PropTypes.array,
  showDeviceDocsUploadModal: PropTypes.bool,
  closeDeviceDocsUploadModal: PropTypes.func,
  handleUploadDocs: PropTypes.func,
  docsBtnLoader: PropTypes.bool,
  addProductDocAsync: PropTypes.func.isRequired,
  getProductDocsAsync: PropTypes.func.isRequired,
  checkPlanEligibility: PropTypes.func.isRequired,
}

export class CustomizedPlanComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showPrice: false,
      currentQueue: [],
      totalPrice: 0,
      isBuyNowBtnDisabled: false,
      showCustomQuestions: false,
      customizeBuytermsConditionState: false,
    }
  }

  componentWillMount() {
    this.props.setCustomPlanPrice(0)
    this.props.addCustomPlanProductPrice(0)
    this.props.addCustomPlanDOP('')
    this.props.resetPlanEligibilityChecks()
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  componentWillReceiveProps(nxtProps) {
    if (this.props !== nxtProps && this.props.store.priorityQueue) {
      this.setState({
        currentQueue: nxtProps.showCustomQuestions
          ? this.state.currentQueue.length
            ? this.state.currentQueue
            : _.get(nxtProps, 'store.priorityQueue', [])[0] || []
          : [],
      })
    }
  }

  handlePillSelection = (e, quesId, hasMultiSelect = false) => {
    if (e) {
      let id = parseInt(e.currentTarget.id.replace('question-' + quesId + '-', ''))
      this.setState(
        {
          isBuyNowBtnDisabled: false,
          currentQueue: this.state.currentQueue.map((queue) => {
            if (quesId === queue.QuestionID) {
              let answersList = queue.Answers.map((ans, index) => {
                return {
                  ...ans,
                  isSelected: parseInt(id) === index ? !ans.isSelected : hasMultiSelect ? ans.isSelected : false,
                }
              })
              return {
                ...queue,
                isQuestionAnswered: !!answersList.find((value) => value.isSelected),
                Answers: answersList,
              }
            } else {
              return queue
            }
          }),
        },
        () => {
          this.checkDependencyUnderSamePriority(quesId)
        }
      )
    }
  }

  checkDependencyUnderSamePriority = (quesId) => {
    let ptyofCurQuesID = this.state.currentQueue.find((ques) => ques.QuestionID === quesId).Priority
    // ???????? can use `some` instead of `find` ??
    let currentQuesListFromPQ = this.props.store.priorityQueue
      .find((pty) => pty[0].Priority === ptyofCurQuesID)
      .filter((ques) => ques.Priority === ptyofCurQuesID)
    // ???? filter kyu kiya hai, agar find se hi wo array mil raha hai toh ?
    // ^ to obtain all list of answers pq current ques pty
    let currentDepIdsList = this.hasDependency(currentQuesListFromPQ)
    let manipualtedQues = _.compact(
      currentQuesListFromPQ &&
        currentQuesListFromPQ.map((ques) => {
          if (currentDepIdsList.quesList.includes(parseInt(ques.QuestionID))) {
            return {
              ...ques,
              Answers: _.compact(
                ques.Answers.map((list) => {
                  if (currentDepIdsList.answrList.includes(list.QuestionAnswerID)) {
                    return list
                  }
                })
              ),
            }
          }
        })
    )
    this.setState(
      {
        isBuyNowBtnDisabled: false,
        currentQueue: [
          ...this.state.currentQueue.filter((ques) => ques.Priority < ptyofCurQuesID),
          ...manipualtedQues.map((ques) => {
            let curQuesRef = this.state.currentQueue.find((data) => data.QuestionID === ques.QuestionID)
            // ques ref from current queue of selected priority ques to retain selected answers
            return {
              ...ques,
              isQuestionAnswered: curQuesRef.isQuestionAnswered,
              Answers: ques.Answers.map((value) => {
                // check if answer was selected in old selection
                let curentAnsRef = curQuesRef.Answers.find((data) => data.QuestionAnswerID === value.QuestionAnswerID)
                return {
                  ...value,
                  isSelected: (curentAnsRef && curentAnsRef.isSelected) || false,
                }
              }),
            }
          }),
        ],
      },
      () => {
        this.checkPriorityHasFulfilled(quesId)
        let lastPriorityID = _.findLast(this.state.currentQueue, 'Priority').Priority
        let highestPriority = this.props.store.priorityQueue[this.props.store.priorityQueue.length - 1][0].Priority
        if (lastPriorityID === highestPriority) {
          this.checkMandatoryQuestionsAnswered(highestPriority, () => {
            this.setState({
              isBuyNowBtnDisabled: true,
            })
          })
        }
      }
    )
  }
  // check if all the answers has been selected for given priority
  checkPriorityHasFulfilled = (quesId) => {
    let currentQuesPriority = this.state.currentQueue.find((ques) => ques.QuestionID === quesId)
    currentQuesPriority = currentQuesPriority && currentQuesPriority.Priority
    let lastPriorityID = _.findLast(this.state.currentQueue, 'Priority').Priority
    if (currentQuesPriority !== lastPriorityID) {
      // meant to reset CQ if lower pty ques has been changed
      this.setState(
        {
          currentQueue: this.state.currentQueue.filter((ques) => {
            if (ques.Priority <= currentQuesPriority) {
              return ques
            }
          }),
        },
        () => {
          this.checkMandatoryQuestionsAnswered(currentQuesPriority, (priorityQuesIndx) => {
            let nxtSetofQues = this.props.store.priorityQueue[++priorityQuesIndx]
            nxtSetofQues && nxtSetofQues.length && this.pushNextPriorityQuestions(nxtSetofQues)
          })
        }
      )
    } else {
      this.checkMandatoryQuestionsAnswered(currentQuesPriority, (priorityQuesIndx) => {
        let nxtSetofQues = this.props.store.priorityQueue[++priorityQuesIndx]
        nxtSetofQues && nxtSetofQues.length && this.pushNextPriorityQuestions(nxtSetofQues)
      })
    }
  }

  checkMandatoryQuestionsAnswered = (currentQuesPriority, callBackFunc) => {
    let mandatoryQuesCount = 0
    let answeredQuesCount = 0
    let priorityQuesIndx = 0
    _.find(this.props.store.priorityQueue, (pty, ptyIdx) => {
      if (currentQuesPriority === pty[0].Priority) {
        priorityQuesIndx = ptyIdx
      }
    })
    this.state.currentQueue.map((question) => {
      if (question.Priority === currentQuesPriority && !question.IsOptional) {
        ++mandatoryQuesCount
        question.isQuestionAnswered && ++answeredQuesCount
      }
    })
    // let lastPriorityID = _.findLast(this.state.currentQueue, 'Priority').Priority
    if (mandatoryQuesCount) {
      if (mandatoryQuesCount === answeredQuesCount) {
        callBackFunc(priorityQuesIndx)
      }
    } else {
      let hasAtleast1Answered = this.state.currentQueue.find((ques) => ques.Priority === currentQuesPriority && ques.isQuestionAnswered)
      hasAtleast1Answered && callBackFunc(priorityQuesIndx)
    }
  }

  pushNextPriorityQuestions = (nextQuestionsList) => {
    let currentDepIdsList = this.hasDependency(nextQuestionsList)
    if (!currentDepIdsList.quesList.length) {
      let showNextPriority = !!this.state.currentQueue[this.state.currentQueue.length - 1].CanSkipNextPriority
      if (showNextPriority) {
        // No ques of current ques list to be shown over here then move onto nxt priority ques
        let priorityQuesIndx = 0
        _.find(this.props.store.priorityQueue, (pty, ptyIdx) => {
          if (nextQuestionsList[0].Priority === pty[0].Priority) {
            priorityQuesIndx = ptyIdx
          }
        })
        let nxtPtyQues = this.props.store.priorityQueue[++priorityQuesIndx]
        if (nxtPtyQues.length) {
          this.pushNextPriorityQuestions(nxtPtyQues)
        }
      } else {
        this.props.setToaster(getTranslationText('error', 'mandatoryQuestions'), 'error')
      }
    } else {
      let manipualtedQues = _.compact(
        nextQuestionsList &&
          nextQuestionsList.map((ques) => {
            if (currentDepIdsList.quesList.includes(parseInt(ques.QuestionID))) {
              return {
                ...ques,
                Answers: _.compact(
                  ques.Answers.map((list) => {
                    if (currentDepIdsList.answrList.includes(list.QuestionAnswerID)) {
                      return list
                    }
                  })
                ),
              }
            }
          })
      )
      let defAnsQuesID
      this.setState(
        {
          isBuyNowBtnDisabled: false,
          currentQueue: [
            ...this.state.currentQueue,
            ...manipualtedQues.map((ques) => {
              if (ques.Answers.length === 1) {
                defAnsQuesID = ques.QuestionID
              }
              return {
                ...ques,
                isQuestionAnswered: ques.Answers.length === 1,
                Answers: ques.Answers.map((val) => ({
                  ...val,
                  isSelected: ques.Answers.length === 1,
                })),
              }
            }),
          ],
        },
        () => {
          defAnsQuesID && this.checkPriorityHasFulfilled(defAnsQuesID)
          let lastPriorityID = _.findLast(this.state.currentQueue, 'Priority').Priority
          let highestPriority = this.props.store.priorityQueue[this.props.store.priorityQueue.length - 1][0].Priority
          if (lastPriorityID === highestPriority) {
            this.checkMandatoryQuestionsAnswered(highestPriority, () => {
              this.setState({
                isBuyNowBtnDisabled: true,
              })
            })
          }
        }
      )
    }
  }

  hasDependency = (QuesList) => {
    let selectedAnsIds = []
    this.state.currentQueue.map((ques) => {
      ques.Answers.map((value) => {
        if (value.isSelected) {
          selectedAnsIds.push(value.QuestionAnswerID)
        }
      })
    })
    let newQuesDepList = {
      quesList: [],
      answrList: [],
      optQuesList: [],
    }
    QuesList &&
      QuesList.map((ques) => {
        let aList =
          ques.Answers.map((val) => {
            return val.QuestionAnswerID
          }) || [] // ????? why this handling ???
        if (ques.IsOptional) {
          newQuesDepList.optQuesList.push(ques.QuestionID)
        } else {
          newQuesDepList.quesList.push(ques.QuestionID)
        }
        newQuesDepList.answrList.push({
          QuestionID: ques.QuestionID,
          AnswersIDList: aList,
        })
      })
    let currentDepIdsList = {
      quesList: [],
      answrList: [],
    }
    // list of ques which has dependency on un-selected answers
    let hideQuesList = []
    this.props.store.dependencyQueue.map((dpList) => {
      if (selectedAnsIds.includes(parseInt(dpList.QuestionAnswerID))) {
        currentDepIdsList.quesList =
          dpList.QuestionID_List && dpList.QuestionID_List.length
            ? [...currentDepIdsList.quesList, ...dpList.QuestionID_List]
            : [...currentDepIdsList.quesList]
        currentDepIdsList.answrList =
          dpList.QuestionAnswerID_List && dpList.QuestionAnswerID_List.length
            ? [...currentDepIdsList.answrList, ...dpList.QuestionAnswerID_List]
            : [...currentDepIdsList.answrList]
      } else {
        let parsedQuesList = _.map(dpList.QuestionID_List, _.parseInt)
        if (_.intersection(newQuesDepList.quesList, parsedQuesList)) {
          hideQuesList = [...hideQuesList, ..._.intersection(newQuesDepList.quesList, parsedQuesList)]
        }
      }
    })
    currentDepIdsList = {
      quesList: _.map(currentDepIdsList.quesList, _.parseInt),
      answrList: _.map(currentDepIdsList.answrList, _.parseInt),
    }
    let infoTobeShown = {
      quesList: [],
      answrList: [],
    }
    newQuesDepList.answrList.map((value) => {
      if (_.intersection(value.AnswersIDList, currentDepIdsList.answrList).length) {
        infoTobeShown.answrList = [...infoTobeShown.answrList, ..._.intersection(value.AnswersIDList, currentDepIdsList.answrList)]
      } else {
        infoTobeShown.answrList = [...infoTobeShown.answrList, ...value.AnswersIDList]
      }
    })
    let mandatoryQuesList = _.intersection(newQuesDepList.quesList, currentDepIdsList.quesList).length
      ? _.intersection(newQuesDepList.quesList, currentDepIdsList.quesList)
      : []
    infoTobeShown.quesList = newQuesDepList.quesList
      .filter((data) => !mandatoryQuesList.includes(data))
      .filter((ques) => !hideQuesList.includes(ques))
    infoTobeShown.quesList = [...infoTobeShown.quesList, ...mandatoryQuesList]
    // infoTobeShown.quesList = (_.intersection(newQuesDepList.quesList, currentDepIdsList.quesList).length
    //   ? _.intersection(newQuesDepList.quesList, currentDepIdsList.quesList)
    //   : newQuesDepList.quesList
    // )
    // if these set of ques has dependency on some answeres which are not selected then hide those too
    infoTobeShown.quesList = [...infoTobeShown.quesList, ...newQuesDepList.optQuesList]
    return infoTobeShown
  }

  handleTnCSelection = () => {
    this.setState({
      customizeBuytermsConditionState: !this.state.customizeBuytermsConditionState,
    })
  }

  // -------------- create object for `postPaymentAction` for custom plan -------------- //
  findQuesionAnsIdList = (qId, qaId) => {
    let qaIdList = _.chain(this.props.store.dependencyQueue)
      .map((dep) => {
        if (dep.QuestionID_List && parseInt(dep.QuestionAnswerID) === qaId) {
          return _.map(dep.QuestionID_List, _.parseInt)
        }
      })
      .compact()
      .value()

    let qaIdListTemp = [qId, ...qaIdList]
    return qaIdListTemp.length > 1 ? _.flatten(qaIdListTemp) : []
  }

  setCustomPlanPostPaymentActionObject = () => {
    let flattenQuestionList = []
    let questionList = []
    this.state.currentQueue.map((ques) => {
      return ques.Answers.map((ans) => {
        if (ans.isSelected) {
          questionList.push(this.findQuesionAnsIdList(ques.QuestionID, ans.QuestionAnswerID))
        }
      })
    })

    let currQueueQuesList = this.state.currentQueue.map((ques) => {
      return ques.QuestionID
    })

    let quesListClone = questionList
    flattenQuestionList = _.flatten(questionList)
    let nonDependentQuesitons = _.difference(currQueueQuesList, flattenQuestionList)

    quesListClone = quesListClone.filter((ques) => {
      if (ques.length) {
        return quesListClone
      }
    })
    let finalListOfQuestions = _.concat(quesListClone, nonDependentQuesitons)

    let finalObj = finalListOfQuestions.map((flq) => {
      if (typeof flq === 'object') {
        return _.flattenDeep(
          flq.map((qid) => {
            return _.compact(
              this.state.currentQueue.map((ques) => {
                if (qid === ques.QuestionID) {
                  return ques.Answers.filter((ans) => {
                    if (ans.isSelected) {
                      return ans
                    }
                  })
                }
              })
            )
          })
        )
      } else {
        return _.flattenDeep(
          _.compact(
            this.state.currentQueue.map((cql) => {
              if (flq === cql.QuestionID) {
                return cql.Answers.filter((ans) => {
                  if (ans.isSelected) {
                    return ans
                  }
                })
              }
            })
          )
        )
      }
    })
    return finalObj
  }

  // -------------- Buy-Now button Handler -------------- //
  customPlanBuyNowHandler = () => {
    // this.props.setBuyPlanStatusAval(true)
    this.props.buySelectedPlan({}, () => {
      this.props.setCustomPlanPrice(this.calculatePlanPrice().toFixed())
      this.props.setPostPaymentActionObject(this.setCustomPlanPostPaymentActionObject())
      this.props.setCurrentQueueQuestion(this.state.currentQueue)
    })
    // browserHistory.push({
    //   pathname: (this.props.user.isAnGuestUser
    //     ? '/store/signin'
    //     : '/store/checkout'
    //   ),
    //   query: {
    //     ...this.props.location.query,
    //     step: (this.props.user.isAnGuestUser ? undefined : 1)
    //   }
    // })
  }

  calculatePlanPrice = () => {
    let totalPrice = 0
    this.state.currentQueue.length &&
      this.state.currentQueue.map((ques) => {
        ques.Answers.map((ans) => {
          if (ans.isSelected) {
            totalPrice += ans.OptionPrice ? ans.OptionPrice : 0
          }
        })
      })
    return totalPrice
  }

  render() {
    let totalPrice = 0
    let curdate = new Date()
    this.state.currentQueue.length &&
      this.state.currentQueue.map((ques) => {
        ques.Answers.map((ans) => {
          if (ans.isSelected) {
            totalPrice += parseFloat(ans.OptionPrice ? ans.OptionPrice : 0)
          }
        })
      })
    const locale = _.get(this, 'props.user.selectedCountryData.countryConfig.Locale.DEFAULT', 'en')
    const currencyCode = _.get(this, 'props.user.selectedCountryData.CurrencyCode')
    const dateFormat = _.get(this, 'props.user.selectedCountryData.countryConfig.DateFormat.DEFAULT', 'DD/MM/YYYY')
    const language = localStorage.getItem('languageCode')
    return (
      <div className='row'>
        <div className={'custom-plan-flow-qa col-' + (totalPrice ? '9' : '12')}>
          <BoxHolder>
            <div className='bck-btn mb28 mediumFontStyle fontMedium'>
              <Link
                className='special-link-txt'
                to={'/store' + (this.props.location.query.region ? 'region=' + this.props.location.query.region : '')}
                params={{ ...this.props.location.query }}
              >
                <div className='bck-img-container'>
                  <DownArrow width='12' height='12' />
                </div>
              </Link>
              <Link className='special-link-txt inline-ele-Casing' to='/store' params={{ ...this.props.location.query }}>
                <span>{getTranslationText('common', 'backLabel')}</span>
              </Link>
            </div>
            <Heading title={getTranslationText('store', 'customizeText')} />
            <div className='row mt28'>
              <CustomizePlanQAContent
                className='imei-ip-field'
                childNodeClassName='displayFlex'
                data={{
                  title: '1. Check Eligibility of your Oneplus device',
                  footerTextNode: (
                    <div>
                      {!this.props.isCustomPlanEligible ? <InfoIcon className='mr6' /> : ''}
                      {this.props.showCustomPlanEligibilityStatus ? (
                        this.props.isCustomPlanEligible ? (
                          <div className='verified-status bookFontStyle'>{getTranslationText('store', 'IMEIValidated')}</div>
                        ) : (
                          <div className='error-status bookFontStyle'>
                            {this.props.customPlanIMEIError || getTranslationText('store', 'notEligibleLabel')}
                          </div>
                        )
                      ) : (
                        getTranslationText('store', 'enterIMEILabel')
                      )}
                    </div>
                  ),
                }}
              >
                <input
                  placeholder={getTranslationText('store', 'enterIMEI')}
                  id='device-imei'
                  autoComplete='off'
                  value={this.props.CustomPlanIMEIValue}
                  onChange={(e) => this.props.handleCustomPlanEligibilityOnChange(e, 'device-imei')}
                  className='mediumFontStyle'
                />
                <MediumButton
                  buttonText={getTranslationText('common', 'check')}
                  id='imei-check'
                  onClick={this.props.checkCustomizedPlanEligibilityCheck}
                  disabled={this.props.customPlanImeiDisabled}
                />
              </CustomizePlanQAContent>
              {_.get(this, 'props.store.purchaseEligibilityInfo.InputParams.DateOfPurchase', true) &&
                this.props.isCustomPlanEligible &&
                this.props.showDOP && (
                  <CustomizePlanQAContent
                    className='dop-ip-field'
                    childNodeClassName='displayFlex'
                    disableBlock={!!this.props.isDopAvailable}
                    data={{
                      title: '2. Enter Date of purchase for your device',
                    }}
                  >
                    <DatePicker
                      id='device-dop'
                      placeholder={dateFormat}
                      format={dateFormat.toLowerCase()}
                      maxDay={new Date()}
                      minDay={new Date(curdate.getFullYear() - 10, curdate.getMonth(), curdate.getDate())}
                      handleOnChange={(date) => this.props.handleCustomPlanEligibilityOnChange(date, 'device-dop')}
                      date={this.props.customPlanDOPValue || ''}
                      locale={locale}
                      language={language}
                    >
                      <CalendarIcon className='calendar-icon' />
                    </DatePicker>
                    <MediumButton
                      buttonText={getTranslationText('common', 'check')}
                      className='dop-btn'
                      id='dop-check'
                      onClick={this.props.checkCustomizedPlanEligibilityCheck}
                      disabled={this.props.customPlanDOPStatus}
                    />
                  </CustomizePlanQAContent>
                )}
              {this.props.isCustomPlanEligible && this.props.showProductPriceInput && (
                <CustomizePlanQAContent
                  className='prod-price-ip-field'
                  childNodeClassName='displayFlex'
                  disableBlock={!!this.props.isProductPurchasePriceAvailable}
                  data={{
                    title: '3. Product Purchase Price',
                    footerTextNode: '',
                  }}
                >
                  <input
                    placeholder={getTranslationText('store', 'enterPurchasePrice')}
                    id='product-price'
                    autoComplete='off'
                    value={this.props.CustomPlanDevicePrice}
                    onChange={(e) => this.props.handleCustomPlanEligibilityOnChange(e, 'product-price')}
                    className='mediumFontStyle'
                  />
                  <MediumButton
                    buttonText={getTranslationText('common', 'next')}
                    id='prod-price-check'
                    onClick={this.props.checkCustomizedPlanEligibilityCheck}
                    disabled={this.props.customPlanPriceDisabled}
                  />
                </CustomizePlanQAContent>
              )}
              {this.state.currentQueue && this.state.currentQueue.length && this.props.showCustomQuestions
                ? this.state.currentQueue.map((ques, index) => {
                    return (
                      <CustomizePlanQAContent
                        className=''
                        childNodeClassName='displayFlex'
                        key={index}
                        data={{
                          title: index + 4 + '. ' + ques.Title + (!ques.IsOptional ? '' : ' ' + getTranslationText('common', 'optional')),
                        }}
                      >
                        {ques.MultiSelectApplicable ? (
                          <PillGroup
                            handlePillSelect={(e) => this.handlePillSelection(e, ques.QuestionID, true)}
                            prefix={'question-' + ques.QuestionID}
                            pills={ques.Answers}
                            showImg={false}
                          />
                        ) : (
                          <RadioBtnPills
                            prefix={'question-' + ques.QuestionID}
                            noSlots
                            optionList={ques.Answers}
                            onClick={(e) => {
                              this.handlePillSelection(e, ques.QuestionID)
                            }}
                          />
                        )}
                      </CustomizePlanQAContent>
                    )
                  })
                : ''}
            </div>
          </BoxHolder>
        </div>
        {totalPrice ? (
          <div className='col-3 position-fixed plan-container'>
            <BoxHolder className='custom-plan-price'>
              <div className={'heading sentence-casing-uppercase bookFontStyle ' + (window.isDirectionRTL ? ' pr0 ' : ' pl0')}>
                <span className='text-underline boldFontStyle'>{getTranslationText('store', 'proceedToPurchaseLabel')}</span>
              </div>
              <div className='mt20 mb20'>
                <span>{getTranslationText('store', 'totalAmountLabel')} :</span>
                <span
                  className='float-right price-amt'
                  dangerouslySetInnerHTML={{
                    __html: getLocaleCurrency(locale, currencyCode, totalPrice.toFixed()),
                  }}
                />
              </div>
              <hr className='mb8 mt8' />
              <div className='purchase-selectables mb20' id='selectable-ip-2' onClick={this.handleTnCSelection}>
                <Checkbox type='checkbox' value={this.state.termsAndCondCheck} handleOnChange={this.handleTandCClick} />

                <Checkbox type='checkbox' value={this.state.customizeBuytermsConditionState} handleOnChange={this.handleTandCClick} />
                <div className='bookFontStyle fontMedium'>
                  <span onClick={this.handleTnCSelection}>
                    <span className=''>{getTranslationText('store', 'byClickingBuyNow')}&nbsp;</span>
                  </span>
                  <span>
                    <Link
                      className='termsfontColor '
                      target='_blank'
                      to={this.props.store.selectedPlanDetails.planMeta.TermsAndConditionsLink}
                    >
                      <span className=''>{getTranslationText('common', 'termsAndCondition')}</span>
                    </Link>
                    &nbsp;
                    <br />
                    <span
                      dangerouslySetInnerHTML={{
                        __html: _.get(this.props, 'user.appConfigDetails.configLegalParams.consent.Text', ''),
                      }}
                    />
                  </span>
                </div>
              </div>
              <MediumButton
                buttonText={getTranslationText('common', 'buyNowLabel')}
                className='full-width'
                onClick={this.customPlanBuyNowHandler}
                disabled={!(this.state.isBuyNowBtnDisabled && this.state.customizeBuytermsConditionState)}
              />
            </BoxHolder>
          </div>
        ) : (
          ''
        )}
      </div>
    )
  }
}
CustomizedPlanComponent.propTypes = {
  isCustomPlanEligible: PropTypes.bool.isRequired,
  showCustomPlanEligibilityStatus: PropTypes.bool,
  customPlanIMEIError: PropTypes.string,
  customPlanImeiDisabled: PropTypes.bool,
  handleCustomPlanEligibilityOnChange: PropTypes.func.isRequired,
  checkCustomizedPlanEligibilityCheck: PropTypes.func.isRequired,
  showDOP: PropTypes.bool,
  customPlanDOPValue: PropTypes.string,
  showCustomQuestions: PropTypes.bool.isRequired,
  customPlanDOPStatus: PropTypes.bool,
  CustomPlanIMEIValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  location: PropTypes.object.isRequired,
  // user: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
  // setBuyPlanStatusAval: PropTypes.func,
  setCustomPlanPrice: PropTypes.func,
  setPostPaymentActionObject: PropTypes.func,
  setCurrentQueueQuestion: PropTypes.func,
  setToaster: PropTypes.func.isRequired,
  buySelectedPlan: PropTypes.func.isRequired,
  addCustomPlanProductPrice: PropTypes.func.isRequired,
  addCustomPlanDOP: PropTypes.func.isRequired,
  resetPlanEligibilityChecks: PropTypes.func.isRequired,
  showProductPriceInput: PropTypes.any.isRequired,
  CustomPlanDevicePrice: PropTypes.any.isRequired,
  customPlanPriceDisabled: PropTypes.bool.isRequired,
  isDopAvailable: PropTypes.bool,
  isProductPurchasePriceAvailable: PropTypes.bool,
}

export const CustomizePlanQAContent = (props) => {
  return (
    <div className={'col-12 mb28 ' + (props.className || '')}>
      <div className='mb8'>
        {props.data.title}
        <div className='title-sub-text fontSmall'>{props.data.subTextNode}</div>
      </div>
      <div className={props.childNodeClassName + (props.disableBlock ? ' disable-Block' : '')}>{props.children}</div>
      <div className='footer-sub-txt fontSmall'>{props.data.footerTextNode}</div>
    </div>
  )
}
CustomizePlanQAContent.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
  disableBlock: PropTypes.bool,
  children: PropTypes.node.isRequired,
  childNodeClassName: PropTypes.string,
}
export const AddressesComponent = (props) => {
  let header = ''
  const [showPaymentDeclPopup, setShowPaymentDeclPopup] = useState(false)
  let paymentDeclConsentText = ''
  let cc = _.get(props, 'user.selectedCountryData.CountryCode', 'other').toLowerCase()
  if (!legalConfigCodes[cc]) cc = 'other'
  let has_gst_consent = hasConsent(_.get(props, 'user.appLegalConfigs.LegalConfigs', []), legalConfigCodes[cc].buy_tnc_gst_credit_na)
  const consents = getLegalConsents(_.get(props, 'user.appLegalConfigs.LegalConfigs', []), [
    has_gst_consent ? legalConfigCodes[cc].buy_tnc_gst_credit_na : legalConfigCodes[cc].buy_tnc,
    legalConfigCodes[cc].data,
    legalConfigCodes[cc].tnc_insurance,
    legalConfigCodes[cc].payment_decl,
    legalConfigCodes[cc].payment_decl2,
  ])
  // setConsents(consents_)
  if (!legalConfigCodes[cc]) cc = 'other'
  if (
    (consents.length && hasConsent(_.get(props, 'user.appLegalConfigs.LegalConfigs', []), legalConfigCodes[cc].payment_decl)) ||
    hasConsent(_.get(props, 'user.appLegalConfigs.LegalConfigs', []), legalConfigCodes[cc].payment_decl2)
  ) {
    let consent = _.get(props, 'user.appLegalConfigs.LegalConfigs', []).find(
      (cfg) =>
        cfg.legalConfig &&
        (cfg.legalConfig.LegalConfigCode === legalConfigCodes[cc].payment_decl ||
          cfg.legalConfig.LegalConfigCode === legalConfigCodes[cc].payment_decl2)
    )
    paymentDeclConsentText = consent && consent.legalConfig ? consent.legalConfig.Text : ''
  }
  if (props.isEdit) {
    header = props.isNewPlanSalesFlow ? (
      <span className='boldFontStyle fontLarge'>{getTranslationText('profile', 'editAddress')}</span>
    ) : (
      <Heading wordBreak title={getTranslationText('profile', 'editAddress')} />
    )
  } else if (props.isCreate) {
    header = props.isNewPlanSalesFlow ? (
      <span className='boldFontStyle fontLarge'>{getTranslationText('profile', 'addAddress')}</span>
    ) : (
      <Heading wordBreak title={getTranslationText('profile', 'addAddress')} />
    )
  } else {
    header = props.isNewPlanSalesFlow ? (
      <span className='boldFontStyle fontLarge'>{getTranslationText('store', 'selectYourAddress')}</span>
    ) : (
      <Heading wordBreak title={getTranslationText('profile', 'savedAddress')} />
    )
  }
  const handleRedirection = (type, customLink) => {
    let skipRedirection = _.get(props, 'user.appConfigDetails.SkipRedirection', false)
    let link = customLink
    if (type === 'tnc') {
      link = _.get(props, 'store.selectedPlanDetails.planMeta.TermsAndConditionsLink') || props.user.appConfigDetails.Terms
      skipRedirection && props.setTncLink(link)
    } else if (type === 'privacy') {
      link = props.user.appConfigDetails.Privacy
      skipRedirection && props.setPrivacyLink(link)
    } else if (type === 'data') {
      link = props.user.appConfigDetails.ConsentURL
      skipRedirection && props.setDataConsentLink(link)
    }
    if (skipRedirection) {
      link = customLink
    }
    return link
  }
  const handleIPIDRedirection = (e) => {
    e.preventDefault()
    window.open(_.get(props, 'store.ipidUrl.DocumentURL'))
  }
  const handleOtherRelatedDocsRedirection = (e) => {
    e.preventDefault()
    window.open(_.get(props, 'store.otherRelatedDocsUrl.DocumentURL'))
  }
  const proceedForPaymentOptions = () => {
    props.setShowAddress(false)
    // If only one payment option pre-select and go to payment page else wait for user to select a payment option
    if (
      _.get(props, 'store.availablePaymentOptions', []).length <= 1 &&
      Object.keys(_.get(props, 'store.selectedPaymentOption', {})).length > 1
    ) {
      props.handleCheckoutNavigation()
    }
    if (props.isNewPlanSalesFlow) props.checkIfPaymentRequiredHandling(null, false)
    else if (_.get(props, 'store.purchaseEligibilityInfo.PlanDiscounts.PlanDiscountOffers', []).length <= 0) {
      props.setShowPaymentMode(true)
    } else {
      props.checkIfPaymentRequiredHandling(null, false)
    }
  }
  const proceedAddressConfirmation = () => {
    if (props.isNewPlanSalesFlow) {
      if (
        (consents.length && hasConsent(_.get(props, 'user.appLegalConfigs.LegalConfigs', []), legalConfigCodes[cc].payment_decl)) ||
        hasConsent(_.get(props, 'user.appLegalConfigs.LegalConfigs', []), legalConfigCodes[cc].payment_decl2)
      ) {
        let countryName = _.get(props, 'user.selectedCountryData.CountryName', '')
        let getLink = (link) => {
          if (link === 'tnc') return _.get(props, 'user.tncLink', planTAndCLink)
          if (link === 'privacy') return _.get(props, 'user.privacyLink', privacyLink)
          if (link === 'dataconsentstore') return _.get(props, 'user.dataLink', dataLink)
          if (link === 'insurancecard' || link === 'ipid') return _.get(props, 'store.ipidUrl.DocumentURL', '')
          if (link === 'relateddocuments') return _.get(props, 'store.otherRelatedDocsUrl.DocumentURL', '')
          if (link === 'country') return getTranslationText('common', 'localized' + countryName.replaceAll(' ', '')) || countryName
        }
        console.log('consents - ', consents)
        let consents_all = consents
        if (paymentDeclConsentText) {
          consents_all.push(
            _.get(props, 'user.appLegalConfigs.LegalConfigs', []).find(
              (cfg) =>
                cfg.legalConfig &&
                (cfg.legalConfig.LegalConfigCode === legalConfigCodes[cc].payment_decl ||
                  cfg.legalConfig.LegalConfigCode === legalConfigCodes[cc].payment_decl2)
            )
          )
        }
        let consentsTemp = consents_all.map((consent) => {
          let attributes = []
          let phs = getPlaceholders(consent.legalConfig.Text)
          let phs_unique = [...new Set(phs)]
          phs_unique.length &&
            phs_unique.map((ph) => {
              attributes.push({ AttributeType: legalConfigAttributes[ph], AttributeValue: getLink(ph) })
            })
          return _.get(props, 'user.userDetails.consumerID', false)
            ? {
                ApplicationLegalConfigID: consent.ApplicationLegalConfigID,
                ConsentText: replacePlaceholders(consent.legalConfig.Text, _.get(props, 'user.selectedCountryData.CountryName', '')),
                Attributes: attributes,
                ConsumerID: _.get(props, 'user.userDetails.consumerID'),
              }
            : {
                ApplicationLegalConfigID: consent.ApplicationLegalConfigID,
                ConsentText: replacePlaceholders(consent.legalConfig.Text, _.get(props, 'user.selectedCountryData.CountryName', '')),
                Attributes: attributes,
                MobileNo: _.get(props, 'store.userData.mobile', undefined),
                EmailID: _.get(props, 'store.userData.email', undefined),
              }
        })
        props.logConsentsAsync({ ConsentArray: consentsTemp })
        pushToAnalytics('Plan Sales - Tnc Agreed', {
          ConsumerID: _.get(props, 'user.consumerDetails.data.consumerID', undefined),
          Plan_Name: _.get(props, 'store.selectedPlanDetails.PlanName', undefined),
          IMEI_checked_on_storepage: !!_.get(props, 'store.imeiForFilter', false),
          eureka: !!_.get(props, 'store.imeiFromToken', false),
          'Logged In': _.get(props, 'user.consumerDetails.data.consumerID', false) ? 'Yes' : 'No',
          ProductName: _.get(props, 'store.purchaseEligibilityInfo.ProductName', ''),
        })
      }
    }
    if (!props.isEdit && !props.isCreate) props.setConsumerAddress(props.locations.find((location) => location.isSelected))
    if (!_.get(props, 'user.userDetails.consumerID', false)) {
      props.setglobalLoaderStatus(true)
      props.checkPlanEligibility(
        '',
        '',
        () => {
          props.setglobalLoaderStatus(false)
          if (_.get(props, 'store.purchaseEligibilityInfo.IsPurchasedAllowed', false)) proceedForPaymentOptions()
          else props.setToaster(getTranslationText('error', 'somethingWentWrong'), 'error')
        },
        () => {
          props.setglobalLoaderStatus(false)
          props.setToaster(getTranslationText('error', 'somethingWentWrong'), 'error')
        }
      )
      if (_.get(props, 'store.purchaseEligibilityInfo.IsPurchasedAllowed', false)) setShowPaymentDeclPopup(true)
      else props.setToaster(getTranslationText('error', 'somethingWentWrong'), 'error')
    } else proceedForPaymentOptions()
  }

  const handleAddressConfirmation = async () => {
    if (props.isNewPlanSalesFlow) {
      pushToAnalytics('Plan Sales - Address Confirmed', {
        ConsumerID: _.get(props, 'user.consumerDetails.data.consumerID', undefined),
        Plan_Name: _.get(props, 'store.selectedPlanDetails.PlanName', undefined),
        IMEI_checked_on_storepage: !!_.get(props, 'store.imeiForFilter', false),
        eureka: !!_.get(props, 'store.imeiFromToken', false),
        'Logged In': _.get(props, 'user.consumerDetails.data.consumerID', false) ? 'Yes' : 'No',
        ProductName: _.get(props, 'store.purchaseEligibilityInfo.ProductName', ''),
      })
      if (
        (consents.length && hasConsent(_.get(props, 'user.appLegalConfigs.LegalConfigs', []), legalConfigCodes[cc].payment_decl)) ||
        hasConsent(_.get(props, 'user.appLegalConfigs.LegalConfigs', []), legalConfigCodes[cc].payment_decl2)
      ) {
        setShowPaymentDeclPopup(true)
      } else {
        proceedAddressConfirmation()
      }
    } else proceedAddressConfirmation()
  }
  const planTAndCLink = handleRedirection('tnc', '/terms-conditions')
  const privacyLink = handleRedirection('privacy', '/privacy-policy')
  const dataLink = handleRedirection('data', '/online-consent')
  let linksData = {
    tnc: { label: 'planTermsAndCondition', link: planTAndCLink },
    privacy: { label: 'privacyPolicy', link: privacyLink },
    dataconsentstore: { label: 'dataConsentStore', link: dataLink },
  }
  const addNewAddressCallback = () => {
    handleAddressConfirmation()
  }
  let currentStep = 1
  let progressBarItems = ['', '', '']
  if (_.get(props, 'store.imeiForFilter', false) && _.get(props, 'user.userDetails.consumerID', false)) {
    currentStep = 0
    progressBarItems = ['', '']
  }
  return (
    <div className='col-sm-4'>
      {props.isNewPlanSalesFlow && (
        <div className={`dot-progress-bar col-sm-12`}>
          <DotProgressBar currentIndex={currentStep} progressBarItems={progressBarItems} showEveryStepCount={true} />
        </div>
      )}
      <BoxHolder className='box-holder-right'>
        <div className='row mb20'>
          <div className={'col-6'}>{header}</div>
          {!props.isEdit && !props.isCreate ? (
            <div className={'col-6 ' + (window.isDirectionRTL ? ' text-left ' : ' text-right')}>
              <div
                className={
                  'boldFontStyle fontSmall' +
                  (props.loaderState ? ' loader-cursor ' : '') +
                  (props.isNewPlanSalesFlow ? ' special-link-txt-main-color' : ' special-link-txt')
                }
                onClick={() => {
                  !props.loaderState && props.handleAddressClick()
                }}
              >
                + {getTranslationText('profile', 'addNewAddress')}
              </div>
            </div>
          ) : (
            ''
          )}
          {!props.isEdit && !props.isCreate ? (
            <div className='col-sm-12 address-view mt20'>
              <div className='address-list row'>
                {props.locations.map((location) => {
                  let addressConfig = _.get(props, 'user.countryAddressConfig.addressConfig', fallbackAddressConfig)
                  addressConfig = props.sortedAddressConfig(addressConfig)
                  let array_1 = []
                  let isLandmark = !!location['Landmark']
                  let isUserLandmark = !!location['UserLandmark']
                  addressConfig.map((definedConifg) => {
                    Object.keys(location).map((addressValue) => {
                      if (definedConifg['keyName'] == addressValue) {
                        if (!!location[addressValue] && !array_1.includes(addressValue)) {
                          array_1.push(location[addressValue])
                        }
                      }
                    })
                  })
                  if (isLandmark && isUserLandmark) {
                    array_1.splice(0, 1)
                  }
                  return (
                    <RadioBox
                      key={location.ConsumerFavouriteLocationID}
                      className={{ container: `payment-mode-radio` }}
                      isSelected={location.isSelected}
                      handleOnClick={(e) => !props.loaderState && props.handleAddressClick(e, location.ConsumerFavouriteLocationID)}
                    >
                      <SubHeading className='boldFontStyle overrideCasing' title={location.AddressType} />
                      <span className='bookFontStyle'>
                        {array_1 &&
                          array_1.length &&
                          array_1.map((v, i) => {
                            return v + (array_1.length !== i + 1 ? ', ' : '.')
                          })}
                      </span>
                    </RadioBox>
                  )
                })}
                <MediumButton
                  buttonText={getTranslationText('common', 'continue')}
                  onClick={handleAddressConfirmation}
                  disabled={!props.locations.some((loc) => loc.isSelected)}
                  className='payment-mode-btn mt20'
                />
              </div>
            </div>
          ) : (
            ''
          )}

          {props.isEdit || props.isCreate ? <CreateAddressComponent {...props} addNewAddressCallback={addNewAddressCallback} /> : ''}
        </div>
      </BoxHolder>
      <Modal className='sfy-modal successfully-added-device-content consent-modal' showModal={showPaymentDeclPopup}>
        <CustomIconComponent
          className={'close-icon ' + (window.isDirectionRTL ? 'appear-left text-left ' : 'appear-right text-right')}
          onClick={() => {
            setShowPaymentDeclPopup(false)
          }}
        />
        <div className={`${props.isNewPlanSalesFlow ? 'mb20' : ''} text-center`}>
          <div className='bookFontStyle fontExtraLarge mt8'>{getTranslationText('common', 'declaration')}</div>
        </div>
        <div className={`text-left ${props.isNewPlanSalesFlow ? 'mb8 pl20 pr20' : ''}`}>
          {getConsentTextChunks(paymentDeclConsentText).length > 0 ? (
            getConsentTextChunks(paymentDeclConsentText).map((chunk, key) => {
              if (chunk[0] === '@' && chunk[chunk.length - 1] === '@') {
                let link = chunk.replace(/\@/g, '')
                if (link === 'insurancecard') {
                  return (
                    <Link key={key} className='termsfontColor' to={{}} onClick={handleIPIDRedirection}>
                      {getTranslationText('store', 'insuranceCard')}
                    </Link>
                  )
                }
                if (link === 'relateddocuments') {
                  return (
                    <Link key={key} className='termsfontColor' to={{}} onClick={handleOtherRelatedDocsRedirection}>
                      {getTranslationText('store', 'relateddocuments')}
                    </Link>
                  )
                }
                if (link === 'country') {
                  return (
                    <span key={key}>
                      {getTranslationText(
                        'common',
                        'localized' + _.get(props, 'user.selectedCountryData.CountryName', '').replaceAll(' ', '')
                      ) || _.get(props, 'user.selectedCountryData.CountryName', '')}
                    </span>
                  )
                }
                return (
                  <Link
                    key={key}
                    className='termsfontColor'
                    target='_blank'
                    to={linksData[link].link}
                    onClick={() => {
                      handleRedirection(link, linksData[link].link)
                    }}
                  >
                    {getTranslationText('common', linksData[link].label)}
                  </Link>
                )
              }
              return <span key={chunk} dangerouslySetInnerHTML={{ __html: chunk.replace(/\n/g, '<br />') }} />
            })
          ) : (
            <div
              className='bookFontStyle fontSmall mt8'
              dangerouslySetInnerHTML={{ __html: paymentDeclConsentText.replace(/\n/g, '<br />') }}
            />
          )}
        </div>
        <div className={`text-center ${props.isNewPlanSalesFlow ? 'mb8 pt20' : ''}`}>
          <MediumButton
            className='fontLarge mt8'
            onClick={() => {
              setShowPaymentDeclPopup(false)
              proceedAddressConfirmation()
            }}
            buttonText={getTranslationText('store', 'acceptAndContinue')}
          />
        </div>
      </Modal>
    </div>
  )
}

AddressesComponent.propTypes = {
  locations: PropTypes.array,
  handleAddressClick: PropTypes.func.isRequired,
  isEdit: PropTypes.any,
  isCreate: PropTypes.bool,
  loaderState: PropTypes.bool,
}

AddressesComponent.defaultProps = {
  locations: [],
  isEdit: '',
}
export const CreateAddressComponent = (props) => {
  let { newAddressDetails } = props
  let selectedAddress = props.isEdit
    ? props.locations.filter((address) => address.ConsumerFavouriteLocationID === parseInt(props.isEdit))[0]
    : newAddressDetails
  const AddressType = _.get(selectedAddress, 'AddressType', '')
  const pinCodeValue = _.get(selectedAddress, 'PinCode', '')
  const cityValue = _.get(selectedAddress, 'City', '')
  const stateValue = _.get(selectedAddress, 'State', '')
  const userLandmarkValue = _.get(selectedAddress, 'UserLandmark', '')
  const addressValue = _.get(selectedAddress, 'Address', '')

  let isAddressValueRequire = true
  let isCityValueRequire = true
  let isStateValueRequire = true
  let isLandmarkValueRequire = _.get(selectedAddress, 'Landmark', '').length > 0
  let isUserLandMarkValueRequire = true
  let isPincodeValueRequire = true
  let pincodeLengthCheck = props.pincodeLengthAr

  let addressConfig = _.get(props, 'user.countryAddressConfig.addressConfig', fallbackAddressConfig)
  let sortedAddressConfig = props.sortedAddressConfig(addressConfig)
  let districtAddressListConfig_ = _.get(props, 'user.districtAddressListConfig', [])
  return (
    <div className='address-create'>
      <div className='col-sm-12 mb20'>
        <AddressBox
          handleTypeaheadSelect={props.handleTypeaheadSelect}
          placesAutocomplete={props.placesAutocomplete}
          onChangeTypeahead={props.onChangeTypeahead}
          user={props.user}
        />
      </div>
      {sortedAddressConfig &&
        sortedAddressConfig.length &&
        sortedAddressConfig.map((element) => {
          switch (element.keyName) {
            case 'Zipcode':
            case 'PinCode': {
              if (element.display) {
                pincodeLengthCheck = element['length'].some((i) => [pinCodeValue.length].includes(i))
                // UK pincodes have empty space in between. so check lengh removing that
                if (!pincodeLengthCheck)
                  pincodeLengthCheck = element['length'].some((i) => [pinCodeValue.replaceAll(' ', '').length].includes(i))
                isPincodeValueRequire = element['mandatory'] ? !!pincodeLengthCheck : pinCodeValue.length ? !!pincodeLengthCheck : true
                return (
                  element.display && (
                    <Input
                      inputType='text'
                      value={pinCodeValue}
                      className={{
                        formControl: 'col-sm-12 mb20',
                        input: 'input-text book',
                        label: 'address-label bookFontStyle overrideCasing',
                      }}
                      label={`${element.label} ${element.mandatory ? '' : getTranslationText('common', 'optional')}`}
                      handleOnChange={props.onChangeAddressInput}
                      name='PinCode'
                      isDisabled={props.isPincodeInputDisabled}
                    />
                  )
                )
              }
            }
            case 'Address':
              isAddressValueRequire = element['mandatory'] ? !!addressValue?.trim() : true
              return (
                element.display && (
                  <div className='col-sm-12 mb20'>
                    <span className={`fontSmall bookFontStyle char-length-checker ` + (window.isDirectionRTL ? ' text-left-align10' : '')}>
                      {`(${(!!_.get(selectedAddress, 'Address', '') && _.get(selectedAddress, 'Address', '').length) || 0} / ${
                        element.inputLength || 50
                      })`}
                    </span>
                    <Input
                      inputType='text'
                      value={addressValue}
                      className={{
                        input: 'input-text bookFontStyle',
                        label: 'address-label bookFontStyle overrideCasing',
                      }}
                      label={`${element.label} ${element.mandatory ? '' : getTranslationText('common', 'optional')}`}
                      handleOnChange={props.onChangeAddressInput}
                      name='Address'
                    />
                  </div>
                )
              )
            case 'State':
              isStateValueRequire = element['mandatory'] ? !!stateValue?.trim() : true
              return (
                element.display && (
                  <div className='col-sm-12 mb20'>
                    <div className='address-label bookFontStyle'>
                      <span>{element.label}</span>
                      {element.mandatory ? '' : ' ' + getTranslationText('common', 'optional')}
                    </div>
                    <Dropdown
                      id='state-address-dropdown'
                      value={stateValue}
                      options={props.user.stateAddressListConfig.map((state) => ({
                        ...state,
                        label: state.State,
                        value: state.State,
                      }))}
                      placeholder={getTranslationText('store', 'selectStateLabel')}
                      handleOnChange={props.onChangeStateSelection}
                      filterBy='value'
                      searchText='Search'
                      classObject={{ container: 'mb0' }}
                      isDisabled={props.isStateInputDisabled}
                    />
                  </div>
                )
              )
            case 'City':
              isCityValueRequire = element['mandatory'] ? !!cityValue?.trim() : true
              return (
                element.display && (
                  <Input
                    inputType='text'
                    value={cityValue}
                    name='City'
                    handleOnChange={props.onChangeAddressInput}
                    className={{
                      formControl: 'col-sm-12 mb20',
                      inputContainer: 'input-text bookFontStyle',
                      label: 'address-label bookFontStyle',
                    }}
                    label={`${element.label} ${element.mandatory ? '' : getTranslationText('common', 'optional')}`}
                    isDisabled={props.isCityInputDisabled}
                  />
                )
              )
            case 'UserLandmark':
              isUserLandMarkValueRequire = element['mandatory'] ? !!userLandmarkValue?.trim() : true
              return (
                element.display && (
                  <div className='col-sm-12 mb20'>
                    <span className={`fontSmall bookFontStyle char-length-checker ` + (window.isDirectionRTL ? ' text-left-align10' : '')}>
                      {`(${(!!_.get(selectedAddress, 'UserLandmark', '') && _.get(selectedAddress, 'UserLandmark', '').length) || 0} / ${
                        element.inputLength || 50
                      })`}
                    </span>
                    <Input
                      inputType='text'
                      value={userLandmarkValue}
                      name='UserLandmark'
                      handleOnChange={props.onChangeAddressInput}
                      className={{
                        inputContainer: 'input-text bookFontStyle',
                        label: 'address-label bookFontStyle',
                      }}
                      label={`${element.label} ${element.mandatory ? '' : getTranslationText('common', 'optional')}`}
                    />
                  </div>
                )
              )
            case 'District':
              isStateValueRequire = element['mandatory'] ? !!stateValue : true
              return (
                element.display && (
                  <div className='col-sm-12 mb20'>
                    <div className='address-label bookFontStyle'>
                      <span>{element.label}</span>
                      {element.mandatory ? '' : ' ' + getTranslationText('common', 'optional')}
                    </div>
                    <Dropdown
                      value={!_.isEmpty(props.selectedDistrict) ? props.selectedDistrict.value : ''}
                      options={districtAddressListConfig_.map((state) => ({
                        ...state,
                        label: state.DistrictID,
                        value: state.DistrictName,
                      }))}
                      placeholder={getTranslationText('store', 'selectDistrictLabel')}
                      handleOnChange={props.handleDistrictSelection}
                      filterBy='value'
                      searchText='Search'
                      isDisabled={props.user.districtAddressListConfig && !props.user.districtAddressListConfig.length}
                    />
                  </div>
                )
              )
          }
        })}

      <div className='col-sm-12 pb20'>
        <div className='address-label bookFontStyle mb8 overrideCasing'>
          <span>{getTranslationText('profile', 'addressType')}</span>
        </div>
        <div className='pills-holder'>
          <PillGroup
            prefix='addressType'
            showImg
            pills={props.addressTypesList}
            newFlow={props.isNewPlanSalesFlow}
            handlePillSelect={props.toggleAddressPillSelection}
          />
        </div>
      </div>

      <div className='row address-actions-btns pt4'>
        {props.locations.length > 0 && (
          <MediumButton
            className='button-small button-ghost mr8'
            onClick={props.cancel}
            buttonText={getTranslationText('common', 'cancel')}
          />
        )}
        <MediumButton
          className={'button-small mr8 ' + (props.locations.length > 0 ? 'save-button' : 'payment-mode-btn')}
          onClick={async () => {
            await props.saveAddress()
            props.addNewAddressCallback()
          }}
          buttonText={props.isNewPlanSalesFlow ? getTranslationText('profile', 'addAddress') : getTranslationText('common', 'save')}
          disabled={
            !props.allowSave ||
            !AddressType ||
            !isAddressValueRequire ||
            !isCityValueRequire ||
            !isStateValueRequire ||
            !isLandmarkValueRequire ||
            !isUserLandMarkValueRequire ||
            !isPincodeValueRequire
          }
        />
        {props.isEdit ? (
          <MediumButton
            className='button-small button-ghost mr8 delete-btn'
            buttonText={getTranslationText('profile', 'delete')}
            onClick={props.toggleConfirmationPopUp}
          />
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

CreateAddressComponent.propTypes = {
  handleTypeaheadSelect: PropTypes.func.isRequired,
  onChangeTypeahead: PropTypes.func.isRequired,
  onChangeAddressInput: PropTypes.func.isRequired,
  onChangeStateSelection: PropTypes.func.isRequired,
  selectedState: PropTypes.any,
  cancel: PropTypes.func.isRequired,
  toggleConfirmationPopUp: PropTypes.func.isRequired,
  saveAddress: PropTypes.func.isRequired,
  placesAutocomplete: PropTypes.object,
  isEdit: PropTypes.any,
  locations: PropTypes.array,
  newAddressDetails: PropTypes.object,
  allowSave: PropTypes.bool,
  addressTypesList: PropTypes.array,
  toggleAddressPillSelection: PropTypes.func,
  pincodeLengthAr: PropTypes.array.isRequired,
}

CreateAddressComponent.defaultProps = {
  newAddressDetails: {
    Address: '',
    AddressType: '',
    PinCode: '',
    City: '',
    State: '',
  },
  allowSave: false,
}
