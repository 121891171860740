import { combineReducers } from 'redux'
import locationReducer from './location'
import userReducer from './user'
import vouchersReducer from './vouchers'
import devicesReducer from './devices'
import requestReducer from './request'
import trackReducer from './track'
import storeReducer from './storeReducer'
import subscriptionReducer from './subscription'
import myPlanReducer from './myPlansReducer'
import paymentReducer from './payment'
import languageReducer from './languageTranslation'
import toastsAndLoadersReducer from './toastsAndLoaders'

const initState = {
  user: {},
  devices: {},
  request: {},
  store: {},
  subscription: {},
  myPlans: {},
  payment: {},
  track: {},
  languageDetail: {
    translationText: {},
    languageCode: null,
  },
}

const appReducer = combineReducers({
  location: locationReducer,
  user: userReducer,
  vouchers: vouchersReducer,
  devices: devicesReducer,
  request: requestReducer,
  store: storeReducer,
  subscription: subscriptionReducer,
  myPlans: myPlanReducer,
  payment: paymentReducer,
  toastsAndLoaders: toastsAndLoadersReducer,
  track: trackReducer,
  languageDetail: languageReducer,
})

const rootReducer = (state, action) => {
  if (action.type === 'CLEAR_USER_DETAILS') {
    state = {
      ...initState,
      toastsAndLoaders: state.toastsAndLoaders,
    }
  }
  return appReducer(state, action)
}

const makeRootReducer = () => {
  return rootReducer
}

export default makeRootReducer
