import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './ReusableComponents.scss'
import { Typeahead } from 'react-bootstrap-typeahead'
import { Link } from 'react-router'
import { PillButton, MediumButton, InputComponent } from '..'
import { Modal, Radio } from 'sfy-react'

import { getTranslationText } from '../../../utils/languageTranslation.js'
import _ from 'lodash'
import { requestTypesList } from '../../../utils/constants'
import { ReactComponent as EditIcon } from '../../../../public/commonAssets/Edit.svg'
import { ReactComponent as Phone } from '../../../../public/commonAssets/phone.svg'
import { ReactComponent as CancelIcon } from '../../../../public/commonAssets/cancel.svg'
import { ReactComponent as AddIcon } from '../../../../public/commonAssets/add-plus.svg'
import { ReactComponent as RightArrow } from '../../../../public/commonAssets/right-arrow.svg'

export const CustomIconComponent = (props) => {
  if (props.iconTag && props.iconTag.toLowerCase() === 'edit') {
    return (
      <EditIcon className={'defaultIconclass ' + props.className} id={props.id} onClick={props.onClick} name={props.name} alt={props.alt} />
    )
  } else {
    return <CancelIcon className={props.className} alt={props.alt} id={props.id} onClick={props.onClick} name={props.name} />
  }
}
CustomIconComponent.propTypes = {
  className: PropTypes.string.isRequired,
  alt: PropTypes.string,
  onClick: PropTypes.func,
  iconTag: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export class LoginSubHeading extends Component {
  render() {
    return (
      <div className={'login-subheading mediumFontStyle'}>
        <span className='inline-ele-Casing'>
          <span>{this.props.title}</span>
        </span>
      </div>
    )
  }

  static propTypes = {
    title: PropTypes.string.isRequired,
  }
}

export class FooterHeading extends Component {
  render() {
    // let color = (this.props.color && this.props.color == 'white') ? 'white-color' : '';
    return (
      <div className='footerheading boldFontStyle'>
        <span>{this.props.title}</span>
      </div>
    )
  }

  static propTypes = {
    title: PropTypes.string.isRequired,
  }
}

export class FooterText extends Component {
  render() {
    // let color = (this.props.color && this.props.color == 'white') ? 'white-color' : '';
    return (
      <div className={'footertext bookFontStyle ' + this.props.className}>
        <span>{this.props.text}</span>
      </div>
    )
  }

  static propTypes = {
    text: PropTypes.string.isRequired,
    className: PropTypes.string,
  }
}

export class FooterMiniHeading extends Component {
  render() {
    // let color = (this.props.color && this.props.color == 'white') ? 'white-color' : '';
    return (
      <div className={'padding-bottom boldFontStyle fontLarge inline-ele-Casing ' + this.props.className}>
        <span>{this.props.title}</span>
      </div>
    )
  }

  static propTypes = {
    title: PropTypes.string.isRequired,
    className: PropTypes.string,
  }
}

export class Heading extends Component {
  render() {
    // let color = (this.props.color && this.props.color == 'white') ? 'white-color' : '';
    return (
      <div className={'heading bookFontStyle ' + this.props.className + (window.isDirectionRTL ? ' pr0 ' : ' pl0')}>
        {this.props.wordBreak ? <div>{this.props.title}</div> : <span dangerouslySetInnerHTML={{ __html: this.props.title }} />}
      </div>
    )
  }

  static propTypes = {
    title: PropTypes.string,
    wordBreak: PropTypes.bool,
    className: PropTypes.string,
  }
}

export const SubHeading = (props) => {
  return (
    <div className={'subheading ' + (props.className ? props.className : '')}>
      <span>{props.title}</span>
    </div>
  )
}

SubHeading.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
}

export const PurpleLink = (props) => {
  let button = ''
  if (props.link) {
    button = (
      <Link className='purple-link' to={props.link}>
        {props.title}
      </Link>
    )
  } else {
    button = (
      <div className='purple-link' onClick={props.onClick}>
        {props.title}
      </div>
    )
  }
  return button
}

PurpleLink.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string,
  onClick: PropTypes.func,
}

export const BoxHolder = (props) => {
  return <div className={'box-holder ' + props.className}>{props.children}</div>
}

BoxHolder.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

BoxHolder.defaultProps = {
  className: '',
}

export const PillGroup = (props) => {
  return (
    <div className='pill-group'>
      {props.pills.map((pill, index) => {
        let flg = false
        if ((!pill.IsActive && pill.IsActive !== undefined) || (!pill.Active && pill.Active !== undefined)) {
          flg = true
        }
        return (
          <PillButton
            key={index}
            dir={props.prefix === 'timepill' ? 'ltr' : ''}
            id={props.prefix + '-' + (props.useID ? pill.id : index)}
            onClick={props.handlePillSelect}
            buttonText={pill.text}
            className={'pillbtn ' + (pill.isSelected ? ' pillbtn-active' : '') + (props.newFlow ? ' pill-btn-new' : '')}
            disabled={props.disabled || pill.status || flg}
            showImg={props.showImg}
            imgSource={pill.isSelected ? pill.activeImg : pill.deactiveImg}
            rtlStyle={
              window.isDirectionRTL
                ? {
                    'margin-left': '15px',
                    'margin-right': 0,
                  }
                : {}
            }
          />
        )
      })}
    </div>
  )
}

PillGroup.propTypes = {
  pills: PropTypes.array.isRequired,
  prefix: PropTypes.string.isRequired,
  handlePillSelect: PropTypes.func.isRequired,
  showImg: PropTypes.bool,
  disabled: PropTypes.bool,
  useID: PropTypes.bool,
}

PillGroup.defaultProps = {
  pills: [],
  prefix: 'pill',
}

export const HorizontalBar = () => {
  return <hr className='horizontal-bar' />
}

export const HighlightedContent = (props) => {
  return (
    <span className={props.className} style={{ color: props.colorcode }}>
      {' '}
      {props.value}
    </span>
  )
}
HighlightedContent.propTypes = {
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
  colorcode: PropTypes.string,
}

export class ListTypeAhead extends React.Component {
  render() {
    return (
      <div>
        <Typeahead
          id={this.props.labelKey}
          className={this.props.className + ' mt4 mb8 list-type-ahead '}
          autoFocus={this.props.autoFocus}
          ref={this.props.refVal}
          labelKey={this.props.labelKey}
          options={this.props.datalist}
          placeholder={this.props.placeholder}
          disabled={!this.props.isEditable}
          onChange={this.props.onChange}
          selected={this.props.selectedValue}
        />
      </div>
    )
  }
}
ListTypeAhead.propTypes = {
  className: PropTypes.string,
  datalist: PropTypes.array.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
  labelKey: PropTypes.string.isRequired,
  selectedValue: PropTypes.array,
  autoFocus: PropTypes.bool,
  refVal: PropTypes.any,
}

export const RadioBtnPills = (props) => {
  return (
    <div className='radio-btn-pills'>
      {props.optionList &&
        props.optionList.map((data, index) => {
          let flg = false
          if ((!data.IsActive && data.IsActive !== undefined) || (!data.Active && data.Active !== undefined)) {
            flg = true
          }
          if (props.makedisabled) {
            flg = true
          }
          return (
            <button
              id={props.prefix + '-' + index}
              key={index}
              onClick={props.onClick}
              disabled={flg}
              className={'mb8 pillbtn ' + (data.isSelected ? 'pillbtn-active' : '') + (flg ? ' radio-pill-disabled' : '')}
            >
              <Radio
                disabled={flg}
                name={props.prefix}
                classes='radio-input'
                value={index}
                selectedValue={data.isSelected && index}
                inputProps={{
                  handleOnUpdate: props.onClick,
                }}
              />
              <div className='lightFontStyle fontSmall' dangerouslySetInnerHTML={{ __html: data.text || data.DisplayValue }} />
            </button>
          )
        })}
    </div>
  )
}
RadioBtnPills.propTypes = {
  prefix: PropTypes.string.isRequired,
  optionList: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  makedisabled: PropTypes.bool,
}

export const HomeDeviceOverView = (props) => {
  return (
    <BoxHolder className='device-overview-container'>
      <Heading wordBreak title={getTranslationText('device', 'deviceOverview')} />
      <LabelWithValue
        label={getTranslationText('device', 'totalDevices')}
        divclassName='mydevices-value mb8 mt20'
        value={props.deviceOverviewState && props.deviceOverviewState.noOfDevices > 0 ? props.deviceOverviewState.noOfDevices : '00'}
      />
      {props.deviceOverviewState.noOfDevices > 0 ? (
        <>
          <hr className='device-overview-seperator' size='2' />
          <div className='mb8'>
            <LabelWithValue
              label={getTranslationText('common', 'protectionLabel')}
              divclassName='overview-devices-low-count'
              value={props.deviceOverviewState ? props.deviceOverviewState.noOfDevicesProtected : '00'}
            />
          </div>
        </>
      ) : (
        ''
      )}
    </BoxHolder>
  )
}
HomeDeviceOverView.propTypes = {
  deviceOverviewState: PropTypes.object,
}

export const LabelWithValue = (props) => {
  return (
    <div className={props.divclassName}>
      <div className='bookFontStyle fontSmall overrideCasing'>{props.label}</div>
      <div className='boldFontStyle fontLarge '>{props.value}</div>
    </div>
  )
}
LabelWithValue.propTypes = {
  divclassName: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export class HomeUserLevels extends React.Component {
  render() {
    let barValue = '0%'
    if (this.props.userDevicePoints) {
      barValue = this.props.userDevicePoints.UserDevicePoints + '%'
    }
    return (
      <BoxHolder className='home-beginner-level'>
        <div className='beginner-level-heading mb20'>
          <img src={'/commonAssets/group-5-copy@3x.png'} />
          <Heading title={this.props.userDevicePoints && this.props.userDevicePoints.UserDeviceLevelName} />
        </div>
        <div className='level-content mt20'>
          <div className='progress-bar-bckground'>
            <div id='levelProgressBar' style={{ width: barValue }} />
          </div>
          <div className='row mt8 mb20'>
            <div className='col-6 boldFontStyle fontSmall'>
              {getTranslationText('common', 'lvl')}&nbsp;
              {this.props.userDevicePoints ? this.props.userDevicePoints.UserDeviceLevelID : 0}
            </div>
            <div className={'col-6 boldFontStyle fontSmall ' + (window.isDirectionRTL ? ' text-left ' : ' text-right')}>
              {getTranslationText('common', 'lvl')}&nbsp;
              {this.props.userDevicePoints ? this.props.userDevicePoints.UserDeviceLevelID + 1 : 0}
            </div>
          </div>
          <div className='mt8'>
            <p className='bookFontStyle fontSmall text-center'>
              <Link
                className={'boldFontStyle special-link-txt ' + (this.props.loaderState ? 'loader-cursor' : '')}
                to={this.props.loaderState ? '' : '/devices/add-device'}
              >
                +&nbsp;{getTranslationText('common', 'add')}&nbsp;
              </Link>
              03&nbsp;{getTranslationText('common', 'addDeviceProfile')}
            </p>
          </div>
        </div>
      </BoxHolder>
    )
  }
}
HomeUserLevels.propTypes = {
  userDevicePoints: PropTypes.object,
  loaderState: PropTypes.bool,
}

export const AddingDeviceWidget = (props) => {
  let brandTypeAheadStatus = !!_.get(props, 'listboxdata.categoryValue', []).length
  if (_.get(props, 'productListBoxData.brandList', []).length === 1) {
    brandTypeAheadStatus = false
  }
  return (
    <BoxHolder className='add-devices-container'>
      <div className='mediumFontStyle fontSmall'>{getTranslationText('addingDeviceWidget', 'quciklyAddDevice')}</div>
      <div className='mt28 mb28'>
        <div>
          <div className='lightFontStyle fontSmall mt20 mb8 overrideCasing'>
            {getTranslationText('addingDeviceWidget', 'selectCategory') + '*'}
          </div>
          <ListTypeAhead
            labelKey='text'
            isEditable={!(_.get(props, 'productListBoxData.categoryList', []).length === 1)}
            onChange={props.CategorySelection}
            selectedValue={props.listboxdata.categoryValue}
            datalist={props.productListBoxData.categoryList}
            placeholder={getTranslationText('addingDevices', 'selectCatg')}
          />
        </div>
        <div>
          <div className='lightFontStyle fontSmall mt20 mb8 overrideCasing'>{getTranslationText('addingDeviceWidget', 'selectBrand')}</div>
          <ListTypeAhead
            labelKey='text'
            isEditable={brandTypeAheadStatus}
            onChange={props.BrandSelection}
            selectedValue={props.listboxdata.brandValue}
            datalist={props.productListBoxData.brandList}
            placeholder={getTranslationText('common', 'selectBrand')}
          />
        </div>
        <div>
          <div className='lightFontStyle fontSmall mt20 mb8 overrideCasing'>{getTranslationText('common', 'selectProduct')}</div>
          <ListTypeAhead
            labelKey='text'
            isEditable={props.listboxdata.productEnabled}
            onChange={props.ProductSelection}
            selectedValue={props.listboxdata.productValue}
            datalist={props.productListBoxData.productList}
            placeholder={getTranslationText('common', 'selectProduct')}
          />
        </div>
      </div>
      <MediumButton
        className='button-small add-devices-btn mb8 inline-ele-Casing'
        buttonText={getTranslationText('addingDeviceWidget', 'addDevices')}
        onClick={props.addNewDeviceHome}
        disabled={!_.get(props, 'listboxdata.productValue', []).length}
      />
    </BoxHolder>
  )
}
AddingDeviceWidget.propTypes = {
  productListBoxData: PropTypes.object.isRequired,
  CategorySelection: PropTypes.func.isRequired,
  BrandSelection: PropTypes.func.isRequired,
  ProductSelection: PropTypes.func.isRequired,
  listboxdata: PropTypes.object.isRequired,
  addNewDeviceHome: PropTypes.func.isRequired,
}

export const ImageWithDescription = (props) => {
  return (
    <div className={'img-content-descp ' + props.divClass}>
      <img className='dummy-img-content' style={{ height: props.height ? props.height : '100px' }} />
      {props.label ? <div className='lightFontStyle img-label mt8'>{props.label}</div> : ''}
      <div className='mediumFontStyle fontSmall mt8 content-heading'>{props.heading}</div>
      {props.withDescp ? (
        <div className='lightFontStyle fontSmall mt8'>
          {props.descp}
          <Link className='lightFontStyle fontSmall special-link-txt' to={props.link}>
            {getTranslationText('common', 'readMore')}
          </Link>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}
ImageWithDescription.propTypes = {
  height: PropTypes.string,
  label: PropTypes.string,
  heading: PropTypes.string.isRequired,
  withDescp: PropTypes.bool,
  descp: PropTypes.string,
  link: PropTypes.string,
  divClass: PropTypes.string,
}

export const BoxImagesComponent = (props) => {
  let inputValue = props.isUploaded ? props.value : ''
  return (
    <div className={'box-image-upload-container ' + (props.className || '')}>
      {props.isUploaded ? (
        <div
          className={'uploaded-image-container mb8' + (props.loaderState ? ' loader-cursor' : '')}
          onClick={(e) => {
            !props.loaderState && props.imgViewerHandler(e)
          }}
          id={props.imageID}
        >
          {/* HARDCODING 'type' attribute below because we get type as 'image' for all file types from backend if it is uploaded from 360 */}
          <object className={props?.type?.includes('application') ? 'object' : 'img'} data={props.imgFilePath} type={'image/jpeg'} />
        </div>
      ) : (
        <label className={'image-uploading-container mb8' + (props.loaderState ? ' loader-cursor' : '')}>
          <span>
            <AddIcon />
          </span>
          <a>
            <InputComponent
              disabled={props.loaderState}
              name={props.inputName}
              title=''
              id={props.inputId}
              accept='.png, .jpg, .jpeg, .heic'
              value={inputValue}
              className={props.loaderState ? ' loader-cursor' : ''}
              onChange={(e) => {
                !props.loaderState && props.fileUploadHander(e)
              }}
              type='file'
            />
          </a>
        </label>
      )}
      <span>{props.imgDescpText}</span>
    </div>
  )
}
BoxImagesComponent.propTypes = {
  loaderState: PropTypes.bool,
  imgViewerHandler: PropTypes.func,
  imageID: PropTypes.any,
  imgFilePath: PropTypes.string,
  fileUploadHander: PropTypes.func,
  inputName: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isUploaded: PropTypes.any.isRequired,
  className: PropTypes.string,
  imgDescpText: PropTypes.string,
  inputId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.string,
}

export const ImageViewerModal = (props) => {
  return (
    <Modal showModal={props.show} className={'sfy-modal img-viewer-modal ' + props.className}>
      <div className={'close-icon ' + (window.isDirectionRTL ? ' text-left ' : ' text-right')}>
        <CustomIconComponent
          className={'close-icon ' + (window.isDirectionRTL ? ' text-left ' : ' text-right')}
          onClick={props.closeIconHandler}
          alt='Cancel'
        />
      </div>
      <div className='text-center img-holder mb20'>
        {/* HARDCODING 'type' attribute below because we get type as 'image' for all file types from backend if it is uploaded from 360 */}
        <object className={props?.type?.includes('application') ? 'object' : 'img'} data={props.imagePath} type={'image/jpeg'} />
      </div>
      {props.removeBtnStatus ? (
        <div className='mt28 text-center remove-doc'>
          <MediumButton
            buttonText={getTranslationText('common', 'remove')}
            onClick={props.deleteDocsHandler}
            className={'button-small button-ghost'}
          />
        </div>
      ) : (
        ''
      )}
    </Modal>
  )
}

ImageViewerModal.propTypes = {
  show: PropTypes.bool.isRequired,
  className: PropTypes.string,
  closeIconHandler: PropTypes.func.isRequired,
  imagePath: PropTypes.any,
  removeBtnStatus: PropTypes.bool,
  deleteDocsHandler: PropTypes.func.isRequired,
}

export const ContactUsModal = (props) => {
  let mailLink = _.get(props, 'user.appConfigDetails.BrandConnect.email[0]', 'support@servify.in')
  return (
    <Modal className='sfy-modal claim-instructions-modal' showModal={props.showContactUsModal}>
      <CustomIconComponent
        className={'close-icon ' + (window.isDirectionRTL ? 'appear-left text-left ' : 'appear-right text-right')}
        onClick={props.closeContactUsModal}
      />
      <div className='claim-instructions'>
        <div className='custom-modal-title mb20'>
          <p className='fontExtraLarge20'>{getTranslationText('common', 'getInTouchLabel')}</p>
          {props.additionalInfo && <p className='mt8 mb28'> {props.additionalInfo} </p>}
        </div>
        <div className='custom-modal-content'>
          <div className='instruction contact-us mb8'>
            <div
              className='connect-servify footer-links bookFontStyle'
              onClick={() => {
                window.location.href = 'mailto:' + _.get(props, 'user.appConfigDetails.footerLinks.support.email') || mailLink
              }}
            >
              <img src={'/commonAssets/MailId@3x.png'} />
              <b>{_.get(props, 'user.appConfigDetails.footerLinks.support.email') || mailLink}</b>
            </div>
          </div>
          <div className='instruction contact-us mb8'>
            <div className='connect-servify fontSmall bookFontStyle'>
              <Phone />
              <b dir='ltr'>{_.get(props, 'user.appConfigDetails.footerLinks.support.number') || '1800 121 4984'}</b>
            </div>
          </div>
          <br />
          {_.get(props, 'user.appConfigDetails.footerLinks.support.defaultAddress', false) && (
            <div className='instruction contact-us mb8'>
              <div className='connect-servify fontSmall bookFontStyle'>
                <img className='location-img' src={'/commonAssets/Request/location.png'} />
                <b>{_.get(props, 'user.appConfigDetails.footerLinks.support.defaultAddress')}</b>
              </div>
            </div>
          )}
        </div>
        <div className='center-align'>
          <MediumButton
            className='button-small mt20'
            onClick={props.closeContactUsModal}
            buttonText={getTranslationText('common', 'okay')}
          />
        </div>
      </div>
    </Modal>
  )
}
ContactUsModal.propTypes = {
  showContactUsModal: PropTypes.bool.isRequired,
  closeContactUsModal: PropTypes.func.isRequired,
}

export const RaiseARequestSupportModal = (props) => (
  <Modal className='sfy-modal empty-fields-modal' showModal={props.showRaiseARequestSupportModal}>
    <div className='row mb28'>
      <div className='col-6'>
        <Heading title={getTranslationText('common', 'customerSupport')} />
      </div>
      <div className='col'>
        <CustomIconComponent
          className={'close-icon ' + (window.isDirectionRTL ? ' text-left ' : ' text-right')}
          onClick={props.closeModal}
        />
      </div>
    </div>
    <div className='row'>
      <div className='col-sm-12 mb20'>
        {getTranslationText('common', 'contactSupport')}&nbsp;
        <span
          className='modalTextLink'
          onClick={() => {
            window.location.href = 'mailto:' + _.get(props, 'user.appConfigDetails.footerLinks.support.email')
          }}
        >
          {_.get(props, 'user.appConfigDetails.footerLinks.support.email')}
        </span>
        &nbsp;or <span className='modalText'>{_.get(props, 'user.appConfigDetails.CallCenterNumber')}</span>&nbsp;
        {getTranslationText('common', 'helpRaise')}
      </div>
      <div className='col-sm-12 center-align'>
        <MediumButton buttonText={getTranslationText('common', 'okay')} onClick={props.closeModal} />
      </div>
    </div>
  </Modal>
)
RaiseARequestSupportModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  showRaiseARequestSupportModal: PropTypes.bool.isRequired,
}

export const ConfirmationPopUp = (props) => {
  return (
    <Modal className='sfy-modal confirmation-popup' showModal={props.show}>
      <div className='row'>
        <div className='col-6 mb28'>
          <Heading title={props.title} />
        </div>
        <div className='col text-right'>
          <CustomIconComponent className='close-icon' onClick={props.toggle} />
        </div>
        <div className={'col-sm-12 ' + (window.isDirectionRTL ? ' right-align' : ' left-align')}>
          <SubHeading title={props.confirmationTxt} className='mb24' />
        </div>
        <div className='col-sm-12 deleteOption'>
          <MediumButton className='button-small button-ghost' buttonText={getTranslationText('common', 'no')} onClick={props.toggle} />
          <MediumButton
            className='button-small mb8 fontMedium'
            buttonText={getTranslationText('common', 'yes')}
            onClick={props.handleConfirmation}
          />
        </div>
      </div>
    </Modal>
  )
}

ConfirmationPopUp.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
  confirmationTxt: PropTypes.string.isRequired,
  handleConfirmation: PropTypes.func.isRequired,
}
export class RequestTypeSelection extends React.Component {
  constructor(props) {
    super(props)
    let servicesOptionsKeys = {
      showRepairOptions: true,
      ShowRaiseClaim: false,
      showServiceEstimator: true,
      showServicing: true,
      showInstallAndDemo: true,
      showRefundOption: false,
      showReplacementOption: false,
      ...props.servicesOptionsKeys,
    }
    let modifiedOptions = requestTypesList().filter((type) => {
      let key = type.key
      if (servicesOptionsKeys[key]) {
        return {
          ...type,
          isSelected: false,
        }
      }
    })
    this.state = {
      requestOptions: modifiedOptions,
    }
  }

  render() {
    return (
      <Modal className='sfy-modal request-type-options-popup' showModal>
        <div className='row request-type-options-wrapper'>
          <div className='col-sm-8'>
            <Heading title={getTranslationText('request', 'selectRequestType')} />
            {this.props.headingContent ? <span dangerouslySetInnerHTML={{ __html: this.props.headingContent }} /> : ''}
          </div>
          <div className={'col-sm-4 ' + (window.isDirectionRTL ? ' text-left ' : ' text-right')}>
            <CustomIconComponent className='close-icon' onClick={this.props.toggle} />
          </div>
          <div className='col-sm-12 mt28'>
            {_.get(this, 'state.requestOptions', []).length
              ? this.state.requestOptions.map((reqType, index) => (
                  <Link
                    key={index}
                    className='row mb20'
                    to={this.props.configurableHandler ? '' : reqType.location}
                    onClick={() => this.props.handleRequest(reqType.type)}
                  >
                    <div className='col-sm-4 col-12 service-action-head'>
                      <img src={reqType.image} />
                      <div className='mediumFontStyle fontLarge overrideCasing'>{reqType.text}</div>
                    </div>
                    <div className='col-sm-8 col-12 service-action-descp'>
                      <div>{reqType.description}</div>
                      <RightArrow />
                    </div>
                  </Link>
                ))
              : getTranslationText('request', 'noRequestAvailable')}
          </div>
          {/*
            <div className='col-sm-12'>
              <MediumButton className='mb8 fontMedium' buttonText='Continue'
                disabled={!this.state.requestOptions.find(req => (req.isSelected))}
                onClick={this.navigateServiceFlow} />
            </div>
            */}
        </div>
      </Modal>
    )
  }
  static propTypes = {
    servicesOptionsKeys: PropTypes.object.isRequired,
    toggle: PropTypes.func.isRequired,
    handleRequest: PropTypes.func.isRequired,
    configurableHandler: PropTypes.bool.isRequired,
    headingContent: PropTypes.node,
  }
}

export const StepFlowPopUp = (props) => {
  return (
    <Modal className='sfy-modal steps-flow-popup' showModal>
      <div className='row'>
        <div className='col-sm-8 mb28'>
          <Heading title={props.title} />
        </div>
        <div className='col-sm-12'>
          {props.stepsList.map((step, idx) => (
            <div key={idx} className='row'>
              <div className='col-sm-12 step-wrapper mediumFontStyle'>
                <div className='step-icon-box'>
                  <div>{idx + 1}</div>
                </div>
                <div>{step}</div>
              </div>
              {idx + 1 === props.stepsList.length ? '' : <div className='col-sm-12 steps-seperator' />}
            </div>
          ))}
        </div>
        <div className='col-sm-12 buttons-container mt28'>
          <MediumButton buttonText={getTranslationText('common', 'cancel')} onClick={() => props.toggle('cancel')} />
          <MediumButton onClick={() => props.toggle('continue')} buttonText={getTranslationText('common', 'continue')} />
        </div>
      </div>
    </Modal>
  )
}
StepFlowPopUp.propTypes = {
  title: PropTypes.string.isRequired,
  stepsList: PropTypes.array.isRequired,
  toggle: PropTypes.func.isRequired,
}

export const StepsIntrPopUp = (props) => {
  return (
    <Modal className='sfy-modal confirmation-popup' showModal={props.show}>
      <div className='row'>
        <div className='col-sm-6 mb28'>
          <Heading title={props.title} />
        </div>
        <div className={'col ' + (window.isDirectionRTL ? ' text-left ' : ' text-right')}>
          <CustomIconComponent className='close-icon' onClick={props.toggle} />
        </div>
        <div className={'col-sm-12 instrContainer ' + (window.isDirectionRTL ? ' right-align' : ' left-align')}>
          {props.InstrData.map((data, index) => (
            <div key={index} className={'mb8'} dangerouslySetInnerHTML={{ __html: data }} />
          ))}
        </div>
        <div className='col-sm-12 mt20 text-center center-align'>
          <MediumButton className='button-small fontMedium' buttonText={props.confirmationTxt} onClick={props.toggle} />
        </div>
      </div>
    </Modal>
  )
}
StepsIntrPopUp.propTypes = {
  title: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  InstrData: PropTypes.array.isRequired,
  confirmationTxt: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
}

export const CustomRadioComponent = (props) => (
  <label className='col-md-4' htmlFor={props.radioDataItem} key={props.radioDataItem}>
    <div
      id={props.radioDataItem}
      className='custom-radio d-flex flex-column text-center'
      onClick={props.handleCustomRadioOnClick}
      tabIndex={-1}
    >
      <input type='radio' name='treatment' />
      <div style={{ pointerEvents: 'none' }}>{props.children}</div>
      {props.selected === props.radioDataItem && <div className='checkmark' />}
    </div>
  </label>
)
CustomRadioComponent.propTypes = {
  radioDataItem: PropTypes.any,
  children: PropTypes.any,
  selected: PropTypes.any,
  handleCustomRadioOnClick: PropTypes.any,
}
