import React, { lazy } from 'react'
import { browserHistory } from 'react-router'
import { connect } from 'react-redux'
import { Loader, Snackbar, Header } from 'sfy-react'
import { MediumButton } from '../../components/UIComponents'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Footer from '../../components/Footer'
import { HeadersTabsConst, menuLangTranslationMapping } from '../../utils/constants'
import getTranslationTextAsync from '../../api/getTranslations.js'
import { getTranslationText } from '../../utils/languageTranslation.js'
import { unsetToast } from '../../store/toastsAndLoaders'
import { storeLastKnowPathName } from '../../store/location'
import './PageLayout.scss'
const ChangeLanguageOption = lazy(() => import('../../components/UIComponents/ChangeLanguage'))

import { ReactComponent as HomeIcon } from '../../../public/commonAssets/home.svg'
import { ReactComponent as PlansIcon } from '../../../public/commonAssets/plans.svg'
import { ReactComponent as StoreIcon } from '../../../public/commonAssets/store.svg'
import { ReactComponent as DevicesIcon } from '../../../public/commonAssets/devices.svg'
import { ReactComponent as ProfileIcon } from '../../../public/commonAssets/profile.svg'
import { ReactComponent as NotificationIcon } from '../../../public/commonAssets/notification.svg'
import { ReactComponent as VoucherIcon } from '../../../public/commonAssets/voucher.svg'

const icons = {
  '/': <HomeIcon />,
  '/devices': <DevicesIcon />,
  '/store': <StoreIcon />,
  '/notifications': <NotificationIcon />,
  '/profile': <ProfileIcon />,
  '/myplans': <PlansIcon />,
  '/mycare': <ProfileIcon />,
  '/faq': <ProfileIcon />,
  '/vouchers': <VoucherIcon />,
}

export class PageLayout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      globalLoaderFlg: false,
      showContent: false,
      tabs: [],
      languages: [],
      selectedLanguage: localStorage.getItem('languageCode'),
      activeTab: undefined,
      count: this.props.user && this.props.user.unReadCount ? this.props.user.unReadCount : 0,
      openPreferencesModal: false,
    }
  }

  componentDidMount = async () => {
    this.checkRouteAccess(this.props.location.pathname)
    window.scrollTo(0, 0)
    window.onload = this.handleResourceLoad()
    window.__LangData__ = JSON.stringify(_.get(this, 'props.languageDetail.translationText.data', []))
    this.props.unsetToast()
    if (this.props.location.pathname) {
      this.handleActiveTab({ pathname: this.props.location.pathname, user: this.props.user })
      if (this.props.user.selectedCountryData && (!window.CountryID || !window.CountryCode)) {
        window.CountryID = this.props.user.selectedCountryData.CountryID
        window.CountryCode = this.props.user.selectedCountryData.CountryCode
        window.PhoneCode = this.props.user.selectedCountryData.PhoneCode
        window.CountryCode && (await this.props.getTranslationTextAsync())
      }
    }
  }

  setTabs = (tabs, callback) => this.setState({ tabs }, callback)

  setNavHeadings = (header) => {
    // Do not show menu items if guest user or is not logged in
    if (this.props.user.isAnGuestUser || _.isEmpty(this.props.user.userDetails) || this.props.pathName === '/link-account') return []

    let navHeading = _.compact(
      header.map((data, index) => {
        let tab = HeadersTabsConst(data)
        const translatedName = this.getMenuTranlations(data)

        //  If logged in show profile name on profile tab
        const title = tab.path === '/profile' ? this.renderUserName() : translatedName

        if (tab.isEnabledOnHeader) {
          const eachTab = {
            id: index,
            title,
            route: tab.path,
            icon: icons[tab.path],
            onClick: () => {
              if (!this.props.user.isAnGuestUser || /store/.test(this.props.pathName)) {
                this.handleChangeTab(eachTab)
              }
            },
            isActive: index === this.state.activeTab,
            ...(tab.path === '/notifications' && this.state.count > 0 ? { badgeContent: this.state.count } : {}),
          }
          return eachTab
        }
      })
    )
    return navHeading
  }

  checkRouteAccess = (pathname) => {
    let availRoutes = _.get(this, 'props.user.appConfigDetails.navBarHeaders', [
      'Home',
      'My devices',
      'Store',
      'Notifications',
      'Profile',
      'My Plans',
      'Subscription',
    ])
    availRoutes = [
      ...availRoutes,
      'Request',
      'Track',
      'Service estimator',
      'Login',
      'Payment',
      'about us',
      'terms and conditions',
      'privacy policy',
      'my plans',
      'online consent',
      'mycare',
      'faq',
    ]
    availRoutes = availRoutes.map((route) => ({
      name: route,
      path: HeadersTabsConst(route).path,
    }))
    let accessedRoute = availRoutes.find((route) => route.path.toLowerCase() === '/' + pathname.toLowerCase().split('/')[1])
    if (!accessedRoute) {
      browserHistory.replace('/pagenotfound')
    }
  }

  componentDidUpdate(previousProps) {
    if (this.props !== previousProps) {
      let updatedselectedCtry = this.props.user.selectedCountryData
      if (
        updatedselectedCtry &&
        this.props.user.selectedCountryData &&
        this.props.user.selectedCountryData.CountryID !== updatedselectedCtry.CountryID
      ) {
        window.CountryID = updatedselectedCtry.CountryID
        window.CountryCode = updatedselectedCtry.CountryCode
        window.PhoneCode = updatedselectedCtry.PhoneCode
      }
      // @ToDo re-initiate navigation menu after page update
      // this.initNav(this.props)
      if (Object.keys(_.get(this, 'props.user.appConfigDetails', {}))) {
        let navHeadings = _.get(this.props, 'user.appConfigDetails.navBarHeaders', [
          'Home',
          'My devices',
          'Store',
          'Notifications',
          'Profile',
        ])
        const tabs = this.setNavHeadings(navHeadings)
        this.setTabs(tabs, () => {
          this.handleActiveTab({ pathname: this.props.location.pathname, user: this.props.user })
        })
      }

      this.setState(
        {
          globalLoaderFlg: this.props.toastsAndLoaders.globalLoaderStatus,
        },
        () => {
          if (this.layoutHeight) {
            if (this.state.globalLoaderFlg) {
              this.layoutHeight.style.minHeight = '90vh'
            } else {
              this.layoutHeight.style.minHeight = 'initial'
            }
          }
        }
      )
    }
  }

  handleResourceLoad = () => {
    this.setState({
      showContent: true,
    })
  }

  checkForCustomRoutes = () => {
    return !!['/about-us', '/privacy-policy', '/terms-conditions', '/online-consent'].includes(this.props.location.pathname)
  }

  handleActiveTab = ({ pathname, user }) => {
    if (pathname && pathname !== '/') {
      pathname = '/' + pathname.split('/')[1]
    }
    let currentTab = this.state.tabs.filter((tab) => tab.route === pathname)
    if (pathname?.includes('/subscription')) {
      currentTab = this.state.tabs.filter((tab) => {
        return tab.route === '/store'
      })
    }
    this.setState({
      activeTab: currentTab?.length && currentTab[0].id,
      count: user.unReadCount ? user.unReadCount : 0,
    })
  }

  renderUserName = () => {
    return _.get(this.props, 'user.userDetails.name') ? this.props.user.userDetails.name.split(' ')[0].slice(0, 15) : 'Profile'
  }

  handleChangeTab = (tab) => {
    let deviceSubRoute = _.includes(['myplans'], this.props.location.query.path)
    this.setState({
      activeTab: tab.id,
    })
    this.hideHeaderNavMobile()
    this.props.storeLastKnowPathName({
      path: this.state.tabs.find((data) => tab.id === data.id).route,
    })
    if (tab.route === '/about-us') {
      window.open(tab.route, '_blank')
    } else if (tab.route === '/devices' && deviceSubRoute) {
      browserHistory.push(tab.route)
      window.location.reload()
    } else {
      browserHistory.push(tab.route)
    }
    // hide nav bar for mobile
  }

  isHamburgerActive = () => {
    if (document.getElementById('header-hamburger')) {
      return document.getElementById('header-hamburger').classList.contains('active')
    }
    return true
  }

  toggleHeaderNavMobile = (val) => {
    if (!val) {
      this.isHamburgerActive() ? this.hideHeaderNavMobile() : this.showHeaderNavMobile()
    } else {
      if (val === 'show') {
        this.showHeaderNavMobile()
      } else {
        this.hideHeaderNavMobile()
      }
    }
  }

  showHeaderNavMobile = () => {
    if (document.getElementById('header-hamburger')) {
      document.getElementById('header-hamburger').classList.add('active')
    }
    if (document.getElementById('header-tabs')) {
      document.getElementById('header-tabs').classList.add('active')
    }
    document.body.classList.add('noScroll')
  }

  hideHeaderNavMobile = () => {
    if (document.getElementById('header-hamburger')) {
      document.getElementById('header-hamburger').classList.remove('active')
    }
    if (document.getElementById('header-tabs')) {
      document.getElementById('header-tabs').classList.remove('active')
    }
    document.body.classList.remove('noScroll')
  }

  handleHamburgerClick = () => {
    this.toggleHeaderNavMobile()
  }

  guestUserCloseBtn = () => {
    // window.location.assign('https://servify.in/')
    browserHistory.replace('/login')
  }

  onLogoClick = () => {
    let homeRoute = '/'
    // if (this.props.user.isAnGuestUser) {
    //   storeLastKnowPathName({
    //     path: '/'
    //   })
    //   route = this.props.location.pathname
    // }
    let currentRoute = this.props.location.pathname
    if (currentRoute !== '/') {
      browserHistory.push(homeRoute)
    } else {
      window.location.reload()
    }
  }

  getMenuTranlations = (tabName) => getTranslationText('common', menuLangTranslationMapping[tabName], tabName)

  closeSnackbar = () => {
    this.props.unsetToast()
  }

  afterLanguageChange = () => {
    if (this.props.user.isAnGuestUser) {
      browserHistory.push({
        pathname: this.props.location.pathname,
        query: {
          ...this.props.location.query,
          reloadPlansforCountry: _.get(this, 'props.user.selectedCountryData.CountryCode', window.CountryCode),
        },
      })
      window.location.reload()
    }
  }

  render() {
    const superNavItems = [
      _.get(this, 'props.devices.deviceDetails.ordersList', []).length
        ? {
            title: getTranslationText('common', 'purchasedHistory'),
            onClick: () => {
              browserHistory.push('/purchasehistory')
            },
            isActive: this.props.pathName.toLowerCase() === '/purchasehistory',
          }
        : {},
    ]

    const showSuperNavItems = this.props.user.isAnGuestUser && !_.get(this, 'props.user.appConfigDetails.SkipRedirection', false)
    const changeLanguageTab =
      _.get(this, 'props.user.appConfigDetails.showChangeLanguageOption', false) &&
      (this.props.user.isAnGuestUser || this.props.location.pathname === '/')
        ? {
            component: (
              <ChangeLanguageOption
                countryCode={window.CountryCode || 'IN'}
                selectedLanguage={localStorage.getItem('languageCode') || 'en'}
                afterLanguageChange={this.afterLanguageChange}
                changeLanguageText={getTranslationText('common', 'changeLanguage')}
              />
            ),
          }
        : null
    let isFestival = new Date().getFullYear() === 2022 && 'Consumer-Web'.includes(_.get(this, 'props.user.appConfigDetails.app', ''))
    let isFixedHeader =
      'SamsungUS-Web'.includes(_.get(this, 'props.user.appConfigDetails.app', '')) && ['/mycare'].includes(this.props.location.pathname)

    let loginUrl = '/login'
    const phone = _.get(this, 'props.location.query.phone', false)
    const email = _.get(this, 'props.location.query.email', false)
    const primary_identifier = _.get(this, 'props.location.query.primary_identifier', 'EMAIL_ID')

    if (phone && email && primary_identifier) {
      loginUrl = `/login?email=${email}?phone=${phone}?primary_identifier=${primary_identifier}`
    } else if (email) {
      loginUrl = `/login?email=${email}?primary_identifier=${primary_identifier}`
    } else if (phone) {
      loginUrl = `/login?phone=${phone}?primary_identifier=${primary_identifier}`
    }

    const samsungSSOUser =
      _.get(this, 'props.user.appConfigDetails.app', '') == 'Samsung-Web' && _.get(this, 'props.user.consumerDetails.data.imei', '')
        ? {
            component: (
              <div className='header-tabs-flex'>
                <div
                  className='tab sentence-casing-uppercase custome-header'
                  onClick={() => (window.location.href = _.get(this, 'props.user.consumerDetails.data.callBackURL', ''))}
                >
                  <img src={`${appName}/images/redirect-icon.svg`} />
                </div>
              </div>
            ),
          }
        : null

    const mycare =
      _.get(this, 'props.user.appConfigDetails.app', '') == 'SamsungUS-Web' && !_.get(this, 'props.user.userDetails.consumerID', false)
        ? {
            component: (
              <div className='header-tabs-flex mycare'>
                <div
                  className='tab sentence-casing-uppercase mobile-header'
                  onClick={() => {
                    browserHistory.push(loginUrl)
                  }}
                >
                  <img src={`/${appName}/images/mycare/navigation.png`} alt={'my care+'} />
                  <span>File a Claim</span>
                </div>

                <div className='mobile-care-plus-btn desktop mb10'>
                  <MediumButton
                    className='button-ghost boldFontStyle'
                    buttonText='Login'
                    onClick={() => {
                      browserHistory.push(loginUrl)
                    }}
                  />
                  <MediumButton
                    className='ml20 boldFontStyle'
                    buttonText='File a Claim'
                    onClick={() => {
                      browserHistory.push(loginUrl)
                    }}
                  />
                  <MediumButton
                    className='ml20 boldFontStyle'
                    buttonText='Purchase Care+'
                    onClick={() => {
                      window.open('https://rg2.samsungcareplus.com/us#/ ')
                    }}
                  />
                </div>
              </div>
            ),
          }
        : null
    return (
      <div
        className={
          'container-fluid text-center ' +
          (this.state.globalLoaderFlg ? 'global-loader-active' : '') +
          (this.checkForCustomRoutes() ? ' background-white' : '')
        }
        style={{ height: !this.checkForCustomRoutes() ? '100%' : '' }}
      >
        {this.state.showContent ? (
          <div style={{ height: '100%' }}>
            <div
              className={
                /store/.test(this.props.location.pathname) || /vouchers/.test(this.props.location.pathname) ? 'header-wrapper' : ''
              }
            >
              {this.props.location.pathname &&
              !['/login', '/terms-conditions', '/about-us', 'privacy-policy', '/online-concent'].includes(this.props.location.pathname) ? (
                <>
                  <Header
                    iconType='svg'
                    className={{
                      header: `header  ${isFixedHeader ? 'header-fixed' : ''}  ${
                        _.get(this, 'props.store.selectedBrand.BrandName', '').toUpperCase() !== 'APPLE' &&
                        _.get(this, 'props.user.appConfigDetails.nonOtpPlanSale', false)
                          ? 'header-for-new-flow'
                          : ''
                      } `,
                      navLink: 'sentence-casing-uppercase',
                    }}
                    brand={
                      <div className={'header-logo-container'}>
                        <img
                          src={isFestival ? `/${appName}/images/festival/christmas/logo.png` : `/${appName}/images/logo.png`}
                          onClick={this.onLogoClick}
                        />
                      </div>
                    }
                    navItems={[
                      ...this.state.tabs,
                      ...(changeLanguageTab ? [changeLanguageTab] : mycare ? [mycare] : samsungSSOUser ? [samsungSSOUser] : []),
                    ]}
                    superNavItems={showSuperNavItems && superNavItems}
                  />
                </>
              ) : (
                ''
              )}
              {/store/.test(this.props.location.pathname) || /vouchers/.test(this.props.location.pathname) ? (
                <div className='overflow-wrapper' />
              ) : (
                ''
              )}
              {this.props.toastsAndLoaders.message && (
                <Snackbar
                  isRTL={window.isDirectionRTL}
                  message={this.props.toastsAndLoaders.message}
                  type={this.props.toastsAndLoaders.type}
                  callback={this.closeSnackbar}
                  imagePath={`/commonAssets/${this.props.toastsAndLoaders.type}-alert.svg`}
                />
              )}
            </div>
            <div
              ref={(node) => {
                this.layoutHeight = node
              }}
              className={
                (/mycare/.test(this.props.location.pathname) || /faq/.test(this.props.location.pathname)
                  ? 'page-layout__viewport_full-width '
                  : 'page-layout__viewport ') +
                (/store/.test(this.props.location.pathname) || /vouchers/.test(this.props.location.pathname) ? 'sticky-header-margin' : '')
              }
            >
              <div className='page-layout'>{this.props.children}</div>
            </div>
            {this.props.location.pathname && this.props.location.pathname !== '/login' ? (
              <Footer pathName={this.props.location.pathname} languageCode={this.props.languageDetail.languageCode} />
            ) : null}
          </div>
        ) : (
          ''
        )}
        {this.state.globalLoaderFlg ? <Loader /> : ''}
      </div>
    )
  }
}

PageLayout.propTypes = {
  children: PropTypes.node,
  languageDetail: PropTypes.shape({
    languageCode: PropTypes.string,
  }).isRequired,
  toastsAndLoaders: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  getTranslationTextAsync: PropTypes.func.isRequired,
}

const mapDispatchToProps = {
  getTranslationTextAsync,
  storeLastKnowPathName: (obj) => storeLastKnowPathName(obj),
  unsetToast,
}

const mapStateToProps = (state) => ({
  toastsAndLoaders: state.toastsAndLoaders,
  user: state.user,
  languageDetail: state.languageDetail,
  devices: state.devices,
  location: state.location,
})

export default connect(mapStateToProps, mapDispatchToProps)(PageLayout)
