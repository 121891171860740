import React, { Component } from 'react'
import { browserHistory } from 'react-router'
import './GuestRoute.scss'
import MyCareComponent from './MyCare'
import PlanBenefitsComponent from './PlanBenefits'
import lookup from '../../utils/ipinfo'

class GuestRoute extends Component {
  constructor() {
    super()
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0)
    this.props.setglobalLoaderStatus(true)
    const regionCode = await lookup()
    window.CountryCode = regionCode
    window.CountryID = undefined
    await this.props.loadAppConfig()
    this.props.setglobalLoaderStatus(false)
    this.props.setPrefilledUserDetails(_.get(this, 'props.location.query', ''))
  }

  render() {
    let renderdiv
    if (this.props.params.p) {
      switch (this.props.params.p) {
        case 'benefits':
          renderdiv = <PlanBenefitsComponent />
          break
        default:
          browserHistory.replace('/pagenotfound')
      }
    } else {
      renderdiv = <MyCareComponent {...this.props} />
    }
    return renderdiv
  }
}

export default GuestRoute
