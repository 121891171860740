// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n", ""]);
// Exports
exports.locals = {
	"mainThemeColor": "var(--primary-700)",
	"calendarAsset": "var(--calendarAssetPath)",
	"sliderIconNext": "var(--sliderIconNext)",
	"mediumFontFamily": "var(--mediumFont-family)",
	"boldFontFamily": "var(--boldFont-family)",
	"lightFontFamily": "var(--lightFont-family)",
	"bookFontFamily": "var(--bookFont-family)",
	"razorPayThemeColor": "var(--primary-700)",
	"customeFontFamily": "var(--custome-boldFontStyle)"
};
module.exports = exports;
