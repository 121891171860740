/* eslint-disable */
// ------------------------------------
// Constants
// ------------------------------------
import { api } from '../config/variables'
import { setToaster } from './toastsAndLoaders'
import _ from 'lodash'
import { getTranslationText } from '../utils/languageTranslation.js'

export const SET_MY_PLAN_DETAILS = 'SET_MY_PLAN_DETAILS'
export const SET_ENROLLED_DEVICES_DETAILS = 'SET_ENROLLED_DEVICES_DETAILS'
export const SET_PURCHASED_PLAN_DETAILS = 'SET_PURCHASED_PLAN_DETAILS'
export const CLEAR_MYPLAN_DETAILS = 'CLEAR_MYPLAN_DETAILS'
export const SET_MY_PLAN_NEW_DEVICE_ADD = 'SET_MY_PLAN_NEW_DEVICE_ADD'
export const SET_SOLD_PLAN_DETAILS = 'SET_SOLD_PLAN_DETAILS'

export const setSoldPlanDetails = (payload) => {
  return {
    type: SET_SOLD_PLAN_DETAILS,
    payload,
  }
}

export const setDeviceAdditionModal = (payload) => {
  return {
    type: SET_MY_PLAN_NEW_DEVICE_ADD,
    payload,
  }
}

export const setMyPlanDetails = (payload) => {
  return {
    type: SET_MY_PLAN_DETAILS,
    payload,
  }
}

export const setEnrolledDevicesDetails = (payload) => {
  return {
    type: SET_ENROLLED_DEVICES_DETAILS,
    payload,
  }
}

export const setSelectedPurchasedPlanDetails = (payload = {}) => {
  return {
    type: SET_PURCHASED_PLAN_DETAILS,
    payload,
  }
}

export const clearMyPlanDetails = (payload = {}) => {
  var devicePath = 'device'
  localStorage.setItem('checkTitlePath', devicePath)
  return {
    type: CLEAR_MYPLAN_DETAILS,
    payload,
  }
}

export const fetchCardDetailsAsync = (SoldPlanID = undefined, Status = undefined, PlanName = undefined, GroupSoldPlanID = undefined) => {
  return (dispatch, getState) => {
    let body = JSON.stringify({
      SoldPlanID,
      GroupSoldPlanID,
    })
    return axios
      .post(api.coreUri + '/ConsumerProduct/fetchCardDetails', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(err.message, 'error'))
        throw err
      })
      .then((response) => {
        if (!response.status === 200 || !response.data.success) {
          // console.log('Api Error: ', response.data.msg)
          let errMsg = response.data.msg
          // let err = new Error(errMsg)
          dispatch(setToaster(errMsg, 'error'))
          // throw err
        } else {
          const soldPlanDetails = _.get(getState(), 'myPlans.soldPlanDetails', {})
          dispatch(setSoldPlanDetails({ ...soldPlanDetails, SoldPlanID, Status, [SoldPlanID]: response.data.data, PlanName }))
          return response.data.data
        }
      })
  }
}

export const getPurchasedPlansAsync = (ConsumerProductID = undefined) => {
  return (dispatch, getState) => {
    let body = JSON.stringify({
      ConsumerID: _.get(getState(), 'user.userDetails.consumerID'),
      ConsumerProductID,
      PlanType: ['Protect', 'Secure', 'ENROLLMENT'],
    })
    return axios
      .post(api.coreUri + '/ConsumerProduct/getPurchasedPlans', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(err.message, 'error'))
        throw err
      })
      .then((response) => {
        let PlanDetailsJson
        if (!response.status === 200 || !response.data.success) {
          // console.log('Api Error: ', response.data.msg)
          let errMsg = response.data.msg || getTranslationText('error', 'noPlansFound')
          let err = new Error(errMsg)
          dispatch(setToaster(errMsg, 'error'))
          throw err
        } else {
          if (_.get(response, 'data.data.PlanArray', []).length > 0) {
            PlanDetailsJson = [
              {
                Heading: getTranslationText('common', 'activePlans'),
                PlanList: _.compact(
                  response.data.data.PlanArray.filter(
                    (plan) =>
                      _.includes([1], plan.Status) &&
                      _.includes([1, -5], plan.GroupSoldPlanStatus) &&
                      !_.get(plan, 'PlanConfig.IsCustomPlan', false)
                  )
                ),
              },
              {
                Heading: getTranslationText('common', 'onHoldPlans'),
                PlanList: _.compact(
                  response.data.data.PlanArray.filter(
                    (plan) => _.includes([-9], plan.Status) && !_.get(plan, 'PlanConfig.IsCustomPlan', false)
                  )
                ),
              },
              {
                Heading: getTranslationText('common', 'cancelledPlans'),
                PlanList: _.compact(
                  response.data.data.PlanArray.filter(
                    (plan) => _.includes([-3, -4, -6, 3, 4], plan.Status) && !_.get(plan, 'PlanConfig.IsCustomPlan', false)
                  )
                ),
              },
            ]
          } else {
            // let errMsg = response.data.msg || getTranslationText('error', 'noPlansFound')
            // let err = new Error(errMsg)
            // console.log('err', err)
            // dispatch(setToaster(errMsg, 'error'))
            PlanDetailsJson = [
              {
                Heading: getTranslationText('common', 'activePlans'),
                PlanList: [],
              },
              {
                Heading: getTranslationText('common', 'onHoldPlans'),
                PlanList: [],
              },
              {
                Heading: getTranslationText('common', 'cancelledPlans'),
                PlanList: [],
              },
            ]
          }
          dispatch(setMyPlanDetails(PlanDetailsJson))
          return response.data.data
        }
      })
  }
}

export const getEnrolledDevicesAsync = (GroupSoldPlanID) => {
  return (dispatch, getState) => {
    let body = JSON.stringify({
      ConsumerID: _.get(getState(), 'user.userDetails.consumerID'),
      GroupSoldPlanID: GroupSoldPlanID,
    })
    return axios
      .post(api.coreUri + '/ConsumerProduct/getEnrolledDevices', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(err.message, 'error'))
        throw err
      })
      .then((response) => {
        if (!response.status === 200 || !response.data.success) {
          // console.log('Api Error: ', response.data.msg)
          let errMsg = response.data.msg || getTranslationText('error', 'noDeviceMapped')
          let err = new Error(errMsg)
          dispatch(setToaster(errMsg, 'error'))
          throw err
        } else {
          dispatch(setEnrolledDevicesDetails(response.data.data.supportedDevices))
          return response.data.data.supportedDevices
        }
      })
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SET_SOLD_PLAN_DETAILS]: (state, action) => ({
    ...state,
    soldPlanDetails: action.payload,
  }),
  [SET_MY_PLAN_DETAILS]: (state, action) => ({
    ...state,
    purchasedPlanList: action.payload,
  }),
  [SET_ENROLLED_DEVICES_DETAILS]: (state, action) => ({
    ...state,
    enrolledDeviceList: action.payload,
  }),
  [SET_PURCHASED_PLAN_DETAILS]: (state, action) => ({
    ...state,
    selectedPlanDetails: action.payload,
  }),
  [CLEAR_MYPLAN_DETAILS]: (state) => ({
    ...state,
    enrolledDeviceList: {},
    selectedPlanDetails: {},
  }),
  [SET_MY_PLAN_NEW_DEVICE_ADD]: (state, action) => ({
    ...state,
    newDeviceAdditionModal: action.payload,
  }),
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  enrolledDeviceList: [],
  purchasedPlanList: [],
  selectedPlanDetails: {},
  newDeviceAdditionModal: false,
}

export default function myPlanReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
