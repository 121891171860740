import { connect } from 'react-redux'
import AccountLinkComponent from '../components/AccountLink'
import { generateOTPAsync, verifyOTPAsync } from '../../Login/modules/login'
import { updateUserDetailsAsync, getUserDetailsAsync, loadAppConfig, getAppLegalConfigs } from '../../../store/user'
import { storeLastKnowPathName } from '../../../store/location'
import { setToaster, setglobalLoaderStatus } from '../../../store/toastsAndLoaders'
/*  Object of action creators (can also be function that returns object).
    Keys will be passed as props to presentational components. Here we are
    implementing our wrapper around increment; the component doesn't care   */
const mapDispatchToProps = {
  generateOTPAsync,
  verifyOTPAsync,
  updateUserDetailsAsync,
  setToaster,
  getUserDetailsAsync,
  loadAppConfig,
  getAppLegalConfigs,
  storeLastKnowPathName,
  setglobalLoaderStatus,
}

const mapStateToProps = (state) => ({
  user: state.user,
  location: state.location,
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountLinkComponent)
