import React, { useEffect, useState, Fragment } from 'react'
import { browserHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'sfy-react'
import { setSubscriptionDetails } from '../../../store/subscription'
import './Success.scss'
import { postRequestAsync } from '../../../api/genericAPIs'
import { getTranslationText } from '../../../utils/languageTranslation'
import { BrandSpecificConfig } from '../../../utils/constants'
import { setglobalLoaderStatus, setToaster } from '../../../store/toastsAndLoaders'
import { setSelectedPurchasedPlanDetails } from '../../../store/myPlansReducer'
import pushToAnalytics from '../../../utils/Analytics'

const Success = () => {
  const dispatch = useDispatch()

  const isSubscribed = useSelector((state) => state.subscription.isSubscribed)
  const accessToken = useSelector((state) => state.user.consumerDetails?.data?.accessToken?.ID)
  const appConfigDetails = useSelector((state) => state.user.appConfigDetails)
  const user = useSelector((state) => state.user)
  const userDetails = useSelector((state) => state.user.userDetails)
  const planDetails = useSelector((state) => state.subscription.planDetails)
  const deviceDetails = useSelector((state) => state.subscription.deviceDetails)

  const [groupedDetails, setGroupedDetails] = useState()

  useEffect(() => {
    !isSubscribed && browserHistory.replace('/subscription')
  }, [isSubscribed])

  useEffect(() => {
    const handleUnloadEvent = () => {
      dispatch(setSubscriptionDetails({ isSubscribed: false }))
    }
    window.addEventListener('unload', handleUnloadEvent)
    return () => {
      window.removeEventListener('unload', handleUnloadEvent)
    }
  }, [dispatch])

  useEffect(() => {
    let result = []
    if (deviceDetails?.length) {
      result = deviceDetails.reduce((r, a) => {
        r[a.category.value] = r[a.category.value] || []
        r[a.category.value].push(a)
        return r
      }, Object.create(null))
    }
    setGroupedDetails(result)
  }, [deviceDetails])

  const handleOnAddMoreDevicesClick = async () => {
    try {
      dispatch(setglobalLoaderStatus(true))
      let response = await postRequestAsync(
        'ConsumerProduct/getPurchasedPlans',
        {
          ConsumerID: userDetails.consumerID,
          PlanType: ['Protect', 'Secure', 'ENROLLMENT'],
        },
        {
          Authorization: accessToken,
        }
      )
      response = response.PlanArray.find((plan) => plan.Status === 1 && plan.PlanID === planDetails.PlanDetails.PlanID)
      if (response) {
        dispatch(setSelectedPurchasedPlanDetails({ ...response, selectedFlow: 'new' }))
        pushToAnalytics(
          'Plan Sales - Post Purchase Onboarding',
          {
            'Plan Name': planDetails.PlanDetails.PlanName,
            'Customer Name': userDetails.name,
            'Email Address': userDetails.emailID,
            ConsumerID: userDetails.consumerID || '',
          },
          user
        )
        browserHistory.replace('/devices/add-device?path=myplans')
        dispatch(setSubscriptionDetails({ isSubscribed: false }))
      } else {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
      }
      dispatch(setglobalLoaderStatus(false))
    } catch (e) {
      console.log(e)
      dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
      dispatch(setglobalLoaderStatus(false))
    }
  }

  return isSubscribed ? (
    <>
      <div className='success-message-container'>
        {planDetails?.PlanDetails && <img className='success-plan-image' src={planDetails.PlanDetails.planMeta.PlanImage} alt='plan' />}
        <h4 className='text-center mb20'>
          {getTranslationText('common', 'hey')} {userDetails.name}, {getTranslationText('store', 'greetingMsg')}!
        </h4>
        <p className='text-center mb0'>{getTranslationText('subscription', 'subscriptionSuccess')}.</p>
      </div>
      {groupedDetails && Object.keys(groupedDetails)?.length > 0 && (
        <div className='success-device-wrapper'>
          <div className='success-device-container'>
            <h6 className='text-center'>
              {getTranslationText('subscription', 'followingDevicesAreProtected')} {planDetails.PlanDetails.PlanName}
            </h6>
            {Object.keys(groupedDetails).map((key) => {
              const devices = groupedDetails[key]
              return (
                <Fragment key={key}>
                  <p className='device-title pt8 pb8 m0'>
                    {key} {getTranslationText('common', 'detailsLabel')}
                  </p>
                  <div className='subscription-device-details'>
                    {devices.map((device, index) => (
                      <Fragment key={index}>
                        <h6 className='subscription-device-container' style={{ maxWidth: 'max-content' }}>
                          {device.brand?.value}&nbsp;|&nbsp;{device.product?.value}
                          {device.serialNumber ? ` | ${device.serialNumber}` : ''}
                        </h6>
                      </Fragment>
                    ))}
                  </div>
                </Fragment>
              )
            })}
          </div>
        </div>
      )}
      <div className='success-add-container'>
        <h4 className='success-add-title mb20'>{`${getTranslationText('subscription', 'wantToAdd')}${
          deviceDetails?.length ? ` ${getTranslationText('common', 'more')}` : ''
        } ${getTranslationText('subscription', 'devicesToYourPlan')}?`}</h4>
        <p className='m0'>{getTranslationText('subscription', 'enjoyHassleFree')}</p>
        <p className='mb20'>{getTranslationText('subscription', 'raiseAClaimFaster')}</p>
        <Button handleOnClick={handleOnAddMoreDevicesClick} className='button button-primary button-small'>
          {getTranslationText('subscription', 'letsDoThis')}
        </Button>
        <a href={appConfigDetails.subscriptionRedirectURL || '/'} className='button button-link d-block mt20'>
          {BrandSpecificConfig().ClientName} {getTranslationText('common', 'home')}
        </a>
      </div>
    </>
  ) : null
}

export default Success
