import { connect } from 'react-redux'
import {
  getTrackDetailsAsync,
  getRequestDetailsAsync,
  getServicetypeDetailsAsync,
  cancelRequestAsync,
  servicefeedbackAsync,
  rescheduleSlotsAsync,
  rescheduleRequestAsync,
  getInvoiceAsync,
  updateEstimationAsync,
  setDeviceDataforRequest,
  initiatePaymentAsync,
  setPaymentDetails,
  getSignedUrlAsync,
  berModeSelectionAsync,
  fetchBERModeListAysnc,
  updateAccessoriesStatusAsync,
  addUploadedImageToPendingDocsAsync,
  addUploadedImageToModalPendingDocsAsync,
  getLogisticsDetailsAsync,
  sendLogisticEmailAsync,
  treatmentsAsync,
  updateCourierDetailsAsync,
  fetchCourierPartners,
  storeReplacementOptions,
  addCSRDocToDSAsync,
  setAddressPreviewDetails,
  viewJobSheetDetailsAsync,
  addReplacementDeviceIMEIAsync,
  getDropOffCentersAsync,
  getCancelReasonDetailsAsync,
  setTrackHoldPaymentStatus,
  setReassignServiceLocationFlag,
} from '../../../store/track'
import { storeLastKnowPathName } from '../../../store/location'
import {
  getPaginatedDevicesAsync,
  getProductDocsAsync,
  pendingDocsListAsync,
  addProductDocAsync,
  clearDeviceState,
} from '../../../store/devices'
import {
  getNotificationsAsync,
  unsetSignedUrl,
  getCountriesAsync,
  getClaimLegalConfigs,
  addLargeFileToDSAsync,
  uploadLargeFileToS3,
  acknowledgeUploadDocToDSAsync,
  getUploadDocToDSAsync,
  setDSFileUploadDetails,
} from '../../../store/user'
import { setToaster, setglobalLoaderStatus } from '../../../store/toastsAndLoaders'
import {
  addToVisitedUrl,
  setRequestFlowSource,
  getRequestModesAsync,
  getRequestSlotsAsync,
  convertDropOffToPickupAsync,
  setSelectedDevice,
  setSelectedMode,
  setUserRequestLocation,
  setSelectedDamageType,
  setClaimDetails,
  getPayoutModesAsync,
  getGeoLocationAsync,
} from '../../../store/request'
import { advancePayAsync, getAdvancePaymentDetails, clearPaymentDetails } from '../../../store/payment'
// import {
//   getPaymentGateways,
//   getCCAvenuePaymentDetails,
//   advancePayAsync,
//   getAdvancePaymentDetails,
//   clearPaymentDetails
// } from '../../../store/payment'
/*  This is a container component. Notice it does not contain any JSX,
    nor does it import React. This component is **only** responsible for
    wiring in the actions and state necessary to render a presentational
    component - in this case, the counter:   */

import Track from '../components/Track'

/*  Object of action creators (can also be function that returns object).
    Keys will be passed as props to presentational components. Here we are
    implementing our wrapper around increment; the component doesn't care   */

const mapDispatchToProps = {
  // getPaymentGateways,
  // getCCAvenuePaymentDetails,
  getTrackDetailsAsync,
  getRequestDetailsAsync,
  getServicetypeDetailsAsync,
  treatmentsAsync,
  cancelRequestAsync,
  rescheduleSlotsAsync,
  rescheduleRequestAsync,
  getInvoiceAsync,
  updateEstimationAsync,
  servicefeedbackAsync,
  setDeviceDataforRequest,
  sendLogisticEmailAsync,
  getNotificationsAsync,
  unsetSignedUrl,
  initiatePaymentAsync,
  setToaster,
  setPaymentDetails,
  fetchBERModeListAysnc,
  berModeSelectionAsync,
  getPaginatedDevicesAsync,
  getProductDocsAsync,
  updateAccessoriesStatusAsync,
  pendingDocsListAsync,
  getSignedUrlAsync,
  addUploadedImageToPendingDocsAsync,
  addUploadedImageToModalPendingDocsAsync,
  getLogisticsDetailsAsync,
  addProductDocAsync,
  addToVisitedUrl,
  setRequestFlowSource,
  getRequestModesAsync,
  getRequestSlotsAsync,
  convertDropOffToPickupAsync,
  setSelectedDevice,
  setSelectedMode,
  setUserRequestLocation,
  setglobalLoaderStatus,
  storeLastKnowPathName,
  setSelectedDamageType,
  setClaimDetails,
  updateCourierDetailsAsync,
  fetchCourierPartners,
  advancePayAsync,
  getAdvancePaymentDetails,
  clearPaymentDetails,
  storeReplacementOptions,
  getPayoutModesAsync,
  addCSRDocToDSAsync,
  getCountriesAsync,
  setAddressPreviewDetails,
  viewJobSheetDetailsAsync,
  addReplacementDeviceIMEIAsync,
  getClaimLegalConfigs,
  getDropOffCentersAsync,
  getGeoLocationAsync,
  getCancelReasonDetailsAsync,
  setTrackHoldPaymentStatus,
  setReassignServiceLocationFlag,
  addLargeFileToDSAsync,
  uploadLargeFileToS3,
  acknowledgeUploadDocToDSAsync,
  getUploadDocToDSAsync,
  setDSFileUploadDetails,
  clearDeviceState,
}

const mapStateToProps = (state) => ({
  user: state.user,
  track: state.track,
  devices: state.devices,
  request: state.request,
  translationData: state.languageDetail.translationText,
  location: state.location,
  payment: state.payment,
})

/*  Note: mapStateToProps is where you should use `reselect` to create selectors, ie:

    import { createSelector } from 'reselect'
    const counter = (state) => state.counter
    const tripleCount = createSelector(counter, (count) => count * 3)
    const mapStateToProps = (state) => ({
      counter: tripleCount(state)
    })

    Selectors can compute derived data, allowing Redux to store the minimal possible state.
    Selectors are efficient. A selector is not recomputed unless one of its arguments change.
    Selectors are composable. They can be used as input to other selectors.
    https://github.com/reactjs/reselect    */

export default connect(mapStateToProps, mapDispatchToProps)(Track)
