import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { MediumButton, Heading, CustomIconComponent } from '../../../components/UIComponents'
import { Modal } from 'sfy-react'
import { browserHistory } from 'react-router'
import { getTranslationText } from '../../../utils/languageTranslation'
import { BoxHolder } from '../../../components/UIComponents/ReusableComponents/ReusableComponents'
import { getLocaleCurrency } from '../../../utils/locale'
import './Payment.scss'
import _ from 'lodash'
import pushToAnalytics from '../../../utils/Analytics'

export const PaymentPortal = (props) => {
  useEffect(() => {
    pushToAnalytics('Payment_page', {
      ConsumerID: _.get(props, 'user.userDetails.consumerID', undefined),
    })
  })
  let razorpay = new RegExp('razorpay', 'i')
  let stripe = new RegExp('stripe', 'i')
  let iyzipay = new RegExp('iyzipay', 'i')
  if (props.payment.payableDetails && razorpay.test(props.payment.payableDetails.gateway)) {
    return <div className='col-md-6 col-md-offset-3 mustHaveMinHeight mt48 mb8' />
  } else if (props.pollingFor3dSecure) {
    return (
      <div className='col-md-6 col-md-offset-3 mustHaveMinHeight mt48 mb8'>
        {getTranslationText('payment', 'completeTransaction')}
        <div className='mb8'>({getTranslationText('payment', 'closeRefreshWindow')})</div>
        <div className='mb8'>{props.pollMessage ? props.pollMessage : ''}</div>
        <div className='mb8'>{props.pollTimer !== undefined ? 'Redirecting in ' + props.pollTimer + ' ...' : ''}</div>
      </div>
    )
  } else if (props.payment.payableDetails && stripe.test(props.payment.payableDetails.gateway)) {
    const locale = _.get(props, 'user.selectedCountryData.countryConfig.Locale.DEFAULT', 'en')
    const currencyCode = _.get(props, 'user.selectedCountryData.CurrencyCode')
    return (
      <BoxHolder className='col-lg-4 col-md-6 col-sm-8 payment-card-container mt28'>
        <Heading className='font-large text-center' title={getTranslationText('payment', 'paymentLabel')} />
        <div className='col-sm-12 mt28 mb28'>
          {!props.isLoading ? (
            <form onSubmit={props.handleCardFormSubmit} id='payment-form'>
              {!(
                (props.store.selectedPaymentOption &&
                  props.store.selectedPaymentOption.maxDiscountOffer &&
                  props.store.selectedPaymentOption.maxDiscountOffer.hasConcession) ||
                props.location.lastpathName.path === '/devices' ||
                props.location.lastpathName.path === '/home' ||
                props.location.lastpathName.path === '/myplans'
              ) && props.payment?.payableDetails?.amount ? (
                <div className={'form-row form-row-wrapper ' + (window.isDirectionRTL ? ' text-right ' : ' text-left')}>
                  <label className='label_text'>{getTranslationText('payment', 'totalAmountLabel')}</label>
                  <div className='font-large'>{getLocaleCurrency(locale, currencyCode, props.payment.payableDetails.amount / 100)}</div>
                </div>
              ) : (
                ''
              )}
              <div className={'form-row form-row-wrapper ' + (window.isDirectionRTL ? ' text-right ' : ' text-left')}>
                <div id='card-element' className='col-sm-12' />
                <div id='card-errors' className='mt8 mb8 error' role='alert' />
              </div>
              <hr className='mb28' />
              <div className={`form-row ${window.isDirectionRTL ? 'text-right ' : 'text-left'}`}>
                <MediumButton
                  buttonText={getTranslationText(
                    'payment',
                    props.location.lastpathName.path !== '/devices' &&
                      props.location.lastpathName.path !== '/home' &&
                      props.location.lastpathName.path !== '/myplans'
                      ? 'submitPayment'
                      : 'updatePayment'
                  )}
                  className='checkout-button'
                  action='submit'
                  disabled={props.isLoadingCheckout || !props.stripeFormStatus}
                  btnLoader={props.isLoadingCheckout}
                />
              </div>
            </form>
          ) : (
            ''
          )}
        </div>
      </BoxHolder>
    )
  } else if (props.payment.payableDetails && iyzipay.test(props.payment.payableDetails.gateway)) {
    return <div id='iyzipay-checkout-form' className='responsive'></div>
  } else {
    return <div />
  }
}

PaymentPortal.propTypes = {
  user: PropTypes.object.isRequired,
  payment: PropTypes.object.isRequired,
  pollingFor3dSecure: PropTypes.bool,
  pollMessage: PropTypes.string,
  pollTimer: PropTypes.number,
  isLoading: PropTypes.bool,
  isLoadingCheckout: PropTypes.bool,
  handleCardFormSubmit: PropTypes.func.isRequired,
}

export class PaymentStatus extends React.Component {
  constructor(props) {
    super(props)
  }

  getMessageFromStatus = (status) => {
    switch (status) {
      case 'succeeded':
        this.props.setPaymentStatus('success')
        return ''

      case 'processing':
        this.props.setPaymentStatus('processing')
        return getTranslationText('payment', 'processingStatusMsg')

      case 'requires_payment_method':
        this.props.setPaymentStatus('failure')
        return getTranslationText('payment', 'failureStatusMsg')

      default:
        this.props.setPaymentStatus('failure')
        return getTranslationText('error', 'somethingWentWrong')
    }
  }

  componentDidMount() {
    const locale = localStorage.getItem('languageCode') || _.get(this, 'props.user.selectedCountryData.countryConfig.Locale.DEFAULT', 'en')
    let stripeObj = { locale }
    if (this.props.payment?.payableDetails?.stripe?.connectedAccountID)
      stripeObj.stripeAccount = this.props.payment.payableDetails.stripe.connectedAccountID
    const stripe = window.Stripe(this.props.payment.payableDetails.apiKey, stripeObj)
    const isSetupIntentFlow = _.get(this, 'props.payment.payableDetails.stripe.setupIntent', false)
    const clientSecretKeyName = isSetupIntentFlow ? 'setup_intent_client_secret' : 'payment_intent_client_secret'
    const clientSecret = new URLSearchParams(window.location.search).get(clientSecretKeyName)

    if (isSetupIntentFlow) {
      stripe
        .retrieveSetupIntent(clientSecret)
        .then(({ setupIntent }) => {
          const shouldCallCharge = setupIntent.status === 'succeeded' || setupIntent.status === 'processing'
          let response = this.getMessageFromStatus(setupIntent.status)
          /********************** StripeCallback - Logger API /**********************/
          if (_.get(this, 'props.user.userDetails.consumerID', false) && window.useLogger) {
            let loggerObj = {
              apiName: 'StripeCallback',
              value: { payableDetails: _.get(this, 'props.payment.payableDetails', undefined), setupIntent, response },
              event: 'StripeCallback',
              identifiers: {
                ConsumerID: _.get(this, 'props.user.userDetails.consumerID', undefined),
              },
            }
            this.props.loggerActionAsync(loggerObj)
          }
          /********************** StripeCallback - Logger API /**********************/
          this.props.setStatusMessage(response)
          this.props.setglobalLoaderStatus(false)
          shouldCallCharge && this.props.callChargeAPI(setupIntent)
        })
        .catch((error) => {
          console.log(error)
          /********************** StripeCallbackError - Logger API /**********************/
          if (_.get(this, 'props.user.userDetails.consumerID', false) && window.useLogger) {
            let loggerObj = {
              apiName: 'StripeCallbackError',
              value: { payableDetails: _.get(this, 'props.payment.payableDetails', undefined), error },
              event: 'StripeCallbackError',
              identifiers: {
                ConsumerID: _.get(this, 'props.user.userDetails.consumerID', undefined),
              },
            }
            this.props.loggerActionAsync(loggerObj)
          }
          /********************** StripeCallbackError - Logger API /**********************/
          this.props.setglobalLoaderStatus(false)
          this.props.setToaster(getTranslationText('error', 'somethingWentWrong'), 'error')
        })
    } else {
      stripe
        .retrievePaymentIntent(clientSecret)
        .then(({ paymentIntent }) => {
          const shouldCallCharge = paymentIntent.status === 'succeeded' || paymentIntent.status === 'processing'
          let response = this.getMessageFromStatus(paymentIntent.status)
          /********************** StripeCallback - Logger API /**********************/
          if (_.get(this, 'props.user.userDetails.consumerID', false) && window.useLogger) {
            let loggerObj = {
              apiName: 'StripeCallback',
              value: { payableDetails: _.get(this, 'props.payment.payableDetails', undefined), response },
              event: 'StripeCallback',
              identifiers: {
                ConsumerID: _.get(this, 'props.user.userDetails.consumerID', undefined),
              },
            }
            this.props.loggerActionAsync(loggerObj)
          }
          /********************** StripeCallback - Logger API /**********************/
          this.props.setStatusMessage(response)
          this.props.setglobalLoaderStatus(false)
          shouldCallCharge && this.props.callChargeAPI(paymentIntent)
        })
        .catch((error) => {
          console.log(error)
          /********************** StripeCallbackError - Logger API /**********************/
          if (_.get(this, 'props.user.userDetails.consumerID', false) && window.useLogger) {
            let loggerObj = {
              apiName: 'StripeCallbackError',
              value: { payableDetails: _.get(this, 'props.payment.payableDetails', undefined), error },
              event: 'StripeCallbackError',
              identifiers: {
                ConsumerID: _.get(this, 'props.user.userDetails.consumerID', undefined),
              },
            }
            this.props.loggerActionAsync(loggerObj)
          }
          /********************** StripeCallbackError - Logger API /**********************/
          this.props.setglobalLoaderStatus(false)
          this.props.setToaster(getTranslationText('error', 'somethingWentWrong'), 'error')
        })
    }
  }
  retryPayment() {
    this.props.closeUpdateCardDetailsModal()
    const retryPayload = _.get(this, 'props.payment.payableDetails.retryPayload', '')
    if (retryPayload) {
      this.props.setglobalLoaderStatus(true)
      try {
        this.props
          .getPayableAmountForNonLoginFlow(retryPayload)
          .then(() => {
            this.props.setglobalLoaderStatus(false)
            pushToAnalytics('Change Payment Method - Retry', {
              ConsumerID: retryPayload.data.ConsumerID,
              SoldPlanID: retryPayload.data.SoldPlanID,
              PlanID: retryPayload.data.PlanArray[0].PlanID,
            })
            browserHistory.push({
              pathname: '/payment',
              query: {
                [this.props.location.query.region ? 'region' : '']: this.props.location.query.region,
              },
            })
            window.location.reload()
          })
          .catch(() => {
            this.props.setglobalLoaderStatus(false)
          })
      } catch (e) {
        console.log('e - ', e)
        this.props.setglobalLoaderStatus(false)
      }
    } else console.log('No payload data to retry')
  }

  render() {
    return this.props.request?.retryPaymentStatus &&
      this.props.location.lastpathName.path !== '/devices' &&
      this.props.location.lastpathName.path !== '/home' &&
      this.props.location.lastpathName.path !== '/myplans' ? (
      <Modal className='sfy-modal empty-fields-modal' showModal={this.props.retryPaymentStatus}>
        <CustomIconComponent
          className={'close-icon ' + (window.isDirectionRTL ? 'appear-left text-left ' : 'appear-right text-right')}
          onClick={() => {
            this.props.closeUpdateCardDetailsModal()
            browserHistory.replace('/')
          }}
        />
        <div className='text-center'>
          <img className='mb20 payment-failure-img' src={'/commonAssets/failed.png'} />
          <div className='boldFontStyle fontLarge mt20 mb10'>{getTranslationText('subscription', 'paymentfailed')}</div>
          <div className='bookFontStyle mt10 mb20'>
            <span>{getTranslationText('payment', 'paymentFailureSorry')}</span>
          </div>
          <MediumButton
            buttonText={getTranslationText('common', 'retry-payment')}
            className='mb20'
            onClick={() => {
              this.props.closeUpdateCardDetailsModal()
              this.props.location?.lastpathName && this.props.location.lastpathName?.path === '/track'
                ? browserHistory.push({
                    pathname: this.props.location.lastpathName.path,
                    query: {
                      ...this.props.location.lastpathName.params,
                      step: this.props.location.lastpathName.path === '/track' ? undefined : 3,
                      [this.props.location.query?.region ? 'region' : '']: this.props.location.query.region,
                    },
                  })
                : browserHistory.push({
                    pathname: this.props.location.lastpathName.path,
                    query: this.props.location.lastpathName.params,
                  })
            }}
          />
        </div>
      </Modal>
    ) : this.props.showUpdateCardDetailsModal ? (
      <Modal className='sfy-modal empty-fields-modal' showModal={this.props.showUpdateCardDetailsModal}>
        <CustomIconComponent
          className={'close-icon ' + (window.isDirectionRTL ? 'appear-left text-left ' : 'appear-right text-right')}
          onClick={() => browserHistory.replace('/')}
        />
        <div className='text-center'>
          <img
            className='mb20 payment-failure-img'
            src={this.props.cardDetailsUpdateStatus ? '/commonAssets/successImg.png' : '/commonAssets/failed.png'}
          />
          <div className='boldFontStyle fontLarge mt20 mb10'>
            {getTranslationText('payment', this.props.cardDetailsUpdateStatus ? 'paymentMethodUpdated' : 'paymentMethodUpdateFailed')}
          </div>
          <div className='bookFontStyle mt10 mb20'>
            <span>
              {getTranslationText(
                'payment',
                this.props.cardDetailsUpdateStatus
                  ? _.get(this, 'props.myPlans.soldPlanDetails.Status') === 1
                    ? 'paymentMethodUpdatedMsg'
                    : 'paymentMethodUpdatedMsg2'
                  : 'paymentMethodUpdatFailedMsg'
              )}
            </span>
          </div>
          <MediumButton
            buttonText={getTranslationText('common', this.props.cardDetailsUpdateStatus ? 'okay' : 'retry')}
            className='button-ghost mb20'
            onClick={() => {
              this.props.cardDetailsUpdateStatus
                ? this.props.location.lastpathName.path === '/home'
                  ? browserHistory.replace('/')
                  : browserHistory.push({
                      pathname: this.props.location.lastpathName.path,
                      query: {
                        ...this.props.location.lastpathName.params,
                        [this.props.location.query?.region ? 'region' : '']: this.props.location.query.region,
                      },
                    })
                : this.retryPayment()
            }}
          />
        </div>
      </Modal>
    ) : (
      <div className='row justify-content-center mb28'>
        <BoxHolder className='col-lg-4 col-md-6 col-sm-8 payment-card-container mt28'>
          <div className='payment-status-container'>
            {this.props.paymentStatus === 'processing' && (
              <img className='mb28 payment-status-img' src={'/commonAssets/failure-alert.png'} />
            )}
            {this.props.paymentStatus === 'failure' && <img className='mb28 payment-status-img' src={'/commonAssets/failed.png'} />}
            <span className='payment-status-text'>{this.props.statusMessage}</span>
          </div>
        </BoxHolder>
      </div>
    )
  }
}
