import React from 'react'
import PropTypes from 'prop-types'
import { BoxHolder, Heading, MediumButton, Loader, Accordion, CustomIconComponent } from '../../../components/UIComponents'
import { browserHistory, Link } from 'react-router'
import { Modal, Checkbox, Tooltip } from 'sfy-react'
import _ from 'lodash'
import { getTranslationText } from '../../../utils/languageTranslation'
// import MyPlansDevices from '../../../../public/images/commonAssets/servifycare/MyPlans_Devices.png'
// import MyPlansSmartphone from '../../../../public/images/commonAssets/servifycare/MyPlans_Smartphone.png'
// import menuIcon from '../../../../public/images/commonAssets/servifycare/menu.png'
// import addIcon from '../../../../public/images/commonAssets/servifycare/add.png'
// import NoDeviceFound from '../../../../public/images/commonAssets/servifycare/NoDeviceFound.png'
// import images from './../../../images/config/images.json'
import { getLocaleCurrency, getLocaleDate } from '../../../utils/locale'
import { deviceCountFormatter } from '../../../utils/functions.js'
// const downArrow = '../../../' + _.get(images, 'common.DOWN_ARROW')
import { ReactComponent as DownArrow } from '../../../../public/commonAssets/down-arrow.svg'
import { ReactComponent as RightArrow } from '../../../../public/commonAssets/right-arrow.svg'
import { ReactComponent as DevicesIcon } from '../../../../public/commonAssets/devices.svg'
import { ReactComponent as PlansAddIcon } from '../../../../public/commonAssets/plans-add.svg'
const InfoIcon = '/commonAssets/info-alert.svg'

const Tab = (props) => {
  return (
    <div
      id={props.id}
      className={'store-tab-plans fontMedium overrideCasing ' + (props.id === parseInt(props.selectedTab) ? 'active' : '')}
      onClick={() => {
        props.handleClick(props.id)
      }}
    >
      <div className={props.id === parseInt(props.selectedTab) ? ' boldFontStyle ' : ' mediumFontStyle'}>{props.children}</div>
    </div>
  )
}

Tab.propTypes = {
  id: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  handleClick: PropTypes.func.isRequired,
  selectedTab: PropTypes.number.isRequired,
  isCancelledPlan: PropTypes.any,
}
export const ListofPlans = (props) => {
  if (props.PlanDetailsJson && props.PlanDetailsJson.length) {
    const dateFormat = _.get(props, 'user.selectedCountryData.countryConfig.DateFormat.DEFAULT', 'DD/MM/YYYY')
    const locale = _.get(props, 'user.selectedCountryData.countryConfig.Locale.DEFAULT', 'en')
    const currencyCode = _.get(props, 'user.selectedCountryData.CurrencyCode')
    return (
      <div className='store-view-container'>
        <div className='store-tab-section'>
          {_.compact(
            props.PlanDetailsJson.map((List, rootIndex) => {
              return (
                <Tab
                  key={rootIndex}
                  id={rootIndex}
                  selectedTab={props.planViewSelectedTab}
                  handleClick={() => props.listPlanViewDetails(rootIndex)}
                >
                  {List.Heading}
                </Tab>
              )
            })
          )}
        </div>
        <BoxHolder>
          <div className='list-of-myplans-container bg-color'>
            {_.compact(
              props.PlanDetailsJson.map((List, rootIndex) => {
                if (props.planViewSelectedTab === rootIndex) {
                  return (
                    <div className='row' key={rootIndex}>
                      {List.PlanList.length ? (
                        List.PlanList.map((plan, index) => {
                          let card_details = _.get(props, 'myPlans.soldPlanDetails.' + plan.SoldPlanID, {})
                          let dateLable1
                          let dateLable2
                          let planDetailsDate1
                          let planDetailsDate2
                          if (plan.IsEnrollmentPlan) {
                            if (plan.Status == 1) {
                              switch (plan.GroupSoldPlanStatus) {
                                case 1:
                                case -4:
                                  // active plans
                                  dateLable1 = getTranslationText('subscription', 'coverageStartDate')
                                  dateLable2 = getTranslationText('subscription', 'nextBillingDate')
                                  planDetailsDate1 = getLocaleDate(props.dateFormat, plan.CoverageStartDate)
                                  planDetailsDate2 = getLocaleDate(props.dateFormat, plan.NextBillingDate)
                                  break

                                case -5:
                                  // active plans // scenario 3 & 4
                                  dateLable1 = getTranslationText('subscription', 'cancellationDate')
                                  dateLable2 = getTranslationText('subscription', 'coverageEndDate')
                                  planDetailsDate1 = getLocaleDate(props.dateFormat, plan.PlanCancellationDate)
                                  planDetailsDate2 = getLocaleDate(props.dateFormat, plan.CoverageEndDate)
                                  break

                                case -3:
                                  // canceled plans
                                  dateLable1 = getTranslationText('subscription', 'planPurchaseDate')
                                  dateLable2 = getTranslationText('subscription', 'cancellationDate')
                                  planDetailsDate1 = getLocaleDate(props.dateFormat, plan.DateOfPurchase)
                                  planDetailsDate2 = getLocaleDate(props.dateFormat, plan.PlanCancellationDate)
                                  break

                                default:
                                  // active plan scenario -> 1 & 2
                                  dateLable1 = getTranslationText('subscription', 'coverageStartDate')
                                  dateLable2 = getTranslationText('subscription', 'nextBillingDate')
                                  planDetailsDate1 = getLocaleDate(props.dateFormat, plan.CoverageStartDate)
                                  planDetailsDate2 = getLocaleDate(props.dateFormat, plan.NextBillingDate)
                                  break
                              }
                            } else if (plan.Status == -4) {
                              switch (plan.GroupSoldPlanStatus) {
                                case -3:
                                  // canceled plans // scenario 5 & 6
                                  dateLable1 = getTranslationText('subscription', 'planPurchaseDate')
                                  dateLable2 = getTranslationText('subscription', 'cancellationDate')
                                  planDetailsDate1 = getLocaleDate(props.dateFormat, plan.DateOfPurchase)
                                  planDetailsDate2 = getLocaleDate(props.dateFormat, plan.PlanCancellationDate)
                                  break

                                default:
                                  dateLable1 = getTranslationText('subscription', 'planPurchaseDate')
                                  dateLable2 = getTranslationText('subscription', 'cancellationDate')
                                  planDetailsDate1 = getLocaleDate(props.dateFormat, plan.DateOfPurchase)
                                  planDetailsDate2 = getLocaleDate(props.dateFormat, plan.PlanCancellationDate)
                                  break
                              }
                            } else if (plan.Status == -9) {
                              switch (plan.GroupSoldPlanStatus) {
                                case -9:
                                  dateLable1 = getTranslationText('subscription', 'coverageStartDate')
                                  dateLable2 = getTranslationText('subscription', 'nextBillingDate')
                                  planDetailsDate1 = getLocaleDate(props.dateFormat, plan.CoverageStartDate)
                                  planDetailsDate2 = getLocaleDate(props.dateFormat, plan.NextBillingDate)
                                  break

                                default:
                                  dateLable1 = getTranslationText('subscription', 'planPurchaseDate')
                                  dateLable2 = getTranslationText('subscription', 'cancellationDate')
                                  planDetailsDate1 = getLocaleDate(props.dateFormat, plan.DateOfPurchase)
                                  planDetailsDate2 = getLocaleDate(props.dateFormat, plan.PlanCancellationDate)
                                  break
                              }
                            } else {
                              switch (plan.Status) {
                                case -6:
                                case -5:
                                case -4:
                                case 3:
                                case 4:
                                  dateLable1 = getTranslationText('subscription', 'planPurchaseDate')
                                  dateLable2 = getTranslationText('subscription', 'cancellationDate')
                                  planDetailsDate1 = getLocaleDate(props.dateFormat, plan.DateOfPurchase)
                                  planDetailsDate2 = getLocaleDate(props.dateFormat, plan.PlanCancellationDate)
                                  break

                                default:
                                  dateLable1 = getTranslationText('subscription', 'coverageStartDate')
                                  dateLable2 = getTranslationText('subscription', 'nextBillingDate')
                                  planDetailsDate1 = getLocaleDate(props.dateFormat, plan.CoverageStartDate)
                                  planDetailsDate2 = getLocaleDate(props.dateFormat, plan.NextBillingDate)
                              }
                            }
                          } else {
                            switch (plan.Status) {
                              case 1:
                                // active plans - one time purchase plans
                                dateLable1 = getTranslationText('subscription', 'coverageStartDate')
                                dateLable2 = getTranslationText('subscription', 'coverageEndDate')
                                planDetailsDate1 = getLocaleDate(props.dateFormat, plan.DateOfPurchase)
                                planDetailsDate2 = getLocaleDate(props.dateFormat, plan.ValidityDate)
                                break

                              default:
                                dateLable1 = getTranslationText('subscription', 'planPurchaseDate')
                                dateLable2 = getTranslationText('subscription', 'cancellationDate')
                                planDetailsDate1 = getLocaleDate(props.dateFormat, plan.DateOfPurchase)
                                planDetailsDate2 = getLocaleDate(props.dateFormat, plan.PlanCancellationDate)
                                break
                            }
                          }
                          return (
                            <div key={index} className='plan-container'>
                              <div
                                className='list-of-myplans mb20 phoneLabelHolderContent transitionClass'
                                id={plan.SoldPlanID}
                                onClick={() => props.viewSelectedPlan(plan)}
                              >
                                {plan.Status == 1 && plan.GroupSoldPlanStatus == -5 ? (
                                  plan.CancellationDaysLeft > 0 ? (
                                    <div className='coverage-starts'>
                                      {getTranslationText('subscription', 'planWillCancelInDays', '', '@days@', plan.CancellationDaysLeft)}
                                    </div>
                                  ) : plan.CoverageDaysLeft > 0 ? (
                                    <div className='coverage-starts'>
                                      {getTranslationText('subscription', 'coverageEndsInDays', '', '@days@', plan.CoverageDaysLeft)}
                                    </div>
                                  ) : (
                                    ''
                                  )
                                ) : (
                                  ''
                                )}
                                {/* 3 and 4 */}
                                {plan.Status == -9 && plan.GroupSoldPlanStatus == -9 ? (
                                  <div className='coverage-starts'>{getTranslationText('subscription', 'paymentfailed')}</div>
                                ) : (
                                  ''
                                )}
                                {plan.Status == 1 && plan.CoolingPeriodDays > 0 ? (
                                  <div className='coverage-starts'>
                                    {getTranslationText('subscription', 'coverageStartsInDays', '', '@days@', plan.CoolingPeriodDays)}
                                  </div>
                                ) : (
                                  ''
                                )}
                                {/* 1 and 2 */}

                                <div className='plan-div-container'>
                                  <div className='plan-img1'>
                                    <img src={plan.PlanEmailImage} />
                                  </div>
                                  <div className='plan-div-content'>
                                    <div className='boldFontStyle fontMedium mb4 special-link-txt' id={'plan-' + rootIndex}>
                                      {plan.PlanDisplayName}
                                    </div>
                                    {/* {plan.PlanAmount ? (
                                      <div className='lightFontStyle mb4 payment-method-val'>
                                        <span>
                                          {getLocaleCurrency(props.locale, props.currencyCode, plan.PlanAmount)}
                                          {plan.BillingDuration ? `/${plan.BillingDuration}` : ''}
                                        </span>
                                      </div>
                                    ) : (
                                      ''
                                    )} */}
                                  </div>
                                </div>

                                <div className='lightFontStyle devices-protected overrideCasing mt20 deviceProtectIcon'>
                                  {plan.NoOfDevicesCovered && plan.NoOfDevicesCovered > 0
                                    ? `${
                                        deviceCountFormatter(plan.NoOfDevicesCovered) +
                                        ' ' +
                                        getTranslationText('device', 'devicesProtectedPostfix')
                                      }`
                                    : getTranslationText('device', 'noDevicesProtected')}
                                  <RightArrow />
                                </div>

                                <div className='plan-validity mt20 pb8'>
                                  <div className='plan-date'>
                                    <div className='lightFontStyle payment-method-title dateLable'>{dateLable1}</div>
                                    <div className='text-date mediumFontStyle payment-method-val'>{planDetailsDate1}</div>
                                  </div>
                                  <div className='plan-date'>
                                    <div className='lightFontStyle payment-method-title dateLable'>{dateLable2}</div>
                                    <div className='text-date mediumFontStyle payment-method-val'>{planDetailsDate2}</div>
                                  </div>
                                </div>
                              </div>
                              {plan.Category !== 'NORMAL' && plan.FrequencyUnit && plan.RequiresExternalPayment === 'SERVIFY_PAYMENT' ? (
                                <div className='payment-method-parent'>
                                  <div className='col-sm-12'>
                                    {plan.Status === -9 ? (
                                      <>
                                        <div className='lightFontStyle smallFont auto-debit-failed-msg mt16'>
                                          {getTranslationText('device', 'autoDebitFailedMsg')
                                            .replace('@amount@', getLocaleCurrency(locale, currencyCode, plan.PlanAmount))
                                            .replace('@plan_name@', plan.PlanName)
                                            .replace('@pending_months@', plan.NoOfPaymentPending)
                                            .replace(
                                              '@billing_date@',
                                              plan.EffectiveDate ? getLocaleDate(dateFormat, plan.EffectiveDate) : ''
                                            )
                                            .replace(
                                              '@grace_period_enddate@',
                                              plan.HoldPeriodDate ? getLocaleDate(dateFormat, plan.HoldPeriodDate) : ''
                                            )}
                                        </div>
                                        <div className='bookFontStyle fontMedium purple-link plan-benefit center-align'>
                                          <a
                                            onClick={() => window.open(plan.PaymentLink || '', '_blank')}
                                            // href={redirectionUrl}
                                            target='_blank'
                                            className='inline-ele-Casing mb8'
                                            rel='noreferrer'
                                          >
                                            <span>{getTranslationText('common', 'pay')}</span>
                                          </a>
                                        </div>
                                      </>
                                    ) : (
                                      ''
                                    )}
                                    {card_details && card_details.Type === 'card' ? (
                                      <>
                                        <div className='lightFontStyle payment-method-title mt16'>
                                          {getTranslationText('device', 'paymentMethod')}
                                        </div>
                                        <div className='lightFontStyle payment-method-label mt4'>
                                          {getTranslationText('device', 'cardNumber')}
                                          <span className='boldFontStyle payment-method-val mt4 ml4'>
                                            {card_details && card_details.LastFourDigits
                                              ? 'XXXXXXXXXXXX' + card_details.LastFourDigits
                                              : '-'}
                                          </span>
                                        </div>
                                        <div className='lightFontStyle payment-method-label mt4'>
                                          {getTranslationText('device', 'cardType')}
                                          <span className='mediumFontStyle payment-method-val text-title-case mt4 ml4'>
                                            {card_details && card_details.Brand ? card_details.Brand : '-'}
                                          </span>
                                        </div>
                                        <div className='lightFontStyle payment-method-label mt4'>
                                          {getTranslationText('device', 'expiry')}
                                          <span className='mediumFontStyle payment-method-val mt4 ml4'>
                                            {card_details && card_details.ExpiryMonth && card_details.ExpiryYear
                                              ? (card_details.ExpiryMonth.toString().length < 2
                                                  ? '0' + card_details.ExpiryMonth
                                                  : card_details.ExpiryMonth) +
                                                '/' +
                                                card_details.ExpiryYear
                                              : '-'}
                                          </span>
                                        </div>
                                      </>
                                    ) : (
                                      ''
                                    )}
                                    {card_details && card_details.Type === 'sepa_debit' ? (
                                      <>
                                        <div className='lightFontStyle payment-method-title mt16'>
                                          {getTranslationText('device', 'paymentMethod')}
                                        </div>
                                        <div className='lightFontStyle payment-method-label mt4'>
                                          {getTranslationText('device', 'iban') + ':'}
                                          <span className='boldFontStyle payment-method-val mt4 ml4'>
                                            {card_details && card_details.LastFourDigits
                                              ? 'XXXXXXXXXXXX' + card_details.LastFourDigits
                                              : '-'}
                                          </span>
                                        </div>
                                        <div className='lightFontStyle payment-method-label mt4'>
                                          {getTranslationText('device', 'paymentMode') + ':'}
                                          <span className='mediumFontStyle payment-method-val text-title-case mt4 ml4'>
                                            {getTranslationText('device', 'sepa')}
                                          </span>
                                        </div>
                                        <div className='lightFontStyle payment-method-label mt4'>
                                          {getTranslationText('device', 'bankCode') + ':'}
                                          <span className='mediumFontStyle payment-method-val mt4 ml4'>
                                            {card_details && card_details.BankCode ? card_details.BankCode : '-'}
                                          </span>
                                        </div>
                                      </>
                                    ) : (
                                      ''
                                    )}
                                    {[1, -9, 10].includes(plan.Status) &&
                                    plan.NextBillingDate &&
                                    plan.RequiresExternalPayment === 'SERVIFY_PAYMENT' &&
                                    (plan.FrequencyUnit === 'MONTH' || plan.FrequencyUnit === 'YEAR') ? (
                                      [1, 10].includes(plan.Status) && [1, 0].includes(plan.TransactionStatus) ? (
                                        <div className='bookFontStyle fontMedium purple-link center-align mt24'>
                                          <a onClick={() => props.onClickChangePaymentMethod(plan)} className='inline-ele-Casing mb8'>
                                            <span>{getTranslationText('device', 'changePaymentMethod')}</span>
                                          </a>
                                        </div>
                                      ) : [1, -9].includes(plan.Status) && [5, -2].includes(plan.TransactionStatus) ? (
                                        <div className='bookFontStyle fontMedium center-align disabled-text mt24'>
                                          <span>{getTranslationText('device', 'changePaymentMethod')}</span>
                                          <Tooltip content={getTranslationText('device', 'optionDisabled')} position='top'>
                                            <img className='ml4 info-icon' src={InfoIcon} />
                                          </Tooltip>
                                        </div>
                                      ) : (
                                        ''
                                      )
                                    ) : (
                                      ''
                                    )}
                                    {plan.Category !== 'NORMAL' && plan.RequiresExternalPayment === 'SERVIFY_PAYMENT' ? (
                                      <div className='mediumFontStyle fontMedium center-align purple-link mt16'>
                                        <a
                                          onClick={() => {
                                            props.viewBillingHistory(plan.SoldPlanID, plan)
                                          }}
                                          // href={redirectionUrl}
                                          target='_blank'
                                          className='inline-ele-Casing mb8'
                                          rel='noreferrer'
                                        >
                                          <span>{getTranslationText('device', 'viewBillingHistory')}</span>
                                        </a>
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                          )
                        })
                      ) : (
                        <div className='col-sm-12 mt20 mb20 font-weight-bold text-center'>
                          {getTranslationText('common', 'noPlansPrefix') + ' ' + List.Heading}
                        </div>
                      )}
                    </div>
                  )
                }
              })
            )}
          </div>
        </BoxHolder>
      </div>
    )
  } else {
    return <div>{getTranslationText('common', 'noActivePlans')}</div>
  }
}
ListofPlans.propTypes = {
  user: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  selectedPlanDetails: PropTypes.func,
  planViewSelectedTab: PropTypes.any,
}

export const MyPlansMainComponents = (props) => {
  return (
    <div className='row mb20 mt20'>
      <div className={'col-sm-12 mb20 col-lg-12'}>
        <ListofPlans {...props} />
      </div>
    </div>
  )
}
MyPlansMainComponents.propTypes = {
  user: PropTypes.object.isRequired,
}

export class SelectedPlansSubscriptionDetails extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedPlanDetails: {},
      selectedCartId: 'new',
      showDeviceAdditionModal: false,
      updatedFaqList: [],
      faqIteration: 5,
    }
  }

  componentDidMount() {
    _.get(this, 'props.PlanDetailsJson', []).length >= 1 &&
      _.compact(
        this.props.PlanDetailsJson.map((List) => {
          const soldPlanID = _.get(this, 'props.location.query.subsciption_id')
          const isActive = _.get(this, 'props.location.query.isActive')
          console.log('isActive - ', isActive)
          if (isActive) {
            (List['Heading'] === getTranslationText('common', 'activePlans') ||
              List['Heading'] === getTranslationText('common', 'onHoldPlans')) &&
              List.PlanList.map((plan) => {
                if (plan.SoldPlanID == soldPlanID) {
                  let plan_ = plan
                  if (!Object.keys(this.props.myPlans.selectedPlanDetails).length) {
                    this.props.setSelectedPurchasedPlanDetails(plan)
                  }
                  this.setState({
                    selectedPlanDetails: plan,
                    updatedFaqList: plan_.FaqList && plan_.FaqList.slice(0, 5),
                  })
                }
              })
          } else {
            List.PlanList.map((plan) => {
              if (plan.SoldPlanID == soldPlanID) {
                this.setState({ selectedPlanDetails: plan })
              }
            })
          }
        })
      )
  }

  handleBackBtnClick = () => {
    browserHistory.goBack()
  }

  deviceAddCartSelection = (selectedId) => {
    this.setState({
      selectedCartId: selectedId,
    })
  }

  toggleModalHandler = () => {
    this.setState({
      showDeviceAdditionModal: !this.state.showDeviceAdditionModal,
      selectedCartId: 'new',
    })
  }

  addDeviceHandler = () => {
    var planPath = 'my_plans'
    localStorage.setItem('checkTitlePath', planPath)
    this.setState({
      showDeviceAdditionModal: true,
    })
  }

  loadMoreFaqs = async () => {
    let FaqList = this.state.selectedPlanDetails.FaqList
    let faqIteration_ = this.state.faqIteration
    let updatedFaqList = []
    await this.setState(
      {
        faqIteration: faqIteration_ + 5,
      },
      () => {
        FaqList.filter((v, index) => {
          if (index <= this.state.faqIteration - 1) {
            return updatedFaqList.push(v)
          }
        })
        this.setState({
          updatedFaqList,
        })
      }
    )
  }

  navigateToDeviceAddtion = (selectedFlow) => {
    this.setState({ showDeviceAdditionModal: false, showDeviceFetchLoaderModal: true }, async () => {
      await this.props.setSelectedPurchasedPlanDetails({ ...this.props.myPlans.selectedPlanDetails, selectedFlow: selectedFlow })
      if (selectedFlow === 'existing') {
        this.props.fetchEligibleDeviceAsync(this.state.selectedPlanDetails, (eligiblePlanDeviceList = []) => {
          this.props.setglobalLoaderStatus(false)
          if (eligiblePlanDeviceList.length > 0) {
            var planPath = 'my_plans'
            localStorage.setItem('checkTitlePath', planPath)
            console.log('props.devices.eligiblePlanDeviceList', this.props.devices.eligiblePlanDeviceList)
            browserHistory.replace({
              pathname: '/devices/add-eligible-devices',
              query: {
                path: 'myplans',
              },
            })
          } else {
            this.setState(
              {
                showDeviceFetchLoaderModal: false,
              },
              () => {
                this.props.setDeviceAdditionModal(true)
              }
            )
          }
        })
      } else {
        browserHistory.replace({
          pathname: '/devices/add-device',
          query: {
            path: 'myplans',
          },
        })
      }
    })
  }

  render() {
    const { selectedPlanDetails, updatedFaqList, faqIteration } = this.state
    const faqButtonDisabled = faqIteration < _.get(selectedPlanDetails, 'FaqList', []).length
    if (Object.keys(selectedPlanDetails).length) {
      return (
        <div className='row'>
          <div className='bck-btn mb20 special-link-txt' onClick={this.handleBackBtnClick}>
            <div className='bck-img-container'>
              <DownArrow />
            </div>
            <span className='overrideCasing mediumFontStyle fontMedium'>{getTranslationText('common', 'backLabel')}</span>
          </div>

          <div className='col-sm-12 mb8'>
            {selectedPlanDetails.Status == 1 && selectedPlanDetails.GroupSoldPlanStatus == -5 ? (
              selectedPlanDetails.CancellationDaysLeft > 0 ? (
                <div className='plan-view-coverage boldFontStyle'>
                  {getTranslationText('subscription', 'planWillCancelInDays', '', '@days@', selectedPlanDetails.CancellationDaysLeft)}
                </div>
              ) : selectedPlanDetails.CoverageDaysLeft > 0 ? (
                <div className='plan-view-coverage boldFontStyle'>
                  {getTranslationText('subscription', 'coverageEndsInDays', '', '@days@', selectedPlanDetails.CoverageDaysLeft)}
                </div>
              ) : (
                ''
              )
            ) : (
              ''
            )}
            {/* 3 and 4 */}

            {selectedPlanDetails.Status == 1 && selectedPlanDetails.CoolingPeriodDays > 0 ? (
              <div className='plan-view-coverage boldFontStyle'>
                {getTranslationText('subscription', 'coverageStartsInDays', '', '@days@', selectedPlanDetails.CoolingPeriodDays)}
              </div>
            ) : (
              ''
            )}
            {/* 1 and 2 */}
            <div className='box-holder fullWidth'>
              <div className='row'>
                <div className='col-sm-12 mb20'>
                  {selectedPlanDetails.Status == -9 && selectedPlanDetails.GroupSoldPlanStatus == -9 && (
                    <div className='payment-status'>{getTranslationText('subscription', 'paymentfailed')}</div>
                  )}
                  <div className='heading bookFontStyle rm_pl mb20'>
                    <Heading title={getTranslationText('subscription', 'subscriptionDetails')} />
                  </div>
                  <div className='plan-view-container'>
                    <div className='row'>
                      <div className='col-md-3'>
                        <div className='list-of-plans-view mb20 transitionClass'>
                          {selectedPlanDetails && selectedPlanDetails.PlanEmailImage && (
                            <div className='plan-div-container'>
                              <div className='plan-img1'>
                                <img src={selectedPlanDetails.PlanEmailImage} />
                              </div>
                              <div className='plan-div-content'>
                                <div className='boldFontStyle fontMedium mb4 special-link-txt'>{selectedPlanDetails.PlanDisplayName}</div>
                                <div className='lightFontStyle fontMedium planDescription'>{selectedPlanDetails.PlanDescription}</div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      {_.includes([-3, -5], selectedPlanDetails.GroupSoldPlanStatus) ? (
                        _.get(selectedPlanDetails, 'DateOfPurchase') && (
                          <div className='col-md-3'>
                            <div className='list-of-plans-view mb20 transitionClass'>
                              <div className='plan-div-container'>
                                <div className='plan-div-content'>
                                  <div className='lightFontStyle fontMedium'>{getTranslationText('subscription', 'cancellationDate')}</div>
                                  <div className='fontLarge overrideCasing overrideCasing'>
                                    {getLocaleDate(this.props.dateFormat, selectedPlanDetails.PlanCancellationDate)}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      ) : (
                        <div className='col-md-3'>
                          <div className='list-of-plans-view mb20 transitionClass'>
                            <div className='plan-div-container'>
                              <div className='plan-div-content'>
                                <div className='lightFontStyle fontMedium'>{getTranslationText('subscription', 'planPurchaseDate')}</div>
                                <div className='fontLarge overrideCasing overrideCasing'>
                                  {getLocaleDate(this.props.dateFormat, selectedPlanDetails.DateOfPurchase)}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {_.includes([-3, -5], selectedPlanDetails.GroupSoldPlanStatus) ? (
                        <div className='col-md-3'>
                          <div className='list-of-plans-view mb20 transitionClass'>
                            <div className='plan-div-container'>
                              <div className='plan-div-content'>
                                <div className='lightFontStyle fontMedium'>{getTranslationText('subscription', 'coverageEndDate')}</div>
                                <div className='fontLarge overrideCasing overrideCasing'>
                                  {getLocaleDate(this.props.dateFormat, selectedPlanDetails.CoverageEndDate)}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        _.get(selectedPlanDetails, 'CoverageStartDate') && (
                          <div className='col-md-3'>
                            <div className='list-of-plans-view mb20 transitionClass'>
                              <div className='plan-div-container'>
                                <div className='plan-div-content'>
                                  <div className='lightFontStyle fontMedium'>{getTranslationText('subscription', 'coverageStartDate')}</div>
                                  <div className='fontLarge overrideCasing overrideCasing'>
                                    {getLocaleDate(this.props.dateFormat, selectedPlanDetails.CoverageStartDate)}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      )}

                      {_.includes([-3, -5], selectedPlanDetails.GroupSoldPlanStatus) ? (
                        <div className='col-md-3'>
                          <div className='list-of-plans-view mb20 transitionClass'>
                            <div className='plan-div-container'>
                              <div className='plan-div-content'>
                                <div className='lightFontStyle fontMedium'>{getTranslationText('subscription', 'nextBillingDate')}</div>
                                <div className='fontLarge overrideCasing overrideCasing'>
                                  {getTranslationText('subscription', 'notApplicable')}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        _.get(selectedPlanDetails, 'NextBillingDate') && (
                          <div className='col-md-3'>
                            <div className='list-of-plans-view mb20 transitionClass'>
                              <div className='plan-div-container'>
                                <div className='plan-div-content'>
                                  <div className='lightFontStyle fontMedium'>{getTranslationText('subscription', 'nextBillingDate')}</div>
                                  <div className='fontLarge overrideCasing overrideCasing'>
                                    {getLocaleDate(this.props.dateFormat, selectedPlanDetails.NextBillingDate)}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      )}

                      {/* {_.get(selectedPlanDetails, 'PlanAmount') && (
                        <div className='col-md-3'>
                          <div className='list-of-plans-view mb20 transitionClass'>
                            <div className='plan-div-container height-plan-details-cart'>
                              <div className='plan-div-content justifyContent'>
                                <div className='lightFontStyle fontMedium'>{getTranslationText('subscription', 'subscriptionFee')}</div>
                                <div className='fontLarge overrideCasing overrideCasing'>
                                  <span>
                                    {getLocaleCurrency(
                                      this.props.locale,
                                      this.props.currencyCode,
                                      _.get(selectedPlanDetails, 'PlanAmount')
                                    )}
                                    {_.get(selectedPlanDetails, 'BillingDuration') ? `/${selectedPlanDetails.BillingDuration} ` : ''}
                                  </span>
                                  <span className='bookFontStyle'>{getTranslationText('subscription', 'excludingTaxes')}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )} */}

                      {_.get(selectedPlanDetails, 'PerClaimLimit') && (
                        <div className='col-md-3'>
                          <div className='list-of-plans-view mb20 transitionClass'>
                            <div className='plan-div-container height-plan-details-cart'>
                              <div className='plan-div-content justifyContent'>
                                <div className='lightFontStyle fontMedium'>{getTranslationText('subscription', 'perClaimLimit')}</div>
                                <div className='fontLarge overrideCasing overrideCasing'>
                                  <span>
                                    {getLocaleCurrency(
                                      this.props.locale,
                                      this.props.currencyCode,
                                      _.get(selectedPlanDetails, 'PerClaimLimit')
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {_.get(selectedPlanDetails, 'AvailableClaimLimit').toString() && (
                        <div className='col-md-3'>
                          <div className='list-of-plans-view mb20 transitionClass'>
                            <div className='plan-div-container height-plan-details-cart'>
                              <div className='plan-div-content justifyContent'>
                                <div className='lightFontStyle fontMedium'>
                                  {getTranslationText('subscription', 'availableClaimAmount')}
                                </div>
                                <div className='fontLarge overrideCasing overrideCasing'>
                                  <span>
                                    {getLocaleCurrency(
                                      this.props.locale,
                                      this.props.currencyCode,
                                      _.get(selectedPlanDetails, 'AvailableClaimLimit')
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {_.get(selectedPlanDetails, 'AggregateClaimLimit').toString() && (
                        <div className='col-md-3'>
                          <div className='list-of-plans-view mb20 transitionClass'>
                            <div className='plan-div-container height-plan-details-cart'>
                              <div className='plan-div-content justifyContent'>
                                <div className='lightFontStyle fontMedium'>{getTranslationText('subscription', 'AggregateClaimLimit')}</div>
                                <div className='fontLarge overrideCasing overrideCasing'>
                                  <span>
                                    {getLocaleCurrency(
                                      this.props.locale,
                                      this.props.currencyCode,
                                      _.get(selectedPlanDetails, 'AggregateClaimLimit')
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='plan-view-tnc mt20'>
                      <Link target='_blank' to={selectedPlanDetails.TermsAndConditionsLink}>
                        {getTranslationText('subscription', 'planTNC')}
                        <RightArrow />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* device details table */}
          <div className='col-sm-12 mb8'>
            <div className='box-holder fullWidth'>
              <div className='row'>
                <div className='col-sm-12 mb20'>
                  <div className='heading bookFontStyle rm_pl mb20'>
                    <Heading title={getTranslationText('device', 'deviceProtected')} />
                  </div>

                  <div className='row'>
                    <div className='col-sm-12 mb8'>
                      <div className='subscription-container'>
                        <div className='image-div-container'>
                          <img src='/commonAssets/servifycare/MyPlans_Devices.png' />
                        </div>
                        <div className='boldFontStyle fontMedium max-width-35'>
                          {deviceCountFormatter(this.props.enrolledDeviceList.length) || '00'} Device(s)
                        </div>
                        <div className='right_alignment max-width-75'>
                          <MediumButton
                            className='button-small button-ghost'
                            buttonText={getTranslationText('device', 'addDeviceLabel')}
                            onClick={() => {
                              this.addDeviceHandler()
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    {/* <div className='col-sm-12 col-md-6'>
                      <div className='subscription-container'>
                        <div className='image-div-container'>
                          <img src={MyPlansSmartphone} />
                        </div>
                        <div className='boldFontStyle fontMedium max-width-35'>
                          23 Days left to add your Smartphone</div>
                        <div className='right_alignment max-width-75'>
                         <MediumButton
                            className='button-ghost button-small'
                            buttonText='Learn How'
                            onClick={() => {}}
                          />
                        </div>
                      </div>
                    </div> */}
                  </div>
                  {_.get(this, 'props.enrolledDeviceList', []).length > 0 ? (
                    <div className='plans-table'>
                      <table>
                        <thead>
                          <tr>
                            <td />
                            <td className='boldFontStyle fontMedium'>{getTranslationText('common', 'brand')}</td>
                            <td className='boldFontStyle fontMedium'>{getTranslationText('common', 'productLabel')}</td>
                            <td className='boldFontStyle fontMedium'>{getTranslationText('common', 'deviceTagLabel')}</td>
                            <td />
                          </tr>
                        </thead>
                        <tbody>
                          {this.props.enrolledDeviceList.map((v, i) => {
                            return (
                              <tr key={i}>
                                <td width='5'>
                                  <img
                                    className='services-div-img'
                                    src={this.props.user.appConfigDetails.productImageUrl + v.ProductSubCategoryID + '.png'}
                                  />
                                </td>
                                <td>{v.brand.BrandName || ''}</td>
                                <td>{v.product.ProductName || ''}</td>
                                <td className='fontLarge'>{v.TagName}</td>
                                {/* {v.isRepairAllowed && (
                                  <td className='td-width-repair'>
                                    <MediumButton buttonText='Raise a Claim' onClick={() => {}} />
                                  </td>
                                )} */}
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className='row mt28 device-fallback-container'>
                      <img className='no-device-found mb20' src='/commonAssets/servifycare/NoDeviceFound.png' />
                      <div className='text-center mediumFontStyle fontLarge'>
                        <span>
                          {getTranslationText('subscription', 'emptyMessage')} <br />
                          {getTranslationText('subscription', 'addSomeDevice')}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className='col-sm-12 mb8'>
            {_.get(selectedPlanDetails, 'Section3', []).length ? (
              <BoxHolder className='mb20'>
                <div className='row'>
                  {selectedPlanDetails.Section3.map((planSection, index) => {
                    return (
                      <div className='col-sm-12' key={index}>
                        <Heading title={planSection.SectionHeader} />
                        <div className='row mt20 mb8'>
                          {planSection.SectionList.map((data, idx) => (
                            <PlanImageTextContainer {...data} key={idx} />
                          ))}
                        </div>
                        {selectedPlanDetails.Section3.length !== index + 1 ? <hr size='2' className='mb28' /> : ''}
                      </div>
                    )
                  })}
                </div>

                {/* <div className='mb20 mt8'>
                  <div className='align-center text-align-center'>
                    <MediumButton onClick={() => {}} buttonText='See Whats Covered' />
                  </div>
                </div> */}
              </BoxHolder>
            ) : (
              ''
            )}
          </div>

          {_.get(selectedPlanDetails, 'FaqList', []).length > 0 && (
            <div className='col-sm-12'>
              <FaqListContainer loadMoreFaqs={this.loadMoreFaqs} faqButtonDisabled={faqButtonDisabled} faqList={updatedFaqList} />
            </div>
          )}
          {this.state.showDeviceAdditionModal && (
            <DeviceAdditionModal
              deviceAddCartSelection={this.deviceAddCartSelection}
              toggleModalHandler={this.toggleModalHandler}
              showDeviceAdditionModal={this.state.showDeviceAdditionModal}
              selectedCartId={this.state.selectedCartId}
              navigateToDeviceAddtion={this.navigateToDeviceAddtion}
              planDisplayName={selectedPlanDetails.PlanDisplayName}
            />
          )}
          {this.state.showDeviceFetchLoaderModal && (
            <p className='mt8 fontLarge position-loader-text'>{getTranslationText('subscription', 'checkEligibleDevices')}</p>
          )}
        </div>
      )
    } else {
      return (
        <Loader loaderState>
          <div />
        </Loader>
      )
    }
  }
}
SelectedPlansSubscriptionDetails.propTypes = {
  PlanDetailsJson: PropTypes.any,
  enrolledDeviceList: PropTypes.any,
  setglobalLoaderStatus: PropTypes.any,
  setSelectedPurchasedPlanDetails: PropTypes.any,
  fetchEligibleDeviceAsync: PropTypes.any,
  setDeviceAdditionModal: PropTypes.any,
}

export const PlanImageTextContainer = (props) => {
  return (
    <div className='image-text-container col-12 col-sm-6 col-md-3 displayFlex'>
      <div
        className={'image-holder ' + (window.isDirectionRTL ? ' ml8' : ' mr8')}
        style={{ backgroundImage: 'url(' + props.image + ')' }}
      />
      <div className='text-holder'>
        <div
          className='mediumFontStyle'
          dangerouslySetInnerHTML={{
            __html: props.text1,
          }}
        />
      </div>
    </div>
  )
}

PlanImageTextContainer.propTypes = {
  image: PropTypes.string.isRequired,
  text1: PropTypes.string,
}

export const FaqListContainer = (props) => {
  return (
    <BoxHolder className='home-faq-container'>
      <div className='row'>
        <div className='col-sm-12 mb20'>
          <Heading wordBreak title={getTranslationText('common', 'faq')} />
        </div>
        <div className='col-sm-12'>
          <Accordion titleClassName='boldFontStyle fontSmall' data={props.faqList} />
          {props.faqButtonDisabled && (
            <div className='mb20 mt8'>
              <div className='faq-label-center' onClick={() => props.loadMoreFaqs()}>
                <span>{getTranslationText('subscription', 'seeMore')}</span>
                <DownArrow />
              </div>
            </div>
          )}
        </div>
      </div>
    </BoxHolder>
  )
}
FaqListContainer.propTypes = {
  faqList: PropTypes.array.isRequired,
  loadMoreFaqs: PropTypes.func,
  faqButtonDisabled: PropTypes.boolean,
}

export const DeviceAdditionModal = (props) => {
  return (
    <Modal showModal={props.showDeviceAdditionModal} className='sfy-modal rating-modal'>
      <div className='row'>
        <div className='col-11'>
          <div
            className='boldFontStyle fontLarge mb4 center-align'
            style={window.isDirectionRTL ? { position: 'relative', right: '5%' } : { position: 'relative', left: '5%' }}
          >
            {`${getTranslationText('device', 'addDevicePlanLabel')} ${props.planDisplayName}`}
          </div>
        </div>
        <div className='modal-cross-icon'>
          <CustomIconComponent className='close-modal-btn' onClick={props.toggleModalHandler} alt='Cancel' />
        </div>
      </div>
      <div className='row mt20'>
        <div className='col-12 col-sm-12 col-md-6'>
          <div
            id='new'
            className={'device-add-action-cart special-link-txt ' + (props.selectedCartId === 'new' ? 'selected-add-device-cart' : '')}
            onClick={() => props.deviceAddCartSelection('new')}
          >
            {/* <img className='modalAddDeviceIcon' src='/commonAssets/servifycare/add.png' /> */}
            <PlansAddIcon className='modalAddDeviceIcon' />
            <span>{getTranslationText('subscription', 'addNewDevice')}</span>
            <Checkbox type='checkbox' value={props.selectedCartId === 'new'} handleOnChange={() => props.deviceAddCartSelection('new')} />
          </div>
        </div>
        <div className='col-12 col-sm-12 col-md-6'>
          <div
            id='existing'
            className={'device-add-action-cart special-link-txt ' + (props.selectedCartId === 'existing' ? 'selected-add-device-cart' : '')}
            onClick={() => props.deviceAddCartSelection('existing')}
          >
            {/* <img className='modalAddDeviceIcon' src='/commonAssets/servifycare/menu.png' /> */}
            <DevicesIcon className='modalAddDeviceIcon mr8' />
            <span>{getTranslationText('subscription', 'addFromDevice')}</span>
            <Checkbox
              type='checkbox'
              value={props.selectedCartId === 'existing'}
              handleOnChange={() => props.deviceAddCartSelection('existing')}
            />
          </div>
        </div>
        <div className='col-12 col-sm-12 col-md-12 mt20 text-center'>
          <MediumButton
            buttonText={getTranslationText('common', 'continue')}
            onClick={() => props.navigateToDeviceAddtion(props.selectedCartId)}
          />
        </div>
      </div>
    </Modal>
  )
}

DeviceAdditionModal.propTypes = {
  deviceAddCartSelection: PropTypes.func,
  toggleModalHandler: PropTypes.func,
  selectedCartId: PropTypes.string,
  showDeviceAdditionModal: PropTypes.boolean,
  navigateToDeviceAddtion: PropTypes.func,
}
