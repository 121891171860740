import React from 'react'
import PropTypes from 'prop-types'
import { BoxHolder, Heading } from '../../../components/UIComponents'
import { getTranslationText } from '../../../utils/languageTranslation.js'
import { Checkbox } from 'sfy-react'
import './messagesStyle.scss'
import { getLocaleDate, getLocaleTime } from '../../../utils/locale'

class Messages extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isEditable: false,
      allselected: false,
      NotificationData: [],
      messagesLoaderState: true,
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    let ConsumerID = this.props.user.userDetails.consumerID
    let AuthID = this.props.user.consumerDetails.data.accessToken.ID
    this.props.setglobalLoaderStatus(true)
    this.props
      .getNotificationsAsync({
        ConsumerID,
        AuthID,
      })
      .then(() => {
        let Unread = []
        this.setState({
          messagesLoaderState: false,
          NotificationData: this.props.user.notifications.map((noti) => {
            if (noti.IsRead !== 1) {
              Unread.push(noti.NotificationID)
            }
            return { ...noti, selected: false }
          }),
        })
        this.props.setglobalLoaderStatus(false)
        if (Unread.length) {
          this.markNotificationAsRead(Unread)
        }
      })
  }

  markNotificationAsRead = (data) => {
    setTimeout(() => {
      let ConsumerID = this.props.user.userDetails.consumerID
      let AuthID = this.props.user.consumerDetails.data.accessToken.ID
      this.props
        .editNotificationAsync({
          ConsumerID,
          Read: data,
        })
        .then(() => {
          this.props
            .getNotificationsAsync({
              ConsumerID,
              AuthID,
            })
            .then(() => {
              this.setState({
                NotificationData: this.props.user.notifications.map((noti) => {
                  return { ...noti, selected: false }
                }),
              })
            })
        })
    }, 3000)
  }

  SelectedNotificationID = (notificationId) => {
    this.setState({
      NotificationData: this.state.NotificationData.map((noti) => {
        return {
          ...noti,
          selected: noti.NotificationID === notificationId ? !noti.selected : noti.selected,
        }
      }),
    })
  }

  handleEvent = (e) => {
    if (e.target.id === 'setEditable') {
      this.setState({
        isEditable: true,
      })
    } else if (e.target.id === 'SelectAllNotification') {
      let flg = !this.state.allselected
      this.setState({
        allselected: flg,
        NotificationData: this.state.NotificationData.map((noti) => ({ ...noti, selected: flg })),
      })
    } else if (e.target.id === 'DeleteNotification') {
      let ConsumerID = this.props.user.userDetails.consumerID
      let AuthID = this.props.user.consumerDetails.data.accessToken.ID
      let selectedIds = []
      this.state.NotificationData.map((noti) => {
        if (noti.selected) {
          selectedIds.push(noti.NotificationID)
        }
      })
      if (selectedIds.length) {
        this.props.setglobalLoaderStatus(true)
        this.props
          .deleteNotificationsAsync({
            ConsumerID,
            selectedIds,
            AuthID,
          })
          .then(() => {
            this.props
              .getNotificationsAsync({
                ConsumerID,
                AuthID,
              })
              .then(() => {
                this.props.setglobalLoaderStatus(false)
                this.setState({
                  NotificationData: this.props.user.notifications.map((noti) => ({ ...noti, selected: false })),
                  allselected: false,
                  isEditable: false,
                })
              })
              .catch(() => {
                this.props.setglobalLoaderStatus(false)
              })
          })
          .catch(() => {
            this.props.setglobalLoaderStatus(false)
          })
      } else {
        this.props.setToaster(getTranslationText('error', 'selectNotification'), 'error')
      }
    } else {
      this.setState({
        isEditable: false,
        NotificationData: this.props.user.notifications.map((noti) => ({ ...noti, selected: false })),
        allselected: false,
      })
    }
  }

  mappingNotificationholder = (NData) => {
    const dateFormat = _.get(this, 'props.user.selectedCountryData.countryConfig.DateFormat.DEFAULT', 'DD/MM/YYYY')
    const locale = _.get(this, 'props.user.selectedCountryData.countryConfig.Locale.DEFAULT', 'en')
    const ele = NData.map((data, index) => {
      return (
        <NotificationHolder
          isEditable={this.state.isEditable}
          data={data}
          key={index.toString()}
          SelectedNotificationID={this.SelectedNotificationID}
          dateFormat={dateFormat}
          locale={locale}
        />
      )
    })
    return ele
  }

  render() {
    return (
      <BoxHolder className='notification-index mt20 box-holder-marginOverride mb48'>
        <div className='row mb20'>
          {this.state.NotificationData.length > 0 && (
            <div className='col-sm-6'>
              <Heading title={getTranslationText('common', 'notifications')} />
            </div>
          )}
          <div className={'col-sm-6 notification-handler ' + (window.isDirectionRTL ? ' text-left ' : ' text-right')}>
            {this.state.isEditable ? (
              <div>
                <span className='btn bookFontStyle fontSmall special-link-txt' id='SelectAllNotification' onClick={this.handleEvent}>
                  {this.state.allselected
                    ? getTranslationText('notifications', 'deselectAll')
                    : getTranslationText('notifications', 'selectAll')}
                </span>{' '}
                |
                <span className='btn bookFontStyle fontSmall special-link-txt' id='DeleteNotification' onClick={this.handleEvent}>
                  {getTranslationText('notifications', 'deleteSelect')}
                </span>{' '}
                |
                <span className='btn bookFontStyle fontSmall special-link-txt pr0' id='CancelEditstatus' onClick={this.handleEvent}>
                  {getTranslationText('common', 'cancel')}
                </span>
              </div>
            ) : this.state.NotificationData.length ? (
              <span className='btn bookFontStyle fontSmall special-link-txt' id='setEditable' onClick={this.handleEvent}>
                {getTranslationText('common', 'edit')}{' '}
              </span>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className='row'>
          {this.state.NotificationData.length ? (
            <div className='col-sm-12 notification-content-data'>{this.mappingNotificationholder(this.state.NotificationData)}</div>
          ) : this.state.messagesLoaderState ? (
            ''
          ) : (
            <div
              className='col-sm-12 bookFontStyle fontLarge'
              style={{ alignItems: 'center', textAlign: 'cener', display: 'flex', flexDirection: 'column' }}
            >
              <img src={'/commonAssets/noNotification.png'} className='mb28' />
              {getTranslationText('notifications', 'noMessage')}
            </div>
          )}
        </div>
      </BoxHolder>
    )
  }
}

Messages.propTypes = {
  user: PropTypes.object.isRequired,
  getNotificationsAsync: PropTypes.func.isRequired,
  deleteNotificationsAsync: PropTypes.func.isRequired,
  setToaster: PropTypes.func.isRequired,
  editNotificationAsync: PropTypes.func.isRequired,
  setglobalLoaderStatus: PropTypes.func.isRequired,
}

export default Messages

export class NotificationHolder extends React.Component {
  handleClick = () => {
    this.props.SelectedNotificationID(this.props.data.NotificationID)
  }

  render() {
    let obj = JSON.parse(this.props.data.Parameters)
    return (
      <div className='notification-content'>
        {this.props.data.IsRead !== 1 && !this.props.isEditable ? <span className='unread-notification' /> : ''}
        {this.props.isEditable ? (
          <div className='notification-checkbox'>
            <Checkbox type='checkbox' value={this.props.data.selected} handleOnChange={this.handleClick} />
          </div>
        ) : (
          ''
        )}
        <div className='row'>
          <div
            className='col-sm-8 notification-holder'
            onClick={() => {
              this.props.isEditable && this.handleClick()
            }}
          >
            <div className='boldFontStyle fontMedium overrideCasing'>{obj.Title}</div>
            <div className='bookFontStyle fontSmall'>{obj.Message}</div>
          </div>
          <div dir='ltr' className='col-sm-4 bookFontStyle fontSmall notification-timing mt8'>
            {getLocaleDate(this.props.dateFormat, this.props.data.CreatedDate) +
              ' | ' +
              getLocaleTime(new Date(this.props.data.CreatedDate), this.props.locale)}
          </div>
        </div>
      </div>
    )
  }
}

NotificationHolder.propTypes = {
  data: PropTypes.object.isRequired,
  isEditable: PropTypes.bool,
  Parameters: PropTypes.array,
  CreatedDate: PropTypes.string,
  SelectedNotificationID: PropTypes.func.isRequired,
}
