import React from 'react'
import { MediumButton, CustomIconComponent } from '../../../components/UIComponents'
import { Modal } from 'sfy-react'
import { getTranslationText } from '../../../utils/languageTranslation'
import informationAlert from '../../../../public/commonAssets/failure-alert.png'

export function DeviceAdditionModal(props) {
  return (
    <Modal showModal className='sfy-modal rating-modal'>
      <div className='modal-cross-icon'>
        <CustomIconComponent className='close-modal-btn' onClick={() => props.setDeviceAdditionModal(false)} alt='Cancel' />
      </div>
      <img className='modal-icon-width' src={informationAlert} />

      <div className='row'>
        <div className='col-12 text-center'>
          <div className='fontLarge boldFontStyle mt20'>{getTranslationText('subscription', 'addNewDevice')}</div>
          <div className='fontMedium bookFontStyle mt8'>{getTranslationText('subscription', 'noEligibleDevices')}</div>
        </div>
      </div>

      <div className='row mt20'>
        <div className='col-12 col-sm-12 col-md-12 mt20 text-center'>
          <MediumButton buttonText={getTranslationText('common', 'addNow')} onClick={() => props.addNewDeviceRedirect()} />
        </div>
      </div>
    </Modal>
  )
}
