import React from 'react'
import PropTypes from 'prop-types'
import { PaymentPortal, PaymentStatus } from './PaymentSubComponents'
import { browserHistory } from 'react-router'
import _ from 'lodash'
import { removeVisitedUrl } from '../../../store/request'
import { BrandSpecificConfig } from '../../../utils/constants.js'
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js'
import { getTranslationText } from '../../../utils/languageTranslation'
import pushToAnalytics from '../../../utils/Analytics'
import { Loader } from 'sfy-react'
import { MediumButton } from '../../../components/UIComponents'
import moment from 'moment'
// import uuid from 'node-uuid'

var cardStyle = {
  base: {
    color: '#32325d',
    lineHeight: '18px',
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    '::placeholder': {
      color: '#aab7c4',
    },
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a',
  },
}

export default class Payment extends React.Component {
  static propTypes = {
    payment: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    request: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    setToaster: PropTypes.func.isRequired,
    postPaymentActionAsync: PropTypes.func.isRequired,
    postPaymentActionNonLoginFlow: PropTypes.func.isRequired,
    getPayableAmountForNonLoginFlow: PropTypes.func.isRequired,
    addStripeSourceAsync: PropTypes.func.isRequired,
    makeStripeChargeAsync: PropTypes.func.isRequired,
    set3dSecurePoll: PropTypes.func.isRequired,
    setPlanActivated: PropTypes.func.isRequired,
    clear3dSecurePoll: PropTypes.func.isRequired,
    pollPaymentStatusAsync: PropTypes.func.isRequired,
    getNotificationsAsync: PropTypes.func.isRequired,
    setglobalLoaderStatus: PropTypes.func.isRequired,
    setPaymentRequestStatus: PropTypes.func.isRequired,
    setPaymentID: PropTypes.func.isRequired,
    CheckOrCreateTelrPaymentAsync: PropTypes.func.isRequired,
    setTelrCreateDetails: PropTypes.func.isRequired,
    setTelrCheckDetails: PropTypes.func.isRequired,
    initTelr: PropTypes.func.isRequired,
    addPaymentInfoAsync: PropTypes.func.isRequired,
    clearPaymentDetails: PropTypes.func,
    getIyzipayPaymentStatus: PropTypes.func,
    getAmazonPaymentStatus: PropTypes.func,
    getBuckarooPaymentStatus: PropTypes.func,
    getNoonpayPaymentStatus: PropTypes.func,
  }

  constructor(props) {
    super(props)
    this.stripeElementsRef = React.createRef()
    this.state = {
      isLoading: false,
      isLoadingCheckout: false,
      pollingFor3dSecure: props.payment.pollFor3DSecure,
      pollMessage: '',
      pollTimer: undefined,
      pollCount: 0,
      paypalButton: false,
      paypalCancel: false,
      userDetails: {
        name: props.user.userDetails.name || _.get(this, 'props.store.userData.name', undefined),
        emailAddress: props.user.userDetails.emailID || _.get(this, 'props.store.userData.email', undefined),
        number: props.user.userDetails.mobileNo || _.get(this, 'props.store.userData.mobile', undefined),
      },
      env: '',
      currency: '',
      total: '',
      client: '',
      iyzicoButton: false,
      amazonPayButton: false,
      showModal: { stripeError: false },
      stripeError: '',
      statusMessage: '',
      paymentStatus: '',
      isPaypalLoading: false,
      showUpdateCardDetailsModal: false,
      cardDetailsUpdateStatus: false,
      stripeFormStatus: false,
      stripeFormType: '',
    }
  }

  componentWillMount() {
    this.props.setglobalLoaderStatus(true)
    this.props.setPaymentRequestStatus(false)
  }

  componentWillUnmount() {
    this.props.setglobalLoaderStatus(false)
    const lastpathName = this.props.location.lastpathName
    if (lastpathName && lastpathName.path === '/store/checkout') {
      console.log('clear payment details')
      if (
        (this.state.paymentStatus !== 'success' &&
          _.get(this, 'props.payment.payableDetails.gateway', '') === 'stripe' &&
          !_.get(this, 'props.payment.status', false)) ||
        !_.get(this, 'props.payment.paymentRequestStatus', false)
      ) {
        pushToAnalytics(
          'Plan Sales - Payment Abandoned',
          {
            'Customer Name': _.get(this, 'props.user.userDetails.name', undefined),
            Email: _.get(this, 'props.user.userDetails.emailID', undefined),
            'Mobile Number': _.get(this, 'props.user.userDetails.mobileNo', undefined),
            ConsumerID: _.get(this, 'props.user.userDetails.consumerID', undefined),
            'Plan Name': _.get(this, 'props.store.selectedPlanDetails.PlanName', undefined),
            Gateway: _.get(this, 'props.payment.payableDetails.gateway', undefined),
            IMEI_checked_on_storepage: !!_.get(this, 'props.store.imeiForFilter', false),
            eureka: !!_.get(this, 'props.store.imeiFromToken', false),
            'Logged In': _.get(this, 'props.user.userDetails.consumerID', false) ? 'Yes' : 'No',
            ProductName: _.get(this, 'props.store.purchaseEligibilityInfo.ProductName', ''),
          },
          _.get(this, 'props.user')
        )
      }
      this.props.clearPaymentDetails()
    }
    this.props.setSelectedPaymentOption({})
    this.props.unsetSignedUrl()
  }

  componentDidMount() {
    if (!window.location.href.includes('stripe-status')) {
      let qry = parseInt(this.props.location.query.status)
      if (!qry || qry !== 1) {
        pushToAnalytics(
          'Payment Initiated',
          {
            'Customer Name': _.get(this, 'props.user.userDetails.name', undefined),
            Email: _.get(this, 'props.user.userDetails.emailID', undefined),
            'Mobile Number': _.get(this, 'props.user.userDetails.mobileNo', undefined),
            ConsumerID: _.get(this, 'props.user.userDetails.consumerID', undefined),
            'Plan Name': _.get(this, 'props.store.selectedPlanDetails.PlanName', undefined),
            Gateway: _.get(this, 'props.payment.payableDetails.gateway', undefined),
          },
          _.get(this, 'props.user')
        )
      }
      //console.log(this.props.payment.payableDetails)
      if (qry && qry === 1 && this.props.payment.payableDetails.gateway === 'iyzipay') {
        //console.log(this.props.payment.payableDetails.iyzipay.token)
        this.props
          .getIyzipayPaymentStatus({
            token: this.props.payment.payableDetails.iyzipay.token,
          })
          .then((response) => {
            //console.log(response);
            if (response.paymentStatus === 'SUCCESS') {
              this.handlePostAsyncAction({
                paymentID: response.paymentId,
                PaymentStatus: 'success',
              })
            } else {
              this.props.setglobalLoaderStatus(false)
              this.props.setToaster(response.errorMessage, 'error')
              browserHistory.push({
                pathname: '/store/checkout',
                query: {
                  step: 1,
                  [this.props.location.query.region ? 'region' : '']: this.props.location.query.region,
                },
              })
            }
          })
      } else if (qry && qry === 1 && this.props.payment.payableDetails.gateway === 'amazonpayment') {
        console.log(this.props.payment.payableDetails)
        this.props
          .getAmazonPaymentStatus({
            PaymentReferenceID: this.props.payment.payableDetails.id,
            ConsumerID: this.props.user.consumerDetails.data.consumerID,
          })
          .then((response) => {
            console.log(response)
            if (response.data.payment_status === 'captured') {
              if (this.props.location.lastpathName) {
                if (!['/track', '/request/confirm'].includes(this.props.location.lastpathName.path)) {
                  this.handlePostAsyncAction({
                    paymentID: response.data.fort_id,
                    PaymentStatus: 'success',
                  })
                } else {
                  this.props.setPaymentRequestStatus(true)
                  this.props.setPaymentID(response.data.fort_id)
                  browserHistory.push({
                    pathname: this.props.location.lastpathName.path,
                    query: this.props.location.lastpathName.params,
                  })
                }
              } else {
                this.handlePostAsyncAction({
                  paymentID: response.data.fort_id,
                  PaymentStatus: 'success',
                })
              }
            } else {
              // TODO: handle for claim flow - payment failure case
              this.props.setglobalLoaderStatus(false)
              this.props.setToaster(response.errorMessage, 'error')
              console.log('props.location - ', this.props.location)
              if (this.props.location.lastpathName) {
                // repair flow
                browserHistory.push({
                  pathname: this.props.location.lastpathName.path,
                  query: this.props.location.lastpathName.params,
                })
              } else {
                browserHistory.push({
                  pathname: '/store/checkout',
                  query: {
                    step: 1,
                    [this.props.location.query.region ? 'region' : '']: this.props.location.query.region,
                  },
                })
              }
            }
          })
      } else if (qry && qry === 1 && this.props.payment.payableDetails.gateway === 'buckaroo') {
        //console.log(this.props.payment.payableDetails.iyzipay.token)
        this.props
          .getBuckarooPaymentStatus({
            PaymentReferenceID: this.props.payment.payableDetails.id,
            ConsumerID: this.props.user.consumerDetails.data.consumerID,
          })
          .then((response) => {
            //console.log(response);
            if (response.success && response.data.PaymentStatus === 'captured') {
              if (this.props.location.lastpathName) {
                if (this.props.location.lastpathName.path !== '/request/confirm') {
                  this.handlePostAsyncAction({
                    paymentID: this.props.payment.payableDetails.id,
                    PaymentStatus: 'success',
                    PaymentMode: _.get(this, 'props.store.selectedPaymentOption.PaymentModeCode'),
                  })
                } else {
                  this.props.setPaymentRequestStatus(true)
                  this.props.setPaymentID(this.props.payment.payableDetails.id)
                  browserHistory.push({
                    pathname: this.props.location.lastpathName.path,
                    query: this.props.location.lastpathName.params,
                  })
                }
              } else {
                this.handlePostAsyncAction({
                  paymentID: this.props.payment.payableDetails.id,
                  PaymentStatus: 'success',
                  PaymentMode: _.get(this, 'props.store.selectedPaymentOption.PaymentModeCode'),
                })
              }
            } else {
              this.props.setglobalLoaderStatus(false)
              this.props.setToaster(response.errorMessage, 'error')
              if (this.props.location.lastpathName) {
                // repair flow
                browserHistory.push({
                  pathname: this.props.location.lastpathName,
                  query: this.props.location.query,
                })
              } else {
                browserHistory.push({
                  pathname: '/store/checkout',
                  query: {
                    step: 1,
                    [this.props.location.query.region ? 'region' : '']: this.props.location.query.region,
                  },
                })
              }
            }
          })
      } else if (qry && qry === 1 && this.props.payment.payableDetails.gateway === 'noonpay') {
        // console.log('query params - ',this.props.location.query)
        this.props
          .getNoonpayPaymentStatus({
            orderId: this.props.location.query.orderId,
            merchantReference: this.props.location.query.merchantReference,
            paymentType: this.props.location.query.paymentType,
            WebAppPay: true,
            isTypeClaim: ['/track', '/request/confirm'].includes(this.props.location.lastpathName.path),
          })
          .then((response) => {
            console.log(response)
            if (
              (response.data && response.data.length && response.data[0].Status === 1) ||
              (response.data && response.data.isTypeClaim && response.data.orderStatus === 'CAPTURED')
            ) {
              this.props.setPaymentID(response.data.PaymentID)
              if (this.props.location.lastpathName) {
                if (!['/track', '/request/confirm'].includes(this.props.location.lastpathName.path)) {
                  if (response.data[0].SoldPlanID) {
                    this.handlePostPaymentActionResponse(response.data)
                  } else {
                    this.handlePostAsyncAction({
                      paymentID: this.props.payment.payableDetails.id,
                      PaymentStatus: 'success',
                    })
                  }
                } else {
                  if (['/track'].includes(this.props.location.lastpathName.path)) {
                    this.handlePostAsyncAction({
                      paymentID: response.data.PaymentID,
                    })
                  } else {
                    _.get(this, 'props.user.appConfigDetails.PaymentConfirmationText', false) &&
                      this.props.setToaster(getTranslationText('payment', 'paymentConfirmation'), 'success')
                    this.props.getNotificationsAsync({
                      ConsumerID: this.props.user.consumerDetails.data.consumerID,
                      AuthID: this.props.user.consumerDetails.data.accessToken.ID,
                    })
                    this.props.setPaymentRequestStatus(true)
                    browserHistory.push({
                      pathname: this.props.location.lastpathName.path,
                      query: {
                        ...this.props.location.lastpathName.params,
                        step: this.props.location.lastpathName.path === '/track' ? undefined : 3,
                        [this.props.location.query.region ? 'region' : '']: this.props.location.query.region,
                      },
                    })
                  }
                }
              } else {
                if (response.data[0].SoldPlanID) {
                  this.handlePostPaymentActionResponse(response.data)
                } else {
                  this.handlePostAsyncAction({
                    paymentID: this.props.payment.payableDetails.id,
                    PaymentStatus: 'success',
                  })
                }
              }
            } else {
              // TODO: handle for claim flow - payment failure case
              this.props.setglobalLoaderStatus(false)
              this.props.setToaster(response.errorMessage, 'error')
              console.log('props.location - ', this.props.location)
              if (this.props.location.lastpathName) {
                // repair flow
                browserHistory.push({
                  pathname: this.props.location.lastpathName.path,
                  query: this.props.location.lastpathName.params,
                })
              } else {
                browserHistory.push({
                  pathname: '/store/checkout',
                  query: {
                    step: 1,
                    [this.props.location.query.region ? 'region' : '']: this.props.location.query.region,
                  },
                })
              }
            }
          })
          .catch(() => {
            this.props.setglobalLoaderStatus(false)
            if (this.props.location.lastpathName) {
              // repair flow
              browserHistory.push({
                pathname: this.props.location.lastpathName.path,
                query: this.props.location.lastpathName.params,
              })
            } else {
              browserHistory.push({
                pathname: '/store/checkout',
                query: {
                  step: 1,
                  [this.props.location.query.region ? 'region' : '']: this.props.location.query.region,
                },
              })
            }
          })
      } else if (/\/payment/.test(this.props.location.pathname) && this.props.payment.pollFor3DSecure) {
        this.props.setglobalLoaderStatus(false)
        this.initiatePoll()
      } else if (
        this.props.payment.payableDetails &&
        (this.props.payment.payableDetails.gateway === 'buckaroo' || this.props.payment.payableDetails.gateway === 'noonpay') &&
        this.props.payment.payableDetails.short_url
      ) {
        if (this.props.payment.payableDetails.gateway === 'noonpay' && !this.props.location.lastpathName.path.includes('/track')) {
          this.props.addPaymentInfoAsync()
        }
        window.location.assign(this.props.payment.payableDetails.short_url)
        //window.location.assign('https://testcheckout.buckaroo.nl/html/?brq_paydirect_inv=5814CA7D627F4AF4B29661B715FA60A0')
        setTimeout(() => {
          this.props.setglobalLoaderStatus(false)
        }, 2000)
      } else if (this.props.payment.payableDetails) {
        if (this.props.payment.payableDetails.gateway === 'iyzipay') {
          this.setState(
            {
              iyzicoButton: true,
            },
            () => {
              this.props.setglobalLoaderStatus(false)
            }
          )
        } else if (this.props.payment.payableDetails.gateway === 'amazonpayment') {
          this.setState(
            {
              amazonPayButton: true,
            },
            () => {
              this.props.setglobalLoaderStatus(false)
            }
          )
        }

        this.props.setglobalLoaderStatus(false)
        let razorpay = new RegExp('razorpay', 'i')
        let stripe = new RegExp('stripe', 'i')
        let paypal = new RegExp('paypal', 'i')
        let telr = new RegExp('telr', 'i')
        const lastpathName = _.get(this, 'props.location.lastpathName.path')
        if (
          razorpay.test(this.props.payment.payableDetails.gateway) &&
          _.get(this, 'props.store.selectedBrand.BrandName', '').toUpperCase() !== 'APPLE' &&
          _.get(this, 'props.user.appConfigDetails.nonOtpPlanSale', false) &&
          !_.get(this, 'props.user.userDetails.consumerID', false)
        ) {
          /********************** openRazorpay - Logger API /**********************/
          // if (window.useLogger) {
          //   let loggerObj = {
          //     apiName: 'openRazorpay',
          //     value: _.get(this, 'props.payment.payableDetails', undefined),
          //     event: 'openRazorpay',
          //     identifiers: {
          //       EmailID: _.get(this, 'props.store.userData.email', undefined),
          //       FirstName: _.get(this, 'props.store.userData.name', undefined),
          //       MobileNo: _.get(this, 'props.store.userData.mobile', undefined),
          //       ConsumerID: _.get(this, 'props.user.userDetails.consumerID', undefined),
          //     },
          //   }
          //   this.props.loggerActionAsync(loggerObj)
          // }
          /********************** openRazorpay - Logger API /**********************/
          this.openRazorpay()
        } else if (razorpay.test(this.props.payment.payableDetails.gateway)) {
          this.props.setglobalLoaderStatus(false)
          if (['/store/checkout', '/request/confirm'].includes(lastpathName)) {
            /********************** addPaymentInfo API calling - Logger API /**********************/
            if (_.get(this, 'props.user.userDetails.consumerID', false) && window.useLogger) {
              let loggerObj = {
                apiName: '/OnlinePayment/addPaymentInfo',
                value: {
                  userLocation: _.get(this, 'props.request.userLocation'),
                  ServiceTypeID: _.get(this, 'props.request.selectedMode.ServiceTypeID'),
                  Lat: (_.get(this, 'props.request.userLocation.latitude') || _.get(this, 'props.request.userLocation.Lat', '')).toString(),
                  Lng: (
                    _.get(this, 'props.request.userLocation.longitude') || _.get(this, 'props.request.userLocation.Lng', '')
                  ).toString(),
                },
                event: 'addPaymentInfo-api-calling',
                identifiers: {
                  ConsumerID: _.get(this, 'props.user.userDetails.consumerID'),
                },
              }
              this.props.loggerActionAsync(loggerObj)
            }
            /********************** addPaymentInfo calling - Logger API /**********************/
            this.props.addPaymentInfoAsync()
          }
          if (_.get(this, 'props.location.lastpathName.path') === '/request/confirm') {
            // ------------- CleverTap | Book  Repair - Claim Payment Initiated --------/
            const { PlanDisplayName, SoldPlanID } = _.get(this, 'props.request.selectedDamageType')
            const DeviceName_ = _.get(this, 'props.request.selectedDeviceInfo.Product.ProductName')
            const BrandName_ = _.get(this, 'props.request.selectedDeviceInfo.Brand.BrandName')
            const ProductUniqueID_ = _.get(this, 'props.request.selectedDeviceInfo.ProductUniqueID')
            const { emailID, mobileNo, consumerID } = _.get(this, 'props.user.userDetails')
            const LocationAddress_ = _.get(this, 'props.request.userLocation.landmark')
            const clevertapUserProfile = _.get(this, 'props.user', {})

            pushToAnalytics(
              'Book  Repair - Claim Payment Initiated',
              {
                Brand: BrandName_,
                'Plan Name': PlanDisplayName,
                'Plan Sold ID': SoldPlanID,
                'Device Name': DeviceName_,
                'Device IMEI': ProductUniqueID_,
                ConsumerProductID: _.get(this, 'props.store.planConsumerProduct.ConsumerProductID', undefined),
                'Mobile Number': mobileNo,
                Email: emailID,
                ConsumerID: consumerID || undefined,
                Address: LocationAddress_,
                'Claim Type': 'Skip Claim Approval',
                Source: 'Web',
              },
              clevertapUserProfile
            )
            // ------------- CleverTap | Book  Repair - Claim Payment Initiated --------/
          }
          this.openRazorpay()
        } else if (stripe.test(this.props.payment.payableDetails.gateway)) {
          /********************** openStripeCheckout - Logger API /**********************/
          if (_.get(this, 'props.user.userDetails.consumerID', false) && window.useLogger) {
            let loggerObj = {
              apiName: 'openStripeCheckout',
              value: _.get(this, 'props.payment.payableDetails', undefined),
              event: 'openStripeCheckout',
              identifiers: {
                ConsumerID: _.get(this, 'props.user.userDetails.consumerID', undefined),
              },
            }
            this.props.loggerActionAsync(loggerObj)
          }
          /********************** openStripeCheckout - Logger API /**********************/
          if (
            _.get(this, 'props.store.selectedBrand.BrandName', '').toUpperCase() !== 'APPLE' &&
            _.get(this, 'props.user.appConfigDetails.nonOtpPlanSale', false) &&
            !_.get(this, 'props.user.userDetails.consumerID', false)
          ) {
            this.openStripeCheckout()
          } else {
            const addPaymentPromise = ['/store/checkout', '/request/confirm'].includes(lastpathName)
              ? this.props.addPaymentInfoAsync()
              : new Promise((resolve) => {
                  resolve()
                })
            addPaymentPromise
              .then(() => {
                this.props.setglobalLoaderStatus(false)
                this.openStripeCheckout()
              })
              .catch((e) => {
                console.log(e)
                this.props.setglobalLoaderStatus(false)
              })
          }
        } else if (paypal.test(this.props.payment.payableDetails.gateway)) {
          this.openPaypalCheckout()
        } else if (telr.test(this.props.payment.payableDetails.gateway)) {
          this.openTelrCheckout()
        }
      }
    }
  }

  componentDidUpdate() {
    const amazonpayForm = document.getElementById('amazonpay')
    if (this.state.amazonPayButton && amazonpayForm) {
      document.getElementById('amazonpay').submit()
      this.setState(
        {
          amazonPayButton: false,
        },
        () => {
          this.checkoutAmazonPayment()
        }
      )
    }
  }

  setStatusMessage = (message) => {
    this.setState({
      statusMessage: message,
    })
  }

  setPaymentStatus = (status) => {
    this.setState({
      paymentStatus: status,
    })
  }

  closeUpdateCardDetailsModal = () => {
    this.setState({
      showUpdateCardDetailsModal: false,
    })
  }

  initiatePoll = () => {
    this.props.setglobalLoaderStatus(false)
    this.props.clear3dSecurePoll()
    let poll = () => {
      this.props.pollPaymentStatusAsync(this.props.payment.three_d_secure_src).then(() => {
        this.props.getNotificationsAsync({
          ConsumerID: this.props.user.userDetails.consumerID,
          AuthID: this.props.user.consumerDetails.data.accessToken.ID,
        })
        if (this.props.payment.paymentPollStatus.success && this.props.payment.paymentPollStatus.data) {
          let poll2 = () => {
            if (this.state.pollTimer !== undefined && this.state.pollTimer <= 0) {
              clearInterval(this.state.pollingId)
              this.setState({
                pollTimer: undefined,
                pollingId: undefined,
              })
              this.props.setPlanActivated(true)
              browserHistory.push({
                pathname: this.props.location.lastpathName.path,
                query: {
                  ...this.props.location.lastpathName.params,
                  step: this.props.location.lastpathName.path === '/track' ? undefined : 3,
                  [this.props.location.query.region ? 'region' : '']: this.props.location.query.region,
                },
              })
            } else {
              this.setState({
                pollTimer: this.state.pollTimer - 1,
              })
            }
          }
          this.setState({
            pollMessage: this.props.payment.paymentPollStatus.msg,
            pollTimer: 3,
            pollingId: setInterval(poll2, 1000),
          })
        } else if (this.props.payment.paymentPollStatus.success && !this.props.payment.paymentPollStatus.data) {
          let poll2 = () => {
            if (this.state.pollTimer !== undefined && this.state.pollTimer <= 0) {
              clearInterval(this.state.pollingId)
              this.setState({
                pollTimer: undefined,
                pollingId: undefined,
              })
              // window.location.assign('/payment')
              browserHistory.push({
                pathname: this.props.location.lastpathName.path,
                query: this.props.location.lastpathName.params,
              })
            } else {
              this.setState({
                pollTimer: this.state.pollTimer - 1,
              })
            }
          }
          this.setState({
            pollMessage: this.props.payment.paymentPollStatus.msg,
            pollTimer: 3,
            pollingId: setInterval(poll2, 1000),
          })
        } else {
          if (this.state.pollCount < 25) {
            setTimeout(() => {
              poll()
              this.setState({
                pollCount: this.state.pollCount + 1,
              })
            }, 5000)
          } else {
            window.location.reload()
          }
        }
      })
    }
    poll()
  }

  openStripeCheckout = () => {
    const locale = localStorage.getItem('languageCode') || _.get(this, 'props.user.selectedCountryData.countryConfig.Locale.DEFAULT', 'en')
    let stripeObj = { locale }
    if (this.props.payment?.payableDetails?.stripe?.connectedAccountID)
      stripeObj.stripeAccount = this.props.payment.payableDetails.stripe.connectedAccountID
    const stripe = window.Stripe(this.props.payment.payableDetails.apiKey, stripeObj)
    window.stripeInstance = stripe
    const options = {
      clientSecret: _.get(this, 'props.payment.payableDetails.stripe.clientSecret'),
    }
    const elements = stripe.elements(options)
    const card = elements.create('payment', { style: cardStyle })
    card.mount('#card-element')
    let cardFrame = document.getElementsByClassName('__PrivateStripeElement')[0].childNodes[0]
    cardFrame.style.cssText = cardFrame.style.cssText + 'width: auto !important'
    card.addEventListener('change', (event) => {
      // console.log('event - ', event)
      if (event?.value?.type === this.state.stripeFormStatus) {
        if (event?.complete !== this.state.stripeFormStatus) {
          this.setState({
            stripeFormStatus: event?.complete,
            stripeFormType: event?.value?.type,
          })
        }
      } else {
        this.setState({
          stripeFormStatus: event?.complete,
          stripeFormType: event?.value?.type,
        })
      }
      var displayError = document.getElementById('card-errors')
      if (event.error) {
        displayError.textContent = event.error.message
      } else {
        displayError.textContent = ''
      }
    })
    this.setState({
      card,
    })
    this.stripeElementsRef.current = elements
  }

  openPaypalCheckout = () => {
    this.setState({
      paypalButton: true,
      env: window.paypalEnv || 'sandbox',
      currency: _.get(this, 'props.payment.payableDetails.CurrencyCode', this.props.user.selectedCountryData.CurrencyCode),
      total: this.props.payment.payableDetails.amount,
      client: {
        sandbox: this.props.payment.payableDetails.apiKey,
        production: this.props.payment.payableDetails.apiKey,
      },
      isPaypalLoading: true,
    })
  }

  openRazorpay = () => {
    let lastpathName = this.props.location.lastpathName
    let options = {
      key: this.props.payment.payableDetails.apiKey,
      amount: this.props.payment.payableDetails.amount, // 2000 paise = INR 20
      order_id: _.get(this, 'props.payment.payableDetails.OrderID', undefined),
      name: BrandSpecificConfig().ClientName,
      image: `/${appName}/images/logo.png`,
      handler: async (response) => {
        const paymentID = response?.razorpay_payment_id || undefined
        /********************** Razorpay response - Logger API /**********************/
        if (_.get(this, 'props.user.userDetails.consumerID', false) && window.useLogger) {
          let loggerObj = {
            apiName: 'RazorpayResponse',
            value: {
              payableDetails: _.get(this, 'props.payment.payableDetails', undefined),
              response: response,
            },
            event: 'RazorpayResponse',
            identifiers: {
              ConsumerID: _.get(this, 'props.user.userDetails.consumerID', undefined),
            },
          }
          this.props.loggerActionAsync(loggerObj)
        }
        /********************** Razorpay response  - Logger API /**********************/
        if (response.error && lastpathName && lastpathName.path === '/store/checkout') {
          this.handleError()
        } else if (lastpathName && lastpathName.path !== '/request/confirm') {
          this.props.setPaymentRequestStatus(true)
          this.props.setglobalLoaderStatus(true)
          if (
            _.get(this, 'props.store.selectedBrand.BrandName', '').toUpperCase() !== 'APPLE' &&
            _.get(this, 'props.user.appConfigDetails.nonOtpPlanSale', false) &&
            !_.get(this, 'props.user.userDetails.consumerID', false)
          ) {
            let planInfo = _.get(this, 'props.store.selectedPlanDetails', {})
            let planPrice = {
              PlanID: planInfo.PlanID,
              PlanPriceID: planInfo.planPrice.find((planprice) => planprice.isSelected).PlanPriceID,
            }
            let fetchedPlanPrice = _.get(this, 'props.store.planPriceOnProductBasis', {})
            if (planInfo.priceRange && fetchedPlanPrice.PlanPrice) {
              // plan price fetched on product basis
              planPrice = {
                PlanID: fetchedPlanPrice.PlanID,
                PlanPriceID: fetchedPlanPrice.PlanPriceID,
              }
            }
            const dob = _.get(this, 'props.store.userData.BirthDate', undefined)
            let obj = {
              PaymentProcessID: _.get(this, 'props.store.planPaymentDetails.id', undefined),
              CurrencyID: planInfo.CurrencyID || undefined,
              CurrencyCode: _.get(this, 'props.user.selectedCountryData.CurrencyCode', undefined),
              currency: _.get(this, 'props.payment.payableDetails.currency', undefined),
              PlanID: planInfo.PlanID || undefined,
              Status: 1,
              PlanArray: [
                {
                  ...planPrice,
                  PlanPriceBillingConfigID: _.get(this, 'props.store.selectedPaymentOption.PlanPriceBillingConfigID'),
                },
              ],
              PlanPriceID: planPrice.PlanPriceID || undefined,
              StateCode: _.get(this, 'props.store.selectedState.StateCode', undefined),
              isSubscriptionPayment: _.get(this, 'props.store.selectedPaymentOption.PaymentModeCode', '') === 'EMI',
              ExternalPlanCode: _.get(this, 'props.store.purchaseEligibilityInfo.ExternalPlanCode', undefined),
              PaymentMode: _.get(this, 'props.store.selectedPaymentOption.PaymentModeCode', undefined),
              PlanPriceBillingConfigID: _.get(this, 'props.store.selectedPaymentOption.PlanPriceBillingConfigID', undefined),
              Gateway: _.get(this, 'props.payment.payableDetails.gateway', undefined),
              EmailID: _.get(this, 'props.store.userData.email'),
              FirstName: _.get(this, 'props.store.userData.name'),
              MobileNo: _.get(this, 'props.store.userData.mobile'),
              BirthDate: dob ? moment(dob).format('YYYY-MM-DD') : undefined,
              CountryCode: _.get(this, 'props.user.selectedCountryData.CountryCode', undefined),
              CountryID: _.get(this, 'props.user.selectedCountryData.CountryID, undefined'),
              PhoneCode: _.get(this, 'props.user.selectedCountryData.PhoneCode', undefined),
              app: BrandSpecificConfig().header,
              PlanDiscountConfigurationID:
                _.get(this, 'props.store.selectedPaymentOption.maxDiscountOffer.DiscountCategory', '') === 'PromoCode'
                  ? _.get(this, 'props.store.selectedPaymentOption.appliedDiscountOffer.PlanDiscountConfigurationID', undefined)
                  : _.get(this, 'props.store.selectedPaymentOption.maxDiscountOffer.PlanDiscountConfigurationID', undefined),
              hasConcession: _.get(this, 'props.store.selectedPaymentOption.maxDiscountOffer.hasConcession', undefined),
              paymentID: paymentID,
            }
            /********************** ConsumerProduct/postPaymentAction - Logger API /**********************/
            // if (window.useLogger) {
            //   let loggerObj = {
            //     apiName: 'ConsumerProduct/postPaymentAction',
            //     value: obj,
            //     event: 'ConsumerProduct/postPaymentAction',
            //     identifiers: {
            //       EmailID: _.get(this, 'props.store.userData.email', undefined),
            //       FirstName: _.get(this, 'props.store.userData.name', undefined),
            //       MobileNo: _.get(this, 'props.store.userData.mobile', undefined),
            //       ConsumerID: _.get(this, 'props.user.userDetails.consumerID', undefined),
            //     },
            //   }
            //   this.props.loggerActionAsync(loggerObj)
            // }
            /********************** ConsumerProduct/postPaymentAction  - Logger API /**********************/
            // console.log('postPaymentActionNonLoginFlow - payload - ', obj)
            const response = await this.props.postPaymentActionNonLoginFlow(obj)
            // console.log('postPaymentActionNonLoginFlow - response - ', response)
            /********************** ConsumerProduct/postPaymentAction response - Logger API /**********************/
            // if (window.useLogger) {
            //   let loggerObj = {
            //     apiName: 'ConsumerProduct/postPaymentActionResponse',
            //     value: response,
            //     event: 'ConsumerProduct/postPaymentActionResponse',
            //     identifiers: {
            //       EmailID: _.get(this, 'props.store.userData.email', undefined),
            //       FirstName: _.get(this, 'props.store.userData.name', undefined),
            //       MobileNo: _.get(this, 'props.store.userData.mobile', undefined),
            //       ConsumerID: _.get(this, 'props.user.userDetails.consumerID', undefined),
            //     },
            //   }
            //   this.props.loggerActionAsync(loggerObj)
            // }
            /********************** ConsumerProduct/postPaymentAction  response - Logger API /**********************/
            if (response && response.Status && response.SoldPlanID) {
              this.setPaymentStatus('success')
              this.props.setPaymentRequestStatus(true)
              const { PlanName } = _.get(this, 'props.store.selectedPlanDetails')
              const { ValidTillMonths } = _.get(this, 'props.store.selectedPlanDetails.PlanConfig')
              const { BrandName } = _.get(this, 'props.store.selectedPlanDetails.EntityDetails[0]')
              const { ProductObj, PlanPrice } = _.get(this, 'props.store.purchaseEligibilityInfo', {})
              const { consumerID } = _.get(this, 'props.user.userDetails', false)
              const clevertapUserProfile = _.get(this, 'props.user', {})
              pushToAnalytics(
                'Plan Sales - Plan Purchased',
                {
                  'Plan Name': PlanName,
                  Brand: BrandName,
                  'Policy Name': PlanName,
                  'Plan Validity': ValidTillMonths,
                  'Plan Price': PlanPrice ? PlanPrice.PlanPrice : undefined,
                  'IMEI or Serial': ProductObj ? ProductObj.ProductUniqueID : undefined,
                  'Payment ID': _.get(this, 'props.store.planPaymentDetails.PaymentProcessID', undefined),
                  Source: 'Web',
                  IMEI_checked_on_storepage: !!_.get(this, 'props.store.imeiForFilter', false),
                  eureka: !!_.get(this, 'props.store.imeiFromToken', false),
                  'Logged In': consumerID ? 'Yes' : 'No',
                  ConsumerID: response.ConsumerID || undefined,
                  ConsumerProductID: response.ConsumerProductID || undefined,
                  ProductName: _.get(this, 'props.store.purchaseEligibilityInfo.ProductName', ''),
                },
                clevertapUserProfile
              )
              browserHistory.push({
                pathname: this.props.location.lastpathName.path,
                query: {
                  ...this.props.location.lastpathName.params,
                  step: this.props.location.lastpathName.path === '/track' ? undefined : 3,
                  [this.props.location.query.region ? 'region' : '']: this.props.location.query.region,
                },
              })
            } else {
              this.setPaymentStatus('failure')
              this.setStatusMessage(response && response.msg ? response.msg : getTranslationText('error', 'somethingWentWrong'))
            }
            this.props.setglobalLoaderStatus(false)
          } else {
            this.handlePostAsyncAction({
              paymentID: response.razorpay_payment_id,
            })
          }
        } else {
          this.props.setPaymentRequestStatus(true)
          this.props.setPaymentID(response.razorpay_payment_id)

          if (this.props.location.lastpathName.path === '/request/confirm') {
            // ------------- CleverTap | Book Repair - Claim Payment Status --------/
            const { PlanDisplayName, SoldPlanID } = _.get(this, 'props.request.selectedDamageType')
            const BrandName_ = _.get(this, 'props.request.selectedDeviceInfo.Brand.BrandName')
            const ProductUniqueID_ = _.get(this, 'props.request.selectedDeviceInfo.ProductUniqueID')
            const DeviceName_ = _.get(this, 'props.request.selectedDeviceInfo.Product.ProductName')
            const { mobileNo, emailID, consumerID } = _.get(this, 'props.user.userDetails')
            const LocationAddress_ = _.get(this, 'props.request.userLocation.landmark')
            const clevertapUserProfile = _.get(this, 'props.user', {})

            pushToAnalytics(
              'Book Repair - Claim Payment Status',
              {
                Brand: BrandName_,
                'Plan Name': PlanDisplayName,
                'Plan Sold ID': SoldPlanID,
                'Device Name': DeviceName_,
                'Device IMEI': ProductUniqueID_,
                ConsumerProductID: _.get(this, 'props.store.planConsumerProduct.ConsumerProductID', undefined),
                'Mobile Number': mobileNo,
                Email: emailID,
                ConsumerID: consumerID || undefined,
                Address: LocationAddress_,
                'Payment Status': response.razorpay_payment_id ? 'Success' : 'Failed',
                'Claim Type': 'Skip Claim Approval',
                Source: 'Web',
              },
              clevertapUserProfile
            )
            // ------------- CleverTap | Book Repair - Claim Payment Status --------/
          }
          browserHistory.replace({
            pathname: lastpathName.path,
            query: lastpathName.params,
          })
        }
      },
      modal: {
        ondismiss: () => {
          browserHistory.replace({
            pathname:
              _.get(this, 'props.store.selectedBrand.BrandName', '').toUpperCase() !== 'APPLE' &&
              _.get(this, 'props.user.appConfigDetails.nonOtpPlanSale', false)
                ? '/store/buy'
                : lastpathName.path,
            query: lastpathName.params,
          })
        },
      },
      prefill: {
        name: this.state.userDetails.name,
        email: this.state.userDetails.emailAddress,
        contact: this.state.userDetails.number,
      },
      notes: {
        app: BrandSpecificConfig().header,
        id: this.props.payment.payableDetails.id,
      },
      theme: {
        color: getComputedStyle(document.querySelector(':root')).getPropertyValue('--primary-700'),
      },
    }
    let rzp = new Razorpay(options)
    rzp.open()
  }

  openTelrCheckout = () => {
    if (['declined', 'cancelled'].includes(_.get(this, 'props.location.query.telrPayStatus', ''))) {
      let status =
        _.get(this, 'props.location.query.telrPayStatus', '') === 'declined' ? getTranslationText('payment', 'telrCancelText') : undefined
      status && this.props.setToaster(getTranslationText('error', 'paymentWas') + status, 'error')
      browserHistory.push({
        pathname: this.props.location.lastpathName.path,
        query: this.props.location.lastpathName.params,
      })
    } else if (_.get(this, 'props.location.query.telrPayStatus', '') === 'authorised') {
      let reqObj = this.props.initTelr('check')
      this.props.setglobalLoaderStatus(true)
      this.props
        .CheckOrCreateTelrPaymentAsync(reqObj)
        .then((res) => {
          this.props.setglobalLoaderStatus(false)
          if (res.order.status.code === 3) {
            if (_.get(this, 'props.request.requestModesData.IsAdvanceChargeApplicable', false)) {
              _.get(this, 'props.user.appConfigDetails.PaymentConfirmationText', false) &&
                this.props.setToaster(getTranslationText('payment', 'paymentConfirmation'), 'success')
              this.props.getNotificationsAsync({
                ConsumerID: this.props.user.consumerDetails.data.consumerID,
                AuthID: this.props.user.consumerDetails.data.accessToken.ID,
              })

              this.props.setPlanActivated(true)
              this.props.setPaymentRequestStatus(true)
              browserHistory.push({
                pathname: this.props.location.lastpathName.path,
                query: {
                  ...this.props.location.lastpathName.params,
                  step: this.props.location.lastpathName.path === '/track' ? undefined : 3,
                  [this.props.location.query.region ? 'region' : '']: this.props.location.query.region,
                },
              })
            } else {
              this.handlePostAsyncAction({
                paymentID: res.order.ref,
              })
            }
          } else {
            // issue with payment verification
          }
        })
        .catch(() => {
          this.props.setglobalLoaderStatus(false)
          this.props.setToaster(getTranslationText('error', 'somethingWentWrong'), 'error')
          browserHistory.push({
            pathname: this.props.location.lastpathName.path,
            query: this.props.location.lastpathName.params,
          })
        })
    } else {
      this.initiateTelr()
    }
  }

  handleError = async () => {
    try {
      this.props.setToaster(getTranslationText('error', 'somethingWentWrong'), 'error')
      await this.props.addPaymentInfoAsync()
    } catch (e) {
      console.log(e)
    }
  }

  initiateTelr = () => {
    this.props.setTelrCreateDetails({})
    this.props.setTelrCheckDetails({})
    let reqObj = this.props.initTelr('create')
    this.props.setglobalLoaderStatus(true)
    this.props
      .CheckOrCreateTelrPaymentAsync(reqObj)
      .then(() => {
        this.props.setglobalLoaderStatus(false)
      })
      .catch(() => {
        this.props.setToaster(getTranslationText('error', 'somethingWentWrong'), 'error')
        this.props.setglobalLoaderStatus(false)
        browserHistory.push({
          pathname: this.props.location.lastpathName.path,
          query: this.props.location.lastpathName.params,
        })
      })
  }

  toggleModal = (modalName, showModal) => {
    this.setState({ showModal: { [modalName]: showModal } })
  }

  handleOnStripePaymentFailure = () => {
    this.toggleModal('stripeError', false)
    this.state.stripeError?.data?.hasPaymentReceived && browserHistory.replace('/')
  }

  callChargeAPI = async (paymentIntent) => {
    try {
      this.props.setglobalLoaderStatus(true)
      if (
        _.get(this, 'props.store.selectedBrand.BrandName', '').toUpperCase() !== 'APPLE' &&
        _.get(this, 'props.user.appConfigDetails.nonOtpPlanSale', false) &&
        !_.get(this, 'props.user.userDetails.consumerID', false)
      ) {
        // console.log('paymentIntent - ', paymentIntent)
        let planInfo = _.get(this, 'props.store.selectedPlanDetails', {})
        let planPrice = {
          PlanID: planInfo.PlanID,
          PlanPriceID: planInfo.planPrice.find((planprice) => planprice.isSelected).PlanPriceID,
        }
        let fetchedPlanPrice = _.get(this, 'props.store.planPriceOnProductBasis', {})
        if (planInfo.priceRange && fetchedPlanPrice.PlanPrice) {
          // plan price fetched on product basis
          planPrice = {
            PlanID: fetchedPlanPrice.PlanID,
            PlanPriceID: fetchedPlanPrice.PlanPriceID,
          }
        }
        const consumerAddress = _.get(this, 'props.store.consumerAddress', {})
        const dob = _.get(this, 'props.store.userData.BirthDate', undefined)
        const isTaxAddress = !!_.get(this, 'props.store.taxAddress', undefined)
        let obj = {
          ...paymentIntent,
          ...consumerAddress,
          intentID: paymentIntent.id || undefined,
          PaymentProcessID: _.get(this, 'props.store.planPaymentDetails.PaymentProcessID', undefined),
          CurrencyID: planInfo.CurrencyID || undefined,
          CurrencyCode: _.get(this, 'props.user.selectedCountryData.CurrencyCode', undefined),
          currency: _.get(this, 'props.payment.payableDetails.currency', undefined),
          Zipcode: isTaxAddress
            ? _.get(this, 'props.store.taxAddress.PinCode', undefined)
            : _.get(this, 'props.store.consumerAddress.PinCode', undefined),
          ConsumerAddress: isTaxAddress
            ? {
                AddressLine1: _.get(this, 'props.store.taxAddress.UserLandmark', undefined),
                AddressLine2: _.get(this, 'props.store.taxAddress.Address', undefined),
                City: _.get(this, 'props.store.taxAddress.City', undefined),
                State: _.get(this, 'props.store.taxAddress.State', undefined),
                Zipcode: _.get(this, 'props.store.taxAddress.PinCode', undefined),
              }
            : consumerAddress,
          PlanID: planInfo.PlanID || undefined,
          Status: 1,
          PlanArray: [
            {
              ...planPrice,
              PlanPriceBillingConfigID: _.get(this, 'props.store.selectedPaymentOption.PlanPriceBillingConfigID'),
            },
          ],
          PlanPriceID: planPrice.PlanPriceID || undefined,
          StateCode: _.get(this, 'props.store.selectedState.StateCode', undefined),
          isSubscriptionPayment: _.get(this, 'props.store.selectedPaymentOption.PaymentModeCode', '') === 'EMI',
          ExternalPlanCode: _.get(this, 'props.store.purchaseEligibilityInfo.ExternalPlanCode', undefined),
          PaymentMode: _.get(this, 'props.store.selectedPaymentOption.PaymentModeCode', undefined),
          PlanPriceBillingConfigID: _.get(this, 'props.store.selectedPaymentOption.PlanPriceBillingConfigID', undefined),
          Gateway: _.get(this, 'props.payment.payableDetails.gateway', undefined),
          EmailID: _.get(this, 'props.store.userData.email'),
          FirstName: _.get(this, 'props.store.userData.name'),
          MobileNo: _.get(this, 'props.store.userData.mobile'),
          BirthDate: dob ? moment(dob).format('YYYY-MM-DD') : undefined,
          CountryCode: _.get(this, 'props.user.selectedCountryData.CountryCode', undefined),
          CountryID: _.get(this, 'props.user.selectedCountryData.CountryID, undefined'),
          PhoneCode: _.get(this, 'props.user.selectedCountryData.PhoneCode', undefined),
          app: BrandSpecificConfig().header,
          PlanDiscountConfigurationID:
            _.get(this, 'props.store.selectedPaymentOption.maxDiscountOffer.DiscountCategory', '') === 'PromoCode'
              ? _.get(this, 'props.store.selectedPaymentOption.appliedDiscountOffer.PlanDiscountConfigurationID', undefined)
              : _.get(this, 'props.store.selectedPaymentOption.maxDiscountOffer.PlanDiscountConfigurationID', undefined),
          hasConcession: _.get(this, 'props.store.selectedPaymentOption.maxDiscountOffer.hasConcession', undefined),
        }
        /********************** ConsumerProduct/postPaymentAction - Logger API /**********************/
        // if (window.useLogger) {
        //   let loggerObj = {
        //     apiName: 'ConsumerProduct/postPaymentAction',
        //     value: obj,
        //     event: 'ConsumerProduct/postPaymentAction',
        //     identifiers: {
        //       EmailID: _.get(this, 'props.store.userData.email', undefined),
        //       FirstName: _.get(this, 'props.store.userData.name', undefined),
        //       MobileNo: _.get(this, 'props.store.userData.mobile', undefined),
        //       ConsumerID: _.get(this, 'props.user.userDetails.consumerID', undefined),
        //     },
        //   }
        //   this.props.loggerActionAsync(loggerObj)
        // }
        /********************** ConsumerProduct/postPaymentAction  - Logger API /**********************/
        // console.log('postPaymentActionNonLoginFlow - payload - ', obj)
        const response = await this.props.postPaymentActionNonLoginFlow(obj)
        // console.log('postPaymentActionNonLoginFlow - response - ', response)
        /********************** ConsumerProduct/postPaymentAction response - Logger API /**********************/
        // if (window.useLogger) {
        //   let loggerObj = {
        //     apiName: 'ConsumerProduct/postPaymentActionResponse',
        //     value: response,
        //     event: 'ConsumerProduct/postPaymentActionResponse',
        //     identifiers: {
        //       EmailID: _.get(this, 'props.store.userData.email', undefined),
        //       FirstName: _.get(this, 'props.store.userData.name', undefined),
        //       MobileNo: _.get(this, 'props.store.userData.mobile', undefined),
        //       ConsumerID: _.get(this, 'props.user.userDetails.consumerID', undefined),
        //     },
        //   }
        //   this.props.loggerActionAsync(loggerObj)
        // }
        /********************** ConsumerProduct/postPaymentAction  response - Logger API /**********************/
        if (response && response.Status && response.SoldPlanID) {
          this.setPaymentStatus('success')
          this.props.setPaymentRequestStatus(true)
          const { PlanName } = _.get(this, 'props.store.selectedPlanDetails')
          const { ValidTillMonths } = _.get(this, 'props.store.selectedPlanDetails.PlanConfig')
          const { BrandName } = _.get(this, 'props.store.selectedPlanDetails.EntityDetails[0]')
          const { ProductObj, PlanPrice } = _.get(this, 'props.store.purchaseEligibilityInfo', {})
          const { consumerID } = _.get(this, 'props.user.userDetails', false)
          const clevertapUserProfile = _.get(this, 'props.user', {})
          pushToAnalytics(
            'Plan Sales - Plan Purchased',
            {
              'Plan Name': PlanName,
              Brand: BrandName,
              'Policy Name': PlanName,
              'Plan Validity': ValidTillMonths,
              'Plan Price': PlanPrice ? PlanPrice.PlanPrice : undefined,
              'IMEI or Serial': ProductObj ? ProductObj.ProductUniqueID : undefined,
              'Payment ID': _.get(this, 'props.store.planPaymentDetails.PaymentProcessID', undefined),
              Source: 'Web',
              IMEI_checked_on_storepage: !!_.get(this, 'props.store.imeiForFilter', false),
              eureka: !!_.get(this, 'props.store.imeiFromToken', false),
              'Logged In': consumerID ? 'Yes' : 'No',
              ConsumerID: response.ConsumerID || undefined,
              ConsumerProductID: response.ConsumerProductID || undefined,
              ProductName: _.get(this, 'props.store.purchaseEligibilityInfo.ProductName', ''),
            },
            clevertapUserProfile
          )
          browserHistory.push({
            pathname: this.props.location.lastpathName.path,
            query: {
              ...this.props.location.lastpathName.params,
              step: this.props.location.lastpathName.path === '/track' ? undefined : 3,
              [this.props.location.query.region ? 'region' : '']: this.props.location.query.region,
            },
          })
        } else {
          this.setPaymentStatus('failure')
          this.setStatusMessage(response && response.msg ? response.msg : getTranslationText('error', 'somethingWentWrong'))
        }
        this.props.setglobalLoaderStatus(false)
      } else {
        if (
          this.props.location.lastpathName.path === '/devices' ||
          this.props.location.lastpathName.path === '/home' ||
          this.props.location.lastpathName.path === '/myplans'
        ) {
          const resp = await this.props.updatePaymentDetailsAsync({
            PaymentProcessID: _.get(this, 'props.payment.payableDetails.PaymentProcessID', undefined),
            SoldPlanID: _.get(this, 'props.myPlans.soldPlanDetails.SoldPlanID', undefined),
            isCardChange: true,
          })
          pushToAnalytics('Change Payment Method - Update Payment', {
            ConsumerID: _.get(this, 'props.user.userDetails.consumerID', ''),
            SoldPlanID: _.get(this, 'props.myPlans.soldPlanDetails.SoldPlanID', ''),
            PlanName: _.get(this, 'props.myPlans.soldPlanDetails.PlanName', ''),
            success: resp.data.success || '',
          })
          this.setState({ showUpdateCardDetailsModal: true, cardDetailsUpdateStatus: resp.data.success })
          this.props.setglobalLoaderStatus(false)
          return
        }
        await this.props.makeStripeChargeAsync(paymentIntent)
        if (this.props.location.lastpathName && this.props.location.lastpathName.path === '/request/confirm') {
          if (this.props.payment?.holdAmount && !this.props.payment.holdAmount?.isHoldAmount) {
            // forced call claim-api on Hold failure
            this.props.setRequestHoldPaymentStatus('Failed')
            this.props.setPaymentRequestStatus(true)
            this.props.setPaymentID(paymentIntent?.id)
            browserHistory.push({
              pathname: this.props.location.lastpathName.path,
              query: this.props.location.lastpathName.params,
            })
            pushToAnalytics('Payment_unsuccessful', {})
          } else if (this.props.payment?.status === 'Refunded') {
            this.props.setRequestHoldPaymentStatus('Refunded')
            this.setPaymentStatus('failure')
            this.setStatusMessage(getTranslationText('error', 'somethingWentWrong'))
            this.props.setglobalLoaderStatus(false)
            pushToAnalytics('Payment_unsuccessful', {})
            return
          } else {
            pushToAnalytics('Payment_successful', {})
            this.props.setPaymentRequestStatus(true)
            this.props.setPaymentID(paymentIntent.id)
            browserHistory.push({
              pathname: this.props.location.lastpathName.path,
              query: this.props.location.lastpathName.params,
            })
          }
        }
        this.props.getNotificationsAsync({
          ConsumerID: this.props.user.userDetails.consumerID,
          AuthID: this.props.user.consumerDetails.data.accessToken.ID,
        })
        if (this.props.payment.stripeCharge) {
          this.props.setglobalLoaderStatus(false)
          this.setState({
            isLoadingCheckout: false,
          })
          this.props.setPlanActivated(true)
          if (this.props.location.lastpathName && this.props.location.lastpathName.path === '/subscription/review') {
            pushToAnalytics(
              'Plan Sales - Plan Purchase Successful',
              {
                'Plan Name': this.props.subscription.planDetails.PlanDetails.PlanName,
                'Total Devices': this.props.subscription.deviceDetails?.length || 0,
                'Customer Name': this.props.user.userDetails.name,
                Email: this.props.user.userDetails.emailID,
                'Mobile Number': this.props.user.userDetails.mobileNo,
                ConsumerID: this.props.user.userDetails.consumerID,
              },
              this.props.user
            )
            if (this.props.payment.status !== 'paid') {
              this.setPaymentStatus('processing')
              this.setStatusMessage(getTranslationText('payment', 'processingStatusMsg'))
            } else {
              browserHistory.replace({ pathname: this.props.location.lastpathName.nextPath })
            }
            return
          }
          if (this.props.location && this.props.location.pathname !== '/request/confirm') {
            if (this.props.payment.status !== 'paid' && this.props.payment.status !== 'authorized') {
              this.setPaymentStatus('processing')
              this.setStatusMessage(getTranslationText('payment', 'processingStatusMsg'))
            } else {
              if (this.props.location && this.props.location.lastpathName.path !== '/track') {
                const { PlanName } = _.get(this, 'props.store.selectedPlanDetails')
                const { ValidTillMonths } = _.get(this, 'props.store.selectedPlanDetails.PlanConfig')
                const { BrandName } = _.get(this, 'props.store.selectedPlanDetails.EntityDetails[0]')
                const { ProductObj, PlanPrice } = _.get(this, 'props.store.purchaseEligibilityInfo', {})
                const { emailID, mobileNo, consumerID } = _.get(this, 'props.user.userDetails', false)
                // ------------- CleverTap | Plan Sales - Plan Purchased --------/
                const clevertapUserProfile = _.get(this, 'props.user', {})
                pushToAnalytics(
                  'Plan Sales - Plan Purchased',
                  {
                    Email: emailID,
                    'Mobile Number': mobileNo,
                    ConsumerID: consumerID || undefined,
                    'Plan Name': PlanName,
                    Brand: BrandName,
                    'Policy Name': PlanName,
                    'Plan Validity': ValidTillMonths,
                    'Plan Price': PlanPrice ? PlanPrice.PlanPrice : undefined,
                    'IMEI or Serial': ProductObj ? ProductObj.ProductUniqueID : undefined,
                    ConsumerProductID: _.get(this, 'props.store.planConsumerProduct.ConsumerProductID', undefined),
                    'Payment ID': _.get(this, 'props.payment.chargePaymentId'),
                    Source: 'Web',
                    IMEI_checked_on_storepage: !!_.get(this, 'props.store.imeiForFilter', false),
                    eureka: !!_.get(this, 'props.store.imeiFromToken', false),
                    'Logged In': consumerID ? 'Yes' : 'No',
                    ProductName: _.get(this, 'props.store.purchaseEligibilityInfo.ProductName', ''),
                  },
                  clevertapUserProfile
                )
              }
              if (this.props.location.lastpathName && this.props.location.lastpathName.path === '/track') {
                if (this.props.payment?.holdAmount && !this.props.payment.holdAmount?.isHoldAmount) {
                  this.props.setTrackHoldPaymentStatus('Failed')
                  this.props.setPaymentID(paymentIntent?.id)
                  browserHistory.push({
                    pathname: this.props.location.lastpathName.path,
                    query: {
                      ...this.props.location.lastpathName.params,
                      step: this.props.location.lastpathName.path === '/track' ? undefined : 3,
                      [this.props.location.query.region ? 'region' : '']: this.props.location.query.region,
                    },
                  })
                } else if (this.props.payment?.status === 'Refunded') {
                  this.props.setTrackHoldPaymentStatus('Refunded')
                  browserHistory.push({
                    pathname: this.props.location.lastpathName.path,
                    query: {
                      ...this.props.location.lastpathName.params,
                      step: this.props.location.lastpathName.path === '/track' ? undefined : 3,
                      [this.props.location.query.region ? 'region' : '']: this.props.location.query.region,
                    },
                  })
                } else {
                  this.props.setTrackHoldPaymentStatus('Success')
                  browserHistory.push({
                    pathname: this.props.location.lastpathName.path,
                    query: {
                      ...this.props.location.lastpathName.params,
                      step: this.props.location.lastpathName.path === '/track' ? undefined : 3,
                      [this.props.location.query.region ? 'region' : '']: this.props.location.query.region,
                    },
                  })
                }
              } else {
                browserHistory.push({
                  pathname: this.props.location.lastpathName.path,
                  query: {
                    ...this.props.location.lastpathName.params,
                    step: this.props.location.lastpathName.path === '/track' ? undefined : 3,
                    [this.props.location.query.region ? 'region' : '']: this.props.location.query.region,
                  },
                })
              }
            }
          }
        } else {
          this.props.setglobalLoaderStatus(false)
          this.setState({
            isLoadingCheckout: false,
          })
        }
      }
    } catch (error) {
      this.props.setRetryPaymentStatus(true)
      this.props.setglobalLoaderStatus(false)
      this.setState({ isLoadingCheckout: false })
      this.props.setToaster(error.msg, 'error')
      this.setStatusMessage(error.msg || getTranslationText('error', 'somethingWentWrong'))
      this.setPaymentStatus('failure')
      let cleverTapObject = {
        'Plan Name': _.get(
          this,
          'props.store.selectedPlanDetails.PlanName',
          _.get(this, 'props.subscription.planDetails.PlanDetails.PlanName', '')
        ),
        'Error Message': error.msg,
        ConsumerID: _.get(this, 'props.user.userDetails.consumerID', undefined),
        eureka: !!_.get(this, 'props.store.imeiFromToken', false),
        ProductName: _.get(this, 'props.store.purchaseEligibilityInfo.ProductName', ''),
      }
      if (!error.data?.hasPaymentReceived) {
        pushToAnalytics('Plan Sales - Payment Unsuccessful', cleverTapObject, this.props.user)
      }
      throw error
      // Commenting because of COUI-3012
      // if (error.msg) {
      //   const number = _.get(this.props, 'user.appConfigDetails.footerLinks.support.number')
      //   const email = _.get(this.props, 'user.appConfigDetails.footerLinks.support.email')
      //   error.msg = error.msg.replace('@email', `<a style='font-size:inherit' class='button-link' href='mailto:${email}'>${email}</a>`)
      //   error.msg = error.msg.replace('@number', `<a style='font-size:inherit' class='button-link' href='tel:+${number}'>${number}</a>`)
      //   let cleverTapObject = {
      //     'Plan Name': this.props.subscription.planDetails.PlanDetails.PlanName,
      //     'Customer Name': this.props.user.userDetails.Name,
      //     'Error Message': error.msg,
      //   }
      //   let cleverTapTitle = 'Plan Sales - Payment Unsuccessful'
      //   if (error.data?.hasPaymentReceived) {
      //     cleverTapTitle = 'Plan Sales - Payment Successful and Plan Creation Failure'
      //     cleverTapObject['Total Price'] = this.props.payment.payableDetails.amount / 100
      //   } else {
      //     cleverTapObject['Email Address'] = this.props.user.userDetails.EmailID
      //     cleverTapObject['Mobile Number'] = this.props.user.userDetails.MobileNo
      //   }
      //   pushToAnalytics(cleverTapTitle, cleverTapObject, this.props.user)
      //   this.setState({ isLoadingCheckout: false, stripeError: error })
      //   this.toggleModal('stripeError', true)
      // } else {
      // }
    }
  }

  handleCardFormSubmit = async (e) => {
    pushToAnalytics('Payment_page_make_payment_button_clicked', {
      ConsumerID: _.get(this, 'props.user.userDetails.consumerID', ''),
    })
    e.preventDefault()
    this.props.setglobalLoaderStatus(true)
    const stripe = window.stripeInstance
    try {
      let response
      const options = {
        elements: this.stripeElementsRef.current,
        confirmParams: {
          return_url: window.location.origin + '/payment/stripe-status',
        },
      }
      if (_.get(this, 'props.payment.payableDetails.stripe.setupIntent', false)) {
        response = await stripe.confirmSetup(options)
      } else {
        response = await stripe.confirmPayment(options)
      }
      this.props.setglobalLoaderStatus(false)
      if (response.error) {
        // error handling for stripe failure modal
        console.log(response.error)
        document.getElementById('card-errors').textContent = response.error.message
        this.props.setToaster(response.error.message, 'error')
      } else {
        document.getElementById('card-errors').textContent = ''
        //if no error -> user is automatically redirected to /payment/stripe-status page by stripe
      }
    } catch (e) {
      console.log(e)
      this.props.setglobalLoaderStatus(false)
      window.location.reload(false)
    }
  }

  handlePostPaymentActionResponse = (postPaymentResponse) => {
    this.props.setglobalLoaderStatus(false)
    this.props.getNotificationsAsync({
      ConsumerID: this.props.user.consumerDetails.data.consumerID,
      AuthID: this.props.user.consumerDetails.data.accessToken.ID,
    })
    if (this.props.payment.postPaymentDetails || postPaymentResponse[0].SoldPlanID) {
      _.get(this, 'props.user.appConfigDetails.PaymentConfirmationText', false) &&
        this.props.setToaster(getTranslationText('payment', 'paymentConfirmation'), 'success')
      //  this.props.payment.postPaymentDetails[0].SoldPlanID
      if (typeof this.props.payment.postPaymentDetails !== 'boolean' || (postPaymentResponse && postPaymentResponse[0].SoldPlanID)) {
        this.props.setPlanActivated(true)
      }
      // window.location.assign('/plans/activation')
      browserHistory.push({
        pathname: this.props.location.lastpathName.path,
        query: {
          ...this.props.location.lastpathName.params,
          step: this.props.location.lastpathName.path === '/track' ? undefined : 3,
          [this.props.location.query.region ? 'region' : '']: this.props.location.query.region,
        },
      })
    } else {
      browserHistory.push({
        pathname: this.props.location.lastpathName.path,
        query: this.props.location.lastpathName.params,
      })
    }
    if (this.props.location.lastpathName.path === '/store/checkout') {
      const { PlanName } = _.get(this, 'props.store.selectedPlanDetails')
      const { ValidTillMonths } = _.get(this, 'props.store.selectedPlanDetails.PlanConfig')
      const { BrandName } = _.get(this, 'props.store.selectedPlanDetails.EntityDetails[0]')
      const { ProductObj, PlanPrice } = _.get(this, 'props.store.purchaseEligibilityInfo', {})
      const soldPlanID = _.get(postPaymentResponse[0], 'SoldPlanID', undefined)
      const { emailID, mobileNo, consumerID } = _.get(this, 'props.user.userDetails', false)
      // ------------- CleverTap | Plan Sales - Plan Purchased --------/
      const clevertapUserProfile = _.get(this, 'props.user', {})
      pushToAnalytics(
        'Plan Sales - Plan Purchased',
        {
          Email: emailID || undefined,
          'Mobile Number': mobileNo || undefined,
          ConsumerID: consumerID || undefined,
          'Plan Sold ID': soldPlanID,
          'Plan Name': PlanName,
          Brand: BrandName,
          'Policy Name': PlanName,
          'Plan Validity': ValidTillMonths,
          'Plan Price': PlanPrice ? PlanPrice.PlanPrice : undefined,
          'IMEI or Serial': ProductObj ? ProductObj.ProductUniqueID : undefined,
          ConsumerProductID: _.get(this, 'props.store.planConsumerProduct.ConsumerProductID', undefined),
          Source: 'Web',
          IMEI_checked_on_storepage: !!_.get(this, 'props.store.imeiForFilter', false),
          eureka: !!_.get(this, 'props.store.imeiFromToken', false),
          'Logged In': consumerID ? 'Yes' : 'No',
          ProductName: _.get(this, 'props.store.purchaseEligibilityInfo.ProductName', ''),
        },
        clevertapUserProfile
      )
      // ------------- CleverTap | Plan Sales - Plan Purchased --------/
    }
  }

  handlePostAsyncAction = (reqObj) => {
    this.props.setglobalLoaderStatus(true)
    pushToAnalytics(
      'Payment Successful',
      {
        'Customer Name': _.get(this, 'props.user.userDetails.name', undefined),
        Email: _.get(this, 'props.user.userDetails.emailID', undefined),
        'Mobile Number': _.get(this, 'props.user.userDetails.mobileNo', undefined),
        ConsumerID: _.get(this, 'props.user.userDetails.consumerID', undefined),
        'Plan Name': _.get(this, 'props.store.selectedPlanDetails.PlanName', undefined),
        Gateway: _.get(this, 'props.payment.payableDetails.gateway', undefined),
      },
      _.get(this, 'props.user')
    )
    this.props
      .postPaymentActionAsync(reqObj)
      .then((postPaymentResponse) => {
        this.handlePostPaymentActionResponse(postPaymentResponse)
      })
      .catch(() => {
        this.props.setToaster(getTranslationText('error', 'somethingWentWrong'), 'error')
        this.props.setglobalLoaderStatus(true)
        browserHistory.replace('/')
        if (this.props.location.lastpathName.path === '/store/checkout') {
          const { PlanName } = _.get(this, 'props.store.selectedPlanDetails')
          const { ValidTillMonths } = _.get(this, 'props.store.selectedPlanDetails.PlanConfig')
          const { BrandName } = _.get(this, 'props.store.selectedPlanDetails.EntityDetails[0]')
          const { ProductObj, PlanPrice } = _.get(this, 'props.store.purchaseEligibilityInfo', {})
          const { emailID, mobileNo, consumerID } = _.get(this, 'props.user.userDetails', false)
          // ------------- CleverTap | Plan Sales - Plan Purchase Failed --------/
          const clevertapUserProfile = _.get(this, 'props.user', {})

          pushToAnalytics(
            'Plan Sales - Plan Purchase Failed',
            {
              Email: emailID || undefined,
              'Mobile Number': mobileNo || undefined,
              ConsumerID: consumerID || undefined,
              'Plan Name': PlanName,
              Brand: BrandName,
              'Policy Name': PlanName,
              'Plan Validity': ValidTillMonths,
              'Plan Price': PlanPrice ? PlanPrice.PlanPrice : undefined,
              'IMEI or Serial': ProductObj ? ProductObj.ProductUniqueID : undefined,
              ConsumerProductID: _.get(this, 'props.store.planConsumerProduct.ConsumerProductID', undefined),
              Source: 'Web',
              eureka: !!_.get(this, 'props.store.imeiFromToken', false),
              ProductName: _.get(this, 'props.store.purchaseEligibilityInfo.ProductName', ''),
            },
            clevertapUserProfile
          )
          // ------------- CleverTap | Plan Sales - Plan Purchase Failed --------/
          this.props.setglobalLoaderStatus(false)
        }
      })
  }

  loadIyzicoCheckoutForm = () => {
    if (!this.props.payment.payableDetails.iyzipay.checkoutFormContent.length) {
      this.props.setToaster(getTranslationText('error', 'somethingWentWrong'), 'error')
      return
    }
    let script = document.createElement('script')
    script.type = 'text/javascript'
    script.innerHTML = this.props.payment.payableDetails.iyzipay.checkoutFormContent
      .replace('<script type="text/javascript">', '')
      .replace('</script>', '')
    this.props.setglobalLoaderStatus(true)
    this.setState({
      iyzicoButton: false,
      amazonPayButton: false,
    })
    document.body.appendChild(script)
    let intrvlID = null
    let isIyzipayInitialized = () => {
      if (window.iyziInit) {
        setTimeout(() => {
          this.props.setglobalLoaderStatus(false)
        }, 3000)
        clearInterval(intrvlID)
      }
    }
    intrvlID = setInterval(isIyzipayInitialized, 1000)
  }

  checkoutAmazonPayment = () => {
    this.props.setglobalLoaderStatus(true)
    this.props.addPaymentInfoAsync()
    setTimeout(() => {
      this.props.setglobalLoaderStatus(false)
    }, 5000)
  }

  handleBackButton = () => {
    removeVisitedUrl()
    browserHistory.goBack()
  }

  createPaypalOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: this.state.total,
          },
        },
      ],
    })
  }

  onPaypalApprove = (data, actions) => {
    return actions.order.capture().then((response) => {
      if (this.props.location.lastpathName && this.props.location.lastpathName.path !== '/request/confirm') {
        this.handlePostAsyncAction({
          paymentID: response.id,
          PaymentStatus: 'success',
        })
      } else {
        this.props.setPaymentRequestStatus(true)
        this.props.setPaymentID(response.id)
        browserHistory.push({
          pathname: this.props.location.lastpathName.path,
          query: this.props.location.lastpathName.params,
        })
      }
    })
  }

  onPaypalInit = () => {
    this.setState({ isPaypalLoading: false })
  }

  render() {
    const params = this.props.params.p

    const amazonpay_checkout_url =
      _.get(this, 'props.payment.payableDetails.amazonpayment') && this.props.payment.payableDetails.amazonpayment.gateway_url
    if (params === 'stripe-status') {
      return (
        <PaymentStatus
          statusMessage={this.state.statusMessage}
          paymentStatus={this.state.paymentStatus}
          setStatusMessage={this.setStatusMessage}
          setPaymentStatus={this.setPaymentStatus}
          callChargeAPI={this.callChargeAPI}
          {...this.props}
          showUpdateCardDetailsModal={this.state.showUpdateCardDetailsModal}
          cardDetailsUpdateStatus={this.state.cardDetailsUpdateStatus}
          closeUpdateCardDetailsModal={this.closeUpdateCardDetailsModal}
        />
      )
    }
    return (
      <>
        <div>
          {this.state.paypalButton ? (
            <div
              style={{
                marginRight: 'auto',
                marginLeft: 'auto',
                display: 'table',
                marginTop: '130px',
                marginBottom: '100px',
                border: '1px solid black',
                padding: '20px',
              }}
            >
              <div style={{ textAllign: 'center' }}>
                <h5 style={{ marginBottom: '25px' }}>{getTranslationText('payment', 'payPalText')}</h5>
                {this.state.isPaypalLoading && <Loader />}
                <PayPalScriptProvider
                  options={{
                    'client-id': _.get(this, 'props.payment.payableDetails.ApiKey', ''),
                    currency: this.state.currency,
                  }}
                >
                  <PayPalButtons
                    style={{ layout: 'vertical' }}
                    createOrder={this.createPaypalOrder}
                    onApprove={this.onPaypalApprove}
                    onInit={this.onPaypalInit}
                  />
                </PayPalScriptProvider>
              </div>
            </div>
          ) : (
            ' '
          )}
          {this.state.paypalCancel ? (
            <div
              style={{
                marginRight: 'auto',
                marginLeft: 'auto',
                display: 'table',
                padding: '20px',
                color: 'red',
                marginTop: '-100px',
                marginBottom: '130px',
              }}
            >
              <div>
                <h5>{getTranslationText('payment', 'payPalCancelText')}</h5>
              </div>
            </div>
          ) : (
            ' '
          )}
          {this.state.iyzicoButton ? (
            <div
              style={{
                marginRight: 'auto',
                marginLeft: 'auto',
                display: 'table',
                marginTop: '130px',
                marginBottom: '100px',
                border: '1px solid black',
                padding: '20px',
              }}
            >
              <div style={{ textAllign: 'center' }}>
                <h5 style={{ marginBottom: '25px' }}>{getTranslationText('payment', 'iyzicoText')}</h5>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <MediumButton buttonText={getTranslationText('payment', 'checkout')} onClick={this.loadIyzicoCheckoutForm} />
                </div>
              </div>
            </div>
          ) : (
            ' '
          )}
          {this.state.amazonPayButton ? (
            <div>
              <form method='POST' action={amazonpay_checkout_url} id='amazonpay' name='amazonpay'>
                {Object.keys(this.props.payment.payableDetails.amazonpayment).map((key, i) => {
                  if (key !== 'gateway_url') {
                    return <input type='hidden' key={i} name={key} value={this.props.payment.payableDetails.amazonpayment[key]} />
                  }
                })}
              </form>
            </div>
          ) : (
            ' '
          )}
          <div className='row justify-content-center mb28'>
            <PaymentPortal
              isLoading={this.state.isLoading}
              handleCardFormSubmit={this.handleCardFormSubmit}
              handleBackButton={this.handleBackButton}
              {...this.props}
              isLoadingCheckout={this.state.isLoadingCheckout}
              pollingFor3dSecure={this.state.pollingFor3dSecure}
              pollMessage={this.state.pollMessage}
              pollTimer={this.state.pollTimer}
              stripeFormStatus={this.state.stripeFormStatus}
            />
          </div>
        </div>
        {
          // Commenting because of COUI-3012
          /* <Modal
          className='plan-modal'
          showModal={this.state.showModal.stripeError}
          closeModal={this.handleOnStripePaymentFailure}
          showCloseBtn={true}
        >
          <Modal.Header className='text-center'>
            <img src={failureAlert} alt='failure-alert' />
            <h4 className='text-center'>{getTranslationText('error', 'errorOccurred')}!</h4>
          </Modal.Header>
          <Modal.Body>
            <h5 className='m20 text-center' dangerouslySetInnerHTML={{ __html: this.state.stripeError.msg }} />
          </Modal.Body>
          <Modal.Footer className='justify-center'>
            <Button classes='button-primary' handleOnClick={this.handleOnStripePaymentFailure}>
              {getTranslationText('common', this.state.stripeError?.data?.hasPaymentReceived ? 'understood' : 'retry')}
            </Button>
          </Modal.Footer>
        </Modal> */
        }
      </>
    )
  }
}
