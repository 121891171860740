import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { MyPlansMainComponents, SelectedPlansSubscriptionDetails } from './MyPlansSubComponents'
import './MyPlans.scss'
import { browserHistory } from 'react-router'
import _ from 'lodash'
import { DeviceAdditionModal } from './MyPlansModalComponents'
import pushToAnalytics from '../../../utils/Analytics'
import { BillingHistory } from './../../Devices/components/DevicesSubComponents'
import { BrandSpecificConfig } from '../../../utils/constants'
class MyPlans extends Component {
  constructor(props) {
    super(props)
    this.state = {
      planViewSelectedTab: 0,
      showBillingHistory: false,
      billingHistoryData: {},
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.props.setglobalLoaderStatus(true)
    this.props
      .getPurchasedPlansAsync()
      .then(() => {
        const plansList = _.get(this, 'props.myPlans.purchasedPlanList', [])
        if (plansList.length > 0) {
          plansList.forEach((plan) => {
            if (plan.PlanList.length > 0) {
              plan.PlanList.forEach(async (planDetail) => {
                if (planDetail.SoldPlanID && planDetail.Category !== 'NORMAL' && planDetail.RequiresExternalPayment === 'SERVIFY_PAYMENT') {
                  await this.props.fetchCardDetailsAsync(
                    planDetail.SoldPlanID,
                    planDetail.Status,
                    planDetail.PlanName,
                    planDetail.GroupSoldPlanID || undefined
                  )
                }
              })
            }
          })
        }
      })
      .finally(() => {
        this.props.setglobalLoaderStatus(false)
      })
  }

  listPlanViewDetails = (index) => {
    this.setState({
      planViewSelectedTab: parseInt(index),
    })
  }

  viewSelectedPlan = (planDetails) => {
    if (_.includes([1, -5, -9], planDetails.Status)) {
      this.props.clearMyPlanDetails()
      this.props.setPlansEligibleDevices([])
      this.props.setSelectedPurchasedPlanDetails()
      this.props.setglobalLoaderStatus(true)
      this.props.setDeviceAdditionModal(false)
      this.props
        .getEnrolledDevicesAsync(planDetails.GroupSoldPlanID)
        .then(() => {
          browserHistory.push({
            pathname: '/myplans/subscription',
            query: {
              ...this.props.location.query,
              subsciption_id: planDetails.SoldPlanID,
              isActive: planDetails.Status === 1 || (planDetails.Status === -9 && planDetails.GroupSoldPlanStatus === -9),
            },
          })
        })
        .finally(() => {
          this.props.setglobalLoaderStatus(false)
        })
    }
  }

  fetchEligibleDeviceAsync = (planDetails, callback) => {
    this.props.setglobalLoaderStatus(true)
    let AuthID = this.props.user.consumerDetails.data.accessToken.ID
    let ConsumerID = this.props.user.userDetails.consumerID
    let requestObject = {
      ConsumerID,
      PlanArray: [planDetails.PlanID],
      pagination: {
        itemsPerPage: 10,
        pageNo: 1,
      },
    }
    this.props
      .fetchConsumerProductsForEligiblePlansAsync(requestObject, AuthID)
      .then((response) => {
        if (response.pagination && response.pagination.totalPageCount > 1) {
          this.props.fetchAllConsumerProductsForEligiblePlansAsync(
            requestObject,
            AuthID,
            requestObject.pagination.pageNo + 1,
            response.pagination.totalPageCount,
            _.get(this, 'props.devices.eligiblePlanDeviceList', []),
            () => {
              return callback(_.get(this, 'props.devices.eligiblePlanDeviceList', []))
            }
          )
        } else {
          return callback(_.get(this, 'props.devices.eligiblePlanDeviceList', []))
        }
      })
      .catch(() => {
        this.props.setglobalLoaderStatus(false)
        callback(_.get(this, 'props.devices.eligiblePlanDeviceList', []))
      })
  }

  addNewDeviceRedirect = () => {
    browserHistory.replace({
      pathname: '/devices/add-device',
      query: {
        path: 'myplans',
      },
    })
  }

  onClickChangePaymentMethod = (plan) => {
    let reqObj = {
      data: {
        ConsumerID: _.get(this.props, 'user.userDetails.consumerID', undefined),
        CountryID: _.get(this.props, 'user.selectedCountryData.CountryID', undefined),
        CurrencyID: _.get(this.props, 'user.selectedCountryData.currencies[0].CurrencyID', undefined),
        PaymentMode: 'EMI',
        PlanArray: [
          {
            PlanID: plan.PlanID,
          },
        ],
        action: 'update',
        app: _.get(this.props, 'user.appConfigDetails.AppName', undefined),
        isSubscriptionPayment: true,
        isCardChange: true,
        SoldPlanID: plan.SoldPlanID,
        PaymentInfoDetails: {
          PlanArray: [
            {
              PlanID: plan.PlanID,
            },
          ],
          Source: _.get(this.props, 'user.appConfigDetails.AppName', undefined),
          CountryID: _.get(this.props, 'user.selectedCountryData.CountryID', undefined),
          CountryCode: _.get(this.props, 'user.selectedCountryData.currencies[0].CurrencyCode', undefined),
          Status: 1,
          CurrencyID: _.get(this.props, 'user.selectedCountryData.currencies[0].CurrencyID', undefined),
          isInternalModule: false,
          interface: 'consumerWeb',
          app: _.get(this.props, 'user.appConfigDetails.AppName', undefined),
        },
      },
    }
    this.props.setglobalLoaderStatus(true)
    try {
      pushToAnalytics('Change Payment Method', {
        ConsumerID: _.get(this, 'props.user.userDetails.consumerID', ''),
        SoldPlanID: plan.SoldPlanID,
        PlanName: plan.PlanName,
        PlanID: plan.PlanID,
        screen_class: 'My Plans',
      })
      this.props
        .getPayableAmountForNonLoginFlow(reqObj, true)
        .then(() => {
          const soldPlanDetails = _.get(this, 'props.myPlans.soldPlanDetails', {})
          this.props.setSoldPlanDetails({ ...soldPlanDetails, SoldPlanID: plan.SoldPlanID, Status: plan.Status, PlanName: plan.PlanName })
          this.props.setglobalLoaderStatus(false)
          this.props.storeLastKnowPathName({
            path: '/myplans',
            params: {
              ...this.props.location.query,
            },
          })
          browserHistory.push({
            pathname: '/payment',
            query: {
              [this.props.location.query.region ? 'region' : '']: this.props.location.query.region,
            },
          })
        })
        .catch(() => {
          this.props.setglobalLoaderStatus(false)
        })
    } catch (e) {
      console.log('e - ', e)
      this.props.setglobalLoaderStatus(false)
    }
  }

  showBillingHistory = (state, data) => {
    this.setState({
      showBillingHistory: state,
      billingHistoryData: data,
    })
  }

  setCurrentPage = (pageNo, SoldPlanID, planDetails) => {
    this.viewBillingHistory(SoldPlanID, planDetails, pageNo)
  }

  viewBillingHistory = async (SoldPlanID, planDetails, currentPage = 1) => {
    this.props.setglobalLoaderStatus(true)
    try {
      const obj = {
        SoldPlanID,
        ConsumerID: _.get(this.props, 'user.userDetails.consumerID', ''),
        app: BrandSpecificConfig().header,
        pagination: {
          currentPage: currentPage,
          itemsPerPage: 10,
        },
      }
      const history = await this.props.getBillingHistory(obj)
      // console.log('hisotry - ', history)
      if (history?.data && history?.data?.PaymentDetails && history?.data?.PaymentDetails?.length) {
        this.showBillingHistory(true, { ...history.data, planDetails })
      } else {
        this.props.setToaster(getTranslationText('error', 'somethingWentWrong'), 'error')
      }
      this.props.setglobalLoaderStatus(false)
    } catch (e) {
      this.props.setglobalLoaderStatus(false)
    }
  }

  render() {
    let renderdiv
    const dateFormat = _.get(this, 'props.user.selectedCountryData.countryConfig.DateFormat.DEFAULT', 'DD/MM/YYYY')
    const locale = _.get(this, 'props.user.selectedCountryData.countryConfig.Locale.DEFAULT', 'en')
    const currencyCode = _.get(this, 'props.user.selectedCountryData.CurrencyCode', '')
    if (this.props.params.p) {
      switch (this.props.params.p) {
        case 'subscription':
          renderdiv = (
            <SelectedPlansSubscriptionDetails
              {...this.props}
              PlanDetailsJson={this.props.myPlans.purchasedPlanList}
              enrolledDeviceList={this.props.myPlans.enrolledDeviceList}
              dateFormat={dateFormat}
              locale={locale}
              currencyCode={currencyCode}
              fetchEligibleDeviceAsync={this.fetchEligibleDeviceAsync}
            />
          )
          break
        default:
          browserHistory.replace('/pagenotfound')
      }
    } else if (this.props.myPlans.purchasedPlanList) {
      renderdiv = (
        <>
          {this.state.showBillingHistory ? (
            <BillingHistory
              {...this.props}
              billingHistoryData={this.state.billingHistoryData}
              setCurrentPage={this.setCurrentPage}
              showBillingHistory={this.showBillingHistory}
            />
          ) : (
            <MyPlansMainComponents
              {...this.props}
              viewSelectedPlan={this.viewSelectedPlan}
              listPlanViewDetails={this.listPlanViewDetails}
              planViewSelectedTab={this.state.planViewSelectedTab}
              PlanDetailsJson={this.props.myPlans.purchasedPlanList}
              dateFormat={dateFormat}
              locale={locale}
              currencyCode={currencyCode}
              onClickChangePaymentMethod={this.onClickChangePaymentMethod}
              viewBillingHistory={this.viewBillingHistory}
            />
          )}
        </>
      )
    }
    return (
      <div className='myplans-components mb48 mt20'>
        <React.Fragment>
          {renderdiv}
          {this.props.myPlans.newDeviceAdditionModal && (
            <>
              <DeviceAdditionModal
                setDeviceAdditionModal={this.props.setDeviceAdditionModal}
                addNewDeviceRedirect={this.addNewDeviceRedirect}
              />
            </>
          )}
        </React.Fragment>
      </div>
    )
  }
}

export default MyPlans

MyPlans.propTypes = {
  setglobalLoaderStatus: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  myPlans: PropTypes.object.isRequired,
  toastsAndLoaders: PropTypes.object.isRequired,
  setToaster: PropTypes.func.isRequired,
  getPurchasedPlansAsync: PropTypes.func.isRequired,
  fetchCardDetailsAsync: PropTypes.func.isRequired,
  getEnrolledDevicesAsync: PropTypes.func.isRequired,
  setSelectedPurchasedPlanDetails: PropTypes.func.isRequired,
  clearMyPlanDetails: PropTypes.func.isRequired,
  setPlansEligibleDevices: PropTypes.func.isRequired,
}
